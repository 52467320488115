import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { Availability } from "./HealthProgramTracking/Availability.component";
import { CallPoints } from "./HealthProgramTracking/CallPoints.component";
import { CallQualityFactor } from "./HealthProgramTracking/CallQualityFactor.component";
import { SpecializationFactor } from "./HealthProgramTracking/SpecializationFactor.component";
import { HealthProgramProps } from "./types";

export const ProgramTrackingForm: FC<HealthProgramProps> = ({ errors, healthProgram, readOnly }) => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom>{t("Call Points")}</Typography>
        <CallPoints readOnly={readOnly} errors={errors} defaultValues={healthProgram} />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>{t("Call Quality Rating")}</Typography>
        <CallQualityFactor readOnly={readOnly} errors={errors} defaultValues={healthProgram} />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>{t("Availability")}</Typography>
        <Availability readOnly={readOnly} errors={errors} defaultValues={healthProgram} />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>{t("Specialization Factor")}</Typography>
        <SpecializationFactor readOnly={readOnly} errors={errors} defaultValues={healthProgram} />
      </Grid>
    </Grid>
  );
};
