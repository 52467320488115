import { InMemoryCacheConfig } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
// import { relayStylePagination } from "@apollo/client/utilities";

export const cacheUtils: InMemoryCacheConfig = {
  typePolicies: {
    Vendor: {
      merge: true,
      fields: {
        branches: relayStylePagination(),
      },
    },
    Query: {
      fields: {
        // categories: relayStylePagination(),
        // vendors: relayStylePagination(),
        getCodeSystems: relayStylePagination(),
      },
    },
  },
};
