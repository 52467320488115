/* eslint-disable max-lines */
import { PermissionEnum } from "@health/queries/types";
import { hasAnyPermission, hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { BookAnAppointmentIcon } from "@toolkit/ui";
import { healthProgramPaths, healthProgramRoute } from "pages/HealthProgram/constants/health-program-paths";
import { ExclusionFormEditPage, ExclusionFormNewPage, ExclusionsList } from "pages/HealthProgram/Exclusions";
import {
  HealthProgramList,
  HealthProgramsFormEditPage,
  HealthProgramsFormNewPage,
  ProgramTrackingPage,
} from "pages/HealthProgram/HealthPrograms";
import { ProgramBenefitEditPage, ProgramBenefitList, ProgramBenefitNewPage } from "pages/HealthProgram/ProgramBenefit";
import { HealthProgramCallsPerVendor } from "pages/HealthProgramCallsPerVendor";
import { callsPerVendorPaths } from "pages/HealthProgramCallsPerVendor/constants/calls-per-vendor-paths";
import { HealthProgramMemberListEditPage, HealthProgramMemberListNewPage, HealthProgramMemberLists } from "pages/HealthProgramMemberLists";
import { memberListPaths, memberListRoute } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import { HealthProgramMemberEditPage, HealthProgramMemberNewPage, HealthProgramMembers } from "pages/HealthProgramMembers";
import { HealthProgramNetworkProviderProfessionals } from "pages/HealthProgramNetworkProviderProfessionals";
import {
  HealthProgramNetworkProviderEditPage,
  HealthProgramNetworkProviderNewPage,
  HealthProgramNetworkProviders,
} from "pages/HealthProgramNetworkProviders";
import { HealthProgramNetworkEditPage, HealthProgramNetworkNewPage, HealthProgramNetworks } from "pages/HealthProgramNetworks";
import { healthProgramsNetworksRoute, networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { HealthProgramVendorTotalPointsReport } from "pages/HealthProgramVendorTotalPointsReport";
import { vendorTotalPointsReportPaths } from "pages/HealthProgramVendorTotalPointsReport/constants/vendor-total-points-report-paths";
import { membersRoutes } from "pages/Members/route";
import { ProgramActivityTracking, ProvidersActivityTrackingLists } from "pages/ProgramActivityTracking";
import { programActivityPaths, programActivityRoute } from "pages/ProgramActivityTracking/constants/program-activity-paths";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const healthProgramsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-programs-routes",
    text: t("Programs", { ns: "admin" }),
    icon: <BookAnAppointmentIcon />,
    isProhibited: !hasAnyPermission([PermissionEnum.ManageHealthPrograms, PermissionEnum.ViewHealthPrograms]),
    subItems: [
      {
        id: "health-program-routes",
        text: t("Profiles", { ns: "admin" }),
        route: healthProgramRoute,
        subItems: [
          {
            id: "health-program-list-route",
            text: t("Profiles", { ns: "admin" }),
            route: healthProgramPaths.listPath.route,
            fullPath: healthProgramPaths.listPath.fullPath,
            element: <HealthProgramList />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-new-route",
            text: t("Health Programs", { ns: "admin" }),
            route: healthProgramPaths.newPath.route,
            fullPath: healthProgramPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramsFormNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-edit-route",
            text: t("Edit Health Program", { ns: "admin" }),
            route: healthProgramPaths.editPath.route,
            fullPath: healthProgramPaths.editPath.fullPath,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            hidden: true,
            element: <HealthProgramsFormEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-program-logic-route",
            text: t("Program Logic Parameters", { ns: "admin" }),
            route: healthProgramPaths.programLogicPath.route,
            fullPath: healthProgramPaths.programLogicPath.fullPath,
            hidden: true,
            element: <ProgramTrackingPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-members-route",
            text: t("Members", { ns: "admin" }),
            route: healthProgramPaths.membersPath.route,
            fullPath: healthProgramPaths.membersPath.fullPath,
            hidden: true,
            element: <HealthProgramMembers />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-members-new-route",
            text: t("Members New", { ns: "admin" }),
            route: healthProgramPaths.membersNewPath.route,
            fullPath: healthProgramPaths.membersNewPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-members-edit-route",
            text: t("Members Edit", { ns: "admin" }),
            route: healthProgramPaths.membersEditPath.route,
            fullPath: healthProgramPaths.membersEditPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-exclusion-route",
            text: t("Program Exclusion Networks", { ns: "admin" }),
            route: healthProgramPaths.exclusionPath.route,
            fullPath: healthProgramPaths.exclusionPath.fullPath,
            hidden: true,
            element: <ExclusionsList />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-exclusion-new-route",
            text: t("New Program Exclusion Network", { ns: "admin" }),
            route: healthProgramPaths.exclusionNewPath.route,
            fullPath: healthProgramPaths.exclusionNewPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <ExclusionFormNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-exclusion-edit-route",
            text: t("Edit Program Exclusion Network", { ns: "admin" }),
            route: healthProgramPaths.exclusionEditPath.route,
            fullPath: healthProgramPaths.exclusionEditPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <ExclusionFormEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-benefits-route",
            text: t("Programs Benefits", { ns: "admin" }),
            route: healthProgramPaths.benefitsPath.route,
            fullPath: healthProgramPaths.benefitsPath.fullPath,
            hidden: true,
            element: <ProgramBenefitList />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-benefits-new-route",
            text: t("New Benefits", { ns: "admin" }),
            route: healthProgramPaths.benefitsNewPath.route,
            fullPath: healthProgramPaths.benefitsNewPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <ProgramBenefitNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-benefits-edit-route",
            text: t("Edit Benefits", { ns: "admin" }),
            route: healthProgramPaths.benefitsEditPath.route,
            fullPath: healthProgramPaths.benefitsEditPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <ProgramBenefitEditPage />,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
      {
        id: "networks-routes",
        text: t("Networks", { ns: "admin" }),
        route: healthProgramsNetworksRoute,
        subItems: [
          {
            id: "networks-list-route",
            text: t("Networks", { ns: "admin" }),
            route: networksPaths.listPath.fullPath,
            fullPath: networksPaths.listPath.fullPath,
            element: <HealthProgramNetworks />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-new-route",
            text: t("Networks New", { ns: "admin" }),
            route: networksPaths.newPath.route,
            fullPath: networksPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramNetworkNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-edit-route",
            text: t("Networks Edit", { ns: "admin" }),
            route: networksPaths.editPath.route,
            fullPath: networksPaths.editPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramNetworkEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-providers-route",
            text: t("Network Providers", { ns: "admin" }),
            route: networksPaths.providersPath.route,
            fullPath: networksPaths.providersPath.fullPath,
            hidden: true,
            element: <HealthProgramNetworkProviders />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-providers-new-route",
            text: t("Network New Providers", { ns: "admin" }),
            route: networksPaths.providersNewPath.route,
            fullPath: networksPaths.providersNewPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramNetworkProviderNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-providers-edit-route",
            text: t("Network Edit Providers", { ns: "admin" }),
            route: networksPaths.providersEditPath.route,
            fullPath: networksPaths.providersEditPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramNetworkProviderEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-providers-program-route",
            text: t("Network Providers", { ns: "admin" }),
            route: networksPaths.providersProgramPath.route,
            fullPath: networksPaths.providersProgramPath.fullPath,
            hidden: true,
            element: <HealthProgramNetworkProviders />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "networks-providers-professionals-route",
            text: t("Network Provider Professionals", { ns: "admin" }),
            route: networksPaths.providersProfessionalsPath.route,
            fullPath: networksPaths.providersProfessionalsPath.fullPath,
            hidden: true,
            element: <HealthProgramNetworkProviderProfessionals />,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
      {
        id: "member-list-routes",
        text: t("Member Lists", { ns: "admin" }),
        route: memberListRoute,
        subItems: [
          {
            id: "member-list-main-route",
            text: t("Member Lists", { ns: "admin" }),
            route: memberListPaths.listPath.fullPath,
            fullPath: memberListPaths.listPath.fullPath,
            element: <HealthProgramMemberLists />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "member-list-new-route",
            text: t("Member List New Management Path", { ns: "admin" }),
            route: memberListPaths.newPath.route,
            fullPath: memberListPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberListNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "member-list-edit-route",
            text: t("Member List Edit Management Path", { ns: "admin" }),
            route: memberListPaths.editPath.route,
            fullPath: memberListPaths.editPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberListEditPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "member-list-members-route",
            text: t("Members", { ns: "admin" }),
            route: memberListPaths.membersPath.route,
            fullPath: memberListPaths.membersPath.fullPath,
            hidden: true,
            element: <HealthProgramMembers />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "member-list-members-new-route",
            text: t("Members New", { ns: "admin" }),
            route: memberListPaths.membersNewPath.route,
            fullPath: memberListPaths.membersNewPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "member-list-members-edit-route",
            text: t("Members Edit", { ns: "admin" }),
            route: memberListPaths.membersEditPath.route,
            fullPath: memberListPaths.membersEditPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
            element: <HealthProgramMemberEditPage />,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
      {
        id: "program-activity-routes",
        text: t("Program Activity Tracking", { ns: "admin" }),
        route: programActivityRoute,
        subItems: [
          {
            id: "program-activity-list-route",
            text: t("Program Activity Tracking", { ns: "admin" }),
            route: programActivityPaths.listPath.fullPath,
            fullPath: programActivityPaths.listPath.fullPath,
            element: <ProgramActivityTracking />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "program-activity-route",
            text: t("Program Activity Tracking Provider", { ns: "admin" }),
            route: programActivityPaths.providerPath.route,
            fullPath: programActivityPaths.providerPath.fullPath,
            hidden: true,
            element: <ProvidersActivityTrackingLists />,
          },
        ],
      },
      {
        id: "vendor-total-points-report-list-route",
        text: t("Vendor Total Points Report", { ns: "admin" }),
        route: vendorTotalPointsReportPaths.listPath.fullPath,
        fullPath: vendorTotalPointsReportPaths.listPath.fullPath,
        element: <HealthProgramVendorTotalPointsReport />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "calls-per-vendor-main-route",
        text: t("Calls Per Vendor", { ns: "admin" }),
        route: callsPerVendorPaths.listPath.fullPath,
        fullPath: callsPerVendorPaths.listPath.fullPath,
        hidden: true,
        element: <HealthProgramCallsPerVendor />,
        onClick: (route: string) => navigate(route),
      },
      ...membersRoutes({ navigate, t }),
    ],
  };
};
