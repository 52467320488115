import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";
import { careForOptions } from "./utils";

export const CareFor: FC<IProgramProps> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    formState: { errors: reactFormErrors },
    control,
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);
  const defaultValuesCareFor = defaultValues?.careFor?.map(item => ({ label: item, value: item }));

  return (
    <AutocompleteController
      multiple
      ControllerProps={{
        name: "careFor",
        control,
        defaultValue: defaultValuesCareFor || [],
      }}
      TextFieldProps={{
        label: t("Care For"),
        helperText: formErrors.careFor?.message,
        error: Boolean(formErrors.careFor?.message),
      }}
      getOptionLabel={(o: { label }) => o.label}
      options={careForOptions}
    />
  );
};
