import { combineErrors, formGirdBreakPoints, formGirdSpacing, getMaxLengthValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, FormCard, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { bankNameOptions } from "../../VendorForm.utils";
import { CustomTextField } from "../CustomTextField.components";
import { ErrorsInputProps, InfoProps } from "../types";

export const BankInformation: FC<InfoProps & ErrorsInputProps> = props => {
  const { defaultValues, errorsInput, isLoading, doYouHaveVendor } = props;
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const bankInfoFields = ["bankInfo.bankName", "bankInfo.accountName", "bankInfo.iban", "bankInfo.accountNumber"];
  const formErrors = combineErrors(errors, errorsInput);
  const defaultBankName = bankNameOptions?.find(el => el?.value === defaultValues?.bankInfo?.bankName);
  const bankInfoFieldsValues = useWatch({
    control,
    name: bankInfoFields,
  });
  const isRequired = bankInfoFieldsValues.some(e => e);

  const onBlur = () => {
    trigger(bankInfoFields);
  };
  return (
    <Grid item xs={12}>
      <FormCard title={t("Banking Information")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <AutocompleteController
              ControllerProps={{
                control: control,
                name: "bankInfo.bankName",
                defaultValue: defaultBankName || null,
                rules: { required: isRequired && t("Required") },
              }}
              getOptionLabel={option => option?.name}
              onBlur={onBlur}
              options={bankNameOptions || []}
              TextFieldProps={{
                placeholder: t("Bank Name"),
                error: Boolean(formErrors?.bankInfo?.bankName?.message),
                helperText: formErrors?.bankInfo?.bankName?.message,
              }}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <CustomTextField
              isRequired={isRequired}
              onBlur={onBlur}
              name='bankInfo.accountName'
              label={t("Account Name")}
              defaultValue={defaultValues?.bankInfo?.accountName}
              registerProps={{
                maxLength: getMaxLengthValidation(t, 255),
              }}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <CustomTextField
              isRequired={isRequired}
              onBlur={onBlur}
              name='bankInfo.iban'
              label={t("IBAN")}
              defaultValue={defaultValues?.bankInfo?.iban}
              registerProps={{
                maxLength: getMaxLengthValidation(t, 255),
              }}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <CustomTextField
              isRequired={isRequired}
              onBlur={onBlur}
              name='bankInfo.accountNumber'
              label={t("Account Number")}
              defaultValue={defaultValues?.bankInfo?.accountNumber}
              registerProps={{
                maxLength: getMaxLengthValidation(t, 255),
              }}
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
