/* eslint-disable max-lines */
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, SurveyGender } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CircularProgress,
  CustomDialog,
  DatePicker,
  FormControlLabel,
  Grid,
  LocalizationProvider,
  MomentUtils,
  Radio,
  RadioGroup,
  TextField,
} from "@toolkit/ui";
import moment, { Moment } from "moment";
import { FC, memo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PublishDialogProps } from "./PublishDialog.types";

const PublishDialog: FC<PublishDialogProps> = ({ isOpen, isLoading, onClose, onPublish }) => {
  const [maxParticipants, setMaxParticipants] = useState(1);
  const [fromAge, setFromAge] = useState<number>();
  const [toAge, setToAge] = useState<number>();
  const [gender, setGender] = useState<SurveyGender>();
  const [endDate, onEndDateChange] = useState<Moment | null>(moment());
  const { t } = useTranslation("admin");
  const methods = useForm();
  const chronicDiseaseCode = methods.watch("chronicDiseaseCode");
  const handlePublish = () => {
    onPublish({
      expirationDate: endDate?.format("YYYY-MM-DD"),
      maxParticipants,
      fromAge,
      toAge,
      chronicDiseaseCode: chronicDiseaseCode?.value?.code,
      gender: gender || undefined,
    });
  };

  const isPublishDisabled =
    !maxParticipants || !endDate || (fromAge && toAge && fromAge > toAge) || isLoading || moment().startOf("day").isAfter(endDate);

  const handleFromAgeChange = event => {
    setFromAge(+event.target.value);
  };
  const handleToAgeChange = event => {
    setToAge(+event.target.value);
  };

  const handleMaxParticipantsChange = event => {
    setMaxParticipants(+event.target.value);
  };

  const handleGenderChange = event => {
    setGender(event.target.value);
  };

  return (
    <CustomDialog
      type='base'
      DialogTitleProps={{
        onClose: onClose,
        title: t("Publish Rules"),
      }}
      open={isOpen}
      maxWidth='md'
      keepMounted={false}
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handlePublish} disabled={isPublishDisabled} startIcon={isLoading && <CircularProgress size={15} />}>
              {t("Publish")}
            </Button>
            <Button variant='outlined' onClick={onClose} autoFocus>
              {t("Close")}
            </Button>
          </>
        ),
      }}
      DialogContentProps={{ sx: { maxHeight: "60vh" } }}
    >
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder={t("From Age")}
              label={t("From Age")}
              value={fromAge}
              type='number'
              InputProps={{ inputProps: { min: 5, max: toAge } }}
              onChange={handleFromAgeChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder={t("To Age")}
              label={t("To Age")}
              value={toAge}
              type='number'
              InputProps={{ inputProps: { min: fromAge } }}
              onChange={handleToAgeChange}
            />
          </Grid>

          <Grid item xs={12}>
            <SystemCodeAutocomplete
              name={"chronicDiseaseCode"}
              label={t("Disease")}
              filter={{ codeSystemCode: CodeSystemCode.ChronicDisease }}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label={t("End Date")}
                value={endDate}
                onChange={onEndDateChange}
                renderInput={params => <TextField fullWidth placeholder={t("End Date")} {...params} />}
                minDate={moment()}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              value={maxParticipants}
              type='number'
              InputProps={{ inputProps: { min: 1 } }}
              placeholder={t("Max Number of Participants")}
              label={t("Max Number of Participants")}
              onChange={handleMaxParticipantsChange}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby='gender-radio-label'
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              value={gender}
              onChange={handleGenderChange}
            >
              <FormControlLabel control={<Radio value='Male' />} label={t("Male")} />
              <FormControlLabel control={<Radio value='Female' />} label={t("Female")} />
              <FormControlLabel control={<Radio value='' />} label={t("Any")} />
            </RadioGroup>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomDialog>
  );
};
export default memo(PublishDialog);
