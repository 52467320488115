import { TFunction } from "@toolkit/i18n";
import { type Palette } from "../../base/mui";

export const mapAppearanceToCardColor = (appearance: string, palette: Palette) => {
  if ("success" === appearance) {
    return palette.success.main;
  } else if ("warning" === appearance) {
    return palette.warning.main;
  } else if ("error" === appearance) {
    return palette.secondary.main;
  } else if ("info" === appearance) {
    return palette.info.main;
  }
};

export const mapAppearanceToSelectedIcon = (appearance: string) => {
  if ("success" === appearance) {
    return "successAlert";
  } else if ("warning" === appearance || "error" === appearance) {
    return "warningAlert";
  } else if ("info" === appearance) {
    return "info";
  }
};

export const mapAppearanceToCardTitle = (appearance: string, t: TFunction) => {
  if ("success" === appearance) {
    return t("Success");
  } else if ("warning" === appearance) {
    return t("Warning");
  } else if ("error" === appearance) {
    return t("Error");
  } else if ("info" === appearance) {
    return t("Info");
  }
};
