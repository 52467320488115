/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc, PermissionGroupErrorFragmentFragmentDoc } from '../../fragments/__generated__/permissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePermissionGroupMutationVariables = Types.Exact<{
  permissionGroupUpdateId: Types.Scalars['ID'];
  input: Types.PermissionGroupUpdateInput;
}>;


export type UpdatePermissionGroupMutation = { __typename?: 'Mutation', permissionGroupUpdate?: { __typename?: 'PermissionGroupUpdate', group?: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', code: Types.PermissionGroupErrorCode, field?: string | null, message?: string | null }> } | null };


export const UpdatePermissionGroupDocument = gql`
    mutation updatePermissionGroup($permissionGroupUpdateId: ID!, $input: PermissionGroupUpdateInput!) {
  permissionGroupUpdate(id: $permissionGroupUpdateId, input: $input) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorFragmentFragmentDoc}`;
export type UpdatePermissionGroupMutationFn = Apollo.MutationFunction<UpdatePermissionGroupMutation, UpdatePermissionGroupMutationVariables>;

/**
 * __useUpdatePermissionGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionGroupMutation, { data, loading, error }] = useUpdatePermissionGroupMutation({
 *   variables: {
 *      permissionGroupUpdateId: // value for 'permissionGroupUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePermissionGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionGroupMutation, UpdatePermissionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionGroupMutation, UpdatePermissionGroupMutationVariables>(UpdatePermissionGroupDocument, options);
      }
export type UpdatePermissionGroupMutationHookResult = ReturnType<typeof useUpdatePermissionGroupMutation>;
export type UpdatePermissionGroupMutationResult = Apollo.MutationResult<UpdatePermissionGroupMutation>;
export type UpdatePermissionGroupMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionGroupMutation, UpdatePermissionGroupMutationVariables>;