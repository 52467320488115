/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { basicBenefitsOptions } from "../components/utils";
import { useBenefitsTypeQueriesHooks } from "./useBenefitsTypeQueriesHooks";

export const useBenefitsTypeHooks = defaultValues => {
  const { healthProgramBenefitGroups, extraBenefitsList, basicBenefitsList } = useBenefitsTypeQueriesHooks();
  const [state, setState] = useState<any>(healthProgramBenefitGroups);
  const [active, setActive] = useState<number | undefined>(undefined);
  const { setValue, formState } = useFormContext();

  const basicBenefitsOptionsFields = basicBenefitsOptions?.map(item => ({ ...item, isChecked: false }));

  const lists = extraBenefitsList?.map(list => ({
    ...list,
    healthChannels: [],
    extraBenefit: list?.id,
    isChecked: false,
  }));

  const handleChangeBenefitsGroup = (index, id) => {
    setActive(index);
    const selected = state?.map(item =>
      item.id === id
        ? { ...item, isSelected: true, optionsValues: item?.optionsValues || basicBenefitsList }
        : { ...item, isSelected: false }
    );
    setState([...selected]);
  };

  useEffect(() => {
    const benefitGroups = defaultValues?.benefitGroups;

    const newData =
      healthProgramBenefitGroups?.map(item => {
        const currentBenefitGroups = benefitGroups?.find(group => group?.benefitGroup?.id === item?.id);

        const optionsValues = basicBenefitsList?.map(option => {
          const optionsValues = currentBenefitGroups?.optionsValues?.find(value => option?.id === value?.option?.id);

          return optionsValues
            ? {
                ...optionsValues,
                isChecked: true,
                nameOption: optionsValues?.name,
                id: optionsValues?.option?.id,
                name: optionsValues?.option?.name,
              }
            : { ...option };
        });
        return currentBenefitGroups
          ? {
              ...currentBenefitGroups,
              isAdded: true,
              id: item?.id,
              optionsValues: optionsValues,
              name: currentBenefitGroups?.benefitGroup?.name,
            }
          : { ...item, isAdded: false };
      }) || [];

    const currentSelected = newData?.findIndex(item => item?.benefitGroup?.id)!;
    setActive(currentSelected);
    newData[currentSelected] = { ...newData[currentSelected], isSelected: true };
    defaultValues ? setState(newData) : setState(healthProgramBenefitGroups);
  }, [JSON.stringify(healthProgramBenefitGroups), JSON.stringify(defaultValues), basicBenefitsList]);

  useEffect(() => {
    const selectedItem = state?.find(item => item.isSelected);
    const newState = state?.map(item =>
      item?.id === selectedItem?.id
        ? {
            ...item,
            optionsValues: selectedItem?.optionsValues?.map(item =>
              item?.isChecked && formState?.isSubmitted
                ? { ...item, errorValue: Boolean(!item?.value), errorName: Boolean(!item?.nameOption) }
                : { ...item }
            ),
          }
        : { ...item }
    );

    formState?.isSubmitted && setState(newState);
    setValue("benefitGroups", formState?.isSubmitted ? newState : state);
  }, [formState?.isSubmitted, JSON.stringify(state)]);

  const handleCheck = (e, i) => {
    const items = state.map((item, index) =>
      i === index ? { ...item, isChecked: e.target.checked, optionsValues: basicBenefitsOptionsFields, extraBenefits: lists } : { ...item }
    );

    const SelectedItem = items?.findIndex(item => item?.isChecked);
    setActive(e.target.checked ? i : SelectedItem);
    setState(items);
  };

  const handleAddBenefitGroup = (e, id) => {
    e.stopPropagation();

    const items = state.map(item => (id === item?.id ? { ...item, isAdded: false, optionsValues: basicBenefitsList } : { ...item }));
    setState(items);
  };

  const handleCheckOptionValue = (e, id) => {
    const items = state.map((item, index) => {
      const optionsValues = item?.optionsValues?.map(value =>
        value?.id === id
          ? {
              ...value,
              isChecked: e.target.checked,
              value: e.target.checked ? item.value : "",
              nameOption: e.target.checked ? item.nameOption : "",
            }
          : { ...value }
      );
      const isAdded = optionsValues?.some(value => value?.isChecked);
      return active === index
        ? {
            ...item,
            isAdded: isAdded,
            optionsValues,
          }
        : { ...item };
    });
    setState(items);
  };

  const handleOptionsFiled = (e, id, filed) => {
    const items = state.map((item, index) => {
      const optionsValues = item?.optionsValues?.map(value => (value?.id === id ? { ...value, [filed]: e.target.value } : { ...value }));

      return active === index
        ? {
            ...item,
            optionsValues,
          }
        : { ...item };
    });
    setState(items);
  };

  const handleChangeHealthChannels = (e, value, currentIndex) => {
    const items = state.map((item, index) =>
      active === index
        ? {
            ...item,
            extraBenefits: item?.extraBenefits?.map((extraBenefit, indexItem) => {
              return indexItem === currentIndex
                ? {
                    ...extraBenefit,
                    healthChannels: value || [],
                  }
                : { ...extraBenefit };
            }),
          }
        : { ...item }
    );
    setState(items);
  };

  return {
    state,
    active,
    handleCheck,
    extraBenefitsList,
    handleAddBenefitGroup,
    handleOptionsFiled,
    handleCheckOptionValue,
    handleChangeBenefitsGroup,
    handleChangeHealthChannels,
  };
};
