import { AdminPromotionInput } from "@health/queries/types";
import { IPromotionUpsertFormValues } from "../forms/PromotionFormUpsert/PromotionUpsertFormSchema";
import { AdminPromotionsQueryNode } from "../types";

export const convertPromotionFormValuesToBackEndValues = (values: IPromotionUpsertFormValues): AdminPromotionInput => {
  return {
    content: values.content,
    contentAr: values.contentAr!,
    description: values?.description!,
    descriptionAr: values.descriptionAr,
    endDate: values.endDate,
    name: values.name,
    nameAr: values.nameAr!,
    photo: values?.photo!,
    startDate: values.startDate,
  };
};

export const convertPromotionToFormValues = (promotion: AdminPromotionsQueryNode): IPromotionUpsertFormValues => {
  return {
    content: promotion.content!,
    contentAr: promotion.contentAr,
    description: promotion?.description,
    descriptionAr: promotion.descriptionAr!,
    endDate: promotion.endDate,
    name: promotion.name!,
    nameAr: promotion.nameAr,
    photo: promotion?.photo,
    startDate: promotion.startDate,
  };
};
