import { AppRoleTypes } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const appRoleTypeOptionsMap = {
    [AppRoleTypes.Admin]: {
        key: AppRoleTypes.Admin,
        get label() {
            return i18n.t("Admin", { ns: "domains" });
        },
        value: AppRoleTypes.Admin,
    },
    [AppRoleTypes.User]: {
        key: AppRoleTypes.User,
        get label() {
            return i18n.t("User", { ns: "domains" });
        },
        value: AppRoleTypes.User,
    },
};
export const appRoleTypeOptions = Object.values(appRoleTypeOptionsMap);
