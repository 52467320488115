import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SupplementTypeTrackerForm } from "./components/SupplementTypeTrackerForm";
import { useTrackerSupplementTypeQuery, useTrackerSupplementTypeUpdateMutation } from "../gql";
import {
  supplementTypeFieldBreadcrumb,
  supplementTypesListBreadcrumb,
  supplementTypesTitleBreadcrumb,
} from "../constants/supplement-types-breadcrumbs";

export const SupplementTypeTrackerEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, loading } = useTrackerSupplementTypeQuery({
    skip: !params?.id,
    variables: {
      id: params?.id!,
    },
  });

  const trackerSupplementType = data?.trackerSupplementType;
  const [TrackerSupplementTypeUpdate, { loading: isSubmitting }] = useTrackerSupplementTypeUpdateMutation({
    onCompleted: response => {
      const id = response?.trackerSupplementTypeUpdate?.id!;
      if (id) {
        succeeded(t("Supplement Type Updated Successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    delete input?.payerId;
    TrackerSupplementTypeUpdate({
      variables: {
        id: params?.id!,
        input,
      },
    });
  };

  useEffect(() => {
    if (params?.id) {
      setBreadCrumb({
        title: supplementTypesTitleBreadcrumb(t),
        values: [supplementTypesListBreadcrumb(t), supplementTypeFieldBreadcrumb(trackerSupplementType?.name!)],
      });
    }
  }, [trackerSupplementType?.name]);

  return (
    <SupplementTypeTrackerForm
      isLoading={loading}
      isSubmitting={isSubmitting}
      defaultValue={trackerSupplementType!}
      onSubmit={handleSubmit}
    />
  );
};
