import { BulkFile, BulkFilesOrderDirection, BulkFileSortField, BulkProcessingStatus, BulkTemplateCode } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { CancelBulkFile } from "./CancelBulkFile";
import { DownloadBulkFile } from "./DownloadBulkFile";
import { useUsersQuery } from "./gql";
import moment from "moment";

export type BulkFilesColumnTypes = CustomTableColumnProps<BulkFile | any>[];

export const useUploadBulkFilesColumns = (): BulkFilesColumnTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "autocomplete",
          name: "code",
          accessor: "code",
          options: Object.keys(BulkTemplateCode).map(key => ({ value: BulkTemplateCode[key], name: key })),
          getOptionLabel: option => option?.name,
          getValueForBackend: option => option?.value,
        },
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.Code,
      },
      {
        key: "name",
        header: t("File Name"),
        accessor: ({ fileName }) => <TruncateTypography text={fileName} />,
        filter: {
          type: "string",
          name: "fileName",
        },
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.FileName,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: "processingStatus",
        filter: {
          type: "autocomplete",
          name: "processingStatus",
          accessor: "processingStatus",
          options: Object.keys(BulkProcessingStatus).map(key => ({ value: BulkProcessingStatus[key], name: key })),
          getOptionLabel: option => option?.name,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "recordsCount",
        header: t("Records Count"),
        accessor: "recordsCount",
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.RecordsCount,
      },
      {
        key: "failedRecordCount",
        header: t("Failed Records Count"),
        accessor: "failedRecordCount",
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.FailedRecordCount,
      },
      {
        key: "processedRecordCount",
        header: t("Processed Records Count"),
        accessor: "processedRecordCount",
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.ProcessedRecordCount,
      },
      {
        key: "createdBy",
        header: t("Created By"),
        accessor: ({ createdBy }) => <TruncateTypography text={createdBy?.firstName + " " + createdBy?.lastName} />,
        filter: {
          type: "autocomplete",
          query: useUsersQuery,
          name: "createdBy",
          accessor: "users",
          getOptionLabel: option => option?.fullName,
          getValueForBackend: selectedOption => selectedOption?.id || null,
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => moment(createdDate).format("DD MMM YYYY"),
        isSortable: true,
        direction: BulkFilesOrderDirection.Desc,
        sortColumnEnum: BulkFileSortField.CreatedDate,
      },
      {
        key: "fileName",
        header: t("Download File"),
        accessor: row => <DownloadBulkFile isButton title={t("Download .xlx")} id={row?.id} processingStatus={row?.processingStatus} />,
        isHidden: false,
      },
      {
        key: "cancel",
        header: t("Stop File Processing"),
        accessor: row => <CancelBulkFile isButton title={t("Cancel")} id={row?.id} processingStatus={row?.processingStatus} />,
        isHidden: false,
      },
    ];
  }, []);
};
