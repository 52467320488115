import { useTranslation } from "@toolkit/i18n";
import { ToggleButtonController, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";

export const PayPerCallToggle: FC<IProgramProps> = ({ defaultValues }) => {
  const { t } = useTranslation("admin");
  const { register, control } = useFormContext();

  return (
    <ToggleButtonController
      {...register("payPerCallOnly")}
      control={control}
      defaultValue={defaultValues?.payPerCallOnly!}
      label={<Typography>{t("Pay Per Call")}</Typography>}
      disabled={Boolean(defaultValues)}
    />
  );
};
