import { makeStyles } from "tss-react/mui";

export const useActiveFilterStyles = makeStyles()({
  filters: {
    display: "flex",
    "@media(max-Width: 900px)": {
      padding: "5px 0px 5px 0px",
    },
  },
  filterItem: {
    padding: "0 10px",
    margin: "0px 10px 10px 0px",
  },
  itemStyle: {
    whiteSpace: "nowrap",
    maxWidth: 140,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
  },
  button: {
    margin: "10px 5px",
  },
});
