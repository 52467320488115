import { CityInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  citiesManagementListBreadcrumb,
  citiesManagementNewBreadcrumb,
  citiesManagementTitleBreadcrumb,
} from "pages/CitiesManagement/constants/cities-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CityCreateMutation, CityErrorsFragment, useCityCreateMutation } from "../gql";

export const useCitiesManagementNewFormPageHooks = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<CityErrorsFragment[]>([]);
  const [cityCreateMutation, { loading: isCreateLoading }] = useCityCreateMutation({
    onCompleted: (createdCity: CityCreateMutation) => {
      const mutationErrors = createdCity?.cityCreate?.cityErrors as CityErrorsFragment[];
      if (mutationErrors && mutationErrors.length > 0) {
        setErrors(mutationErrors);
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        succeeded(t("City created successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed to create city"));
    },
  });
  const handleSubmit = (requestInput: CityInput) => {
    cityCreateMutation({
      variables: {
        input: requestInput,
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: citiesManagementTitleBreadcrumb(),
      values: [citiesManagementListBreadcrumb(), citiesManagementNewBreadcrumb()],
    });
  }, []);

  return {
    errors,
    isCreateLoading,
    handleSubmit,
  };
};
