import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useOptimaEditActivationMutation } from "../../../OptimaEdits/gql";
export const OptimaEditActivation = props => {
    const { id, isActive } = props;
    const [active, setActive] = useState(isActive);
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const [activateOptimaEdit, { loading: isSubmitting }] = useOptimaEditActivationMutation({
        onCompleted: mutationData => {
            var _a;
            setActive(!!((_a = mutationData === null || mutationData === void 0 ? void 0 : mutationData.updateOptimaEditActiveStatus) === null || _a === void 0 ? void 0 : _a.isActive));
            succeeded(t("Optima Edit updated successfully"));
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const handleChangeToggle = () => {
        activateOptimaEdit({ variables: { id } });
    };
    return _jsx(CustomToggleButton, { disabled: isSubmitting, isLoading: isSubmitting, checked: active, onChange: handleChangeToggle });
};
