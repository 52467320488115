import { replacePathParams } from "utils";

const callsPerVendorRoute = "/calls-per-vendor/:programId/:vendorId";

export const callsPerVendorPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${callsPerVendorRoute}${this.route}`;
    },
    fullPathWithParams: function (programId: string, vendorId: string) {
      return replacePathParams(this.fullPath, { programId, vendorId });
    },
  },
};
