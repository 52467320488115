/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type BranchUsersQuery = { __typename?: 'Query', users?: { __typename?: 'UserCountableConnection', edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const BranchUsersDocument = gql`
    query branchUsers($id: String!, $sortBy: UserSortingInput, $before: String, $after: String, $first: Int, $last: Int) {
  users(
    filter: {branchId: $id}
    sortBy: $sortBy
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        email
        firstName
        lastName
        mobile
        isStaff
        isSuperuser
        isVendorAdmin
        appType
        appRole
        isActive
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useBranchUsersQuery__
 *
 * To run a query within a React component, call `useBranchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBranchUsersQuery(baseOptions: Apollo.QueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
      }
export function useBranchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
        }
export type BranchUsersQueryHookResult = ReturnType<typeof useBranchUsersQuery>;
export type BranchUsersLazyQueryHookResult = ReturnType<typeof useBranchUsersLazyQuery>;
export type BranchUsersQueryResult = Apollo.QueryResult<BranchUsersQuery, BranchUsersQueryVariables>;