/* eslint-disable max-lines */
import { getAppRoleTypeFilter } from "@health/enum-options";
import { OrderDirection, User, UserSortField, UserStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { UserActiveToggleButton } from "./UserActiveToggleButton.component";

export type UsersManagementColumnsTypes = CustomTableColumnProps<User>[];

export const useUserManagementColumns = (userId: string, canManageUser: boolean): UsersManagementColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "status",
          options: Object.keys(UserStatus).map(key => ({
            value: UserStatus[key],
            label: key === "Deactivated" ? t("Inactive") : t(key),
          })),
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "appRole",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAppRoleTypeFilter({ name: "appRole" }),
      },
      {
        key: "firstName",
        header: t("First Name"),
        accessor: row => <TruncateTypography text={row?.firstName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.FirstName,
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: row => <TruncateTypography text={row?.lastName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.LastName,
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.Email,
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: (row: User) => {
          return (
            <UserActiveToggleButton
              disabled={!canManageUser || userId === row.id}
              id={row?.id as string}
              isActive={row?.isActive as boolean}
            />
          );
        },
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoined",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ];
  }, [t]);
};
