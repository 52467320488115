import { i18n } from "@toolkit/i18n";
import { payersPaths } from "./payersPaths";

export const payersTitleBreadcrumb = () => {
  return i18n.t("Payers", { ns: "admin" });
};

export const payersListBreadcrumb = () => {
  return {
    id: "payers-list",
    name: i18n.t("Payers", { ns: "admin" }),
    route: payersPaths.listPath.fullPath,
  };
};

export const payersNewBreadcrumb = () => {
  return {
    id: "payers-new",
    name: i18n.t("New Payer", { ns: "admin" }),
  };
};
export const payerEditBreadcrumb = (fieldName: string) => {
  return {
    id: payersPaths.editPath.fullPath,
    name: fieldName,
  };
};
