export const formGirdBreakPoints = {
  xs: 12,
  sm: 6,
  md: 4,
};
export const toggleGridBreakPoints = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
};

export const formGirdSpacing = 2;
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_FOR_TABLE_LONG = "DD MMMM YYYY | HH:mm A";
export const BACKEND_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
