import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import moment from "moment";
import { programActivityTitleBreadcrumb } from "pages/ProgramActivityTracking/constants/program-activity-breadcrumbs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useDoctorVisitHealthProgramTrackingQuery } from "../gql";
import { useProvidersActivityTrackingColumns } from "./useProvidersActivityTrackingColumns";

export const ProvidersActivityTrackingLists = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const params = useParams();

  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment().endOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    setBreadCrumb({
      title: programActivityTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='doctorVisitHealthProgramTracking'
      columns={useProvidersActivityTrackingColumns()}
      query={useDoctorVisitHealthProgramTrackingQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
      filterInput={{ fromDate, toDate, healthProgramId: Number(params?.id), doctorId: params?.doctorId }}
      variables={{
        filter: {
          fromDate: fromDate!,
          toDate: toDate!,
          healthProgramId: Number(params?.id),
          doctorId: params?.doctorId!,
        },
      }}
    >
      <PageWrapper filters={<FilterGrid />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
