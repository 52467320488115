/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMembershipRequestsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramMembershipRequestFilterInput>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.HealthProgramMembershipRequestSortingInput>;
}>;


export type HealthProgramMembershipRequestsQuery = { __typename?: 'Query', healthProgramMembershipRequests: { __typename?: 'HealthProgramMembershipRequestConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMembershipRequestCountableEdge', node: { __typename?: 'HealthProgramMembershipRequest', created?: any | null, id: string, insuranceId?: string | null, status?: Types.RequestStatus | null, healthProgramMember?: { __typename?: 'HealthProgramMember', coverageBy?: Types.CoverageByType | null, membershipEnd?: any | null, membershipStart?: any | null, insuranceClass?: string | null, insuranceCategory?: string | null, insurancePolicyNumber?: string | null } | null, user?: { __typename?: 'User', fullName?: string | null } | null } }> } };


export const HealthProgramMembershipRequestsDocument = gql`
    query HealthProgramMembershipRequests($filter: HealthProgramMembershipRequestFilterInput, $last: Int, $first: Int, $before: String, $after: String, $sortBy: HealthProgramMembershipRequestSortingInput) {
  healthProgramMembershipRequests(
    filter: $filter
    last: $last
    first: $first
    before: $before
    after: $after
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        created
        healthProgramMember {
          coverageBy
          membershipEnd
          membershipStart
          insuranceClass
          insuranceCategory
          insurancePolicyNumber
        }
        id
        insuranceId
        status
        user {
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMembershipRequestsQuery__
 *
 * To run a query within a React component, call `useHealthProgramMembershipRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMembershipRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMembershipRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramMembershipRequestsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMembershipRequestsQuery, HealthProgramMembershipRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMembershipRequestsQuery, HealthProgramMembershipRequestsQueryVariables>(HealthProgramMembershipRequestsDocument, options);
      }
export function useHealthProgramMembershipRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMembershipRequestsQuery, HealthProgramMembershipRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMembershipRequestsQuery, HealthProgramMembershipRequestsQueryVariables>(HealthProgramMembershipRequestsDocument, options);
        }
export type HealthProgramMembershipRequestsQueryHookResult = ReturnType<typeof useHealthProgramMembershipRequestsQuery>;
export type HealthProgramMembershipRequestsLazyQueryHookResult = ReturnType<typeof useHealthProgramMembershipRequestsLazyQuery>;
export type HealthProgramMembershipRequestsQueryResult = Apollo.QueryResult<HealthProgramMembershipRequestsQuery, HealthProgramMembershipRequestsQueryVariables>;