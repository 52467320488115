import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { AdminPromotionPublishStatusUpdateMutation, useAdminPromotionPublishStatusUpdateMutation } from "../gql";

export const TogglePromotionsPublishStatus: FC<{ id: string; isPublished: boolean; endDate: string }> = ({ id, isPublished, endDate }) => {
  const [checked, setChecked] = useState<boolean>(isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const isExpired = endDate && moment().isAfter(endDate);
  const [productsPublishStatusUpdateMutation, { loading }] = useAdminPromotionPublishStatusUpdateMutation({
    onCompleted: (data: AdminPromotionPublishStatusUpdateMutation, options) => {
      const responseData = data?.[options?.variables?.isPublish ? "publishMarketplacePromotion" : "unPublishMarketplacePromotion"];
      if (responseData?.id) {
        setChecked(responseData?.isPublished as boolean);
        succeeded(!checked ? t("Promotion published successfully!") : t("Promotion is now unpublished"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handlePublishPackage = () => {
    productsPublishStatusUpdateMutation({
      variables: {
        id: id,
        isPublish: !checked,
      },
    });
  };

  useEffect(() => {
    setChecked(isPublished);
  }, [isPublished]);

  return (
    <>
      <CustomToggleButton disabled={loading || !!isExpired} checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked
              ? t("Are you sure you want to un publish this promotion?")
              : t("Are you sure you want to publish this promotion?"),
          }}
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
