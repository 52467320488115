/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BenefitGroupCreateMutationVariables = Types.Exact<{
  input: Types.BenefitGroupInput;
}>;


export type BenefitGroupCreateMutation = { __typename?: 'Mutation', benefitGroupCreate?: { __typename?: 'BenefitGroupCRUD', healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, code: Types.H_EntityErrorCode, message?: string | null }> | null, benefitGroup?: { __typename?: 'BenefitGroup', id: string, name?: string | null, description?: string | null } | null } | null };


export const BenefitGroupCreateDocument = gql`
    mutation benefitGroupCreate($input: BenefitGroupInput!) {
  benefitGroupCreate(input: $input) {
    healthProgramErrors {
      field
      code
      message
    }
    benefitGroup {
      id
      name
      description
    }
  }
}
    `;
export type BenefitGroupCreateMutationFn = Apollo.MutationFunction<BenefitGroupCreateMutation, BenefitGroupCreateMutationVariables>;

/**
 * __useBenefitGroupCreateMutation__
 *
 * To run a mutation, you first call `useBenefitGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitGroupCreateMutation, { data, loading, error }] = useBenefitGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenefitGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<BenefitGroupCreateMutation, BenefitGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BenefitGroupCreateMutation, BenefitGroupCreateMutationVariables>(BenefitGroupCreateDocument, options);
      }
export type BenefitGroupCreateMutationHookResult = ReturnType<typeof useBenefitGroupCreateMutation>;
export type BenefitGroupCreateMutationResult = Apollo.MutationResult<BenefitGroupCreateMutation>;
export type BenefitGroupCreateMutationOptions = Apollo.BaseMutationOptions<BenefitGroupCreateMutation, BenefitGroupCreateMutationVariables>;