import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const UploadBulkFileDocument = gql `
    mutation UploadBulkFile($code: BulkTemplateCode!, $fileName: String!, $entityId: ID) {
  uploadBulkFile(code: $code, fileName: $fileName, entityID: $entityId) {
    id
    code
    errors {
      field
      message
    }
  }
}
    `;
/**
 * __useUploadBulkFileMutation__
 *
 * To run a mutation, you first call `useUploadBulkFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBulkFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBulkFileMutation, { data, loading, error }] = useUploadBulkFileMutation({
 *   variables: {
 *      code: // value for 'code'
 *      fileName: // value for 'fileName'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useUploadBulkFileMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadBulkFileDocument, options);
}
