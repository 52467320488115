import { replacePathParams } from "utils";

const permissionsManagementRoute = "/permissions-management";
const permissionsManagementNewRoute = "new";
const permissionsManagementEditRoute = "edit/:id";

export const permissionsManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${permissionsManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: permissionsManagementNewRoute,
    get fullPath() {
      return `${permissionsManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: permissionsManagementEditRoute,
    get fullPath() {
      return `${permissionsManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
