/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles } from "tss-react/mui";

export const useTableFooterStyles = makeStyles()(theme => ({
  root: {
    minHeight: 60,
    height: 60,
    background: theme.palette.stale[50],
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.stale[400],
  },
  result: {
    margin: "0px 10px",
  },
  dot: {
    margin: "0px 2px",
  },
  paginationButtons: {
    display: "flex",
    alignItems: "center",
  },
  nextIconButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    width: "81px",
    height: "35px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    opacity: 1,
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
    "&:clicked": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.5,
      color: theme.palette.common.white,
    },
  },
  prevIconButton: {
    backgroundColor: theme.palette.common.white,
    width: "81px",
    height: "35px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderRadius: "5px",
    opacity: 1,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    "&:clicked": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    "&:disabled": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      opacity: 0.5,
      color: theme.palette.primary[800],
    },
  },
  buttonIcons: {
    fontSize: `${theme.mixins.fonts.fontSize.xs}px !important`,
  },
  pageSizeContainer: {
    marginRight: 10,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pageSize: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    borderRadius: 10,
    width: 63,
    height: 30,
    fontSize: theme.mixins.fonts.fontSize.md,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    padding: 4,
  },
  pageSizeSelect: {
    padding: 0,
    textAlign: "center",
  },
  pageSelectIcon: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  columns: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
    marginRight: 10,
  },
}));
