import React from "react";
import { TFunction } from "../types";

export const patternEmail = (t: TFunction) => {
  return {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t("Please enter a valid email", {}),
  };
};

export const patternArabicText = {
  value: /[\s\S]*/,
  message: "Enter a valid Arabic name",
};
export const patternArabicTextWithSpecialCharAndNumbers = {
  // eslint-disable-next-line no-misleading-character-class
  value: /^[\u0621-\u064A\u0660-\u0669\u064B-\u0655\u0657\u0658\u0670\u0671 [0-9&*#٪/$!?؟|@%^"'_:!%^><»«(){}\]/\\\-+=,….;]+$/,
  message: "Enter a valid Arabic name",
};
export const patternMobileMessage = "Phone must be like : +9XXXXXXXXXXX";
export const mobileStartRegex = /^(\+|(00))/;
export const patternMobile = (t: TFunction) => {
  return {
    message: t(patternMobileMessage),
    value: /^(\+|(00))\d{12}$/,
  };
};

export const MIN_LENGTH_MESSAGE = "Minimum input length is {{ value }}";
export const MAX_LENGTH_MESSAGE = "Maximum input length is {{ value }}";

export const MIN_VALUE_MESSAGE = "Minimum value is {{ value }}";
export const MAX_VALUE_MESSAGE = "Maximum value is {{ value }}";

export const FIELD_IS_REQUIRED_MSG = "Required";
export const X_FIELD_IS_REQUIRED_MSG = "{{ value }} field is required";

export const INVALID_PATTERN_MSG = "Invalid input pattern";
export const NUMERIC_PATTERN_MSG = "Invalid input,must consist of Numeric digits only";

export const NumericPattern = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/g;

export const POSITIVE_NUMBER_MSG = "please enter positive number";
export const getValuedValidationFactory =
  (message: string) =>
  <T,>(t: TFunction, value: T) => {
    return { value: value, message: t(message, { value: value }) };
  };

export const getMinLengthValidation = getValuedValidationFactory(MIN_LENGTH_MESSAGE);
export const getMaxLengthValidation = getValuedValidationFactory(MAX_LENGTH_MESSAGE);

export const getPatternValidation = getValuedValidationFactory(INVALID_PATTERN_MSG);

export const getMinValueValidation = getValuedValidationFactory(MIN_VALUE_MESSAGE);
export const getMaxValueValidation = getValuedValidationFactory(MAX_VALUE_MESSAGE);

export const getRequiredValidation = getValuedValidationFactory(FIELD_IS_REQUIRED_MSG);
export const getNumericValidation = getValuedValidationFactory(NUMERIC_PATTERN_MSG);
export const getPositiveNumber = getValuedValidationFactory(POSITIVE_NUMBER_MSG);

export const minRequired = getValuedValidationFactory(MIN_VALUE_MESSAGE);
export const maxRequired = getValuedValidationFactory(MAX_VALUE_MESSAGE);
export const required = (t: TFunction) => getRequiredValidation(t, true);

export const validateValueIsNotEmpty = (value: string, t: TFunction) => {
  if (value?.trim() === "") {
    return t("This field is required");
  }

  return true;
};

export const getPatternMobileErrorMessage = (t: TFunction, errorMessage: string) => {
  if (errorMessage === patternMobileMessage) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t(patternMobileMessage, {
            phone: "<span class='mobile-phone-rtl-fix'>+9XXXXXXXXXXX</span>",
            interpolation: { escapeValue: false },
          }),
        }}
      />
    );
  } else return t(errorMessage);
};

export const toTitleCase: (value: string) => string = value => {
  return value.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};
