import { TFunction } from "@toolkit/i18n";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";

export const networkTitleBreadcrumb = (t: TFunction) => {
  return t("Networks", { ns: "admin" });
};

export const networkListBreadcrumb = (t: TFunction) => {
  return {
    id: "network-list",
    name: t("Networks", { ns: "admin" }),
    route: networksPaths.listPath.fullPath,
  };
};

export const networkNewBreadcrumb = (t: TFunction) => {
  return {
    id: "network-new",
    name: t("New", { ns: "admin" }),
  };
};

export const networkFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: `network-field-${fieldName}`,
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
