import { ActionFieldType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const actionFieldTypeOptionsMap = {
    [ActionFieldType.Boolean]: {
        key: ActionFieldType.Boolean,
        get label() {
            return i18n.t("Boolean", { ns: "domains" });
        },
        value: ActionFieldType.Boolean,
    },
    [ActionFieldType.Number]: {
        key: ActionFieldType.Number,
        get label() {
            return i18n.t("Number", { ns: "domains" });
        },
        value: ActionFieldType.Number,
    },
    [ActionFieldType.String]: {
        key: ActionFieldType.String,
        get label() {
            return i18n.t("String", { ns: "domains" });
        },
        value: ActionFieldType.String,
    },
    [ActionFieldType.List]: {
        key: ActionFieldType.List,
        get label() {
            return i18n.t("List", { ns: "domains" });
        },
        value: ActionFieldType.List,
    },
    [ActionFieldType.Date]: {
        key: ActionFieldType.Date,
        get label() {
            return i18n.t("Date", { ns: "domains" });
        },
        value: ActionFieldType.Date,
    },
};
export const actionFieldTypeOptions = Object.values(actionFieldTypeOptionsMap);
