import { jsx as _jsx } from "react/jsx-runtime";
import { useAllListFieldListQuery } from "../../../Sublists/gql";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";
export const FieldsAutocomplete = props => {
    var _a, _b, _c;
    const { defaultValue } = props;
    const { t } = useTranslation("domains");
    const methods = useFormContext();
    const { data, loading: isLoadingFields } = useAllListFieldListQuery();
    const fieldsOptionItems = (_a = data === null || data === void 0 ? void 0 : data.getAllListFields) !== null && _a !== void 0 ? _a : [];
    const { errors } = methods.formState;
    return (_jsx(AutocompleteController, { ControllerProps: {
            control: methods.control,
            name: "field",
            defaultValue: defaultValue,
            rules: {
                required: getRequiredValidation(t, true),
            },
        }, options: fieldsOptionItems, loading: isLoadingFields, isOptionEqualToValue: (o, v) => {
            return o === v || (o === null || o === void 0 ? void 0 : o.id) === (v === null || v === void 0 ? void 0 : v.id);
        }, getOptionLabel: o => o.display, defaultValue: defaultValue, TextFieldProps: {
            error: Boolean((_b = errors === null || errors === void 0 ? void 0 : errors.field) === null || _b === void 0 ? void 0 : _b["message"]),
            helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.field) === null || _c === void 0 ? void 0 : _c["message"],
            label: t("Field"),
        } }));
};
