import { makeStyles } from "tss-react/mui";

export const useOrderAutocompleteSearchStyle = makeStyles()(theme => ({
  textField: {
    width: 250,
  },
  rootAuto: {
    "& .MuiFilledInput-root": {
      minHeight: 43,
      padding: 0,
      fontSize: theme.mixins.fonts.fontSize.sm,
    },
    "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
      padding: "0 5px !important",
    },
  },
}));
