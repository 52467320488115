/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilitiesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.VendorOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FacilitiesQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string } }> } | null };


export const FacilitiesDocument = gql`
    query facilities($first: Int, $filter: VendorFilterInput, $after: String, $sortBy: VendorOrder, $before: String, $last: Int) {
  vendors(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useFacilitiesQuery__
 *
 * To run a query within a React component, call `useFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFacilitiesQuery(baseOptions?: Apollo.QueryHookOptions<FacilitiesQuery, FacilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, options);
      }
export function useFacilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilitiesQuery, FacilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, options);
        }
export type FacilitiesQueryHookResult = ReturnType<typeof useFacilitiesQuery>;
export type FacilitiesLazyQueryHookResult = ReturnType<typeof useFacilitiesLazyQuery>;
export type FacilitiesQueryResult = Apollo.QueryResult<FacilitiesQuery, FacilitiesQueryVariables>;