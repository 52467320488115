import { TFunction } from "@toolkit/i18n";
import { adminUserManagementPaths } from "pages/UserManagement/constants/admin-user-management-paths";

export const adminUserManagementTitleBreadcrumb = (t: TFunction) => {
  return t("Admin Users Management", { ns: "admin" });
};

export const adminUserManagementListBreadcrumb = (t: TFunction) => {
  return {
    id: "admin-user-management-list",
    name: t("Admin Users", { ns: "admin" }),
    route: adminUserManagementPaths.listPath.fullPath,
  };
};

export const adminUserManagementNewBreadcrumb = (t: TFunction) => {
  return {
    id: "admin-user-management-new",
    name: t("New", { ns: "admin" }),
  };
};

export const adminUserManagementFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "admin-user-management-field",
    name: fieldName,
  };
};
