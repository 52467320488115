const specializationManagement = "specialization-management";
const specialization = "specialization";
const newSpecialization = "new";
const editSpecialization = "update/:id";

export const specializationManagementRoutes = {
  name: "Specialization Management",
  path: specializationManagement,
  specializationInfo: {
    name: "Specialization information",
    path: specialization,
    fullPath: [specializationManagement, specialization].join("/"),
    new: {
      name: "new",
      path: [specialization, newSpecialization].join("/"),
      fullPath: [specializationManagement, specialization, newSpecialization].join("/"),
    },
    edit: {
      name: "Update",
      path: [specialization, editSpecialization].join("/"),
      fullPath: [specializationManagement, specialization, editSpecialization].join("/"),
    },
  },
};
