import { ReactNode } from "react";
import { CustomTableColumnProps } from "../GridProvider/types";

export interface IFilterProps<Z extends object> {
  title?: string;
  children?: ReactNode;
  fields: CustomTableColumnProps<Z>[];
}

export enum FieldUIComponentType {
  custom = "CUSTOM",
  checkbox = "CHECKBOX",
  autocomplete = "AUTOCOMPLETE",
  input = "INPUT",
  date = "DATE",
  dateTime = "dateTime",
}

export interface IFields<row extends object> {
  field: CustomTableColumnProps<row>;
}
