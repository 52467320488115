import { BenefitGroupInput, H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBenefitGroupCreateMutation } from "../../gql";
import { ProgramBenefitForm } from "./ProgramBenefitForm";

export const ProgramBenefitNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const [errors, setError] = useState<H_EntityError[]>([]);

  const [benefitGroupCreate] = useBenefitGroupCreateMutation({
    onCompleted: data => {
      const healthProgramErrors = data?.benefitGroupCreate?.healthProgramErrors!;
      if (healthProgramErrors?.length === 0) {
        succeeded(t("Benefit Group Created Successfully"));
        navigate(-1);
      } else {
        setError(healthProgramErrors);
        failed(formatMessageErrors(healthProgramErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });
  const handleSubmit = (data: BenefitGroupInput) => {
    benefitGroupCreate({
      variables: {
        input: data,
      },
    });
  };

  return <ProgramBenefitForm errors={errors} onDone={handleSubmit} />;
};
