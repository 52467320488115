import { makeStyles } from "tss-react/mui";

export const useCustomTransferListStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: "bold",
  },

  button: {
    width: "60px",
    height: "60px",
    background: theme.palette.common.white,
    borderRadius: "5px",
    border: "none",
    marginBottom: "10px",
  },
}));
export const useCustomListStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: "bold",
  },
  divider: {
    borderColor: theme.palette.primary.main,
    borderWidth: "1px",
  },
  errorCardStyle: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
}));
export const useCustomListComponentStyle = makeStyles()(theme => ({
  listItemText: {
    color: theme.palette.gray[900],
  },
  listItem: {
    "&.MuiListItem-root": {
      borderRadius: 0,
    },
  },
}));
