import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, H_EntityError } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Payers } from "shared/components";
import { TypeBenefits } from "./BenefitsType/TypeBenefits";
import { CareFor } from "./CareFor";
import { GeneralInformation } from "./GeneralInformation";
import { HealthProgramMemberLists } from "./HealthProgramMemberLists";
import { HealthProgramNetworks } from "./HealthProgramNetworks";
import { Images } from "./Images";
import { ProgramDescriptionSection } from "./ProgramDescription";
import { ProgramTrackingForm } from "./ProgramTrackingForm.component";
import { ProgramTypes } from "./ProgramTypes";
import { HealthProgramProps } from "./types";

const HealthProgramForm: FC<HealthProgramProps> = props => {
  const { errors, healthProgram, loading } = props;

  const { t } = useTranslation("admin");

  const { watch, setValue } = useFormContext();

  const payPerCallOnly = watch("payPerCallOnly") || healthProgram?.payPerCallOnly;

  useEffect(() => {
    if (healthProgram?.specializations) {
      const specializationsDefaultValues = healthProgram?.specializations?.map(item => item?.specialization);
      setValue("specializations", specializationsDefaultValues, { shouldValidate: true });
    }
  }, [healthProgram?.specializations, setValue]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormCard title={t("General Information")} loading={loading} doYouHaveData>
          <GeneralInformation errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Program Description")} loading={loading} doYouHaveData>
          <ProgramDescriptionSection errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Program Types")} loading={loading} doYouHaveData>
          <ProgramTypes errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Payer")} loading={loading} doYouHaveData>
          <Payers<H_EntityError> errors={errors} payer={healthProgram?.payer!} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Network")} loading={loading} doYouHaveData>
          <HealthProgramNetworks errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Specializations")} loading={loading} doYouHaveData>
          <SystemCodeAutocomplete name={"specializations"} multiple isCodeHidden filter={{ codeSystemCode: CodeSystemCode.Speciality }} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Types Of Care")} loading={loading} doYouHaveData>
          <CareFor errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>

      {!payPerCallOnly && (
        <Grid item xs={12}>
          <FormCard title={t("Benefits")} loading={loading} doYouHaveData>
            <TypeBenefits defaultValues={healthProgram} />
          </FormCard>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormCard title={t("Member Lists")} loading={loading} doYouHaveData>
          <HealthProgramMemberLists errors={errors} healthProgramMemberListsDefaultValues={healthProgram?.healthProgramMemberLists!} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Points Factors And Rates")} loading={loading} doYouHaveData>
          <ProgramTrackingForm errors={errors} healthProgram={healthProgram} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Images")} loading={loading} doYouHaveData>
          <Images errors={errors} defaultValues={healthProgram} />
        </FormCard>
      </Grid>
    </Grid>
  );
};

export default HealthProgramForm;
