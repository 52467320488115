import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FilterDialog } from "./FilterDialog";
import { StaticFilterDialogProps } from "./StaticFilterDialog.types";

export const StaticFilterDialog = <X extends object>(props: StaticFilterDialogProps<X>) => {
  const methods = useForm<Record<string, unknown>>();

  return (
    <FormProvider {...methods}>
      <FilterDialog {...props} />
    </FormProvider>
  );
};
