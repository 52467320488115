import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { categoriesBreadcrumb } from "../../constants";
import { categoriesPaths } from "../../constants";
import { CategoryUpsertForm } from "../../forms";
import {
  MarketplaceProductCategoryUpdateMutation,
  useMarketplaceProductCategoryQuery,
  useMarketplaceProductCategoryUpdateMutation,
} from "../../gql";
import { ICategoryUpdateContainerParams } from "../../types";
import { convertCategoryFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICategoryUpsertFormValues } from "../../forms/CategoryFormUpsert/CategoryUpsertFormSchema";

export const CategoryUpdateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const { categoryId } = useParams<ICategoryUpdateContainerParams>();

  const { data, loading } = useMarketplaceProductCategoryQuery({
    variables: { marketplaceProductCategoryId: categoryId! },
    skip: !categoryId,
  });
  const categoryData = data?.marketplaceProductCategory;
  const parent = categoryData?.parent;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchCategoryUpdateMutation, { loading: isSubmitting }] = useMarketplaceProductCategoryUpdateMutation({
    onCompleted: (data: MarketplaceProductCategoryUpdateMutation) => {
      if (data?.marketplaceProductCategoryUpdate?.id) {
        succeeded(t("Category updated successfully!"));
        !parent?.id
          ? navigate(categoriesPaths.main.fullPath)
          : navigate(categoriesPaths.productCategoryCategories.fullPathWithParams({ categoryId: parent?.id }));
      } else {
        failed(t("Category update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onCategoryUpsertFormChange = (values: ICategoryUpsertFormValues) => {
    const _values = convertCategoryFormValuesToBackEndValues(values);

    fetchCategoryUpdateMutation({
      variables: {
        marketplaceProductCategoryUpdateId: categoryId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, category } = categoriesBreadcrumb();
    setBreadCrumb({
      title,
      values: [
        parent?.id ? category(pickLocalizedValue(parent?.name!, parent?.nameAr!), parent?.id!) : main,
        category(pickLocalizedValue(categoryData?.name!, categoryData?.nameAr!), categoryData?.id!),
      ],
    });
  }, [setBreadCrumb, categoryData, t, parent?.id, parent?.name, parent?.nameAr]);
  return (
    <CategoryUpsertForm
      isLoading={loading}
      category={categoryData!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onCategoryUpsertFormChange}
    />
  );
};
