import { makeStyles } from "tss-react/mui";

export const useTimelineStyles = makeStyles()(theme => ({
  iconContainer: {
    padding: 0,
  },
  year: {
    fontSize: theme.mixins.fonts.fontSize.xxs,
    fontWeight: 600,
    padding: "0 !important",
    position: "absolute",
    right: -20,
    bottom: 0,
    height: 22,
    minWidth: 50,
    borderRadius: 15,
    "& svg": {
      width: 14,
      height: 23,
    },
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
  },
  prevYear: {
    left: -15,
    right: "auto !important",
  },
  centerFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  stepIcon: {
    borderLeft: `2px solid ${theme.palette.stale.main}`,
    height: "36px",
  },
  stepLabel: {
    alignItems: "end",
    padding: 0,
  },
  activities: {
    alignContent: "baseline",
    display: "flex",
    flexDirection: "row",
    marginBlock: 20,
    height: "300px",
    flexWrap: "wrap-reverse",
    width: "100px",
    borderRadius: "15px",
    alignItems: "center",
    padding: 6,
  },
  activitiesMonth: {
    display: "flex",
    height: "300px",
    marginBlock: 20,
    flexDirection: "column-reverse",
    alignItems: "center",
    width: "70px",
  },
  monthly: {
    padding: 0,
    height: 340,
    width: "105px",
    alignItems: "flex-start",
  },
  appointmentIcon: {
    borderRadius: 10,
    margin: "5px 2px",
    padding: "4px",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  type: {
    padding: "6px",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  separate: {
    borderLeft: `2px solid ${theme.palette.primary[300]}`,
    // marginInline: "9px",
    height: "20px",
  },
  step: {
    padding: "0px",
    display: "flex",
    alignItems: "flex-end",
  },
  btn: {
    width: 25,
    height: 25,
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary[300],
    },
  },
  separateWeek: {
    borderLeft: `2px solid ${theme.palette.primary[300]}`,
    height: "23px",
  },
}));
