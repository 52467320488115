/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloneSurveyMutationVariables = Types.Exact<{
  cloneSurveyId: Types.Scalars['ID'];
  label: Types.Scalars['String'];
}>;


export type CloneSurveyMutation = { __typename?: 'Mutation', cloneSurvey?: { __typename?: 'Survey', id?: string | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const CloneSurveyDocument = gql`
    mutation CloneSurvey($cloneSurveyId: ID!, $label: String!) {
  cloneSurvey(id: $cloneSurveyId, label: $label) {
    id
    errors {
      code
      errorType
      field
      message
    }
  }
}
    `;
export type CloneSurveyMutationFn = Apollo.MutationFunction<CloneSurveyMutation, CloneSurveyMutationVariables>;

/**
 * __useCloneSurveyMutation__
 *
 * To run a mutation, you first call `useCloneSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSurveyMutation, { data, loading, error }] = useCloneSurveyMutation({
 *   variables: {
 *      cloneSurveyId: // value for 'cloneSurveyId'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useCloneSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CloneSurveyMutation, CloneSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSurveyMutation, CloneSurveyMutationVariables>(CloneSurveyDocument, options);
      }
export type CloneSurveyMutationHookResult = ReturnType<typeof useCloneSurveyMutation>;
export type CloneSurveyMutationResult = Apollo.MutationResult<CloneSurveyMutation>;
export type CloneSurveyMutationOptions = Apollo.BaseMutationOptions<CloneSurveyMutation, CloneSurveyMutationVariables>;