import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import FieldInput from "./FieldInput";
import EditAdminManagedListFormSkeleton from "./PageSkeleton";
import { useEditCentralListItemStyles } from "./styles";
import { useEditAdminManagedListDetailsHook } from "./useEditAdminManagedListDetailsHook";
const EditAdminManagedListDetailsPage = () => {
    const { methods, isLoading, isFetchingUpdate, conceptFieldsDefenitions, codeDisplayName, isChanged, navigate, onSubmit } = useEditAdminManagedListDetailsHook();
    const handleCancel = () => {
        navigate(-1);
    };
    const { t } = useTranslation("domains");
    const { classes } = useEditCentralListItemStyles();
    return (_jsx(PageWrapper, { actions: _jsx(FormActions, { loadingIndicators: {
                edit: isFetchingUpdate,
            }, hasEdit: true, hasCancel: true, onEditItem: methods.handleSubmit(onSubmit), onNavigation: handleCancel, isChanged: isChanged }), children: _jsx(FormCard, { doYouHaveData: !isLoading && conceptFieldsDefenitions.length > 0, loading: isLoading, title: t("Edit") + " " + codeDisplayName, children: isLoading ? (_jsx(EditAdminManagedListFormSkeleton, {})) : (_jsx(FormProvider, Object.assign({}, methods, { children: _jsx("div", { className: classes.root, children: _jsx(Grid, { container: true, spacing: 2, children: conceptFieldsDefenitions === null || conceptFieldsDefenitions === void 0 ? void 0 : conceptFieldsDefenitions.map(fieldDefinition => {
                            return (_jsx(Grid, { container: true, alignItems: "center", item: true, xs: 12, sm: 6, spacing: 1, children: _jsx(Grid, { flex: 1, item: true, children: _jsx(FieldInput, { fieldDefenition: fieldDefinition }) }) }, fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.code));
                        }) }) }) }))) }) }));
};
export default EditAdminManagedListDetailsPage;
