import { Components, Theme } from "@mui/material/styles";

export const MuiCardOverride: Components<Theme>["MuiCard"] = {
  defaultProps: {
    elevation: 1,
  },
  styleOverrides: {
    root: {
      boxShadow: "unset",
      borderRadius: 10,
      border: "1.5px solid #C7D6EE",
    },
  },
};
