import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormHelperText, Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { WorkingHourProps } from "../BranchesForm.types";
export const BranchWorkingHours: FC<WorkingHourProps> = props => {
  const { t } = useTranslation("admin");
  const { loading, doYouHaveBranch, branch, errorsInput } = props;
  const { formState } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errorsInput);
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <WorkingHours
        inputName='workingHours'
        isLoading={loading}
        doYouHaveData={doYouHaveBranch}
        workingHoursDefaultValue={branch?.workingHours}
        errorText={
          <FormHelperText error={Boolean(combinedErrors?.workingHours?.message)}>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight='bold' color={theme.palette.secondary.main}>
              {t(combinedErrors?.workingHours?.message)}
            </Typography>
          </FormHelperText>
        }
      />
    </Grid>
  );
};
