export const commonColors = {
  // Raven
  BackgroundColor: "#F4F7FC",
  Transparent: "transparent",
  TransparentBlack: "#0000003d",
  TextPrimary: "#057cc1",
  // common
  White: "#ffffff",
  Black: "#000000",
};
