/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadSubListQueryVariables = Types.Exact<{
  subListId: Types.Scalars['ID'];
}>;


export type DownloadSubListQuery = { __typename?: 'Query', downloadSubList?: string | null };


export const DownloadSubListDocument = gql`
    query downloadSubList($subListId: ID!) {
  downloadSubList(subListId: $subListId)
}
    `;

/**
 * __useDownloadSubListQuery__
 *
 * To run a query within a React component, call `useDownloadSubListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadSubListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadSubListQuery({
 *   variables: {
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useDownloadSubListQuery(baseOptions: Apollo.QueryHookOptions<DownloadSubListQuery, DownloadSubListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadSubListQuery, DownloadSubListQueryVariables>(DownloadSubListDocument, options);
      }
export function useDownloadSubListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadSubListQuery, DownloadSubListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadSubListQuery, DownloadSubListQueryVariables>(DownloadSubListDocument, options);
        }
export type DownloadSubListQueryHookResult = ReturnType<typeof useDownloadSubListQuery>;
export type DownloadSubListLazyQueryHookResult = ReturnType<typeof useDownloadSubListLazyQuery>;
export type DownloadSubListQueryResult = Apollo.QueryResult<DownloadSubListQuery, DownloadSubListQueryVariables>;