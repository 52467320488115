import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaEditListDocument = gql `
    query OptimaEditList($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaEditFilterInput) {
  optimaEdits(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        code
        category
        subCategory
        source
        priority
        isActive
        actions {
          id
          action
        }
      }
    }
  }
}
    `;
/**
 * __useOptimaEditListQuery__
 *
 * To run a query within a React component, call `useOptimaEditListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaEditListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOptimaEditListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaEditListDocument, options);
}
export function useOptimaEditListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaEditListDocument, options);
}
