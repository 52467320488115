import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SublistDownloadDocument = gql `
    query SublistDownload($subListId: ID!) {
  downloadSubList(subListId: $subListId)
}
    `;
/**
 * __useSublistDownloadQuery__
 *
 * To run a query within a React component, call `useSublistDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSublistDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSublistDownloadQuery({
 *   variables: {
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useSublistDownloadQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SublistDownloadDocument, options);
}
export function useSublistDownloadLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SublistDownloadDocument, options);
}
