import { replacePathParams } from "utils";

export const providersManagementRoute = "/health-providers";
const providersManagementNewRoute = "new";
const providersManagementEditRoute = "edit/:id";
const providersManagementUsersRoute = ":id/provider-users";
const providersManagementBranchesRoute = "branches/:id";
const providersManagementBranchesNewRoute = `${providersManagementBranchesRoute}/new`;
const providersManagementBranchesEditRoute = `${providersManagementBranchesRoute}/edit/:idBranch`;
const providersManagementBranchesUserRoute = `${providersManagementBranchesRoute}/branch-user/:idBranch`;

export const providersManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${providersManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: providersManagementNewRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: providersManagementEditRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  usersPath: {
    route: providersManagementUsersRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  branchesPath: {
    route: providersManagementBranchesRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  branchesNewPath: {
    route: providersManagementBranchesNewRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  branchesEditPath: {
    route: providersManagementBranchesEditRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, idBranch: string) {
      return replacePathParams(this.fullPath, { id, idBranch });
    },
  },
  branchesUsersPath: {
    route: providersManagementBranchesUserRoute,
    get fullPath() {
      return `${providersManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, idBranch: string) {
      return replacePathParams(this.fullPath, { id, idBranch });
    },
  },
};
