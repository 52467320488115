import { i18n } from "@toolkit/i18n";
import { BarChartIcon } from "@toolkit/ui";
import { dashboardPaths, dashboardRoute } from "pages/Dashboard/constants/dashboard-paths";
import Dashboard from "pages/Dashboard/Dashboard";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const dashboardRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "dashboard-routes",
    text: i18n.t("Dashboard", { ns: "admin" }),
    route: dashboardRoute,
    fullPath: dashboardPaths.mainPath.fullPath,
    icon: <BarChartIcon />,
    element: <Dashboard />,
    onClick: (route: string) => navigate(route),
  };
};
