import { makeStyles } from "tss-react/mui";

export const useEmptyCardStyles = makeStyles()(() => ({
  mainDiv: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
}));
