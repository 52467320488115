import React, { FC } from "react";
import { type ButtonProps, IconButton } from "../../../../base/mui";
import { MDEditorUploadIcon } from "../../../../icons";

interface CustomCommandButtonProps extends ButtonProps {}

const UploadImageCommandButton: FC<CustomCommandButtonProps> = ({ ...props }) => (
  <IconButton
    sx={{
      top: "0",
      width: "20px",
      height: "20px",
      verticalAlign: "text-bottom",
      "& svg": {
        width: "12px",
        height: "12px",
        marginTop: "1px",
      },
    }}
    {...props}
  >
    <MDEditorUploadIcon />
  </IconButton>
);

export default UploadImageCommandButton;
