import { CityInput } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CityErrorsFragment, CityFragmentFragment } from "../gql";
import { CityInformation } from "./components/CityInformation.component";

export const CitiesManagementForm: FC<{
  errors: CityErrorsFragment[];
  isLoading: boolean;
  defaultValues?: CityFragmentFragment;
  onDone: (requestInput: CityInput) => void;
  isEditMode?: boolean;
  isSubmitting?: boolean;
}> = ({ errors, isLoading, defaultValues, onDone, isEditMode, isSubmitting }) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const methods = useForm<{ name: string; nameAr: string; area: string }>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const handleCancel = () => navigate(-1);

  const doYouHaveData = isEditMode ? defaultValues != undefined : true;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onDone)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={isLoading}
              hasCancel
              hasFormButton
              formButtonTitle={t("Save")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} title={t("City Information")} doYouHaveData={doYouHaveData}>
                <CityInformation defaultValues={defaultValues} errors={errors} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
CitiesManagementForm.defaultProps = {
  defaultValues: undefined,
  isEditMode: false,
};
