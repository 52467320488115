import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ConsumerViewPreferenceSaveDocument = gql `
    mutation consumerViewPreferenceSave($input: ConsumerViewPreferenceInput!) {
  consumerViewPreferenceSave(input: $input) {
    consumerViewPreference {
      id
      user {
        id
      }
      view
      data
    }
    consumerViewPreferenceErrors {
      field
      message
      code
    }
  }
}
    `;
/**
 * __useConsumerViewPreferenceSaveMutation__
 *
 * To run a mutation, you first call `useConsumerViewPreferenceSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerViewPreferenceSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerViewPreferenceSaveMutation, { data, loading, error }] = useConsumerViewPreferenceSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerViewPreferenceSaveMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ConsumerViewPreferenceSaveDocument, options);
}
