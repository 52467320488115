/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceNetworkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type InsuranceNetworkQuery = { __typename?: 'Query', insuranceNetwork?: { __typename?: 'InsuranceNetwork', id: string, name?: string | null, created?: any | null, isActive?: boolean | null, facilities?: { __typename?: 'InsuranceNetworkFacilityCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkFacilityCountableEdge', node: { __typename?: 'InsuranceNetworkFacility', id: string } }> } | null } | null };


export const InsuranceNetworkDocument = gql`
    query insuranceNetwork($id: ID!) {
  insuranceNetwork(id: $id) {
    id
    name
    created
    facilities(first: 10) {
      edges {
        node {
          id
        }
      }
    }
    isActive
  }
}
    `;

/**
 * __useInsuranceNetworkQuery__
 *
 * To run a query within a React component, call `useInsuranceNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceNetworkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuranceNetworkQuery(baseOptions: Apollo.QueryHookOptions<InsuranceNetworkQuery, InsuranceNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceNetworkQuery, InsuranceNetworkQueryVariables>(InsuranceNetworkDocument, options);
      }
export function useInsuranceNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceNetworkQuery, InsuranceNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceNetworkQuery, InsuranceNetworkQueryVariables>(InsuranceNetworkDocument, options);
        }
export type InsuranceNetworkQueryHookResult = ReturnType<typeof useInsuranceNetworkQuery>;
export type InsuranceNetworkLazyQueryHookResult = ReturnType<typeof useInsuranceNetworkLazyQuery>;
export type InsuranceNetworkQueryResult = Apollo.QueryResult<InsuranceNetworkQuery, InsuranceNetworkQueryVariables>;