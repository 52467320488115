import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { AutocompleteController } from "@toolkit/ui";
import { IFormAutocomplete } from "../types";

export const PayerAutocomplete: FC<IFormAutocomplete> = ({ errors, defaultValue, name, placeholder, options, isDisabled }) => {
  const { t } = useTranslation("admin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);

  return (
    <AutocompleteController
      ControllerProps={{
        name: name,
        control: control,
        defaultValue: defaultValue,
      }}
      TextFieldProps={{
        placeholder: placeholder,
        error: Boolean(combinedErrors?.[name]?.message),
        helperText: t(combinedErrors?.[name]?.message),
        label: placeholder,
      }}
      disabled={isDisabled}
      getOptionLabel={option => t(option?.label)}
      options={options}
    />
  );
};
