import { DecisionListContainer } from "@health/domains";
import { Decision, DecisionPlanCategory } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { kebabCase } from "lodash";
import { decisionsBreadcrumbs, decisionsPaths } from "pages/Decisions/constants";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type AdminDecisionListContainerProps = {
  category: DecisionPlanCategory;
};

export const AdminDecisionListContainer: FC<AdminDecisionListContainerProps> = props => {
  const { category } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const _category = kebabCase(category);

  const onAddNewClick = () => {
    navigate(decisionsPaths.new.fullPathWithParams({ category: _category }));
  };

  const onEditRowClick = (item: Decision) => {
    if (!item) return;
    navigate(decisionsPaths.edit.fullPathWithParams({ category: _category, id: item.id! }));
  };

  useEffect(() => {
    const { title } = decisionsBreadcrumbs();

    setBreadCrumb({ title: title(category) });
  }, [category, setBreadCrumb, t]);

  return <DecisionListContainer category={category} onAddNewClick={onAddNewClick} onEditRowClick={onEditRowClick} />;
};
