import { i18n } from "@toolkit/i18n";
import { healthPackageCategoriesPaths } from "./CategoriesPaths";

export const healthPackageCategoriesBreadcrumb = () => ({
  title: i18n.t("Health Package Categories", { ns: "admin" }),
  main: {
    id: "healthPackageCategories",
    name: i18n.t("Health Package Categories", { ns: "admin" }),
    route: healthPackageCategoriesPaths.main.fullPath,
  },
  create: {
    id: "category-new",
    name: i18n.t("New", { ns: "admin" }),
  },
  category: (fieldName: string, categoryId: string) => ({
    id: "category-update",
    name: fieldName || i18n.t("Category", { ns: "admin" }),
    route: healthPackageCategoriesPaths.healthPackageCategoryCategories.fullPathWithParams({ categoryId: categoryId }),
  }),
});
