import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IFormData, IPayerForm } from "./types";
import { GeneralInformation } from "./components/GeneralInformation";
import { ImgUploader } from "./components/ImgUploader.component";
import { payersPaths } from "../Constants/payersPaths";

export const PayerForm: FC<IPayerForm> = props => {
  const { t } = useTranslation("admin");
  const { errors, defaultValue, loading, onSubmit: handleSubmitData, isSubmitting } = props;
  const navigate = useNavigate();
  const methods = useForm<IFormData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const handleCancel = () => {
    navigate(payersPaths.listPath.fullPath);
  };

  const onSubmit: SubmitHandler<IFormData> = data => {
    handleSubmitData(data as IFormData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={defaultValue ? t("Update") : t("Create")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData>
                <GeneralInformation errors={errors} defaultValue={defaultValue} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Upload image")} loading={loading} doYouHaveData>
                <ImgUploader defaultValue={defaultValue} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
