import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetCodeSystemVersionsDocument = gql `
    query getCodeSystemVersions($after: String, $code: CodeSystemCode, $first: Int, $isActive: Boolean) {
  getCodeSystems(first: $first, after: $after, code: $code, isActive: $isActive) {
    edges {
      node {
        id
        display
        isActive
        version
        processingStatus
        codeSystemHistory {
          id
          processingStatus
          updateDate
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useGetCodeSystemVersionsQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemVersionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      code: // value for 'code'
 *      first: // value for 'first'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetCodeSystemVersionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCodeSystemVersionsDocument, options);
}
export function useGetCodeSystemVersionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCodeSystemVersionsDocument, options);
}
