import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const MarketplaceHealthPackageCategoryAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const MarketplaceHealthPackageCategoryAutocompleteSchemas = {
    MarketplaceHealthPackageCategoryAutocomplete,
    MarketplaceHealthPackageCategoryAutocompleteOptional: MarketplaceHealthPackageCategoryAutocomplete.nullable().optional(),
};
