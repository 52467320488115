/* eslint-disable max-statements */
import { useTranslation } from "@toolkit/i18n";
import { produce } from "immer";
import { get, set } from "lodash";
import { Moment } from "moment";
import moment from "moment-timezone";
import React, { FC, useEffect, useState } from "react";
import { Grid, Typography } from "../../base/mui";
import WorkingHoursComponent from "../WorkingHours";
import { DAYS } from "./constants";
import { useStyles } from "./styles";
import { WorkingHoursCardProps } from "./types";
import { getMomentTime } from "./utils";

const noop = () => {
  void 0;
};
const WorkingHoursCard: FC<WorkingHoursCardProps> = ({ onChange = noop, workingHours, withTitle = true, onError = noop, errorText }) => {
  const { classes } = useStyles();
  const userTz = moment.tz.guess();
  const { t } = useTranslation();
  const [daysErrors, setDaysErrors] = useState({});
  const isError = Object.values(daysErrors).some(dayMessage => dayMessage != "");
  useEffect(() => {
    onError(isError);
  }, [isError]);

  const handleAddTime = (dayIndex: number, index: number) => {
    const newWeekDays = [...workingHours!];
    const day = { ...newWeekDays[dayIndex] };
    day.openTimeRanges = day.openTimeRanges?.slice();
    day.openTimeRanges?.splice(index + 1, 0, {
      id: `${Math.random() * 1000}`,
      openTime: "",
      closeTime: "",
    });
    set(newWeekDays, dayIndex, day);
    onChange(newWeekDays);
  };
  const handleTimeChange = (timeKey: string, dayIndex: number, newTime: Moment, indexWorkingHours: number) => {
    onChange(
      produce(workingHours, draft => {
        set(
          draft!,
          `${dayIndex}.openTimeRanges.${indexWorkingHours}.${timeKey}`,
          newTime ? moment.tz(newTime, userTz).format("HH:mm:ss") : null
        );
      })!
    );
  };
  const handleDays = (dayIndex: number, index: number) => {
    if (!workingHours) return;
    onChange(
      produce(workingHours, draft => {
        (get(draft, `${dayIndex}.openTimeRanges`)! as any[])?.splice(index, 1);
      })
    );
  };
  const handleRemoveTime = (dayIndex: number, index: number) => {
    if (workingHours && get(workingHours, `${dayIndex}.openTimeRanges.length`) === 1) {
      onChange(
        produce(workingHours, draft => {
          set(draft, `${dayIndex}.openTimeRanges.0.openTime`, null);
          set(draft, `${dayIndex}.openTimeRanges.0.closeTime`, null);
        })
      );
      return;
    }
    handleDays(dayIndex, index);
  };

  return (
    <Grid container>
      {errorText}
      {withTitle && (
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.cardTitle}>{t("Working Hours")}</Typography>
        </Grid>
      )}
      {workingHours?.map((workingDay, dayIndex) => (
        <React.Fragment key={dayIndex}>
          {workingDay?.openTimeRanges?.map((timeRange, timeIndex) => {
            return (
              <Grid key={`${workingDay.day}_${timeRange?.id}`} item xs={12} className={classes.day}>
                <WorkingHoursComponent
                  onTimeChange={(date: Moment, type) => handleTimeChange(type, dayIndex, date, timeIndex)}
                  min={getMomentTime(workingDay?.openTimeRanges?.[timeIndex - 1]?.closeTime!)}
                  labelInputOne={t("Opening")}
                  labelInputTwo={t("Closing")}
                  closeTime={timeRange?.closeTime!}
                  openTime={timeRange?.openTime!}
                  dayName={timeIndex > 0 ? "" : DAYS(t)?.[workingDay?.day!]}
                  onRemoveTime={() => handleRemoveTime(dayIndex, timeIndex)}
                  isLastItem={timeIndex === workingDay?.openTimeRanges?.length! - 1}
                  onAddTime={() => handleAddTime(dayIndex, timeIndex)}
                  setDaysErrors={setDaysErrors}
                  daysErrors={daysErrors}
                />
              </Grid>
            );
          })}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default WorkingHoursCard;
