import { useTranslation } from "@toolkit/i18n";
import { Grid, ImageUploadController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { IProgramProps } from "./types";

export const Images: FC<IProgramProps> = ({ defaultValues }) => {
  const { t } = useTranslation("admin");
  const { control } = useFormContext();
  const { accessToken } = useOidcUserProfile();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageUploadController
            hasMultipleImages
            control={control}
            label={t("Program icon")}
            defaultValue={defaultValues?.images ? defaultValues?.images : null}
            name='images'
            rules={{
              value: false,
            }}
            token={accessToken!}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
