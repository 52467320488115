/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkAllNotificationsAsSeenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type MarkAllNotificationsAsSeenMutation = { __typename?: 'Mutation', markAllNotificationsAsSeen?: { __typename?: 'MarkAllNotificationsAsSeenResponse', success?: boolean | null, errors?: Array<{ __typename?: 'SubscriptionGraphqlError', code?: Types.SubscriptionErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const MarkAllNotificationsAsSeenDocument = gql`
    mutation markAllNotificationsAsSeen {
  markAllNotificationsAsSeen {
    success
    errors {
      code
      errorType
      field
      message
    }
  }
}
    `;
export type MarkAllNotificationsAsSeenMutationFn = Apollo.MutationFunction<MarkAllNotificationsAsSeenMutation, MarkAllNotificationsAsSeenMutationVariables>;

/**
 * __useMarkAllNotificationsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsSeenMutation, { data, loading, error }] = useMarkAllNotificationsAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsAsSeenMutation, MarkAllNotificationsAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllNotificationsAsSeenMutation, MarkAllNotificationsAsSeenMutationVariables>(MarkAllNotificationsAsSeenDocument, options);
      }
export type MarkAllNotificationsAsSeenMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsSeenMutation>;
export type MarkAllNotificationsAsSeenMutationResult = Apollo.MutationResult<MarkAllNotificationsAsSeenMutation>;
export type MarkAllNotificationsAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsAsSeenMutation, MarkAllNotificationsAsSeenMutationVariables>;