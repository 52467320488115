import { replacePathParams } from "utils";

export const membersRoute = "/members";
const membersNewRoute = "new";
const membersEditRoute = "edit/:memberId";

export const membersPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${membersRoute}${this.route}`;
    },
  },
  newPath: {
    route: membersNewRoute,
    get fullPath() {
      return `${membersRoute}/${this.route}`;
    },
  },
  editPath: {
    route: membersEditRoute,
    get fullPath() {
      return `${membersRoute}/${this.route}`;
    },
    fullPathWithParams: function (memberId: string) {
      return replacePathParams(this.fullPath, { memberId });
    },
  },
};
