import { CoverageByType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const coverageByTypeOptionsMap = {
    [CoverageByType.Cash]: {
        key: CoverageByType.Cash,
        get label() {
            return i18n.t("Cash", { ns: "domains" });
        },
        value: CoverageByType.Cash,
    },
    [CoverageByType.Insurance]: {
        key: CoverageByType.Insurance,
        get label() {
            return i18n.t("Insurance", { ns: "domains" });
        },
        value: CoverageByType.Insurance,
    },
    [CoverageByType.Provider]: {
        key: CoverageByType.Provider,
        get label() {
            return i18n.t("Provider", { ns: "domains" });
        },
        value: CoverageByType.Provider,
    },
    [CoverageByType.Tpa]: {
        key: CoverageByType.Tpa,
        get label() {
            return i18n.t("Tpa", { ns: "domains" });
        },
        value: CoverageByType.Tpa,
    },
};
export const coverageByTypeOptions = Object.values(coverageByTypeOptionsMap);
