import { jsx as _jsx } from "react/jsx-runtime";
import { GridProvider } from "../../../../shared/components";
import { useSublistItemDeleteMutation, useSublistItemListQuery } from "../../../Sublists/gql";
import { TableGrid } from "@toolkit/ui";
import { useSublistItemListColumnsContainer } from "./useSublistItemListColumnsContainer";
export const SublistItemList = props => {
    const { id, onEditItemRowClick } = props;
    return (_jsx(GridProvider, { hasTableSetting: true, gridName: 'sublistItemList', columns: useSublistItemListColumnsContainer(), query: useSublistItemListQuery, variables: { id }, tableAction: {
            isEditVisible: true,
            isDeleteVisible: true,
            onEditRow: onEditItemRowClick,
        }, deleteItemProps: {
            useDeleteMutation: useSublistItemDeleteMutation,
            entityTypeName: "SubListItem",
            name: "SubList",
        }, children: _jsx(TableGrid, {}) }));
};
