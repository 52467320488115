import { SimplePaletteColorOptions } from "../../../base/mui";

export const gray: SimplePaletteColorOptions = {
  // Raven
  main: "#6b7280",
  50: "#f7f8f9",
  100: "#f0f1f2",
  200: "#e8eaec",
  300: "#d2d4d9",
  400: "#c3c6cd",
  500: "#b4b8c0",
  600: "#acb1ba",
  700: "#969ca7",
  800: "#878e9b",
  900: "#787f8e",
  1100: "#606673",
  1200: "#555b66",
  1300: "#4b5059",
  1400: "#40444d",
  1500: "#353940",
  1600: "#2b2e33",
  1700: "#202226",
  1800: "#15171a",
  1900: "#0b0b0d",
};
