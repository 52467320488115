/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles } from "tss-react/mui";

export const useCommonCardStyles = makeStyles()(theme => ({
  root: {
    padding: "15px",
  },
  card: {
    color: theme.palette.primary.main,
    margin: "0 auto",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    height: "380px",
    boxShadow: theme.mixins.shadows.md,
    borderRadius: "10px",
  },
  gutterBottom: {
    color: theme.palette.primary.main,
    padding: "10px 0px 0px 5px",
    fontSize: theme.mixins.fonts.fontSize.xl,
  },
  subGutter: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.lg,
    width: "304px",
    height: "42px",
    margin: "0 auto",
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  subGutter2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.md,
    width: "fit-content",
    height: "21px",
    margin: "0 auto",
    paddingTop: "30px",
    left: "497px",
    paddingBottom: "7px",
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  headerIcon: {
    paddingTop: "10px",
  },
  button: {
    background: theme.palette.primary.main,
    padding: "10px",
    color: theme.palette.common.white,
    margin: "0px 18px",
    marginBottom: "10px",
    // width: "180px",
    width: "fit-content",
    height: " 40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  container: {
    color: theme.palette.primary.main,
    padding: "10px",
    fontSize: theme.mixins.fonts.fontSize.xl,
    margin: "10px",
  },
  divider: {
    background: theme.palette.gray.light,
    marginTop: "5px",
    width: "calc(100% - 50px)",
    marginInline: "auto",
  },
  header: {},
}));
