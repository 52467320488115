import { produce } from "immer";
import { get } from "lodash";
import { CityErrorsFragment } from "pages/CitiesManagement/gql";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useGetBackendErrorsForInputsValues = (errors: CityErrorsFragment[]) => {
  const [backendErrorStorage, setBackendErrorsStorage] = useState({
    name: {},
    nameAr: {},
    area: {},
  });
  const { watch } = useFormContext();
  const formFieldsValues = watch();
  useEffect(() => {
    if (!errors.length) return;
    setBackendErrorsStorage(
      produce(backendErrorStorage, draft => {
        errors
          ?.filter(error => ["name", "Area", "nameAr"].includes(error?.field || ""))
          ?.forEach(error => {
            const fieldName = error?.field || "";
            const fieldValue = get(formFieldsValues, fieldName) || "";
            draft[fieldName][fieldValue] = error?.message;
          });
      })
    );
  }, [errors]);
  return {
    backendErrorStorage,
  };
};
