import { SimplePaletteColorOptions } from "../../../base/mui";

export const midnightBlue: SimplePaletteColorOptions = {
  // Midnight Blue
  main: "#25287f",
  50: "#f2f2fb",
  100: "#e4e5f7",
  200: "#c9cbef",
  300: "#aeb0e8",
  400: "#9396e0",
  500: "#797cd8",
  600: "#5e62d0",
  700: "#4347c8",
  800: "#3439b4",
  900: "#2d3099",
  1100: "#212472",
  1200: "#1d2065",
  1300: "#1a1c59",
  1400: "#16184c",
  1500: "#12143f",
  1600: "#0f1033",
  1700: "#0b0c26",
  1800: "#070819",
  1900: "#04040d",
};
