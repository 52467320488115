/* eslint-disable max-lines */
import { HealthProgramMember, HealthProgramMemberSortingField, H_Gender, H_OrderDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format, TruncateTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { usePayersQuery } from "../../../../gql/queries";
import { UserMembershipsDialog } from "../../../Members/UserMembershipsDialog";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<HealthProgramMember>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useHealthProgramMembersColumns = ({ hasMembers = true }): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "firstName",
        header: t("First Name"),
        accessor: ({ firstName }) => <TruncateTypography text={firstName!} />,
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthProgramMemberSortingField.Name,
        filter: {
          label: t("Name"),
          name: "name",
          type: "string",
        },
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: ({ lastName }) => <TruncateTypography text={lastName!} />,
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthProgramMemberSortingField.Name,
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: "nationalId",
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthProgramMemberSortingField.NationalId,
        filter: {
          name: "nationalId",
          type: "string",
        },
      },
      {
        key: "insuranceId",
        header: t("Member Id"),
        accessor: "insuranceId",
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthProgramMemberSortingField.InsuranceId,
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "email",
        header: t("Email"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "email",
        },
      },
      {
        key: "gender",
        header: t("Gender"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "gender",
          options: Object.keys(H_Gender).map(key => ({ value: H_Gender[key], name: key })),
          getOptionLabel: option => t(option?.name!),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "dateOfBirth",
        header: t("Date Of Birth"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateOfBirth",
        },
      },
      {
        key: "relationType",
        header: t("Relation Type"),
        accessor: ({ relationType }) => relationType || "-",
      },
      {
        key: "insurancePolicyNumber",
        header: t("Insurance Policy Number"),
        accessor: ({ insurancePolicyNumber }) => insurancePolicyNumber || "-",
        filter: {
          type: "string",
          name: "insurancePolicyNumber",
        },
      },
      {
        key: "insuranceCompanyId",
        header: t("Payer"),
        accessor: ({ payer }) => payer?.name,
        isHidden: true,
        type: "string",
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          multiple: true,
          query: usePayersQuery,
          getOptionLabel: option => option?.name!,
          getValueForBackend: options => options?.map(item => item?.id),
          accessor: "payers",
          name: "insuranceCompanyIds",
        },
      },
      {
        key: "phone",
        header: t("Phone Number"),
        accessor: "phone",
        type: "string",
        filter: {
          name: "phone",
          type: "string",
        },
      },
      {
        key: "membershipStart",
        header: t("Membership Start date"),
        accessor: ({ membershipStart }) => format(membershipStart),
        type: "string",
      },
      {
        key: "membershipEnd",
        header: t("Membership End date"),
        accessor: ({ membershipEnd }) => format(membershipEnd),
        type: "string",
      },
      {
        key: "parentNationalId",
        header: t("Parent National Id"),
        accessor: ({ parentNationalId }) => parentNationalId || "-",
      },
      {
        key: "created",
        header: t("Created"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMemberSortingField.Created,
        accessor: row => <Typography noWrap>{format(row?.created, "DD MMM YYYY MM:HH") || "-"}</Typography>,
      },
      {
        key: "MembersList",
        header: t("Member Lists"),
        accessor: member => <UserMembershipsDialog member={member} />,
        type: "string",
        isHidden: !hasMembers,
      },
    ];
  }, [hasMembers]);
};
