import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import React, { FC } from "react";

export const ProviderCard: FC<{ loading; vendor }> = ({ loading, vendor }) => {
  const { t } = useTranslation("admin");
  return (
    <FormCard loading={loading} doYouHaveData={true} title={t("Health Provider")}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField disabled fullWidth size='small' placeholder={t("Name")} label={t("Name")} name='name' value={vendor?.name} />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            size='small'
            fullWidth
            name='ownerName'
            value={vendor?.ownerName}
            placeholder={t("Owner Name")}
            label={t("Owner Name")}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            fullWidth
            size='small'
            name='nationalId'
            value={vendor?.nationalId}
            placeholder={t("National Id")}
            label={t("National Id")}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            disabled
            size='small'
            fullWidth
            name='description'
            multiline
            maxRows={4}
            value={vendor?.description}
            placeholder={t("Description")}
            label={t("Description")}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
