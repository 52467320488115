import { IOptimaActionCreateContainerEvent, OptimaActionCreateContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { optimaActionsPaths } from "../../constants";

export const AdminOptimaActionCreateContainer = () => {
  const navigate = useNavigate();

  const onOptimaActionCreateContainerChange = (event: IOptimaActionCreateContainerEvent) => {
    if (event.type === "SUBMIT_SUCCESS") {
      navigate(optimaActionsPaths.list.fullPath);
    }
  };

  return <OptimaActionCreateContainer onChange={onOptimaActionCreateContainerChange} />;
};
