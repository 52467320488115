import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { InsuranceNetworksDocument, useInsuranceNetworkCreateMutation } from "pages/HealthInsurance/gql";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import NetworkInsuranceForm from "./components/NetworkInsuranceForm";
import { InsuranceFormPagesProps } from "./components/types";

export const NetworkInsuranceNewPage: FC<InsuranceFormPagesProps> = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [errors, setError] = useState<any>("");
  const navigate = useNavigate();
  const [insuranceNetworkCreate] = useInsuranceNetworkCreateMutation({
    onCompleted: data => {
      const errors = data.insuranceNetworkCreate?.insuranceNetworkErrors;
      if (data.insuranceNetworkCreate?.insuranceNetworkErrors?.length === 0) {
        succeeded(t("SuperAdminForm:Network created successfully'", "Network created successfully'"));
        navigate(-1);
      } else {
        setError(data.insuranceNetworkCreate?.insuranceNetworkErrors);
        const message = errors?.map(error => {
          return `Error: ${error.message}`;
        });
        const dataFailed = message?.[0].split(",,");
        failed(t(String(dataFailed?.[0])));
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors?.map(error => {
        return `Error: ${error.message}`;
      });
      failed(t("SuperAdminForm:createNetworkFailed", "Create Network Failed"));
    },
    refetchQueries: [
      {
        query: InsuranceNetworksDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });
  const handleSubmit = (data: any) => {
    insuranceNetworkCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return <NetworkInsuranceForm onDone={handleSubmit} onCancel={handleCancel} errors={errors} isEditMode={false} />;
};
