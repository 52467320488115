import { adminManagedListsPaths } from "./paths";
export const adminManageListsTitleBreadcrumb = (t) => {
    return t("Admin Managed Lists", { ns: "domains" });
};
export const adminManageListBreadcrumb = (t) => {
    return {
        id: "admin-managed-list",
        name: t("Admin Managed Lists", { ns: "domains" }),
        route: adminManagedListsPaths.listPath.fullPath,
    };
};
export const adminManageListFieldBreadcrumb = (fieldName, fieldRoute) => {
    return {
        id: "admin-managed-field",
        name: fieldName,
        route: fieldRoute || undefined,
    };
};
