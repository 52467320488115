import { makeStyles } from "@toolkit/ui";

export const useSurveyCardStyle = makeStyles()(theme => ({
  dateStyle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "140px",
  },
  participants: {
    width: 104,
    height: 104,
    borderRadius: "50%",
    textAlign: "center",
    padding: 2,
    background: theme.palette.primary.main,
    boxShadow: theme.mixins.shadows.sm,
    border: "3px solid" + theme.palette.common.white,
    opacity: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
}));
