/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BenefitGroupDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type BenefitGroupDeleteMutation = { __typename?: 'Mutation', benefitGroupDelete?: { __typename?: 'BenefitGroupCRUD', benefitGroup?: { __typename?: 'BenefitGroup', id: string, name?: string | null, description?: string | null } | null, healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const BenefitGroupDeleteDocument = gql`
    mutation benefitGroupDelete($id: ID!) {
  benefitGroupDelete(id: $id) {
    benefitGroup {
      id
      name
      description
    }
    healthProgramErrors {
      field
      message
      code
    }
  }
}
    `;
export type BenefitGroupDeleteMutationFn = Apollo.MutationFunction<BenefitGroupDeleteMutation, BenefitGroupDeleteMutationVariables>;

/**
 * __useBenefitGroupDeleteMutation__
 *
 * To run a mutation, you first call `useBenefitGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitGroupDeleteMutation, { data, loading, error }] = useBenefitGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefitGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BenefitGroupDeleteMutation, BenefitGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BenefitGroupDeleteMutation, BenefitGroupDeleteMutationVariables>(BenefitGroupDeleteDocument, options);
      }
export type BenefitGroupDeleteMutationHookResult = ReturnType<typeof useBenefitGroupDeleteMutation>;
export type BenefitGroupDeleteMutationResult = Apollo.MutationResult<BenefitGroupDeleteMutation>;
export type BenefitGroupDeleteMutationOptions = Apollo.BaseMutationOptions<BenefitGroupDeleteMutation, BenefitGroupDeleteMutationVariables>;