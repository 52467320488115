import { z } from "zod";
export const optimaActionUpsertFormSchema = z.object({
    action: z.string().min(1).max(255),
    link: z.string().min(1).max(255),
    summary: z.string().min(1).max(255),
    isActive: z.boolean(),
});
export const optimaActionUpsertFormDefaultValues = {
    action: undefined,
    link: undefined,
    summary: undefined,
    isActive: false,
};
