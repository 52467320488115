import { combineErrors, formGirdBreakPoints, getPositiveNumber } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, ToggleButtonController } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";

export const Copay: FC<IProgramProps> = ({ defaultValues, errors, currency }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  const copay = watch("copay") || defaultValues?.copay;

  const copayAmountValue = defaultValues?.copayAmount ? "copayAmount" : "copayPercentage";

  const copayCheck = watch("copayCheck") || copayAmountValue;

  useEffect(() => {
    setValue("copayCheck", copayCheck);
  }, [defaultValues]);

  return (
    <>
      <Grid container spacing={2} item xs alignItems='center'>
        <Grid item xs={12}>
          <ToggleButtonController {...register("copay")} control={control} defaultValue={defaultValues?.copay} label={t("Copay")} />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup value={copayCheck} sx={{ width: "100%", flexDirection: "row", alignItems: "center" }}>
            <Grid item {...formGirdBreakPoints}>
              <FormControlLabel
                value='copayAmount'
                sx={{
                  width: "100%",
                  "& .MuiFormControlLabel-label": {
                    width: "100%",
                  },
                }}
                control={<Radio disabled={!copay} {...register("copayCheck")} />}
                label={
                  <TextField
                    fullWidth
                    type='number'
                    size='small'
                    disabled={(!copay || copayCheck !== "copayAmount") && Boolean(!defaultValues?.copayAmount)}
                    label={t("Amount of copay")}
                    {...register("copayAmount", {
                      required: { value: copay && copayCheck === "copayAmount", message: t("Required") },
                      min: getPositiveNumber(t, 0),
                    })}
                    defaultValue={defaultValues?.copayAmount}
                    error={copay && copayCheck === "copayAmount" && Boolean(formErrors.copayAmount?.message)}
                    helperText={copay && copayCheck === "copayAmount" && formErrors.copayAmount?.message}
                    InputProps={{
                      endAdornment: currency,
                    }}
                  />
                }
              />
            </Grid>
            <Grid item {...formGirdBreakPoints}>
              <FormControlLabel
                value='copayPercentage'
                sx={{
                  width: "100%",
                  "& .MuiFormControlLabel-label": {
                    width: "100%",
                  },
                }}
                control={<Radio disabled={!copay} {...register("copayCheck")} />}
                label={
                  <TextField
                    fullWidth
                    type='number'
                    inputProps={{ step: "0.1" }}
                    size='small'
                    disabled={(!copay || copayCheck !== "copayPercentage") && Boolean(!defaultValues?.copayPercentage)}
                    label={t("Percentage")}
                    {...register("copayPercentage", {
                      required: { value: copay && copayCheck === "copayPercentage", message: t("Required") },
                      min: getPositiveNumber(t, 0),
                    })}
                    InputProps={{
                      startAdornment: "%",
                    }}
                    defaultValue={defaultValues?.copayPercentage}
                    error={copay && copayCheck === "copayPercentage" && Boolean(formErrors.copayPercentage?.message)}
                    helperText={copay && copayCheck === "copayPercentage" && formErrors.copayPercentage?.message}
                  />
                }
              />
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};
