import { CallbackRequest, Maybe } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, useTheme } from "@toolkit/ui";
import { startCase } from "lodash";
import moment from "moment-timezone";
import React, { FC } from "react";

export const RequestDetails: FC<{ callBackRequest?: CallbackRequest }> = ({ callBackRequest }) => {
  const { t } = useTranslation("admin");
  const userTz = moment.tz.guess();
  const theme = useTheme();
  const fontSize = theme.mixins.fonts.fontSize.sm;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
        {t("Request Details")}
      </Typography>
      <Grid container columnSpacing={{ sx: 4, md: 8 }}>
        <Grid item xs={4}>
          <RequestDetailsSection fontSize={fontSize} label={t("Patient Name")} value={callBackRequest?.user?.fullName} />
        </Grid>
        <Grid item xs={4}>
          <RequestDetailsSection fontSize={fontSize} label={t("Contact Number")} value={callBackRequest?.patient?.contactNumber} />
        </Grid>
        <Grid item xs={4}>
          <RequestDetailsSection fontSize={fontSize} label={t("Service Name")} value={callBackRequest?.serviceName} />
        </Grid>
        <Grid item xs={4}>
          <RequestDetailsSection
            fontSize={fontSize}
            label={t("Request Status")}
            value={t(startCase(callBackRequest?.status?.toLowerCase()))}
          />
        </Grid>
        <Grid item xs={4}>
          <RequestDetailsSection
            fontSize={fontSize}
            label={t("Request Date")}
            value={callBackRequest?.created ? moment(callBackRequest?.created).format("DD MMM YYYY") : "-" || ""}
          />
        </Grid>
        <Grid item xs={4}>
          <RequestDetailsSection
            fontSize={fontSize}
            label={t("Request Time")}
            value={callBackRequest?.created ? moment(callBackRequest?.created).tz(userTz).format("hh:mm a") : "-"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const RequestDetailsSection: FC<{ label: string; value?: Maybe<string>; fontSize: number }> = ({ label, value = "", fontSize }) => (
  <Box marginTop='8px' marginBottom='8px'>
    <Typography fontWeight='bold' fontSize={fontSize}>
      {label}:
    </Typography>
    <Typography fontSize={fontSize}>{value}</Typography>
  </Box>
);
