import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { OwnerInfoProps } from "./VendorList.types";

export const OwnerInfo: FC<OwnerInfoProps> = ({ nationalId, ownerName }) => {
  const { t, i18n } = useTranslation("admin");

  const currentDir = i18n.dir(i18n.language);

  return (
    <CustomDialog type='info' title={t("Owner Info")}>
      <Grid container spacing={2}>
        {ownerName && (
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              dir={currentDir}
              placeholder={t("Owner Name")}
              value={t(ownerName) + " "}
              label={t("Owner Name")}
            />
          </Grid>
        )}
        {nationalId && (
          <Grid item xs={6}>
            <TextField disabled fullWidth dir={currentDir} placeholder='nationalId' value={t(nationalId) + " "} label={t("National Id")} />
          </Grid>
        )}
      </Grid>
    </CustomDialog>
  );
};
