import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid } from "@toolkit/ui";
import { ManagedListCard } from "./ManagedListCard";
import ManagedListCardLoader from "./ManagedListCard/ManagedListCardLoader";
import { useAdminManagedListsIndexPage } from "./useAdminManagedListsIndexPage";
/**
 *
 * @returns renders the list of all managed lists, each in its own card
 */
export const AdminManagedLists = () => {
    const { adminManagedLists, isLoading = true, onDetailsClick } = useAdminManagedListsIndexPage();
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(ManagedListCardLoader, {})) : (_jsx(Grid, { container: true, padding: 4, spacing: 2, children: adminManagedLists === null || adminManagedLists === void 0 ? void 0 : adminManagedLists.map(item => {
                var _a;
                return (_jsx(Grid, { item: true, xs: 12, sm: 6, lg: 4, xl: 3, children: _jsx(ManagedListCard, { data: item, handleDetailsClick: onDetailsClick }) }, (_a = item === null || item === void 0 ? void 0 : item.activeCodeSystem) === null || _a === void 0 ? void 0 : _a.display));
            }) })) }));
};
export default AdminManagedLists;
