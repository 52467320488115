import { combineErrors, getPositiveNumber, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, HintComponent, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "../types";

export const SpecializationFactor: FC<IProgramProps> = ({ errors, defaultValues, readOnly }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  const defaultValue = defaultValues?.mainSpecializationsRateFactors && JSON?.parse(defaultValues?.mainSpecializationsRateFactors);

  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("GP")}
          error={Boolean(formErrors.mainSpecializationsRateFactors?.gp?.message)}
          helperText={t(formErrors.mainSpecializationsRateFactors?.gp?.message)}
          {...register("mainSpecializationsRateFactors.gp", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValue?.GP || 1}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for General Practitioners (GP) multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("SP")}
          error={Boolean(formErrors.mainSpecializationsRateFactors?.sp?.message)}
          helperText={t(formErrors.mainSpecializationsRateFactors?.sp?.message)}
          {...register("mainSpecializationsRateFactors.sp", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValue?.SP || 1.5}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for Speciality Practitioners (SP) multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("CP")}
          error={Boolean(formErrors.mainSpecializationsRateFactors?.cp?.message)}
          helperText={t(formErrors.mainSpecializationsRateFactors?.cp?.message)}
          {...register("mainSpecializationsRateFactors.cp", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValue?.CP || 2}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for Consultant Practitioners (CP) multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
