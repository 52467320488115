import { replacePathParams } from "utils";

export const adminUserManagementRoute = "/admin-user-management";
const adminUserManagementNewRoute = "new";
const adminUserManagementEditRoute = "edit/:id";

export const adminUserManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${adminUserManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: adminUserManagementNewRoute,
    get fullPath() {
      return `${adminUserManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: adminUserManagementEditRoute,
    get fullPath() {
      return `${adminUserManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
