/* eslint-disable max-lines */
import { PublishCriteriaInput, SurveyInput } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { surveysPaths } from "pages/Surveys/constants/surveys-paths";
import { FetchSurveysDocument } from "pages/Surveys/gql";
import {
  CancelSurveyMutation,
  CloneSurveyMutation,
  CreateSurveyMutation,
  PublishSurveyMutation,
  UnPublishSurveyMutation,
  UpdateSurveyMutation,
  useCancelSurveyMutation,
  useCloneSurveyMutation,
  useCreateSurveyMutation,
  usePublishSurveyMutation,
  useUnPublishSurveyMutation,
  useUpdateSurveyMutation,
} from "pages/Surveys/gql/mutations";
import { useNavigate } from "react-router-dom";

export const useMutations = ({ survey, methods, onCloneCompleted }) => {
  const navigate = useNavigate();

  const { failed, succeeded } = useAddToast();

  const { t } = useTranslation("admin");

  const handleSaveSurvey = () => {
    const values = methods.getValues();
    const input = {
      label: values.label,
      description: values.description,
      notification: values.notification,
      questions: values.questions.map(question => ({
        question: question.question,
        options: question.options,
        questionType: question.questionType,
        required: question.required,
      })),
    };

    if (survey?.id) {
      saveSurvey(input);
    } else {
      createSurveyMutation({
        variables: {
          input,
        },
      });
    }
  };

  const saveSurvey = (input: SurveyInput) => {
    updateSurveyMutation({
      variables: {
        updateSurveyId: survey?.id!,
        input,
      },
    });
  };

  const handleCreateSurvey = (input: SurveyInput) => {
    createSurveyMutation({
      variables: {
        input,
      },
    });
  };

  const handlePublish = (input: PublishCriteriaInput) => {
    publishSurveyMutation({ variables: { publishSurveyId: survey?.id!, input } });
  };

  const [publishSurveyMutation, { loading: isPublishLoading }] = usePublishSurveyMutation({
    onCompleted: (mutationData: PublishSurveyMutation) => {
      const errors = mutationData?.publishSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey published successfully"));
        navigate(surveysPaths.listPath.fullPath);
      }
    },
    onError: error => {
      failed(t(error.message ?? "Failed publishing survey"));
    },
  });

  const [unPublishSurveyMutation, { loading: isUnPublishLoading }] = useUnPublishSurveyMutation({
    onCompleted: (mutationData: UnPublishSurveyMutation) => {
      const errors = mutationData?.unPublishSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey unpublished successfully"));
        navigate(surveysPaths.listPath.fullPath);
      }
    },
    onError: error => {
      failed(t(error.message ?? "Failed unpublish survey"));
    },
  });

  const handleUnPublish = () => {
    unPublishSurveyMutation({ variables: { unPublishSurveyId: survey?.id } });
  };

  const [updateSurveyMutation, { loading: isUpdateLoading }] = useUpdateSurveyMutation({
    onCompleted: (mutationData: UpdateSurveyMutation) => {
      const errors = mutationData?.updateSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey updated successfully"));
        navigate(surveysPaths.listPath.fullPath);
      }
    },
    onError: () => {
      failed(t("Failed updating survey"));
    },
  });

  const [cloneSurveyMutation, { loading: isCloneLoading }] = useCloneSurveyMutation({
    onCompleted: (mutationData: CloneSurveyMutation) => {
      const errors = mutationData?.cloneSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey cloned successfully"));
        onCloneCompleted();
        navigate(surveysPaths.detailsPath.fullPathWithParams(mutationData.cloneSurvey?.id!));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const message = formattedError || t("Failed cloning survey");
      failed(t(message));
    },
    refetchQueries: [
      {
        query: FetchSurveysDocument,
        variables: {
          first: 8,
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const [createSurveyMutation, { loading: isCreateLoading }] = useCreateSurveyMutation({
    onCompleted: (mutationData: CreateSurveyMutation) => {
      const errors = mutationData?.createSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey created successfully"));
        navigate(surveysPaths.listPath.fullPath);
      }
    },
    onError: () => {
      failed(t("Failed creating survey"));
    },
    refetchQueries: [
      {
        query: FetchSurveysDocument,
        variables: {
          first: 8,
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const [cancelSurveyMutation, { loading: isCancelLoading }] = useCancelSurveyMutation({
    onCompleted: (res: CancelSurveyMutation) => {
      if (res?.cancelSurvey?.id) {
        succeeded(t("Succeeded in cancelling survey"));
      }
    },
    onError: () => {
      failed(t("Failed in cancelling survey"));
    },
  });

  return {
    isUpdateLoading,
    isCreateLoading,
    isPublishLoading,
    isCancelLoading,
    isUnPublishLoading,
    isCloneLoading,
    cloneSurveyMutation,
    handlePublish,
    handleUnPublish,
    handleSaveSurvey,
    handleCreateSurvey,
    publishSurveyMutation,
    updateSurveyMutation,
    createSurveyMutation,
    cancelSurveyMutation,
  };
};
