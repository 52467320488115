var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React from "react";
import { useGuidedCareTeamMembersUsersQuery } from "./gql";
export const GuidedCareTeamMembersUsersAutocomplete = props => {
    var _a, _b, _c;
    const { name, filter, skip, label } = props, rest = __rest(props, ["name", "filter", "skip", "label"]);
    const { t } = useTranslation("domains");
    const { data, loading } = useGuidedCareTeamMembersUsersQuery({
        variables: {
            id: filter === null || filter === void 0 ? void 0 : filter.teamId,
        },
        skip: props.disabled || skip,
    });
    const _data = ((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.providerGuidedCareHealthProgramTeam) === null || _a === void 0 ? void 0 : _a.teamMembers) === null || _b === void 0 ? void 0 : _b.filter(item => { var _a, _b; return !((_a = filter === null || filter === void 0 ? void 0 : filter.positions) === null || _a === void 0 ? void 0 : _a.length) || (filter === null || filter === void 0 ? void 0 : filter.positions.includes((_b = item === null || item === void 0 ? void 0 : item.guidedCareProgramTeamMember) === null || _b === void 0 ? void 0 : _b.position)); })) === null || _c === void 0 ? void 0 : _c.map(item => {
        var _a, _b;
        return ({
            id: item === null || item === void 0 ? void 0 : item.id,
            userId: (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.id,
            name: (_b = item === null || item === void 0 ? void 0 : item.user) === null || _b === void 0 ? void 0 : _b.fullName,
        });
    })) || [];
    const options = mapToAutocompleteOptions(_data, "id", "name");
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || (props.multiple ? t("Users") : t("User")), options: options, loading: loading })));
};
