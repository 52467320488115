import { getRequiredValidation, maxRequired, minRequired, patternEmail } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export const userFields = (t: TFunction, defaultValues, isEdit) => [
  {
    name: "firstName",
    label: t("First Name", { ns: "admin" }),
    defaultValue: defaultValues?.firstName,
    isRequired: true,
    minLength: minRequired(t, 3),
    maxLength: maxRequired(t, 30),
  },
  {
    name: "lastName",
    label: t("Last Name", { ns: "admin" }),
    defaultValue: defaultValues?.lastName,
    isRequired: true,
    minLength: minRequired(t, 3),
    maxLength: maxRequired(t, 30),
  },
  {
    name: "email",
    label: t("Email", { ns: "admin" }),
    pattern: patternEmail(t),
    defaultValue: defaultValues?.email,
    disabled: !!defaultValues?.email,
    isRequired: !defaultValues?.email,
  },
  {
    name: "password",
    label: t("Password", { ns: "admin" }),
    type: "password",
    isRequired: !isEdit,
    disabled: isEdit,
    autoComplete: "new-password",
  },
  {
    name: "nationalId",
    label: t("National ID", { ns: "admin" }),
    required: getRequiredValidation(t, true),
    defaultValue: defaultValues?.nationalId,
    isRequired: !isEdit,
    disabled: !!defaultValues?.nationalId,
  },
];
