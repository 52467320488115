import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionActionChronicTemplatesAutocompleteDocument = gql `
    query DecisionActionChronicTemplatesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramTemplateFilterInput) {
  healthProgramTemplates(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name
      }
    }
  }
}
    `;
/**
 * __useDecisionActionChronicTemplatesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionActionChronicTemplatesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionActionChronicTemplatesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionActionChronicTemplatesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionActionChronicTemplatesAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionActionChronicTemplatesAutocompleteDocument, options);
}
export function useDecisionActionChronicTemplatesAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionActionChronicTemplatesAutocompleteDocument, options);
}
