import { replacePathParams } from "utils";

export const programActivityRoute = "/program-activity";
const programActivityRouteProviderRoute = ":id/:doctorId";

export const programActivityPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${programActivityRoute}${this.route}`;
    },
  },
  providerPath: {
    route: programActivityRouteProviderRoute,
    get fullPath() {
      return `${programActivityRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, doctorId: string) {
      return replacePathParams(this.fullPath, { id, doctorId });
    },
  },
};
