import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { PayerForm } from "../PayerForm";

import { usePayerAdd } from "./usePayerCreate";
import { payersListBreadcrumb, payersNewBreadcrumb, payersTitleBreadcrumb } from "../../Constants/payersBreadcrumbs";

export const PayerNewPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, handleSubmit, isSubmitting } = usePayerAdd();

  useEffect(() => {
    setBreadCrumb({
      title: payersTitleBreadcrumb(),
      values: [payersListBreadcrumb(), payersNewBreadcrumb()],
    });
  }, []);

  return <PayerForm errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
