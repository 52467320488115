/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSurveyQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID'];
}>;


export type FetchSurveyQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id?: string | null, label?: string | null, description?: string | null, notification?: string | null, answeredParticipantsCount?: number | null, status?: Types.SurveyStatus | null, updateDate?: any | null, createdDate?: any | null, publishCriteria?: Array<{ __typename?: 'PublishCriteria', id?: string | null, gender?: Types.SurveyGender | null, fromAge?: number | null, expirationDate?: any | null, maxParticipants?: number | null, toAge?: number | null, chronicDiseaseCode?: Array<string | null> | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null> | null, questions?: Array<{ __typename?: 'Question', id?: string | null, options?: Array<string | null> | null, question?: string | null, questionType?: Types.QuestionType | null, required?: boolean | null, updateDate?: any | null, createdDate?: any | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null }> | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.SurveyErrorCodes | null } | null> | null } | null };


export const FetchSurveyDocument = gql`
    query fetchSurvey($surveyId: ID!) {
  survey(id: $surveyId) {
    id
    label
    description
    notification
    answeredParticipantsCount
    publishCriteria {
      id
      gender
      fromAge
      expirationDate
      errors {
        code
        errorType
        field
        message
      }
      maxParticipants
      toAge
      chronicDiseaseCode
    }
    status
    updateDate
    questions {
      id
      options
      question
      questionType
      required
      updateDate
      errors {
        code
        errorType
        field
        message
      }
      createdDate
    }
    createdDate
    errors {
      message
      field
      errorType
      code
    }
  }
}
    `;

/**
 * __useFetchSurveyQuery__
 *
 * To run a query within a React component, call `useFetchSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useFetchSurveyQuery(baseOptions: Apollo.QueryHookOptions<FetchSurveyQuery, FetchSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSurveyQuery, FetchSurveyQueryVariables>(FetchSurveyDocument, options);
      }
export function useFetchSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSurveyQuery, FetchSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSurveyQuery, FetchSurveyQueryVariables>(FetchSurveyDocument, options);
        }
export type FetchSurveyQueryHookResult = ReturnType<typeof useFetchSurveyQuery>;
export type FetchSurveyLazyQueryHookResult = ReturnType<typeof useFetchSurveyLazyQuery>;
export type FetchSurveyQueryResult = Apollo.QueryResult<FetchSurveyQuery, FetchSurveyQueryVariables>;