import { i18n } from "@toolkit/i18n";
import { promotionsPaths } from "./PromotionsPaths";

export const promotionsBreadcrumb = () => ({
  title: i18n.t("Promotions", "admin"),
  main: {
    id: "promotions",
    name: i18n.t("Promotions", "admin"),
    path: promotionsPaths.main.fullPath,
  },
  create: {
    id: "promotion-new",
    name: i18n.t("New", "admin"),
  },
  update: (fieldName: string) => ({
    id: "promotion-update",
    name: fieldName || i18n.t("Update", "admin"),
  }),
});
