import { MarketplaceApprovalStatus } from "@health/queries/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CheckMarkIcon, CustomDialog, IconButton, useAddToast } from "@toolkit/ui";
import { ProductApproveMutation, useProductApproveMutation } from "pages/MarketplaceProducts/gql/mutations";
import { ProductsQueryNode } from "pages/MarketplaceProducts/types";
import { ProductDetails } from "./ProductDetails";

export const ProductApprovalModal: React.FC<ProductsQueryNode> = productProps => {
  const { t } = useTranslation();
  const { handleToggle, open } = useOpenState();
  const { failed, succeeded } = useAddToast();

  const [productApproveMutation, { loading }] = useProductApproveMutation({
    onCompleted: (data: ProductApproveMutation) => {
      if (data?.marketplaceProductApprove?.approvalStatus === MarketplaceApprovalStatus.Approved) {
        handleToggle();
        succeeded(t("Product approved successfully!"));
      } else {
        failed(t("Failed to approve product!"));
      }
    },
  });
  const isApproveDisabled =
    loading || MarketplaceApprovalStatus.PendingApproval !== productProps?.approvalStatus || !productProps?.updatable;
  const handleApproveProduct = () => {
    productApproveMutation({
      variables: {
        marketplaceProductApproveId: productProps?.id,
      },
    });
  };
  return (
    <CustomDialog
      type='base'
      title={t("Approve Product")}
      open={open}
      DialogTitleProps={{
        title: t("Approve Product"),
        onClose: handleToggle,
      }}
      button={
        <IconButton disabled={isApproveDisabled} onClick={handleToggle}>
          <CheckMarkIcon color='success' />
        </IconButton>
      }
      DialogActionsProps={{
        children: (
          <Button onClick={handleApproveProduct} disabled={loading} color='success'>
            {t("Approve")}
          </Button>
        ),
      }}
    >
      <ProductDetails {...productProps} />
    </CustomDialog>
  );
};
