import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { Typography } from "../../base/mui";
import { OrdersAutocompleteSearch } from "./OrdersAutocompleteSearch";
import { BaseOrder } from "./types";
import { useAuditHistoryHeaderStyles } from "./useAuditHistoryHeaderStyles";

export const AuditHistoryHeader: FC<{
  value: string[];
  ordersData: BaseOrder[];
  referenceNumber: string;
  onChange: (e: unknown, v: string[]) => void;
  onSearch: () => void;
}> = props => {
  const { value, onChange: handleChange, onSearch: handleSearch, ordersData: ordersNumbers, referenceNumber } = props;

  const { t } = useTranslation();
  const { classes } = useAuditHistoryHeaderStyles();

  return (
    <div className={classes.flex}>
      <Typography className={classes.title}>
        {t("eRx")} <span className={classes.referenceNumber}>{t(referenceNumber)}</span> {t("History")}
      </Typography>

      <OrdersAutocompleteSearch value={value} onChange={(e, v) => handleChange(e, v!)} onSearch={handleSearch} ordersData={ordersNumbers} />
    </div>
  );
};
