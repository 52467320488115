import { AdminUserCreateInput, AppTypes, UserUpdateInput } from "@health/queries/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { createAutocompleteOption } from "@toolkit/ui";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

export const useProviderInformationHook = (errors, defaultValues) => {
  const { t } = useTranslation("admin");
  const {
    control,
    watch,
    formState: { errors: formErrors },
    setValue,
  } = useFormContext<UserUpdateInput | AdminUserCreateInput | any>();

  const isDefaultValueSet = useRef<boolean>(false);

  const combinedErrors = combineErrors(formErrors, errors);

  const appType = { value: AppTypes.Vendor, name: "Vendor" };
  const role: { name: string; value: string } = watch("appRole");
  const type: { name: string; value: string } = appType;

  const vendorsValue = watch("vendor");
  const formBranchesValues = watch("branches");
  const formDefaultBranchValue = watch("defaultBranch");

  const branchesValues = defaultValues?.branches?.map(branch => createAutocompleteOption(branch, "id", "name")) || [];
  const vendorDefaultValues = defaultValues?.vendor ? createAutocompleteOption(defaultValues?.vendor, "id", "name") : null;

  const branchAccessor = "branches";

  const hasVendor = Boolean(defaultValues?.vendor?.id);

  useEffect(() => {
    if (formDefaultBranchValue?.value?.id) {
      formBranchesValues?.some(item => item.value.id === formDefaultBranchValue?.value?.id) || setValue("defaultBranch", null);
    }
  }, [formBranchesValues]);

  useEffect(() => {
    if (isDefaultValueSet.current) {
      setValue("branches", []);
      setValue("defaultBranch", null);
    } else if (vendorsValue?.value) {
      isDefaultValueSet.current = true;
    }
  }, [vendorsValue]);

  useEffect(() => {
    const defaultBranchDefaultValue = branchesValues?.find(branch => branch?.value?.id === defaultValues?.defaultBranch?.id) || null;
    setValue("branches", branchesValues);
    setValue("vendor", vendorDefaultValues);
    if (defaultBranchDefaultValue?.value?.id) {
      formBranchesValues?.some(item => item.value.id === formDefaultBranchValue?.value?.id) ||
        setValue("defaultBranch", defaultBranchDefaultValue);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (isDefaultValueSet.current) {
      setValue("branches", []);
      setValue("defaultBranch", null);
    }
  }, [role]);

  return {
    t,
    combinedErrors,
    control,
    hasVendor,
    vendorDefaultValues,
    branchAccessor,
    vendorsValue,
    role,
    branchesValues,
    type,
    formBranchesValues,
  };
};
