import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { brandsBreadcrumb } from "../../constants";
import { brandsPaths } from "../../constants";
import { BrandUpsertForm, IBrandUpsertFormValues } from "../../forms";
import { MarketplaceBrandCreateMutation, useMarketplaceBrandCreateMutation } from "../../gql";
import { convertBrandFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const BrandCreateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchBrandCreateMutation, { loading: isSubmitting }] = useMarketplaceBrandCreateMutation({
    onCompleted: (data: MarketplaceBrandCreateMutation) => {
      if (data?.marketplaceBrandCreate?.id) {
        succeeded(t("Brand created successfully!"));
        navigate(brandsPaths.main.fullPath);
      } else {
        failed(t("Brand creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onBrandUpsertFormChange = (values: IBrandUpsertFormValues) => {
    const _values = convertBrandFormValuesToBackEndValues(values);

    fetchBrandCreateMutation({
      variables: {
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, create } = brandsBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);
  return <BrandUpsertForm isSubmitting={isSubmitting} submitButtonLabel={t("Create")} onChange={onBrandUpsertFormChange} />;
};
