import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { PrescriptionsOrdersTrackingPage } from "@health/domains";
import { prescriptionsOrdersTrackingTitleBreadcrumb } from "../constants/PrescriptionsOrderTrackingBreadcrumbs";

export const AdminPrescriptionsOrdersTrackingContainer = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: prescriptionsOrdersTrackingTitleBreadcrumb(t),
    });
  }, []);

  return <PrescriptionsOrdersTrackingPage isAdmin />;
};
