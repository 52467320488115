import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { marketplaceOrdersInvoicesTitleBreadcrumb } from "../constants/MarketplaceOrderInvoicesBreadcrumbs";
import { OrdersInvoicesPage } from "@health/domains";

export const AdminMarketPlaceOrdersInvoicesContainer = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: marketplaceOrdersInvoicesTitleBreadcrumb(t),
    });
  }, []);

  return <OrdersInvoicesPage />;
};
