import React from "react";
import { Box, Typography } from "../../base/mui";

type ValueWithLabelProps = {
  label: string;
  value?: string;
};

export const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ value, label }) => (
  <Box>
    <Typography fontWeight='bold' fontSize='14px'>
      {label}:
    </Typography>
    <Typography fontSize='14px'>{value}</Typography>
  </Box>
);
