import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const BookAnAppointmentIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  const { palette } = useTheme();
  return (
    <SvgIcon
      id='Group_8300'
      data-name='Group 8300'
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "68"}
      height={height ? height : "87.858"}
      viewBox={viewBox ? viewBox : "0 0 68 87.858"}
      {...props}
    >
      <defs>
        <clipPath id='clip-path-appointment'>
          <rect id='Rectangle_4046' data-name='Rectangle 4046' width='68' height='87.858' fill='none' />
        </clipPath>
      </defs>
      <g id='Group_6546' data-name='Group 6546' clipPath='url(#clip-path-appointment)'>
        <path
          id='Path_4404'
          data-name='Path 4404'
          d='M5.952,95.247c-.1-.136-.261-.1-.393-.129A6.857,6.857,0,0,1,.02,88.269q0-31.015,0-62.029a22.619,22.619,0,0,1,.093-3.554,6.868,6.868,0,0,1,6.554-5.68c2.917-.025,5.834,0,8.751-.014.328,0,.409.1.4.414-.018,1.072-.023,2.145,0,3.217.008.381-.082.506-.489.5-2.759-.019-5.519-.011-8.279-.009a2.743,2.743,0,0,0-2.92,2.929q0,32.066,0,64.131a2.752,2.752,0,0,0,2.948,2.95q26.941,0,53.881,0a2.746,2.746,0,0,0,2.91-2.9q0-32.087,0-64.174a2.748,2.748,0,0,0-2.959-2.936c-2.76,0-5.52-.007-8.28.008-.361,0-.457-.1-.45-.455.022-1.086.018-2.173,0-3.26,0-.307.066-.42.4-.419,2.9.014,5.806-.013,8.709.014A6.837,6.837,0,0,1,68,23.921q.014,8.408,0,16.816,0,23.722,0,47.444a6.882,6.882,0,0,1-4.344,6.588c-.544.218-1.123.292-1.673.479Z'
          transform='translate(0 -7.39)'
          fill={fill ? fill : palette.primary.main}
        />
        <path
          id='Path_4405'
          data-name='Path 4405'
          d='M26.01,61a2.036,2.036,0,0,1,1.283-1.864,1.988,1.988,0,0,1,2.273.521c1.149,1.273,2.289,2.555,3.405,3.857.28.326.411.274.664-.012q3.27-3.691,6.562-7.362a2.324,2.324,0,0,1,1.113-.764,2.044,2.044,0,0,1,2.281.863A2.016,2.016,0,0,1,43.4,58.7c-.864.991-1.748,1.965-2.624,2.945L34.913,68.2a2.039,2.039,0,0,1-3.264-.008q-2.5-2.823-5-5.65A2.165,2.165,0,0,1,26.01,61'
          transform='translate(-11.312 -24.05)'
          fill={fill ? fill : palette.primary.main}
        />
        <path
          id='Path_4406'
          data-name='Path 4406'
          d='M63.742,6.992c0-.369-.083-.484-.47-.481-2.445.019-4.889.006-7.334.015-.3,0-.443-.041-.462-.406a7.09,7.09,0,0,0-1.585-4.132A4.647,4.647,0,0,0,51.085.229C50.669.147,50.251.076,49.834,0H46.574c-.093.127-.235.081-.356.1a5,5,0,0,0-3.5,1.852A7.165,7.165,0,0,0,41.1,6.113c-.019.354-.14.415-.454.414-2.459-.011-4.918,0-7.377-.012-.337,0-.431.093-.43.43q.018,4.718,0,9.435c0,.367.115.444.461.444q14.99-.014,29.979,0c.394,0,.466-.124.465-.484q-.02-4.675,0-9.349M48.333,9.609a3.087,3.087,0,1,1,3.044-3.118,3.095,3.095,0,0,1-3.044,3.118'
          transform='translate(-14.281)'
          fill={fill ? fill : palette.primary.main}
        />
        <path
          id='Path_4407'
          data-name='Path 4407'
          d='M82.182,56.383c0-.416-.128-.529-.537-.527-2.673.016-5.347.008-8.021.008v0H65.6c-.568,0-.571,0-.571.557q0,8.042-.008,16.085c0,.416.12.53.531.528q8.063-.019,16.128,0c.415,0,.5-.15.5-.522q-.012-8.064,0-16.128M79.452,69.8c0,.377-.1.488-.483.485-1.786-.019-3.573-.008-5.36-.008s-3.544-.011-5.316.008c-.4,0-.545-.1-.544-.521.016-3.53.008-7.06.011-10.59,0-.56,0-.563.57-.563,3.545,0,7.089,0,10.634-.008.369,0,.491.094.489.479q-.021,5.36,0,10.719'
          transform='translate(-28.28 -24.293)'
          fill={fill ? fill : palette.stale.main}
        />
        <path
          id='Path_4408'
          data-name='Path 4408'
          d='M42.1,96.466c0-.418-.13-.529-.538-.526-2.674.016-5.348.008-8.021.008v0H25.516c-.567,0-.57,0-.57.557q0,8.042-.008,16.085c0,.417.122.528.531.527q8.043-.02,16.086,0c.4,0,.542-.1.541-.521q-.019-8.064,0-16.128m-2.737,8.073c0,1.786-.008,3.573.007,5.359,0,.346-.086.471-.453.47q-5.382-.018-10.763,0c-.383,0-.486-.122-.485-.491q.017-5.338,0-10.676c0-.383.1-.516.506-.515q5.36.021,10.72,0c.39,0,.478.13.474.494-.016,1.786-.007,3.572-.007,5.359'
          transform='translate(-10.846 -41.726)'
          fill={fill ? fill : palette.stale.main}
        />
        <path
          id='Path_4409'
          data-name='Path 4409'
          d='M82.181,96.507c0-.406-.075-.572-.536-.571q-8.065.024-16.13,0c-.364,0-.494.087-.493.476q.019,8.086,0,16.172c0,.413.114.529.528.528q8.022-.02,16.044-.01c.574,0,.576,0,.576-.555q0-3.99,0-7.979h0c0-2.688-.007-5.376.008-8.064m-2.729,13.419c0,.336-.1.441-.44.44q-5.4-.016-10.8,0c-.37,0-.455-.138-.454-.477q.014-5.36,0-10.719c0-.378.119-.489.493-.485,1.8.017,3.6.007,5.4.007,1.773,0,3.545.01,5.317-.007.371,0,.49.1.489.48q-.019,5.381,0,10.761'
          transform='translate(-28.279 -41.724)'
          fill={fill ? fill : palette.stale.main}
        />
      </g>
    </SvgIcon>
  );
};
