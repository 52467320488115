import { makeStyles } from "@toolkit/ui";

export const useHealthPackageStyles = makeStyles()(theme => ({
  imageListItem: {
    "&.MuiImageListItem-img": {
      width: "359px",
      height: "370px",
      margin: "10px",
      objectFit: "contain",
    },
    margin: "0px 5px 10px 0px",
  },
  rejectButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    ":hover": {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: theme.palette.error.main + "!important",
      borderColor: theme.palette.error.main + "!important",
    },
  },
}));
