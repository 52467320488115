import React, { FC } from "react";
import { makeStyles, SvgIcon, SvgIconProps } from "../../base/mui";

const useStyles = makeStyles()(() => ({
  root: {
    width: 15,
    height: 17,
  },
}));

export const RemoveIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { classes, theme } = useStyles();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "15px"}
      height={height ? height : "17.25px"}
      viewBox={viewBox ? viewBox : "0 0 16.515 18.992"}
      fill={fill ? fill : theme.palette.primary.main}
      classes={{ root: classes.root }}
      {...props}
    >
      <path
        id='icons8-remove'
        d='M11.606,3a.826.826,0,0,0-.837.826H5.826a.826.826,0,1,0,0,1.652H20.689a.826.826,0,1,0,0-1.652H15.746A.826.826,0,0,0,14.909,3ZM5.826,7.129V20.341a1.651,1.651,0,0,0,1.651,1.651h11.56a1.651,1.651,0,0,0,1.651-1.651V7.129Zm4.954,4.129a.823.823,0,0,1,.584.242l1.893,1.893L15.151,11.5a.826.826,0,1,1,1.168,1.168L14.425,14.56l1.893,1.893a.826.826,0,1,1-1.168,1.168l-1.893-1.893-1.893,1.893A.826.826,0,1,1,10.2,16.454L12.09,14.56,10.2,12.667a.826.826,0,0,1,.584-1.41Z'
        transform='translate(-5 -3)'
        fill={fill ? fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
