import { useHealthProgramExclusionOptionQuery } from "pages/HealthProgram/gql";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExclusionForm from "./components/ExclusionForm";
import { ExclusionsFormPagesProps } from "./components/types";

export const ExclusionFormEditPage: FC<ExclusionsFormPagesProps> = () => {
  const params = useParams() as { id: string };
  const { data, loading: isDataLoading } = useHealthProgramExclusionOptionQuery({
    variables: {
      id: params.id,
    },
  });
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <ExclusionForm
      exclusions={data?.healthProgramExclusionOption}
      onCancel={handleCancel}
      isDataLoading={isDataLoading}
      isEditMode={true}
    />
  );
};
