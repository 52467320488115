/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramSortingInput>;
}>;


export type HealthProgramsQuery = { __typename?: 'Query', healthPrograms?: { __typename?: 'HealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramCountableEdge', node: { __typename?: 'HealthProgram', id: string, name?: string | null, description?: string | null, isActive?: boolean | null, targetGroup?: string | null, totalMembers?: number | null, subscriptionPeriod?: Types.HealthProgramSubscriptionPeriod | null, code?: string | null, type?: Types.HealthProgramType | null, healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, name?: string | null } }> } | null, healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null } }> } | null, benefitGroups?: Array<{ __typename?: 'BenefitGroupComplex', benefitGroup?: { __typename?: 'BenefitGroup', id: string, name?: string | null, description?: string | null, created?: any | null } | null, optionsValues?: Array<{ __typename?: 'BenefitOptionComplex', id: string, name?: string | null, value?: number | null } | null> | null } | null> | null } }> } | null };


export const HealthProgramsDocument = gql`
    query healthPrograms($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramFilterInput, $sortBy: HealthProgramSortingInput) {
  healthPrograms: healthPrograms(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        description
        isActive
        targetGroup
        totalMembers
        subscriptionPeriod
        healthProgramMemberLists(first: 10) {
          edges {
            node {
              id
              name
            }
          }
        }
        code
        type
        healthProgramNetworks(first: 10) {
          edges {
            node {
              id
              name
            }
          }
        }
        benefitGroups {
          benefitGroup {
            id
            name
            description
            created
          }
          optionsValues {
            id
            name
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramsQuery__
 *
 * To run a query within a React component, call `useHealthProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramsQuery, HealthProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramsQuery, HealthProgramsQueryVariables>(HealthProgramsDocument, options);
      }
export function useHealthProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramsQuery, HealthProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramsQuery, HealthProgramsQueryVariables>(HealthProgramsDocument, options);
        }
export type HealthProgramsQueryHookResult = ReturnType<typeof useHealthProgramsQuery>;
export type HealthProgramsLazyQueryHookResult = ReturnType<typeof useHealthProgramsLazyQuery>;
export type HealthProgramsQueryResult = Apollo.QueryResult<HealthProgramsQuery, HealthProgramsQueryVariables>;