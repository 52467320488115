import { HealthProgram } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import {
  healthProgramFieldBreadcrumb,
  healthProgramListBreadcrumb,
  healthProgramTitleBreadcrumb,
} from "pages/HealthProgram/constants/health-program-breadcrumbs";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import HealthProgramForm from "../form/components/HealthProgramForm";
import { IHealthProgramInput } from "../types";
import { useHealthProgramEditHook } from "./HealthProgramEdit.hook";

interface HealthProgramsFormEditPageProps {}

export const HealthProgramsFormEditPage: FC<HealthProgramsFormEditPageProps> = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, healthProgram, loading, onSubmit, handleCancel, isSubmitting } = useHealthProgramEditHook();
  const methods = useForm<IHealthProgramInput>({
    mode: "onChange",
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (healthProgram?.name) {
      setBreadCrumb({
        title: healthProgramTitleBreadcrumb(t),
        values: [healthProgramListBreadcrumb(t), healthProgramFieldBreadcrumb(healthProgram.name)],
      });
    }
  }, [healthProgram?.name]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              isLoading={loading}
              hasFormButton
              newButtonDisabled={isSubmitting}
              formButtonTitle={t("Update")}
              onNavigation={handleCancel}
            />
          }
        >
          <HealthProgramForm errors={errors} healthProgram={healthProgram as Partial<HealthProgram>} loading={loading} />
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
