/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSurveyMutationVariables = Types.Exact<{
  input: Types.SurveyInput;
}>;


export type CreateSurveyMutation = { __typename?: 'Mutation', createSurvey?: { __typename?: 'Survey', createdDate?: any | null, description?: string | null, id?: string | null, label?: string | null, notification?: string | null, status?: Types.SurveyStatus | null, createdBy?: { __typename?: 'User', id: string, fullName?: string | null } | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, questions?: Array<{ __typename?: 'Question', createdDate?: any | null, required?: boolean | null, questionType?: Types.QuestionType | null, question?: string | null, options?: Array<string | null> | null, id?: string | null }> | null } | null };


export const CreateSurveyDocument = gql`
    mutation CreateSurvey($input: SurveyInput!) {
  createSurvey(input: $input) {
    createdBy {
      id
      fullName
    }
    createdDate
    description
    errors {
      code
      errorType
      field
      message
    }
    id
    label
    notification
    questions {
      createdDate
      required
      questionType
      question
      options
      id
    }
    status
  }
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;