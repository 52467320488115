import { AccountError, AppRoleTypes, UserUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  adminUserManagementFieldBreadcrumb,
  adminUserManagementListBreadcrumb,
  adminUserManagementTitleBreadcrumb,
} from "pages/UserManagement/constants/admin-user-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateUserMutation, UserQuery, useUpdateUserMutation, useUserQuery } from "../../gql";
import { AdminUserInputData } from "../Form/components/UserForm.types";

export const useAdminUserManagementEditHook = () => {
  const { t } = useTranslation("admin");
  const params = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const { succeeded, failed } = useAddToast();
  const [updateUserMutation, { loading: isUpdateLoading }] = useUpdateUserMutation({
    onCompleted: (mutationData: UpdateUserMutation) => {
      const error = mutationData?.userUpdate?.accountErrors as AccountError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("User updated successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed in updating user"));
    },
  });

  const { data, loading } = useUserQuery({
    variables: {
      userId: params.id as string,
    },
    onCompleted: (dataQuery: UserQuery) => {
      if (!dataQuery?.user) {
        failed(t("User not found"));
      }
    },
    fetchPolicy: "no-cache",
  });
  const user = data?.user;
  const handleUpdate = (inputData: AdminUserInputData) => {
    const addGroups = inputData?.addGroups?.map(group => group?.id);
    const oldUserPermissionGroups = user?.permissionGroups?.map(group => group?.id || "");
    const removeGroups = oldUserPermissionGroups?.filter(group => !addGroups?.includes(group));
    const request: UserUpdateInput = {
      ...inputData,
      firstName: inputData?.firstName,
      lastName: inputData?.lastName,
      branches: inputData?.branches?.map(branch => branch?.id),
      appRole: inputData?.appRole?.value,
      isActive: inputData?.isActive,
      mobile: inputData?.mobile,
      removeGroups,
      addGroups,
    };
    if (request.appRole === AppRoleTypes.Admin) {
      delete request.branches;
      delete request?.addGroups;
    }
    const filtered = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(request).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );
    updateUserMutation({
      variables: {
        userUpdateId: params?.id as string,
        input: filtered,
      },
    });
  };

  useEffect(() => {
    if (user?.firstName) {
      setBreadCrumb({
        title: adminUserManagementTitleBreadcrumb(t),
        values: [adminUserManagementListBreadcrumb(t), adminUserManagementFieldBreadcrumb(user.firstName)],
      });
    }
  }, [user?.firstName]);

  return { handleUpdate, errors, isUpdateLoading, user, loading };
};
