import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const medicalFormClassificationAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
    medicalFormClassifications: z.array(z.object({
        id: z.string(),
        name: z.string(),
        rangeFrom: z.number().nullable(),
        rangeTo: z.number().nullable(),
    })),
}));
export const MedicalFormClassificationAutocompleteSchemas = {
    medicalFormClassificationAutocomplete,
    medicalFormClassificationAutocompleteOptional: medicalFormClassificationAutocomplete.nullable().optional(),
};
