import { getLastPublishedCriteria } from "pages/Surveys/utils";
import { UseSurveyCardProps } from "./SurveyCard.types";
import { formatDate } from "./utils";

export const useSurveyCard = ({ survey }: UseSurveyCardProps) => {
  const { createdDate, label, id, description, status, publishCriteria, createdBy } = survey;
  const lastPublishedCriteria = getLastPublishedCriteria(publishCriteria);

  const createdDateText = formatDate(createdDate);
  const creatorName = createdBy?.fullName ?? "";
  const endDateText = formatDate(lastPublishedCriteria?.expirationDate);
  const publishDateText = formatDate(lastPublishedCriteria?.createdDate);

  return {
    id,
    status,
    label,
    description,
    creatorName,
    endDateText: lastPublishedCriteria ? endDateText : "",
    publishDateText: lastPublishedCriteria ? publishDateText : "",
    participantsCount: lastPublishedCriteria?.participantsCount ?? 0,
    createdDateText,
  };
};
