import { i18n } from "@toolkit/i18n";
import moment from "moment";
import { z } from "zod";

export const PromotionUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    nameAr: z.string().min(3).max(255),
    content: z.string().min(3),
    contentAr: z.string().min(3),
    description: z.string().min(3).max(255),
    descriptionAr: z.string().min(3).max(255),
    endDate: z.string(),
    photo: z.string(),
    startDate: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data?.startDate && data?.endDate) {
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      if (startDate.isAfter(endDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("End date must be after start date", "admin"),
          path: ["endDate"],
        });
      } else if (startDate.isBefore(moment().format("YYYY-MM-DD"))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Start date must be after today", "admin"),
          path: ["startDate"],
        });
      }
    }
  });

export type IPromotionUpsertFormValues = z.infer<typeof PromotionUpsertFormSchema>;

export const promotionUpsertFormSchemaDefaultValues: Partial<IPromotionUpsertFormValues> = {
  content: undefined,
  contentAr: undefined,
  description: undefined,
  descriptionAr: undefined,
  endDate: undefined,
  name: undefined,
  nameAr: undefined,
  photo: undefined,
  startDate: undefined,
};
