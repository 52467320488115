import { toSentenceCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getConceptPropertyValue } from "../AdminManagedListDetails/utils";
import { adminManageListBreadcrumb, adminManageListFieldBreadcrumb, adminManageListsTitleBreadcrumb, adminManagedListsPaths, } from "../constants";
import { useFetchDataForEditCodeSystemConceptQuery, useUpdateCodeSystemConceptMutation } from "../gql";
import { getPropertyValueObjectForBackend, getValidationErrors } from "./utils";
export const useEditAdminManagedListDetailsHook = () => {
    var _a;
    const methods = useForm();
    const navigate = useNavigate();
    const { t } = useTranslation("domains");
    const { failed, succeeded } = useAddToast();
    const { setBreadCrumb } = useBreadCrumbs();
    const { code, id, codeSystemConceptId } = useParams();
    const [isLoadingPropValues, setIsLoadingPropValues] = useState(true);
    const { setValue, formState } = methods;
    const isChanged = formState === null || formState === void 0 ? void 0 : formState.isDirty;
    const { data: editCodeSystemFetchData, loading: isLoadingEditCodeSystemFetchData } = useFetchDataForEditCodeSystemConceptQuery({
        variables: {
            id: id,
            codeSystemConceptId: codeSystemConceptId,
        },
        fetchPolicy: "no-cache",
        onCompleted: ({ propertiesData }) => {
            const properties = (propertiesData === null || propertiesData === void 0 ? void 0 : propertiesData.properties) || [];
            properties.forEach(propertyItem => {
                setValue(propertyItem === null || propertyItem === void 0 ? void 0 : propertyItem.code, getConceptPropertyValue(propertyItem));
            });
            setIsLoadingPropValues(false);
        },
    });
    const [updateCodeSystemConcept, { loading: isFetchingUpdate }] = useUpdateCodeSystemConceptMutation({
        onCompleted: () => {
            succeeded(t("You have updated the data successfully"));
            navigate(-1);
        },
        onError: ({ message }) => {
            failed(message);
        },
    });
    const isLoading = isLoadingPropValues || isLoadingEditCodeSystemFetchData;
    const { propertiesData, schemaData } = editCodeSystemFetchData || {};
    const codeDisplayName = toSentenceCase(code);
    const properties = (propertiesData === null || propertiesData === void 0 ? void 0 : propertiesData.properties) || [];
    const conceptFieldsDefenitions = (((_a = schemaData === null || schemaData === void 0 ? void 0 : schemaData.codeSystemDefinition) === null || _a === void 0 ? void 0 : _a.codeSystemConceptFields) || []);
    useEffect(() => {
        if (code && id) {
            setBreadCrumb({
                title: adminManageListsTitleBreadcrumb(t),
                values: [
                    adminManageListBreadcrumb(t),
                    adminManageListFieldBreadcrumb(toSentenceCase(code), adminManagedListsPaths.detailsPath.fullPathWithParams(code, id)),
                ],
            });
        }
    }, [code, id]);
    function reportErrors(errors) {
        errors.forEach(err => {
            failed(err.message);
            methods.setError(err.code, {
                message: err.message,
                type: "required",
            });
        });
    }
    const onSubmit = (data) => {
        const validationErrors = getValidationErrors(conceptFieldsDefenitions, data);
        if (validationErrors.length > 0) {
            reportErrors(validationErrors);
            return;
        }
        const updatedData = (conceptFieldsDefenitions === null || conceptFieldsDefenitions === void 0 ? void 0 : conceptFieldsDefenitions.map(item => {
            var _a;
            return getPropertyValueObjectForBackend(item, data[(_a = item === null || item === void 0 ? void 0 : item.code) !== null && _a !== void 0 ? _a : ""]);
        })) || [];
        const updatedValues = Object.assign(Object.assign({}, propertiesData), { properties: updatedData });
        delete updatedValues["__typename"];
        delete updatedValues["display"];
        updateCodeSystemConcept({
            variables: {
                codeSystemConcept: updatedValues,
            },
        });
    };
    return {
        methods,
        isChanged,
        isLoading,
        properties,
        codeDisplayName,
        isFetchingUpdate,
        conceptFieldsDefenitions,
        navigate,
        onSubmit,
    };
};
