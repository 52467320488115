import { TextField, styled } from "@mui/material";

export const RoundedTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "28px",
    backgroundColor: "#F1F2FB",
    border: `1px solid #F1F2FB`,
    display: "hidden",
    height: "55px",
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
      "& svg": {
        fill: theme.palette.error[1300],
      },
    },
  },
  "& .MuiSvgIcon-root": {
    marginInline: "10px",
  },
  "& .MuiInputBase-input": {
    width: "100%",
    padding: "5px",
    backgroundColor: "inherit",
    borderRadius: "28px",
    "&::placeholder": {
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      fontSize: theme.mixins.fonts.fontSize.sm,
      color: `${theme.palette.primary[1200]}!important`,
    },
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
      "&::placeholder": {
        color: theme.palette.error[1200],
      },
    },
  },
  "& .MuiFilledInput-input:active": {
    backgroundColor: "#F1F2FB",
  },
  "& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    display: "none",
  },
  "&:hover .MuiInputBase-root": {
    border: "1px solid",
    borderColor: "#F1F2FB",
    backgroundColor: "#F1F2FB",
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
    },
  },
  "&:focus-within .MuiInputBase-root": {
    border: `1px solid #F1F2FB`,
    backgroundColor: "#F1F2FB",
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
    },
  },
  "&:focus-within input": {
    backgroundColor: "#F1F2FB",
  },
  "& input:-webkit-autofill": {
    borderRadius: "28px",
    color: theme.palette.primary[1200],
    "-webkit-box-shadow": `0 0 0 30px ${theme.palette.primary[100]} inset!important`,
  },
  "& .Mui-error": {
    color: theme.palette.error[1300],
    "& input": {
      backgroundColor: theme.palette.error[50],
      color: theme.palette.error[1200],
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      fontSize: theme.mixins.fonts.fontSize.sm,
      "&::placeholder": {
        color: `${theme.palette.error[1200]}!important`,
      },
    },
    "& path": {
      fill: theme.palette.error[1300],
    },
  },
  "& .MuiFormLabel-root": {
    display: "none",
  },
  "&:focus-within .MuiFormLabel-root": {
    display: "block",
  },
}));
