import { VendorError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { VendorUpdateMutation, useVendorUpdateMutation } from "gql/mutations";
import { VendorDocument, VendorQuery, useVendorQuery } from "gql/queries";
import {
  providerFieldBreadcrumb,
  providerListBreadcrumb,
  providersTitleBreadcrumb,
} from "pages/ProvidersManagement/constants/providers-management-breadcrumbs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useVendorEditHook = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const [vendor, setVendor] = useState<any>(undefined);
  const [errors, setError] = useState<VendorError[]>([]);

  const [updateVendor, { loading: isUpdateLoading }] = useVendorUpdateMutation({
    onCompleted: (data: VendorUpdateMutation) => {
      const errors = data.vendorUpdate?.vendorErrors;
      if (errors && errors?.length > 0) {
        const error = formatMessageErrors(errors);
        failed(error);
        setError(errors as VendorError[]);
      } else {
        succeeded(t("Vendor Updated Successfully"));
        navigate(-1);
      }
    },
    refetchQueries: [
      {
        query: VendorDocument,
        variables: {
          id: params.id,
        },
      },
    ],
    onError: () => {
      failed(t("Vendor Updated Failed"));
    },
  });

  const { loading: isLoading } = useVendorQuery({
    variables: {
      id: params.id as string,
    },
    onCompleted: (data: VendorQuery) => {
      if (data.vendor) {
        const _mapped = {
          ...data.vendor,
          subscriptions: data?.vendor?.subscriptions?.edges?.map((sub: any) => sub.node)[0],
        };
        setVendor(_mapped);
      } else {
        failed(t("Vendor Not Found"));
      }
    },
  });

  const handleUpdate = async (data: any) => {
    delete data?.branchWorkingHours;
    delete data?.healthLicenseStartDate;
    delete data?.healthLicenseEndDate;
    const request = {
      ...data,
      logo: data?.logo,
      backGroundImage: data?.backGroundImage,
    };
    await Object.keys(request).forEach(key => request[key] === null && delete request[key]);
    await updateVendor({
      variables: {
        id: String(params.id),
        input: {
          ...request,
        },
      },
    });
  };

  useEffect(() => {
    if (vendor?.name) {
      setBreadCrumb({
        title: providersTitleBreadcrumb(t),
        values: [providerListBreadcrumb(t), providerFieldBreadcrumb(vendor.name)],
      });
    }
  }, [vendor?.name]);

  return {
    vendor,
    errors,
    handleUpdate,
    isLoading,
    isUpdateLoading,
  };
};
