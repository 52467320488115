import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { ChangeEvent, FC, ReactElement } from "react";
import { Controller } from "react-hook-form";
import { Box, FormControlLabel, FormHelperText, SwitchProps } from "../../../base/mui";
import { CustomToggleButton } from "../../ToggleButton";

type FormSwitchProps = SwitchProps & {
  name: string;
  label: string | ReactElement;
};

export const FormSwitch: FC<FormSwitchProps> = props => {
  const { name, label, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.checked);
              };

              return (
                <CustomToggleButton
                  {...rest}
                  {...field}
                  checked={field?.value}
                  disabled={isFormDisabled || disabled}
                  onChange={handleOnChange}
                />
              );
            }}
          />
        }
      />

      {!!error && <FormHelperText error> {error} </FormHelperText>}
    </Box>
  );
};
