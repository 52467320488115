import { i18n } from "@toolkit/i18n";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";

export const branchesTitleBreadcrumb = () => {
  return i18n.t("Branches", { ns: "admin" });
};

export const branchListBreadcrumb = (branchId?: string) => {
  return {
    id: "branch-list",
    name: i18n.t("Branches", { ns: "admin" }),
    route: branchId ? providersManagementPaths.branchesPath.fullPathWithParams(branchId) : undefined,
  };
};

export const branchNewBreadcrumb = () => {
  return {
    id: "branch-new",
    name: i18n.t("New", { ns: "admin" }),
  };
};

export const branchFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "branch-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
