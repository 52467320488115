import { ExclusionOptionInput } from "@health/queries/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, TextField } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useExclusionUpdateForm from "../hooks/useExclusionUpdateForm";
import { ExclusionProps } from "./types";

const ExclusionForm: FC<ExclusionProps> = ({
  onDone,
  errors: errorsCreate,
  onCancel,
  isLoading,
  isDataLoading = false,
  isEditMode = false,
  exclusions,
}) => {
  const { t } = useTranslation("admin");
  const { ...methods } = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { errors: reactFormErrors },
  } = methods;
  const { onUpdate, isUpdateLoading, errors: errorsUpdate } = useExclusionUpdateForm(exclusions?.id);
  const errors = errorsUpdate || errorsCreate;
  const formErrors = combineErrors(reactFormErrors, errors);
  const doYouHaveData = isEditMode ? exclusions != undefined : true;
  const isFormLoading = isDataLoading;
  const onSubmit = (data: ExclusionOptionInput) => {
    const request = {
      ...data,
    };
    if (exclusions) {
      onUpdate(request);
    } else if (onDone) {
      onDone(request);
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  const loadingTittle = (() => {
    if (isLoading || isUpdateLoading) {
      return t("Loading...");
    } else if (exclusions != undefined) {
      return t("Update");
    } else {
      return t("Create");
    }
  })();
  return (
    <FormProvider {...methods}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageWrapper
            actions={
              <FormActions isLoading={isLoading} hasCancel hasFormButton formButtonTitle={loadingTittle} onNavigation={handleCancel} />
            }
          >
            <FormCard loading={isFormLoading} doYouHaveData={doYouHaveData} title={t("Exclusions")}>
              <Grid container columnSpacing={2}>
                <Grid item xs={4}>
                  <TextField
                    placeholder={t("Name")}
                    label={t("Name")}
                    defaultValue={exclusions?.name}
                    fullWidth
                    error={Boolean(formErrors.name?.message)}
                    helperText={t(formErrors.name?.message)}
                    {...register("name", { required: { value: true, message: t("Required") } })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    placeholder={t("Description")}
                    label={t("Description")}
                    defaultValue={exclusions?.description}
                    fullWidth
                    error={Boolean(formErrors.description?.message)}
                    helperText={t(formErrors.description?.message)}
                    {...register("description", { required: { value: true, message: t("Required") } })}
                  />
                </Grid>
              </Grid>
            </FormCard>
          </PageWrapper>
        </form>
      </Grid>
    </FormProvider>
  );
};
export default ExclusionForm;
