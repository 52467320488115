import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestionType } from "@health/queries/types";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormNumberField, FormTextField, Grid, IconButton, MuiDeleteForeverIcon, PlusIcon, useIsMobileView, useTheme, } from "@toolkit/ui";
import { isNil } from "lodash";
import { uniqueId } from "lodash";
import { useFieldArray } from "react-hook-form";
export const MedicalFormQuestionOptionUpsertForm = () => {
    var _a;
    const { t } = useTranslation("domains");
    const isMobileView = useIsMobileView();
    const theme = useTheme();
    const form = useCustomFormContext();
    const { watch, control } = form;
    const { append, remove } = useFieldArray({
        control: control,
        name: "options",
    });
    const options = watch("options");
    const isQuestionTypeBoolean = ((_a = watch("questionType")) === null || _a === void 0 ? void 0 : _a.value) === QuestionType.Boolean;
    const isAddOptionDisabled = !!options.find(item => !item.value || isNil(item === null || item === void 0 ? void 0 : item.score));
    const isRemoveQuestionAvailable = !isQuestionTypeBoolean && options.length !== 1;
    const onAddQuestionClick = () => {
        append({
            id: uniqueId("field-"),
            value: "",
            score: null,
        });
    };
    const onRemoveQuestionClick = (index) => {
        remove(index);
    };
    return (_jsx(Grid, { container: true, spacing: 2, children: options.map((option, index) => {
            return (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, alignItems: 'center', flexWrap: isMobileView ? "nowrap" : "wrap", children: [_jsx(Grid, { item: true, xs: isQuestionTypeBoolean ? 12 : 10, children: _jsxs(Grid, { container: true, spacing: 1, alignItems: "center", children: [_jsx(Grid, { item: true, xs: 8, children: _jsx(FormTextField, { name: `options.${index}.value`, placeholder: t("Option"), label: t("Option"), disabled: isQuestionTypeBoolean }) }), _jsx(Grid, { item: true, xs: isRemoveQuestionAvailable ? 3 : 4, children: _jsx(FormNumberField, { name: `options.${index}.score`, placeholder: t("Score"), label: t("Score") }) }), isRemoveQuestionAvailable && (_jsx(Grid, { item: true, xs: 1, children: _jsx(IconButton, { onClick: () => onRemoveQuestionClick(index), children: _jsx(MuiDeleteForeverIcon, {}) }) }))] }) }), !isQuestionTypeBoolean && index === 0 && (_jsx(Grid, { item: true, xs: 2, textAlign: "end", children: _jsx(Button, { onClick: onAddQuestionClick, disabled: isAddOptionDisabled, startIcon: _jsx(PlusIcon, { fill: theme.palette.common.white }), children: t("Add Option") }) }))] }) }, option === null || option === void 0 ? void 0 : option.id));
        }) }));
};
