import { i18n } from "@toolkit/i18n";
export var GuidedCareActivityItemFilter;
(function (GuidedCareActivityItemFilter) {
    GuidedCareActivityItemFilter["ShowAllActivities"] = "ShowAllActivities";
    GuidedCareActivityItemFilter["HideMedicalMessages"] = "HideMedicalMessages";
})(GuidedCareActivityItemFilter || (GuidedCareActivityItemFilter = {}));
export const guidedCareActivityItemFilterOptionsMap = {
    [GuidedCareActivityItemFilter.ShowAllActivities]: {
        key: GuidedCareActivityItemFilter.ShowAllActivities,
        get label() {
            return i18n.t("Show all activities", { ns: "domains" });
        },
        value: GuidedCareActivityItemFilter.ShowAllActivities,
    },
    [GuidedCareActivityItemFilter.HideMedicalMessages]: {
        key: GuidedCareActivityItemFilter.HideMedicalMessages,
        get label() {
            return i18n.t("Hide patient engagement msgs", { ns: "domains" });
        },
        value: GuidedCareActivityItemFilter.HideMedicalMessages,
    },
};
export const guidedCareActivityItemFilterOptions = Object.values(guidedCareActivityItemFilterOptionsMap);
