import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const branchAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const BranchAutocompleteSchemas = {
    branchAutocomplete,
    branchAutocompleteOptional: branchAutocomplete.nullable().optional(),
};
