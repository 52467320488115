/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import { CityFragmentFragmentDoc } from '../../../pages/CitiesManagement/gql/fragments/__generated__/cityFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CitiesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CityFilterInput>;
  sortBy?: Types.InputMaybe<Types.CityOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CitiesQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null, created: any } }> } | null };


export const CitiesDocument = gql`
    query cities($filter: CityFilterInput, $sortBy: CityOrder, $before: String, $after: String, $first: Int = 10, $last: Int) {
  cities(
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...CityFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${CityFragmentFragmentDoc}`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;