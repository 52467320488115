import { ExclusionOptionInput, H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useExclusionOptionCreateMutation } from "pages/HealthProgram/gql";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExclusionForm from "./components/ExclusionForm";
import { ExclusionsFormPagesProps } from "./components/types";

export const ExclusionFormNewPage: FC<ExclusionsFormPagesProps> = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const [errors, setError] = useState<H_EntityError[]>();
  const [exclusionOptionsCreate] = useExclusionOptionCreateMutation({
    onCompleted: data => {
      const errors = data.exclusionOptionCreate?.healthProgramErrors!;
      if (errors?.length === 0) {
        succeeded(t("Exclusion Created Successfully"));
        navigate("/health-program/exclusion");
      } else {
        setError(errors! || null);
        failed(t(formatMessageErrors(errors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t("Failed to create exclusion"));
      failed(t(message));
    },
  });
  const handleSubmit = (data: ExclusionOptionInput) => {
    exclusionOptionsCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };
  const handleCancel = () => {
    navigate("/health-program/exclusion");
  };
  return <ExclusionForm onDone={handleSubmit} onCancel={handleCancel} errors={errors} isEditMode={false} />;
};
