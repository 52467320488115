/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceBrandQueryVariables = Types.Exact<{
  marketplaceBrandId: Types.Scalars['ID'];
}>;


export type MarketplaceBrandQuery = { __typename?: 'Query', marketplaceBrand?: { __typename?: 'MarketplaceBrand', id: string, image?: string | null, name?: string | null, nameAr?: string | null } | null };


export const MarketplaceBrandDocument = gql`
    query MarketplaceBrand($marketplaceBrandId: ID!) {
  marketplaceBrand(id: $marketplaceBrandId) {
    id
    image
    name
    nameAr
  }
}
    `;

/**
 * __useMarketplaceBrandQuery__
 *
 * To run a query within a React component, call `useMarketplaceBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceBrandQuery({
 *   variables: {
 *      marketplaceBrandId: // value for 'marketplaceBrandId'
 *   },
 * });
 */
export function useMarketplaceBrandQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceBrandQuery, MarketplaceBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceBrandQuery, MarketplaceBrandQueryVariables>(MarketplaceBrandDocument, options);
      }
export function useMarketplaceBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceBrandQuery, MarketplaceBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceBrandQuery, MarketplaceBrandQueryVariables>(MarketplaceBrandDocument, options);
        }
export type MarketplaceBrandQueryHookResult = ReturnType<typeof useMarketplaceBrandQuery>;
export type MarketplaceBrandLazyQueryHookResult = ReturnType<typeof useMarketplaceBrandLazyQuery>;
export type MarketplaceBrandQueryResult = Apollo.QueryResult<MarketplaceBrandQuery, MarketplaceBrandQueryVariables>;