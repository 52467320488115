import { Components, Theme } from "@mui/material/styles";

export const MuiFilledInputOverride: Components<Theme>["MuiFilledInput"] = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    underline: "none",
    input: ({ theme }) => ({
      color: theme.palette.text.secondary,
      borderRadius: 8,
      paddingTop: 8,
      paddingRight: 8,
      paddingLeft: 8,
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      "&:active": {
        backgroundColor: theme.palette.common.white,
      },
      "&:focus": {
        backgroundColor: theme.palette.common.white,
      },
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 0,
      },
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: "5px",
      },
      "&.MuiInputBase-inputHiddenLabel": {
        paddingTop: 12,
        paddingBottom: 12,
      },
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.gray[50],
        borderColor: theme.palette.gray[600],
      },
      // Autocomplete input color and placeholder
      "&.MuiInputBase-input::placeholder": {
        opacity: 1,
        color: theme.palette.stale.main,
        fontSize: theme.mixins.fonts.fontSize.sm,
      },
    }),
    root: ({ theme }) => ({
      minHeight: 40,
      backgroundColor: theme.palette.common.white,
      borderRadius: 10,
      border: "1.5px  solid #C7D6EE",
      color: theme.palette.stale.main,
      "&:hover": {
        borderColor: theme.palette.stale.main,
        backgroundColor: theme.palette.common.white,
      },
      "&.MuiInputBase-colorError": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
      "&.MuiInputBase-colorSecondary": {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
      "&.Mui-focused": {
        backgroundColor: theme.palette.common.white,
      },
      "&.MuiInputBase-adornedStart": {},
      "&.MuiInputBase-adornedEnd": {
        paddingRight: 17,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.gray[50],
        borderColor: theme.palette.gray[600],
      },
    }),
    multiline: {
      "& > textarea": {
        paddingTop: 0,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  },
};
