import { MarketplaceOrderType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const marketplaceOrderTypeOptionsMap = {
    [MarketplaceOrderType.HealthPackage]: {
        key: MarketplaceOrderType.HealthPackage,
        get label() {
            return i18n.t("Health Package", { ns: "domains" });
        },
        value: MarketplaceOrderType.HealthPackage,
    },
    [MarketplaceOrderType.Product]: {
        key: MarketplaceOrderType.Product,
        get label() {
            return i18n.t("Health Product", { ns: "domains" });
        },
        value: MarketplaceOrderType.Product,
    },
};
export const marketplaceOrderTypeOptions = Object.values(marketplaceOrderTypeOptionsMap);
