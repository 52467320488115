import { makeStyles } from "@toolkit/ui";

export const useSettingViewStyle = makeStyles()(theme => ({
  box: {
    height: 55,
    display: "flex",
    alignItems: "center",
    background: theme.palette.common.white,
    paddingInline: 20,
    overflow: "hidden",
  },
  selected: {
    backgroundColor: theme.palette.stale[50],
  },
  button: {
    borderRadius: 0,
    height: 60,
    whiteSpace: "nowrap",
    paddingInline: 20,
    margin: 0,
    "&:hover": {
      borderRadius: `0px !important`,
      height: `60px !important`,
    },
  },
}));
