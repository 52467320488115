import { Group, PermissionGroupSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { PermissionsGroup } from "./PermissionsGroup.component";

export type PermissionsGroupColumnTypes = CustomTableColumnProps<Group>;
export type PermissionsGroupColumnsTypes = PermissionsGroupColumnTypes[];

export const usePermissionsManagementColumns = (): PermissionsGroupColumnsTypes => {
  const { t } = useTranslation("admin");
  const isEditableOptions = [
    { name: t("Editable"), value: true },
    { name: t("Not editable"), value: false },
  ];
  const isGlobalOptions = [
    { name: t("Global"), value: true },
    { name: t("Not global"), value: false },
  ];
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: row => <TruncateTypography text={row?.name} />,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: PermissionGroupSortField.Name,
      },
      {
        key: "permissions",
        header: t("Permissions Group"),
        accessor: row => <PermissionsGroup permissions={row?.permissions} />,
      },
      {
        key: "search",
        header: t("Search"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "isEditable",
        header: t("Is editable"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "isEditable",
          options: isEditableOptions,
          getOptionLabel: option => option?.name,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "isGlobal",
        header: t("Is global"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "isGlobal",
          options: isGlobalOptions,
          getOptionLabel: option => option?.name,
          getValueForBackend: option => option?.value,
        },
      },
    ];
  }, []);
};
