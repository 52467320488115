import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useToasts } from "@toolkit/ui";
import { UpdatePlanActiveStatusMutation, useUpdatePlanActiveStatusMutation } from "gql/mutations";
import { FC } from "react";

interface ToggleActiveSwitchProps {
  id: string;
  isActive: boolean;
}

export const ToggleActiveSwitch: FC<ToggleActiveSwitchProps> = props => {
  const { id, isActive } = props;
  const { addToast } = useToasts();
  const { t } = useTranslation("admin");

  const [updatePlanActivityStatus] = useUpdatePlanActiveStatusMutation({
    onCompleted: (data: UpdatePlanActiveStatusMutation) => {
      if (!data?.planUpdate?.subscriptionErrors?.length) {
        addToast(t("Your plan Active status has been updated"), {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        const message = data?.planUpdate?.subscriptionErrors?.map(error => error.message);
        addToast(t(message as unknown as string), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    onError: (error: any) => {
      const message = t(error.message) || t("Create plan error");
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
      console.error("create plan error", error);
    },
  });
  const handleActiveToggle = () => {
    updatePlanActivityStatus({
      variables: {
        id,
        input: {
          isActive: !isActive,
        },
      },
    });
  };
  return <CustomToggleButton checked={isActive} onChange={handleActiveToggle} />;
};
