import { getAutocompleteFilter } from "@toolkit/ui";
import { useSystemCodeAutocompleteQuery } from "./gql";
export const getSystemCodeAutocompleteFilter = ({ name, isCodeHidden, multiple, queryFilter, queryVariables, }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "codeSystemConceptSearch",
        query: useSystemCodeAutocompleteQuery,
        labelBy: isCodeHidden ? "display" : option => `${option === null || option === void 0 ? void 0 : option.code} - ${option === null || option === void 0 ? void 0 : option.display}`,
        backendAccessor: "code",
        isFilterSearchKeyAQueryVariable: true,
        filterSearchKey: "keyword",
        filtersForQuery: queryFilter,
        queryVariables,
    });
};
