import { vendorUserTypeOptions, vendorUserTypeOptionsMap } from "@health/enum-options";
import { AccountError, AppRoleTypes, VendorUserTypes } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormCard, FormControlLabel, FormPhoneInput, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { appRolesAsInput } from "../../../UserManagement.constant";
import { AutocompleteAppComponent } from "./CustomAppComponent";
import { userFields } from "./UserInformation.utils";

export const UserInformation: FC<{ errors: AccountError[]; defaultValues; isEdit; isLoading; doYouHaveUser }> = ({
  errors,
  defaultValues,
  isEdit,
  isLoading,
  doYouHaveUser,
}) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: formErrors },
    control,
    watch,
    setValue,
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);

  const appRoleDefaultValue = appRolesAsInput(t)?.find(el => el?.value === defaultValues?.appRole) || null;
  const vendorUserDefaultValue = vendorUserTypeOptionsMap?.[defaultValues?.vendorUserType || ""] || undefined;
  const watchVendorUserType = watch("vendorUserType");
  const filteredAppRole =
    watchVendorUserType?.value === VendorUserTypes.Manager || vendorUserDefaultValue?.value === VendorUserTypes.Manager
      ? appRolesAsInput(t).filter(role => role.value != AppRoleTypes.User)
      : appRolesAsInput(t);

  useEffect(() => {
    setValue("mobile", defaultValues?.mobile ?? "");
  }, [defaultValues]);

  return (
    <FormCard doYouHaveData={doYouHaveUser} loading={isLoading} title={t("User Information")}>
      <Grid container spacing={formGirdSpacing}>
        {userFields(defaultValues, isEdit)?.map(item => (
          <Grid item {...formGirdBreakPoints} key={item?.name}>
            <TextField
              inputProps={{
                autoComplete: item?.autoComplete ?? "off",
              }}
              fullWidth
              error={Boolean(combinedErrors?.[item?.name]?.message)}
              helperText={t(combinedErrors?.[item?.name]?.message)}
              defaultValue={item?.defaultValue}
              label={item?.label}
              placeholder={item?.label}
              {...register(item?.name, {
                required: getRequiredValidation(t, item?.isRequired),
                minLength: item?.minLength,
                maxLength: item?.maxLength,
                pattern: item?.pattern,
              })}
              type={item?.type}
              disabled={item?.disabled}
            />
          </Grid>
        ))}
        <Grid item {...formGirdBreakPoints}>
          <FormPhoneInput
            {...register("mobile", {
              required: getRequiredValidation(t, true),
            })}
            label={t("Mobile Number")}
            error={Boolean(combinedErrors?.mobile?.message)}
            helperText={t(combinedErrors?.mobile?.message)}
            fullWidth
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <AutocompleteAppComponent
            formErrors={errors}
            name={"appRole"}
            label={t("App Role")}
            options={filteredAppRole}
            defaultAppValue={appRoleDefaultValue}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <AutocompleteAppComponent
            formErrors={errors}
            name={"vendorUserType"}
            label={t("Vendor User Type")}
            options={vendorUserTypeOptions}
            defaultAppValue={vendorUserDefaultValue}
            isDisabled={isEdit}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={formGirdSpacing} item alignItems='center' sx={{ padding: "8px" }}>
        <Grid item {...formGirdBreakPoints}>
          <Controller
            name='isActive'
            defaultValue={isEdit ? defaultValues?.isActive : true}
            control={control}
            render={({ field: { onChange: handleC, value } }) => (
              <FormControlLabel
                control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                label={t("Is Active")}
              />
            )}
          />
        </Grid>
        {(watchVendorUserType?.value == VendorUserTypes.Doctor || vendorUserDefaultValue?.value == VendorUserTypes.Doctor) && (
          <>
            <Grid item {...formGirdBreakPoints}>
              <Controller
                name='doctorInfo.isMobileNumberPublic'
                defaultValue={isEdit ? defaultValues?.doctor?.isMobileNumberPublic : true}
                control={control}
                render={({ field: { onChange: handleC, value } }) => (
                  <FormControlLabel
                    control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                    label={t("Is Mobile Number Public")}
                  />
                )}
              />
            </Grid>
            <Grid item {...formGirdBreakPoints}>
              <Controller
                name='doctorInfo.isNationalIdPublic'
                defaultValue={isEdit ? defaultValues?.doctor?.isNationalIdPublic : true}
                control={control}
                render={({ field: { onChange: handleC, value } }) => (
                  <FormControlLabel
                    control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                    label={t("Is National Id Public")}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormCard>
  );
};
