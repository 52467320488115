import { H_EntityError, HealthProgramMember } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  memberFieldBreadcrumb,
  membersBreadcrumb,
  membersTitleBreadcrumb,
} from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useHealthProgramMemberUpdateMutation, useHealthProgramMembersQuery } from "../gql";
import { HealthProgramMemberForm } from "./components/HealthProgramMemberForm";

export const HealthProgramMemberEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);
  const id = params?.id;
  const { setBreadCrumb } = useBreadCrumbs();
  const showMemberListSection = String(location?.pathname).includes("/member/edit");

  const { data, loading } = useHealthProgramMembersQuery({
    skip: !params?.memberId,
    variables: {
      filter: {
        ids: [String(params?.memberId)],
      },
    },
  });

  const healthProgramMember = data?.healthProgramMembers?.edges?.[0]?.node;

  const [healthProgramMemberUpdate, { loading: isSubmitting }] = useHealthProgramMemberUpdateMutation({
    onCompleted: input => {
      const entityErrors = input?.healthProgramMemberUpdate?.entityErrors!;
      if (entityErrors?.length === 0) {
        succeeded(t("Member Updated Successfully"));
        navigate(-1);
      } else {
        setError(entityErrors);
        failed(t(formatMessageErrors(entityErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t(message));
    },
  });
  const handleSubmit = input => {
    const { coverageBy, gender, healthProgramMemberLists, idType, nationality, ...rest } = input;
    delete rest?.payerId;
    healthProgramMemberUpdate({
      variables: {
        id: String(healthProgramMember?.id),
        input: {
          coverageBy: coverageBy?.value,
          gender: gender?.value,
          healthProgramMemberLists: showMemberListSection ? healthProgramMemberLists?.map(item => item?.id) : [id],
          idType: idType?.value,
          nationality: nationality?.value?.code,
          ...rest,
        },
      },
    });
  };

  useEffect(() => {
    if (healthProgramMember?.firstName) {
      setBreadCrumb({
        title: membersTitleBreadcrumb(t),
        values: [membersBreadcrumb(t), memberFieldBreadcrumb(healthProgramMember.firstName)],
      });
    }
  }, [healthProgramMember?.firstName]);

  return (
    <HealthProgramMemberForm
      isLoading={loading}
      errors={errors}
      defaultValue={healthProgramMember as Partial<HealthProgramMember>}
      onDone={handleSubmit}
      showMemberListSection={showMemberListSection}
      isSubmitting={isSubmitting}
    />
  );
};
