export function getCurrentLocation(): Promise<{ lat: number; lng: number }> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      },
      error => {
        console.error("Error Code = " + error.code + " - " + error.message);
        reject(error);
      }
    );
  });
}
