/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProviderAndNetworkQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramNetworkProviderFilterInput>;
  filterNetwork?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
}>;


export type GetProviderAndNetworkQuery = { __typename?: 'Query', healthProgramNetworkProviders?: { __typename?: 'HealthProgramNetworkProviderCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkProviderCountableEdge', node: { __typename?: 'HealthProgramNetworkProvider', id: string, provider?: { __typename?: 'Vendor', name: string } | null } }> } | null, healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null } }> } | null };


export const GetProviderAndNetworkDocument = gql`
    query getProviderAndNetwork($filter: HealthProgramNetworkProviderFilterInput, $filterNetwork: HealthProgramNetworkFilterInput) {
  healthProgramNetworkProviders(first: 1, filter: $filter) {
    edges {
      node {
        id
        provider {
          name
        }
      }
    }
  }
  healthProgramNetworks(first: 1, filter: $filterNetwork) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProviderAndNetworkQuery__
 *
 * To run a query within a React component, call `useGetProviderAndNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderAndNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderAndNetworkQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      filterNetwork: // value for 'filterNetwork'
 *   },
 * });
 */
export function useGetProviderAndNetworkQuery(baseOptions?: Apollo.QueryHookOptions<GetProviderAndNetworkQuery, GetProviderAndNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderAndNetworkQuery, GetProviderAndNetworkQueryVariables>(GetProviderAndNetworkDocument, options);
      }
export function useGetProviderAndNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderAndNetworkQuery, GetProviderAndNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderAndNetworkQuery, GetProviderAndNetworkQueryVariables>(GetProviderAndNetworkDocument, options);
        }
export type GetProviderAndNetworkQueryHookResult = ReturnType<typeof useGetProviderAndNetworkQuery>;
export type GetProviderAndNetworkLazyQueryHookResult = ReturnType<typeof useGetProviderAndNetworkLazyQuery>;
export type GetProviderAndNetworkQueryResult = Apollo.QueryResult<GetProviderAndNetworkQuery, GetProviderAndNetworkQueryVariables>;