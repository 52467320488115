import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { AutocompleteComponent } from "../Autocomplete/Autocomplete";
import { AutocompleteControllerComponentProps } from "./types";

export const AutocompleteController: FC<AutocompleteControllerComponentProps> = ({ ControllerProps, ...props }) => {
  const { t } = useTranslation();
  return (
    <Controller
      {...ControllerProps}
      name={ControllerProps?.name}
      rules={
        ControllerProps?.rules ?? {
          required: getRequiredValidation(t, true),
        }
      }
      defaultValue={ControllerProps?.defaultValue}
      render={({ field }) => <AutocompleteComponent defaultValue={ControllerProps?.defaultValue} field={field} {...props} />}
    />
  );
};
