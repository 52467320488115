import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, useAutocompleteCustomHook } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useProviderListQuery } from "../../gql";
import { IProps } from "../types";

export const Providers: FC<IProps> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");
  const inputs = {
    first: 10,
    filter: {
      isActive: true,
    },
  };
  const {
    control,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const { data, isLoading, onFetchMoreData, refetch } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useProviderListQuery,
    input: inputs,
    dataAccessor: "vendors",
  });

  const providers = data?.vendors?.edges?.map(item => item?.node);
  const formErrors = combineErrors(reactFormErrors, errors);

  const pageInfo = data?.vendors?.pageInfo;
  const handleVendorSearch = (filter: string) => {
    refetch({
      filter: {
        name_Icontains: filter,
        isActive: true,
      },
    });
  };

  return (
    <AutocompleteController
      ControllerProps={{
        control: control,
        name: "providers",
        defaultValue: providers?.find(el => el?.id === defaultValue?.provider?.id) || null,
      }}
      hasMore={pageInfo?.hasNextPage}
      fetchMoreData={onFetchMoreData}
      loading={isLoading}
      options={providers}
      TextFieldProps={{
        label: t("Providers"),
        error: Boolean(formErrors.providers?.message),
        helperText: formErrors.providers?.message,
      }}
      defaultValue={providers?.find(el => el?.id === defaultValue?.provider?.id) || null}
      getOptionLabel={(o: { name }) => o.name}
      onSearchInput={handleVendorSearch}
    />
  );
};
