import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, TextField } from "@toolkit/ui";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formButtonTitle } from "shared/utils";
import { ProgramBenefitFormProps } from "./types";

export const ProgramBenefitForm: FC<ProgramBenefitFormProps> = props => {
  const { defaultValue, isLoading, errors, onDone } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const { ...methods } = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors: reactFormErrors },
  } = methods;

  const formErrors = combineErrors(reactFormErrors, errors);

  const onSubmit = data => {
    onDone(data);
  };

  const handleCancel = () => {
    navigate(healthProgramPaths.benefitsPath.fullPath);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
            />
          }
        >
          <FormCard loading={isLoading} doYouHaveData title={t("Benefit")}>
            <Grid container columnSpacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder={t("Name")}
                  label={t("Name")}
                  defaultValue={defaultValue?.name}
                  error={Boolean(formErrors.name?.message)}
                  helperText={t(formErrors.name?.message)}
                  {...register("name", { required: getRequiredValidation(t, true) })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder={t("Description")}
                  label={t("Description")}
                  defaultValue={defaultValue?.description}
                  error={Boolean(formErrors.description?.message)}
                  helperText={t(formErrors.description?.message)}
                  {...register("description", { required: getRequiredValidation(t, true) })}
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
