import {
  useHealthProgramBenefitGroupOptionsQuery,
  useHealthProgramBenefitGroupsListQuery,
  useHealthProgramExtraBenefitsQuery,
} from "../../../gql";

export const useBenefitsTypeQueriesHooks = () => {
  const { data: extraBenefits } = useHealthProgramExtraBenefitsQuery({ skip: true });
  const { data: basicBenefits } = useHealthProgramBenefitGroupOptionsQuery();
  const { data: healthProgramBenefit } = useHealthProgramBenefitGroupsListQuery();

  const extraBenefitsList = extraBenefits?.healthProgramExtraBenefits;
  const basicBenefitsList = basicBenefits?.healthProgramBenefitGroupOptions;
  const healthProgramBenefitGroups = healthProgramBenefit?.healthProgramBenefitGroups;

  return {
    basicBenefitsList,
    extraBenefitsList,
    healthProgramBenefitGroups,
  };
};
