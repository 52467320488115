import { useBreadCrumbs } from "@toolkit/ui";
import {
  branchUserFieldBreadcrumb,
  branchUserListBreadcrumb,
  branchUsersTitleBreadcrumb,
} from "pages/BranchUsers/constants/branch-users-breadcrumbs";
import { branchListBreadcrumb } from "pages/Branches/constants/branches-breadcrumbs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranchInfoQuery } from "./gql";

export const useBranchUsersHook = () => {
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, loading: loadingBranchUsers } = useBranchInfoQuery({
    variables: {
      id: String(params.idBranch),
    },
  });
  const branch = data?.branch;

  const handleSortColumn = () => {};

  useEffect(() => {
    if (branch?.name) {
      setBreadCrumb({
        title: branchUsersTitleBreadcrumb(),
        values: [branchListBreadcrumb(String(params.id)), branchUserListBreadcrumb(), branchUserFieldBreadcrumb(branch.name)],
      });
    }
  }, [branch?.name]);

  return {
    loadingBranchUsers,
    branch,
    handleSortColumn,
    vendor: branch?.vendor,
  };
};
