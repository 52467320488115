import { ProcessingStatus } from "@health/queries/types";
export const getCodeSystemProcessingStatusColor = (lastCadeSystemProcessingStatus, palette) => {
    switch (lastCadeSystemProcessingStatus) {
        case ProcessingStatus.UnderReview:
            return palette.warning.main;
        case ProcessingStatus.Approved:
            return palette.primary.main;
        case ProcessingStatus.Published:
            return palette.success.main;
        case ProcessingStatus.Rejected:
            return palette.secondary.main;
        case ProcessingStatus.Expired:
            return palette.primary.main;
        case ProcessingStatus.Draft:
            return palette.stale.main;
        default:
            return palette.primary.main;
    }
};
export const getCodeSystemProcessingStatusText = (t, lastCadeSystemProcessingStatus) => {
    switch (lastCadeSystemProcessingStatus) {
        case ProcessingStatus.UnderReview:
            return t("Under Review", { ns: "domains" });
        case ProcessingStatus.Approved:
            return t("Approved", { ns: "domains" });
        case ProcessingStatus.Draft:
            return t("Draft", { ns: "domains" });
        case ProcessingStatus.Expired:
            return t("Expired", { ns: "domains" });
        case ProcessingStatus.Published:
            return t("Published", { ns: "domains" });
        case ProcessingStatus.Rejected:
            return t("Rejected", { ns: "domains" });
        default:
            return t("No Data ", { ns: "domains" });
    }
};
