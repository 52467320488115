import { H_OrderDirection, HealthProgramNetworkSortingField, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { networkTitleBreadcrumb } from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useHealthProgramNetworksQuery } from "../gql";
import { useHealthProgramNetworksColumns } from "./components/useHealthProgramNetworksColumns";
import { HealthProgramNetworksList } from "./HealthProgramNetworksList";

export const HealthProgramNetworks: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const [searchParams] = useSearchParams();
  const program = searchParams.get("program");

  const handleEditRow = data => {
    navigate(networksPaths.editPath.fullPathWithParams(data?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: networkTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='healthProgramNetworks'
      columns={useHealthProgramNetworksColumns({ canManageActions })}
      query={useHealthProgramNetworksQuery}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramNetworkSortingField.Created,
        },
        filter: { healthPrograms: program ? [program] : [] },
      }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
    >
      <HealthProgramNetworksList canManageActions={canManageActions} />
    </GridProvider>
  );
};
