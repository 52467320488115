export enum SelectionStatus {
  Selected = "Selected",
  NotSelected = "NotSelected",
  Disabled = "Disabled",
}

export enum TableRowsSelectionStatus {
  All = "All",
  Some = "Some",
  None = "None",
}