import { HealthProgramCallsForVendorResponse, Vendor } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHealthProgramsListQuery } from "../HealthProgramMembers";
import { useVendorsQuery } from "../ProgramActivityTracking/gql";

export type HealthProgramCallsPerVendorColumnTypes = CustomTableColumnProps<HealthProgramCallsForVendorResponse>;

export const useHealthProgramCallsPerVendorColumns = (): HealthProgramCallsPerVendorColumnTypes[] => {
  const { t } = useTranslation("admin");
  const params = useLocation();
  const state: { vendor: Vendor; healthProgramName: string } = params?.state as { vendor: Vendor; healthProgramName: string };

  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment().endOf("month").format("YYYY-MM-DD");

  return useMemo(() => {
    return [
      {
        key: "provider",
        header: t("Provider"),
        accessor: () => <Typography>{state?.vendor?.name || "-"}</Typography>,
        type: "string",
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        accessor: () => <Typography>{state?.healthProgramName || "-"}</Typography>,
        filter: {
          type: "autocomplete",
          query: useHealthProgramsListQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => (isNaN(options?.id) ? undefined : Number(options?.id)),
          name: "healthProgramId",
          accessor: "healthPrograms",
        },
      },
      {
        key: "number of urgent calls",
        header: t("Number Of Urgent Calls"),
        accessor: ({ isUrgentDemandCall, numberOfCalls }) => (isUrgentDemandCall ? numberOfCalls : "-"),
        type: "string",
      },
      {
        key: "number of normal calls",
        header: t("Number Of Normal Calls"),
        accessor: ({ isUrgentDemandCall, numberOfCalls }) => (!isUrgentDemandCall ? numberOfCalls : "-"),
        type: "string",
      },
      {
        key: "Vendors",
        header: t("Vendors"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useVendorsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
          name: "vendorId",
          accessor: "vendors",
        },
      },
      {
        key: "fromDate",
        header: t("From Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromDate",
          initialValue: fromDate,
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toDate",
          initialValue: toDate,
        },
      },
    ];
  }, []);
};
