import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { HealthProgramNetworksListDocument } from "../../../HealthProgram/gql";
import { useHealthProgramNetworkUpdateMutation } from "../../gql";

export const ActiveToggleSwitch: FC<{ isActive: boolean; id: string; name: string; isDisabled?: boolean }> = ({
  isActive,
  id,
  name,
  isDisabled,
}) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(isActive);

  useEffect(() => {
    setCheck(isActive);
  }, [isActive]);

  const [healthProgramNetworkUpdate, { loading }] = useHealthProgramNetworkUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramNetworkUpdate?.entityErrors;
      if (errors?.length === 0) {
        succeeded(t("Network updated successfully"));
      } else {
        const message = errors?.map(error => {
          return `${error.field || ""},\r\n ${error.message || ""}\r\n`;
        });
        failed(message as any);
      }
    },
    onError: () => {
      failed(t("Network Update Failed"));
    },
    refetchQueries: [
      {
        query: HealthProgramNetworksListDocument,
        variables: {
          first: 10,
          filter: {
            isActive: true,
          },
        },
      },
    ],
  });
  const handleChangeToggle = e => {
    setCheck(e.target.checked);
    healthProgramNetworkUpdate({
      variables: {
        id,
        input: {
          isActive: e.target.checked,
          name: name,
        },
      },
    });
  };

  return (
    <CustomToggleButton value={check || false} disabled={loading || isDisabled} defaultChecked={isActive} onChange={handleChangeToggle} />
  );
};
