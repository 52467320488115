import { makeStyles } from "tss-react/mui";

export const useCustomTableStyles = makeStyles<{ isCardView: boolean }>({ name: "ui-table" })((theme, { isCardView }) => ({
  root: {
    boxShadow: theme.mixins.shadows.main,
    borderRadius: 10,
    borderWidth: isCardView ? 0 : 1,
    borderStyle: "solid",
    borderColor: theme.palette.stale[400],
    overflow: "auto",
    padding: isCardView ? 10 : 0,
  },
  tableContainer: {
    maxHeight: "calc(100vh - 222px)",
    overflowX: "auto",
    position: "relative",
  },
}));
