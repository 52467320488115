/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionGroupQueryVariables = Types.Exact<{
  permissionGroupId: Types.Scalars['ID'];
}>;


export type PermissionGroupQuery = { __typename?: 'Query', permissionGroup?: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null, groupConfiguration?: { __typename?: 'GroupConfiguration', groupType?: Types.AppTypes | null } | null } | null };


export const PermissionGroupDocument = gql`
    query permissionGroup($permissionGroupId: ID!) {
  permissionGroup(id: $permissionGroupId) {
    id
    name
    permissions {
      code
      name
    }
    groupConfiguration {
      groupType
    }
  }
}
    `;

/**
 * __usePermissionGroupQuery__
 *
 * To run a query within a React component, call `usePermissionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupQuery({
 *   variables: {
 *      permissionGroupId: // value for 'permissionGroupId'
 *   },
 * });
 */
export function usePermissionGroupQuery(baseOptions: Apollo.QueryHookOptions<PermissionGroupQuery, PermissionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionGroupQuery, PermissionGroupQueryVariables>(PermissionGroupDocument, options);
      }
export function usePermissionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupQuery, PermissionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionGroupQuery, PermissionGroupQueryVariables>(PermissionGroupDocument, options);
        }
export type PermissionGroupQueryHookResult = ReturnType<typeof usePermissionGroupQuery>;
export type PermissionGroupLazyQueryHookResult = ReturnType<typeof usePermissionGroupLazyQuery>;
export type PermissionGroupQueryResult = Apollo.QueryResult<PermissionGroupQuery, PermissionGroupQueryVariables>;