import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const marketplaceTrackingOrdersRoute = `${marketplaceRoute}/marketplace-orders-tracking`;

export const marketplaceOrdersTrackingPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${marketplaceTrackingOrdersRoute}${this.route}`;
    },
  },
};
