import { createPathDefinition } from "@toolkit/core";
import { marketplaceProductsRoute } from "pages/MarketplaceProducts/constants/MarketPlaceProductsPaths";

export const categoriesRoute = `${marketplaceProductsRoute}/categories`;

export const categoriesPaths = createPathDefinition(categoriesRoute, {
  main: "",
  new: "new",
  update: ":categoryId",
  productCategoryCategories: ":categoryId/sub-categories",
  newSubcategory: ":categoryId/new-subcategory",
});
