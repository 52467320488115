import { TFunction } from "@toolkit/i18n";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";

export const providersTitleBreadcrumb = (t: TFunction) => {
  return t("Health Providers", { ns: "admin" });
};

export const providerListBreadcrumb = (t: TFunction) => {
  return {
    id: "provider-list",
    name: t("Providers", { ns: "admin" }),
    route: providersManagementPaths.listPath.fullPath,
  };
};

export const providerNewBreadcrumb = (t: TFunction) => {
  return {
    id: "provider-new",
    name: t("New", { ns: "admin" }),
  };
};

export const providerFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "provider-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
