import { MarketplaceHealthPackage } from "@health/queries/types";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useHealthPackageStyles } from "./PreviewHealthPackages.styles";

export const PreviewHealthPackages: FC<{ row: MarketplaceHealthPackage }> = ({ row }) => {
  const { t } = useTranslation("admin");
  const { classes } = useHealthPackageStyles();

  return (
    <>
      <CustomDialog type='info' title={t("Health package preview")}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField disabled fullWidth value={row?.description} label={t("Description")} />
          </Grid>
          <Grid item xs={6}>
            <TextField disabled fullWidth value={row?.descriptionAr} label={t("Arabic Description")} />
          </Grid>
          <Grid item>
            <img
              className={classes.imageListItem}
              height={150}
              width={"300px"}
              loading='lazy'
              src={getMediaLink(decodeURI(row?.mainImageUrl!))}
              alt={"preview"}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
};
