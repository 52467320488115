import { AppointmentFrequencyType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const appointmentFrequencyTypeOptionsMap = {
    [AppointmentFrequencyType.Day]: {
        key: AppointmentFrequencyType.Day,
        get label() {
            return i18n.t("Day", { ns: "domains" });
        },
        value: AppointmentFrequencyType.Day,
    },
    [AppointmentFrequencyType.Week]: {
        key: AppointmentFrequencyType.Week,
        get label() {
            return i18n.t("Week", { ns: "domains" });
        },
        value: AppointmentFrequencyType.Week,
    },
    [AppointmentFrequencyType.Month]: {
        key: AppointmentFrequencyType.Month,
        get label() {
            return i18n.t("Month", { ns: "domains" });
        },
        value: AppointmentFrequencyType.Month,
    },
    [AppointmentFrequencyType.Year]: {
        key: AppointmentFrequencyType.Year,
        get label() {
            return i18n.t("Year", { ns: "domains" });
        },
        value: AppointmentFrequencyType.Year,
    },
};
export const appointmentFrequencyTypeOptions = Object.values(appointmentFrequencyTypeOptionsMap);
