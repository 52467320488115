import { FormCard, Grid, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMarketplaceProductCategoryQuery } from "../gql";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { formGirdBreakPoints } from "@toolkit/core";
import { categoriesBreadcrumb } from "../constants";
import { ValueWithLabel } from "./ValueWithLabel";
type ParentCategoryInfoCardProps = {
  hasParent?: boolean;
};
const ParentCategoryInfoCard: FC<ParentCategoryInfoCardProps> = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { data: categoryData, loading: loadingCategory } = useMarketplaceProductCategoryQuery({
    variables: { marketplaceProductCategoryId: categoryId! },
    skip: !categoryId,
    fetchPolicy: "no-cache",
  });
  const category = categoryData?.marketplaceProductCategory;
  const parent = category?.parent;
  useEffect(() => {
    setBreadCrumb({
      title: categoriesBreadcrumb().title,
      values: [
        parent?.id
          ? categoriesBreadcrumb().category(pickLocalizedValue(parent?.name!, parent?.nameAr!), parent?.id!)
          : categoriesBreadcrumb().main,
        categoriesBreadcrumb().category(pickLocalizedValue(category?.name!, category?.nameAr!), category?.id!),
      ],
    });
  }, [category, parent, setBreadCrumb, t]);
  return (
    <FormCard loading={loadingCategory} doYouHaveData={true} title={t("Category")}>
      <Grid container>
        <Grid item {...formGirdBreakPoints} lg={6}>
          <ValueWithLabel label={t("Name")} value={pickLocalizedValue(category?.name!, category?.nameAr!)} />
        </Grid>
        <Grid item {...formGirdBreakPoints} lg={6}>
          <ValueWithLabel label={t("Description")} value={pickLocalizedValue(category?.description!, category?.descriptionAr!)} />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ParentCategoryInfoCard;
