import { jsx as _jsx } from "react/jsx-runtime";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionActionOptimaEditsAutocompleteQuery } from "../../../Decisions/gql";
export const DecisionActionOptimaEditsAutocomplete = props => {
    const { name, label } = props;
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMore, fetchMoreData } = useRelayStyleConnection({
        useQuery: useDecisionActionOptimaEditsAutocompleteQuery,
    });
    const options = mapToAutocompleteOptions(data, "id", "name");
    return (_jsx(FormAutocomplete, { name: name, label: label || t("Optima Edits"), options: options, hasMore: hasMore, loading: isLoading, fetchMoreData: fetchMoreData }));
};
