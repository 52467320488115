/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type PermissionGroupErrorFragmentFragment = { __typename?: 'PermissionGroupError', code: Types.PermissionGroupErrorCode, field?: string | null, message?: string | null };

export type PermissionGroupFragmentFragment = { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null };

export const PermissionGroupErrorFragmentFragmentDoc = gql`
    fragment PermissionGroupErrorFragment on PermissionGroupError {
  code
  field
  message
}
    `;
export const PermissionGroupFragmentFragmentDoc = gql`
    fragment PermissionGroupFragment on Group {
  id
  name
  permissions {
    code
    name
  }
}
    `;