import { FC } from "react";
import { AdminUserManagementForm } from "../Form/AdminUserManagementForm";
import { useAdminUserManagementEditHook } from "./AdminUserManagementEdit.hook";

export const AdminUserManagementEditFormPage: FC = () => {
  const { errors, handleUpdate, loading, user, isUpdateLoading } = useAdminUserManagementEditHook();
  return (
    <AdminUserManagementForm
      errors={errors}
      onDone={handleUpdate}
      defaultValues={user}
      isEdit
      isLoading={loading}
      isFormLoading={isUpdateLoading}
    />
  );
};
