import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const marketplaceInvoicesOrdersRoute = `${marketplaceRoute}/marketplace-orders-invoices`;

export const marketplaceOrdersInvoicesPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${marketplaceInvoicesOrdersRoute}${this.route}`;
    },
  },
};
