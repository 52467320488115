import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { SvgIcon, SvgIconProps } from "../../base/mui";

interface IconProps extends SvgIconProps {}

const useStyles = makeStyles()(theme => ({
  a: {
    fill: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontFamily: "Montserrat-SemiBold, Montserrat",
    fontWeight: 600,
  },
  b: {
    fill: theme.palette.success.main,
    fontFamily: "Montserrat-Bold, Montserrat",
    fontWeight: 700,
  },
}));

export const SehaCityWhite: FC<IconProps> = ({ viewBox, ...props }) => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='137' height='29' viewBox={viewBox ? viewBox : "0 0 106 29"} {...props}>
      <text className={classes.a} transform='translate(53 23)'>
        <tspan x='-52.128' y='0'>
          seha
        </tspan>
        <tspan className={classes.b} y='0' x={i18n?.language === "ar" ? "-10" : "0"}>
          city
        </tspan>
      </text>
    </SvgIcon>
  );
};
