import { TFunction } from "@toolkit/i18n";
import { healthInsuranceNetworkPaths, healthInsuranceNetworkRoute } from "pages/HealthInsurance/constants/health-insurance-network-paths";
import {
  healthInsurancesManagementPath,
  healthInsurancesManagementPaths,
} from "pages/HealthInsurance/constants/health-insurances-management-paths";
import { NetworkInsuranceEditPage, NetworkInsuranceNewPage } from "pages/HealthInsurance/NetworkInsurance";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const healthInsurancesManagementRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-insurances-management-routes",
    text: t("Insurance Management", { ns: "admin" }),
    route: healthInsurancesManagementPath,
    hidden: true,
    subItems: [
      {
        id: "health-insurances-management-main-route",
        text: t("Insurance Management", { ns: "admin" }),
        route: healthInsurancesManagementPaths.mainPath.route,
        fullPath: healthInsurancesManagementPaths.mainPath.fullPath,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-insurances-management-new-route",
        text: t("Insurance", { ns: "admin" }),
        route: healthInsurancesManagementPaths.newPath.route,
        fullPath: healthInsurancesManagementPaths.newPath.fullPath,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-insurances-management-edit-route",
        text: t("Edit Insurance", { ns: "admin" }),
        route: healthInsurancesManagementPaths.editPath.route,
        fullPath: healthInsurancesManagementPaths.editPath.fullPath,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};

export const healthInsuranceNetworkRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  {
    return {
      id: "health-insurance-network-routes",
      text: t("Insurance Networks", { ns: "admin" }),
      route: healthInsuranceNetworkRoute,
      hidden: true,
      subItems: [
        {
          id: "health-insurance-network-main-route",
          text: t("Insurance Networks", { ns: "admin" }),
          route: healthInsuranceNetworkPaths.mainPath.route,
          fullPath: healthInsuranceNetworkPaths.mainPath.fullPath,
          hidden: true,
          onClick: (route: string) => navigate(route),
        },
        {
          id: "health-insurance-network-new-route",
          text: t("New Insurance Network", { ns: "admin" }),
          route: healthInsuranceNetworkPaths.newPath.route,
          fullPath: healthInsuranceNetworkPaths.newPath.fullPath,
          element: <NetworkInsuranceNewPage />,
          hidden: true,
          onClick: (route: string) => navigate(route),
        },
        {
          id: "health-insurance-network-edit-route",
          text: t("Edit Insurance Network", { ns: "admin" }),
          route: healthInsuranceNetworkPaths.editPath.route,
          fullPath: healthInsuranceNetworkPaths.editPath.fullPath,
          element: <NetworkInsuranceEditPage />,
          hidden: true,
          onClick: (route: string) => navigate(route),
        },
      ],
    };
  }
};
