import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const cityAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const CityAutocompleteSchemas = {
    cityAutocomplete,
    cityAutocompleteOptional: cityAutocomplete.nullable().optional(),
};
