import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSettingForm } from "../components/types";
import { fieldPattern, secondsToMinutes } from "../utils";

export const HealthProgramSettingForm: FC<ISiteSettingForm> = ({ errors: formErrors, defaultValue }) => {
  const { t } = useTranslation("admin");

  const { register } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          defaultValue={secondsToMinutes(defaultValue?.doctorVisitAcceptanceTimeoutSeconds)}
          label={t("Doctor Visit Acceptance Timeout(Minutes)")}
          fullWidth
          {...register("doctorVisitAcceptanceTimeout", {
            ...fieldPattern(t),
          })}
          error={Boolean(formErrors?.doctorVisitAcceptanceTimeout?.message)}
          helperText={formErrors?.doctorVisitAcceptanceTimeout?.message}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={secondsToMinutes(defaultValue?.healthProgramVisitWaitTimeBeforeCallSeconds)}
          label={t("Health Program Visit Wait Time Before Call(Minutes)")}
          fullWidth
          {...register("healthProgramVisitWaitTimeBeforeCall", {
            ...fieldPattern(t),
          })}
          error={Boolean(formErrors?.healthProgramVisitWaitTimeBeforeCall?.message)}
          helperText={formErrors?.healthProgramVisitWaitTimeBeforeCall?.message}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={secondsToMinutes(defaultValue?.messengerCallMinimumDurationSeconds)}
          label={t("Messenger Call Minimum Duration(Minutes)")}
          fullWidth
          {...register("messengerCallMinimumDuration", {
            ...fieldPattern(t),
          })}
          error={Boolean(formErrors?.messengerCallMinimumDuration?.message)}
          helperText={formErrors?.messengerCallMinimumDuration?.message}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={secondsToMinutes(defaultValue?.appointmentReminderTimeSeconds)}
          label={t("Appointment Reminder Time(Minutes)")}
          fullWidth
          {...register("appointmentReminderTime", {
            ...fieldPattern(t),
          })}
          error={Boolean(formErrors?.appointmentReminderTime?.message)}
          helperText={formErrors?.appointmentReminderTime?.message}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={defaultValue?.vatPercentageForMedicalServices}
          label={t("Vat Percentage For Medical Services")}
          fullWidth
          {...register("vatPercentageForMedicalServices", {
            ...fieldPattern(t),
            pattern: {
              value: /^\d+(\.\d{1,2})?$/,
              message: t("Vat Percentage For Medical Services should be a number with up to 2 decimal digits"),
            },
          })}
          InputProps={{
            endAdornment: "%",
          }}
          inputProps={{
            step: "0.01",
            onKeyPress: (event: any) => {
              if (event.key === "." && event?.target?.value?.includes(".")) {
                event.preventDefault();
              }
              const parts = event?.target?.value?.split(".");
              if (parts[1]?.length >= 2) {
                event.preventDefault();
              }
            },
          }}
          error={Boolean(formErrors?.vatPercentageForMedicalServices?.message)}
          helperText={formErrors?.vatPercentageForMedicalServices?.message}
        />
      </Grid>
    </Grid>
  );
};
