import { payerTpoOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { OrderDirection, Payer, PayerOrderField } from "@health/queries/types";
import { getMediaLink } from "@toolkit/core";
import moment from "moment";
import { PayerActiveToggleButton } from "../form/components/PayerActiveToggleButton";

export type PayersColumnTypes = CustomTableColumnProps<Payer>;

export const usePayersColumns = (): PayersColumnTypes[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "avatar",
        header: t("Logo"),
        accessor: ({ logo }) => <Avatar src={getMediaLink(logo!)} />,
        isHidden: false,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        filter: {
          type: "string",
          name: "name_Icontains",
        },
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Name,
      },
      {
        key: "contactName",
        header: t("Contact Name"),
        accessor: "contactName",
      },
      {
        key: "contactEmail",
        header: t("Contact Email"),
        accessor: "contactEmail",
      },
      {
        key: "contactPhoneNumber",
        header: t("Contact Phone Number"),
        accessor: ({ contactPhoneNumber }) => <Typography textAlign='center'>{contactPhoneNumber || "-"}</Typography>,
      },
      {
        key: "licenseNumber",
        header: t("License Number"),
        accessor: "licenseNumber",
        filter: {
          type: "string",
          name: "licenseNumber",
        },
      },
      {
        key: "tpo",
        header: t("TPO"),
        accessor: ({ tpo }) => payerTpoOptionsMap[tpo!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Tpo,
      },
      {
        key: "requestDate",
        header: t("Date"),
        accessor: ({ created }) => (created ? moment(created).format("DD/MM/YYYY") : "-"),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Created,
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: ({ id, isActive }) => <PayerActiveToggleButton id={id} isActive={isActive} />,
      },
    ];
  }, []);
};
