import { DevTools } from "@tolgee/i18next";
import i18n from "./config";
import { ContextUi, InContextTools } from "@tolgee/web/tools";

type FrontendToolkitConfig = {
  enableTolgeeWizard: string;
  tolgeeProjectID: string;
  apiUrl: string;
  apiKey: string;
};

class Config {
  private static instance: Config;
  private config: FrontendToolkitConfig | null = null;

  private constructor() {
    setTimeout(() => {
      console.log({ config: this.config });
    }, 500);
  }

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: FrontendToolkitConfig): void {
    this.config = config;
    if (config.enableTolgeeWizard === "true" && process.env.NODE_ENV !== "development") {
      i18n.tolgee.addPlugin(InContextTools());
      i18n.tolgee.addPlugin(ContextUi());
    }
    if (process.env.NODE_ENV === "development") {
      i18n.tolgee.addPlugin(DevTools());
    }
    i18n.tolgee.updateOptions({
      projectId: config.tolgeeProjectID,
      apiKey: config.apiKey,
      apiUrl: config.apiUrl,
    });
  }

  getConfig(): FrontendToolkitConfig {
    if (!this.config) {
      console.error("Configuration has not been loaded. Please call loadConfig() first.");
      return {} as FrontendToolkitConfig;
    }
    return this.config;
  }

  get(key: keyof FrontendToolkitConfig): string {
    const config = this.getConfig();
    return config[key];
  }
}

export const i18nConfig = Config.getInstance();

export const initializeI18nConfig = (config: FrontendToolkitConfig): void => {
  i18nConfig.loadConfig(config);
};
