import { TFunction } from "@toolkit/i18n";
import { providerUserManagementPaths } from "pages/UserManagement/constants/provider-user-management-paths";

export const providerUserManagementTitleBreadcrumb = (t: TFunction) => {
  return t("Health Providers Users Management", { ns: "admin" });
};

export const providerUserManagementListBreadcrumb = (t: TFunction) => {
  return {
    id: "provider-user-management-list",
    name: t("Providers Users", { ns: "admin" }),
    route: providerUserManagementPaths.listPath.fullPath,
  };
};

export const providerUserManagementNewBreadcrumb = (t: TFunction) => {
  return {
    id: "provider-user-management-new",
    name: t("New", { ns: "admin" }),
  };
};

export const providerUserManagementFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "provider-user-management-field",
    name: fieldName,
  };
};
