/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type CityErrorsFragment = { __typename?: 'BlockError', code: Types.BlockErrorCode, field?: string | null, message?: string | null };

export const CityErrorsFragmentDoc = gql`
    fragment CityErrors on BlockError {
  code
  field
  message
}
    `;