import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { FormDataProps } from "../types";
import { GeneralInformation } from "./GeneralInformation.component";
import { ImgUploader } from "./ImgUploader.component";

export const ProviderInformation: FC<FormDataProps> = props => {
  const { isLoading, doYouHaveVendor, vendor: defaultValues, errorsInput, isEditMode } = props;
  const { t } = useTranslation("admin");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormCard title={t("Provider Information")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
          <GeneralInformation isEditMode={isEditMode} defaultValues={defaultValues} errorsInput={errorsInput} />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Upload image")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
          <ImgUploader defaultValues={defaultValues} />
        </FormCard>
      </Grid>
    </Grid>
  );
};
