import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const decisionPlanAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
}));
export const DecisionPlanAutocompleteSchemas = {
    decisionPlanAutocomplete,
    decisionPlanAutocompleteOptional: decisionPlanAutocomplete.nullable().optional(),
};
