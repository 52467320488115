import React, { createContext } from "react";

export interface breadcrumbValues {
  id?: string;
  name: string;
  route?: string | any;
}

export interface IBreadcrumbs {
  title?: string;
  values?: breadcrumbValues[];
}

interface BreadCrumbProps {
  values: IBreadcrumbs;
  onChange: (state: IBreadcrumbs) => void;
  setValues: React.Dispatch<React.SetStateAction<IBreadcrumbs | undefined>>;
  navigate?: any;
}

export const BreadcrumbContext = createContext<BreadCrumbProps>({
  values: {
    title: "",
    values: [],
  },
} as unknown as BreadCrumbProps);
