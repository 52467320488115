import { i18n } from "@toolkit/i18n";
import React from "react";

// eslint-disable-next-line react/require-optimization
export class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error: null | { message: string; stack: string; componentStack: string } }
> {
  state = {
    hasError: false,
    error: { message: "", stack: "", componentStack: "" },
  };
  componentDidCatch(e, b) {
    this.setState({
      hasError: true,
      error: { message: e.message, stack: e.stack, ...b },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1> {i18n.t("errorBoundary:unexpected_error", { defaultValue: "Unexpected error!" })} </h1>
          {process.env.NODE_ENV === "production" ? (
            <> </>
          ) : (
            <>
              <p>message {this.state.error?.message} </p>
              <pre>stack {this.state.error?.stack} </pre>
              <pre>componentStack {this.state.error?.componentStack} </pre>
            </>
          )}
        </>
      );
    }
    return this.props.children;
  }
}
