import { MutationHookOptions, MutationTuple, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { keyBy } from "lodash";
import React from "react";
import { GridProviderBase } from "./GridProvider";
import { CustomTableColumnProps, IGridProviderBase } from "./types";
import { parseViewPreferences } from "./utils";

interface IOptions {
  query: (x: QueryHookOptions<any, any>) => QueryResult<any, any>;
  mutation: (options: MutationHookOptions<any, any>) => MutationTuple<any, any>;
}
export const withTablePreferences = (Component: typeof GridProviderBase, option: IOptions) => {
  function renderTheGrid<T extends object, TData = any, TVar extends OperationVariables = Record<string, any>>({
    hasTableSetting = false,
    ...props
  }: IGridProviderBase<T, TData, TVar> & { hasTableSetting?: boolean }) {
    // static defaultProps={};
    const { gridName: view, columns } = props;

    const {
      data,
      loading: isColumnsLoading,
      refetch,
    } = option.query({
      variables: {
        view: view,
      },
      skip: !hasTableSetting,
    });

    const tablePreferences = parseViewPreferences<T>(data, columns);

    const [saveTablePreferences] = option.mutation({
      onCompleted: () => {
        refetch();
      },
    });
    const columnsObject = keyBy(columns, "key");
    const reOrderColumns = tablePreferences?.columns
      .map(item => {
        return columnsObject[item?.key] ? { ...columnsObject[item?.key], isHidden: item?.isHidden } : undefined;
      })
      .filter(item => item) as CustomTableColumnProps<T>[];
    const handleColumnsChanged = (_data: Pick<CustomTableColumnProps<T>, "key" | "isHidden">[]) => {
      saveTablePreferences({
        variables: {
          input: {
            view,
            data: JSON.stringify({
              version: "v1",
              columns: _data,
            }),
          },
        },
      });
    };

    return (
      <Component<T, TData, TVar>
        {...props}
        reOrderColumns={reOrderColumns}
        isColumnsLoading={isColumnsLoading}
        onColumnsChanged={handleColumnsChanged}
      />
    );
  }
  renderTheGrid.defaultProps = {
    hasTableSetting: false,
  };

  return renderTheGrid;
};
