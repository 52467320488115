import { combineErrors, formGirdBreakPoints, getPositiveNumber, required, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useSiteSettingsQuery } from "../../../gql";
import { Copay } from "./Copay";
import { PlatformProfitSharePercentage } from "./PlatformProfitSharePercentage";
import { ToggleButtonGroup } from "./ToggleButtonGroup";
import { IProgramProps } from "./types";
import { healthProgramSubscriptionPeriods } from "./utils";

export const GeneralInformation: FC<IProgramProps> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    control,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const payPerCallOnly = watch("payPerCallOnly") || defaultValues?.payPerCallOnly;
  const formErrors = combineErrors(reactFormErrors, errors);
  const { data } = useSiteSettingsQuery();
  const currency = data?.siteSettings?.defaultCurrency;
  return (
    <Grid container spacing={2}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Name")}
          error={Boolean(formErrors.name?.message)}
          helperText={t(formErrors.name?.message)}
          {...register("name", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
          defaultValue={defaultValues?.name}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Code")}
          error={Boolean(formErrors.code?.message)}
          helperText={t(formErrors.code?.message)}
          {...register("code", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
          defaultValue={defaultValues?.code}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Targeted Group")}
          {...register("targetGroup", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
          defaultValue={defaultValues?.targetGroup}
          error={Boolean(formErrors.targetGroup?.message)}
          helperText={formErrors.targetGroup?.message}
        />
      </Grid>
      <Grid item container spacing={2} xs={12} alignItems='center'>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            size='small'
            label={t("Monthly Price")}
            placeholder={t("Monthly Price")}
            type='number'
            defaultValue={defaultValues?.priceAmount}
            error={Boolean(formErrors.priceAmount?.message)}
            helperText={formErrors.priceAmount?.message}
            {...register("priceAmount", {
              required: required(t),
              min: getPositiveNumber(t, 0),
            })}
            InputProps={{
              endAdornment: currency,
            }}
          />
        </Grid>
        {!payPerCallOnly && (
          <Grid item {...formGirdBreakPoints}>
            <AutocompleteController
              ControllerProps={{
                control: control,
                name: "subscriptionPeriod",
                defaultValue:
                  (defaultValues?.subscriptionPeriod &&
                    healthProgramSubscriptionPeriods?.find(
                      el => el?.name?.toLowerCase() === defaultValues?.subscriptionPeriod?.toLowerCase()
                    )) ||
                  null,
              }}
              options={healthProgramSubscriptionPeriods}
              TextFieldProps={{
                label: t("Subscription Period"),
                placeholder: t("Subscription Period"),
                error: Boolean(formErrors.subscriptionPeriod?.message),
                helperText: formErrors.subscriptionPeriod?.message,
              }}
              getOptionLabel={(o: { name: string }) => t(o?.name)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <PlatformProfitSharePercentage errors={errors} defaultValues={defaultValues} />
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup defaultValues={defaultValues} />
      </Grid>
      <Grid item container spacing={2} xs={12} alignItems='center'>
        <Copay defaultValues={defaultValues} errors={errors} currency={currency} />
      </Grid>
    </Grid>
  );
};
