import { useTranslation } from "@toolkit/i18n";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete, Button, TextField } from "../../base/mui";
import { MuiExpandMoreIcon } from "../../base/mui-icons";
import { FilterIcon } from "../../icons";
import { CustomDialog } from "../Dialogs";
import { Filter } from "../Filter";
import { useStaticFilterDialogHooks } from "./StaticFilterDialog.hooks";
import { StyledIconButton } from "./StaticFilterDialog.style";
import { StaticFilterDialogProps } from "./StaticFilterDialog.types";

export const FilterDialog = <X extends object>(props: StaticFilterDialogProps<X>) => {
  const { t } = useTranslation();
  const { handleSubmit, reset } = useFormContext();
  const {
    visibleFilters,
    isDynamicFilters,
    filteringItems,
    filterFields,
    isOpen,
    handleToggle,
    handleSubmitClicked,
    handleDynamicFiltersSelectChanged,
    handleFormReset,
    handleClickClosed,
  } = useStaticFilterDialogHooks(props);

  useEffect(() => {
    reset({ ...props.filters });
  }, [JSON.stringify(props.filters)]);

  return (
    <>
      <StyledIconButton color='primary' size='medium' onClick={handleToggle} id='filterDialogButton' disableFocusRipple>
        <FilterIcon />
      </StyledIconButton>
      <CustomDialog
        type='base'
        fullWidth
        maxWidth='md'
        open={isOpen}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        DialogTitleProps={{
          title: t("Filter"),
          onClose: handleClickClosed,
        }}
        DialogActionsProps={{
          children: (
            <>
              <Button type='submit' onClick={handleSubmit(handleSubmitClicked)}>
                {t("Submit")}
              </Button>
              <Button variant='outlined' onClick={handleFormReset}>
                {t("Reset")}
              </Button>
            </>
          ),
        }}
      >
        <form>
          {isDynamicFilters && (
            <Autocomplete
              defaultValue={visibleFilters}
              popupIcon={<MuiExpandMoreIcon />}
              onChange={handleDynamicFiltersSelectChanged}
              options={filterFields}
              multiple
              getOptionLabel={o => o.key}
              sx={{ marginBottom: "15px" }}
              noOptionsText={t("No Options")}
              renderInput={params => <TextField hiddenLabel {...params} placeholder={t("Chose a filter field")} />}
            />
          )}
          <Filter fields={filteringItems} />
        </form>
      </CustomDialog>
    </>
  );
};
