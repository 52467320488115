import { useTranslation } from "@toolkit/i18n";
import { Box, ToggleButtonController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ToggleGroupProps } from "./types";
import { PayPerCallToggle } from "./PayPerCallToggle";

export const ToggleButtonGroup: FC<ToggleGroupProps> = ({ defaultValues }) => {
  const { t } = useTranslation("admin");
  const { register, control } = useFormContext();
  return (
    <Box display='flex' flexWrap='wrap'>
      <Box display='flex' alignItems='center'>
        <ToggleButtonController
          {...register("prescriptionEnabled")}
          control={control}
          defaultValue={defaultValues?.prescriptionEnabled}
          label={t("Prescription")}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <ToggleButtonController
          {...register("referralsToSpecialistsEnabled")}
          control={control}
          defaultValue={defaultValues?.referralsToSpecialistsEnabled}
          label={t("Referrals To Specialists")}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <ToggleButtonController {...register("isActive")} control={control} defaultValue={defaultValues?.isActive} label={t("Is Active")} />
      </Box>
      <PayPerCallToggle defaultValues={defaultValues} />
    </Box>
  );
};
