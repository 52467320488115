import { Components, Theme } from "@mui/material/styles";

export const MuiInputBaseOverride: Components<Theme>["MuiInputBase"] = {
  styleOverrides: {
    root: {
      backgroundColor: "#F5F7FD",
      border: "none",
    },
    input: {
      borderColor: "#C7D6EE",
      borderRadius: 8,
      backgroundColor: "#F5F7FD",
    },
  },
};
