import { useTranslation } from "@toolkit/i18n";
import React, { forwardRef, ForwardRefRenderFunction, SyntheticEvent, useImperativeHandle, useRef, useState } from "react";
import { Typography } from "../../base/mui";
import { useVideoPlayerStyle } from "./useVideoPlayerStyle";

export type VideoPlayerRef = {
  isPlaying: () => boolean;
  isPaused: () => boolean;
  isStopped: () => boolean;
  play: () => void;
  pause: () => void;
};

type VideoPlayerProps = {
  src: string;
  type?: string;
  maxHeight?: number;
  isControlsHidden?: boolean;
  isAutoplayEnabled?: boolean;
  isMuted?: boolean;
};

const VideoPlayerForwardRef: ForwardRefRenderFunction<VideoPlayerRef, VideoPlayerProps> = (props, ref) => {
  const { src, type = "video/mp4", maxHeight = 400, isControlsHidden = false, isAutoplayEnabled = false, isMuted = false } = props;

  const [error, setError] = useState("");

  const videoRef = useRef<HTMLVideoElement>(null);

  const { t } = useTranslation();

  const { classes, theme } = useVideoPlayerStyle({ maxHeight });

  const handleOnError = (event: SyntheticEvent<HTMLVideoElement>) => {
    setError(t("An error occurred while loading the video, please try again"));
    console.error(event);
  };

  useImperativeHandle(ref, () => ({
    isPlaying() {
      return (
        !!videoRef.current &&
        videoRef.current.currentTime > 0 &&
        !videoRef.current.paused &&
        !videoRef.current.ended &&
        videoRef.current.readyState > 2
      );
    },
    isPaused() {
      return !!videoRef.current && videoRef.current.paused;
    },
    isStopped() {
      return !!videoRef.current && videoRef.current.ended;
    },
    play() {
      videoRef.current?.play();
    },
    pause() {
      videoRef.current?.pause();
    },
  }));

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        key={src}
        ref={videoRef}
        controls={!isControlsHidden}
        autoPlay={isAutoplayEnabled}
        muted={isMuted}
        className={classes.root}
        onError={handleOnError}
      >
        <source src={src} type={type} />
      </video>

      {error && <Typography color={theme.palette.error.main}> {error} </Typography>}
    </>
  );
};

export const VideoPlayer = forwardRef(VideoPlayerForwardRef);
