import { Components, Theme } from "@mui/material/styles";

export const MuiCardOverride: Components<Theme>["MuiCard"] = {
  defaultProps: {
    elevation: 1,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: theme.mixins.shadows.sm,
      borderRadius: 15,
      // padding: 10,  >> bad table padding
    }),
  },
};
