import { FormCard, Grid, useBreadCrumbs, ValueWithLabel } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMarketplaceHealthPackageCategoryQuery } from "../gql";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { formGirdBreakPoints } from "@toolkit/core";
import { healthPackageCategoriesBreadcrumb } from "../constants";
type ParentCategoryInfoCardProps = {
  hasParent?: boolean;
};
const ParentCategoryInfoCard: FC<ParentCategoryInfoCardProps> = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { data: categoryData, loading: loadingCategory } = useMarketplaceHealthPackageCategoryQuery({
    variables: { marketplaceHealthPackageCategoryId: categoryId! },
    skip: !categoryId,
    fetchPolicy: "no-cache",
  });
  const category = categoryData?.marketplaceHealthPackageCategory;
  const parent = category?.parent;
  useEffect(() => {
    setBreadCrumb({
      title: healthPackageCategoriesBreadcrumb().title,
      values: [
        parent
          ? healthPackageCategoriesBreadcrumb().category(pickLocalizedValue(parent?.name!, parent?.nameAr!), parent?.id!)
          : healthPackageCategoriesBreadcrumb().main,
        healthPackageCategoriesBreadcrumb().category(pickLocalizedValue(category?.name!, category?.nameAr!), category?.id!),
      ],
    });
  }, [category]);
  return (
    <FormCard loading={loadingCategory} doYouHaveData={true} title={t("Category")}>
      <Grid container>
        <Grid item {...formGirdBreakPoints} lg={6}>
          <ValueWithLabel label={t("Name")} value={pickLocalizedValue(category?.name, category?.nameAr) || category?.name || "--"} />
        </Grid>
        <Grid item {...formGirdBreakPoints} lg={6}>
          <ValueWithLabel
            label={t("Description")}
            value={pickLocalizedValue(category?.description, category?.descriptionAr) || category?.description || "--"}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ParentCategoryInfoCard;
