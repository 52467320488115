export default {};
export var FieldUIComponent;
(function (FieldUIComponent) {
    FieldUIComponent["custom"] = "CUSTOM";
    FieldUIComponent["checkbox"] = "CHECKBOX";
    FieldUIComponent["autocomplete"] = "AUTOCOMPLETE";
    FieldUIComponent["lazyAutocomplete"] = "LAZY_AUTOCOMPLETE";
    FieldUIComponent["input"] = "INPUT";
    FieldUIComponent["date"] = "DATE";
})(FieldUIComponent || (FieldUIComponent = {}));
