import { useAuth } from "react-oidc-context";
export const useOidcUserProfile = () => {
    var _a, _b;
    const auth = useAuth();
    const userInfo = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.profile;
    return {
        accessToken: (_b = auth === null || auth === void 0 ? void 0 : auth.user) === null || _b === void 0 ? void 0 : _b.access_token,
        userInfo,
    };
};
