import { gql } from '@apollo/client';
import { DecisionPlanFragmentDoc } from '../../fragments/__generated__/DecisionPlan';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionPlanGetDocument = gql `
    query DecisionPlanGet($getPlanId: ID!) {
  getPlan(id: $getPlanId) {
    ...DecisionPlan
  }
}
    ${DecisionPlanFragmentDoc}`;
/**
 * __useDecisionPlanGetQuery__
 *
 * To run a query within a React component, call `useDecisionPlanGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionPlanGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionPlanGetQuery({
 *   variables: {
 *      getPlanId: // value for 'getPlanId'
 *   },
 * });
 */
export function useDecisionPlanGetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionPlanGetDocument, options);
}
export function useDecisionPlanGetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionPlanGetDocument, options);
}
