import { useTranslation } from "@toolkit/i18n";
import { FormControlLabel, Radio, RadioGroup, TextField, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { ReasonsFormProps } from "./tyoes";
import { useReasonForm } from "./useReasonForm";

export const ReasonsForm: FC<ReasonsFormProps> = props => {
  const { reasons, isLoading, onChange } = props;

  const theme = useTheme();
  const { t } = useTranslation("domains");

  const { state, reasonsWithOtherOption, handleChangeOtherReason, handleChangeReason } = useReasonForm({ reasons, onChange });

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <RadioGroup onChange={handleChangeReason}>
        {reasonsWithOtherOption?.map(item => (
          <FormControlLabel
            control={<Radio />}
            key={item?.value}
            value={item?.value}
            label={item?.label || "-"}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>

      {state.isOther && (
        <TextField
          label={t("Other Reason")}
          placeholder={t("Other Reason")}
          multiline
          minRows={3}
          fullWidth
          value={state.other}
          onChange={handleChangeOtherReason}
        />
      )}
    </>
  );
};
