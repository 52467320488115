import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertToTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, useTheme } from "@toolkit/ui";
import moment from "moment-timezone";
export const OrderDetails = ({ order }) => {
    var _a;
    const { t } = useTranslation("domains");
    const userTz = moment.tz.guess();
    const theme = useTheme();
    return (_jsxs(Box, { sx: { width: "100%" }, children: [_jsx(Typography, { component: 'span', fontWeight: 'bold', fontSize: theme.mixins.fonts.fontSize.md, children: t("Order Details") }), _jsxs(Grid, { container: true, columnSpacing: { sx: 4, md: 8 }, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(OrderSection, { label: t("eRx Number"), value: (_a = order === null || order === void 0 ? void 0 : order.prescription) === null || _a === void 0 ? void 0 : _a.referenceNumber }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(OrderSection, { label: t("Order Number"), value: order === null || order === void 0 ? void 0 : order.number }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(OrderSection, { label: t("Order Status"), value: t(convertToTitleCase(order === null || order === void 0 ? void 0 : order.status)) }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(OrderSection, { label: t("Order Date"), value: (order === null || order === void 0 ? void 0 : order.created) ? moment(order === null || order === void 0 ? void 0 : order.created).format("DD MMM YYYY") : "-" }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(OrderSection, { label: t("Order Time"), value: (order === null || order === void 0 ? void 0 : order.created) ? moment(order === null || order === void 0 ? void 0 : order.created).tz(userTz).format("hh:mm a") : "-" }) })] })] }));
};
export const OrderSection = ({ label, value = "" }) => {
    const theme = useTheme();
    return (_jsxs(Box, { marginTop: '8px', marginBottom: '8px', children: [_jsxs(Typography, { fontWeight: 'bold', fontSize: theme.mixins.fonts.fontSize.sm, children: [label, ":"] }), _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.sm, children: value })] }));
};
