import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useHealthProgramNetworksListQuery } from "../../../gql";
import { IProgramProps } from "./types";

export const HealthProgramNetworks: FC<IProgramProps> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: reactErrors },
  } = useFormContext();

  const { data, fetchMore } = useHealthProgramNetworksListQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
  });

  const hasMore = data?.healthProgramNetworks?.pageInfo?.hasNextPage;
  const fetchMoreData = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          first: 5,
          after: data?.healthProgramNetworks?.pageInfo?.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            healthProgramNetworks: {
              ...fetchMoreResult.healthProgramNetworks,
              edges: [...prev?.healthProgramNetworks?.edges, ...fetchMoreResult?.healthProgramNetworks?.edges],
            },
          });
        },
      });
    }
  };
  const formErrors = combineErrors(reactErrors, errors);
  const healthProgramNetworks = data?.healthProgramNetworks?.edges?.map(item => item?.node);
  const healthProgramNetworksList = defaultValues?.healthProgramNetworks?.edges?.map(item => item?.node);

  return (
    <AutocompleteController
      multiple
      ControllerProps={{
        control: control,
        name: "healthProgramNetworks",
        defaultValue: healthProgramNetworksList || [],
        rules: {
          value: false,
        },
      }}
      TextFieldProps={{
        label: t("Networks"),
        helperText: t(formErrors.healthProgramNetworks?.message),
        error: Boolean(formErrors.healthProgramNetworks?.message),
      }}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
      getOptionLabel={(o: { name }) => o?.name}
      options={healthProgramNetworks || []}
    />
  );
};
