import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { OptimaEditListDocument, useOptimaEditCreateMutation } from "../../../OptimaEdits/gql";
import { OptimaEditUpsertForm } from "../../../OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertForm";
import { convertOptimaEditFormValuesToBackEndValues } from "../../../OptimaEdits/others";
export const OptimaEditCreateContainer = props => {
    const { onChange } = props;
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const [createOptimaEdit, { loading: isSubmitting }] = useOptimaEditCreateMutation({
        onCompleted: () => {
            succeeded(t("Optima Edit created successfully"));
            onChange({
                type: "SUBMIT_SUCCESS",
            });
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
        refetchQueries: [OptimaEditListDocument],
    });
    const onOptimaEditUpsertFormChange = (event) => {
        if (event.type === "SUBMIT") {
            const values = convertOptimaEditFormValuesToBackEndValues(event.payload.values);
            createOptimaEdit({
                variables: {
                    input: values,
                },
            });
        }
    };
    return _jsx(OptimaEditUpsertForm, { buttonLabel: t("Create"), isButtonDisabled: isSubmitting, onChange: onOptimaEditUpsertFormChange });
};
