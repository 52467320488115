import { FileDownload, FileImportDialog } from "@health/domains";
import { BulkTemplateCode } from "@health/queries/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const HealthProgramNetworksList: FC<{ canManageActions?: boolean }> = ({ canManageActions }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { open, handleToggle } = useOpenState();
  const { customTableProps } = useGridContext();

  const handleAddNew = () => navigate("new");

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  useEffect(() => {
    !customTableProps?.isLoading && !customTableProps?.data?.length && handleToggle();
  }, []);

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />
          {canManageActions && (
            <MainListActions hasAddNew onAddNewItem={handleAddNew}>
              <FileImportDialog
                title={t("Import Networks")}
                subTitle={t("Upload Networks list you want to get")}
                isOpen={open}
                onSuccessUpload={handleSuccessUpload}
                onToggle={handleToggle}
                templateCode={BulkTemplateCode.HealthProgramNetwork}
              />

              <FileDownload templateCode={BulkTemplateCode.HealthProgramNetwork} />
            </MainListActions>
          )}
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
