import { TFunction } from "@toolkit/i18n";
import { WorkingDay } from "./types";

enum DayOfWeekEnum {
  Fri = "FRI",
  Mon = "MON",
  Sat = "SAT",
  Sun = "SUN",
  Thu = "THU",
  Tue = "TUE",
  Wed = "WED",
}
export const DAYS = (t: TFunction) => ({
  SAT: t("Saturday"),
  SUN: t("Sunday"),
  MON: t("Monday"),
  TUE: t("Tuesday"),
  WED: t("Wednesday"),
  THU: t("Thursday"),
  FRI: t("Friday"),
});

export const defaultWeekWorkingDays: any[] = [
  {
    day: "SAT",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "SUN",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "MON",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "TUE",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "WED",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "THU",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "FRI",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
];

export const getDefaultWeekWorkingDays = (): WorkingDay[] => [
  {
    day: DayOfWeekEnum.Sat,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Sun,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Mon,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Tue,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Wed,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Thu,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: DayOfWeekEnum.Fri,
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
];
