import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const doctorAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const DoctorAutocompleteSchemas = {
    doctorAutocomplete,
    doctorAutocompleteOptional: doctorAutocomplete.nullable().optional(),
};
