import { i18n } from "@toolkit/i18n";
import { z, ZodIssueCode, ZodIssueOptionalMessage, ZodTooSmallIssue, ZodTooBigIssue } from "zod";

export const getZodErrorMapMessages = (): z.ZodErrorMap => {
  return (issue: ZodIssueOptionalMessage, ctx: z.ErrorMapCtx) => {
    switch (issue.code) {
      case ZodIssueCode.invalid_type:
        return { message: i18n.t("Required") };

      case ZodIssueCode.too_small: {
        const message = getTooSmallMessage(issue);
        return { message: message ?? ctx.defaultError };
      }

      case ZodIssueCode.too_big: {
        const message = getTooBigMessage(issue);
        return { message: message ?? ctx.defaultError };
      }

      default:
        return { message: ctx.defaultError };
    }
  };
};

const getTooSmallMessage = (issue: ZodTooSmallIssue) => {
  switch (issue.type) {
    case "number":
      return i18n.t("Minimum value is {{value}}", { value: issue.minimum });
    case "string":
      return i18n.t("Minimum field length is {{value}}", { value: issue.minimum });
    case "date":
      return i18n.t("Minimum date is {{value}}", { value: issue.minimum });
    case "array":
      return i18n.t("Minimum array length is {{value}}", { value: issue.minimum });
    default:
      return undefined;
  }
};

const getTooBigMessage = (issue: ZodTooBigIssue) => {
  switch (issue.type) {
    case "number":
      return i18n.t("Maximum value is {{value}}", { value: issue.maximum });
    case "string":
      return i18n.t("Maximum field length is {{value}}", { value: issue.maximum });
    case "date":
      return i18n.t("Maximum date is {{value}}", { value: issue.maximum });
    case "array":
      return i18n.t("Maximum array length is {{value}}", { value: issue.maximum });
    default:
      return undefined;
  }
};
