import { getPayersAutocompleteFilter } from "@health/autocompletes";
import { HealthProgramMemberList, HealthProgramMemberListSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getActiveInActiveFilter } from "shared/components";
import { useHealthProgramsListQuery, useMembersQuery } from "../../gql";
import { ActiveToggleSwitch } from "./ActiveToggle";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<HealthProgramMemberList>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useHealthProgramMemberListsColumns = ({ canManageActions }): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const handleGoToMembers = id => () => {
    navigate(memberListPaths.membersPath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name!} />,
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "members",
        header: t("Members"),
        accessor: ({ healthProgramMembers, id }) => (
          <Box sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleGoToMembers(id)}>
            {healthProgramMembers?.totalCount}
          </Box>
        ),
        type: "string",
        filter: {
          type: "autocomplete",
          query: useMembersQuery,
          multiple: true,
          getOptionLabel: option => option?.firstName + " " + option?.lastName,
          getValueForBackend: options => options?.map(item => item?.id),
          name: "members",
          accessor: "healthProgramMembers",
          filterSearchKey: "name",
        },
      },
      {
        key: "health program list",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useHealthProgramsListQuery,
          multiple: true,
          getOptionLabel: option => option?.name!,
          getValueForBackend: options => options?.map(item => item?.id),
          name: "healthPrograms",
          accessor: "healthPrograms",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description }) => <TruncateTypography text={description!} />,
        type: "string",
      },
      {
        key: "active",
        header: t("Active"),
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.IsActive,
        accessor: row => {
          return (
            <ActiveToggleSwitch
              isDisabled={!canManageActions}
              id={row?.id!}
              updateRequiredInput={{
                name: row?.name!,
              }}
              isActive={row?.isActive!}
            />
          );
        },
        filter: { ...getActiveInActiveFilter() },
      },
      {
        key: "payer",
        header: t("Payer"),
        showOnlyForFilterField: true,
        filter: getPayersAutocompleteFilter({ name: "payers", multiple: true }),
      },
    ];
  }, []);
};
