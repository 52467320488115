/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTimeZoneQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTimeZoneQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', timeZone?: number | null } };


export const GetTimeZoneDocument = gql`
    query getTimeZone {
  siteSettings {
    timeZone
  }
}
    `;

/**
 * __useGetTimeZoneQuery__
 *
 * To run a query within a React component, call `useGetTimeZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeZoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeZoneQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeZoneQuery, GetTimeZoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeZoneQuery, GetTimeZoneQueryVariables>(GetTimeZoneDocument, options);
      }
export function useGetTimeZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeZoneQuery, GetTimeZoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeZoneQuery, GetTimeZoneQueryVariables>(GetTimeZoneDocument, options);
        }
export type GetTimeZoneQueryHookResult = ReturnType<typeof useGetTimeZoneQuery>;
export type GetTimeZoneLazyQueryHookResult = ReturnType<typeof useGetTimeZoneLazyQuery>;
export type GetTimeZoneQueryResult = Apollo.QueryResult<GetTimeZoneQuery, GetTimeZoneQueryVariables>;