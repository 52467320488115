/* eslint-disable max-lines */
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormCard, Grid, PageWrapper, TextField, ToggleButtonController, Typography } from "@toolkit/ui";
import { useFacilitiesQuery } from "gql/queries";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useInsuranceUpdateForm from "../hooks/useInsuranceUpdateForm";
import { useInsuranceFormStyles } from "../styles/useFormStyles";
import { VendorForm } from "./VendorForm";
import { InsuranceProps } from "./types";

const NetworkInsuranceForm: FC<InsuranceProps> = ({
  errors: errorsCreate,
  onDone,
  onCancel,
  isLoading,
  isDataLoading = false,
  isEditMode = false,
  data: insuranceNetwork,
}) => {
  const { t } = useTranslation("admin");
  const { classes } = useInsuranceFormStyles();
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors: reactFormErrors },
  } = methods;

  const { onUpdate, isUpdateLoading, errors: errorsUpdate } = useInsuranceUpdateForm(insuranceNetwork?.id);
  const {
    data: vendorsData,
    fetchMore: fetchMoreVendors,
    loading: loadingVendors,
  } = useFacilitiesQuery({
    variables: {
      first: 10,
      // filter: { type: [VendorTypeEnum.Clinic, VendorTypeEnum.Hospital, VendorTypeEnum.Pharmacy], approved: true, isActive: true },
      filter: { type: ["CLINIC", "HOSPITAL", "PHARMACY"] as any, approved: true, isActive: true },
    },
  });

  const errors = [errorsUpdate || errorsCreate];
  const formErrors = combineErrors(reactFormErrors, errors);
  const doYouHaveData = isEditMode ? insuranceNetwork != undefined : true;

  const onSubmit = (data: any) => {
    const request = {
      ...data,
      insurance: data?.insurance?.id,
      facilities: data?.facilities?.map(facility => facility.id),
    };

    if (insuranceNetwork) {
      onUpdate(request);
    } else if (onDone) {
      onDone(request);
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  const buttonLabel = () => {
    if (isLoading || isUpdateLoading) {
      return t("loading", "Loading...");
    } else if (insuranceNetwork != undefined) {
      return t("Update", "Update");
    } else {
      return t("Create", "Create");
    }
  };

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.container}>
              <FormCard loading={isDataLoading} doYouHaveData={doYouHaveData} title={t("SuperAdmin:insuranceNetwork", "Insurance Network")}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      placeholder={t("Name", "Name")}
                      label={t("Name", "Name")}
                      size={"small"}
                      defaultValue={insuranceNetwork?.name}
                      fullWidth
                      error={Boolean(formErrors.name?.message)}
                      helperText={formErrors.name?.message}
                      {...register("name", { required: { value: true, message: t("Required", "Required") } })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <VendorForm
                      defaultValue={insuranceNetwork}
                      fetchMoreVendors={fetchMoreVendors}
                      loading={loadingVendors}
                      vendorsData={vendorsData}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography component='span'>
                    <ToggleButtonController
                      {...register("isActive")}
                      control={control}
                      defaultValue={insuranceNetwork?.isActive}
                      label={t("SuperAdminForm:isActive", "isActive")}
                    />
                  </Typography>
                </Grid>
              </FormCard>
            </div>
            <Button
              color='success'
              disabled={isLoading || isUpdateLoading}
              type='submit'
              size='large'
              variant='contained'
              className={classes.createButton}
            >
              {buttonLabel()}
            </Button>
            <Button className={classes.cancelButton} variant='contained' size='large' onClick={handleCancel}>
              {t("Cancel")}
            </Button>
          </form>
        </Grid>
      </FormProvider>
    </PageWrapper>
  );
};
export default NetworkInsuranceForm;
