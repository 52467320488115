/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageCategoryUpdateMutationVariables = Types.Exact<{
  marketplaceHealthPackageCategoryUpdateId: Types.Scalars['ID'];
  input: Types.MarketplaceCategoryInput;
}>;


export type MarketplaceHealthPackageCategoryUpdateMutation = { __typename?: 'Mutation', marketplaceHealthPackageCategoryUpdate?: { __typename?: 'MarketplaceHealthPackageCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceHealthPackageCategory', id: string, name?: string | null, nameAr?: string | null } | null } | null };


export const MarketplaceHealthPackageCategoryUpdateDocument = gql`
    mutation MarketplaceHealthPackageCategoryUpdate($marketplaceHealthPackageCategoryUpdateId: ID!, $input: MarketplaceCategoryInput!) {
  marketplaceHealthPackageCategoryUpdate(
    id: $marketplaceHealthPackageCategoryUpdateId
    input: $input
  ) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      id
      name
      nameAr
    }
  }
}
    `;
export type MarketplaceHealthPackageCategoryUpdateMutationFn = Apollo.MutationFunction<MarketplaceHealthPackageCategoryUpdateMutation, MarketplaceHealthPackageCategoryUpdateMutationVariables>;

/**
 * __useMarketplaceHealthPackageCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceHealthPackageCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceHealthPackageCategoryUpdateMutation, { data, loading, error }] = useMarketplaceHealthPackageCategoryUpdateMutation({
 *   variables: {
 *      marketplaceHealthPackageCategoryUpdateId: // value for 'marketplaceHealthPackageCategoryUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceHealthPackageCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceHealthPackageCategoryUpdateMutation, MarketplaceHealthPackageCategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceHealthPackageCategoryUpdateMutation, MarketplaceHealthPackageCategoryUpdateMutationVariables>(MarketplaceHealthPackageCategoryUpdateDocument, options);
      }
export type MarketplaceHealthPackageCategoryUpdateMutationHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoryUpdateMutation>;
export type MarketplaceHealthPackageCategoryUpdateMutationResult = Apollo.MutationResult<MarketplaceHealthPackageCategoryUpdateMutation>;
export type MarketplaceHealthPackageCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceHealthPackageCategoryUpdateMutation, MarketplaceHealthPackageCategoryUpdateMutationVariables>;