import { formGirdBreakPoints, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, DateAdapter, Grid, LocalizationProvider } from "@toolkit/ui";
import moment from "moment-timezone";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { SubscriptionInfoProps } from "./SubscriptionInfo.type";

export const SubscriptionValidFromTillForm: FC<SubscriptionInfoProps> = ({ formData, formErrors }) => {
  const { t } = useTranslation("admin");
  const { control, setValue, watch } = useFormContext();

  useEffect(() => {
    if (formData?.id || formData?.validFrom || formData?.validTill) {
      setValue("validFrom", moment(formData?.validFrom).format() || moment());
      setValue("validTill", moment(formData?.validTill).format() || moment());
    }
  }, [formData]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          name='validFrom'
          format='YYYY-MM-DD hh:mm:ss'
          label={t("Valid From")}
          placeholder={t("Valid From")}
          controllerProps={{
            rules: { required: getRequiredValidation(t, true) },
          }}
          error={Boolean(formErrors?.validFrom?.message)}
          helperText={t(formErrors?.validFrom?.message)}
          datePickerProps={{
            disablePast: true,
            minDate: moment().add(1, "d"),
          }}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          name='validTill'
          format='YYYY-MM-DD hh:mm:ss'
          label={t("Valid Till")}
          placeholder={t("Valid Till")}
          error={Boolean(formErrors?.validTill?.message)}
          helperText={t(formErrors?.validTill?.message)}
          controllerProps={{
            rules: {
              required: getRequiredValidation(t, true),
            },
          }}
          datePickerProps={{
            disablePast: true,
            disabled: Boolean(!watch("validFrom")),
            minDate: moment(watch("validFrom")).add(1, "d"),
          }}
        />
      </Grid>
    </LocalizationProvider>
  );
};
