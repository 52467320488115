import { H_EntityError, HealthProgramNetworkProvider } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  networkFieldBreadcrumb,
  networkListBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthProgramNetworkProviderUpdateMutation, useHealthProgramNetworkProvidersQuery } from "../gql";
import { HealthProgramNetworkProviderForm } from "./components/HealthProgramNetworkProviderForm";

export const HealthProgramNetworkProviderEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const id = params?.id as string;
  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  const { data, loading } = useHealthProgramNetworkProvidersQuery({
    skip: !params?.providerId,
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 1,
      filter: {
        ids: [params?.providerId as string],
      },
    },
  });

  const healthProgramNetworkProvider = data?.healthProgramNetworkProviders?.edges.find(item => item?.node?.id === params?.providerId)
    ?.node! as Partial<HealthProgramNetworkProvider>;
  const [healthProgramNetworkProviderUpdate, { loading: isSubmitting }] = useHealthProgramNetworkProviderUpdateMutation({
    onCompleted: input => {
      const entityErrors = input?.healthProgramNetworkProviderUpdate?.entityErrors!;
      if (entityErrors?.length === 0) {
        succeeded(t("Network Provider Updated Successfully"));
        navigate(-1);
      } else {
        setError(entityErrors);
        failed(formatMessageErrors(entityErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });

  const handleSubmit = input => {
    const { providers, ...rest } = input;
    healthProgramNetworkProviderUpdate({
      variables: {
        id: healthProgramNetworkProvider?.id!,
        input: {
          ...rest,
          providerId: providers?.id,
          healthProgramNetwork: id,
          isActive: input?.isActive || false,
          virtualCallEnabled: input?.virtualCallEnabled || false,
        },
      },
    });
  };

  useEffect(() => {
    if (healthProgramNetworkProvider?.provider?.name) {
      setBreadCrumb({
        title: networkTitleBreadcrumb(t),
        values: [
          networkListBreadcrumb(t),
          networkFieldBreadcrumb("Providers", networksPaths.providersPath.fullPathWithParams(id)),
          networkFieldBreadcrumb(healthProgramNetworkProvider?.provider?.name),
        ],
      });
    }
  }, [healthProgramNetworkProvider?.provider?.name]);

  return (
    <HealthProgramNetworkProviderForm
      isLoading={loading}
      errors={errors}
      defaultValue={healthProgramNetworkProvider}
      onDone={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
