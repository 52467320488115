import { H_OrderDirection, HealthProgramMemberSortingField, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import {
  memberFieldBreadcrumb,
  membersBreadcrumb,
  membersTitleBreadcrumb,
} from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useHealthProgramMemberListsQuery, useHealthProgramMembersQuery } from "../gql";
import { useHealthProgramMembersColumns } from "./components/useHealthProgramMembersColumns";
import { HealthProgramMembersList } from "./HealthProgramMembersList";

export const HealthProgramMembers: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const params = useParams();
  const id = params?.id!;
  const { setBreadCrumb } = useBreadCrumbs();
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const handleEditRow = data => {
    navigate(memberListPaths.membersEditPath.fullPathWithParams(params?.id!, data?.id));
  };

  const { data } = useHealthProgramMemberListsQuery({
    variables: {
      first: 1,
      filter: {
        ids: [id],
      },
    },
  });
  const memberList = data?.healthProgramMemberLists?.edges?.map(item => item?.node)[0];
  const truncatedName = memberList?.name?.length! > 20 ? `${memberList?.name?.slice(0, 20)}...` : memberList?.name;

  useEffect(() => {
    if (truncatedName) {
      setBreadCrumb({
        title: membersTitleBreadcrumb(t),
        values: [membersBreadcrumb(t), memberFieldBreadcrumb(truncatedName)],
      });
    }
  }, [truncatedName]);
  const canAddNewOrImportMember = memberList?.isActive;
  return (
    <GridProvider
      variables={{
        filter: { healthProgramMemberLists: [id] },
        sortBy: { direction: H_OrderDirection.Desc, field: HealthProgramMemberSortingField.Created },
      }}
      gridName='healthProgramMembers'
      columns={useHealthProgramMembersColumns({ hasMembers: false })}
      query={useHealthProgramMembersQuery}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
      hideFilterInput={true}
    >
      <HealthProgramMembersList canManageActions={canManageActions} canAddNewOrImportMember={Boolean(canAddNewOrImportMember)} />
    </GridProvider>
  );
};
