import { Components, Theme } from "@mui/material/styles";

export const MuiTextFieldOverride: Components<Theme>["MuiTextField"] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        color: theme.palette.primary.main,
      };
    },
  },
  defaultProps: {
    variant: "filled",
    color: "primary",
  },
};
