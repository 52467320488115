import { OperationVariables } from "@apollo/client";
import { PageWrapperWithGrid } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "./GridProvider";
import { DataGridProps } from "./type";

export const DataGrid = <RowType extends object, TData = any, TVar extends OperationVariables = any>(
  props: DataGridProps<RowType, TData, TVar>
) => {
  const { name, query, columns, tableAction, deleteItemProps, hasTableSetting, addNewLink, editLink } = props;
  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate(addNewLink);
  };
  const handleGoToUpdateRow = ({ id }: any) => {
    navigate(editLink + id);
  };

  return (
    <GridProvider<RowType, TData, TVar>
      gridName={name}
      columns={columns}
      query={query}
      hasTableSetting={hasTableSetting}
      tableAction={{ ...tableAction, onEditRow: handleGoToUpdateRow }}
      deleteItemProps={deleteItemProps}
    >
      <PageWrapperWithGrid hasTableSetting={hasTableSetting} onAddNew={handleAddNew} />
    </GridProvider>
  );
};
