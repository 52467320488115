import React, { FC, useContext, PropsWithChildren, useState } from "react";
import { BreadcrumbContext, IBreadcrumbs } from "./BreadcrumbContext";

export const useBreadCrumbs = () => {
  const { setValues: setBreadCrumb, onChange, values } = useContext(BreadcrumbContext);
  return { setBreadCrumb, onChange, values };
};
export const BreadcrumbContextProvider: FC<PropsWithChildren<{ navigate: any }>> = ({ navigate, children }) => {
  const [value, setValue] = useState<IBreadcrumbs>();
  const handleChange = (nextState: IBreadcrumbs) => {
    setValue(nextState);
  };
  return (
    <BreadcrumbContext.Provider
      value={{
        values: value!,
        onChange: handleChange,
        setValues: setValue,
        navigate: navigate,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
