import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceOrdersTrackingPaths, marketplaceTrackingOrdersRoute } from "./constants/MarketPlaceTrackingPaths";
import { AdminMarketPlaceOrdersTrackingContainer } from "./List/MarketPlaceOrdersTrackingListContainer";

export const marketplaceOrdersTrackingRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  const canTrackOrders = hasPermission(PermissionEnum.ManageMarketplaceOrders);

  return {
    id: "marketplace-order-tracking-routes",
    text: i18n.t("Marketplace Orders Tracking", { ns: "admin" }),
    route: marketplaceTrackingOrdersRoute,
    fullPath: marketplaceOrdersTrackingPaths.mainPath.fullPath,
    element: <AdminMarketPlaceOrdersTrackingContainer />,
    hidden: !canTrackOrders,
    isProhibited: !canTrackOrders,
    hidden: !canTrackOrders,

    onClick: (route: string) => navigate(route),
    subItems: [
      {
        id: "marketplace-order-tracking-routes-id",
        route: marketplaceTrackingOrdersRoute,
        fullPath: marketplaceOrdersTrackingPaths.mainPath.fullPath,
        text: i18n.t("Marketplace Orders Tracking", { ns: "admin" }),
        element: <AdminMarketPlaceOrdersTrackingContainer />,
        onClick: route => navigate(route),
        isProhibited: !canTrackOrders,
        hidden: !canTrackOrders,
      },
    ],
  };
};
