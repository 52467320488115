import { User } from "@health/queries/types";
import { FC } from "react";
import { HealthProviderUserManagementForm } from "../Form/HealthProviderUserManagementForm";
import { useHealthProviderUserManagementEditHook } from "./HealthProviderUserManagementEdit.hook";

export const HealthProviderUserManagementEditFormPage: FC = () => {
  const { errors, handleUpdate, loading, user, isUpdateLoading } = useHealthProviderUserManagementEditHook();
  return (
    <HealthProviderUserManagementForm
      errors={errors}
      onDone={handleUpdate}
      defaultValues={user as User}
      isEdit
      isLoading={loading}
      isFormLoading={isUpdateLoading}
    />
  );
};
