/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type BranchInfoQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, healthLicense?: string | null, description: string, created: any, address?: { __typename?: 'Address', city: { __typename?: 'City', id: string, name: string, nameAr?: string | null } } | null, vendor: { __typename?: 'Vendor', name: string, description?: string | null, ownerName: string, nationalId: string } } | null };


export const BranchInfoDocument = gql`
    query branchInfo($id: ID!) {
  branch(id: $id) {
    id
    name
    healthLicense
    description
    created
    address {
      city {
        id
        name
        nameAr
      }
    }
    vendor {
      name
      description
      ownerName
      nationalId
    }
  }
}
    `;

/**
 * __useBranchInfoQuery__
 *
 * To run a query within a React component, call `useBranchInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchInfoQuery(baseOptions: Apollo.QueryHookOptions<BranchInfoQuery, BranchInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchInfoQuery, BranchInfoQueryVariables>(BranchInfoDocument, options);
      }
export function useBranchInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchInfoQuery, BranchInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchInfoQuery, BranchInfoQueryVariables>(BranchInfoDocument, options);
        }
export type BranchInfoQueryHookResult = ReturnType<typeof useBranchInfoQuery>;
export type BranchInfoLazyQueryHookResult = ReturnType<typeof useBranchInfoLazyQuery>;
export type BranchInfoQueryResult = Apollo.QueryResult<BranchInfoQuery, BranchInfoQueryVariables>;