import { Vendor } from "@health/queries/types";
import { ShowButton } from "@toolkit/ui";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface UsersListProps {
  id: string;
  provider: Partial<Vendor>;
}

export const UsersList: FC<UsersListProps> = ({ id, provider }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(providersManagementPaths.usersPath.fullPathWithParams(id), {
      state: {
        providerName: provider?.name,
      },
    });
  };

  return <ShowButton onClick={handleClick} />;
};
