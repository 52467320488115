import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthParameterUnitAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullable().optional(),
}));
export const HealthParameterUnitAutocompleteSchemas = {
    healthParameterUnitAutocomplete,
    healthParameterUnitAutocompleteOptional: healthParameterUnitAutocomplete.nullable().optional(),
};
