import { Type } from "@health/queries/types";
import moment from "moment";
import { getFilterValuePropertyName } from "../AdminManagedListDetails/utils";
export const getPropertyValueObjectForBackend = (item, formValue) => {
    const valuePropName = getFilterValuePropertyName(item === null || item === void 0 ? void 0 : item.fieldType);
    return {
        code: item === null || item === void 0 ? void 0 : item.code,
        fieldType: item === null || item === void 0 ? void 0 : item.fieldType,
        valueBoolean: null,
        valueString: null,
        valueDate: null,
        valueInteger: null,
        valueFloat: null,
        [valuePropName]: mapStringValueToBackendFieldType(item === null || item === void 0 ? void 0 : item.fieldType, formValue),
    };
};
export function mapStringValueToBackendFieldType(fieldType, propertyValue) {
    if (propertyValue === "")
        return null;
    switch (fieldType) {
        case Type.String:
            return propertyValue;
        case Type.Boolean:
            return typeof propertyValue === "string" ? (propertyValue === null || propertyValue === void 0 ? void 0 : propertyValue.toLowerCase()) === "yes" : propertyValue["value"] === "yes";
        case Type.Float:
            return !isNaN(propertyValue) ? parseFloat(propertyValue) : null;
        case Type.Integer:
            return !isNaN(propertyValue) ? parseInt(propertyValue) : null;
        case Type.Date:
            return propertyValue ? moment(propertyValue, "YYYY-MM-DD").format("YYYY-MM-DD") : null;
        default:
            return propertyValue;
    }
}
export const isFieldDefinitionValueValid = (item, formValue) => {
    const isValueInvalid = [null, "", undefined].includes(mapStringValueToBackendFieldType(item.fieldType, formValue));
    return !(item === null || item === void 0 ? void 0 : item.isMandatory) || !isValueInvalid;
};
export function getValidationErrors(codeSystemConceptFields, data) {
    const initialValue = [];
    return ((codeSystemConceptFields === null || codeSystemConceptFields === void 0 ? void 0 : codeSystemConceptFields.reduce((_errors, item) => {
        const propertyValue = data[item === null || item === void 0 ? void 0 : item.code];
        if (isFieldDefinitionValueValid(item, propertyValue)) {
            return _errors;
        }
        const message = `${item === null || item === void 0 ? void 0 : item.display} is required`;
        return [..._errors, { code: item === null || item === void 0 ? void 0 : item.code, message }];
    }, initialValue)) || initialValue);
}
