import { NetworkStatus } from "@apollo/client";
import { AutocompleteCustomHProps } from "./types";
import { get } from "lodash";

export const useAutocompleteCustomHook = ({
  useAutocompleteControllerQuery,
  input,
  onCompleted,
  onError,
  dataAccessor,
  skip,
}: AutocompleteCustomHProps) => {
  const { data, loading, networkStatus, fetchMore, refetch, ...queryResult } = useAutocompleteControllerQuery({
    variables: { ...input },
    fetchPolicy: "network-only",
    onCompleted: onCompleted,
    onError: onError,
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const onFetchMoreData = () => {
    if (!get(data, dataAccessor + ".pageInfo.hasNextPage", false)) return;

    fetchMore({
      variables: { ...input, after: get(data, dataAccessor + ".pageInfo.endCursor") as string },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          ...fetchMoreResult,
          [dataAccessor as string]: {
            ...fetchMoreResult?.[dataAccessor as string],
            edges: [...(prev?.[dataAccessor as string]?.edges ?? []), ...(fetchMoreResult?.[dataAccessor as string]?.edges ?? [])],
          },
        });
      },
    });
  };

  const isLoading: boolean = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  return {
    ...queryResult,
    data,
    loading,
    isLoading,
    fetchMore,
    refetch,
    input,
    networkStatus,
    onFetchMoreData,
  };
};
