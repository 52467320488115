/* eslint-disable react/boolean-prop-naming */
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { UpdateUserMutation, useUpdateUserMutation } from "pages/UserManagement/gql";
import React, { FC } from "react";

type ActiveToggleProps = {
  id: string;
  isActive: boolean;
  disabled?: boolean;
};

export const ProviderUserActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive, disabled = false }) => {
  const { t } = useTranslation("admin");
  const [checked, setChecked] = React.useState(isActive);
  const { succeeded, failed } = useAddToast();

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: (data: UpdateUserMutation) => {
      const errors = data?.userUpdate?.accountErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
      } else {
        setChecked(data?.userUpdate?.user?.isActive as boolean);
        succeeded(t("User updated successfully"));
      }
    },
    onError: () => {
      failed(t("Failed in updating user"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    updateUser({
      variables: {
        userUpdateId: id,
        input: {
          isActive: isChecked,
        },
      },
    });
  };
  return <CustomToggleButton disabled={disabled} checked={checked} onChange={handleChangeActivity} isLoading={loading} />;
};
