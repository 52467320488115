import { z } from "zod";
import { i18n } from "@toolkit/i18n";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { Priority } from "@health/queries/types";
import { DecisionActionFieldsFormSchema } from "../../../Decisions/forms/DecisionActionFields/DecisionActionFieldsFormSchema";
import { DecisionConditionsGroupsFormSchema } from "../../../Decisions/forms/DecisionConditionsGroups/DecisionConditionsGroupsFormSchema";
export const DecisionUpsertFormSchema = z
    .object({
    name: z.string().min(3).max(255),
    decisionPlan: createZodAutocompleteObject(z.object({
        id: z.string(),
        code: z.string(),
        display: z.string(),
    })),
    priority: createZodAutocompleteObject(Priority),
    healthParameter: createZodAutocompleteObject(z.object({
        id: z.string(),
        code: z.string(),
        display: z.string(),
    })).optional(),
    isActive: z.boolean(),
    isScript: z.boolean(),
    script: z.string().optional(),
    actionFields: z.array(DecisionActionFieldsFormSchema),
    conditionsGroups: z.array(DecisionConditionsGroupsFormSchema),
})
    .superRefine((data, ctx) => {
    if (data.isScript && !data.script) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["script"],
        });
    }
});
export const decisionUpsertFormSchemaDefaultValues = {
    name: undefined,
    decisionPlan: undefined,
    priority: undefined,
    healthParameter: undefined,
    isActive: false,
    isScript: false,
    script: undefined,
    actionFields: [],
    conditionsGroups: [],
};
