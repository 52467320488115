import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthProgramNetworkAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const HealthProgramNetworkAutocompleteSchemas = {
    healthProgramNetworkAutocomplete,
    healthProgramNetworkAutocompleteOptional: healthProgramNetworkAutocomplete.nullable().optional(),
};
