import { makeStyles } from "tss-react/mui";

export const useNetworkStatusStyles = makeStyles<{ isOpen: boolean }>()((_, { isOpen }) => ({
  ltr: {
    ["@media (min-width: 600px)"]: {
      top: "10px !important",
      left: isOpen ? "56%" : "50%",
    },
  },
  rtl: {
    ["@media (min-width: 600px)"]: {
      top: "10px !important",
      left: isOpen ? "44%" : "50%",
    },
  },
}));
