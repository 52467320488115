/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramExtraBenefitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthProgramExtraBenefitsQuery = { __typename?: 'Query', healthProgramExtraBenefits?: Array<{ __typename?: 'ExtraBenefit', id: string, name?: string | null } | null> | null };


export const HealthProgramExtraBenefitsDocument = gql`
    query healthProgramExtraBenefits {
  healthProgramExtraBenefits {
    id
    name
  }
}
    `;

/**
 * __useHealthProgramExtraBenefitsQuery__
 *
 * To run a query within a React component, call `useHealthProgramExtraBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramExtraBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramExtraBenefitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthProgramExtraBenefitsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramExtraBenefitsQuery, HealthProgramExtraBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramExtraBenefitsQuery, HealthProgramExtraBenefitsQueryVariables>(HealthProgramExtraBenefitsDocument, options);
      }
export function useHealthProgramExtraBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramExtraBenefitsQuery, HealthProgramExtraBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramExtraBenefitsQuery, HealthProgramExtraBenefitsQueryVariables>(HealthProgramExtraBenefitsDocument, options);
        }
export type HealthProgramExtraBenefitsQueryHookResult = ReturnType<typeof useHealthProgramExtraBenefitsQuery>;
export type HealthProgramExtraBenefitsLazyQueryHookResult = ReturnType<typeof useHealthProgramExtraBenefitsLazyQuery>;
export type HealthProgramExtraBenefitsQueryResult = Apollo.QueryResult<HealthProgramExtraBenefitsQuery, HealthProgramExtraBenefitsQueryVariables>;