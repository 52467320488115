import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const subListAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    display: z.string(),
}));
export const SubListAutocompleteSchemas = {
    subListAutocomplete,
    subListAutocompleteOptional: subListAutocomplete.nullable().optional(),
};
