import { getMaxValueValidation, getMinValueValidation, getNumericValidation, getRequiredValidation, NumericPattern } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export enum siteSetting {
  siteSettings = "siteSettings",
  healthProgramsSiteSettings = "healthProgramsSiteSettings",
}

export const fieldPattern = t => {
  return {
    required: getRequiredValidation(t, true),
    pattern: getNumericValidation(t, NumericPattern),
    min: getMinValueValidation(t, 0),
    max: getMaxValueValidation(t, 99),
  };
};
export const requiredPositiveIntegerPattern = (t: TFunction) => {
  return {
    required: getRequiredValidation(t, true),
    pattern: getNumericValidation(t, NumericPattern),
  };
};

export const minutesToSeconds = (value?: number | null) => {
  return (value ?? 0) * 60;
};

export const secondsToMinutes = (value?: number | null) => {
  return (value ?? 0) / 60;
};
