import { Grid } from "@toolkit/ui";
import { ParentSize } from "@visx/responsive";
import React from "react";
import { CARD_HEIGHT } from "./constants";
import { StatisticsCard } from "./StatisticsCard";
import StatisticsCardLoader from "./StatisticsCard/StatisticsCardLoader";
import { useSurveyStatisticsTab } from "./useSurveyStatisticsTab";

export const SurveyStatisticsTab: React.FC = () => {
  const { isLoading, surveyStatistics } = useSurveyStatisticsTab();
  return (
    <Grid container padding={4} spacing={2}>
      {isLoading ? (
        <StatisticsCardLoader />
      ) : (
        surveyStatistics?.questionsStatistics?.map(item => (
          <Grid key={item!.question?.id} item xs={12} sm={6}>
            <ParentSize>
              {({ width }) => <StatisticsCard height={Math.min(CARD_HEIGHT, width)} width={width} questionStatistics={item!} />}
            </ParentSize>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default SurveyStatisticsTab;
