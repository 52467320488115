import { makeStyles } from "tss-react/mui";
import { renderSwitch } from "./utils";

export const useNotificationItemStyles = makeStyles<{ type: string }>()((theme, { type }) => ({
  card: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 5,
    paddingInline: 0,
    color: renderSwitch(type, theme.palette).color,
  },
  icon: {
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.md,
    background: renderSwitch(type, theme.palette).color,
  },
  titleNote: {
    fontWeight: 600,
  },
  item: {
    margin: 10,
  },
  link: {
    color: renderSwitch(type, theme.palette).color,
  },
}));
