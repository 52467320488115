import { replacePathParams } from "utils";

export const healthInsurancesManagementPath = "/insurances-management";
const healthInsurancesManagementNewPath = "new";
const healthInsurancesManagementEditPath = "edit/:id";

export const healthInsurancesManagementPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${healthInsurancesManagementPath}/${this.route}`;
    },
  },
  newPath: {
    route: healthInsurancesManagementNewPath,
    get fullPath() {
      return `${healthInsurancesManagementPath}/${this.route}`;
    },
  },
  editPath: {
    route: healthInsurancesManagementEditPath,
    get fullPath() {
      return `${healthInsurancesManagementPath}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
