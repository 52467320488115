const vendorTotalPointsReportRoute = "/vendor-total-points-report";

export const vendorTotalPointsReportPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${vendorTotalPointsReportRoute}${this.route}`;
    },
  },
};
