import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TradeDrugTypeTrackerForm } from "./components/TradeDrugTypeTrackerForm";
import {
  tradeDrugTypeFieldBreadcrumb,
  tradeDrugTypesListBreadcrumb,
  tradeDrugTypesTitleBreadcrumb,
} from "../constants/trade-drug-types-breadcrumbs";
import { useTrackerTradeDrugTypeQuery, useTrackerTradeDrugTypeUpdateMutation } from "../gql";

export const TradeDrugTypeTrackerEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, loading } = useTrackerTradeDrugTypeQuery({
    skip: !params?.id,
    variables: {
      id: params?.id!,
    },
  });

  const trackerTradeDrugType = data?.trackerTradeDrugType;
  const [tradeDrugTypeUpdate, { loading: isSubmitting }] = useTrackerTradeDrugTypeUpdateMutation({
    onCompleted: response => {
      const id = response?.trackerTradeDrugTypeUpdate?.id!;
      if (id) {
        succeeded(t("Trade Drug Type Updated Successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    delete input?.payerId;
    tradeDrugTypeUpdate({
      variables: {
        id: params?.id!,
        input,
      },
    });
  };

  useEffect(() => {
    if (params?.id) {
      setBreadCrumb({
        title: tradeDrugTypesTitleBreadcrumb(t),
        values: [tradeDrugTypesListBreadcrumb(t), tradeDrugTypeFieldBreadcrumb(trackerTradeDrugType?.name!)],
      });
    }
  }, [trackerTradeDrugType?.name]);

  return (
    <TradeDrugTypeTrackerForm
      isLoading={loading}
      isSubmitting={isSubmitting}
      defaultValue={trackerTradeDrugType!}
      onSubmit={handleSubmit}
    />
  );
};
