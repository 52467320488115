import { jsx as _jsx } from "react/jsx-runtime";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "../../../../shared/components";
import { useGetMarketplaceOrdersQuery } from "../../../OrdersTracking/gql/queries";
import { useMarketplaceOrdersTrackingColumns } from "./useMarketplaceOrdersTrackingColumns";
export const MarketplaceOrdersTrackingPage = ({ isAdmin }) => {
    return (_jsx(GridProvider, { gridName: 'marketplace-orders', variables: {
            isAdmin,
        }, fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first', columns: useMarketplaceOrdersTrackingColumns({ isAdmin }), query: useGetMarketplaceOrdersQuery, hasTableSetting: true, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsx(TableSettingComponent, {}), children: _jsx(TableGrid, {}) }) }));
};
