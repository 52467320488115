import { NetworkStatus } from "@apollo/client";
import { formatGraphQLError } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import { useFetchSurveyStatisticsQuery } from "pages/Surveys/gql/queries";
import { useParams } from "react-router-dom";

export const useSurveyStatisticsTab = () => {
  const { surveyId } = useParams();
  const { failed } = useAddToast();

  const { data, networkStatus } = useFetchSurveyStatisticsQuery({
    variables: { surveyStatisticsId: surveyId! },
    skip: surveyId == "new" || !surveyId,
    onError: ({ graphQLErrors }) => {
      if (surveyId != "new") {
        failed(formatGraphQLError(graphQLErrors));
      }
    },
  });

  const isLoading = NetworkStatus.loading === networkStatus;

  return {
    surveyStatistics: data?.surveyStatistics,
    isLoading: isLoading,
  };
};
