import { Branch } from "@health/queries/types";
import { ActiveFilters, ActiveFiltersProps, StaticFilterDialog, StaticFilterDialogProps, useGridContext } from "@toolkit/ui";
import { omit } from "lodash";
import React from "react";

export const BranchesListFilter: React.VFC = () => {
  const { staticFiltersProps, activeFiltersProps, fields } = useGridContext();

  const staticFilterDialogProps: StaticFilterDialogProps<Branch> = {
    isOpen: staticFiltersProps?.isOpen!,
    onApplyFilters: staticFiltersProps?.onApplyFilters!,
    onToggleDialog: staticFiltersProps?.onToggleDialog!,
    filterFields: fields!,
  };

  const activeFiltersPropsWithoutVendors = omit(activeFiltersProps, ["activeFilters.vendors"]) as ActiveFiltersProps;

  return (
    <>
      <ActiveFilters {...activeFiltersPropsWithoutVendors} />
      <StaticFilterDialog {...staticFilterDialogProps} />
    </>
  );
};
