import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import moment from "moment";
import { programActivityTitleBreadcrumb } from "pages/ProgramActivityTracking/constants/program-activity-breadcrumbs";
import React, { useEffect } from "react";
import { GridProvider } from "shared/components";
import { useProgramTrackingColumns } from "./components/useProgramTrackingColumns";
import { useHealthProgramTotalPointsReportQuery } from "./gql";

export const ProgramActivityTracking = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const month = moment().month() + 1;
  const year = moment().get("year");

  useEffect(() => {
    setBreadCrumb({
      title: programActivityTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='doctors'
      filterInput={{ month, year }}
      variables={{
        filter: { month, year },
      }}
      columns={useProgramTrackingColumns()}
      query={useHealthProgramTotalPointsReportQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper filters={<FilterGrid />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
