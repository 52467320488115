/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackerTradeDrugTypeUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.TrackerTradeDrugTypeInput;
}>;


export type TrackerTradeDrugTypeUpdateMutation = { __typename?: 'Mutation', trackerTradeDrugTypeUpdate?: { __typename?: 'TrackerTradeDrugType', id: string, name: string, photo?: string | null } | null };


export const TrackerTradeDrugTypeUpdateDocument = gql`
    mutation TrackerTradeDrugTypeUpdate($id: ID!, $input: TrackerTradeDrugTypeInput!) {
  trackerTradeDrugTypeUpdate(id: $id, input: $input) {
    id
    name
    photo
  }
}
    `;
export type TrackerTradeDrugTypeUpdateMutationFn = Apollo.MutationFunction<TrackerTradeDrugTypeUpdateMutation, TrackerTradeDrugTypeUpdateMutationVariables>;

/**
 * __useTrackerTradeDrugTypeUpdateMutation__
 *
 * To run a mutation, you first call `useTrackerTradeDrugTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackerTradeDrugTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackerTradeDrugTypeUpdateMutation, { data, loading, error }] = useTrackerTradeDrugTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackerTradeDrugTypeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TrackerTradeDrugTypeUpdateMutation, TrackerTradeDrugTypeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackerTradeDrugTypeUpdateMutation, TrackerTradeDrugTypeUpdateMutationVariables>(TrackerTradeDrugTypeUpdateDocument, options);
      }
export type TrackerTradeDrugTypeUpdateMutationHookResult = ReturnType<typeof useTrackerTradeDrugTypeUpdateMutation>;
export type TrackerTradeDrugTypeUpdateMutationResult = Apollo.MutationResult<TrackerTradeDrugTypeUpdateMutation>;
export type TrackerTradeDrugTypeUpdateMutationOptions = Apollo.BaseMutationOptions<TrackerTradeDrugTypeUpdateMutation, TrackerTradeDrugTypeUpdateMutationVariables>;