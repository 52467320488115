import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Typography, useAddToast } from "@toolkit/ui";
import { useTerminateVendorSubscriptionMutation } from "gql/mutations";
import { FC } from "react";
import { useTerminateVendorStyles } from "./VendorList.styles";
import { TerminateVendorInterface } from "./VendorList.types";

const TerminateVendor: FC<TerminateVendorInterface> = props => {
  const { id, isActive } = props;
  const { classes } = useTerminateVendorStyles();
  const { succeeded, failed } = useAddToast();
  const { t, i18n } = useTranslation("admin");

  const currentDir = i18n.dir(i18n.language);

  const [terminateVendor] = useTerminateVendorSubscriptionMutation({
    onCompleted: data => {
      const errors = data?.terminateVendorSubscription?.vendorErrors;
      if (errors?.length === 0) {
        succeeded(t("Terminate Vendor Subscription Successfully"));
      } else {
        formatMessageErrors(errors);
      }
    },
    onError: () => {
      failed(t("Terminate Vendor Subscription Failed"));
    },
  });

  const handleTerminateVendor = () => {
    terminateVendor({
      variables: {
        vendor: id,
      },
    });
  };
  return (
    <>
      <CustomDialog
        type='base'
        DialogTitleProps={{
          title: t("Terminate Vendor Plan"),
        }}
        DialogActionsProps={{
          children: (
            <Button onClick={handleTerminateVendor} color={"primary"} variant='contained'>
              {t("Terminate")}
            </Button>
          ),
        }}
        button={
          <Button color={isActive ? "warning" : "primary"} disabled={!isActive} size={"small"} className={classes.customButton}>
            {t("Terminate Vendor Plan")}
          </Button>
        }
      >
        <Typography variant={"h6"} gutterBottom dir={currentDir}>
          {t("Are you sure you want to Terminate Vendor Plan?")}
        </Typography>
      </CustomDialog>
    </>
  );
};

export default TerminateVendor;
