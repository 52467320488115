import * as Types from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { shouldQuestionHaveOptions } from "pages/Surveys/utils";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useQuestionCardStyles } from "./QuestionCard.styles";
import { QuestionCardProps } from "./types";

export const useQuestionCard = ({ onAddOption, questionIndex }: Partial<QuestionCardProps>) => {
  const [hoveredOption, setHoveredOption] = useState<Types.Maybe<string>>();
  const [option, setOption] = useState("");
  const [selectedOptionForEdit, setSelectedOptionForEdit] = useState<Types.Maybe<string>>();
  const { t } = useTranslation("admin");

  const currentDir = i18n.dir(i18n.language);
  const { classes } = useQuestionCardStyles();

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const requiredQuestion = watch(`questions.${questionIndex}.required`);
  const questionType = watch(`questions.${questionIndex}.questionType`);
  const questionOptions = watch(`questions.${questionIndex}.options`);

  const questionTypeOptions: Types.QuestionType[] = [
    Types.QuestionType.MultiChoice,
    Types.QuestionType.Boolean,
    Types.QuestionType.SingleChoice,
    Types.QuestionType.String,
  ];

  const getDisplayedQuestionType = (questionType: Types.QuestionType) => {
    const QuestionTypeMap: Record<Types.QuestionType, string> = {
      BOOLEAN: t("Yes/No"),
      MULTI_CHOICE: t("Multiple Choice"),
      SINGLE_CHOICE: t("Single Choice"),
      STRING: t("Free Text"),
    };
    return QuestionTypeMap[questionType];
  };

  const handleOptionChange = (value: string) => {
    setOption(value);
  };

  const handleAddOption = () => {
    onAddOption?.(option);
    setOption("");
  };

  const handleSelectOptionForEdit = (optionText?: Types.Maybe<string>) => {
    setSelectedOptionForEdit(optionText);
  };

  const handleCopyOption = (copiedOption: string) => {
    setOption(copiedOption);
  };

  useEffect(() => {
    if (questionType) {
      const options = shouldQuestionHaveOptions(questionType) ? questionOptions : [];
      setValue(`questions.${questionIndex}.options`, options);
    }
  }, [questionType]);

  return {
    questionTypeOptions,
    currentDir,
    hoveredOption,
    classes,
    option,
    selectedOptionForEdit,
    control,
    requiredQuestion,
    reactFormErrors,
    questionOptions,
    questionType,
    canManageSurvey: hasPermission(Types.PermissionEnum.ManageSurveys),
    register,
    handleSelectOptionForEdit,
    handleOptionChange,
    handleAddOption,
    handleCopyOption,
    t,
    onOptionHovered: setHoveredOption,
    getDisplayedQuestionType,
  };
};

export default useQuestionCard;
