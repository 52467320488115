import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const articleAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    title: z.string(),
}));
export const ArticleAutocompleteSchemas = {
    articleAutocomplete,
    articleAutocompleteOptional: articleAutocomplete.nullable().optional(),
};
