import { styled, Theme, Typography } from "../../base/mui";

export const TypographyRegular = styled(Typography)((theme: Theme) => ({
  fontWeight: theme.mixins.fonts.fontWeight.regular,
  fontSize: theme.mixins.fonts.fontSize.md,
}));

export const TypographyMedium = styled(Typography)((theme: Theme) => ({
  fontWeight: theme.mixins.fonts.fontWeight.medium,
  fontSize: theme.mixins.fonts.fontSize.md,
}));

export const TypographyBold = styled(Typography)((theme: Theme) => ({
  fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  fontSize: theme.mixins.fonts.fontSize.md,
}));
