import { TFunction } from "@toolkit/i18n";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";

export const healthProgramTitleBreadcrumb = (t: TFunction) => {
  return t("Program Profiles", { ns: "admin" });
};

export const healthProgramListBreadcrumb = (t: TFunction) => {
  return {
    id: "health-program-list",
    name: t("Program Profiles", { ns: "admin" }),
    route: healthProgramPaths.listPath.fullPath,
  };
};

export const healthProgramNewBreadcrumb = (t: TFunction) => {
  return {
    id: "health-program-new",
    name: t("New", { ns: "admin" }),
  };
};

export const healthProgramFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "health-program-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};

export const healthProgramProgramLogicBreadcrumb = (t: TFunction) => {
  return {
    id: "health-program-program-logic",
    name: t("Program Logic Parameters", { ns: "admin" }),
  };
};
