import React, { useState } from "react";
import { Checkbox, IconButton, TableCell, TableRow, Typography } from "../../../base/mui";
import { MuiCreateIcon, MuiDeleteForeverIcon } from "../../../base/mui-icons";
import { CustomDialog } from "../../Dialogs";
import { SelectionStatus } from "../constants";
import { useTableBodyLayoutStyles } from "../styles/useTableBodyLayoutStyles";
import { CustomTableColumnProps } from "../types";
import { TableBodyLoader } from "./TableLoader";
import { TableBodyLayoutProps } from "./types";

export const TableBodyLayout = <RowType extends object>({
  isLoading,
  data,
  isEditVisible,
  isRowDeletable = () => true,
  isRowEditable = () => true,
  pageSize = 10,
  columnsToRender,
  onRowClick,
  extraActionsRenderer,
  onEditRow,
  onDeleteRow,
  isDeleteVisible,
  isCardView,
  onRowSelectionStatusChange,
  rowsSelectionStatus,
  isSelectVisible,
}: TableBodyLayoutProps<RowType>) => {
  const { classes, theme } = useTableBodyLayoutStyles();
  const [rowToBeDeleted, setRowToBeDeleted] = useState<null | RowType>(null);
  const [rowIndexToBeDeleted, setRowIndexToBeDeleted] = useState<null | number>(null);

  const renderCell = (row: RowType, column: CustomTableColumnProps<RowType>, index: number) => {
    return (
      <Typography
        component='span'
        fontWeight={theme.mixins.fonts.fontWeight.regular}
        fontSize={theme.mixins.fonts.fontSize.sm}
        width={"max-content"}
      >
        {typeof column.accessor === "function" ? (column.accessor?.(row, index) as any) : row[column.accessor as keyof RowType]}
      </Typography>
    );
  };

  const handleOpenDeleteDialog = (row?: RowType | null, rowIndex?: number) => () => {
    setRowToBeDeleted(row ? row : null);
    setRowIndexToBeDeleted(rowIndex!);
  };

  const handleClose = () => {
    setRowToBeDeleted(null);
  };
  const handleDeleteConfirmation = () => {
    onDeleteRow(rowToBeDeleted!, rowIndexToBeDeleted!);
    setRowToBeDeleted(null);
  };
  const handleRowClick = (row: RowType) => {
    onRowClick?.(row);
  };

  const handleDelete = () => {
    if (rowToBeDeleted) {
      handleDeleteConfirmation();
      handleClose();
    }
  };

  const getBooleanValueFn = (predicate: boolean | ((row: RowType) => boolean)) => {
    return typeof predicate === "function" ? predicate : () => Boolean(predicate);
  };

  return (
    <>
      {data && !isLoading ? (
        data?.map((row, rowIndex) => {
          const _isRowDeletable = getBooleanValueFn(isRowDeletable)(row);
          const _isRowEditable = getBooleanValueFn(isRowEditable)(row);
          return (
            <TableRow
              key={`${rowIndex}`}
              hover
              classes={{
                hover: classes.tableRowHover,
              }}
              onClick={handleRowClick.bind(null, row)}
              className={isCardView ? `${classes.tableRow} ${rowIndex % 2 === 0 && classes.row}` : classes.tableRow}
              sx={{
                position: "relative",
              }}
            >
              {isSelectVisible && (
                <TableCell padding='checkbox' className={classes.cell} width={"min-content"} sx={{ padding: "0!important" }}>
                  <Typography component='span' fontWeight={500} fontSize='14px' width={"min-content"}>
                    <Checkbox
                      color='primary'
                      disabled={rowsSelectionStatus[rowIndex] === SelectionStatus.Disabled}
                      checked={rowsSelectionStatus[rowIndex] === SelectionStatus.Selected}
                      // eslint-disable-next-line react/jsx-handler-names
                      onChange={event => onRowSelectionStatusChange(rowIndex, event)}
                    />
                  </Typography>
                </TableCell>
              )}
              {columnsToRender?.map((column, index) => (
                <TableCell className={classes.cell} key={index + 1} sx={column.sx}>
                  {renderCell(row, column, rowIndex)}
                </TableCell>
              ))}
              {columnsToRender.length !== 0 && (isDeleteVisible || isEditVisible || extraActionsRenderer) && (
                <TableCell className={`${classes.cell} ${classes.actionsCell}`}>
                  {isEditVisible && (
                    <IconButton
                      // eslint-disable-next-line react/jsx-handler-names
                      onClick={event => onEditRow(event, row)}
                      disabled={!_isRowEditable}
                      sx={{
                        fontWeight: theme.mixins.fonts.fontWeight.regular,
                        fontSize: theme.mixins.fonts.fontSize.lg,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <MuiCreateIcon fontSize='inherit' />
                    </IconButton>
                  )}
                  {isDeleteVisible && (
                    <IconButton
                      color='error'
                      onClick={handleOpenDeleteDialog(row, rowIndex)}
                      disabled={!_isRowDeletable}
                      sx={{
                        fontWeight: theme.mixins.fonts.fontWeight.regular,
                        fontSize: theme.mixins.fonts.fontSize.lg,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <MuiDeleteForeverIcon fontSize='inherit' className={classes.delete} />
                    </IconButton>
                  )}
                  {extraActionsRenderer && extraActionsRenderer(row)}
                </TableCell>
              )}
            </TableRow>
          );
        })
      ) : (
        <TableBodyLoader numberOfColumns={columnsToRender.length} numberOfRows={pageSize!} />
      )}

      {rowToBeDeleted && <CustomDialog isOpen={true} type='delete' onClose={handleClose} onDelete={handleDelete} />}
    </>
  );
};
