import { z } from "zod";
import { zodSchema } from "@health/domains";

export const CategoryUpsertFormSchema = z.object({
  name: z.string().min(3).max(255),
  nameAr: z.string().min(3).max(255).nullable().optional(),
  description: z.string().nullable().optional(),
  descriptionAr: z.string().nullable().optional(),
  parentCategory: zodSchema.MarketplaceProductCategoryAutocompleteOptional,
  image: z.string().nullable().optional(),
});

export type ICategoryUpsertFormValues = z.infer<typeof CategoryUpsertFormSchema>;

export const categoryUpsertFormSchemaDefaultValues: Partial<ICategoryUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  description: undefined,
  descriptionAr: undefined,
  parentCategory: undefined,
  image: undefined,
};
