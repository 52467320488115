import { useInsuranceNetworkQuery } from "pages/HealthInsurance/gql";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NetworkInsuranceForm from "./components/NetworkInsuranceForm";
import { InsuranceFormPagesProps } from "./components/types";

export const NetworkInsuranceEditPage: FC<InsuranceFormPagesProps> = () => {
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const {
    data,
    loading: isDataLoading,
    refetch,
  } = useInsuranceNetworkQuery({
    variables: {
      id: params.id,
    },
  });
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <NetworkInsuranceForm
      onCancel={handleCancel}
      data={data?.insuranceNetwork}
      isDataLoading={isDataLoading}
      isEditMode={true}
      refetch={refetch}
    />
  );
};
