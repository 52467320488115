import { HealthProgramTotalPointsTrackingResponse } from "@health/queries/types";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps, Typography } from "@toolkit/ui";
import moment from "moment";
import { programActivityPaths } from "pages/ProgramActivityTracking/constants/program-activity-paths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useHealthProgramsListQuery } from "../../HealthProgramMembers";
import { useDoctorsQuery, useVendorsQuery } from "../gql";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<HealthProgramTotalPointsTrackingResponse>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useProgramTrackingColumns = (): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");

  const navigate = useNavigate();

  const handleClick = (healthProgramId, doctorId) => () => {
    navigate(programActivityPaths.providerPath.fullPathWithParams(healthProgramId, doctorId));
  };

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Enrolled Doctors"),
        accessor: ({ doctor }) =>
          doctor ? (
            <Box display='flex' alignItems='center'>
              <Avatar src={doctor?.user?.photo!} />
              <Typography sx={{ paddingInline: 1 }}>{doctor?.user?.firstName + " " + doctor?.user?.lastName}</Typography>
            </Box>
          ) : (
            "-"
          ),
        type: "string",
      },
      {
        key: "vendor-id",
        header: t("Provider"),
        accessor: ({ doctor }) => doctor?.vendor?.name,
        type: "string",
      },
      {
        key: "total-points",
        header: t("Total Points"),
        accessor: ({ totalPoints, healthProgramId, doctor }) => (
          <Box
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              paddingInline: "10px",
              width: "fit-content",
              borderRadius: "25px",
            }}
            onClick={handleClick(healthProgramId, doctor?.id)}
          >
            {totalPoints}
          </Box>
        ),
        type: "string",
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useHealthProgramsListQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => (isNaN(options?.id) ? undefined : Number(options?.id)),
          name: "healthProgramId",
          accessor: "healthPrograms",
        },
      },
      {
        key: "month",
        header: t("Month"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "month",
          min: 1,
          max: 12,
          initialValue: moment().get("month"),
        },
      },
      {
        key: "year",
        header: t("Year"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "year",
          min: 2022,
          max: moment().get("year") + 1,
          initialValue: moment().get("year"),
        },
      },
      {
        key: "Doctors",
        header: t("Doctors"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useDoctorsQuery,
          getOptionLabel: option => formatUserNameFull(option?.user!),
          getValueForBackend: value => value?.id || null,
          initialValue: null,
          name: "doctorId",
          accessor: "doctors",
        },
      },
      {
        key: "Vendors",
        header: t("Vendors"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useVendorsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
          name: "vendorId",
          accessor: "vendors",
        },
      },
      {
        key: "percentage",
        header: t("percentage"),
        accessor: x => `${x?.["percentage"]}%`,
      },
      {
        key: "healthProgramName",
        header: t("Health Program Name"),
        accessor: "healthProgramName",
      },
    ];
  }, []);
};
