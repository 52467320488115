import { Vendor, VendorTypeEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";

export type VendorColumnType = CustomTableColumnProps<Vendor>;
export type VendorColumnsType = VendorColumnType[];
export interface GetVendorsGridColumnsProps {
  t: TFunction;
}
export const getVendorType = item => Object.keys(VendorTypeEnum).filter(x => VendorTypeEnum[x] === item);
export interface OwnerInfoProps {
  nationalId?: string;
  ownerName?: string;
}
export interface BankingInfoProps {
  iban?: string | undefined;
  accountNumber?: string | undefined;
  bankName?: string | undefined;
  accountName?: string | undefined | null;
}
export interface WorkingHoursProps {
  id: string;
}
export interface TerminateVendorInterface {
  id: string;
  isActive: boolean;
}
export interface ToggleActiveSwitchProps {
  id: string;
  isActive: boolean | undefined;
  approved;
}
