/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPromotionUpdateMutationVariables = Types.Exact<{
  adminPromotionUpdateId: Types.Scalars['ID'];
  input: Types.AdminPromotionInput;
}>;


export type AdminPromotionUpdateMutation = { __typename?: 'Mutation', adminPromotionUpdate?: { __typename?: 'AdminPromotion', id: string } | null };


export const AdminPromotionUpdateDocument = gql`
    mutation AdminPromotionUpdate($adminPromotionUpdateId: ID!, $input: AdminPromotionInput!) {
  adminPromotionUpdate(id: $adminPromotionUpdateId, input: $input) {
    id
  }
}
    `;
export type AdminPromotionUpdateMutationFn = Apollo.MutationFunction<AdminPromotionUpdateMutation, AdminPromotionUpdateMutationVariables>;

/**
 * __useAdminPromotionUpdateMutation__
 *
 * To run a mutation, you first call `useAdminPromotionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPromotionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPromotionUpdateMutation, { data, loading, error }] = useAdminPromotionUpdateMutation({
 *   variables: {
 *      adminPromotionUpdateId: // value for 'adminPromotionUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPromotionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdminPromotionUpdateMutation, AdminPromotionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPromotionUpdateMutation, AdminPromotionUpdateMutationVariables>(AdminPromotionUpdateDocument, options);
      }
export type AdminPromotionUpdateMutationHookResult = ReturnType<typeof useAdminPromotionUpdateMutation>;
export type AdminPromotionUpdateMutationResult = Apollo.MutationResult<AdminPromotionUpdateMutation>;
export type AdminPromotionUpdateMutationOptions = Apollo.BaseMutationOptions<AdminPromotionUpdateMutation, AdminPromotionUpdateMutationVariables>;