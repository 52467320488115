import { makeStyles } from "tss-react/mui";

export const useAdminHeaderStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.divider,
    border: "1px solid",
    borderColor: theme.palette.divider,
  },
  appMenu: {
    backgroundColor: theme.palette.common.white,
    height: theme.mixins.header.height,
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    ...(isOpen && {
      width: `calc(100% - ${theme.mixins.sidebar.width}px)`,
      marginInlineStart: `${theme.mixins.sidebar.width}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        width: `100%`,
      },
    }),
  },
  leftIcon: {
    background: isOpen ? "transparent" : theme.palette.primary.main,
    color: isOpen ? theme.palette.primary.main : theme.palette.common.white,
    "&:hover": { color: isOpen ? theme.palette.primary.main : theme.palette.stale.main },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.md,
    lineHeight: 1,
  },
  branchName: {
    fontWeight: "bold",
    cursor: "pointer",
    marginInline: 1,
  },
  iconButton: {
    margin: 1,
    "&.MuiIconButton-root": {
      width: 35,
      height: 35,
    },
  },
}));
