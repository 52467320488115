import { UserTokenPayload } from "./types/UserTokenPayload";

interface FrontendToolkitConfig {
  baseMediaUrl: string;
  fileServiceApiUrl: string;
  utils: {
    getAccessToken: () => string | null;
  };
}

class Config {
  private static instance: Config;
  private config: FrontendToolkitConfig | null = null;

  private constructor() {}

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: FrontendToolkitConfig): void {
    this.config = config;
  }

  getConfig(): FrontendToolkitConfig {
    if (!this.config) {
      throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
    }
    return this.config;
  }

  get(key: keyof FrontendToolkitConfig): string {
    const config = this.getConfig();
    if (config[key] !== undefined && key !== "utils") {
      return config[key];
    } else {
      throw new Error(`Key ${key} is not present in the configuration.`);
    }
  }

  getAccessToken(): string | null {
    return this.getConfig().utils.getAccessToken();
  }

  getTokenPayloadData(): null | UserTokenPayload {
    if (!this.getAccessToken()) {
      return null;
    }
    // eslint-disable-next-line prefer-destructuring
    const b64 = this.getAccessToken()!.split(".")[1];
    return JSON.parse(atob(b64));
  }
}

export const coreConfig = Config.getInstance();

export const initializeCoreConfig = (config: FrontendToolkitConfig): void => {
  coreConfig.loadConfig(config);
};
