import { Vendor } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { BankInformation } from "./components/BankInformation/BankInformation.component";
import { ManagersContactInfo } from "./components/ContactInformation/ManagersContactInfo.component";
import { HealthLicense } from "./components/HealthLicense/HealthLicense";
import LocationCard from "./components/Location/LocationCardForm.component";
import { ProviderInformation } from "./components/ProviderInformation/ProviderInformation.component";
import { Subscription } from "./components/SubscriptionPlan/Subscription.component";
import { useVendorForm } from "./VendorForm.hook";
import { NotificationSettings } from "./components/NotificationSettings/NotificationSettings";

type VendorFormProps = {
  isEditMode?: boolean;
  isLoading?: boolean;
  vendor?: Partial<Vendor>;
  onDone: (data) => void;
  vendorErrors;
  isSubmitting?: boolean;
};

export const VendorForm: FC<VendorFormProps> = ({
  isLoading = false,
  isEditMode,
  onDone,
  vendorErrors: errorsInput,
  vendor,
  isSubmitting,
}) => {
  const { t } = useTranslation("admin");
  const {
    plans,
    location,
    isDirty,
    methods,
    setValue,
    hasMoreForPlans,
    hasMultipleBranches,
    handleSubmit,
    onSubmit,
    handleCancel,
    handleLocationChange,
    handleFetchMoreDataForPlans,
    handlePlanSearchInput,
  } = useVendorForm(vendor);

  const handleClick = data => {
    const result = onSubmit(data);
    onDone(result);
  };

  const doYouHaveVendor = isEditMode ? vendor != undefined : true;
  const isLoadingWithSearch = isLoading;

  useEffect(() => {
    if (vendor) {
      setValue("notifyByEmail", !!vendor?.notifyByEmail);
    }
  }, [setValue, vendor]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleClick)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasAddNewButton={!isEditMode && !hasMultipleBranches}
              hasFormButton={isEditMode ? isEditMode : hasMultipleBranches}
              isLoading={isLoadingWithSearch}
              TitleNewButton={t("Create And Add New Branch")}
              formButtonTitle={isEditMode ? t("Update") : t("Save")}
              onNavigation={handleCancel}
              onAddNewButton={() => handleSubmit(handleClick)}
              newButtonDisabled={!isDirty || isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <ProviderInformation
                isEditMode={isEditMode}
                isLoading={isLoadingWithSearch}
                vendor={vendor}
                doYouHaveVendor={doYouHaveVendor}
                errorsInput={errorsInput}
              />
            </Grid>
            {!vendor && (
              <Grid item xs={12}>
                <HealthLicense
                  isLoading={isLoadingWithSearch}
                  vendor={vendor}
                  doYouHaveVendor={doYouHaveVendor}
                  errorsInput={errorsInput}
                />
              </Grid>
            )}
            {!vendor && hasMultipleBranches && (
              <Grid item xs={12}>
                <WorkingHours isLoading={isLoadingWithSearch} inputName='branchWorkingHours' doYouHaveData={doYouHaveVendor} />
              </Grid>
            )}
            <ManagersContactInfo
              defaultValues={vendor}
              isLoading={isLoadingWithSearch}
              doYouHaveVendor={doYouHaveVendor}
              errorsInput={errorsInput}
            />
            <BankInformation
              defaultValues={vendor}
              isLoading={isLoadingWithSearch}
              doYouHaveVendor={doYouHaveVendor}
              errorsInput={errorsInput}
            />
            <Grid item xs={12}>
              <LocationCard
                isLoading={isLoadingWithSearch}
                location={location}
                defaultValues={vendor}
                errorsInput={errorsInput}
                doYouHaveVendor={doYouHaveVendor}
                onLocationChange={handleLocationChange}
              />
            </Grid>
            <Subscription
              plans={plans}
              defaultValues={vendor}
              isLoading={isLoadingWithSearch}
              errorsInput={errorsInput}
              doYouHaveVendor={doYouHaveVendor}
              onFetchMoreDataForPlans={handleFetchMoreDataForPlans}
              hasMoreForPlans={hasMoreForPlans}
              handlePlanSearchInput={handlePlanSearchInput}
            />

            <Grid item xs={12}>
              <NotificationSettings />
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

VendorForm.defaultProps = {
  isEditMode: undefined,
  isLoading: undefined,
  vendor: undefined,
};
