import { useTranslation } from "@toolkit/i18n";
import React, { FC, Fragment } from "react";
import { Box, CircularProgress, makeStyles, Typography, useTheme } from "../../base/mui";
import { EmptyCard } from "../Cards/EmptyCard";
import { OrdersEventsContainerProps } from "./types";
import { CustomInfiniteScroll } from "../CustomInfiniteScroll";

export const useOrdersEventsContainerStyle = makeStyles()(() => ({
  infinite: {
    overflow: "hidden !important",
  },
}));

export const OrdersEventsContainer: FC<OrdersEventsContainerProps> = ({
  loading,
  pageInfo,
  ordersData,
  fetchMoreData: handleFetchMoreData,
  renderItem,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {!ordersData?.length ? (
            <Box height='100%' display='flex' alignItems='center'>
              <EmptyCard title={t("There are no orders")} message={t("There are no orders related to this e-prescription yet")} />
            </Box>
          ) : (
            <CustomInfiniteScroll
              dataLength={Number(ordersData?.length) || 0}
              onFetchMore={handleFetchMoreData}
              hasMore={Boolean(pageInfo?.hasNextPage)}
              loader={
                <Box display='flex' justifyContent='center' color={theme.palette.primary.main} m={1}>
                  <CircularProgress size={50} color='inherit' />
                </Box>
              }
              // TODO Fix
              // classes={{
              //   infinite: classes.infinite,
              // }}
            >
              {ordersData?.map((item, index) => {
                return (
                  <Fragment key={String(item?.id) + index}>
                    <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
                      {t("Order No") + ": - "}
                      {item?.number}
                    </Typography>

                    {renderItem({ item })}
                  </Fragment>
                );
              })}
            </CustomInfiniteScroll>
          )}
        </>
      )}
    </>
  );
};
