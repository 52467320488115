import React, { FC } from "react";
import { MainListActions, PageWrapper } from "../PageWrapper";
import { FilterGrid } from "./FilterGrid";
import { TableGrid } from "./TableGrid";
import { TableSettingComponent } from "./TableSetting";

type IPageWrapperWithGrid = {
  hasTableSetting?: boolean;
  onAddNew?: () => void;
};

export const PageWrapperWithGrid: FC<IPageWrapperWithGrid> = props => {
  const { hasTableSetting, onAddNew } = props;
  const handleAddNew = () => {
    onAddNew?.();
  };

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          {hasTableSetting && <TableSettingComponent />}
          <MainListActions hasAddNew onAddNewItem={handleAddNew} />
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};

PageWrapperWithGrid.defaultProps = {
  hasTableSetting: false,
  onAddNew: undefined,
};
