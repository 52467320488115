import { Box, styled } from "../../base/mui";
import { MuiCloudUploadOutlinedIcon } from "../../base/mui-icons";

export const FileUploaderRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  borderColor: theme.palette.stale.main,
  borderRadius: 8,
}));

export const FileUploaderLabel = styled("label")(() => ({
  textAlign: "center",
  width: "calc(100% - 16px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const FileUploaderProgressContainer = styled(Box)(() => ({
  overflow: "auto",
  height: "100%",
  width: "100%",
}));

export const FileInput = styled("input")(() => ({
  display: "none",
}));

export const FileUploadIcon = styled(MuiCloudUploadOutlinedIcon)(() => ({
  width: 64,
  height: 64,
}));
