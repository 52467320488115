import React from "react";
import { useTheme } from "../../base/mui";
export const UploadCloudIcon = () => {
  const { palette } = useTheme();

  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='119.022' height='89.292' viewBox='0 0 119.022 89.292'>
        <g id='icons8-upload-to-cloud_1_' data-name='icons8-upload-to-cloud (1)' transform='translate(0 -7)'>
          <path
            id='Path_3447'
            data-name='Path 3447'
            d='M119.022,54.113A29.747,29.747,0,0,1,89.267,83.869H23.556A23.562,23.562,0,0,1,18.473,37.3,39.688,39.688,0,0,1,89.862,24.382,29.716,29.716,0,0,1,119.022,54.113Z'
            transform='translate(0 0)'
            fill={palette.gray.light}
          />
          <path
            id='Path_3448'
            data-name='Path 3448'
            d='M58.237,44.35A4.85,4.85,0,0,1,53.7,47.3H46.88V64.633H27.043V47.3H20.224a4.965,4.965,0,0,1-3.695-8.282l14.9-16.564a7.455,7.455,0,0,1,11.059,0l14.9,16.564A4.861,4.861,0,0,1,58.237,44.35Z'
            transform='translate(22.55 19.235)'
            opacity='0.05'
          />
          <path
            id='Path_3449'
            data-name='Path 3449'
            d='M56.351,43.114a3.637,3.637,0,0,1-3.4,2.207H44.895V63.894H27.538V45.321H19.479a3.723,3.723,0,0,1-2.777-6.2L31.6,22.558a6.2,6.2,0,0,1,9.224,0l14.9,16.564A3.635,3.635,0,0,1,56.351,43.114Z'
            transform='translate(23.295 19.975)'
            opacity='0.07'
          />
          <path
            id='Path_3450'
            data-name='Path 3450'
            d='M18.726,43.342h9.311V73.1a2.481,2.481,0,0,0,2.48,2.48h9.919a2.481,2.481,0,0,0,2.48-2.48V43.342h9.311A2.479,2.479,0,0,0,54.069,39.2L39.163,22.642a4.958,4.958,0,0,0-7.372,0L16.886,39.2A2.479,2.479,0,0,0,18.726,43.342Z'
            transform='translate(24.035 20.714)'
            fill={palette.primary.main}
          />
        </g>
      </svg>
    </>
  );
};
