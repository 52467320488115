/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPromotionQueryVariables = Types.Exact<{
  adminPromotionId: Types.Scalars['ID'];
}>;


export type AdminPromotionQuery = { __typename?: 'Query', adminPromotion?: { __typename?: 'AdminPromotion', content?: string | null, contentAr?: string | null, description?: string | null, descriptionAr?: string | null, endDate?: any | null, id: string, isPublished: boolean, name?: string | null, nameAr?: string | null, photo?: string | null, startDate?: any | null } | null };


export const AdminPromotionDocument = gql`
    query AdminPromotion($adminPromotionId: ID!) {
  adminPromotion(id: $adminPromotionId) {
    content
    contentAr
    description
    descriptionAr
    endDate
    id
    isPublished
    name
    nameAr
    photo
    startDate
  }
}
    `;

/**
 * __useAdminPromotionQuery__
 *
 * To run a query within a React component, call `useAdminPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPromotionQuery({
 *   variables: {
 *      adminPromotionId: // value for 'adminPromotionId'
 *   },
 * });
 */
export function useAdminPromotionQuery(baseOptions: Apollo.QueryHookOptions<AdminPromotionQuery, AdminPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminPromotionQuery, AdminPromotionQueryVariables>(AdminPromotionDocument, options);
      }
export function useAdminPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPromotionQuery, AdminPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminPromotionQuery, AdminPromotionQueryVariables>(AdminPromotionDocument, options);
        }
export type AdminPromotionQueryHookResult = ReturnType<typeof useAdminPromotionQuery>;
export type AdminPromotionLazyQueryHookResult = ReturnType<typeof useAdminPromotionLazyQuery>;
export type AdminPromotionQueryResult = Apollo.QueryResult<AdminPromotionQuery, AdminPromotionQueryVariables>;