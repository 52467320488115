import { BranchInput } from "@health/queries/types";
import { removeEmptyRanges } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const useBranchFormHook = ({ branch, handleOnSubmit }) => {
  const navigate = useNavigate();

  const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>({
    lat: 24.4961134,
    lng: 54.3798649,
  });

  useEffect(() => {
    branch?.address?.coordinates && setLocation(branch?.address?.coordinates);
  }, [branch]);

  const handleLocation = (location: { lat: number; lng: number }) => {
    setLocation(location);
  };

  const methods = useForm<BranchInput>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors: errorsForm },
  } = methods;

  const onSubmit = (data: any) => {
    const { healthLicenseStartDate, healthLicenseEndDate, addressInput, arabicStreetAddress2, arabicStreetAddress, ...rest } = data;
    const request = {
      ...rest,
      healthLicenseStartDate: typeof healthLicenseStartDate == "string" ? healthLicenseStartDate : healthLicenseStartDate?.toISOString(),
      healthLicenseEndDate: typeof healthLicenseEndDate == "string" ? healthLicenseEndDate : healthLicenseEndDate?.toISOString(),
      type: data?.type?.value,
      addressInput: {
        ...addressInput,
        city: addressInput?.city?.value?.id,
        coordinates: {
          lat: location?.lat as number,
          lng: location?.lng as number,
        },
        streetAddress2Ar: arabicStreetAddress2,
        areaAr: addressInput.areaAr,
        streetAddress1Ar: arabicStreetAddress,
        streetAddress1: addressInput.streetAddress1,
        area: addressInput.area,
        streetAddress2: addressInput.streetAddress2,
      },
      workingHours: removeEmptyRanges(data?.workingHours),
    };

    handleOnSubmit(request);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return {
    methods,
    location,
    errorsForm,
    handleSubmit,
    onSubmit,
    handleCancel,
    handleLocation,
  };
};
