import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Divider, Grid, MDTextEditor, TextField } from "@toolkit/ui";
import { ProductsQueryNode } from "pages/MarketplaceProducts/types";
import { FC } from "react";
import { ProductImages } from "./productsImages/ProductImages";
export const ProductDetails: FC<ProductsQueryNode> = props => {
  const { t } = useTranslation("admin");
  const emptyMessage = t("");
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label={t("Product Name")} value={props.name || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={t("Product Arabic Name")} value={props.nameAr || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("Brand Name")}
              value={pickLocalizedValue(props?.brand?.name!, props?.brand?.nameAr!) || emptyMessage}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("Category Name")}
              value={pickLocalizedValue(props?.category?.name!, props?.category?.nameAr!) || emptyMessage}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={t("Short Discretion")} value={props.shortDescription || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={t("Arabic Short Discretion")} value={props.shortDescriptionAr || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={t("Price")} value={props?.price || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={t("SKU")} value={props?.sku || emptyMessage} disabled />
          </Grid>
          <Grid item xs={6}>
            <MDTextEditor label={t("Description")} value={props?.description! || emptyMessage} readonly />
          </Grid>
          <Grid item xs={6} mb={2}>
            <MDTextEditor label={t("Arabic Description")} value={props?.descriptionAr! || emptyMessage} readonly />
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      <ProductImages mainImageUrl={props.mainImageUrl} subImages={props.subImages} />
    </>
  );
};
