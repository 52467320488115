import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Grid, MainListActions, PageWrapper, StaticFilterDialog, TableBodyWithoutDataView } from "@toolkit/ui";
import React from "react";
import { SurveyCard } from "./SurveyCard";
import SurveyCardLoader from "./SurveyCard/SurveyCardLoader";
import { useFiltersFields } from "./SurveyFilters";
import { useSurveysListPage } from "./useSurveysListPage";

/**
 *
 * @returns renders the list of all surveys, each in its own card
 */
export const SurveysListPage: React.FC = () => {
  const { isLoading, surveys, onAddNewClick, onDetailsClick, fetchMoreSurveys, hasMore, staticFiltersProps } = useSurveysListPage();
  const { t } = useTranslation("admin");

  return (
    <PageWrapper
      actions={
        <>
          <MainListActions hasAddNew={hasPermission(PermissionEnum.ManageSurveys)} onAddNewItem={onAddNewClick} />
        </>
      }
      filters={
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <StaticFilterDialog filterFields={useFiltersFields()} {...staticFiltersProps} />
        </Box>
      }
    >
      <>
        {isLoading ? (
          <SurveyCardLoader />
        ) : (
          <>
            <Grid container spacing={2}>
              {surveys?.map(item => (
                <Grid key={item!.id} item xs={12} sm={6} lg={4} xl={3}>
                  <SurveyCard survey={item!} onDetailsClick={onDetailsClick} />
                </Grid>
              ))}
            </Grid>
            {hasMore && (
              <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 2 }}>
                <Button onClick={fetchMoreSurveys} sx={{ paddingInline: "30px" }}>
                  {t("See More")}
                </Button>
              </Grid>
            )}
          </>
        )}
        {!isLoading && surveys.length === 0 ? <TableBodyWithoutDataView isFilterApplied /> : null}
      </>
    </PageWrapper>
  );
};

export default SurveysListPage;
