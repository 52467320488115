import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ReviewCodeSystemDocument = gql `
    mutation reviewCodeSystem($codeSystemId: ID!, $processingStatus: ProcessingStatus!) {
  reviewCodeSystem(
    codeSystemId: $codeSystemId
    processingStatus: $processingStatus
  ) {
    id
    display
    isActive
    version
    processingStatus
  }
}
    `;
/**
 * __useReviewCodeSystemMutation__
 *
 * To run a mutation, you first call `useReviewCodeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewCodeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewCodeSystemMutation, { data, loading, error }] = useReviewCodeSystemMutation({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *      processingStatus: // value for 'processingStatus'
 *   },
 * });
 */
export function useReviewCodeSystemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReviewCodeSystemDocument, options);
}
