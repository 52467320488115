import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const sadaVideoAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    title: z.string(),
}));
export const SadaVideoAutocompleteSchemas = {
    sadaVideoAutocomplete,
    sadaVideoAutocompleteOptional: sadaVideoAutocomplete.nullable().optional(),
};
