import { SimplePaletteColorOptions } from "@mui/material/styles";

export const lochmara: SimplePaletteColorOptions = {
  // Lochmara Blue
  50: "#e6f5fe",
  100: "#ccecfe",
  200: "#b3e2fd",
  300: "#9ad8fc",
  400: "#81cffc",
  500: "#67c5fb",
  600: "#4ebbfa",
  700: "#1ca8f9",
  800: "#069df4",
  900: "#068ddb",
  main: "#057CC1",
  1100: "#0571B0",
  1200: "#04669F",
  1300: "#045B8D",
  1400: "#034F7B",
  1500: "#03446A",
  1600: "#023958",
  1700: "#022D47",
  1800: "#012235",
  1900: "#011723",
};
