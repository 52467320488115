import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const FlowerIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='139' height='127.483' viewBox='0 0 139 127.483' {...props}>
      <g id='icons8-lotus' transform='translate(-11.5 -30.8)'>
        <path
          id='Path_3770'
          d='M65.766,147.9h0a74.989,74.989,0,0,0,40.906-97.7h0A74.989,74.989,0,0,0,65.766,147.9Z'
          transform='translate(15.764 6.282)'
          fill='#adf9d2'
        />
        <path
          id='Path_3771'
          d='M93.56,126.777a75.15,75.15,0,0,0,18.4-55.335c-2.118.53-4.236,1.191-6.222,1.853a3.945,3.945,0,0,1-5.163-3.045A100.1,100.1,0,0,0,96.34,54.1a74.835,74.835,0,0,0-36.008,60.5h0c0,1.324-.132,2.515-.132,3.839v.265a34.686,34.686,0,0,0,.132,3.707v.132a54.109,54.109,0,0,0,.927,7.943v.265a31.521,31.521,0,0,0,.662,3.442c.132.4.132.662.265,1.059.265.927.4,1.853.662,2.78l.4,1.191c.265.927.53,1.721.794,2.648a9.119,9.119,0,0,0,.4,1.059c.4,1.191.927,2.383,1.324,3.707h0l3.574-1.589c.265-.132.662-.265.927-.4,1.059-.53,2.118-1.059,3.045-1.589.132-.132.265-.132.4-.265,1.191-.662,2.25-1.324,3.442-1.986a2.769,2.769,0,0,0,.794-.53c.927-.662,1.853-1.324,2.912-1.986.132,0,.132-.132.265-.132A60.063,60.063,0,0,0,87.47,133l.132-.132a64.305,64.305,0,0,0,5.957-6.09Z'
          transform='translate(15.77 7.545)'
          fill='#6dc289'
        />
        <path
          id='Path_3772'
          d='M66.755,152.845a3.988,3.988,0,0,1-3.707-2.515,78.83,78.83,0,0,1,43.024-102.86,4,4,0,0,1,5.163,2.118,78.835,78.835,0,0,1-.132,60.366,77.838,77.838,0,0,1-42.759,42.494A3.525,3.525,0,0,1,66.755,152.845Zm38.655-96.373a71.094,71.094,0,0,0-36.4,87.107,71.026,71.026,0,0,0,34.816-36.537A70.173,70.173,0,0,0,105.411,56.471Z'
          transform='translate(14.774 5.306)'
          fill='#444b54'
        />
        <path
          id='Path_3773'
          d='M76.057,147.9h0A74.816,74.816,0,0,1,35.284,50.2h0A74.816,74.816,0,0,1,76.057,147.9Z'
          transform='translate(5.869 6.282)'
          fill='#adf9d2'
        />
        <path
          id='Path_3774'
          d='M42.645,53.8A96.671,96.671,0,0,0,44.5,125.021a73.959,73.959,0,0,0,29.389,21.71h0A75.033,75.033,0,0,0,42.645,53.8Z'
          transform='translate(8.172 7.448)'
          fill='#fff'
        />
        <path
          id='Path_3775'
          d='M76.482,152.816a4.759,4.759,0,0,1-1.456-.265,3.889,3.889,0,0,1-2.118-5.163,70.759,70.759,0,0,0-.132-54.276A70.036,70.036,0,0,0,37.959,56.575a65.667,65.667,0,0,0-3.442,15.091,3.933,3.933,0,0,1-7.81-.927,78.272,78.272,0,0,1,5.428-21.049,4.134,4.134,0,0,1,2.118-2.118,4.039,4.039,0,0,1,3.045,0A78.257,78.257,0,0,1,80.057,90.067a78.239,78.239,0,0,1,.132,60.366A4.074,4.074,0,0,1,76.482,152.816Z'
          transform='translate(4.915 5.335)'
          fill='#444b54'
        />
        <path
          id='Path_3776'
          d='M64,140.029h0A65.593,65.593,0,0,0,129.529,74.5h0a66.215,66.215,0,0,0-25.55,5.163C97.625,82.31,91.8,89.989,86.637,94.49,72.737,106.536,64,120.3,64,140.029Z'
          transform='translate(17 14.15)'
          fill='#adf9d2'
        />
        <path
          id='Path_3777'
          d='M100.934,79.5A65.574,65.574,0,0,0,64,138.41h0a64.169,64.169,0,0,0,18.269-2.648A95.6,95.6,0,0,0,100.934,79.5Z'
          transform='translate(17 15.77)'
          fill='#6dc289'
        />
        <path
          id='Path_3778'
          d='M80.029,140.029h0A65.593,65.593,0,0,1,14.5,74.5h0A65.593,65.593,0,0,1,80.029,140.029Z'
          transform='translate(0.971 14.15)'
          fill='#adf9d2'
        />
        <path
          id='Path_3779'
          d='M70.047,153.143h0A84.134,84.134,0,0,1,70.047,34h0A84.134,84.134,0,0,1,70.047,153.143Z'
          transform='translate(10.953 1.036)'
          fill='#adf9d2'
        />
        <path
          id='Path_3780'
          d='M146.529,84.679a69.291,69.291,0,0,0-27.006,5.428,3.946,3.946,0,0,0,3.045,7.281,64.081,64.081,0,0,1,19.857-4.766,61.581,61.581,0,0,1-51.893,56.659,86.927,86.927,0,0,0,19.063-55.6A87.872,87.872,0,0,0,83.78,31.991a3.838,3.838,0,0,0-5.56,0,88.828,88.828,0,0,0-14.3,106.3,3.974,3.974,0,1,0,6.884-3.971A80.531,80.531,0,0,1,81,40.729a79.5,79.5,0,0,1,20.651,53.217,80.271,80.271,0,0,1-22.372,56.262A61.733,61.733,0,0,1,19.443,92.754a61.376,61.376,0,0,1,19.328,4.5,3.946,3.946,0,1,0,3.045-7.281,68.7,68.7,0,0,0-26.344-5.163A3.9,3.9,0,0,0,11.5,88.783a69.5,69.5,0,0,0,139,0A3.933,3.933,0,0,0,146.529,84.679Z'
          transform='translate(0 0)'
          fill='#444b54'
        />
        <path
          id='Path_3781'
          d='M70.764,123.918a3.039,3.039,0,0,1-1.853-.53,3.947,3.947,0,0,1-1.589-5.428,72.568,72.568,0,0,0,6.884-49.378,4,4,0,1,1,7.81-1.721,80.639,80.639,0,0,1-7.678,54.673A3.742,3.742,0,0,1,70.764,123.918Z'
          transform='translate(17.914 10.669)'
          fill='#6dc289'
        />
      </g>
    </SvgIcon>
  );
};
