import { SimplePaletteColorOptions } from "@mui/material/styles";

export const tradewind: SimplePaletteColorOptions = {
  // tradewind Blue
  main: "#58acad",
  50: "#DDEEEF",
  100: "#eef7f7",
  200: "#ECF8F8",
  300: "#cde6e6",
  400: "#bcdede",
  500: "#acd6d6",
  600: "#9bcdce",
  700: "#8ac5c6",
  800: "#79bdbd",
  900: "#69b4b5",
  1100: "#4f9b9c",
  1200: "#468a8a",
  1300: "#3e7879",
  1400: "#356768",
  1500: "#2c5657",
  1600: "#214545",
  1700: "#1a3434",
  1800: "#122223",
  1900: "#091111",
};
