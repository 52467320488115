import { isEmpty, isNull, isObject, isString, isUndefined, omitBy } from "lodash-es";

type InputCleanUpOptionsType =
  | "shouldRemoveUndefined"
  | "shouldRemoveNull"
  | "shouldRemoveEmptyString"
  | "shouldRemoveEmptyArray"
  | "shouldRemoveEmptyObject";

// TODO Fix generic T
type InputCleanUpType = <T>(input: T | unknown, options?: Partial<Record<InputCleanUpOptionsType, boolean>>) => T | unknown;

const omitByCallback = (
  value: any,
  options: {
    shouldRemoveEmptyString?: boolean;
    shouldRemoveEmptyArray?: boolean;
    shouldRemoveEmptyObject?: boolean;
    shouldRemoveNull?: boolean;
    shouldRemoveUndefined?: boolean;
  }
) => {
  const shouldRemoveEmptyString = options.shouldRemoveEmptyString && isString(value) && isEmpty(value);
  const shouldRemoveEmptyArray = options.shouldRemoveEmptyArray && Array.isArray(value) && isEmpty(value);
  const shouldRemoveEmptyObject = options.shouldRemoveEmptyObject && isObject(value) && isEmpty(value);
  const shouldRemoveNull = options.shouldRemoveNull && isNull(value);
  const shouldRemoveUndefined = options.shouldRemoveUndefined && isUndefined(value);

  // TODO Remove from nested object
  // if (isObject(value) && !isEmpty(value)) {
  //   omitByCallback(value, options);
  // }

  return shouldRemoveEmptyString || shouldRemoveEmptyArray || shouldRemoveEmptyObject || shouldRemoveNull || shouldRemoveUndefined;
};
/*
 * Remove all null, undefined, empty string and empty array values from an object
 */
export const inputCleanUp: InputCleanUpType = (
  input,
  options = {
    shouldRemoveUndefined: true,
    shouldRemoveNull: true,
    shouldRemoveEmptyString: false,
    shouldRemoveEmptyArray: false,
    shouldRemoveEmptyObject: false,
  }
) => {
  if (isObject(input)) {
    return omitBy(input, value => omitByCallback(value, options));
  } else {
    console.error("@health/smart requestCleanUp, Input is not supported.");
    return input;
  }
};
