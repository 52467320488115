import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ImportTerminologyDataToSubListDocument = gql `
    mutation importTerminologyDataToSubList($codeSystemId: ID!, $searchParameters: [SearchParameter]!, $subListId: ID!) {
  fetchTerminologyListData(
    codeSystemId: $codeSystemId
    searchParameters: $searchParameters
    subListId: $subListId
  ) {
    display
    id
    errors {
      message
      field
    }
  }
}
    `;
/**
 * __useImportTerminologyDataToSubListMutation__
 *
 * To run a mutation, you first call `useImportTerminologyDataToSubListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTerminologyDataToSubListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTerminologyDataToSubListMutation, { data, loading, error }] = useImportTerminologyDataToSubListMutation({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *      searchParameters: // value for 'searchParameters'
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useImportTerminologyDataToSubListMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ImportTerminologyDataToSubListDocument, options);
}
