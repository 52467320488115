import { combineErrors, getMediaLink, getRequiredValidation, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formButtonTitle } from "shared/utils";
import { SupplementTypeTrackerProps } from "./types";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { supplementTypesPaths } from "../../constants/supplement-types-paths";

export const SupplementTypeTrackerForm: FC<SupplementTypeTrackerProps> = props => {
  const { defaultValue, isLoading, onSubmit, isSubmitting } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { accessToken } = useOidcUserProfile();

  const { ...methods } = useForm();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors: reactFormErrors },
  } = methods;

  const formErrors = combineErrors(reactFormErrors, []);

  const handleCancel = () => {
    navigate(supplementTypesPaths.listPath.fullPath);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <FormCard loading={isLoading} doYouHaveData title={t("General Information")}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  placeholder={t("Name")}
                  label={t("Name")}
                  defaultValue={defaultValue?.name}
                  error={Boolean(formErrors?.name?.message)}
                  helperText={t(formErrors?.name?.message!)}
                  {...register("name", {
                    required: getRequiredValidation(t, true),
                    validate: value => validateValueIsNotEmpty(value, t),
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUploadController
                  control={control}
                  name='photo'
                  defaultValue={defaultValue?.photo && getMediaLink(defaultValue?.photo)}
                  validationRation={true}
                  error={Boolean(formErrors?.photo?.message)}
                  helperText={formErrors?.photo?.message}
                  token={accessToken}
                  rules={{
                    required: getRequiredValidation(t, true),
                  }}
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
