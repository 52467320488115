import { IBenefitGroups, IHealthProgramInput } from "../types";

export const allowedSubscriptionPeriodsInput = (input: IHealthProgramInput) => input?.subscriptionPeriod?.value;

export const programTrackingInputVariables = (data: IHealthProgramInput) => {
  return {
    pointsDuringNormalDemand: Number(data?.pointsDuringNormalDemand),
    pointsDuringUrgentDemand: Number(data?.pointsDuringUrgentDemand),
    baselineAvailabilityHours: Number(data?.baselineAvailabilityHours),
    unattendedCallPenaltyFactor: Number(data?.unattendedCallPenaltyFactor),
    mainSpecializationsRateFactors: JSON.stringify({
      GP: Number(data?.mainSpecializationsRateFactors?.gp),
      SP: Number(data?.mainSpecializationsRateFactors?.sp),
      CP: Number(data?.mainSpecializationsRateFactors?.cp),
    }),
    callQualityRatingFactors: JSON.stringify({
      "1": Number(data?.callQualityRatingFactors?.star1),
      "2": Number(data?.callQualityRatingFactors?.star2),
      "3": Number(data?.callQualityRatingFactors?.star3),
      "4": Number(data?.callQualityRatingFactors?.star4),
      "5": Number(data?.callQualityRatingFactors?.star5),
    }),
  };
};

export const definitionInputVariables = (input: IHealthProgramInput | any, copayCheck?: string) => {
  return {
    operatorProfitSharePercentage: input?.operatorProfitSharePercentage ? Number(input?.operatorProfitSharePercentage) : null,
    vendorProfitSharePercentage: input?.vendorProfitSharePercentage ? Number(input?.vendorProfitSharePercentage) : null,
    platformProfitSharePercentage: input?.platformProfitSharePercentage ? Number(input?.platformProfitSharePercentage) : null,
    careFor: input?.careFor?.map(el => el.value),
    subscriptionPeriod: input?.subscriptionPeriod?.value,
    images: input.images,
    copay: input?.copay,
    priceAmount: Number(input?.priceAmount),
    copayAmount: input?.copay && copayCheck === "copayAmount" ? Number(input?.copayAmount) : null,
    copayPercentage: input?.copay && copayCheck !== "copayAmount" ? Number(input?.copayPercentage) : null,
    specializations: input?.specializations?.map(el => el?.value?.code),
    insuranceNetworks: input?.insuranceNetworks?.map(el => el?.id),
    healthProgramMemberLists: input?.healthProgramMemberLists?.map(item => item?.id),
    healthProgramNetworks: input?.healthProgramNetworks?.map(item => item?.id),
  };
};

export const getProgramTrackingRequestInputs = (inputData: IHealthProgramInput, benefitGroups: IBenefitGroups) => {
  return {
    subscriptionPeriod: allowedSubscriptionPeriodsInput(inputData),
    benefitGroups,
    ...programTrackingInputVariables(inputData),
  };
};

export const getDefinitionRequestInputs = (input: IHealthProgramInput, benefitGroups) => {
  const { copayCheck, ...rest } = input;
  return {
    ...rest,
    benefitGroups,
    ...definitionInputVariables(rest, copayCheck),
    ...programTrackingInputVariables(rest),
  };
};
