import { ShowButton } from "@toolkit/ui";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface UsersListProps {
  id: string;
  vendorId: string;
  disabled: boolean;
}

export const UsersList: FC<UsersListProps> = ({ id, vendorId, disabled }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(providersManagementPaths.branchesUsersPath.fullPathWithParams(vendorId, id));
  };

  return <ShowButton disabled={disabled} onClick={handleClick} />;
};
