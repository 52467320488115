import { Doctor } from "@health/queries/types";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { VirtualProgramView } from "./VirtualProgramView";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<Doctor>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useHealthProgramNetworkProviderProfessionalsColumns = (): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "Name",
        header: t("Name"),
        accessor: ({ user }) => formatUserNameFull(user!),
        type: "string",
      },
      {
        key: "Speciality",
        header: t("Specialty"),
        accessor: ({ specializations }) => specializations?.map(item => item?.code!).join(", "),
        type: "string",
      },
      {
        key: "Virtual IDs",
        header: t("Virtual IDs"),
        accessor: "id",
        type: "string",
      },

      {
        key: "overridingVirtualCallEnabled",
        header: t("Virtual Program"),
        type: "string",
        accessor: row => {
          return <VirtualProgramView row={row!} />;
        },
      },
    ];
  }, []);
};
