interface FrontendToolkitConfig {
  googleApiKey: string;
}

class Config {
  private static instance: Config;
  private config: FrontendToolkitConfig | null = null;

  private constructor() {}

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: FrontendToolkitConfig): void {
    this.config = config;
  }

  getConfig(): FrontendToolkitConfig {
    if (!this.config) {
      throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
    }
    return this.config;
  }

  get(key: keyof FrontendToolkitConfig): string {
    const config = this.getConfig();
    if (config[key] !== undefined) {
      return config[key];
    } else {
      throw new Error(`Key ${key} is not present in the configuration.`);
    }
  }
}

export const mapConfig = Config.getInstance();

export const initializeMapConfig = (config: FrontendToolkitConfig): void => {
  mapConfig.loadConfig(config);
};
