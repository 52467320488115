import { createPathDefinition } from "@toolkit/core";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const promotionsRoute = `${marketplaceRoute}/promotions`;

export const promotionsPaths = createPathDefinition(promotionsRoute, {
  main: "",
  new: "new",
  update: ":promotionId",
});
