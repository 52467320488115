import { makeStyles } from "@toolkit/ui";

export const useInsuranceFormStyles = makeStyles()(theme => ({
  createButton: {
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    marginRight: theme.spacing(2),
    "&:hover": {
      background: theme.palette.success.light,
    },
  },
  cancelButton: {
    background: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  container: {
    paddingBottom: "20px",
  },
}));
