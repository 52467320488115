export const vendorPermissionsManagementRoute = "/vendor-permissions-management";

export const vendorPermissionsManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${vendorPermissionsManagementRoute}${this.route}`;
    },
  },
};
