/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBranchMutationVariables = Types.Exact<{
  branch: Types.BranchInput;
  vendorId: Types.Scalars['ID'];
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', branchCreate?: { __typename?: 'BranchCreate', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, branch?: { __typename?: 'Branch', id: string, name: string, isOpen?: boolean | null } | null } | null };


export const CreateBranchDocument = gql`
    mutation CreateBranch($branch: BranchInput!, $vendorId: ID!) {
  branchCreate(input: $branch, vendorId: $vendorId) {
    branchErrors {
      field
      message
      code
    }
    branch {
      id
      name
      isOpen
    }
  }
}
    `;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;