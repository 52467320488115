import { combineErrors, getPositiveNumber, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, HintComponent, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "../types";

export const CallQualityFactor: FC<IProgramProps> = ({ errors, defaultValues, readOnly }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  const {
    "1": rating1,
    "2": rating2,
    "3": rating3,
    "4": rating4,
    "5": rating5,
  } = (defaultValues?.callQualityRatingFactors && JSON.parse(defaultValues?.callQualityRatingFactors)) || {};
  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("1", { defaultValue: "number1" })}
          error={Boolean(formErrors.callQualityRatingFactors?.star1?.message)}
          helperText={t(formErrors.callQualityRatingFactors?.star1?.message)}
          {...register("callQualityRatingFactors.star1", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={rating1 || 0.4}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 0 to 1/5"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("2")}
          error={Boolean(formErrors.callQualityRatingFactors?.star2?.message)}
          helperText={t(formErrors.callQualityRatingFactors?.star2?.message)}
          {...register("callQualityRatingFactors.star2", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={rating2 || 0.6}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 1 to 2/5"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("3")}
          error={Boolean(formErrors.callQualityRatingFactors?.star3?.message)}
          helperText={t(formErrors.callQualityRatingFactors?.star3?.message)}
          {...register("callQualityRatingFactors.star3", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={rating3 || 0.8}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 2 to 3/5"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          size='small'
          type='number'
          label={t("4")}
          error={Boolean(formErrors.callQualityRatingFactors?.star4?.message)}
          helperText={t(formErrors.callQualityRatingFactors?.star4?.message)}
          {...register("callQualityRatingFactors.star4", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={rating4 || 1}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 3 to 4/5"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("5")}
          error={Boolean(formErrors.callQualityRatingFactors?.star5?.message)}
          helperText={t(formErrors.callQualityRatingFactors?.star5?.message)}
          {...register("callQualityRatingFactors.star5", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={rating5 || 1.2}
          InputProps={{
            readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 4 to 5/5"
                )}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
