/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import React, { FC, Fragment } from "react";
import { Box, BoxProps, Typography, useTheme } from "../../../base/mui";
import { EmptyDataIcon } from "../../../icons";

type TableBodyWithoutDataViewProps = {
  withoutDataMessage?: JSX.Element | string;
  isFilterApplied?: boolean;
  emptyIconHeight?: number | string;
  iconBoxProps?: BoxProps;
  // eslint-disable-next-line react/require-default-props
  isScrollableTable?: boolean;
};

export const TableBodyWithoutDataView: FC<TableBodyWithoutDataViewProps> = ({
  withoutDataMessage,
  isFilterApplied = false,
  emptyIconHeight = 140.68,
  iconBoxProps,
  isScrollableTable,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        textAlign: "center",
        width: 250,
        display: "block",
        left: "50%",
        top: isScrollableTable ? "65%" : "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <Box m={isScrollableTable ? 0 : "15px"} {...iconBoxProps}>
        <EmptyDataIcon sx={{ width: emptyIconHeight }} height={isScrollableTable ? 100 : emptyIconHeight} />
      </Box>
      {withoutDataMessage ? (
        <Typography
          sx={{ minWidth: "max-content" }}
          fontSize={theme.mixins.fonts.fontSize.md}
          color={theme.palette.primary.main}
          fontWeight={theme.mixins.fonts.fontWeight.medium}
        >
          {withoutDataMessage}
        </Typography>
      ) : (
        <Fragment>
          <Typography
            fontSize={theme.mixins.fonts.fontSize.md}
            color={theme.palette.primary.main}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
          >
            {isFilterApplied ? t("No Items Found") : t("No Items Added")}
          </Typography>
          <Typography fontSize={theme.mixins.fonts.fontSize.md} color={theme.palette.stale.main}>
            {isFilterApplied ? null : t("Start by adding data now")}
          </Typography>
        </Fragment>
      )}
    </Box>
  );
};
TableBodyWithoutDataView.defaultProps = {
  withoutDataMessage: undefined,
  isFilterApplied: false,
  emptyIconHeight: 140.68,
  iconBoxProps: {},
};
