import { IOptimaActionListContainerEvent, OptimaActionListContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { optimaActionsPaths } from "../../constants";

export const AdminOptimaActionListContainer = () => {
  const navigate = useNavigate();

  const onOptimaActionListContainerChange = (event: IOptimaActionListContainerEvent) => {
    if (event.type === "ADD_CLICK") {
      navigate(optimaActionsPaths.create.fullPath);
    } else if (event.type === "UPDATE_CLICK") {
      navigate(optimaActionsPaths.update.fullPathWithParams({ actionId: event.payload.item?.id }));
    }
  };

  return <OptimaActionListContainer onChange={onOptimaActionListContainerChange} />;
};
