import { gql } from '@apollo/client';
export const ConditionFragmentDoc = gql `
    fragment Condition on Condition {
  id
  conditionOperation
  entityType
  isGroup
  operator
  value {
    extra
    valueDate
    valueList
    valueID
    valueNumber
    valueString
  }
  field {
    id
    code
    display
    operators
    allowedValues
    fieldType {
      id
      type
    }
    category {
      id
      code
      display
    }
  }
}
    `;
