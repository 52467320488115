import { i18n } from "@toolkit/i18n";
import { citiesManagementPaths } from "pages/CitiesManagement/constants/cities-management-paths";

export const citiesManagementTitleBreadcrumb = () => {
  return i18n.t("Cities Management", { ns: "admin" });
};

export const citiesManagementListBreadcrumb = () => {
  return {
    id: "cities-management-list",
    name: i18n.t("Cities", { ns: "admin" }),
    route: citiesManagementPaths.listPath.fullPath,
  };
};

export const citiesManagementNewBreadcrumb = () => {
  return {
    id: "cities-management-new",
    name: i18n.t("New", { ns: "admin" }),
  };
};

export const citiesManagementFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "cities-management-field",
    name: fieldName,
  };
};
