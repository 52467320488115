import { H_EntityError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  networkFieldBreadcrumb,
  networkListBreadcrumb,
  networkNewBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNetworkQuery, useHealthProgramNetworkProviderCreateMutation } from "../gql";
import { HealthProgramNetworkProviderForm } from "./components/HealthProgramNetworkProviderForm";

export const HealthProgramNetworkProviderNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const id = params.id!;
  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  const { data, loading } = useGetNetworkQuery({
    variables: {
      filter: {
        ids: [id],
      },
    },
  });

  const healthProgramNetworks = data?.healthProgramNetworks?.edges?.map(item => item?.node)[0];

  const [healthProgramNetworkProviderCreate, { loading: isSubmitting }] = useHealthProgramNetworkProviderCreateMutation({
    onCompleted: data => {
      const entityErrors = data?.healthProgramNetworkProviderCreate?.entityErrors!;
      if (entityErrors?.length === 0) {
        succeeded(t("Network Provider Created Successfully"));
        navigate(-1);
      } else {
        setError(entityErrors);
        failed(t(formatMessageErrors(entityErrors)));
      }
    },
    onError: () => {
      failed(t("Failed in creating network provider"));
    },
  });
  const handleSubmit = data => {
    const { providers, ...rest } = data;
    healthProgramNetworkProviderCreate({
      variables: {
        input: {
          ...rest,
          providerId: providers?.id,
          healthProgramNetwork: id,
          isActive: data?.isActive || false,
          virtualCallEnabled: data?.virtualCallEnabled || false,
        },
      },
    });
  };

  useEffect(() => {
    if (healthProgramNetworks?.name) {
      setBreadCrumb({
        title: networkTitleBreadcrumb(t),
        values: [
          networkListBreadcrumb(t),
          networkFieldBreadcrumb(healthProgramNetworks?.name, networksPaths.providersPath.fullPathWithParams(id)),
          networkNewBreadcrumb(t),
        ],
      });
    }
  }, [healthProgramNetworks?.name]);

  return <HealthProgramNetworkProviderForm isLoading={loading} errors={errors!} onDone={handleSubmit} isSubmitting={isSubmitting} />;
};
