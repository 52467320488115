import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getMaxLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, TextField } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";
export const SublistItemUpsertForm = props => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { onHandleSubmit: handleSubmit, submitButtonText = undefined, defaults = undefined, isSubmitting } = props;
    const { t } = useTranslation("domains");
    const methods = useFormContext();
    return (_jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, columnSpacing: 2, alignItems: 'center', children: [_jsx(Grid, { item: true, children: _jsx(TextField, Object.assign({ label: t("Name"), defaultValue: defaults === null || defaults === void 0 ? void 0 : defaults.display }, methods.register("display", {
                        required: getRequiredValidation(t, true),
                        maxLength: getMaxLengthValidation(t, 500),
                    }), { error: Boolean((_b = (_a = methods.formState.errors) === null || _a === void 0 ? void 0 : _a.display) === null || _b === void 0 ? void 0 : _b.message), helperText: (_d = (_c = methods.formState.errors) === null || _c === void 0 ? void 0 : _c.display) === null || _d === void 0 ? void 0 : _d.message })) }), _jsx(Grid, { item: true, children: _jsx(TextField, Object.assign({ label: t("Code"), defaultValue: defaults === null || defaults === void 0 ? void 0 : defaults.code }, methods.register("code", {
                        required: getRequiredValidation(t, true),
                        maxLength: getMaxLengthValidation(t, 500),
                    }), { error: Boolean((_f = (_e = methods.formState.errors) === null || _e === void 0 ? void 0 : _e.code) === null || _f === void 0 ? void 0 : _f.message), helperText: (_h = (_g = methods.formState.errors) === null || _g === void 0 ? void 0 : _g.code) === null || _h === void 0 ? void 0 : _h.message })) }), Boolean(submitButtonText) && (_jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleSubmit, disabled: isSubmitting, children: submitButtonText }) }))] }) }));
};
