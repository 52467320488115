/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramNetworkInput;
}>;


export type HealthProgramNetworkCreateMutation = { __typename?: 'Mutation', healthProgramNetworkCreate?: { __typename?: 'HealthProgramNetworkCRUD', entity?: { __typename?: 'HealthProgramNetwork', id: string, isActive?: boolean | null, name?: string | null, healthProgramNetworkProviders?: { __typename?: 'HealthProgramNetworkProviderCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkProviderCountableEdge', node: { __typename?: 'HealthProgramNetworkProvider', provider?: { __typename?: 'Vendor', doctors: { __typename?: 'DoctorCountableConnection', edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string } }> } } | null } }> } | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const HealthProgramNetworkCreateDocument = gql`
    mutation healthProgramNetworkCreate($input: HealthProgramNetworkInput!) {
  healthProgramNetworkCreate(input: $input) {
    entity {
      id
      isActive
      name
      healthProgramNetworkProviders(first: 10) {
        edges {
          node {
            provider {
              doctors(first: 10) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    entityErrors {
      field
      message
    }
  }
}
    `;
export type HealthProgramNetworkCreateMutationFn = Apollo.MutationFunction<HealthProgramNetworkCreateMutation, HealthProgramNetworkCreateMutationVariables>;

/**
 * __useHealthProgramNetworkCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkCreateMutation, { data, loading, error }] = useHealthProgramNetworkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramNetworkCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkCreateMutation, HealthProgramNetworkCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkCreateMutation, HealthProgramNetworkCreateMutationVariables>(HealthProgramNetworkCreateDocument, options);
      }
export type HealthProgramNetworkCreateMutationHookResult = ReturnType<typeof useHealthProgramNetworkCreateMutation>;
export type HealthProgramNetworkCreateMutationResult = Apollo.MutationResult<HealthProgramNetworkCreateMutation>;
export type HealthProgramNetworkCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkCreateMutation, HealthProgramNetworkCreateMutationVariables>;