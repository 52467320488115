import { z } from "zod";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { medicalFormClassificationUpsertFormSchema } from "../MedicalFormClassificationUpsert/MedicalFormClassificationUpsertFormSchema";
import { medicalFormQuestionUpsertFormSchema } from "../MedicalFormQuestionUpsert/MedicalFormQuestionUpsertFormSchema";
import { zodSchema } from "../../../../zod";
export const medicalFormUpsertFormSchema = z.object({
    name: z.string().min(1).max(50),
    nameAr: z.string().min(1).max(50),
    code: z.string().min(1).max(50),
    type: zodSchema.medicalFormTypeAutocompleteEnum,
    category: zodSchema.medicalFormCategoryAutocompleteEnum,
    welcomeCard: z.string().nullable().optional(),
    welcomeCardAr: z.string().nullable().optional(),
    description: z.string().min(1).max(255),
    department: createZodAutocompleteObject(z.object({ id: z.string(), name: z.string() })).optional(),
    active: z.boolean(),
    questions: z.array(medicalFormQuestionUpsertFormSchema).optional(),
    medicalFormClassifications: z.array(medicalFormClassificationUpsertFormSchema).optional(),
});
export const medicalFormUpsertFormDefaultValues = {
    name: undefined,
    nameAr: undefined,
    code: undefined,
    type: undefined,
    category: undefined,
    welcomeCard: undefined,
    welcomeCardAr: undefined,
    description: undefined,
    department: undefined,
    active: false,
    questions: [],
    medicalFormClassifications: [],
};
