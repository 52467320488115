import { H_OrderDirection, HealthProgramSortingField, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { healthProgramTitleBreadcrumb } from "pages/HealthProgram/constants/health-program-breadcrumbs";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useHealthProgramsQuery } from "../../gql";
import { useHealthProgramsColumns } from "./components/useHealthProgramsColumns";

export const HealthProgramList: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("admin");
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const handleEditRow = data => {
    navigate(healthProgramPaths.editPath.fullPathWithParams(data?.id));
  };

  const handleAddNew = () => {
    navigate(healthProgramPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({
      title: healthProgramTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='healthPrograms'
      columns={useHealthProgramsColumns(canManageActions)}
      query={useHealthProgramsQuery}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canManageActions && <MainListActions hasAddNew onAddNewItem={handleAddNew} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
