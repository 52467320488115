import { TrackerSupplementType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";

import { useMemo } from "react";

export type SupplementTypeTrackerColumnTypes = CustomTableColumnProps<TrackerSupplementType>;
export type SupplementTypeTrackerColumnsTypes = SupplementTypeTrackerColumnTypes[];

export const useSupplementTypesTrackerColumns = (): SupplementTypeTrackerColumnsTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "photo",
        type: "string",
        header: t("Photo"),
        accessor: ({ photo }) => <Avatar src={photo!} variant='square' />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => {
          return <TruncateTypography text={name!} />;
        },
        filter: {
          type: "string",
          name: "name",
        },
      },
    ];
  }, []);
};
