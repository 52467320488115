import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, TextField, useTheme } from "@toolkit/ui";
import { useSurveyDetailsContext } from "pages/Surveys/state/context";
import React from "react";
import { OptionItemIcon } from "./OptionItemIcon";
import { OptionItemProps } from "./types";

export const OptionItem: React.FC<OptionItemProps> = ({ item, checked, type, color, isEditing, onSubmit }) => {
  const { questionOptionState, updateQuestionOptionState } = useSurveyDetailsContext();
  const { t } = useTranslation("admin");

  const handleChangeOptionText = event => {
    updateQuestionOptionState({ newOption: event.target.value });
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      onSubmit?.();
    }
  };
  const theme = useTheme();
  return (
    <Grid container alignItems='center' flexWrap='nowrap'>
      <Box sx={{ width: 40, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: -1 }}>
        <OptionItemIcon type={type!} isChecked={!!checked} color={color || theme.palette.primary.main} />
      </Box>
      <Grid item xs={12} sm={8} md={4} sx={{ color: color ?? theme.palette.primary.main }}>
        {isEditing ? (
          <TextField
            fullWidth
            value={questionOptionState.newOption}
            onChange={handleChangeOptionText}
            onKeyPress={handleKeyPress}
            size='small'
            error={questionOptionState.isOptionDuplicated}
            helperText={questionOptionState.isOptionDuplicated ? t("Option Already Exist") : ""}
          />
        ) : (
          item
        )}
      </Grid>
    </Grid>
  );
};

export default OptionItem;
