import { TFunction } from "@toolkit/i18n";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";

export const membersTitleBreadcrumb = (t: TFunction) => {
  return t("Members Management", { ns: "admin" });
};
export const memberBreadcrumb = (t: TFunction) => {
  return { id: "member", name: t("Members", { ns: "admin" }) };
};

export const membersBreadcrumb = (t: TFunction) => {
  return {
    id: "members",
    name: t("Members", { ns: "admin" }),
    route: memberListPaths.listPath.fullPath,
  };
};

export const membersMemberListBreadcrumb = (t: TFunction, memberListId: string) => {
  return {
    id: "members-member-list",
    name: t("Member List", { ns: "admin" }),
    route: memberListPaths.membersPath.fullPathWithParams(memberListId),
  };
};

export const memberNewBreadcrumb = (t: TFunction) => {
  return {
    id: "member-new",
    name: t("New", { ns: "admin" }),
  };
};

export const memberFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "member-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
