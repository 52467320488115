import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const optimaActionAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    action: z.string(),
}));
export const OptimaActionsAutocompleteSchemas = {
    optimaActionAutocomplete,
    optimaActionAutocompleteOptional: optimaActionAutocomplete.nullable().optional(),
};
