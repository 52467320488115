export const streamingRoute = "/streaming";

export const streamingPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${streamingRoute}${this.route}`;
    },
  },
};
