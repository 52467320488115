import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { AutocompleteController, FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { usePermissionsGroupHook } from "./PermissionsInfo.hook";
import { FormProps } from "./UserForm.types";
export const PermissionsGroupInformation: FC<FormProps> = ({ errors, defaultValues, doYouHaveUser, isLoading }) => {
  const {
    combinedErrors,
    control,
    loadingPermission,
    onFetchMorePermissions,
    t,
    hasMorePermission,
    permissions,
    permissionDefaultValue,
    handleSearch,
  } = usePermissionsGroupHook(errors, defaultValues);
  return (
    <FormCard doYouHaveData={doYouHaveUser} loading={isLoading} title={t("Permission information")}>
      <Grid container spacing={formGirdSpacing}>
        <Grid item {...formGirdBreakPoints}>
          <AutocompleteController
            ControllerProps={{
              name: "addGroups",
              control: control,
              rules: { required: false },
              defaultValue: permissionDefaultValue || [],
            }}
            multiple
            loading={loadingPermission}
            fetchMoreData={onFetchMorePermissions}
            hasMore={hasMorePermission}
            TextFieldProps={{
              error: Boolean(combinedErrors?.addGroups?.message),
              helperText: combinedErrors?.addGroups?.message,
              label: t("Permissions Group"),
            }}
            getOptionLabel={o => o?.name}
            options={permissions || []}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onSearchInput={handleSearch}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
