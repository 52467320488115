import { makeStyles } from "tss-react/mui";
import { IconProps } from "./IconProps";

export const useStyles = makeStyles<IconProps>()((theme, props) => ({
  root: {
    height: props.height,
    width: props.width,
    color: props.color || theme.palette.primary.main,
    transform: props.transform,
    ":hover": {
      backgroundColor: "initial",
    },
    margin: props.margin,
  },
}));
