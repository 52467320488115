export const prescriptionsTrackingOrdersRoute = "/prescriptions-orders-tracking";

export const prescriptionsOrdersTrackingPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${prescriptionsTrackingOrdersRoute}${this.route}`;
    },
  },
};
