import { useGetFeatureFlagQuery } from "./gql/queries";
export var FeatureEnum;
(function (FeatureEnum) {
    FeatureEnum["HealthPackage"] = "HealthPackage";
    FeatureEnum["Marketplace"] = "Marketplace";
})(FeatureEnum || (FeatureEnum = {}));
const FeatureEnumMap = {
    [FeatureEnum.HealthPackage]: "healthPackageFeatureEnabled",
    [FeatureEnum.Marketplace]: "marketplaceFeatureEnabled",
};
export const useFeatureFlag = () => {
    const { data, loading } = useGetFeatureFlagQuery();
    const featureFlag = data === null || data === void 0 ? void 0 : data.featureFlag;
    // const featureFlag = {
    //   healthPackageFeatureEnabled: true,
    //   marketplaceFeatureEnabled: true,
    // };
    const isFeatureEnabled = (features) => {
        if (loading) {
            return false;
        }
        return features.length ? features.some(feature => featureFlag === null || featureFlag === void 0 ? void 0 : featureFlag[FeatureEnumMap[feature]]) : false;
    };
    return {
        isFeatureEnabled,
    };
};
