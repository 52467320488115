import { DataGrid } from "shared/components/GridProvider/DataGrid";
import { useExclusionOptionDeleteMutation, useHealthProgramExclusionOptionsQuery } from "../../gql";
import { useExclusionsColumn } from "./ExclusionsColumn";

const ExclusionsList = () => {
  const props = {
    name: "healthProgramExclusionOptions",
    query: useHealthProgramExclusionOptionsQuery,
    columns: useExclusionsColumn(),
    hasTableSetting: false,
    addNewLink: "new",
    editLink: "edit/",
    tableAction: {
      isEditVisible: true,
      isDeleteVisible: true,
    },
    deleteItemProps: {
      name: "exclusionOption",
      entityTypeName: "ExclusionOption",
      useDeleteMutation: useExclusionOptionDeleteMutation,
    },
  };

  return <DataGrid {...props} />;
};

export default ExclusionsList;
