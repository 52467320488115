import { SublistUpsertForm } from "@health/domains";
import { toBase64 } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { SubListItem } from "@health/queries/types";
import { subListsBreadcrumbs, subListsPaths } from "../../constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AdminSublistCreateContainer = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const onEditItemRowClick = (item: SubListItem) => {
    const base64Item = toBase64(JSON.stringify(item));
    navigate(`${subListsPaths.editItem.fullPathWithParams({ itemId: item.id! })}?item=${base64Item}`);
  };

  useEffect(() => {
    const { title, main, create } = subListsBreadcrumbs(t);

    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return <SublistUpsertForm onEditItemRowClick={onEditItemRowClick} />;
};
