import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, ImageUploadController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { IForm } from "../types";

export const ImgUploader: FC<IForm> = ({ defaultValue }) => {
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const { t } = useTranslation("admin");
  const { accessToken } = useOidcUserProfile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploadController
          control={control}
          name='logo'
          label={t("Logo")}
          defaultValue={defaultValue?.logo && getMediaLink(defaultValue?.logo)}
          validationRation={true}
          error={Boolean(formErrors?.logo?.message)}
          helperText={formErrors?.logo?.message}
          token={accessToken!}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUploadController
          control={control}
          defaultValue={defaultValue?.backgroundImage && getMediaLink(defaultValue?.backgroundImage)}
          name='backgroundImage'
          label={t("Background Image")}
          validationRation={true}
          error={Boolean(formErrors?.backgroundImage?.message)}
          helperText={formErrors?.backgroundImage?.message}
          token={accessToken!}
        />
      </Grid>
    </Grid>
  );
};
