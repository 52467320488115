import { AccountError, UserUpdateInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  providerUserManagementFieldBreadcrumb,
  providerUserManagementListBreadcrumb,
  providerUserManagementTitleBreadcrumb,
} from "pages/UserManagement/constants/provider-user-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateUserMutation, UserQuery, useUpdateUserMutation, useUserQuery } from "../../gql";
import { formatMessageErrors } from "@toolkit/apollo";
import { getUserUpdateInfo } from "../UserManagement.utils";

export const useHealthProviderUserManagementEditHook = () => {
  const { t } = useTranslation("admin");
  const params = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const { succeeded, failed } = useAddToast();
  const [updateUserMutation, { loading: isUpdateLoading }] = useUpdateUserMutation({
    onCompleted: (mutationData: UpdateUserMutation) => {
      const error = mutationData?.userUpdate?.accountErrors as AccountError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("User updated successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed in updating user"));
    },
  });

  const handleUpdate = (inputData: UserUpdateInput | any) => {
    const fromDate = inputData?.doctorInfo?.qualifications?.fromDate;
    const toDate = inputData?.doctorInfo?.qualifications?.toDate;
    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      if (fromDateObj > toDateObj) {
        failed("Validation failed: From Date should be less than To Date");
        return;
      }
    }
    const request = getUserUpdateInfo(inputData);
    delete request.vendorUserType;
    updateUserMutation({
      variables: {
        userUpdateId: params?.id as string,
        input: request,
      },
    });
  };
  const { data, loading } = useUserQuery({
    variables: {
      userId: params.id as string,
    },
    fetchPolicy: "no-cache",
    onCompleted: (dataQuery: UserQuery) => {
      if (!dataQuery?.user) {
        failed(t("User not found"));
      }
    },
  });

  const user = data?.user;

  useEffect(() => {
    if (user?.firstName) {
      setBreadCrumb({
        title: providerUserManagementTitleBreadcrumb(t),
        values: [providerUserManagementListBreadcrumb(t), providerUserManagementFieldBreadcrumb(user.firstName)],
      });
    }
  }, [user?.firstName]);

  return { handleUpdate, errors, isUpdateLoading, user, loading };
};
