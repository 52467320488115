import { Components, Theme } from "@mui/material/styles";

export const MuiRadioOverride: Components<Theme>["MuiRadio"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary?.main,
      // "&.Mui-disabled": {
      //   color: theme.palette.stale?.main,
      // },
    }),
  },
};
