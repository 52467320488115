/* eslint-disable max-statements */
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSadaContext } from "@sada/react";
import React from "react";
import { CircularProgress, styled } from "@toolkit/ui";
import { getSadaConfig } from "./util";
import { useLoadAssets } from "./useLoadAssets";
import SadaLogin from "./SadaLogin";
import SadaError from "./SadaError";
const Content = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
}));
const SadaApp = ({ currentUrl, appType, appSignIn = () => { } }) => {
    const { state } = useSadaContext();
    const { token, refresh_token, oauthError, oauthLoading } = state;
    const authToken = useMemo(() => ({ token, refresh_token }), [token, refresh_token]);
    const { appUrl: baseUrl } = useMemo(() => getSadaConfig(), []);
    const { handleGetAssets, assetsError } = useLoadAssets(baseUrl, !!oauthLoading);
    const [sada, setSada] = useState();
    const onReady = useCallback((value) => {
        value.init({ appType, appSignIn });
        setSada(value);
    }, [appType, appSignIn]);
    useEffect(() => {
        window["SADA_EMBEDDED_VIEW"] = { onReady };
        return () => {
            window["SADA_EMBEDDED_VIEW"] = {};
        };
    }, [onReady]);
    useEffect(() => {
        if (sada) {
            sada.init({ appType, appSignIn });
        }
    }, [sada, appType, appSignIn]);
    useEffect(() => {
        sada === null || sada === void 0 ? void 0 : sada.onRouteChange(currentUrl.url);
    }, [sada, currentUrl]);
    useEffect(() => {
        if (!(authToken === null || authToken === void 0 ? void 0 : authToken.token) || !(authToken === null || authToken === void 0 ? void 0 : authToken.refresh_token))
            return;
        sada === null || sada === void 0 ? void 0 : sada.onTokenChange({
            token: authToken.token,
            refresh_token: authToken.refresh_token,
        });
    }, [sada, authToken]);
    if (assetsError) {
        return React.createElement(SadaError, { title: 'Oops!', subtitle: 'Sorry, an unexpected error occurred.', onClick: handleGetAssets });
    }
    if (oauthError) {
        return React.createElement(SadaLogin, null);
    }
    return (
    // eslint-disable-next-line react/forbid-dom-props
    React.createElement("app-root", { style: { display: "flex", minHeight: "100%", backgroundColor: "#ffffff", padding: 10, flexDirection: "column" } },
        React.createElement(Content, null,
            React.createElement(CircularProgress, { color: 'primary', size: 50 }))));
};
export default memo(SadaApp);
