/* eslint-disable max-lines */
import { getAppRoleTypeFilter, getVendorUserTypeFilter, vendorUserTypeOptionsMap } from "@health/enum-options";
import { OrderDirection, UserSortField, UserStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { GetAdminUsersQuery, useGetAllVendorsQuery, useGetBranchesQuery } from "pages/UserManagement/gql";
import { useMemo } from "react";
import { ProviderUserActiveToggleButton } from "./ProviderUserActiveToggleButton.component";

export type UsersManagementColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<GetAdminUsersQuery, "users">>[];

export const useProviderUserManagementColumns = (canManageUser: boolean): UsersManagementColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "status",
          options: Object.keys(UserStatus).map(key => ({
            value: UserStatus[key],
            label: key === "Deactivated" ? t("Inactive") : t(key),
          })),
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "appRole",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAppRoleTypeFilter({ name: "appRole" }),
      },
      {
        key: "firstName",
        header: t("First Name"),
        accessor: row => <TruncateTypography text={row?.firstName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.FirstName,
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: row => <TruncateTypography text={row?.lastName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.LastName,
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.Email,
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => {
          return <ProviderUserActiveToggleButton disabled={!canManageUser} id={row?.id as string} isActive={row?.isActive as boolean} />;
        },
      },
      {
        key: "isVendorAdmin",
        header: t("Is Vendor Admin"),
        accessor: row => (row?.isVendorAdmin === true ? t("Yes") : t("No")),
      },
      {
        key: "vendorUserType",
        header: t("Vendor User Type"),
        accessor: ({ vendorUserType }) => vendorUserTypeOptionsMap[vendorUserType!]?.label || "",
        filter: getVendorUserTypeFilter({ name: "vendorUserType" }),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "branchId",
          accessor: "branches",
          query: useGetBranchesQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
        },
      },
      {
        key: "Vendor",
        header: t("Vendor"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          query: useGetAllVendorsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
          name: "vendorId",
          accessor: "vendors",
        },
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoined",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ];
  }, [t]);
};
