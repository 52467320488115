import { useTranslation } from "@toolkit/i18n";
import { ComingSoon as ComingSoonCard, useBreadCrumbs } from "@toolkit/ui";
import { dashboardTitleBreadcrumb } from "pages/Dashboard/constants/dashboard-breadcrumbs";
import { FC, useEffect } from "react";

const Dashboard: FC = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: dashboardTitleBreadcrumb(),
    });
  }, [t]);

  return <ComingSoonCard />;
};

export default Dashboard;
