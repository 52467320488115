import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOpenState } from "@toolkit/core";
import { CircularProgress, CustomDialog, CustomIcon, Divider, Grid, MenuItem, StyledButton, Typography, useGridContext, useTheme, } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
import { useFetchToSubListDialog } from "./FetchToSublistDialog/useFetchToSubList";
import { SubListsAutocomplete } from "./SubListsAutocomplete";
export const FetchToSubListButton = ({ isDisabled, onClick }) => {
    const { activeFiltersProps } = useGridContext();
    const { getSearchParameters } = useAdminManagedListDetailsPageContext();
    const searchParameters = getSearchParameters === null || getSearchParameters === void 0 ? void 0 : getSearchParameters(activeFiltersProps === null || activeFiltersProps === void 0 ? void 0 : activeFiltersProps.activeFilters);
    const { open: isOpen, handleClose, handleOpen } = useOpenState();
    const handleClick = () => {
        onClick();
        handleOpen();
    };
    const { methods, isSubmitting, t, getValue, handleOnFetch } = useFetchToSubListDialog({ searchParameters });
    const theme = useTheme();
    return (_jsx(CustomDialog, { type: 'base', onClose: handleClose, open: isOpen, button: _jsxs(MenuItem, { disabled: isDisabled, onClick: handleClick, children: [_jsx(CustomIcon, { icon: 'fetchToSubList' }), _jsxs(Typography, { marginLeft: '2px', children: [" ", t("Fetch To SubList")] })] }), DialogTitleProps: {
            title: t("Import current list to Sublist"),
        }, DialogActionsProps: {
            children: (_jsxs(StyledButton, { color: 'success', disabled: isSubmitting, size: 'medium', type: "submit", onClick: methods.handleSubmit(handleOnFetch), children: [isSubmitting && _jsx(CircularProgress, { size: 20, sx: { mx: 1 } }), " ", isSubmitting ? t("Fetching") : t("Fetch")] })),
        }, children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx("form", { onSubmit: methods.handleSubmit(handleOnFetch), children: _jsxs(Grid, { container: true, spacing: 4, xs: 12, lg: 12, flexDirection: 'column', justifyContent: "stretch", children: [_jsx(SubListsAutocomplete, { isOpen: isOpen }), (searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { mt: 2 } }), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: 'subtitle1', children: t("Applied Filters") }) }), _jsx(Grid, { item: true, container: true, spacing: 2, xs: 12, lg: 12, children: _jsx(Grid, { container: true, spacing: 2, xs: 12, lg: 12, sx: { px: 2 }, children: searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.map(param => (_jsx(Grid, { item: true, xs: 6, sm: 6, md: 4, children: _jsxs(Typography, { fontSize: theme.mixins.fonts.fontSize.sm, children: [_jsxs("b", { children: [param.code, ": "] }), getValue(param)] }) }, param.code))) }) })] }))] }) }) })) }));
};
