import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, Chip, CustomIcon, IconButton, TextField, Tooltip } from "@toolkit/ui";
import React, { FC, Fragment, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IProps } from "../types";

export const ServicesAutocomplete: FC<IProps> = ({ defaultValue, errors }) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const { t } = useTranslation("admin");

  const formErrors = combineErrors(reactFormErrors, errors);

  const services = watch("services") || [];
  const [inputValue, setInputValue] = useState<string>("");
  const [newOptions, setNewOptions] = useState<string[]>([]);

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleAddOption = () => {
    if (inputValue === "") return;
    if (!newOptions.some(option => option === inputValue)) {
      setNewOptions([...newOptions, inputValue]);
    }
    const selectedOption: string | undefined = newOptions.find(option => option === inputValue);
    setValue("services", selectedOption ? [...services, selectedOption] : [...services, inputValue]);
    setInputValue("");
  };

  const handleChange = onChange => (_, data) => {
    onChange(data);
  };
  const renderInput = params => (
    <TextField
      {...params}
      hiddenLabel
      label={t("Services")}
      fullWidth
      variant='filled'
      error={Boolean(formErrors?.services?.message)}
      helperText={formErrors?.services?.message}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            <Tooltip title={t("Please Click To add Services")}>
              <IconButton onClick={handleAddOption}>
                <CustomIcon icon={"add"} />
              </IconButton>
            </Tooltip>
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
  function validateServiceObject(serviceArray) {
    if (serviceArray.some(service => service.trim() === "")) {
      return t("Required");
    }

    return true;
  }

  return (
    <Controller
      name='services'
      control={control}
      defaultValue={defaultValue?.services || []}
      rules={{
        required: { value: true, message: t("Required") },
        validate: value => validateServiceObject(value),
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            onChange={handleChange(onChange)}
            renderTags={(value: readonly string[], getTagProps) =>
              services.map((option: string, index: number) => (
                <Fragment key={index}>
                  <Chip sx={{ height: "25px", marginTop: "10px !important" }} label={option} {...getTagProps({ index })} />
                </Fragment>
              ))
            }
            value={value || []}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={renderInput}
          />
        );
      }}
    />
  );
};
