import { Card, Grid, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import ContentLoader from "react-content-loader";

const useSimpleCardLoaderStyles = makeStyles()({
  card: {
    padding: 10,
    height: 250,
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

const SurveyCardLoader: FC = () => {
  const { classes } = useSimpleCardLoaderStyles();
  return (
    <>
      <Grid container padding={4} spacing={2}>
        {new Array(10).fill(0).map((el, key) => (
          <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
            <Card elevation={1} className={classes.card}>
              <ContentLoader width={334} height='100%' viewBox='0 0 400 300'>
                <rect x='0' y='0' rx='0' ry='0' width='280' height='18' />
                <circle x='40' y='40' cx='200' cy='150' r='120' />
              </ContentLoader>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SurveyCardLoader;
