import { makeStyles } from "@toolkit/ui";

export const useTerminateVendorStyles = makeStyles()(() => ({
  customButton: {
    whiteSpace: "nowrap",
  },
}));

export const useWorkingHoursStyles = makeStyles()(theme => ({
  input: {},
  infinite: {
    overflow: "hidden !important",
  },
  hiddenInput: {
    visibility: "hidden",
  },
  div: { maxHeight: 250 },
  accordionSummaryRoot: {
    justifyContent: "space-between",
  },
  accordionSummaryContent: {
    maxWidth: "90%",
  },
  dialogContent: {
    minHeight: "65vh",
    [theme.breakpoints.down("lg")]: {
      minHeight: "85vh",
    },
  },
}));
