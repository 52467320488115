import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useTrackerTradeDrugTypesQuery } from "../gql";
import { tradeDrugTypesPaths } from "../constants/trade-drug-types-paths";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@health/queries/types";
import { tradeDrugTypesTitleBreadcrumb } from "../constants/trade-drug-types-breadcrumbs";
import { useTradeDrugTypesTrackerColumns } from "./components/useTradeDrugTypesTrackerColumns";

export const TradeDrugTypesTrackerList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const canManageActions = hasPermission(PermissionEnum.MangeActivityTracker);

  const handleEditRow = data => {
    navigate(tradeDrugTypesPaths.editPath.fullPathWithParams(data?.id));
  };
  const handleAddNew = () => navigate("new");

  useEffect(() => {
    setBreadCrumb({
      title: tradeDrugTypesTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='trackerTradeDrugTypes'
      columns={useTradeDrugTypesTrackerColumns()}
      query={useTrackerTradeDrugTypesQuery}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
        isRowEditable: canManageActions,
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<>{canManageActions && <MainListActions hasAddNew onAddNewItem={handleAddNew} />}</>}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
