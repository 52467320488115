import { replacePathParams } from "utils";

export const providerUserManagementRoute = "/health-provider-user-management";
const providerUserManagementNewRoute = "new";
const providerUserManagementEditRoute = "edit/:id";

export const providerUserManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${providerUserManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: providerUserManagementNewRoute,
    get fullPath() {
      return `${providerUserManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: providerUserManagementEditRoute,
    get fullPath() {
      return `${providerUserManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
