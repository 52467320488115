import { makeStyles } from "@toolkit/ui";
export const useDecisionConditionUpsertStyle = makeStyles()((_, params) => ({
    gridIcon: {
        ["@media (min-width:1200px)"]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    container: {
        borderRight: "none!important",
        borderBottom: "none!important",
        ["@media (min-width:1200px)"]: {
            border: params.isFieldsMoreThanOne ? "dashed 2px #CBD5E1" : 0,
        },
    },
}));
