/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthProgramsFragmentFragmentDoc } from '../../fragments/__generated__/healthProgramFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramInput;
}>;


export type HealthProgramCreateMutation = { __typename?: 'Mutation', healthProgramCreate?: { __typename?: 'HealthProgramCRUD', healthProgram?: { __typename?: 'HealthProgram', id: string, name?: string | null, copay?: boolean | null, isActive?: boolean | null, careFor?: Array<string | null> | null, targetGroup?: string | null, subscriptionPeriod?: Types.HealthProgramSubscriptionPeriod | null, type?: Types.HealthProgramType | null, currency?: string | null, priceAmount?: number | null, description?: string | null, pointsDuringNormalDemand?: number | null, pointsDuringUrgentDemand?: number | null, baselineAvailabilityHours?: number | null, unattendedCallPenaltyFactor?: number | null, callQualityRatingFactors?: string | null, mainSpecializationsRateFactors?: string | null, images?: Array<string | null> | null, copayAmount?: number | null, copayPercentage?: number | null, prescriptionEnabled?: boolean | null, referralsToSpecialistsEnabled?: boolean | null, insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null, specializations?: Array<{ __typename?: 'HealthProgramSpecialization', created?: any | null, id: string, modified?: any | null, specialization?: { __typename?: 'T_Specialization', code: string, display?: string | null } | null } | null> | null, benefitGroups?: Array<{ __typename?: 'BenefitGroupComplex', benefitGroup?: { __typename?: 'BenefitGroup', id: string, name?: string | null, description?: string | null, created?: any | null } | null, optionsValues?: Array<{ __typename?: 'BenefitOptionComplex', id: string, name?: string | null, value?: number | null } | null> | null } | null> | null } | null, healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const HealthProgramCreateDocument = gql`
    mutation healthProgramCreate($input: HealthProgramInput!) {
  healthProgramCreate(input: $input) {
    healthProgram {
      ...HealthProgramsFragment
    }
    healthProgramErrors {
      field
      message
    }
  }
}
    ${HealthProgramsFragmentFragmentDoc}`;
export type HealthProgramCreateMutationFn = Apollo.MutationFunction<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>;

/**
 * __useHealthProgramCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramCreateMutation, { data, loading, error }] = useHealthProgramCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>(HealthProgramCreateDocument, options);
      }
export type HealthProgramCreateMutationHookResult = ReturnType<typeof useHealthProgramCreateMutation>;
export type HealthProgramCreateMutationResult = Apollo.MutationResult<HealthProgramCreateMutation>;
export type HealthProgramCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>;