import { replacePathParams } from "utils";

export const citiesManagementRoute = "/cities-management";
const citiesManagementNewRoute = "new";
const citiesManagementEditRoute = "edit/:id";

export const citiesManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${citiesManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: citiesManagementNewRoute,
    get fullPath() {
      return `${citiesManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: citiesManagementEditRoute,
    get fullPath() {
      return `${citiesManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
