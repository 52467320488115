import { combineErrors, getRequiredValidation, patternEmail } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormPhoneInput, Grid, TextField } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { InputMember } from "../types";

export const ContactInformation: FC<InputMember> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");
  const {
    setValue,
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);
  useEffect(() => {
    setValue("phone", defaultValue?.phone ?? "");
  }, [defaultValue?.phone, setValue]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormPhoneInput
          {...register("phone", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Phone")}
          error={Boolean(formErrors?.["phone"]?.message)}
          helperText={t(formErrors?.["phone"]?.message)}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("Email")}
          label={t("Email")}
          defaultValue={defaultValue?.email}
          error={Boolean(formErrors.email?.message)}
          helperText={t(formErrors.email?.message)}
          {...register("email", { required: getRequiredValidation(t, true), pattern: patternEmail(t) })}
        />
      </Grid>
    </Grid>
  );
};
