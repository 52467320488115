import { PaletteOptions, Theme } from "@mui/material/styles";
import { commonColors, gray, green, orange, red, roseBudCherry, stale, yellow } from "./colors";
import { tradewind } from "./colors/tradewind";

export const CustomThemePalette: (theme: Theme) => PaletteOptions = theme => ({
  mode: "light",
  divider: "#DDEEEF",

  background: {
    default: "#F8F9FB",
    paper: commonColors.White,
  },
  text: {
    primary: tradewind[1600],
    secondary: tradewind.main,
  },
  primary: {
    ...tradewind,
    light: tradewind[50],
    main: tradewind.main,
    dark: tradewind[1300],
  },
  secondary: {
    ...roseBudCherry,
    main: roseBudCherry.main,
    dark: roseBudCherry[1300],
  },
  success: {
    ...green,
    main: green.main,
  },
  warning: {
    ...orange,
    main: orange.main,
  },
  error: {
    ...red,
    light: red[200],
    main: red.main,
  },
  info: {
    ...stale,
    main: stale.main,
  },
  common: {
    black: commonColors.Black,
    white: commonColors.White,
    ...commonColors,
  },

  // ** Custom Colors **
  gray: theme.palette.augmentColor({
    color: {
      ...gray,
      main: gray.main,
      light: gray[200],
    },
    name: "gray",
  }),
  stale: theme.palette.augmentColor({
    color: {
      ...stale,
      main: stale.main,
    },
    name: "stale",
  }),
  yellow: theme.palette.augmentColor({
    color: {
      ...yellow,
      main: yellow.main,
    },
    name: "yellow",
  }),
});
