import type { FC, PropsWithChildren } from "react";
import { createContext, useState } from "react";

type ProgramTabSteps = "definition" | "program-tracking";
export const ProgramsContext = createContext<{
  step: ProgramTabSteps;
  id: string | undefined;
  onChangeStep: (step: ProgramTabSteps) => void;
  setHealthProgramState: React.Dispatch<
    React.SetStateAction<{
      step: ProgramTabSteps;
      id: string | undefined;
    }>
  >;
}>({
  step: "definition",
  id: undefined,
  onChangeStep: s => console.log(s),
  setHealthProgramState: () => {},
});

export const ProgramsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<{
    step: ProgramTabSteps;
    id: string | undefined;
  }>({
    step: "definition",
    id: undefined,
  });
  const handleChangeStep = step => {
    setState(state => ({ ...state, step }));
  };

  return (
    <ProgramsContext.Provider
      value={{
        id: state.id,
        step: state.step as ProgramTabSteps,
        onChangeStep: handleChangeStep,
        setHealthProgramState: setState,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};
