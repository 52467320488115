import { useTranslation } from "@toolkit/i18n";
import React, { FC, PropsWithChildren } from "react";
import { PlusIcon } from "../../../icons/icons";
import { StyledButton } from "./StyledButton.styles";
import { MainListActionsProps } from "./types";

export const MainListActions: FC<PropsWithChildren<MainListActionsProps>> = ({
  children,
  hasAddNew,
  onAddNewItem: handleAddNewItem,
  titleAddButton = "Add New",
}) => {
  const { t } = useTranslation();

  return (
    <>
      <>{children}</>

      {hasAddNew && (
        <StyledButton
          fullWidth
          data-testId={"addNew"}
          color='success'
          size='small'
          sx={{ width: "150px" }}
          startIcon={<PlusIcon />}
          onClick={handleAddNewItem}
        >
          {t(titleAddButton)}
        </StyledButton>
      )}
    </>
  );
};
