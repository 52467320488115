import { i18n } from "@toolkit/i18n";
import moment from "moment";
export const formatNames = (...args) => args.filter(Boolean).join(" ") || "-";
export const getCreatorName = (statusText, creator, t) => {
    if (statusText === "Draft" || statusText === undefined || statusText === "Expired" || creator === undefined || creator === null) {
        return statusText;
    }
    else {
        return statusText + t("- By :", { ns: "domains" }) + " " + creator;
    }
};
export const formatDate = (updateDate) => updateDate ? moment(updateDate).locale(i18n.language).format("DD MMM YYYY | HH:MM") : "";
