import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const articleCategoryAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    display: z.string(),
    displayAr: z.string().nullable().optional(),
}));
export const ArticleCategoryAutocompleteSchemas = {
    articleCategoryAutocomplete,
    articleCategoryAutocompleteOptional: articleCategoryAutocomplete.nullable().optional(),
};
