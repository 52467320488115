import { combineErrors, getPositiveNumber, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, HintComponent, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "../types";

export const Availability: FC<IProgramProps> = ({ errors, defaultValues, readOnly }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("Baseline Availability Hours")}
          error={Boolean(formErrors.baselineAvailabilityHours?.message)}
          helperText={t(formErrors.baselineAvailabilityHours?.message)}
          {...register("baselineAvailabilityHours", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValues?.baselineAvailabilityHours || 40}
          InputProps={{
            readOnly,
            endAdornment: <HintComponent title={t("Number of availability hours expected to be met by each enrolled doctor per month")} />,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("Unattended Call Penalty Factor")}
          error={Boolean(formErrors.unattendedCallPenaltyFactor?.message)}
          helperText={t(formErrors.unattendedCallPenaltyFactor?.message)}
          {...register("unattendedCallPenaltyFactor", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValues?.unattendedCallPenaltyFactor || 0.2}
          InputProps={{
            readOnly,
            endAdornment: <HintComponent title={t("Penalty factor to be multiplied by the total number of unattended calls per month")} />,
          }}
        />
      </Grid>
    </Grid>
  );
};
