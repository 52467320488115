import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatUserNameFull } from "@toolkit/core";
import { AutocompleteComponent, Button, CircularProgress, CustomDialog, FormControl } from "@toolkit/ui";
import { memo } from "react";
import { useAMLAssignReviewersHook } from "./useAMLAssignReviewersHook";
const AMLAssignReviewerDialog = props => {
    var _a;
    const { isOpen, errors, reviewer, editor, users, isLoading, isSubmitting, onFetchMoreUsers, t, handleOpen, handleClose, handleReviewerChange, handleEditorChange, handleAssignClick, } = useAMLAssignReviewersHook(props);
    const reviewerInputLabel = t("Reviewer Name");
    const editorInputLabel = t("Editor Name");
    return (_jsxs(CustomDialog, { type: 'base', open: isOpen, button: "renderButton" in props ? (_a = props.renderButton) === null || _a === void 0 ? void 0 : _a.call(props, handleOpen) : _jsx(Button, Object.assign({}, props.buttonProps, { onClick: handleOpen })), maxWidth: 'xs', DialogTitleProps: {
            onClose: handleClose,
            title: t("Assign"),
        }, keepMounted: false, DialogActionsProps: {
            children: (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleAssignClick, disabled: isSubmitting, children: isSubmitting ? (_jsxs(_Fragment, { children: [_jsx(CircularProgress, { size: 14 }), " ", t("Assign"), "..."] })) : (t("Assign")) }), _jsx(Button, { variant: 'outlined', onClick: handleClose, autoFocus: true, children: t("Close") })] })),
        }, children: [_jsx(FormControl, { fullWidth: true, children: _jsx(AutocompleteComponent, { fetchMoreData: onFetchMoreUsers, loading: isLoading, getOptionLabel: item => formatUserNameFull(item), options: users || [], onChange: handleEditorChange, 
                    // placeholder={editorInputLabel}
                    defaultValue: editor, TextFieldProps: {
                        label: editorInputLabel,
                        placeholder: editorInputLabel,
                        error: !!errors.editorId,
                        helperText: errors.editorId,
                    } }) }), _jsx(FormControl, { fullWidth: true, sx: { mt: 3 }, children: _jsx(AutocompleteComponent, { fetchMoreData: onFetchMoreUsers, loading: isLoading, defaultValue: reviewer, getOptionLabel: item => formatUserNameFull(item), options: users || [], isOptionEqualToValue: (o, v) => o.id === v.id || o == v, onChange: handleReviewerChange, 
                    // placeholder={reviewerInputLabel}
                    TextFieldProps: {
                        label: reviewerInputLabel,
                        placeholder: reviewerInputLabel,
                        error: !!errors.reviewerId,
                        helperText: errors.reviewerId,
                    } }) })] }));
};
export default memo(AMLAssignReviewerDialog);
