import { type Palette } from "../../base/mui";
import { paths } from "../CustomIcon";

export const renderSwitch = (param: string, palette: Palette): { icon: keyof typeof paths; color: string } => {
  switch (param) {
    case "WARNING":
      return { icon: "calendar", color: palette.warning.main };
    case "Error":
      return { icon: "miniClose", color: palette.error.main };
    case "INFO":
      return { icon: "calendar", color: palette.primary.main };
    case "success":
      return { icon: "warning", color: palette.success.main };
    default:
      return { icon: "calendar", color: palette.warning.main };
  }
};
