import { makeStyles } from "tss-react/mui";

export const useTabsStyle = makeStyles()(theme => ({
  selectedTab: {
    boxShadow: theme.mixins.shadows.xs,
    zIndex: 1,
    borderRadius: `10px !important`,
    height: `50px !important`,
    fontSize: `${theme.mixins.fonts.fontSize.md} !important`,
    color: `${theme.palette.common.white} !important`,
    background: `${theme.palette.primary.main} !important`,
  },
  tab: {
    // height: 37,
    fontSize: theme.mixins.fonts.fontSize.sm,
    minHeight: 43,
    background: theme.palette.background.default,
    color: `${theme.palette.primary.main}`,
    border: "1px solid",
    borderColor: theme.palette.common.white,
    "&:last-child": {
      border: 5,
      // borderBottomRightRadius: 5,
      // borderTopRightRadius: 5,
    },
    "&:first-child": {
      border: 5,
      // borderBottomLeftRadius: 5,
      // borderTopLeftRadius: 5,
    },
  },
  tabs: {
    margin: 12,
    padding: 2,
    backgroundColor: theme.palette.common.white,
    background: theme.palette.background.default,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
  },
  tabPanel: {
    padding: 0,
  },
  cardTabs: {
    padding: "4px",
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
  },
  cardSelectedTab: {
    margin: "-5px",
    zIndex: 1,
    borderRadius: `10px !important`,
    height: `50px !important`,
    fontSize: `${theme.mixins.fonts.fontSize.md} !important`,
    color: `${theme.palette.common.white} !important`,
    background: `${theme.palette.primary[50]} !important`,
  },
  cardTab: {
    width: "100%",
    // height: 37,
    fontSize: theme.mixins.fonts.fontSize.sm,
    minHeight: 43,
    background: theme.palette.common.white,
    borderRadius: "0px",
    color: `${theme.palette.primary.main}`,
    "&:last-child": {
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "&:first-child": {
      borderBottomLeftRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
}));
