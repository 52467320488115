import { Components, Theme } from "../../../base/mui";

export const MuiInputAdornmentOverride: Components<Theme>["MuiInputAdornment"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: "inherit",
      "&.MuiInputAdornment-filled": {
        // backgroundColor: inputBorderColorDefault,
        backgroundColor: "transparent",
        height: "100%",
        borderRadius: 4,
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
    }),
  },
};
