import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { ExcelIcon, MenuItem, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { domainsConfig } from "../../../../../domainsConfig";
import { useDownloadCodeSystemFileLazyQuery } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const DownloadButton = ({ onClick, hasData }) => {
    const { t } = useTranslation("domains");
    const { failed } = useAddToast();
    const theme = useTheme();
    const { code, versionParamId } = useAdminManagedListDetailsPageContext();
    const handleDownloadFile = () => {
        onClick();
        getDownloadCodeSystemFile({
            variables: {
                code: code,
                codeSystemId: versionParamId,
            },
        });
    };
    const handleDownloadTemplateFile = () => {
        onClick();
        getDownloadCodeSystemFile({
            variables: {
                code: code,
            },
        });
    };
    const [getDownloadCodeSystemFile, { loading }] = useDownloadCodeSystemFileLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: data => {
            if (data === null || data === void 0 ? void 0 : data.downloadCodeSystemFile) {
                const baseURL = domainsConfig.get("serverUrl");
                window.open(baseURL + data.downloadCodeSystemFile, "_self");
            }
        },
        onError: () => {
            failed(t("Download Failed"));
        },
    });
    return (_jsxs(_Fragment, { children: [_jsxs(MenuItem, { disabled: loading || !hasData, onClick: handleDownloadFile, children: [_jsx(ExcelIcon, { fill: theme.palette.primary.main }), _jsxs(Typography, { marginLeft: '8px', children: [" ", t("Export Data"), " .xlsx"] })] }), _jsxs(MenuItem, { disabled: loading, onClick: handleDownloadTemplateFile, children: [_jsx(ExcelIcon, { fill: theme.palette.primary.main }), _jsxs(Typography, { marginLeft: '8px', children: [" ", t("Download Template"), " .xlsx"] })] })] }));
};
