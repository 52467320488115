import { Box, Button, styled } from "../../base/mui";

export const StyledButton = styled(Button)(() => ({
  minWidth: "fit-content",
  margin: "10px 5px",
}));

export const FormActionsWrapperStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
