import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const healthPackagesRoute = `${marketplaceRoute}/health-packages`;

export const healthPackagePaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthPackagesRoute}${this.route}`;
    },
  },
};
