/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceBrandCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceBrandInput;
}>;


export type MarketplaceBrandCreateMutation = { __typename?: 'Mutation', marketplaceBrandCreate?: { __typename?: 'MarketplaceBrand', id: string, image?: string | null, name?: string | null, nameAr?: string | null } | null };


export const MarketplaceBrandCreateDocument = gql`
    mutation marketplaceBrandCreate($input: MarketplaceBrandInput!) {
  marketplaceBrandCreate(input: $input) {
    id
    image
    name
    nameAr
  }
}
    `;
export type MarketplaceBrandCreateMutationFn = Apollo.MutationFunction<MarketplaceBrandCreateMutation, MarketplaceBrandCreateMutationVariables>;

/**
 * __useMarketplaceBrandCreateMutation__
 *
 * To run a mutation, you first call `useMarketplaceBrandCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrandCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceBrandCreateMutation, { data, loading, error }] = useMarketplaceBrandCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceBrandCreateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceBrandCreateMutation, MarketplaceBrandCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceBrandCreateMutation, MarketplaceBrandCreateMutationVariables>(MarketplaceBrandCreateDocument, options);
      }
export type MarketplaceBrandCreateMutationHookResult = ReturnType<typeof useMarketplaceBrandCreateMutation>;
export type MarketplaceBrandCreateMutationResult = Apollo.MutationResult<MarketplaceBrandCreateMutation>;
export type MarketplaceBrandCreateMutationOptions = Apollo.BaseMutationOptions<MarketplaceBrandCreateMutation, MarketplaceBrandCreateMutationVariables>;