import { useFeatureFlag } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import RouteItem from "./routeTypes";

export const useAppRoutes = (): RouteItem[] => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const auth = useAuth();
  const userInfo = auth?.user?.profile;

  const { isFeatureEnabled } = useFeatureFlag();

  return useMemo(
    () => [
      ROUTE_PATHS.dashboardRoutes({ navigate }),
      ROUTE_PATHS.adminManagedListsRoutes({ navigate }),
      ROUTE_PATHS.marketplaceRoutes({ navigate, isFeatureEnabled }),
      ROUTE_PATHS.prescriptionsOrdersTrackingRoutes({ navigate, t }),
      ROUTE_PATHS.providersManagementRoutes({ navigate, t }),
      ROUTE_PATHS.healthProgramsRoutes({ navigate, t }),
      ROUTE_PATHS.activityTrackerRoutes({ navigate, t }),
      ROUTE_PATHS.decisionsRoutes({ navigate, t }),
      ROUTE_PATHS.optimaRoutes({ navigate, t }),
      ROUTE_PATHS.surveysRoutes({ navigate, t }),
      ROUTE_PATHS.healthInsurancesManagementRoutes({ navigate, t }),
      ROUTE_PATHS.healthInsuranceNetworkRoutes({ navigate, t }),
      ROUTE_PATHS.callbackRequestsRoutes({ navigate }),
      ROUTE_PATHS.permissionsManagementRoutes({ navigate, t }),
      ROUTE_PATHS.citiesManagementRoutes({ navigate }),
      ROUTE_PATHS.insuranceApprovalsRoutes({ navigate, t }),
      ROUTE_PATHS.PayersRoutes({ navigate, t }),
      ROUTE_PATHS.reviewPageUploadRoutes({ navigate, t }),
      ROUTE_PATHS.streamingRoutes({ navigate, t }),
      ROUTE_PATHS.settingsRoutes({ navigate, t }),
    ],
    [userInfo, isFeatureEnabled]
  );
};
