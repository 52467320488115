import { combineErrors, required, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";

export const ProgramDescriptionSection: FC<IProgramProps> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size='small'
          label={t("Program Description")}
          multiline
          rows={7}
          {...register("description", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
          defaultValue={defaultValues?.description}
          error={Boolean(formErrors.description?.message)}
          helperText={formErrors.description?.message}
        />
      </Grid>
    </Grid>
  );
};
