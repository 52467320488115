import { City } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { citiesManagementTitleBreadcrumb } from "pages/CitiesManagement/constants/cities-management-breadcrumbs";
import { citiesManagementPaths } from "pages/CitiesManagement/constants/cities-management-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useGetAllCitiesQuery } from "../gql";
import { useCitiesManagementColumns } from "./components/useCitiesManagementColumns";

export const CitiesManagementList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEdit = ({ id }: City) => {
    navigate(citiesManagementPaths.editPath.fullPathWithParams(id));
  };

  const handleAddNew = () => {
    navigate(citiesManagementPaths.newPath.fullPath);
  };

  const CitiesColumns = useCitiesManagementColumns();

  useEffect(() => {
    setBreadCrumb({
      title: citiesManagementTitleBreadcrumb(),
    });
  }, [t]);

  return (
    <GridProvider
      hasTableSetting
      gridName='cities'
      columns={CitiesColumns}
      query={useGetAllCitiesQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEdit,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
