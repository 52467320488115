import React, { FC } from "react";
import { VendorForm } from "../components/Form/VendorForm";
import { useVendorEditHook } from "./VendorEdit.hook";

const VendorsFormEditPage: FC = () => {
  const { vendor, errors, isLoading, handleUpdate, isUpdateLoading } = useVendorEditHook();
  return (
    <VendorForm
      isEditMode
      vendor={vendor}
      isLoading={isLoading}
      vendorErrors={errors}
      onDone={handleUpdate}
      isSubmitting={isUpdateLoading}
    />
  );
};

export default VendorsFormEditPage;
