import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles } from "tss-react/mui";

const generatePseudoElement = (position: string) => ({
  [`::${position}`]: {
    content: "''",
    display: "block",
    position: "absolute",
    zIndex: 1,
    top: "0",
    [position === "before" ? "left" : "right"]: "46px",
    height: "100%",
    width: "2px",
    opacity: 1,
    background: "#25287f17",
  },
});

const generateElementBorderByPseudoElement = (itemLevel: number) => {
  if (itemLevel >= 3) {
    return pickLocalizedValue(generatePseudoElement("before"), generatePseudoElement("after"));
  }

  return {};
};

export const useSidebarStyles = makeStyles<{ selected?: boolean; itemLevel?: number }>()((theme, { selected, itemLevel }) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    height: theme.mixins.header.height,
    // necessary for content to be below app bar
    ...(theme.mixins.toolbar as any),
    justifyContent: "flex-end",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
    marginTop: 0,
  },
  listItem: {
    height: 50,
    backgroundColor: selected ? theme.palette.stale[100] : "transparent",
    borderRadius: "0px",
    paddingInline: "8px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.stale[100],
      "&:hover": {
        backgroundColor: theme.palette.stale[100],
      },
    },
  },
  listItemIcon: {
    minWidth: 20,
    minHeight: 20,
    marginInline: 10,
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
  listItemText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    marginInlineStart: itemLevel! >= 3 ? 7 * itemLevel! + "px" : "0px",
    fontWeight: selected ? theme.mixins.fonts.fontWeight.medium : theme.mixins.fonts.fontWeight.regular,
    "& > span": {
      fontSize: theme.mixins.fonts.fontSize.sm,
      fontWeight: selected ? theme.mixins.fonts.fontWeight.medium : theme.mixins.fonts.fontWeight.regular,
    },
    ...generateElementBorderByPseudoElement(itemLevel!),
  },
  root: {
    backgroundColor: theme.palette.stale[100],
  },
}));
