import { useTranslation } from "@toolkit/i18n";
import { Container, CustomToggleButton, useAddToast } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { HealthProgramMemberListsDocument, useHealthProgramMemberListUpdateMutation } from "../../gql";
import { HealthProgramMemberListInput } from "@health/queries/types";

export type UpdateRequiredInput = Omit<HealthProgramMemberListInput, "isActive">;

export const ActiveToggleSwitch: FC<{ isActive: boolean; id: string; isDisabled?: boolean; updateRequiredInput: UpdateRequiredInput }> = ({
  isActive,
  id,
  isDisabled,
  updateRequiredInput,
}) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(isActive);

  useEffect(() => {
    setCheck(isActive);
  }, [isActive]);

  const [healthProgramMemberListUpdate, { loading }] = useHealthProgramMemberListUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramMemberListUpdate?.entityErrors;
      if (errors?.length === 0) {
        succeeded(t("Member List updated successfully"));
      } else {
        const message = errors?.map(error => {
          return `${error.field || ""},\r\n ${error.message || ""}\r\n`;
        });
        failed(message as any);
      }
    },
    onError: () => {
      failed(t("Network Update Failed"));
    },
    refetchQueries: [
      {
        query: HealthProgramMemberListsDocument,
        variables: {
          first: 10,
          filter: {
            isActive: true,
          },
        },
      },
    ],
  });
  const handleChangeToggle = e => {
    setCheck(e.target.checked);
    healthProgramMemberListUpdate({
      variables: {
        id,
        input: {
          isActive: e.target.checked,
          ...updateRequiredInput,
        },
      },
    });
  };

  return (
    <Container sx={{ display: "flex" }}>
      <CustomToggleButton value={check || false} disabled={loading || isDisabled} defaultChecked={isActive} onChange={handleChangeToggle} />
    </Container>
  );
};
