import { H_EntityError } from "@health/queries/types";
import { combineErrors, getRequiredValidation, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, TextField, ToggleButtonController } from "@toolkit/ui";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Payers } from "shared/components";
import { formButtonTitle } from "shared/utils";
import { healthProgramMemberListsProps } from "../types";

export const HealthProgramMemberListsForm: FC<healthProgramMemberListsProps> = props => {
  const { defaultValue, isLoading, errors, onDone, isSubmitting } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const { ...methods } = useForm();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors: reactFormErrors },
  } = methods;

  const formErrors = combineErrors(reactFormErrors, errors);

  const onSubmit = data => {
    onDone(data);
  };

  const handleCancel = () => {
    navigate(memberListPaths.listPath.fullPath);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <FormCard loading={isLoading} doYouHaveData title={t("General Information")}>
            <Grid container spacing={2}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    placeholder={t("Member List Name")}
                    label={t("Member List Name")}
                    defaultValue={defaultValue?.name}
                    error={Boolean(formErrors.name?.message)}
                    helperText={t(formErrors.name?.message)}
                    {...register("name", {
                      required: getRequiredValidation(t, true),
                      validate: (value: string) => validateValueIsNotEmpty(value, t),
                    })}
                  />
                </Grid>
                <Grid item xs={2}>
                  <ToggleButtonController
                    control={control}
                    label={t("Active")}
                    defaultValue={defaultValue?.isActive}
                    {...register("isActive")}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Payers<Partial<H_EntityError>> errors={errors!} payer={defaultValue?.payer!} />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    multiline
                    rows={5}
                    fullWidth
                    placeholder={t("Member List Description")}
                    label={t("Member List Description")}
                    defaultValue={defaultValue?.description}
                    error={Boolean(formErrors.description?.message)}
                    helperText={t(formErrors.description?.message)}
                    {...register("description", {
                      required: getRequiredValidation(t, true),
                      validate: (value: string) => validateValueIsNotEmpty(value, t),
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
