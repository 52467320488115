import { makeStyles, Theme } from "../../../base/mui";

export const useStyles = makeStyles()((theme:Theme) => ({
  container: {
    direction: "ltr",
  },
  input: {
    width: "42px",
    minHeight: "42px",
    margin: "0px 4px",
    boxShadow: theme.mixins.shadows.sm,
    borderRadius: "4px",
    border: "none",
    fontSize: theme.mixins.fonts.fontSize.xl,
    textAlign: "center",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
    direction: "ltr",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
}));
