import { SurveyDetailsHeader } from "../components";
import SurveyQuestionsTab from "../components/SurveyQuestionsTab";
import SurveyStatisticsTab from "../components/SurveyStatisticsTab";
import { useSurveyDetailsPage } from "./useSurveyDetailsPage";

export const SurveyDetailsPage: React.FC = () => {
  const { selectedTab, answeredParticipantsCount, showResponsesTab, handleChangeTab } = useSurveyDetailsPage();

  return (
    <>
      <SurveyDetailsHeader
        selectedTab={selectedTab}
        showResponsesTab={showResponsesTab}
        responsesCount={answeredParticipantsCount ?? 0}
        onChangeTab={handleChangeTab}
      />
      {selectedTab === "Questions" && <SurveyQuestionsTab />}
      {selectedTab === "Responses" && showResponsesTab && <SurveyStatisticsTab />}
    </>
  );
};

export default SurveyDetailsPage;
