import { i18n } from "@toolkit/i18n";
import { SurveyIcon } from "@toolkit/ui";
import { callbackRequestsPaths, callbackRequestsRoute } from "pages/CallbackRequests/constants/callback-requests-paths";
import CallbackRequestList from "pages/CallbackRequests/List/CallbackRequestList.screen";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const callbackRequestsRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "callback-requests-list-route",
    text: i18n.t("Callback Requests", { ns: "admin" }),
    icon: <SurveyIcon />,
    route: callbackRequestsRoute,
    fullPath: callbackRequestsPaths.listPath.fullPath,
    element: <CallbackRequestList />,
    isProhibited: false,
    onClick: (route: string) => navigate(route),
  };
};
