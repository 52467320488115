import { makeStyles } from "@toolkit/ui";
export const useDecisionConditionsGroupsFormStyle = makeStyles()(theme => ({
    container: {
        padding: "0px 10px !important",
        marginBottom: 5,
    },
    addGroupButton: {
        backgroundColor: theme.palette.success.main,
    },
}));
