import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { memberBreadcrumb, membersTitleBreadcrumb } from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { membersPaths } from "pages/Members/constants/members-paths";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useHealthProgramMembersQuery } from "../HealthProgramMembers";
import { useHealthProgramMembersColumns } from "../HealthProgramMembers/list/components/useHealthProgramMembersColumns";

export const UserMemberShips: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const handleAddNew = () => {
    navigate(membersPaths.newPath.fullPath);
  };

  const handleEditRow = data => {
    navigate(membersPaths.editPath.fullPathWithParams(data?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: membersTitleBreadcrumb(t),
      values: [memberBreadcrumb(t)],
    });
  }, []);

  return (
    <GridProvider
      gridName='healthProgramMembers'
      columns={useHealthProgramMembersColumns({ hasMembers: true })}
      query={useHealthProgramMembersQuery}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
