import { makeStyles } from "tss-react/mui";

export const useHintComponentStyles = makeStyles()(theme => ({
  icon: {
    marginInline: "-6px !important",
    "& svg ": {
      fill: theme.palette.primary.main,
      "&:hover": {
        fill: theme.palette.warning.main,
      },
    },
  },
  openIcon: {
    marginInline: "-6px !important",

    "& svg ": {
      fill: theme.palette.warning.main,
    },
  },
}));
