import { useTranslation } from "@toolkit/i18n";
import { PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import {
  networkFieldBreadcrumb,
  networkListBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useGetProviderAndNetworkQuery, useHealthProgramNetworkProviderProfessionalsQuery } from "../gql";
import { useHealthProgramNetworkProviderProfessionalsColumns } from "./components/useHealthProgramNetworkProviderProfessionalsColumns";

export const HealthProgramNetworkProviderProfessionals: FC = () => {
  const { t } = useTranslation("admin");
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const id = params?.id!;
  const providerId = params?.providerId!;
  const { data } = useGetProviderAndNetworkQuery({
    variables: {
      filter: {
        ids: [providerId],
      },
      filterNetwork: {
        ids: [id],
      },
    },
  });
  const healthProgramNetworkProvider = data?.healthProgramNetworkProviders?.edges?.map(item => item?.node)[0];
  const healthProgramNetwork = data?.healthProgramNetworks?.edges?.map(item => item?.node)[0];

  useEffect(() => {
    healthProgramNetworkProvider?.provider?.name &&
      healthProgramNetwork?.name &&
      setBreadCrumb({
        title: networkTitleBreadcrumb(t),
        values: [
          networkListBreadcrumb(t),
          networkFieldBreadcrumb(healthProgramNetwork?.name, networksPaths.providersPath.fullPathWithParams(id)),
          networkFieldBreadcrumb(healthProgramNetworkProvider?.provider?.name),
          networkFieldBreadcrumb("Professionals"),
        ],
      });
  }, [healthProgramNetworkProvider?.provider?.name, healthProgramNetwork?.name]);

  return (
    <GridProvider
      gridName='doctors'
      variables={{ firstDoctor: 10, filterDoctor: { vendor: [providerId] }, filter: { healthProgramNetworks: [id] } }}
      columns={useHealthProgramNetworkProviderProfessionalsColumns()}
      query={useHealthProgramNetworkProviderProfessionalsQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
