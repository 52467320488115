/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExclusionOptionCreateMutationVariables = Types.Exact<{
  input: Types.ExclusionOptionInput;
}>;


export type ExclusionOptionCreateMutation = { __typename?: 'Mutation', exclusionOptionCreate?: { __typename?: 'ExclusionOptionCRUD', healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, code: Types.H_EntityErrorCode, message?: string | null }> | null, exclusionOption?: { __typename?: 'ExclusionOption', id: string, name?: string | null, description?: string | null } | null } | null };


export const ExclusionOptionCreateDocument = gql`
    mutation ExclusionOptionCreate($input: ExclusionOptionInput!) {
  exclusionOptionCreate(input: $input) {
    healthProgramErrors {
      field
      code
      message
    }
    exclusionOption {
      id
      name
      description
    }
  }
}
    `;
export type ExclusionOptionCreateMutationFn = Apollo.MutationFunction<ExclusionOptionCreateMutation, ExclusionOptionCreateMutationVariables>;

/**
 * __useExclusionOptionCreateMutation__
 *
 * To run a mutation, you first call `useExclusionOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExclusionOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exclusionOptionCreateMutation, { data, loading, error }] = useExclusionOptionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExclusionOptionCreateMutation(baseOptions?: Apollo.MutationHookOptions<ExclusionOptionCreateMutation, ExclusionOptionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExclusionOptionCreateMutation, ExclusionOptionCreateMutationVariables>(ExclusionOptionCreateDocument, options);
      }
export type ExclusionOptionCreateMutationHookResult = ReturnType<typeof useExclusionOptionCreateMutation>;
export type ExclusionOptionCreateMutationResult = Apollo.MutationResult<ExclusionOptionCreateMutation>;
export type ExclusionOptionCreateMutationOptions = Apollo.BaseMutationOptions<ExclusionOptionCreateMutation, ExclusionOptionCreateMutationVariables>;