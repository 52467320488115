import { MeNotificationsQuery, useMeNotificationsQuery } from "gql/queries";
import { useEffect, useState } from "react";
import { useMarkAllNotificationsAsSeenMutation } from "./gql";

export const useNotificationsHooks = () => {
  const { data, loading, fetchMore, refetch } = useMeNotificationsQuery({
    variables: {
      first: 10,
    },
  });
  const [markAllNotificationsAsSeenMutation] = useMarkAllNotificationsAsSeenMutation();

  const notifications = data?.me?.notifications?.edges?.map(notification => notification?.node);
  const totalCount = data?.me?.notifications?.totalCount;
  const unReadNotificationsCount = data?.me?.unReadMessagesCount;

  const pageInfo = data?.me?.notifications?.pageInfo;

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    unReadNotificationsCount && !open && markAllNotificationsAsSeenMutation();
    setOpen(!open);
  };

  useEffect(() => {
    open && unReadNotificationsCount && refetch();
  }, [open]);

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: MeNotificationsQuery, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prev?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          };
        },
      });
    }
  };

  return {
    open,
    loading,
    pageInfo,
    totalCount,
    notifications,
    unReadNotificationsCount,
    handleToggle,
    fetchMoreData,
  };
};
