import { isLTR, isRTL, pickLocalizedValue } from "@toolkit/i18n";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box } from "../../../base/mui";
import { useStyles } from "./verificationInput.styles";

type VerificationInputProps = {
  onChange: (value: string) => void;
  length?: number;
  inputType?: "text" | "number";
  defaultValue?: string;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
};

export type VerificationInputRef = {
  resetInput: () => void;
};

const VerificationInputForwardRef: ForwardRefRenderFunction<VerificationInputRef, VerificationInputProps> = (
  { onChange, length = 6, inputType = "number", defaultValue = "", classes: _classes = {} },
  ref
) => {
  const { classes, cx } = useStyles();
  const [otp, setOTP] = useState<string[]>(Array.from({ length }, (_, i) => defaultValue[i] || ""));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const isRtl = isRTL();
  const isLtr = isLTR();

  const resetInput = () => {
    setOTP(Array(length).fill(""));
  };

  // eslint-disable-next-line max-statements
  const handleChange = (index: number, value: string) => {
    const newOTP = [...otp];

    if (value.length > 1) {
      const prevValues = otp[index] ? Array.from(otp[index]) : [];
      const currentValues = Array.from(value);
      const diffIndex = currentValues.findIndex((char, i) => char !== prevValues[i]);
      newOTP[index] = currentValues[diffIndex];
    } else {
      newOTP[index] = value;
    }

    setOTP(newOTP);

    if (value && isLtr && index < length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (value && isRtl && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useImperativeHandle(ref, () => ({
    resetInput,
  }));

  useEffect(() => {
    const finalValue = pickLocalizedValue(otp.join(""), [...otp].reverse().join(""));
    onChange(finalValue);
  }, [otp, onChange]);

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && otp[index] === "") {
      if (isLtr && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (isRtl && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    const pastedOTP = pastedData.slice(0, length).split("");
    const newOTP = [...otp];

    pastedOTP.forEach((value, index) => {
      const idx = pickLocalizedValue(index, length - 1 - index);
      if (inputRefs.current[idx] && /^[0-9a-zA-Z]$/.test(value)) {
        newOTP[idx] = value;
      }
    });

    setOTP(newOTP);
    onChange(newOTP.join(""));
  };

  return (
    <Box className={cx(classes.container, _classes.container)}>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          maxLength={1}
          min={0}
          max={9}
          type={inputType}
          value={otp[index]}
          onPaste={handlePaste}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          ref={input => (inputRefs.current[index] = input as HTMLInputElement)}
          className={cx(classes.input, _classes.input)}
          aria-label={`Verification code input ${index + 1}`}
        />
      ))}
    </Box>
  );
};

export const VerificationInput = forwardRef(VerificationInputForwardRef);
