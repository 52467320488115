import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import { SublistItemListDocument, useSublistCreateMutation, useSublistDownloadLazyQuery, useSublistGetLazyQuery, useSublistUpdateMutation, useSublistUploadMutation, } from "../../../Sublists/gql";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useReportAnError } from "../../../../shared/hooks";
import { domainsConfig } from "../../../../domainsConfig";
export const useSublistUpsertForm = ({ id }) => {
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { reportGraphQlErrors, reportError, reportGenericApiError } = useReportAnError();
    const methods = useForm({
        mode: "all",
    });
    const [fetchSubList, { data, loading }] = useSublistGetLazyQuery({
        variables: {
            id: id,
        },
    });
    const defaults = data === null || data === void 0 ? void 0 : data.getSubList;
    const { errors } = methods.formState;
    const [create, { loading: isSubmittingCreate }] = useSublistCreateMutation();
    const [update, { loading: isSubmittingUpdate }] = useSublistUpdateMutation();
    const onSubmit = (input) => {
        var _a;
        const method = id ? update : create;
        method({
            variables: {
                subList: {
                    display: input === null || input === void 0 ? void 0 : input.display,
                    field: { id: (_a = input === null || input === void 0 ? void 0 : input.field) === null || _a === void 0 ? void 0 : _a.id },
                    id: id || null,
                },
            },
            onCompleted: mutationData => {
                var _a, _b, _c;
                const errors = (_a = mutationData.updateSubListItem) === null || _a === void 0 ? void 0 : _a.errors;
                if (!((_c = (_b = mutationData.updateSubListItem) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.length)) {
                    addToast(id ? t("Sublist updated Successfully!") : t("Sublist Added Successfully!"), {
                        appearance: "success",
                        autoDismiss: true,
                    });
                }
                else {
                    addToast(t(formatMessageErrors(errors)), {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
                navigate(-1);
            },
            onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error saving sublist"))(graphQLErrors),
        });
    };
    const handleSubmit = methods.handleSubmit(onSubmit);
    const [download] = useSublistDownloadLazyQuery();
    const [fetchImportSubList, { data: importData, loading: isSubmittingList }] = useSublistUploadMutation();
    const [isUploadDialogOpened, setIsUploadDialogOpened] = useState(false);
    const handleCancel = () => {
        navigate(-1);
    };
    const handleDownloadClicked = () => {
        download({
            variables: {
                subListId: id,
            },
        })
            .then(e => {
            var _a, _b;
            if ((_a = e.data) === null || _a === void 0 ? void 0 : _a.downloadSubList) {
                const baseURL = domainsConfig.get("serverUrl");
                window.open(baseURL + ((_b = e.data) === null || _b === void 0 ? void 0 : _b.downloadSubList), "_self");
            }
            else {
                reportError(t("Failed to download list"));
            }
        })
            .catch(reportGenericApiError(t("Failed to download list")));
    };
    const handleOpenImport = () => {
        setIsUploadDialogOpened(true);
    };
    const handleOnCloseImportDialog = () => {
        setIsUploadDialogOpened(false);
    };
    const handleOnUpload = file => {
        fetchImportSubList({
            refetchQueries: [SublistItemListDocument],
            variables: {
                file,
                subListId: id,
            },
            onCompleted: e => {
                var _a, _b, _c;
                if ((_b = (_a = e.uploadSubList) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
                    addToast(t("Failed to import list") + "\n" + formatMessageErrors((_c = e.uploadSubList) === null || _c === void 0 ? void 0 : _c.errors), {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    return;
                }
                handleOnCloseImportDialog();
                addToast(t("Imported Successfully"), { autoDismiss: true, appearance: "success" });
            },
            onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error import list"))(graphQLErrors),
        });
    };
    useEffect(() => {
        if (!id)
            return;
        fetchSubList({
            variables: {
                id,
            },
        });
    }, [id]);
    return {
        defaults,
        methods,
        errors,
        isSubmitting: isSubmittingUpdate || isSubmittingCreate,
        isDataLoading: loading,
        importData,
        isSubmittingList,
        isUploadDialogOpened,
        handleCancel,
        handleDownloadClicked,
        handleOpenImport,
        handleOnCloseImportDialog,
        handleOnUpload,
        handleSubmit,
        onSubmit,
    };
};
