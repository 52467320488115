import { replacePathParams } from "utils";

export const healthProgramRoute = "/health-program";
const healthProgramNewRoute = "new";
const healthProgramEditRoute = "edit/:id";
const healthProgramProgramLogicRoute = "program-logic/:id";

const healthProgramBenefitsRoute = "benefits";
const healthProgramBenefitsNewRoute = `${healthProgramBenefitsRoute}/new`;
const healthProgramBenefitsEditRoute = `${healthProgramBenefitsRoute}/edit/:id`;

const healthProgramExclusionRoute = "exclusion";
const healthProgramExclusionNewRoute = `${healthProgramExclusionRoute}/new`;
const healthProgramExclusionEditRoute = `${healthProgramExclusionRoute}/edit/:id`;

const healthProgramMembersRoute = ":programId/member-lists/:id/members";
const healthProgramMembersNewRoute = `${healthProgramMembersRoute}/new`;
const healthProgramMembersMembersRoute = `${healthProgramMembersRoute}/edit/:memberId`;

export const healthProgramPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthProgramRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthProgramNewRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
  },
  editPath: {
    route: healthProgramEditRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  programLogicPath: {
    route: healthProgramProgramLogicRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  benefitsPath: {
    route: healthProgramBenefitsRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
  },
  benefitsNewPath: {
    route: healthProgramBenefitsNewRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
  },
  benefitsEditPath: {
    route: healthProgramBenefitsEditRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  exclusionPath: {
    route: healthProgramExclusionRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
  },
  exclusionNewPath: {
    route: healthProgramExclusionNewRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
  },
  exclusionEditPath: {
    route: healthProgramExclusionEditRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  membersPath: {
    route: healthProgramMembersRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (programId: string, id: string) {
      return replacePathParams(this.fullPath, { programId, id });
    },
  },
  membersNewPath: {
    route: healthProgramMembersNewRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (programId: string, id: string) {
      return replacePathParams(this.fullPath, { programId, id });
    },
  },
  membersEditPath: {
    route: healthProgramMembersMembersRoute,
    get fullPath() {
      return `${healthProgramRoute}/${this.route}`;
    },
    fullPathWithParams: function (programId: string, id: string, memberId: string) {
      return replacePathParams(this.fullPath, { programId, id, memberId });
    },
  },
};
