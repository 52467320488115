import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Dialog, DialogActions, Grid, LinearProgress, Typography } from "@toolkit/ui";
import { UploadToCloudIcon, XlsIcon } from "./icons";
import { useUploadDialog } from "./useUploadDialog";
import { useUploadDialogStyle } from "./useUploadDialogStyle";
export const UploadDialog = props => {
    const { title, isOpen, subTitle, hasErrors, errorMessage, isDialogLoading, handleClose, handleResetError, onFileUploadedSuccessfully } = props;
    const { t } = useTranslation("domains");
    const { classes, theme } = useUploadDialogStyle();
    const { file, dragActive, customError, handleDrop, handleDrag, handleRemoveFileClick, handleUploadClick, handleUploadFile, handleCloseClick, } = useUploadDialog({ handleClose, handleResetError, onFileUploadedSuccessfully });
    return (_jsxs(Dialog, { BackdropProps: {
            className: classes.backDropStyle,
        }, classes: {
            container: classes.container,
        }, PaperProps: {
            className: classes.paperStyle,
            sx: {
                width: "40%",
            },
        }, open: isOpen, onClose: handleCloseClick, "aria-labelledby": 'alert-dialog-title', "aria-describedby": 'alert-dialog-description', children: [_jsxs(Box, { flex: 1, padding: 1, paddingLeft: 3, paddingRight: 3, children: [_jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.xxl, textAlign: 'center', children: title ? title : "No title" }), _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.md, textAlign: 'center', color: theme.palette.stale.main, children: subTitle ? subTitle : "No subtitle" }), _jsxs(Box, { onDragEnter: handleDrag, sx: { backgroundColor: dragActive ? theme.palette.background.default : theme.palette.common.white }, className: classes.box, children: [_jsx(UploadToCloudIcon, { width: 120, height: 90 }), _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.md, textAlign: 'center', color: theme.palette.stale.main, marginTop: 1, marginBottom: 1, children: t("Drag & Drop Your Files Here") }), _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.md, textAlign: 'center', marginBottom: 1, children: t("OR") }), _jsxs(Button, { variant: 'contained', component: 'label', children: [t("Browse File"), _jsx("input", { type: 'file', accept: '.xlsx', hidden: true, onChange: handleUploadFile })] }), dragActive && (_jsx("div", { className: classes.dragArea, onDragEnter: handleDrag, onDragLeave: handleDrag, onDragOver: handleDrag, onDrop: handleDrop }))] }), !!file && (_jsxs(Grid, { container: true, spacing: 1, marginTop: 2, children: [_jsx(Grid, { item: true, children: _jsx(XlsIcon, { width: 23, height: 29 }) }), _jsxs(Grid, { item: true, flex: 1, children: [_jsx(Typography, { flex: 1, fontSize: theme.mixins.fonts.fontSize.sm, paddingBottom: 0.5, children: t(file.name) }), isDialogLoading && (_jsx(LinearProgress, { color: 'success', classes: {
                                            root: classes.progressRoot,
                                        }, value: 0 }))] }), !isDialogLoading && (_jsx(Grid, { item: true, children: _jsx(Grid, { onClick: () => handleRemoveFileClick(file), children: _jsx(CustomIcon, { icon: 'icons8_cancel', color: theme.palette.gray.light }) }) }))] }))] }), customError && (_jsx(Box, { marginBottom: '20px', children: _jsxs(Typography, { sx: {
                        fontSize: theme.mixins.fonts.fontSize.md,
                        textAlign: "center",
                        color: theme.palette.secondary.main,
                    }, children: [t("Error"), ": ", customError] }) })), hasErrors && (_jsx(Box, { marginBottom: '20px', children: _jsxs(Typography, { sx: {
                        fontSize: theme.mixins.fonts.fontSize.md,
                        textAlign: "center",
                        color: theme.palette.secondary.main,
                    }, children: [t("Error"), ": ", t(errorMessage || "")] }) })), _jsxs(DialogActions, { className: classes.dialogContainerStyle, children: [_jsx(Button, { disabled: !file || isDialogLoading, onClick: handleUploadClick, children: t("Submit file") }), _jsx(Button, { variant: 'outlined', onClick: handleCloseClick, children: t("Close") })] })] }));
};
