import React, { FC, ReactElement } from "react";
import { Control, Controller } from "react-hook-form";
import { FormControlLabel } from "../../base/mui";
import { CustomToggleButton } from "../ToggleButton";

type ToggleButtonControllerProps = {
  defaultValue: boolean | undefined | null;
  name: string;
  control: Control<any>;
  label: string | ReactElement;
  // eslint-disable-next-line react/boolean-prop-naming
  disabled?: boolean;
};
export const ToggleButtonController: FC<ToggleButtonControllerProps> = ({ defaultValue, name, control, label, disabled = false }) => {
  return (
    <Controller
      name={name}
      defaultValue={Boolean(defaultValue)}
      control={control}
      render={({ field: { onChange: handleChange, value } }) => (
        <FormControlLabel
          control={<CustomToggleButton disabled={disabled} onChange={e => handleChange(e.target.checked)} checked={value} />}
          label={label}
        />
      )}
    />
  );
};

ToggleButtonController.defaultProps = {
  disabled: false,
};
