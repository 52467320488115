import React, { FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { ToastProvider, ToastProviderProps } from "react-toast-notifications";
import { SmallAlert } from "../components";
import { CustomToastContainer } from "./ToastContainer";
import { useAddToast } from "../hooks";
import { set } from "lodash";
import { pickLocalizedValue } from "@toolkit/i18n";

type ToastHackRef = ReturnType<typeof useAddToast>;

const ToastHack = forwardRef((_, ref: ForwardedRef<ToastHackRef>) => {
  const addToastResp = useAddToast();
  useImperativeHandle(ref, () => ({ ...addToastResp }), [addToastResp]);
  return <></>;
});

export const AppToastProvider = ({ children, ...props }: ToastProviderProps): ReturnType<FC<ToastProviderProps>> => {
  const toastHackRef = useRef<ToastHackRef>(null);

  useEffect(() => {
    set(AppToastProvider, "addSuccessToast", toastHackRef.current?.succeeded);
    set(AppToastProvider, "addFailedToast", toastHackRef.current?.failed);
  }, []);

  return (
    <ToastProvider
      placement={pickLocalizedValue("top-right", "top-left")}
      components={{ Toast: SmallAlert, ToastContainer: CustomToastContainer }}
      {...props}
    >
      <ToastHack ref={toastHackRef} />
      {children}
    </ToastProvider>
  );
};

AppToastProvider.addSuccessToast = (_msg: string) => void _msg;
AppToastProvider.addFailedToast = (_msg: string, field?: any) => void (0 + _msg + field);
