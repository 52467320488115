import { TemplateFieldType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const templateFieldTypeOptionsMap = {
    [TemplateFieldType.Boolean]: {
        key: TemplateFieldType.Boolean,
        get label() {
            return i18n.t("Boolean", { ns: "domains" });
        },
        value: TemplateFieldType.Boolean,
    },
    [TemplateFieldType.Number]: {
        key: TemplateFieldType.Number,
        get label() {
            return i18n.t("Number", { ns: "domains" });
        },
        value: TemplateFieldType.Number,
    },
    [TemplateFieldType.String]: {
        key: TemplateFieldType.String,
        get label() {
            return i18n.t("String", { ns: "domains" });
        },
        value: TemplateFieldType.String,
    },
    [TemplateFieldType.List]: {
        key: TemplateFieldType.List,
        get label() {
            return i18n.t("List", { ns: "domains" });
        },
        value: TemplateFieldType.List,
    },
    [TemplateFieldType.Date]: {
        key: TemplateFieldType.Date,
        get label() {
            return i18n.t("Date", { ns: "domains" });
        },
        value: TemplateFieldType.Date,
    },
    [TemplateFieldType.AudioFile]: {
        key: TemplateFieldType.AudioFile,
        get label() {
            return i18n.t("Audio File", { ns: "domains" });
        },
        value: TemplateFieldType.AudioFile,
    },
    [TemplateFieldType.VideoFile]: {
        key: TemplateFieldType.VideoFile,
        get label() {
            return i18n.t("Video File", { ns: "domains" });
        },
        value: TemplateFieldType.VideoFile,
    },
    [TemplateFieldType.NumericList]: {
        key: TemplateFieldType.NumericList,
        get label() {
            return i18n.t("Numeric List", { ns: "domains" });
        },
        value: TemplateFieldType.NumericList,
    },
};
export const templateFieldTypeOptions = Object.values(templateFieldTypeOptionsMap);
