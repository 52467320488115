import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const MedicalFormActivateDocument = gql `
    mutation MedicalFormActivate($activateMedicalFormId: ID!) {
  activateMedicalForm(id: $activateMedicalFormId) {
    active
    errors {
      errorType
      field
      message
    }
  }
}
    `;
/**
 * __useMedicalFormActivateMutation__
 *
 * To run a mutation, you first call `useMedicalFormActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalFormActivateMutation, { data, loading, error }] = useMedicalFormActivateMutation({
 *   variables: {
 *      activateMedicalFormId: // value for 'activateMedicalFormId'
 *   },
 * });
 */
export function useMedicalFormActivateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MedicalFormActivateDocument, options);
}
