import { makeStyles } from "@toolkit/ui";
export const useStyles = makeStyles()(theme => ({
    container: {
        height: "75%",
    },
    backDropStyle: {
        backgroundColor: theme.palette.common.black,
        opacity: 0.5,
    },
    paperStyle: {
        minHeight: 281,
        borderRadius: 15,
    },
    dialogContainerStyle: {
    // boxShadow: theme.mixins.shadows.sm,
    },
    dialogContentContainer: { overflowY: "initial" },
    componentRoot: {
        height: 50,
        borderRadius: 10,
    },
}));
