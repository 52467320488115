import { makeStyles } from "@toolkit/ui";

export const useWorkingHoursStyles = makeStyles()(theme => ({
  input: {},
  hiddenInput: {
    visibility: "hidden",
  },
  accordionSummaryRoot: {
    justifyContent: "space-between",
  },
  accordionSummaryContent: {
    maxWidth: "90%",
  },
  dialogContent: {
    minHeight: "65vh",
    [theme.breakpoints.down("lg")]: {
      minHeight: "85vh",
    },
  },
}));
