import { MarketplaceDiscountType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const marketplaceDiscountTypeOptionsMap = {
    [MarketplaceDiscountType.Fixed]: {
        key: MarketplaceDiscountType.Fixed,
        get label() {
            return i18n.t("Fixed", { ns: "domains" });
        },
        value: MarketplaceDiscountType.Fixed,
    },
    [MarketplaceDiscountType.Percentage]: {
        key: MarketplaceDiscountType.Percentage,
        get label() {
            return i18n.t("Percentage", { ns: "domains" });
        },
        value: MarketplaceDiscountType.Percentage,
    },
};
export const marketplaceDiscountTypeOptions = Object.values(marketplaceDiscountTypeOptionsMap);
