import { H_EntityError } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formButtonTitle } from "shared/utils";
import { healthProgramMemberProps } from "../types";
import { ContactInformation } from "./ContactInformation";
import { GeneralInformation } from "./GeneralInformation";
import { HealthProgramMemberLists } from "./HealthProgramMemberLists";
import { InsuranceInformation } from "./InsuranceInformation";

export const HealthProgramMemberForm: FC<healthProgramMemberProps> = props => {
  const { defaultValue, isLoading, showMemberListSection, errors, onDone, isSubmitting } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { ...methods } = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = data => {
    onDone(data);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData title={t("General Information")}>
                <GeneralInformation errors={errors} defaultValue={defaultValue} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData title={t("Insurance Information")}>
                <InsuranceInformation errors={errors} defaultValue={defaultValue} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData title={t("Contact Information")}>
                <ContactInformation errors={errors} defaultValue={defaultValue} />
              </FormCard>
            </Grid>
            {showMemberListSection && (
              <Grid item xs={12}>
                <FormCard loading={isLoading} doYouHaveData title={t("Member Lists")}>
                  <HealthProgramMemberLists errors={errors as H_EntityError[]} memberId={defaultValue?.id!} />
                </FormCard>
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
