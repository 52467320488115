import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const userAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    fullName: z.string(),
}));
export const UserAutocompleteSchemas = {
    userAutocomplete,
    userAutocompleteOptional: userAutocomplete.nullable().optional(),
};
