import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";

type ValueWithLabelProps = {
  label: string;
  value?: string;
};
export const ValueWithLabel: FC<ValueWithLabelProps> = ({ value, label }) => (
  <Box>
    <Typography fontWeight='bold' fontSize='14px'>
      {label}:
    </Typography>
    <Typography fontSize='14px'>{value}</Typography>
  </Box>
);
