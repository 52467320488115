import { Components, Theme } from "@mui/material/styles";

export const MuiIconButtonOverride: Components<Theme>["MuiIconButton"] = {
  styleOverrides: {
    root: {
      borderRadius: 10,
      marginRight: "-4px",
      "&.Mui-disabled": {
        opacity: "50%",
      },
    },
  },
};
