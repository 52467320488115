export const commonColors = {
  // Raven
  BackgroundColor: "#f7f8f9",
  Transparent: "transparent",
  TransparentBlack: "#0000003d",

  TextPrimary: "#25287f",
  // common
  White: "#ffffff",
  Black: "#000000",
};
