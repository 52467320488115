import { SimplePaletteColorOptions } from "../../../base/mui";

export const roseBudCherry: SimplePaletteColorOptions = {
  // Rose Bud Cherry
  main: "#913263",
  50: "#fbf3f7",
  100: "#f7e7ef",
  200: "#efd0e0",
  300: "#e7b8d0",
  400: "#dfa1c1",
  500: "#d789b1",
  600: "#cf72a2",
  700: "#c75a92",
  800: "#be4383",
  900: "#a83973",
  1100: "#822c59",
  1200: "#74284f",
  1300: "#652345",
  1400: "#571e3b",
  1500: "#481931",
  1600: "#3a1427",
  1700: "#2b0f1e",
  1800: "#1d0a14",
  1900: "#0e050a",
};
