import { SystemCodeAutocomplete } from "@health/autocompletes";
import { User, AccountError, DoctorSeniorityEnum, CodeSystemCode } from "@health/queries/types";
import { BACKEND_DATE_FORMAT, combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, CustomDatePicker, FormPhoneInput, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DoctorInformationButtonGroup } from "./DoctorInformationButtonGroup.component";
import { DoctorLanguages } from "./DoctorLanguages.component";
import { DoctorSocialLink } from "./DoctorSocialLinks.component";

const seniority = [
  { name: "GP", value: DoctorSeniorityEnum.Gp },
  { name: "SP", value: DoctorSeniorityEnum.Sp },
  { name: "CP", value: DoctorSeniorityEnum.Cp },
];

export const DoctorInformation: FC<{ errors: AccountError[]; defaultValues?: User | undefined; isEdit: boolean }> = ({
  errors,
  defaultValues,
  isEdit,
}) => {
  const { t } = useTranslation("admin");
  const { register, setValue, formState, control } = useFormContext();

  const combinedErrors = combineErrors(formState.errors, errors);
  const seniorityDefaultData = seniority?.find(target => target?.value === defaultValues?.doctor?.seniority);
  const doctorSecondMobileNumber = "doctorInfo.secondMobileNumber";
  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const cityDefaultValue = defaultValues?.doctor?.specializations
      ? defaultValues?.doctor?.specializations?.map(item =>
          createAutocompleteOption(
            {
              id: item?.id!,
              code: item?.code!,
              display: item?.display!,
            },
            "code",
            "display"
          )
        ) || []
      : [];

    setValue("doctorInfo.specializations", cityDefaultValue);
    setValue(doctorSecondMobileNumber, defaultValues?.doctor?.secondMobileNumber || "");
  }, [defaultValues?.doctor, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Bio")}
          error={Boolean(combinedErrors?.doctorInfo?.bio?.message)}
          helperText={t(combinedErrors?.doctorInfo?.bio?.message)}
          defaultValue={defaultValues?.doctor?.bio || null}
          {...register("doctorInfo.bio", {
            required: getRequiredValidation(t, false),
          })}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <SystemCodeAutocomplete
          name={"doctorInfo.specializations"}
          label={t("Specializations")}
          multiple
          isCodeHidden
          filter={{ codeSystemCode: CodeSystemCode.Speciality }}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            name: "doctorInfo.seniority",
            control: control,
            rules: { required: true },
            defaultValue: seniorityDefaultData || null,
          }}
          TextFieldProps={{
            error: Boolean(combinedErrors?.doctorInfo?.seniority),
            helperText: combinedErrors?.doctorInfo?.seniority && t("Required"),
            label: t("Seniority"),
          }}
          getOptionLabel={o => o?.name}
          options={seniority || []}
        />
      </Grid>

      <DoctorLanguages errors={errors} defaultValues={defaultValues} />

      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Years of Experience")}
          placeholder={t("Years of Experience")}
          defaultValue={defaultValues?.doctor?.yearsOfExperience || null}
          fullWidth
          error={Boolean(combinedErrors?.doctorInfo?.yearsOfExperience?.message)}
          helperText={t(combinedErrors?.doctorInfo?.yearsOfExperience?.message)}
          {...register("doctorInfo.yearsOfExperience", {
            required: getRequiredValidation(t, false),
            validate: value => {
              const min = 0,
                max = 100;
              return value === "" || (parseFloat(value) >= min && parseFloat(value) <= max)
                ? true
                : t("Years of experience should be between {{min}} and {{max}}", { min, max });
            },
          })}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register(doctorSecondMobileNumber, {
            required: getRequiredValidation(t, false),
          })}
          label={t("Second Mobile Number")}
          error={Boolean(combinedErrors?.[doctorSecondMobileNumber]?.message)}
          helperText={t(combinedErrors?.[doctorSecondMobileNumber]?.message)}
          fullWidth
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          defaultValue={defaultValues?.dateOfBirth}
          label={t("Date Of Birth")}
          placeholder={t("Date Of Birth")}
          {...register("dateOfBirth", {
            validate: value => {
              const birthDate = new Date(value);
              const currentDate = new Date();
              const age = currentDate.getFullYear() - birthDate.getFullYear();

              if (age < 18) {
                return t("Must be at least 18 years old");
              }

              return true;
            },
          })}
          error={Boolean(combinedErrors?.dateOfBirth)}
          helperText={t(combinedErrors?.dateOfBirth?.message)}
          format={BACKEND_DATE_FORMAT}
        />
      </Grid>

      <DoctorSocialLink errors={errors} defaultValues={defaultValues} />
      <DoctorInformationButtonGroup defaultValues={defaultValues} isEdit={isEdit} />
    </Grid>
  );
};
