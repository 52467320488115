import { Components, Theme } from "@mui/material/styles";

export const MuiSliderOverride: Components<Theme>["MuiSlider"] = {
  styleOverrides: {
    root: {
      color: "#6b7280",
      opacity: 1,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      boxShadow: "0px 3px 6px #0000004D",
    },
    valueLabel: ({ theme }) => ({
      backgroundColor: "#E6EFF8",
      height: 40,
      color: theme.palette.primary.main,
    }),
    rail: {
      background: "#A1B2C5",
      opacity: 1,
    },
  },
};
