import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const SurveyIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19.252' height='24.318' viewBox='0 0 19.252 24.318' {...props}>
      <path
        id='icons8-survey'
        d='M15.626,0a2.011,2.011,0,0,0-1.932,1.52H7.52A1.528,1.528,0,0,0,6,3.04V22.8a1.528,1.528,0,0,0,1.52,1.52H23.732a1.528,1.528,0,0,0,1.52-1.52V3.04a1.528,1.528,0,0,0-1.52-1.52H17.558A2.011,2.011,0,0,0,15.626,0Zm0,1.013a1,1,0,0,1,1.013,1.013.508.508,0,0,0,.507.507h1.52V3.546h-6.08V2.533h1.52a.508.508,0,0,0,.507-.507A1,1,0,0,1,15.626,1.013ZM7.52,2.533h4.053v1.52a.508.508,0,0,0,.507.507h7.093a.508.508,0,0,0,.507-.507V2.533h4.053a.5.5,0,0,1,.507.507V22.8a.5.5,0,0,1-.507.507H7.52a.5.5,0,0,1-.507-.507V3.04A.5.5,0,0,1,7.52,2.533ZM21.2,8.581a.51.51,0,0,0-.079.016l-.032.016a.507.507,0,0,0-.317.222l-1.678,2.517-1.077-1.077a.509.509,0,0,0-.412-.158.592.592,0,0,0-.063.016.5.5,0,0,0-.4.356.509.509,0,0,0,.142.515l1.457,1.457a.239.239,0,0,0,.032.032l.032.032.016.016.016.016.016.016a.63.63,0,0,0,.063.032l.032.016.032.016H19l.032.016h.016l.032.016h.063a.761.761,0,0,0,.095,0h.016a.544.544,0,0,0,.111-.032l.032-.016a.445.445,0,0,0,.047-.016.239.239,0,0,0,.032-.032l.032-.016a.27.27,0,0,0,.047-.047c.012-.016.022-.032.032-.047l.016-.016.016-.016a.445.445,0,0,0,.016-.047L21.626,9.4a.507.507,0,0,0-.427-.823ZM9.911,10.133a.512.512,0,1,0,.142,1.013h5.066a.507.507,0,1,0,0-1.013H9.911ZM21.2,15.167a.51.51,0,0,0-.079.016l-.032.016a.507.507,0,0,0-.317.222l-1.678,2.517-1.077-1.077a.509.509,0,0,0-.412-.158.593.593,0,0,0-.063.016.5.5,0,0,0-.4.356.509.509,0,0,0,.142.515l1.457,1.457a.239.239,0,0,0,.032.032l.032.032.016.016.016.016.016.016a.63.63,0,0,0,.063.032l.032.016.032.016H19l.032.016h.016l.032.016h.063a.761.761,0,0,0,.095,0h.016a.545.545,0,0,0,.111-.032l.032-.016a.445.445,0,0,0,.047-.016.239.239,0,0,0,.032-.032l.032-.016a.27.27,0,0,0,.047-.047c.012-.016.022-.032.032-.047l.016-.016.016-.016a.445.445,0,0,0,.016-.047l1.995-2.976a.507.507,0,0,0-.427-.823ZM9.911,16.719a.512.512,0,1,0,.142,1.013h5.066a.507.507,0,1,0,0-1.013H9.911Z'
        transform='translate(-6)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
