import { H_EntityError, HealthProgramNetwork } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  networkFieldBreadcrumb,
  networkListBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthProgramNetworkUpdateMutation, useHealthProgramNetworksQuery } from "../gql";
import { HealthProgramNetworkForm } from "./HealthProgramNetworkForm";

export const HealthProgramNetworkEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  const { data, loading } = useHealthProgramNetworksQuery({
    skip: !params?.id,
    variables: {
      first: 10,
      filter: {
        ids: [params?.id!],
      },
    },
  });

  const healthProgramNetworks = data?.healthProgramNetworks?.edges.find(item => item?.node?.id === params?.id)!;
  const [healthProgramNetworkUpdate, { loading: isSubmitting }] = useHealthProgramNetworkUpdateMutation({
    onCompleted: input => {
      const entityErrors = input?.healthProgramNetworkUpdate?.entityErrors!;
      if (entityErrors?.length === 0) {
        succeeded(t("Network Updated Successfully"));
        navigate(-1);
      } else {
        setError(entityErrors);
        failed(formatMessageErrors(entityErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });

  const handleSubmit = input => {
    delete input?.payerId;
    healthProgramNetworkUpdate({
      variables: {
        id: healthProgramNetworks?.node?.id!,
        input,
      },
    });
  };

  useEffect(() => {
    if (healthProgramNetworks?.node?.name) {
      setBreadCrumb({
        title: networkTitleBreadcrumb(t),
        values: [networkListBreadcrumb(t), networkFieldBreadcrumb(healthProgramNetworks?.node?.name)],
      });
    }
  }, [healthProgramNetworks?.node?.name]);

  return (
    <HealthProgramNetworkForm
      isLoading={loading}
      errors={errors}
      defaultValue={healthProgramNetworks?.node as Partial<HealthProgramNetwork>}
      onDone={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
