import React from "react";
import { CustomerIcon, MenuIcon, NotificationIcon, SpeechIcon } from "../../icons";
import { AdminHeaderItemProps } from "../Header";

export const leftIcons: AdminHeaderItemProps[] = [
  {
    id: "menu",
    name: "",
    icon: <MenuIcon fill='inherit' />,
  },
];
export const rightIcons: AdminHeaderItemProps[] = [
  {
    id: "notifications",
    name: "",
    icon: <NotificationIcon />,
  },
  {
    id: "customer",
    name: "",
    icon: <CustomerIcon />,
  },
  {
    id: "messages",
    name: "",
    icon: <SpeechIcon />,
  },
];
