import { DecisionMakerOrderDirection, SubListItemSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useSublistItemListColumnsContainer = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                accessor: ({ display }) => display,
                header: t("Display name"),
                key: "displayName",
                isSortable: true,
                sortDirection: DecisionMakerOrderDirection.Asc,
                sortColumnEnum: SubListItemSortField.Display,
            },
            {
                accessor: ({ code }) => code,
                isSortable: true,
                header: t("Code"),
                key: "code",
                sortDirection: undefined,
                sortColumnEnum: SubListItemSortField.Code,
            },
        ];
    }, []);
};
