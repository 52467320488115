import { useFileUpload } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
const FileExtNotSupportedText = "File extension is not supported,please upload only xlsx format";
const MaxFileSizeMessage = "Please import a file with less than {{maxSize}} mb";
const maxFileSyzeBytes = 20971520;
const One_MB = Math.pow(2, 20);
export const useUploadDialog = (props) => {
    var _a;
    const { handleClose, handleResetError, onFileUploadedSuccessfully: handleOnFileUploadedSuccessfully } = props;
    const { t } = useTranslation("domains");
    // TODO - fix this
    const auth = useAuth();
    const token = (_a = auth === null || auth === void 0 ? void 0 : auth.user) === null || _a === void 0 ? void 0 : _a.access_token;
    const [customError, setCustomError] = useState("");
    const [dragActive, setDragActive] = React.useState(false);
    const { files, fetchUploadFile, clearAllFiles, removeFile, setFiles, handleDragDropEvent } = useFileUpload({
        token: token,
    });
    const handleUploadFile = (event) => {
        var _a, _b;
        const selectedFile = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        handleFileSelected(selectedFile, event);
    };
    const handleFileSelected = (selectedFile, e) => {
        if (!selectedFile)
            return;
        const errors = getFileValidationErrors(selectedFile);
        if (!isFileValid(errors)) {
            setCustomError(errors[0].message);
            return;
        }
        setCustomError("");
        setFiles(e, "a");
    };
    const isFileValid = (fileErrors) => {
        return fileErrors.length == 0;
    };
    const getFileValidationErrors = (file) => {
        const issues = [];
        if ((file === null || file === void 0 ? void 0 : file.size) > maxFileSyzeBytes) {
            issues.push({
                type: "SizeTooBig",
                message: t(MaxFileSizeMessage, { maxSize: Math.floor(maxFileSyzeBytes / One_MB) }),
            });
        }
        const fileName = file === null || file === void 0 ? void 0 : file.name;
        const rE = /(\.xlsx)$/i;
        if (!rE.exec(fileName)) {
            issues.push({
                type: "invalidExtension",
                message: t(FileExtNotSupportedText),
            });
        }
        return issues;
    };
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        }
        else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function (e) {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        handleDragDropEvent(e);
        const selectedFile = (_a = e.dataTransfer.files) === null || _a === void 0 ? void 0 : _a[0];
        handleFileSelected(selectedFile, e);
    };
    const handleRemoveFileClick = file => {
        handleResetError === null || handleResetError === void 0 ? void 0 : handleResetError();
        removeFile(file);
        setCustomError("");
    };
    const handleCloseClick = () => {
        clearAllFiles();
        handleClose();
        setCustomError("");
    };
    const handleUploadClick = () => {
        fetchUploadFile({
            onComplete: fileName => {
                handleOnFileUploadedSuccessfully(fileName);
            },
            onProgress: progress => {
                console.log(progress, "progress");
            },
        });
    };
    return {
        file: files[0],
        dragActive,
        customError,
        handleDrop,
        handleDrag,
        handleClose,
        handleUploadFile,
        handleCloseClick,
        handleUploadClick,
        handleRemoveFileClick,
    };
};
