import { SimplePaletteColorOptions } from "@mui/material/styles";

export const orange: SimplePaletteColorOptions = {
  // Tango
  main: "#F88732",
  50: "#fdf8f5",
  100: "#fbf0ec",
  200: "#f7e2d9",
  300: "#f2d3c5",
  400: "#eec4b2",
  500: "#eab59f",
  600: "#e6a78c",
  700: "#e19878",
  800: "#dd8965",
  900: "#d97a52",
  1100: "#cb5c2d",
  1200: "#b55228",
  1300: "#9e4823",
  1400: "#873d1e",
  1500: "#713319",
  1600: "#5a2914",
  1700: "#441f0f",
  1800: "#2d140a",
  1900: "#170a05",
};
