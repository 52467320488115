import { VendorOrderField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { useVendorDeleteMutation } from "gql/mutations";
import { useAdminVendorsQuery } from "gql/queries";
import { providersTitleBreadcrumb } from "pages/ProvidersManagement/constants/providers-management-breadcrumbs";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useVendorsColumns } from "../components/columns";

const VendorsList = () => {
  const columns = useVendorsColumns();
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEdit = ({ id }) => {
    navigate(providersManagementPaths.editPath.fullPathWithParams(id));
  };

  const handleAddNew = () => {
    navigate(providersManagementPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({
      title: providersTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='vendors'
      columns={columns}
      query={useAdminVendorsQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEdit,
      }}
      deleteItemProps={{
        name: "vendor",
        entityTypeName: "Vendor",
        useDeleteMutation: useVendorDeleteMutation,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: VendorOrderField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};

export default VendorsList;
