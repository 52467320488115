import React, { FC } from "react";
import BranchForm from "../BranchForm.component";
import { useBranchEditPageHook } from "./useBranchEditPageHook";

const EditBranch: FC = () => {
  const { branch, errors, loading, handleUpdateBranch, isSubmitting } = useBranchEditPageHook();
  return (
    <BranchForm isEditMode loading={loading} branch={branch} onSubmit={handleUpdateBranch} errors={errors} isSubmitting={isSubmitting} />
  );
};

export default EditBranch;
