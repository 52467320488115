/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc, PermissionGroupErrorFragmentFragmentDoc } from '../../fragments/__generated__/permissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePermissionGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePermissionGroupMutation = { __typename?: 'Mutation', permissionGroupDelete?: { __typename?: 'PermissionGroupDelete', group?: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', code: Types.PermissionGroupErrorCode, field?: string | null, message?: string | null }> } | null };


export const DeletePermissionGroupDocument = gql`
    mutation deletePermissionGroup($id: ID!) {
  permissionGroupDelete(id: $id) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorFragmentFragmentDoc}`;
export type DeletePermissionGroupMutationFn = Apollo.MutationFunction<DeletePermissionGroupMutation, DeletePermissionGroupMutationVariables>;

/**
 * __useDeletePermissionGroupMutation__
 *
 * To run a mutation, you first call `useDeletePermissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionGroupMutation, { data, loading, error }] = useDeletePermissionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionGroupMutation, DeletePermissionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermissionGroupMutation, DeletePermissionGroupMutationVariables>(DeletePermissionGroupDocument, options);
      }
export type DeletePermissionGroupMutationHookResult = ReturnType<typeof useDeletePermissionGroupMutation>;
export type DeletePermissionGroupMutationResult = Apollo.MutationResult<DeletePermissionGroupMutation>;
export type DeletePermissionGroupMutationOptions = Apollo.BaseMutationOptions<DeletePermissionGroupMutation, DeletePermissionGroupMutationVariables>;