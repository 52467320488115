export const dashboardRoute = "/dashboard";

export const dashboardPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${dashboardRoute}${this.route}`;
    },
  },
};
