import { makeStyles } from "tss-react/mui";

export const useStatusStyles = makeStyles()(theme => ({
  text: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  blue: {
    color: theme.palette.primary.light,
  },
  preparing: {
    color: theme.palette.yellow.main,
  },
  pending: {
    color: theme.palette.success.main,
  },
}));
