import { TFunction } from "@toolkit/i18n";

export const providerUsersTitleBreadcrumb = (t: TFunction) => {
  return t("Provider Users", { ns: "admin" });
};

export const providerUserListBreadcrumb = (t: TFunction) => {
  return {
    id: "provider-user-list",
    name: t("Provider Users", { ns: "admin" }),
  };
};

export const providerUserFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "provider-user-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
