import { toggleGridBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormControlLabel, Grid } from "@toolkit/ui";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";

export const GeneralToggleSettingsGroups: React.FC<ISiteSetting> = ({ siteSettings }) => {
  const { t } = useTranslation("admin");
  const { control } = useFormContext();

  return (
    <Grid container rowSpacing={0} sx={{ marginTop: 1 }}>
      <Grid item {...toggleGridBreakPoints}>
        <Controller
          name='multiplePharmaciesDelivery'
          defaultValue={siteSettings ? siteSettings?.multiplePharmaciesDelivery : true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Multiple Pharmacies Delivery")}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
