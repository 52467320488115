import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionConditionGuidedCareTemplatesAutocompleteDocument = gql `
    query DecisionConditionGuidedCareTemplatesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramTemplateFilterInput, $sortBy: HealthProgramTemplateSortingInput) {
  healthProgramTemplates(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        display: name
      }
    }
  }
}
    `;
/**
 * __useDecisionConditionGuidedCareTemplatesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionConditionGuidedCareTemplatesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionConditionGuidedCareTemplatesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionConditionGuidedCareTemplatesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDecisionConditionGuidedCareTemplatesAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionConditionGuidedCareTemplatesAutocompleteDocument, options);
}
export function useDecisionConditionGuidedCareTemplatesAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionConditionGuidedCareTemplatesAutocompleteDocument, options);
}
