/* eslint-disable max-lines */
import {
  CoverageByType,
  H_OrderDirection,
  HealthProgramMembershipRequest,
  HealthProgramMembershipRequestSortingField,
  RequestStatus,
} from "@health/queries/types";
import { formatUserNameFull, toPascalCase, toTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { usePatientsQuery } from "../gql";
import { ApproveInsuranceCardDialog } from "./components/ApproveInsuranceCardDialog";
import RejectInsuranceCardDialog from "./components/RejectInsuranceCardDialog";

export type InsuranceApprovalsColumnTypes = CustomTableColumnProps<HealthProgramMembershipRequest>[];

const formatDate = (createdDate?: null | string, i18n?: any) =>
  createdDate ? moment(createdDate).locale(i18n.language).format("DD MMM YYYY") : "";

export const useInsuranceCardsListColumns = (): InsuranceApprovalsColumnTypes => {
  const { t, i18n } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "insuranceId",
        header: t("Insurance Id"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.InsuranceId,
        accessor: row => row?.insuranceId,
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.Name,
        accessor: ({ user }) => <TruncateTypography text={user?.fullName!} />,
        filter: {
          type: "autocomplete",
          name: "patientId",
          accessor: "patients",
          query: usePatientsQuery,
          getOptionLabel: option => formatUserNameFull({ firstName: option?.firstName, lastName: option?.lastName }),
          getValueForBackend: item => item?.id,
          filterSearchKey: "firstName_Icontains",
        },
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <TruncateTypography text={t(toPascalCase(status?.toLowerCase()!))} />,
        filter: {
          type: "autocomplete",
          name: "status",
          options: Object.keys(RequestStatus)?.map(key => ({
            value: RequestStatus[key],
            label: key,
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "coverageBy",
        header: t("Coverage By"),
        filter: {
          type: "autocomplete",
          name: "coverageBys",
          options: Object.keys(CoverageByType).map(key => ({
            value: CoverageByType[key],
            label: toTitleCase(key),
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
        accessor: row => <Box sx={{ textAlign: "center" }}>{t(toTitleCase(row?.healthProgramMember?.coverageBy || "-"))}</Box>,
      },
      {
        key: "membershipStartFilterFrom",
        header: t("Membership Start Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.gte",
        },
      },
      {
        key: "membershipStartFilterTo",
        header: t("Membership Start Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.lte",
        },
      },
      {
        key: "membershipEndFilterFrom",
        header: t("Membership End Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.gte",
        },
      },
      {
        key: "membershipEndFilterTo",
        header: t("Membership End Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.lte",
        },
      },
      {
        key: "created",
        header: t("Created"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.Created,
        accessor: row => formatDate(row?.created, i18n),
      },
      {
        key: "membershipStartDate",
        header: t("Membership Start Date"),
        accessor: row => <Box sx={{ textAlign: "center" }}>{formatDate(row?.healthProgramMember?.membershipStart, i18n) || "-"}</Box>,
      },
      {
        key: "membershipEndDate",
        header: t("Membership End Date"),
        accessor: row => <Box sx={{ textAlign: "center" }}>{formatDate(row?.healthProgramMember?.membershipEnd, i18n) || "-"}</Box>,
      },
      {
        key: "approve",
        header: t("Approve"),
        accessor: row => <ApproveInsuranceCardDialog healthProgramMembershipRequest={row} />,
      },
      {
        key: "reject",
        header: t("Reject"),
        accessor: row => <RejectInsuranceCardDialog healthProgramMembershipRequest={row} />,
      },
      {
        key: "insuranceCompanyLicenseNumbers",
        header: t("Insurance Company License Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "insuranceCompanyLicenseNumbers",
          getValueForBackend: value => (value ? [value] : null),
        },
      },
    ];
  }, []);
};
