/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExclusionOptionUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.ExclusionOptionInput;
}>;


export type ExclusionOptionUpdateMutation = { __typename?: 'Mutation', exclusionOptionUpdate?: { __typename?: 'ExclusionOptionCRUD', healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null, exclusionOption?: { __typename?: 'ExclusionOption', id: string, name?: string | null, description?: string | null } | null } | null };


export const ExclusionOptionUpdateDocument = gql`
    mutation ExclusionOptionUpdate($id: ID!, $input: ExclusionOptionInput!) {
  exclusionOptionUpdate(id: $id, input: $input) {
    healthProgramErrors {
      field
      message
      code
    }
    exclusionOption {
      id
      name
      description
    }
  }
}
    `;
export type ExclusionOptionUpdateMutationFn = Apollo.MutationFunction<ExclusionOptionUpdateMutation, ExclusionOptionUpdateMutationVariables>;

/**
 * __useExclusionOptionUpdateMutation__
 *
 * To run a mutation, you first call `useExclusionOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExclusionOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exclusionOptionUpdateMutation, { data, loading, error }] = useExclusionOptionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExclusionOptionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ExclusionOptionUpdateMutation, ExclusionOptionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExclusionOptionUpdateMutation, ExclusionOptionUpdateMutationVariables>(ExclusionOptionUpdateDocument, options);
      }
export type ExclusionOptionUpdateMutationHookResult = ReturnType<typeof useExclusionOptionUpdateMutation>;
export type ExclusionOptionUpdateMutationResult = Apollo.MutationResult<ExclusionOptionUpdateMutation>;
export type ExclusionOptionUpdateMutationOptions = Apollo.BaseMutationOptions<ExclusionOptionUpdateMutation, ExclusionOptionUpdateMutationVariables>;