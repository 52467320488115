/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelSurveyMutationVariables = Types.Exact<{
  cancelSurveyId: Types.Scalars['ID'];
}>;


export type CancelSurveyMutation = { __typename?: 'Mutation', cancelSurvey?: { __typename?: 'Survey', id?: string | null } | null };


export const CancelSurveyDocument = gql`
    mutation CancelSurvey($cancelSurveyId: ID!) {
  cancelSurvey(id: $cancelSurveyId) {
    id
  }
}
    `;
export type CancelSurveyMutationFn = Apollo.MutationFunction<CancelSurveyMutation, CancelSurveyMutationVariables>;

/**
 * __useCancelSurveyMutation__
 *
 * To run a mutation, you first call `useCancelSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSurveyMutation, { data, loading, error }] = useCancelSurveyMutation({
 *   variables: {
 *      cancelSurveyId: // value for 'cancelSurveyId'
 *   },
 * });
 */
export function useCancelSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CancelSurveyMutation, CancelSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSurveyMutation, CancelSurveyMutationVariables>(CancelSurveyDocument, options);
      }
export type CancelSurveyMutationHookResult = ReturnType<typeof useCancelSurveyMutation>;
export type CancelSurveyMutationResult = Apollo.MutationResult<CancelSurveyMutation>;
export type CancelSurveyMutationOptions = Apollo.BaseMutationOptions<CancelSurveyMutation, CancelSurveyMutationVariables>;