import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  permissionsManagementFieldBreadcrumb,
  permissionsManagementGroupListBreadcrumb,
  permissionsManagementTitleBreadcrumb,
} from "pages/PermissionsManagement/constants/permissions-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PermissionGroupErrorFragmentFragment,
  UpdatePermissionGroupMutation,
  usePermissionGroupQuery,
  useUpdatePermissionGroupMutation,
} from "../../gql";

export const usePermissionGroupEditHook = () => {
  const { t } = useTranslation("admin");
  const params = useParams();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const [errors, setErrors] = React.useState<PermissionGroupErrorFragmentFragment[]>([]);
  const { failed, succeeded } = useAddToast();
  const [updatePermissionGroupMutation, { loading: isSubmitting }] = useUpdatePermissionGroupMutation({
    onCompleted: (updateData: UpdatePermissionGroupMutation) => {
      const mutationErrors = updateData?.permissionGroupUpdate?.permissionGroupErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        setErrors(mutationErrors);
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        succeeded(t("Permission Group Updated Successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Error updating permission group "));
    },
  });
  const { data, loading } = usePermissionGroupQuery({
    variables: {
      permissionGroupId: params?.id as string,
    },
    onError: () => {
      failed(t("Permission Group not found!"));
    },
  });

  const permission = data?.permissionGroup;

  const handleSubmit = inputData => {
    delete inputData?.groupConfiguration?.groupType;
    updatePermissionGroupMutation({
      variables: {
        permissionGroupUpdateId: params?.id as string,
        input: inputData,
      },
    });
  };

  useEffect(() => {
    if (permission?.name) {
      setBreadCrumb({
        title: permissionsManagementTitleBreadcrumb(t),
        values: [permissionsManagementGroupListBreadcrumb(t), permissionsManagementFieldBreadcrumb(permission?.name)],
      });
    }
  }, [permission?.name]);

  return {
    permission,
    loading,
    handleSubmit,
    errors,
    isSubmitting,
  };
};
