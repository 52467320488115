import { forwardRef, useImperativeHandle } from "react";
import { useGridContext } from "./GridContext";
import React from "react";

const GridContextRef = forwardRef((props, ref) => {
  const context = useGridContext();
  useImperativeHandle(ref, () => context);
  return <></>;
});

export default GridContextRef;
