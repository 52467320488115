import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SubListsAutocompleteDocument = gql `
    query SubListsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $sortBy: SubListSortingInput, $filter: SublistFilterInput) {
  getSubLists(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        display
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;
/**
 * __useSubListsAutocompleteQuery__
 *
 * To run a query within a React component, call `useSubListsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubListsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubListsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubListsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SubListsAutocompleteDocument, options);
}
export function useSubListsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SubListsAutocompleteDocument, options);
}
