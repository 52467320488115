/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerViewPreferenceSaveMutationVariables = Types.Exact<{
  input: Types.ConsumerViewPreferenceInput;
}>;


export type ConsumerViewPreferenceSaveMutation = { __typename?: 'Mutation', consumerViewPreferenceSave?: { __typename?: 'ConsumerViewPreferenceSave', consumerViewPreference?: { __typename?: 'ConsumerViewPreference', id: string, view: string, data?: any | null, user: { __typename?: 'User', id: string } } | null, consumerViewPreferenceErrors: Array<{ __typename?: 'ConsumerViewPreferenceError', field?: string | null, message?: string | null, code: Types.ConsumerViewPreferenceErrorCode }> } | null };


export const ConsumerViewPreferenceSaveDocument = gql`
    mutation consumerViewPreferenceSave($input: ConsumerViewPreferenceInput!) {
  consumerViewPreferenceSave(input: $input) {
    consumerViewPreference {
      id
      user {
        id
      }
      view
      data
    }
    consumerViewPreferenceErrors {
      field
      message
      code
    }
  }
}
    `;
export type ConsumerViewPreferenceSaveMutationFn = Apollo.MutationFunction<ConsumerViewPreferenceSaveMutation, ConsumerViewPreferenceSaveMutationVariables>;

/**
 * __useConsumerViewPreferenceSaveMutation__
 *
 * To run a mutation, you first call `useConsumerViewPreferenceSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerViewPreferenceSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerViewPreferenceSaveMutation, { data, loading, error }] = useConsumerViewPreferenceSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerViewPreferenceSaveMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerViewPreferenceSaveMutation, ConsumerViewPreferenceSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerViewPreferenceSaveMutation, ConsumerViewPreferenceSaveMutationVariables>(ConsumerViewPreferenceSaveDocument, options);
      }
export type ConsumerViewPreferenceSaveMutationHookResult = ReturnType<typeof useConsumerViewPreferenceSaveMutation>;
export type ConsumerViewPreferenceSaveMutationResult = Apollo.MutationResult<ConsumerViewPreferenceSaveMutation>;
export type ConsumerViewPreferenceSaveMutationOptions = Apollo.BaseMutationOptions<ConsumerViewPreferenceSaveMutation, ConsumerViewPreferenceSaveMutationVariables>;