import { isMobile } from "react-device-detect";
import { makeStyles } from "tss-react/mui";

export const useNotificationStyle = makeStyles()({
  paper: {
    fontSize: 13,
    width: isMobile ? "100%" : 382,
    height: isMobile ? "100%" : "inherit",
    marginTop: 15,
    borderRadius: "10px",
    "& .MuiList-padding": {
      padding: 0,
    },
    overflow: "auto",
    "&.MuiPaper-root": {
      maxHeight: "100%",
      maxWidth: "100%",
      margin: isMobile ? "18px" : 0,
    },
  },
});
