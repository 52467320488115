export const convertOptimaActionToFormValues = (optimaAction) => {
    return {
        action: optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.action,
        link: optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.link,
        summary: optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.summary,
        isActive: !!(optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.isActive),
    };
};
export const convertOptimaActionFormValuesToBackEndValues = (values) => {
    return {
        action: values === null || values === void 0 ? void 0 : values.action,
        link: values === null || values === void 0 ? void 0 : values.link,
        summary: values === null || values === void 0 ? void 0 : values.summary,
        isActive: values === null || values === void 0 ? void 0 : values.isActive,
    };
};
