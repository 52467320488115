/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { AdminVendorFragmentFragmentDoc } from '../../fragments/__generated__/Vendor';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVendorMutationVariables = Types.Exact<{
  vendor: Types.VendorAdminInput;
}>;


export type CreateVendorMutation = { __typename?: 'Mutation', vendorCreate?: { __typename?: 'VendorCreate', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, vendor?: { __typename?: 'Vendor', hasMultipleBranches: boolean, isActive: boolean, id: string, name: string, nameAr?: string | null, description?: string | null, logo?: string | null, deliveryPrice: number, backGroundImage?: string | null, commercialRegistrationNumber: string, ownerName: string, nationalId: string, contactMobileNumber?: string | null, contactPhoneNumber?: string | null, tradeName: string, taxLicenseNumber: string, isVip: boolean, enableVisitDetails: boolean, isIntegrated: boolean, notifyByEmail: boolean, created: any, deliveryMinFrom: number, deliveryMinTo: number, priceRange?: Types.PriceRangeEnum | null, type?: Types.VendorType | null, hasOwnDrivers: boolean, address?: { __typename: 'Address', id: string, area: string, areaAr: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, bankInfo?: { __typename?: 'VendorBankInfo', bankName?: string | null, accountNumber?: string | null, iban?: string | null, accountName?: string | null } | null, managersContactInfo?: { __typename?: 'VendorManagersContactInfo', id: string, generalManagerEmail?: string | null, purchasingManagerName?: string | null, purchasingManagerMobileNumber?: string | null, purchasingManagerEmail?: string | null, financialManagerName?: string | null, financialManagerEmail?: string | null, financialManagerMobileNumber?: string | null } | null } | null } | null };


export const CreateVendorDocument = gql`
    mutation CreateVendor($vendor: VendorAdminInput!) {
  vendorCreate(input: $vendor) {
    vendorErrors {
      field
      message
      code
    }
    vendor {
      hasMultipleBranches
      ...AdminVendorFragment
      isActive
    }
  }
}
    ${AdminVendorFragmentFragmentDoc}`;
export type CreateVendorMutationFn = Apollo.MutationFunction<CreateVendorMutation, CreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorMutation, CreateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVendorMutation, CreateVendorMutationVariables>(CreateVendorDocument, options);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<CreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<CreateVendorMutation, CreateVendorMutationVariables>;