import { Components, Theme } from "../../../base/mui";

export const MuiAutocompleteOverride: Components<Theme>["MuiAutocomplete"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiFilledInput-root.Mui-focused": {
        borderRadius: "10px 10px 0px 0px",
        borderColor: theme.palette.stale.main,
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "50%",
        padding: 0,
        transform: "translate(0, -50%)",
      },
      "& .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator": {
        height: "100%",
      },
      "& .MuiAutocomplete-popupIndicator": {
        height: "fit-content",
      },
      "&:hover .MuiAutocomplete-clearIndicator": {
        height: "100%",
      },
      "& .MuiInputBase-hiddenLabel": {
        paddingTop: 5,
        paddingBottom: 5,
      },
    }),
    input: {},
    endAdornment: {
      height: "auto",
      top: "auto",
    },
  },
};
