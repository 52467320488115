import { createPathDefinition } from "@toolkit/core";
import { healthPackagesRoute } from "pages/HealthPackages/constants/health-packages-path";

export const healthPackageCategoriesRoute = `${healthPackagesRoute}/healthPackageCategories`;

export const healthPackageCategoriesPaths = createPathDefinition(healthPackageCategoriesRoute, {
  main: "",
  new: "new",
  update: ":categoryId",
  healthPackageCategoryCategories: ":categoryId/sub-categories",
  newSubcategory: ":categoryId/new-subcategory",
});
