import { makeStyles } from "tss-react/mui";

export const useAlertStyle = makeStyles()(theme => ({
  alertTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "19px",
    width: "100%",
  },
  alertDescription: {
    overflow: "inherit !important",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: 493,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  alertText: {
    color: theme.palette.common.white,
    paddingInlineStart: "9px",
    height: "36px",
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  alertTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    maxWidth: "493px",
  },
  alertTextContent: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    maxWidth: "493px",
  },
  xButton: {
    marginTop: "0px",
    marginRight: "18px",
  },
}));
