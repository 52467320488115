import { TFunction } from "@toolkit/i18n";

import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ListViewIcon } from "@toolkit/ui";
import { payersPaths, payersRoute } from "./Constants/payersPaths";
import { PayersList } from "./List/payersList";
import { PayerNewPage } from "./form/new/PayerNewPage";
import { PayerEditPage } from "./form/edit/HealthParameterEditPage";

export const PayersRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "payers-routes",
    text: t("Payers", { ns: "admin" }),
    route: payersRoute,
    icon: <ListViewIcon />,
    subItems: [
      {
        id: "payers-list-route",
        text: t("Payers List", { ns: "admin" }),
        route: payersPaths.listPath.route,
        fullPath: payersPaths.listPath.fullPath,
        element: <PayersList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-fields-new-route",
        text: t("Payer New", { ns: "admin" }),
        route: payersPaths.newPath.route,
        fullPath: payersPaths.newPath.fullPath,
        element: <PayerNewPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: payersPaths.editPath.fullPath,
        route: payersPaths.editPath.route,
        fullPath: payersPaths.editPath.fullPath,
        text: t("Edit Payer", { ns: "admin" }),
        element: <PayerEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
    ],
  };
};
