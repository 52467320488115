import { AppTypes, Permission } from "@health/queries/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTransferList, EmptyDataIcon, FormCard, Grid, TextField, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { usePermissionsQuery } from "../../../gql";

export const PermissionGroupTransferListForm: FC<{ defaultValues; errors }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    setValue,
    register,
    watch,
    formState: { errors: formErrors },
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  const groupTypeValue: { name: string; value: string } = watch("groupConfiguration.groupType");

  const { data: permissionsData, loading } = usePermissionsQuery({
    skip: groupTypeValue?.value == AppTypes.Vendor,
  });
  const permissions = permissionsData?.permissions || [];

  const handleRightList = value => {
    setValue("rightChecked", value);
  };
  const defaultPermissionValue = defaultValues?.permissions as Permission | undefined;
  useEffect(() => {
    setValue("rightChecked", defaultPermissionValue);
  }, [JSON.stringify(defaultPermissionValue)]);
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12}>
        {(groupTypeValue?.value || defaultValues?.groupConfiguration?.groupType) === AppTypes.Vendor ? (
          <FormCard doYouHaveData={true} loading={false}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "50px" }}>
              <EmptyDataIcon />
              <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("There is no permissions")}</Typography>
            </Box>
          </FormCard>
        ) : (
          <>
            <TextField
              type='hidden'
              defaultValue={defaultValues?.rightChecked}
              {...register("rightChecked", {
                validate: value => {
                  if (!(value && value.length > 0)) return t("At least one permission should be selected");
                  return true;
                },
              })}
              InputProps={{ style: { display: "none" } }}
            />
            <CustomTransferList
              ChosenLabel={t("Selected Permissions")}
              ChoicesLabel={t("Permissions")}
              helperText={t(combinedErrors?.rightChecked?.message)}
              error={Boolean(combinedErrors?.rightChecked?.type)}
              row={groupTypeValue?.value == AppTypes.Vendor ? [] : permissions.map(item => ({ ...item, name: t(item?.name || "") }))}
              onRightList={handleRightList}
              defaultPermissions={defaultPermissionValue as Array<Permission> | any}
              loading={loading}
            />
          </>
        )}
      </Grid>
    </>
  );
};
