import { CityInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  citiesManagementFieldBreadcrumb,
  citiesManagementListBreadcrumb,
  citiesManagementTitleBreadcrumb,
} from "pages/CitiesManagement/constants/cities-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CityErrorsFragment, CityFragmentFragment, CityUpdateMutation, useCityUpdateMutation, useGetCityQuery } from "../gql";

export const useCitiesManagementEditFormPageHooks = () => {
  const params = useParams();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<CityErrorsFragment[]>([]);
  const [cityUpdateMutation, { loading: isSubmitting }] = useCityUpdateMutation({
    onCompleted: (updatedCity: CityUpdateMutation) => {
      const mutationErrors = updatedCity?.cityUpdate?.cityErrors as CityErrorsFragment[];
      if (mutationErrors && mutationErrors.length > 0) {
        setErrors(mutationErrors);
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        navigate(-1);
        succeeded(t("City updated successfully"));
      }
    },
  });
  const { data, loading: isCityDataLoading } = useGetCityQuery({
    variables: {
      cityId: params?.id as string,
    },
    onError: () => {
      failed(t("City not found!"));
    },
  });
  const city = data?.city as CityFragmentFragment;
  const handleSubmit = (requestInput: CityInput) => {
    cityUpdateMutation({
      variables: {
        cityUpdateId: params?.id as string,
        input: requestInput,
      },
    });
  };

  useEffect(() => {
    if (data?.city?.name) {
      setBreadCrumb({
        title: citiesManagementTitleBreadcrumb(),
        values: [citiesManagementListBreadcrumb(), citiesManagementFieldBreadcrumb(data.city.name)],
      });
    }
  }, [data?.city?.name]);

  return {
    errors,
    handleSubmit,
    isCityDataLoading,
    city,
    isSubmitting,
  };
};
