/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramsListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramFilterInput>;
}>;


export type HealthProgramsListQuery = { __typename?: 'Query', healthPrograms?: { __typename?: 'HealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramCountableEdge', node: { __typename?: 'HealthProgram', id: string, name?: string | null } }> } | null };


export const HealthProgramsListDocument = gql`
    query healthProgramsList($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramFilterInput) {
  healthPrograms(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useHealthProgramsListQuery__
 *
 * To run a query within a React component, call `useHealthProgramsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramsListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramsListQuery, HealthProgramsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramsListQuery, HealthProgramsListQueryVariables>(HealthProgramsListDocument, options);
      }
export function useHealthProgramsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramsListQuery, HealthProgramsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramsListQuery, HealthProgramsListQueryVariables>(HealthProgramsListDocument, options);
        }
export type HealthProgramsListQueryHookResult = ReturnType<typeof useHealthProgramsListQuery>;
export type HealthProgramsListLazyQueryHookResult = ReturnType<typeof useHealthProgramsListLazyQuery>;
export type HealthProgramsListQueryResult = Apollo.QueryResult<HealthProgramsListQuery, HealthProgramsListQueryVariables>;