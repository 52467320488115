/* eslint-disable max-lines */
import { formGirdSpacing, toggleGridBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, FormSwitch, Grid } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";

export const MarketplaceSettings: React.FC<ISiteSetting> = ({ loading, siteSettings }) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation("admin");
  useEffect(() => {
    setValue("marketPlacePharmacyFirst", siteSettings?.marketPlacePharmacyFirst);
  }, [siteSettings?.marketPlacePharmacyFirst, setValue]);
  return (
    <Grid item xs={12}>
      <FormCard title={t("Marketplace Settings")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...toggleGridBreakPoints}>
            <FormSwitch name='marketPlacePharmacyFirst' label={t("Pharmacy First")} />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
