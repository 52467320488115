import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useVendorUpdateMutation } from "gql/mutations";
import { FC, useState } from "react";

export const SupportOutpatientJourney: FC<{ id: string; supportOutpatientJourney: boolean }> = ({ id, supportOutpatientJourney }) => {
  const { t } = useTranslation("admin");
  const [supportsPatient, setSupportsPatient] = useState(supportOutpatientJourney);
  const { failed, succeeded } = useAddToast();
  const [vendorUpdate, { loading }] = useVendorUpdateMutation({
    onCompleted: data => {
      const errors = data?.vendorUpdate?.vendorErrors;
      if (errors?.length == 0) {
        setSupportsPatient(data?.vendorUpdate?.vendor?.supportOutpatientJourney as boolean);
        const message = !supportsPatient
          ? t("Health provider now supports outpatient journey")
          : t("Health provider now doesn't support outpatient journey");
        succeeded(t(message));
      } else {
        failed(t(formatMessageErrors(errors)));
      }
    },
    onError: () => {
      failed(t("Failed to update out patient journey"));
    },
  });
  const handleConfirmToggleActivation = () => {
    vendorUpdate({
      variables: {
        id: id,
        input: {
          supportOutpatientJourney: !supportsPatient,
        },
      },
    });
  };
  return <CustomToggleButton checked={supportsPatient} disabled={loading} onChange={handleConfirmToggleActivation} />;
};
