import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const systemCodeAutocomplete = createZodAutocompleteObject(z.object({
    code: z.string(),
    display: z.string(),
}));
export const SystemCodeAutocompleteSchemas = {
    systemCodeAutocomplete,
    systemCodeAutocompleteOptional: systemCodeAutocomplete.nullable().optional(),
};
