/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import React, { ReactNode, useMemo, useState } from "react";
import { Checkbox, SortDirection, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from "../../../base/mui";
import { MuiArrowDropDownIcon, MuiArrowDropUpIcon } from "../../../base/mui-icons";
import { TableRowsSelectionStatus } from "../constants";
import { useTableLayoutStyles } from "../styles/useTableLayoutStyles";
import { CustomTableColumnProps, TableLayoutProps } from "../types";
import { TableBodyLayout } from "./TableBodyLayout";
import { useTableSelection } from "./useTableSelection";

const TableLayout = <RowType extends object>({
  data,
  columns,
  isLoading,
  pageSize,
  isDeleteVisible,
  isEditVisible,
  extraActionsRenderer,
  title,
  isCardView,
  isRowDeletable,
  isRowEditable,
  extraHeaderComponent,
  onDeleteRow,
  onEditRow,
  onSortColumn,
  onRowClick: handleRowClick,
  selectionProps,
}: TableLayoutProps<RowType>) => {
  const { isSelectVisible = false, isRowSelectable, rowId, handleRowsSelectionStatusChange, getRowSelectionStatus } = selectionProps || {};
  const { classes } = useTableLayoutStyles();

  const columnsToRender = useMemo(
    () => columns.filter(({ isHidden, hideFromPreferencesAndTable }) => !(isHidden || hideFromPreferencesAndTable)),
    [columns]
  );
  const renderColumnHeader = (header: ReactNode) => {
    if (typeof header === "string") {
      return (
        <Typography component='span' className={classes.tableHeaderCell}>
          {header}
        </Typography>
      );
    }
    return header;
  };

  const handleDeleteRow = (row: RowType, index: number) => {
    onDeleteRow?.(row, index);
  };
  const handleEditRow = (event: React.MouseEvent<HTMLButtonElement>, row: RowType) => {
    event?.stopPropagation();
    onEditRow(row);
  };
  const [columnsSort, setColumnsSort] = useState<{ key: string; direction: SortDirection }>();
  const {
    onRowSelectionStatusChange: handleRowSelectionStatusChange,
    allRowsSelectionStatus,
    onAllCheckboxChange: handleAllCheckboxChange,
    rowsSelectionStatus,
  } = useTableSelection<RowType>({
    rows: data,
    isRowSelectable,
    isSelectVisible,
    rowId,
    handleRowsSelectionStatusChange,
    getRowSelectionStatus,
  });
  const nextSortDirection = (key: string) => {
    columnsSort?.direction === "asc" && (columnsSort?.key === key || !columnsSort?.key)
      ? setColumnsSort({ key, direction: "desc" })
      : setColumnsSort({ key, direction: "asc" });
  };

  const handleSortColumn = (column: CustomTableColumnProps<RowType>) => () => {
    onSortColumn?.(column);
    nextSortDirection(column?.key);
  };

  return (
    <>
      <Table stickyHeader className={classes.table}>
        <TableHead className={classes.tableHead}>
          {extraHeaderComponent && <TableRow className={classes.tableRow}>{extraHeaderComponent}</TableRow>}
          <TableRow className={classes.tableRow}>
            {isSelectVisible && !isLoading && (
              <TableCell padding='checkbox' variant='head' className={classes.tableHeadCell} sx={{ padding: "0!important" }}>
                <Checkbox
                  checked={allRowsSelectionStatus !== TableRowsSelectionStatus.None}
                  color='primary'
                  indeterminate={allRowsSelectionStatus === TableRowsSelectionStatus.Some}
                  onChange={handleAllCheckboxChange}
                />
              </TableCell>
            )}
            {columnsToRender.length !== 0
              ? columnsToRender?.map((column, index) => (
                  <TableCell variant='head' key={index} className={classes.tableHeadCell}>
                    {column.isSortable ? (
                      <TableSortLabel
                        direction={column?.key === columnsSort?.key ? columnsSort?.direction || "asc" : "asc"}
                        active={column?.key === columnsSort?.key}
                        className={classes.tableSortLabel}
                        IconComponent={
                          column?.key === columnsSort?.key
                            ? columnsSort?.direction
                              ? MuiArrowDropUpIcon
                              : MuiArrowDropDownIcon
                            : MuiArrowDropDownIcon
                        }
                        onClick={handleSortColumn(column)}
                      >
                        {renderColumnHeader(column.header)}
                      </TableSortLabel>
                    ) : (
                      renderColumnHeader(column.header)
                    )}
                  </TableCell>
                ))
              : Array(Math.floor(10))
                  .fill("")
                  .map((e, i) => <TableCell key={`t-head-cell-${i}`} className={classes.tableHeadCell} />)}

            {columnsToRender.length !== 0 && (isDeleteVisible || isEditVisible || extraActionsRenderer) && (
              <TableCell variant='head' className={classes.tableHeadCell} />
            )}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          <TableBodyLayout
            title={title}
            isCardView={isCardView}
            columnsToRender={columnsToRender}
            onEditRow={handleEditRow}
            onDeleteRow={handleDeleteRow}
            data={data}
            isDeleteVisible={!!isDeleteVisible}
            isEditVisible={isEditVisible}
            isLoading={!!isLoading}
            extraActionsRenderer={extraActionsRenderer}
            pageSize={pageSize}
            onRowClick={handleRowClick}
            isRowDeletable={isRowDeletable}
            isRowEditable={isRowEditable}
            onRowSelectionStatusChange={handleRowSelectionStatusChange}
            rowsSelectionStatus={rowsSelectionStatus}
            isSelectVisible={isSelectVisible}
          />
        </TableBody>
      </Table>
    </>
  );
};

export default TableLayout;
