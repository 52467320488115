import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(theme => ({
  dayText: {
    minWidth: 80,
  },
  day: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  },
  cardTitle: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: 600,
  },
  timeDivider: {},
  addIcon: {
    height: 21,
    width: 21,
    opacity: 1,
    cursor: "pointer",
  },
  deleteIcon: {
    height: 21,
    width: 21,
    opacity: 1,
    cursor: "pointer",
  },
  header: {
    background: theme.palette.gray.light,
    padding: "12px 19px",
    height: 45,
  },
}));
