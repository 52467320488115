import React, { FC } from "react";
import { Tooltip, Typography, TypographyProps, useTheme } from "../../base/mui";

export const TruncateTooltipTypography: FC<{ text: string } & TypographyProps> = ({ text, ...props }) => {
  const theme = useTheme();
  return (
    <Tooltip title={text}>
      <Typography
        sx={{
          maxWidth: 250,
          fontSize: theme.mixins.fonts.fontSize.sm,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        {...props}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
