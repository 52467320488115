import { useMemo, useState } from "react";
import { SelectionStatus } from "../../Table/constants";
import { RowSelectionStatus } from "../../Table/types";

export interface UseTableSelectionProps<RowType> {
  formatSelectedRow?: (row: RowType) => Partial<RowType>;
}
export const useGridSelection = <RowType,>({ formatSelectedRow }: UseTableSelectionProps<RowType>) => {
  const [selectedRows, setSelectedRows] = useState<Record<string, RowType | Partial<RowType>>>({});
  const _selectedRowsArray = useMemo<(RowType | Partial<RowType>)[]>(
    () =>
      Object.keys(selectedRows)
        ?.sort()
        .map(rowId => selectedRows[rowId]),
    [selectedRows]
  );
  const handleRowsSelectionStatusChange = (rowsSelectionStatus: Record<string, RowSelectionStatus<RowType>>) => {
    const _selectedRows = { ...selectedRows };
    Object.keys(rowsSelectionStatus).forEach(rowId => {
      if (rowsSelectionStatus[rowId].status === SelectionStatus.Selected) {
        _selectedRows[rowId] = formatSelectedRow?.(rowsSelectionStatus[rowId].row) || rowsSelectionStatus[rowId].row;
      } else {
        delete _selectedRows[rowId];
      }
      setSelectedRows(_selectedRows);
    });
  };
  const getRowSelectionStatus = (rowId: string) => {
    return selectedRows[rowId] ? SelectionStatus.Selected : SelectionStatus.NotSelected;
  };
  const onResetSelectedRows = () => {
    setSelectedRows({});
  };
  const onRemoveSelectedRow = (rowId: string) => {
    const _selectedRows = { ...selectedRows };
    delete _selectedRows[rowId];
    setSelectedRows(_selectedRows);
  };
  return {
    handleRowsSelectionStatusChange,
    getRowSelectionStatus,
    selectedRows: _selectedRowsArray,
    onResetSelectedRows,
    onRemoveSelectedRow,
  };
};
