import { H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  memberNewBreadcrumb,
  membersBreadcrumb,
  membersTitleBreadcrumb,
} from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { membersPaths } from "pages/Members/constants/members-paths";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useHealthProgramMemberCreateMutation } from "../gql";
import { HealthProgramMemberForm } from "./components/HealthProgramMemberForm";

export const HealthProgramMemberNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);
  const params = useParams();
  const location = useLocation();

  const id = params?.id;
  const { setBreadCrumb } = useBreadCrumbs();
  const showMemberListSection = location?.pathname === membersPaths.newPath.fullPath;

  const [healthProgramMemberCreate, { loading: isSubmitting }] = useHealthProgramMemberCreateMutation({
    onCompleted: data => {
      const healthProgramMemberListErrors = data?.healthProgramMemberCreate?.entityErrors!;
      if (healthProgramMemberListErrors?.length === 0) {
        succeeded(t("Member Created Successfully"));
        navigate(-1);
      } else {
        setError(healthProgramMemberListErrors);
        failed(t(formatMessageErrors(healthProgramMemberListErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t(message));
    },
  });

  const handleSubmit = data => {
    const { coverageBy, gender, payerId, healthProgramMemberLists, idType, nationality, ...rest } = data;
    healthProgramMemberCreate({
      variables: {
        input: {
          insuranceCompanyId: payerId?.id,
          coverageBy: coverageBy?.value,
          gender: gender?.value,
          healthProgramMemberLists: showMemberListSection ? healthProgramMemberLists?.map(item => item?.id) : [id],
          idType: idType?.value,
          nationality: nationality?.value?.code,
          ...rest,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: membersTitleBreadcrumb(t),
      values: [membersBreadcrumb(t), memberNewBreadcrumb(t)],
    });
  }, []);

  return (
    <HealthProgramMemberForm
      isSubmitting={isSubmitting}
      showMemberListSection={showMemberListSection}
      errors={errors}
      onDone={handleSubmit}
    />
  );
};
