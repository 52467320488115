/* eslint-disable react/jsx-handler-names */
import { useTranslation } from "@toolkit/i18n";
import {
  Badge,
  Box,
  CircularProgress,
  CustomInfiniteScroll,
  IconButton,
  NotificationIcon,
  NotificationsDrawer,
  NotificationsItem,
  Typography,
} from "@toolkit/ui";
import { useNotificationsHooks } from "./Notifications.hook";
import { useNotificationsStyles } from "./Notifications.styles";

export const Notifications = () => {
  const { t } = useTranslation("admin");
  const { classes, theme } = useNotificationsStyles();
  const { open, loading, notifications, pageInfo, totalCount, unReadNotificationsCount, handleToggle, fetchMoreData } =
    useNotificationsHooks();

  return (
    <>
      <IconButton color='primary' onClick={handleToggle}>
        <Badge badgeContent={unReadNotificationsCount} color='error'>
          <NotificationIcon fill={theme.palette.primary.main} />
        </Badge>
      </IconButton>
      {open && (
        <NotificationsDrawer open={open} number={Number(totalCount)} onClose={handleToggle}>
          {loading ? (
            <Box display='flex' justifyContent='center' color={theme.palette.primary[400]} m={1}>
              <CircularProgress size={50} color='inherit' />
            </Box>
          ) : (
            <>
              {notifications?.length ? (
                <div className={classes.container} id='scrollableDiv'>
                  <CustomInfiniteScroll
                    dataLength={Number(notifications?.length) || 0}
                    onFetchMore={() => fetchMoreData()}
                    hasMore={Boolean(pageInfo?.hasNextPage)}
                    loader={
                      <Box display='flex' justifyContent='center' color={theme.palette.primary[400]} m={1}>
                        <CircularProgress size={50} color='inherit' />
                      </Box>
                    }
                  >
                    {notifications?.map(notification => (
                      <NotificationsItem key={notification?.id} title={String(notification?.type)} />
                    ))}
                  </CustomInfiniteScroll>
                </div>
              ) : (
                <Typography p={3} variant='h5'>
                  {t("There is no Notifications")}
                </Typography>
              )}
            </>
          )}
        </NotificationsDrawer>
      )}
    </>
  );
};
