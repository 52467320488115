import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { healthPackagePaths, healthPackagesRoute } from "./constants/health-packages-path";
import { ReviewHealthPackagesList } from "./list/HealthPackages.screen";

export const healthPackagesRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  enabled,
}): RouteItem => {
  return {
    id: "health-packages",
    text: i18n.t("Health Packages", { ns: "admin" }),
    route: healthPackagesRoute,
    fullPath: healthPackagePaths.listPath.fullPath,
    element: <ReviewHealthPackagesList />,
    onClick: (route: string) => navigate(route),
    hidden: !enabled || !hasPermission(PermissionEnum.ManageHealthPackages),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageHealthPackages),
    subItems: [
      {
        id: "health-packages-id",
        route: healthPackagesRoute,
        fullPath: healthPackagePaths.listPath.fullPath,
        text: i18n.t("Health Packages", { ns: "admin" }),
        element: <ReviewHealthPackagesList />,
        onClick: route => navigate(route),
        isProhibited: !(enabled && hasPermission(PermissionEnum.ManageHealthPackages)),
        hidden: !enabled,
      },
    ],
  };
};
