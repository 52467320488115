import { RequestStatus } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, IconButton, RejectIcon, TextField, Tooltip, useTheme, useToasts } from "@toolkit/ui";
import {
  HealthProgramMembershipRequestRejectMutation,
  HealthProgramMembershipRequestsDocument,
  useHealthProgramMembershipRequestRejectMutation,
} from "pages/InsuranceApprovals/gql";
import { FC, useState } from "react";
import { RejectInsuranceCardDialogProps } from "./types";

export const RejectInsuranceCardDialog: FC<RejectInsuranceCardDialogProps> = ({ healthProgramMembershipRequest }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation("admin");
  const [reason, setReason] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [healthProgramMembershipRequestRejectMutation, { loading: isSubmitting }] = useHealthProgramMembershipRequestRejectMutation({
    onCompleted: (data: HealthProgramMembershipRequestRejectMutation) => {
      const errors = data?.healthProgramMembershipRequestReject?.healthProgramMembershipRequestErrors;
      if (errors?.length === 0) {
        addToast(t("Insurance Card Rejected"), {
          appearance: "success",
          autoDismiss: true,
        });
        handleToggleDialog();
      } else {
        const formatted = formatMessageErrors(errors);
        addToast(formatted, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      const formatted = formatGraphQLError(graphQLErrors);
      addToast(formatted || t("Insurance Card Reject failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
    refetchQueries: [
      {
        query: HealthProgramMembershipRequestsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const handleToggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const handleToggle = e => {
    e.stopPropagation();
    handleToggleDialog();
  };

  const handleChangeReason = e => {
    setReason(e.target.value);
  };
  const handleSubmit = () => {
    if (!reason) return;
    healthProgramMembershipRequestRejectMutation({
      variables: {
        healthProgramMembershipRequestRejectId: healthProgramMembershipRequest.id,
        rejectionReason: reason,
      },
    });
  };

  const isAlreadyApproved = healthProgramMembershipRequest.status === RequestStatus.Approved;
  const isAlreadyRejected = healthProgramMembershipRequest.status === RequestStatus.Rejected;

  let tooltipTitle = "";
  if (isAlreadyApproved) {
    tooltipTitle = t("The request is already been approved");
  } else if (isAlreadyRejected) {
    tooltipTitle = t("The request is already been rejected");
  }
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: t("Reject Insurance Request"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={tooltipTitle}>
          <span>
            <IconButton disabled={isAlreadyApproved || isAlreadyRejected} onClick={handleToggle}>
              <RejectIcon color='error' sx={{ fontSize: theme.mixins.fonts.fontSize.xl }} />
            </IconButton>
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={t("Please Enter Rejection Reason")}>
            <span>
              <Button color='error' disabled={isAlreadyApproved || isAlreadyRejected || isSubmitting} onClick={handleSubmit}>
                {t("Reject")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <TextField
        label={t("Rejection Reason")}
        placeholder={t("Rejection Reason")}
        multiline
        minRows={3}
        fullWidth
        value={reason}
        error={!reason}
        helperText={!reason && t("Required")}
        onChange={handleChangeReason}
      />
    </CustomDialog>
  );
};
