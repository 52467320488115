import { i18n } from "./index";

export const isRTL = () => {
  return i18n.dir() === "rtl";
};

export const isLTR = () => {
  return i18n.dir() === "ltr";
};

export const isLanguageArabic = () => {
  return i18n.language?.toLocaleLowerCase().startsWith("ar");
};

export const isLanguageEnglish = () => {
  return i18n.language?.toLocaleLowerCase().startsWith("en");
};

export const pickLocalizedValue = <T>(whenLTR: T, whenRTL: T): T => {
  if (isLTR()) {
    return whenLTR;
  }

  return whenRTL || whenLTR;
};
