export const reviewPageUploadRoute = "/review-page-upload";

export const reviewPageUploadPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${reviewPageUploadRoute}${this.route}`;
    },
  },
};
