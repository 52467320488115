import { SimplePaletteColorOptions } from "../../../base/mui";

export const yellow: SimplePaletteColorOptions = {
  // Golden Poppy
  main: "#f1c502",
  50: "#fffcf2",
  100: "#fffae5",
  200: "#fef5ca",
  300: "#fef0b0",
  400: "#feeb96",
  500: "#fee67c",
  600: "#fde161",
  700: "#fddc47",
  800: "#fdd72d",
  900: "#fdd213",
  1100: "#dab302",
  1200: "#c29f02",
  1300: "#aa8b02",
  1400: "#917701",
  1500: "#796301",
  1600: "#614f01",
  1700: "#493c01",
  1800: "#302800",
  1900: "#181400",
};
