/* eslint-disable react/default-props-match-prop-types */
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography, paths } from "@toolkit/ui";
import _ from "lodash";
import React, { FC } from "react";
import GoogleMapSearchComponent from "../GoogleMapSearch/GoogleMapSearchComponent";
import { useLocationPickerStyles } from "./styles";
import { ILocationPickerProps } from "./types";
import { useLocationPickerHook } from "./useLocationPickerHook";

const LocationPicker: FC<ILocationPickerProps> = props => {
  const { classes, theme } = useLocationPickerStyles();
  const {
    containerStyle,
    mapContainerStyle,
    searchContainerStyle,
    searchTitle,
    location,
    locations,
    addressError,
    addressRef,
    hasSearch,
    isLoaded,
    icon,
    addressName,
    hasLocationLngLat,
    center,
    defaultZoom,
    isChecked,
    containerClassName,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation,
  } = useLocationPickerHook(props);

  if (!isLoaded) return <Box>NotLoaded</Box>;
  return (
    <Grid container item sx={{ ...containerStyle }}>
      <Grid item xs={12} mb='15px' sx={{ ...searchContainerStyle }}>
        {searchTitle && (
          <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.md}>
            {searchTitle}
          </Typography>
        )}
        {hasSearch && <GoogleMapSearchComponent onLocationChange={handleSearchLocation} />}
      </Grid>
      <Grid ref={addressRef} item xs={12} sx={{ ...mapContainerStyle }}>
        {addressError && (
          <Typography color={"red"} mt='5px'>
            {addressError}
          </Typography>
        )}
        {hasLocationLngLat && (
          <Box m='20px 0'>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleIsChecked} />}
              label={t("Show Longitude and Latitude Inputs")}
            />
            {isChecked && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='latitude'
                    value={location?.lat}
                    onChange={handleLocationChanged("lat")}
                    placeholder={t("Latitude")}
                    label={t("Latitude")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='longitude'
                    value={location?.lng}
                    onChange={handleLocationChanged("lng")}
                    placeholder={t("Longitude")}
                    label={t("Longitude")}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <GoogleMap
          mapContainerClassName={containerClassName || classes.containerMapStyle}
          center={center}
          zoom={defaultZoom}
          onClick={handlePickLocation}
        >
          {!!location?.lat && !!location?.lng && (
            <Marker
              clickable
              position={location}
              icon={{
                path: _.get(paths, icon!),
                fillColor: theme.palette.secondary.main,
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(15, 15),
              }}
            >
              {addressName && (
                <InfoWindow>
                  <Typography>{addressName}</Typography>
                </InfoWindow>
              )}
            </Marker>
          )}
          {!!locations?.length &&
            locations?.map(locationItem => (
              <Marker
                key={locationItem?.id}
                clickable
                position={locationItem?.coordinates}
                icon={{
                  path: _.get(paths, icon!) | (paths?.["markerMap"] as any),
                  fillColor: theme.palette.secondary.main,
                  fillOpacity: 1,
                  strokeWeight: 0,
                  rotation: 0,
                  scale: 2,
                  anchor: new google.maps.Point(15, 15),
                }}
              ></Marker>
            ))}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};

LocationPicker.defaultProps = {
  icon: "markerMap",
  hasSearch: false,
  hasLocationLngLat: false,
  defaultCenter: { lat: 24.750643, lng: 46.707766 },
  location: undefined,
  containerClassName: undefined,
  defaultZoom: 13,
};

export default LocationPicker;
