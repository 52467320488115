import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { useHealthProgramNetworkProviderUpdateMutation } from "../../gql";
import { HealthProgramNetworkProviderInput } from "@health/queries/types";

export const ActiveToggleSwitch: FC<{
  isActive: { value: boolean; name: string };
  id: string;
  isDisabled?: boolean;
  updateRequiredInputs?: Partial<HealthProgramNetworkProviderInput>;
}> = ({ isActive, id, isDisabled, updateRequiredInputs }) => {
  const { t } = useTranslation("admin");
  const { value, name } = isActive;
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(value);

  useEffect(() => {
    setCheck(value);
  }, [value]);

  const [healthProgramNetworkProviderUpdate, { loading }] = useHealthProgramNetworkProviderUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramNetworkProviderUpdate?.entityErrors;
      if (errors?.length === 0) {
        succeeded(t("Network Provider updated successfully"));
      } else {
        const message = errors?.map(error => {
          return `${error.field || ""},\r\n ${error.message || ""}\r\n`;
        });
        failed(message as any);
      }
    },
    onError: () => {
      failed(t("Network Provider Update Failed"));
    },
  });

  const handleChangeToggle = e => {
    setCheck(e.target.checked);
    healthProgramNetworkProviderUpdate({
      variables: {
        id,
        input: {
          [name]: e.target.checked,
          ...updateRequiredInputs,
        },
      },
    });
  };

  return (
    <CustomToggleButton value={check || false} disabled={loading || isDisabled} defaultChecked={value} onChange={handleChangeToggle} />
  );
};
