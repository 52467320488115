import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { optimaActionsRoutes } from "@/pages/OptimaActions/constants";
import { optimaEditsRoutes } from "@/pages/OptimaEdits/constants";

export const optimaRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-routes",
    text: t("Optima", { ns: "admin" }),
    icon: <ListViewIcon />,
    hidden: !hasPermission(PermissionEnum.MangeEditsAndActions),
    isProhibited: !hasPermission(PermissionEnum.MangeEditsAndActions),
    subItems: [optimaActionsRoutes({ navigate, t }), optimaEditsRoutes({ navigate, t })],
  };
};
