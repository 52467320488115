import { useTranslation } from "@toolkit/i18n";
import type { ReactNode } from "react";
import React, { FC } from "react";
import { Box, Divider, Menu, MenuItem, Tooltip, Typography, styled, useTheme, type MenuProps } from "../../base/mui";
import { SignoutIcon } from "../../icons";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: theme.mixins.sidebar.width,
  borderRadius: 0,
}));

type AccountDropdownMenuProps = MenuProps & {
  onLogout?: () => void;
  username: string;
  imgSrc?: string;
  items: Array<{
    title: string;
    icon: ReactNode;
    onClick: () => void;
  }>;
};

export const AccountDropdownMenu: FC<AccountDropdownMenuProps> = ({ onLogout: handleLogout, username, imgSrc, items, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: theme.mixins.shadows.md,
          borderRadius: "10px",
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      {...props}
    >
      <Box
        sx={{
          width: 250,
          margin: "10px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "60px",
            width: "60px",
            boxShadow: theme.mixins.shadows.md,
            borderRadius: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.gray.light,
          }}
        >
          {imgSrc ? (
            <Box
              component='img'
              src={imgSrc}
              alt='user-profile-img'
              width={60}
              height={60}
              borderRadius={100}
              sx={{ boxShadow: theme.mixins.shadows.xs }}
            />
          ) : (
            <Typography fontSize='30px' fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
              {username.charAt(0)}
            </Typography>
          )}
        </Box>
        <Tooltip title={username} placement='top'>
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            align='center'
            fontSize={theme.mixins.fonts.fontSize.md}
            sx={{
              margin: "10px auto",
              paddingInline: 1,
              overflow: "hidden",
              width: 250,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {username}
          </Typography>
        </Tooltip>
      </Box>
      {items.map(({ title, icon, onClick: handleClick }, key) => (
        <StyledMenuItem key={title + key} onClick={handleClick}>
          {icon}
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} sx={{ marginInline: "8px" }}>
            {title}
          </Typography>
        </StyledMenuItem>
      ))}

      {handleLogout && (
        <>
          <Divider
            sx={{
              marginInline: "4px",
            }}
          />
          <MenuItem
            sx={{
              borderRadius: 0,
              justifyContent: "center",
            }}
            onClick={handleLogout}
          >
            <SignoutIcon
              sx={{
                height: "19px",
                width: "19px",
              }}
              fill={theme.palette.primary.main}
            />
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} sx={{ marginInline: "8px" }}>
              {t("Logout")}
            </Typography>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
AccountDropdownMenu.defaultProps = {
  onLogout: undefined,
  imgSrc: undefined,
};
