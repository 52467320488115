import { useEffect, useState } from "react";
import { useNetworkStatus } from "use-network-status";

const on = (obj: any, ...args: any[]) => obj.addEventListener(...args);

const off = (obj: any, ...args: any[]) => obj.removeEventListener(...args);

interface NetworkState {
  online?: boolean;
  since?: Date;
  downlink?: number;
  downlinkMax?: number;
  effectiveType?: string;
  rtt?: number;
  type?: string;
}

const getConnection = () => {
  if (typeof navigator !== "object") {
    return null;
  }
  const nav = navigator as any;
  return nav.connection || nav.mozConnection || nav.webkitConnection;
};

const getConnectionState = (): NetworkState => {
  const connection = getConnection();
  if (!connection) {
    return {};
  }
  const { downlink, downlinkMax, effectiveType, type, rtt } = connection;
  return {
    downlink,
    downlinkMax,
    effectiveType,
    type,
    rtt,
  };
};

export const useNetwork = (initialState: NetworkState = {}) => {
  const [state, setState] = useState(initialState);
  const isOnline = useNetworkStatus();

  let localState = state;
  const localSetState = (patch: Partial<NetworkState>) => {
    localState = { ...localState, ...patch };
    setState(localState);
  };
  const connection = getConnection();
  const onConnectionChange = () => {
    localSetState(getConnectionState());
  };

  useEffect(() => {
    if (connection) {
      on(connection, "change", onConnectionChange);
      localSetState({
        ...state,
        ...getConnectionState(),
      });
    }

    return () => {
      if (connection) {
        off(connection, "change", onConnectionChange);
      }
    };
  }, []);

  return { ...state, online: isOnline };
};
