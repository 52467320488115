import { makeStyles } from "tss-react/mui";

export const useLayoutStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  mainContainer: {
    flex: 1,
    backgroundColor: theme.palette.stale[100],
    width: "100%",
    height: `calc(100vh - ${theme.mixins.header.height}px)`,
    overflowX: "hidden",
    marginTop: theme.mixins.header.height,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isOpen && {
      width: `calc(100% - ${theme.mixins.sidebar.width + 10}px)`,
      // marginLeft: `${drawerWidth + 10}px`,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    height: theme.mixins.header.height,
    // necessary for content to be below app bar
    ...(theme.mixins.toolbar as any),
    justifyContent: "flex-end",
  },
  iconButton: { margin: 1 },
}));
