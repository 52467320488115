/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramExclusionOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthProgramExclusionOptionsQuery = { __typename?: 'Query', healthProgramExclusionOptions?: Array<{ __typename?: 'ExclusionOption', id: string, name?: string | null, description?: string | null } | null> | null };


export const HealthProgramExclusionOptionsDocument = gql`
    query healthProgramExclusionOptions {
  healthProgramExclusionOptions {
    id
    name
    description
  }
}
    `;

/**
 * __useHealthProgramExclusionOptionsQuery__
 *
 * To run a query within a React component, call `useHealthProgramExclusionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramExclusionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramExclusionOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthProgramExclusionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramExclusionOptionsQuery, HealthProgramExclusionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramExclusionOptionsQuery, HealthProgramExclusionOptionsQueryVariables>(HealthProgramExclusionOptionsDocument, options);
      }
export function useHealthProgramExclusionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramExclusionOptionsQuery, HealthProgramExclusionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramExclusionOptionsQuery, HealthProgramExclusionOptionsQueryVariables>(HealthProgramExclusionOptionsDocument, options);
        }
export type HealthProgramExclusionOptionsQueryHookResult = ReturnType<typeof useHealthProgramExclusionOptionsQuery>;
export type HealthProgramExclusionOptionsLazyQueryHookResult = ReturnType<typeof useHealthProgramExclusionOptionsLazyQuery>;
export type HealthProgramExclusionOptionsQueryResult = Apollo.QueryResult<HealthProgramExclusionOptionsQuery, HealthProgramExclusionOptionsQueryVariables>;