import { replacePathParams } from "utils";

export const surveysRoute = "/surveys";
export const surveysNewRoute = "new";
const surveysDetailsRoute = ":surveyId";

export const surveysPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${surveysRoute}${this.route}`;
    },
  },
  newPath: {
    route: surveysNewRoute,
    get fullPath() {
      return `${surveysRoute}/${this.route}`;
    },
  },
  detailsPath: {
    route: surveysDetailsRoute,
    get fullPath() {
      return `${surveysRoute}/${this.route}`;
    },
    fullPathWithParams: function (surveyId: string) {
      return replacePathParams(this.fullPath, { surveyId });
    },
  },
};
