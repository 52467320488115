import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useMedicalFormActivateMutation, useMedicalFormDeactivateMutation, } from "../../gql";
export const MedicalFormActivation = props => {
    const { id, isActive } = props;
    const { t, i18n } = useTranslation("domains");
    const currentDir = i18n.dir(i18n.language);
    const { open, handleToggle } = useOpenState();
    const { succeeded, failed } = useAddToast();
    const [active, setActive] = useState(isActive);
    const [fetchMedicalFormDeactivateMutation] = useMedicalFormDeactivateMutation({
        onCompleted: (data) => {
            var _a, _b;
            const mutationErrors = (_a = data === null || data === void 0 ? void 0 : data.deactivateMedicalForm) === null || _a === void 0 ? void 0 : _a.errors;
            if (mutationErrors === null || mutationErrors === void 0 ? void 0 : mutationErrors.length) {
                failed(t("Medical form update failed"));
            }
            else {
                setActive(!!((_b = data === null || data === void 0 ? void 0 : data.deactivateMedicalForm) === null || _b === void 0 ? void 0 : _b.active));
                succeeded(t("Medical form updated successfully"));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const [fetchMedicalFormActivateMutation] = useMedicalFormActivateMutation({
        onCompleted: (data) => {
            var _a, _b;
            const errors = (_a = data === null || data === void 0 ? void 0 : data.activateMedicalForm) === null || _a === void 0 ? void 0 : _a.errors;
            if (errors && errors.length > 0) {
                failed(t("Medical form update failed"));
            }
            else {
                setActive(!!((_b = data === null || data === void 0 ? void 0 : data.activateMedicalForm) === null || _b === void 0 ? void 0 : _b.active));
                succeeded(t("Medical form updated successfully"));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const handleChangeActive = () => {
        if (active) {
            fetchMedicalFormDeactivateMutation({
                variables: {
                    deactivateMedicalFormId: id,
                },
            });
        }
        else {
            fetchMedicalFormActivateMutation({
                variables: {
                    activateMedicalFormId: id,
                },
            });
        }
    };
    useEffect(() => {
        setActive(isActive);
    }, [isActive]);
    return (_jsxs(_Fragment, { children: [_jsx(CustomToggleButton, { dir: currentDir, checked: active, onChange: handleToggle, formGroupProps: {
                    sx: {
                        display: "block",
                    },
                } }), open && (_jsx(CustomDialog, { type: 'warning', text: {
                    body: t("Are you sure you want to perform this action?"),
                    title: active ? t("Medical Form Deactivation") : t("Medical Form Activation"),
                }, isOpen: open, onClose: handleToggle, onConfirm: handleChangeActive }))] }));
};
