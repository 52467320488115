import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
export const useReportAnError = () => {
    const { t } = useTranslation("domains");
    const { addToast } = useToasts();
    return {
        reportError: (param) => {
            const message = typeof param === "string" ? param : param.message;
            const details = typeof param === "string" ? "" : param.details;
            console.error({ "@health/domains/error": param });
            addToast(message + details ? `\n ${details}` : "");
        },
        reportGenericApiError: (genericMessage) => (e) => {
            console.error({ "@health/domains/error": e });
            addToast(genericMessage + ": " + e.message);
        },
        reportGraphQlErrors: (title) => (e) => {
            console.error({ "@health/domains/error": e });
            const formattedMessage = `${title}${pickLocalizedValue(",", " ،")} \n${t(formatGraphQLError(e))}`;
            addToast(formattedMessage, { appearance: "error", autoDismiss: true });
        },
    };
};
