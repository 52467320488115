import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import moment from "moment";
import { vendorTotalPointsReportTitleBreadcrumb } from "pages/HealthProgramVendorTotalPointsReport/constants/vendor-total-points-report-breadcrumbs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useHealthProgramVendorTotalPointsReportQuery } from "./gql";
import { useHealthProgramVendorTotalPointsReportColumns } from "./useHealthProgramVendorTotalPointsReportColumns";

export const HealthProgramVendorTotalPointsReport = () => {
  const { t } = useTranslation("admin");
  const params = useParams();

  const { setBreadCrumb } = useBreadCrumbs();
  const month = moment().get("month") + 1;
  const year = moment().get("year");

  useEffect(() => {
    setBreadCrumb({
      title: vendorTotalPointsReportTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='doctors'
      filterInput={{ month, year, healthProgramId: Number(params?.programId), vendorId: params?.vendorId! }}
      variables={{
        filter: { month, year, healthProgramId: Number(params?.programId), vendorId: params?.vendorId! },
      }}
      columns={useHealthProgramVendorTotalPointsReportColumns()}
      query={useHealthProgramVendorTotalPointsReportQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper filters={<FilterGrid />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
