import { UserSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  FilterGrid,
  GridContext,
  MainListActions,
  OrderDirection,
  PageWrapper,
  TableGrid,
  TableSettingComponent,
  useBreadCrumbs,
} from "@toolkit/ui";
import { useProviderUsersQuery } from "gql/queries";
import { staffUsersColumns } from "pages/PermissionsManagement/Users/list/usersColumns";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import {
  providerUserFieldBreadcrumb,
  providerUserListBreadcrumb,
  providerUsersTitleBreadcrumb,
} from "pages/ProviderUsers/constants/provider-users-breadcrumbs";
import { useDeleteUserMutation } from "pages/UserManagement/gql";
import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GridProvider, ProviderCard } from "shared/components";

const ProviderUsersManagement = () => {
  const params = useParams();
  const { t } = useTranslation("admin");
  const { state } = useLocation();
  const columns = useMemo(() => staffUsersColumns(t), []);
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    if (params?.id) {
      const providerName = (state as any)?.["providerName"];
      setBreadCrumb({
        title: providerUsersTitleBreadcrumb(t),
        values: [
          providerUserListBreadcrumb(t),
          providerUserFieldBreadcrumb(providerName, providersManagementPaths.editPath.fullPathWithParams(params.id)),
        ],
      });
    }
  }, [params?.id]);

  return (
    <GridProvider
      hasTableSetting
      gridName='providerUsers'
      columns={columns}
      query={useProviderUsersQuery}
      variables={{
        id: params?.id!,
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
      }}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
      extractCountableConnectionData={({ responseData }) => {
        return {
          nodes: responseData?.vendor?.users?.edges?.map(e => e.node) || [],
          totalCount: null,
          pageInfo: responseData?.vendor?.users?.pageInfo,
        };
      }}
      deleteItemProps={{
        name: "user",
        entityTypeName: "User",
        useDeleteMutation: useDeleteUserMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <>
          <Box m={"20px"}>
            <GridContext.Consumer>
              {({ customTableProps, queryResponse }) => (
                <ProviderCard
                  loading={customTableProps?.isLoading}
                  vendor={(queryResponse as ReturnType<typeof useProviderUsersQuery>["data"])?.vendor}
                />
              )}
            </GridContext.Consumer>
          </Box>
          <Box m={"20px"}>
            <TableGrid />
          </Box>
        </>
      </PageWrapper>
    </GridProvider>
  );
};

export default ProviderUsersManagement;
