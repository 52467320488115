import { Components, Theme } from "../../../base/mui";

export const MuiPaginationItemOverride: Components<Theme>["MuiPaginationItem"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&.Mui-selected": {
        backgroundColor: theme.palette.gray[100],
      },
    }),
    previousNext: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.stale.main,
      },
    }),
    firstLast: ({ theme }) => ({
      border: "1px solid " + theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.gray[100],
      },
    }),
  },
};
