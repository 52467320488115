/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSubListItemMutationVariables = Types.Exact<{
  input: Types.SubListItemInput;
  subListId: Types.Scalars['ID'];
}>;


export type AddSubListItemMutation = { __typename?: 'Mutation', addSubListItem?: { __typename: 'SubListItem', id?: string | null, display: string, code: string, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const AddSubListItemDocument = gql`
    mutation addSubListItem($input: SubListItemInput!, $subListId: ID!) {
  addSubListItem(subListItem: $input, subListId: $subListId) {
    __typename
    id
    display
    code
    errors {
      field
      code
      message
    }
  }
}
    `;
export type AddSubListItemMutationFn = Apollo.MutationFunction<AddSubListItemMutation, AddSubListItemMutationVariables>;

/**
 * __useAddSubListItemMutation__
 *
 * To run a mutation, you first call `useAddSubListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubListItemMutation, { data, loading, error }] = useAddSubListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useAddSubListItemMutation(baseOptions?: Apollo.MutationHookOptions<AddSubListItemMutation, AddSubListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubListItemMutation, AddSubListItemMutationVariables>(AddSubListItemDocument, options);
      }
export type AddSubListItemMutationHookResult = ReturnType<typeof useAddSubListItemMutation>;
export type AddSubListItemMutationResult = Apollo.MutationResult<AddSubListItemMutation>;
export type AddSubListItemMutationOptions = Apollo.BaseMutationOptions<AddSubListItemMutation, AddSubListItemMutationVariables>;