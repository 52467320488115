/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { startCase } from "lodash";
import moment from "moment-timezone";
import { useMemo } from "react";
import { CallbackRequestUpdateHistoryQueryNodeType } from "./types";

export type CallbackRequestListColumnsTypes = CustomTableColumnProps<CallbackRequestUpdateHistoryQueryNodeType>[];

export const useCallbackHistoryColumns = (): CallbackRequestListColumnsTypes => {
  const { t } = useTranslation("admin");
  const userTz = moment.tz.guess();

  return useMemo(() => {
    return [
      {
        key: "fullName",
        header: t("Admin Name"),
        accessor: row => <TruncateTypography text={row?.user?.fullName!} />,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: row => t(startCase(row?.status!.toLowerCase())),
      },
      {
        key: "comments",
        header: t("Comment"),
        accessor: row => <TruncateTypography text={row?.comments as string} />,
      },
      {
        key: "requestDate",
        header: t("Date"),
        accessor: ({ created }) => (created ? moment(created).format("DD MMM YYYY") : "-"),
      },
      {
        key: "requestTime",
        header: t("Time"),
        accessor: ({ created }) => (created ? moment(created).tz(userTz).format("hh:mm a") : "-"),
      },
    ];
  }, [t]);
};
