import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { useNetworkStatus } from "use-network-status";
import { Alert, CircularProgress, Snackbar } from "../../base/mui";
import { useNetworkStatusStyles } from "./NetworkStatus.styles";

export const NetworkStatus: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const { classes } = useNetworkStatusStyles({ isOpen });

  return (
    <Snackbar
      open={!isOnline}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ root: pickLocalizedValue(classes.ltr, classes.rtl) }}
    >
      <Alert severity='warning' icon={<CircularProgress size={20} />}>
        {t("No internet connection")}
      </Alert>
    </Snackbar>
  );
};
