/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductApproveMutationVariables = Types.Exact<{
  marketplaceProductApproveId: Types.Scalars['ID'];
}>;


export type ProductApproveMutation = { __typename?: 'Mutation', marketplaceProductApprove?: { __typename?: 'MarketplaceProduct', approvalStatus?: Types.MarketplaceApprovalStatus | null, id: string } | null };


export const ProductApproveDocument = gql`
    mutation productApprove($marketplaceProductApproveId: ID!) {
  marketplaceProductApprove(id: $marketplaceProductApproveId) {
    approvalStatus
    id
  }
}
    `;
export type ProductApproveMutationFn = Apollo.MutationFunction<ProductApproveMutation, ProductApproveMutationVariables>;

/**
 * __useProductApproveMutation__
 *
 * To run a mutation, you first call `useProductApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productApproveMutation, { data, loading, error }] = useProductApproveMutation({
 *   variables: {
 *      marketplaceProductApproveId: // value for 'marketplaceProductApproveId'
 *   },
 * });
 */
export function useProductApproveMutation(baseOptions?: Apollo.MutationHookOptions<ProductApproveMutation, ProductApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductApproveMutation, ProductApproveMutationVariables>(ProductApproveDocument, options);
      }
export type ProductApproveMutationHookResult = ReturnType<typeof useProductApproveMutation>;
export type ProductApproveMutationResult = Apollo.MutationResult<ProductApproveMutation>;
export type ProductApproveMutationOptions = Apollo.BaseMutationOptions<ProductApproveMutation, ProductApproveMutationVariables>;