import { CallbackRequest } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton, TextField } from "@toolkit/ui";
import { FC } from "react";

export const PatientNotesModal: FC<{ patientNotes?: CallbackRequest["patientNotes"] }> = ({ patientNotes }) => {
  const { t } = useTranslation("admin");

  return (
    <CustomDialog type='info' title={t("Patient Notes")} button={<ShowButton />}>
      <TextField
        multiline
        fullWidth
        minRows={10}
        InputProps={{
          readOnly: true,
        }}
        value={patientNotes || t("Patient didn't provide notes")}
      />
    </CustomDialog>
  );
};
