import { AccountError, User, VendorUserTypes, WorkingHour } from "@health/queries/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, FormHelperText, Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { DoctorAppointmentInformation } from "./DoctorAppointmentInformation";
import { DoctorInformation } from "./DoctorInformation.component";
import { DoctorQualificationsInformation } from "./DoctorQualificationsInformation";

export const DoctorDetailsComponent: FC<{
  errors: AccountError[];
  isLoading: boolean;
  doYouHaveUser: boolean;
  defaultValues?: User | undefined;
  isEdit: boolean;
}> = ({ errors, isLoading, doYouHaveUser, defaultValues, isEdit }) => {
  const { t } = useTranslation("admin");
  const defaultVendorUserType = defaultValues?.vendorUserType as VendorUserTypes | undefined;
  const { formState, watch } = useFormContext();
  const vendorUserType = watch("vendorUserType");
  const combinedErrors = combineErrors(formState.errors, errors);
  const theme = useTheme();
  return (
    <>
      {(vendorUserType?.value == VendorUserTypes.Doctor || defaultVendorUserType === VendorUserTypes.Doctor) && (
        <>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Information")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorInformation errors={errors} defaultValues={defaultValues} isEdit={isEdit as boolean} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Appointment Information")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorAppointmentInformation errors={errors} defaultValues={defaultValues} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Qualifications")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorQualificationsInformation errors={errors} defaultValues={defaultValues} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <WorkingHours
              isLoading={isLoading}
              inputName='doctorInfo.workingHours'
              doYouHaveData={doYouHaveUser}
              workingHoursDefaultValue={defaultValues?.doctor?.workingHours! as WorkingHour[]}
              errorText={
                <FormHelperText error={Boolean(combinedErrors?.doctorInfo?.workingHours?.message)}>
                  <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight='bold' color={theme.palette.error.main}>
                    {combinedErrors?.doctorInfo?.workingHours?.message}
                  </Typography>
                </FormHelperText>
              }
            />
          </Grid>
        </>
      )}
    </>
  );
};
