import { SimplePaletteColorOptions } from "../../../base/mui";

export const stale: SimplePaletteColorOptions = {
  // Waikawa Grey
  main: "#5b7798",
  50: "#f7f8fa",
  100: "#eef1f5",
  200: "#e6ebf0",
  300: "#cdd6e1",
  400: "#bdc9d7",
  500: "#acbbcd",
  600: "#9cadc3",
  700: "#8ba0b9",
  800: "#7a92af",
  900: "#6a85a5",
  1100: "#536b8a",
  1200: "#495f7a",
  1300: "#40546b",
  1400: "#37485c",
  1500: "#2e3c4d",
  1600: "#25303d",
  1700: "#1c242e",
  1800: "#12181f",
  1900: "#090c0f",
};
