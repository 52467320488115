import { makeStyles } from "tss-react/mui";

export const useAutocompleteStyles = makeStyles()(theme => ({
  autocomplete: {
    width: "100%",
    "& .MuiFilledInput-root.Mui-focused": {
      borderRadius: "10px 10px 0px 0px",
      borderColor: theme.palette.gray.main,
    },
  },
  tag: {
    marginTop: `10px !important`,
    "&.MuiChip-root": {
      minHeight: 24,
      maxHeight: "inherit",
      height: "100%",
      "& .MuiChip-deleteIcon": {
        marginInlineEnd: 2,
      },
    },
  },
  paper: {
    boxShadow: theme.mixins.shadows.none,
    border: "1px solid",
    borderColor: theme.palette.gray.main,
    borderTop: 0,
    height: "200px",
    position: "absolute",
    width: "calc(100% + 2px)",
    margin: "0px -1px",
    padding: 0,
    borderRadius: "0px 0px 10px 10px",
    overflow: "hidden",
  },
  height: {
    height: "inherit",
  },
  textField: {
    borderRadius: "15px 10px 0px 0px",
    borderBottom: "none",
    padding: 0,
    height: "100%",
  },
  popper: {
    zIndex: 1000,
  },
}));
