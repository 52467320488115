import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CustomIcon, Divider, Menu, useTheme } from "@toolkit/ui";
import { useActionsMenuHooks } from "./ActionsMenu.hooks";
import { ApproveRejectButtons } from "./ApproveRejectButtons";
import { AssignButton } from "./AssignButton";
import { CloneButton } from "./CloneButton";
import { DownloadButton } from "./DownloadButton";
import { FetchToSubListButton } from "./FetchToSublistButton";
import { ImportConceptsDialog } from "./ImportConceptsDialog";
import { PublishButton } from "./PublishButton";
import { SendToReviewButton } from "./SendToReviewButton";
export const ActionsMenu = () => {
    const { hasData, hasClone, hasImport, hasDownload, hasApproveReject, hasPublish, isDraft, hasReassign, isMenuOpened, menuActionsAnchorEl, t, handleOpenMenuToAnchor, handleCloseMenu, } = useActionsMenuHooks();
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { id: 'med-list-actions-dropDown-button', "aria-controls": isMenuOpened ? "med-list-dropDown-menu" : undefined, "aria-haspopup": 'true', "aria-expanded": isMenuOpened ? "true" : undefined, onClick: handleOpenMenuToAnchor, endIcon: _jsx(CustomIcon, { icon: 'expandArrow', color: theme.palette.common.white }), children: t("Actions") }), _jsx(Menu, { id: 'med-list-dropDown-menu', anchorEl: menuActionsAnchorEl, open: isMenuOpened, keepMounted: true, onClose: handleCloseMenu, MenuListProps: {
                    "aria-labelledby": "med-list-actions-dropDown-button",
                }, children: _jsxs(_Fragment, { children: [hasImport && _jsx(ImportConceptsDialog, { onClick: handleCloseMenu }), hasDownload && _jsx(DownloadButton, { onClick: handleCloseMenu, hasData: hasData }), _jsx(Divider, {}), hasClone && _jsx(CloneButton, { disabled: !hasData, onClick: handleCloseMenu }), hasApproveReject && _jsx(ApproveRejectButtons, { onClick: handleCloseMenu }), hasPublish && _jsx(PublishButton, { onClick: handleCloseMenu }), isDraft && _jsx(SendToReviewButton, { onClick: handleCloseMenu }), hasReassign && _jsx(AssignButton, { onClick: handleCloseMenu }), _jsx(FetchToSubListButton, { isDisabled: !hasData, onClick: handleCloseMenu })] }) })] }));
};
