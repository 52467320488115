import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const vendorAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const VendorAutocompleteSchemas = {
    vendorAutocomplete,
    vendorAutocompleteOptional: vendorAutocomplete.nullable().optional(),
};
