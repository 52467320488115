import { getAutocompleteFilter } from "@toolkit/ui";
import { useDecisionPlansAutocompleteQuery } from "./gql";
export const getDecisionPlansAutocompleteFilter = ({ name, filter, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "getPlans",
        query: useDecisionPlansAutocompleteQuery,
        labelBy: option => (option === null || option === void 0 ? void 0 : option.display) || (option === null || option === void 0 ? void 0 : option.code),
        backendAccessor: "id",
        filtersForQuery: filter,
        filterSearchKey: "planCode",
    });
};
