import { Coverage } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const coverageOptionsMap = {
    [Coverage.FullyCovered]: {
        key: Coverage.FullyCovered,
        get label() {
            return i18n.t("Fully Covered", { ns: "domains" });
        },
        value: Coverage.FullyCovered,
    },
    [Coverage.NotCovered]: {
        key: Coverage.NotCovered,
        get label() {
            return i18n.t("Not Covered", { ns: "domains" });
        },
        value: Coverage.NotCovered,
    },
    [Coverage.PartiallyCovered]: {
        key: Coverage.PartiallyCovered,
        get label() {
            return i18n.t("Partially Covered", { ns: "domains" });
        },
        value: Coverage.PartiallyCovered,
    },
};
export const coverageOptions = Object.values(coverageOptionsMap);
