import {
  formGirdBreakPoints,
  formGirdSpacing,
  getMaxValueValidation,
  getMinValueValidation,
  getNumericValidation,
  NumericPattern,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "../CustomTextField.components";
import { SubscriptionProps } from "../types";
import { SubscriptionPlan } from "./SubPlanForm.component";

type PlanProps = {
  id: string;
  fixedCostAmount: number | null;
  fixedOrderCostAmount: number | null;
  fixedOrderPercentage: number | null;
};

export const Subscription: FC<
  SubscriptionProps & {
    onFetchMoreDataForPlans: () => void;
    hasMoreForPlans: boolean;
    handlePlanSearchInput: (input: string) => void;
  }
> = ({
  defaultValues,
  errorsInput,
  isLoading,
  plans,
  doYouHaveVendor,
  onFetchMoreDataForPlans,
  hasMoreForPlans,
  handlePlanSearchInput,
}) => {
  const { t } = useTranslation("admin");
  const { watch, setValue } = useFormContext();

  const watchPlan: Partial<PlanProps> = watch("subscription.plan", defaultValues?.subscriptions || null);

  const fixedCostAmountName = "subscription.fixedCostAmount";
  const fixedOrderCostAmountName = "subscription.fixedOrderCostAmount";
  const fixedOrderPercentageName = "subscription.fixedOrderPercentage";

  useEffect(() => {
    if (watchPlan) {
      setValue(fixedCostAmountName, watchPlan?.fixedCostAmount);
      setValue(fixedOrderCostAmountName, watchPlan?.fixedOrderCostAmount);
      setValue(fixedOrderPercentageName, watchPlan?.fixedOrderPercentage);
    } else if (watchPlan == null || watchPlan == undefined) {
      setValue(fixedCostAmountName, null);
      setValue(fixedOrderCostAmountName, null);
      setValue(fixedOrderPercentageName, null);
    }
  }, [JSON.stringify(defaultValues?.subscriptions), watchPlan]);

  return (
    <Grid item xs={12}>
      <FormCard title={t("Subscription Plan")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
        <Grid container spacing={formGirdSpacing}>
          <SubscriptionPlan
            plans={plans}
            defaultValues={defaultValues}
            onFetchMoreDataForPlans={onFetchMoreDataForPlans}
            hasMoreForPlans={hasMoreForPlans}
            handlePlanSearchInput={handlePlanSearchInput}
          />
          {watchPlan?.fixedCostAmount != null && watchPlan.fixedCostAmount !== 0 && (
            <Grid item {...formGirdBreakPoints}>
              <CustomTextField
                errorsInput={errorsInput}
                name='subscription.fixedCostAmount'
                label={t("Fixed cost amount")}
                defaultValue={defaultValues?.subscriptions?.fixedCostAmount}
                registerProps={{
                  pattern: getNumericValidation(t, NumericPattern),
                  min: getMinValueValidation(t, 0),
                }}
              />
            </Grid>
          )}
          {watchPlan && watchPlan.fixedOrderCostAmount != 0 && (
            <Grid item {...formGirdBreakPoints}>
              <CustomTextField
                errorsInput={errorsInput}
                name={fixedOrderCostAmountName}
                label={t("Fixed Order Cost Amount")}
                defaultValue={defaultValues?.subscriptions?.fixedOrderCostAmount}
                registerProps={{
                  pattern: getNumericValidation(t, NumericPattern),
                  min: getMinValueValidation(t, 0),
                }}
              />
            </Grid>
          )}
          {watchPlan && watchPlan.fixedOrderPercentage != 0 && (
            <Grid item {...formGirdBreakPoints}>
              <CustomTextField
                errorsInput={errorsInput}
                name={fixedOrderPercentageName}
                label={t("Fixed Order Cost Amount")}
                defaultValue={defaultValues?.subscriptions?.fixedOrderPercentage}
                registerProps={{
                  pattern: getNumericValidation(t, NumericPattern),
                  min: getMinValueValidation(t, 0),
                  max: getMaxValueValidation(t, 100),
                }}
              />
            </Grid>
          )}
        </Grid>
      </FormCard>
    </Grid>
  );
};
