import { makeStyles } from "tss-react/mui";

export const useWaveSurferAudioStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
    borderRadius: 35,
    padding: "0px 8px",
  },
  waveformRoot: {
    flex: 1,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
      svg: {
        backgroundColor: theme.palette.gray.light,
      },
    },
  },
}));
