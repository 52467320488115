import { makeStyles } from "tss-react/mui";

export const useBreadcrumbsStyles = makeStyles()(theme => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.gray.light,
  },
  link: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  defaultPointer: {
    p: {
      cursor: "default",
    },
  },
  notLink: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.primary.main,
  },
}));
