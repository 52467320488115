import { gql } from '@apollo/client';
export const DecisionPlanFragmentDoc = gql `
    fragment DecisionPlan on DecisionPlan {
  id
  code
  display
  actionDefinitionFields {
    id
    display
    type
    code
    isRequired
    allowedValues
  }
  fields: field {
    id
    code
    display
    operators
    allowedValues
    category {
      code
      display
    }
    fieldType {
      id
      type
    }
  }
}
    `;
