import { replacePathParams } from "utils";

export const memberListRoute = "/member-list";
const memberListNewRoute = "new";
const memberListEditRoute = "edit/:id";
const memberListMembersRoute = ":id/members";
const memberListMembersNewRoute = `${memberListMembersRoute}/new`;
const memberListMembersEditRoute = `${memberListMembersRoute}/edit/:memberId`;

export const memberListPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${memberListRoute}${this.route}`;
    },
  },
  newPath: {
    route: memberListNewRoute,
    get fullPath() {
      return `${memberListRoute}/${this.route}`;
    },
  },
  editPath: {
    route: memberListEditRoute,
    get fullPath() {
      return `${memberListRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  membersPath: {
    route: memberListMembersRoute,
    get fullPath() {
      return `${memberListRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  membersNewPath: {
    route: memberListMembersNewRoute,
    get fullPath() {
      return `${memberListRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  membersEditPath: {
    route: memberListMembersEditRoute,
    get fullPath() {
      return `${memberListRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, memberId: string) {
      return replacePathParams(this.fullPath, { id, memberId });
    },
  },
};
