import { formGirdBreakPoints, getMaxLengthValidation, getRequiredValidation, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormPhoneInput, Grid } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { CustomTextField } from "../CustomTextField.components";
import { GeneralInformationProps } from "../types";
import { GeneralInfoToggleButton } from "./GeneralInfoToggleButton.components";
import { generalInformationFields } from "./providerInfoUtils";
import { TypesControl } from "./TypesForm.component";

export const GeneralInformation: FC<GeneralInformationProps> = props => {
  const { defaultValues, errorsInput, isEditMode } = props;
  const { t } = useTranslation("admin");
  const { register, setValue } = useCustomFormContext();
  useEffect(() => {
    setValue("contactPhoneNumber", defaultValues?.contactPhoneNumber ?? "");
  }, [defaultValues]);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {generalInformationFields(t, defaultValues).map(field => {
          const isUpdate = isEditMode && (field.name === "email" || field.name === "mobileNumber");

          return (
            !isUpdate && (
              <Grid item {...formGirdBreakPoints} key={field.name}>
                <CustomTextField pattern={field.pattern} errorsInput={errorsInput} {...field} />
              </Grid>
            )
          );
        })}
        {!isEditMode && (
          <Grid item {...formGirdBreakPoints}>
            <FormPhoneInput
              {...register("mobileNumber", {
                required: getRequiredValidation(t, true),
              })}
              label={t("Mobile Number")}
              error={Boolean(errorsInput?.["mobileNumber"]?.message)}
              helperText={t(errorsInput?.["mobileNumber"]?.message)}
              fullWidth
            />
          </Grid>
        )}
        <Grid item {...formGirdBreakPoints}>
          <FormPhoneInput
            {...register("contactPhoneNumber", {
              required: getRequiredValidation(t, true),
            })}
            label={t("Contact Phone Number")}
            error={Boolean(errorsInput?.["contactPhoneNumber"]?.message)}
            helperText={t(errorsInput?.["contactPhoneNumber"]?.message)}
            fullWidth
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TypesControl type={String(defaultValues?.type)} errorsInput={errorsInput} />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <CustomTextField
            {...{
              name: "description",
              label: t("Description"),
              defaultValue: defaultValues?.description,
              rows: 4,
              multiline: true,
              isRequired: false,
              registerProps: {
                maxLength: getMaxLengthValidation(t, 2000),
              },
            }}
          />
        </Grid>
        <GeneralInfoToggleButton defaultValues={defaultValues} />
      </Grid>
    </React.Fragment>
  );
};
