/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExclusionOptionDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ExclusionOptionDeleteMutation = { __typename?: 'Mutation', exclusionOptionDelete?: { __typename?: 'ExclusionOptionCRUD', healthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null, exclusionOption?: { __typename?: 'ExclusionOption', id: string, name?: string | null, description?: string | null } | null } | null };


export const ExclusionOptionDeleteDocument = gql`
    mutation ExclusionOptionDelete($id: ID!) {
  exclusionOptionDelete(id: $id) {
    healthProgramErrors {
      field
      message
      code
    }
    exclusionOption {
      id
      name
      description
    }
  }
}
    `;
export type ExclusionOptionDeleteMutationFn = Apollo.MutationFunction<ExclusionOptionDeleteMutation, ExclusionOptionDeleteMutationVariables>;

/**
 * __useExclusionOptionDeleteMutation__
 *
 * To run a mutation, you first call `useExclusionOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExclusionOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exclusionOptionDeleteMutation, { data, loading, error }] = useExclusionOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExclusionOptionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ExclusionOptionDeleteMutation, ExclusionOptionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExclusionOptionDeleteMutation, ExclusionOptionDeleteMutationVariables>(ExclusionOptionDeleteDocument, options);
      }
export type ExclusionOptionDeleteMutationHookResult = ReturnType<typeof useExclusionOptionDeleteMutation>;
export type ExclusionOptionDeleteMutationResult = Apollo.MutationResult<ExclusionOptionDeleteMutation>;
export type ExclusionOptionDeleteMutationOptions = Apollo.BaseMutationOptions<ExclusionOptionDeleteMutation, ExclusionOptionDeleteMutationVariables>;