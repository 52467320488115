import { DataGrid } from "shared/components/GridProvider/DataGrid";
import { useBenefitGroupDeleteMutation, useHealthProgramBenefitGroupsQuery } from "../../gql";
import { useBenefitGroupColumns } from "./useBenefitGroupColumns";

export const ProgramBenefitList = () => {
  return (
    <DataGrid
      addNewLink={"new"}
      columns={useBenefitGroupColumns()}
      deleteItemProps={{
        name: "benefitGroup",
        entityTypeName: "BenefitGroup",
        useDeleteMutation: useBenefitGroupDeleteMutation,
      }}
      editLink={"edit/"}
      hasTableSetting={false}
      name={"healthProgramBenefitGroups"}
      query={useHealthProgramBenefitGroupsQuery}
      tableAction={{
        isDeleteVisible: true,
        isEditVisible: true,
      }}
    />
  );
};
