import { SurveyDetailsContextProvider } from "./state/context";
import SurveyDetailsComponent from "./SurveyDetailsPage";
import { SurveysListPage } from "./SurveysListPage";

const SurveyDetailsPage: React.FC = () => {
  return (
    <div>
      <SurveyDetailsContextProvider>
        <SurveyDetailsComponent />
      </SurveyDetailsContextProvider>
    </div>
  );
};

export { SurveysListPage, SurveyDetailsPage };
