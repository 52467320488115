import { H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors, updateCache } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { HealthProgramDocument, useHealthProgramQuery } from "gql/queries";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { HealthProgramUpdateMutation, useHealthProgramUpdateMutation } from "pages/HealthProgram/gql";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDefinitionRequestInputs, programTrackingInputVariables } from "../form/HealthProgramForm.utils";
import { IHealthProgramInput, VariablesInput } from "../types";

export const useHealthProgramEditHook = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const params = useParams() as { id: string };
  const { id } = params;

  const [errors, setErrors] = React.useState<H_EntityError[]>([]);
  const { data, loading } = useHealthProgramQuery({
    variables: {
      id: id as string,
      isAdminApp: true,
    },
    skip: !id,
  });
  const healthProgram = data?.healthProgram;

  const [healthProgramUpdate, { loading: isSubmitting }] = useHealthProgramUpdateMutation({
    onCompleted: (mutationData: HealthProgramUpdateMutation) => {
      const mutationErrors = mutationData?.healthProgramUpdate?.healthProgramErrors as H_EntityError[];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Program Updated Successfully"));
        navigate(healthProgramPaths.listPath.fullPath);
      } else {
        setErrors(mutationErrors);
        failed(formatMessageErrors(mutationErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update(cache, { data }) {
      updateCache(cache, data, HealthProgramDocument);
    },
  });

  const onSubmit = (input: IHealthProgramInput) => {
    if (!id) return;
    const benefitMap = input?.benefitGroups?.filter(item => item?.isAdded);
    const benefitGroups = benefitMap?.map(item => ({
      benefitGroup: item?.id,
      optionsValues: item?.optionsValues
        ?.filter(item => item.isChecked)
        .map(value => ({
          value: value?.value,
          name: value?.nameOption,
          option: value?.id,
        })),
      hasEmptyValue: item?.optionsValues
        ?.filter(item => item.isChecked)
        ?.some(item => item?.value == "" || !item?.nameOption || item?.value < 0),
    }));
    const request: { benefitGroup: string | undefined; optionsValues: any }[] | undefined = benefitGroups?.map(item => ({
      benefitGroup: item?.benefitGroup,
      optionsValues: item?.optionsValues.map(value => ({
        ...value,
        value: Number(value?.value),
      })),
    }));
    const checkValue = benefitGroups?.some(item => item?.hasEmptyValue);
    if (checkValue) {
      return;
    } else {
      delete input.payerId;
      delete input?.type;
      healthProgramUpdate({
        variables: {
          id,
          input: getDefinitionRequestInputs(input, request),
        },
      } as VariablesInput);
    }
  };

  const handleProgramTracking = input => {
    healthProgramUpdate({
      variables: {
        id,
        input: {
          code: String(healthProgram?.code),
          name: healthProgram?.name!,
          subscriptionPeriod: healthProgram?.subscriptionPeriod!,
          ...programTrackingInputVariables(input),
        },
      },
    });
  };

  const handleCancel = () => {
    navigate(healthProgramPaths.listPath.fullPath);
  };

  return {
    errors,
    healthProgram,
    loading,
    handleCancel,
    onSubmit,
    handleProgramTracking,
    isSubmitting,
  };
};
