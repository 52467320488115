import { i18n } from "@toolkit/i18n";
import { categoriesPaths } from "./CategoriesPaths";

export const categoriesBreadcrumb = () => ({
  title: i18n.t("Product Categories", { ns: "admin" }),
  main: {
    id: "categories",
    name: i18n.t("Product Categories", { ns: "admin" }),
    route: categoriesPaths.main.fullPath,
  },
  create: {
    id: "category-new",
    name: i18n.t("New", { ns: "admin" }),
  },
  category: (fieldName: string, categoryId: string) => ({
    id: "category-update",
    name: fieldName || i18n.t("Category", { ns: "admin" }),
    route: categoriesPaths.productCategoryCategories.fullPathWithParams({ categoryId: categoryId }),
  }),
});
