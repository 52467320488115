/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackerSupplementTypesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.TrackerSupplementTypeInputFilter>;
}>;


export type TrackerSupplementTypesQuery = { __typename?: 'Query', trackerSupplementTypes?: { __typename?: 'TrackerSupplementTypeConnection', edges: Array<{ __typename?: 'TrackerSupplementTypeEdge', node: { __typename?: 'TrackerSupplementType', id: string, name: string, photo?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const TrackerSupplementTypesDocument = gql`
    query TrackerSupplementTypes($first: Int, $last: Int, $after: String, $before: String, $filter: TrackerSupplementTypeInputFilter) {
  trackerSupplementTypes(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    edges {
      node {
        id
        name
        photo
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useTrackerSupplementTypesQuery__
 *
 * To run a query within a React component, call `useTrackerSupplementTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerSupplementTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerSupplementTypesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrackerSupplementTypesQuery(baseOptions?: Apollo.QueryHookOptions<TrackerSupplementTypesQuery, TrackerSupplementTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackerSupplementTypesQuery, TrackerSupplementTypesQueryVariables>(TrackerSupplementTypesDocument, options);
      }
export function useTrackerSupplementTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackerSupplementTypesQuery, TrackerSupplementTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackerSupplementTypesQuery, TrackerSupplementTypesQueryVariables>(TrackerSupplementTypesDocument, options);
        }
export type TrackerSupplementTypesQueryHookResult = ReturnType<typeof useTrackerSupplementTypesQuery>;
export type TrackerSupplementTypesLazyQueryHookResult = ReturnType<typeof useTrackerSupplementTypesLazyQuery>;
export type TrackerSupplementTypesQueryResult = Apollo.QueryResult<TrackerSupplementTypesQuery, TrackerSupplementTypesQueryVariables>;