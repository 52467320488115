import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography, useAddToast } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { adminManagedListsPaths } from "../../../constants";
import { useCloneCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const CloneButton = ({ disabled, onClick }) => {
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const { failed } = useAddToast();
    const { code } = useAdminManagedListDetailsPageContext();
    const [cloneCodeSystem, { loading }] = useCloneCodeSystemMutation({
        onCompleted: data => {
            var _a;
            const codeId = (_a = data === null || data === void 0 ? void 0 : data.cloneCodeSystem) === null || _a === void 0 ? void 0 : _a.id;
            if (!codeId)
                return;
            navigate(adminManagedListsPaths.detailsPath.fullPathWithParams(code, codeId));
        },
        onError: data => {
            failed(t(data === null || data === void 0 ? void 0 : data.message));
        },
    });
    const handleCloneClicked = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        cloneCodeSystem({
            variables: {
                codeSystemCode: code,
            },
        });
    };
    return (_jsxs(MenuItem, { onClick: handleCloneClicked, disabled: disabled || loading, children: [_jsx(CustomIcon, { icon: 'clone' }), _jsxs(Typography, { marginLeft: '2px', children: [" ", t("Clone")] })] }));
};
CloneButton.defaultProps = {
    disabled: false,
    onClick: () => { },
};
