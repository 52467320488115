/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { CityFragmentFragmentDoc } from '../../fragments/__generated__/cityFragment';
import { CityErrorsFragmentDoc } from '../../fragments/__generated__/cityErrorsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityUpdateMutationVariables = Types.Exact<{
  cityUpdateId: Types.Scalars['ID'];
  input: Types.CityInput;
}>;


export type CityUpdateMutation = { __typename?: 'Mutation', cityUpdate?: { __typename?: 'CityUpdate', city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null, created: any } | null, cityErrors: Array<{ __typename?: 'BlockError', code: Types.BlockErrorCode, field?: string | null, message?: string | null }> } | null };


export const CityUpdateDocument = gql`
    mutation cityUpdate($cityUpdateId: ID!, $input: CityInput!) {
  cityUpdate(id: $cityUpdateId, input: $input) {
    city {
      ...CityFragment
    }
    cityErrors {
      ...CityErrors
    }
  }
}
    ${CityFragmentFragmentDoc}
${CityErrorsFragmentDoc}`;
export type CityUpdateMutationFn = Apollo.MutationFunction<CityUpdateMutation, CityUpdateMutationVariables>;

/**
 * __useCityUpdateMutation__
 *
 * To run a mutation, you first call `useCityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityUpdateMutation, { data, loading, error }] = useCityUpdateMutation({
 *   variables: {
 *      cityUpdateId: // value for 'cityUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCityUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CityUpdateMutation, CityUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CityUpdateMutation, CityUpdateMutationVariables>(CityUpdateDocument, options);
      }
export type CityUpdateMutationHookResult = ReturnType<typeof useCityUpdateMutation>;
export type CityUpdateMutationResult = Apollo.MutationResult<CityUpdateMutation>;
export type CityUpdateMutationOptions = Apollo.BaseMutationOptions<CityUpdateMutation, CityUpdateMutationVariables>;