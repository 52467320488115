import { uniqueId } from "lodash";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, MuiBreadcrumbs } from "../../base/mui";
import { TruncateTooltipTypography } from "../TruncateTypography";
import { BreadcrumbContext } from "./BreadcrumbContext";
import { useBreadcrumbsStyles } from "./Breadcrumbs.styles";

const BreadcrumbsComponent = () => {
  const { values: breadcrumbs } = useContext(BreadcrumbContext);

  const { classes } = useBreadcrumbsStyles();

  return (
    <MuiBreadcrumbs aria-label={"breadcrumb"}>
      {breadcrumbs?.values?.map(breadcrumb => {
        const uniqId = uniqueId();
        const hasRoute = !!breadcrumb?.route;

        return (
          <Link
            key={uniqId}
            underline={hasRoute ? "hover" : "none"}
            color={"inherit"}
            className={`${classes.link} ${!hasRoute ? classes.defaultPointer : ""}`}
            component={hasRoute ? RouterLink : "span"}
            to={hasRoute ? breadcrumb.route : undefined}
          >
            <TruncateTooltipTypography text={breadcrumb?.name} />
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsComponent;
