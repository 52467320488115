import { BulkProcessingStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { CancelBulkFileMutation, useCancelBulkFileMutation } from "./gql";

export const CancelBulkFile: FC<{ isButton: boolean; title: string; id: string; processingStatus: BulkProcessingStatus }> = ({
  isButton,
  title,
  id,
  processingStatus,
}) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [cancelBulkFileMutation, { loading }] = useCancelBulkFileMutation({
    onCompleted: (data: CancelBulkFileMutation) => {
      if (data?.cancelBulkFile?.errors?.length! > 0) {
        failed(t("Failed to cancel bulk file"));
      } else {
        succeeded(t("Bulk file cancelled successfully"));
      }
    },
    onError: () => {
      failed(t("Failed to cancel bulk file"));
    },
  });
  const handleGetTemplate = () => {
    cancelBulkFileMutation({
      variables: {
        fileId: id,
      },
    });
  };
  const theme = useTheme();
  return !isButton ? (
    <Typography
      fontSize={theme.mixins.fonts.fontSize.sm}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={handleGetTemplate}
    >
      {title}
    </Typography>
  ) : (
    <Button
      sx={{ paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }}
      disabled={processingStatus !== BulkProcessingStatus.Pending || loading}
      onClick={handleGetTemplate}
    >
      {title}
    </Button>
  );
};
