import { formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { useBranchUpdateMutation } from "../gql";

export const ActiveSwitch: FC<{ id; isActive; disabled: boolean }> = ({ id, isActive, disabled }) => {
  const { t, i18n } = useTranslation("admin");
  const currentDir = i18n.dir(i18n.language);
  const { open, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const [active, setActive] = useState(isActive);
  const [updateBranch] = useBranchUpdateMutation({
    onCompleted: data => {
      const errors = data?.branchUpdate?.branchErrors;
      if (errors && errors.length > 0) {
        formatMessageErrors(errors);
        failed(t("Branch Update Failed"));
      } else {
        setActive(data?.branchUpdate?.branch?.isActive);
        succeeded(t("Branch Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("Branch Update Failed"));
    },
  });

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const handleActive = () => {
    updateBranch({
      variables: {
        id: id,
        branch: {
          isActive: !active,
        },
      },
    });
  };

  return (
    <>
      <CustomDialog
        type='warning'
        button={<CustomToggleButton dir={currentDir} checked={active} disabled={disabled} onChange={handleToggle} />}
        isOpen={open}
        text={{
          title: active ? t("Deactivate Branch Confirmation") : t("Activate Branch Confirmation"),
          body: active ? t("Deactivating selected branch") : t("Activate selected branch"),
        }}
        onClose={handleToggle}
        onConfirm={handleActive}
      />
    </>
  );
};
