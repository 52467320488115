import { AccountError, AdminUserCreateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  providerUserManagementListBreadcrumb,
  providerUserManagementNewBreadcrumb,
  providerUserManagementTitleBreadcrumb,
} from "pages/UserManagement/constants/provider-user-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateUserMutation, useCreateUserMutation } from "../../gql";
import { getUserCreateInfo } from "../UserManagement.utils";

export const useHealthProviderUserManagementAddHook = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const [createUserMutation, { loading: isCreateLoading }] = useCreateUserMutation({
    onCompleted: (mutationData: CreateUserMutation) => {
      const error = mutationData?.userCreate?.accountErrors as AccountError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("User created successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed in creating user"));
    },
  });

  const handleCreate = (inputData: AdminUserCreateInput | any) => {
    const fromDate = inputData?.doctorInfo?.qualifications?.fromDate;
    const toDate = inputData?.doctorInfo?.qualifications?.toDate;

    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      if (fromDateObj > toDateObj) {
        failed("From Date should be less than To Date");
        return;
      }
    }

    createUserMutation({
      variables: {
        input: getUserCreateInfo(inputData),
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: providerUserManagementTitleBreadcrumb(t),
      values: [providerUserManagementListBreadcrumb(t), providerUserManagementNewBreadcrumb(t)],
    });
  }, []);

  return {
    errors,
    handleCreate,
    isCreateLoading,
  };
};
