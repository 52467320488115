export const checkImage = (image: any) => {
  if (typeof image === "string") {
    return null;
  } else {
    return image;
  }
};

export const bankNameOptions = [
  { name: "Alawwal Bank", value: "Alawwal Bank" },
  { name: "Al Bilad Bank", value: "Al Bilad Bank" },
  { name: "Arab National Bank", value: "Arab National Bank" },
  { name: "Bank Aljazira", value: "Bank Aljazira" },
  { name: "Bank Muscat", value: "Bank Muscat" },
  { name: "Banque Saudi Fransi", value: "Banque Saudi Fransi" },
  { name: "Emirates Bank", value: "Emirates Bank" },
  { name: "Gulf International Bank", value: "ulf International Bank" },
  { name: "AL INMA BANK", value: "AL INMA BANK" },
  { name: "National Bank Of Kuwait", value: "National Bank Of Kuwait" },
  { name: "National Bank Of Pakistan", value: "National Bank Of Pakistan" },
  { name: "National Commercial Bank", value: "National Commercial Bank" },
  { name: "Riyadh Bank", value: "Riyadh Bank" },
  { name: "Al Rajhi Bank", value: "Al Rajhi Bank" },
  { name: "Saudi American Bank SAMBA", value: "Saudi American Bank SAMBA" },
  { name: "State Bank Of India", value: "State Bank Of India" },
  { name: "Saudi Investment Bank", value: "Saudi Investment Bank" },
  { name: "Turkiye Cumhuriyeti Ziraat Bankasi", value: "Turkiye Cumhuriyeti Ziraat Bankasi" },
];
