import React, { ReactElement, useEffect, useState } from "react";
import { Card, TableContainer } from "../../base/mui";
import { TableBodyWithoutDataView } from "./components/TableBodyWithoutDataView";
import CustomTableFooter from "./components/TableFooter";
import TableLayout from "./components/TableLayout";
import { useCustomTableStyles } from "./styles/useCustomTableStyles";
import { useScrollableTableStyle } from "./styles/useScrollableTableStyle";
import { CustomTableProps } from "./types";

const noop = () => undefined;

const CustomTable = <RowType extends object>(props: CustomTableProps<RowType>): ReactElement => {
  const {
    data = [],
    columns,
    extraHeaderComponent,
    withoutDataMessage,
    emptyIconHeight,
    title,
    TableContainerProps = {},
    isCardView,
    iconBoxProps,
    selectionProps,
    isLoading,
    onEditRow: handleEditRow = noop,
    onRowClick: handleRowClick = noop,
    onDeleteRow: handleDeleteRow = noop,
    onSortColumn: handleSortColumn = noop,
    hasFooter = true,
    scrollable = false,
    ...rest
  } = props;
  const footerProps = {
    footerHeight: 60,
    hasNextPage: false,
    hasPreviousPage: false,
    pageIndex: 0,
    pagesCount: 0,
    pageSize: 10,
    totalCount: 0,
    onGoToNext: noop,
    onGotoPage: noop,
    onGoToPrevious: noop,
    onPageSizeChange: noop,
    ...rest,
  };
  const { classes } = useCustomTableStyles({ isCardView: !!isCardView });
  const { classes: scrollableClasses, cx } = useScrollableTableStyle({ minHeight: props.minHeight, maxHeight: props.maxHeight });
  const [dataStatus, setDataStatus] = useState<"loading" | "loaded" | "updated">("loading");

  useEffect(() => {
    if (isLoading) return;
    if (data.length) {
      setDataStatus(status => (status === "loading" ? "loaded" : "updated"));
    }
  }, [data, isLoading]);

  return (
    <Card
      sx={{
        overflow: "hidden",
        position: "relative",
      }}
      className={cx(classes.root, scrollable && scrollableClasses.root)}
      elevation={0}
      id='ui-table'
    >
      <TableContainer
        className={cx(classes.tableContainer, scrollable && scrollableClasses.tableContainer)}
        sx={{ minHeight: props.minHeight || "500px" }}
        {...TableContainerProps}
      >
        <TableLayout
          pageSize={footerProps?.pageSize}
          title={title}
          data={data}
          isCardView={isCardView}
          columns={columns}
          isLoading={isLoading}
          isDeleteVisible={props.isDeleteVisible}
          isEditVisible={props.isEditVisible}
          isRowEditable={props.isRowEditable}
          isRowDeletable={props.isRowDeletable}
          extraHeaderComponent={extraHeaderComponent}
          onRowClick={handleRowClick}
          onEditRow={handleEditRow}
          onDeleteRow={handleDeleteRow}
          onSortColumn={handleSortColumn}
          extraActionsRenderer={props.extraActionsRenderer}
          selectionProps={selectionProps}
        />
      </TableContainer>
      {data?.length === 0 && !isLoading && (
        <TableBodyWithoutDataView
          isScrollableTable={scrollable}
          withoutDataMessage={withoutDataMessage}
          emptyIconHeight={emptyIconHeight}
          isFilterApplied={dataStatus === "updated"}
          iconBoxProps={iconBoxProps}
        />
      )}
      {hasFooter && <CustomTableFooter {...footerProps} />}
    </Card>
  );
};

export default CustomTable;
