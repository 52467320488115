export const settingsRoute = "/settings";

export const settingsPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${settingsRoute}${this.route}`;
    },
  },
};
