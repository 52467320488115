import { TFunction } from "@toolkit/i18n";
import { subListsPaths } from "./SublistsPaths";

export const subListsBreadcrumbs = (t: TFunction) => ({
  title: t("Sub Lists Management", { ns: "admin" }),
  main: {
    id: "sub-lists",
    name: t("Sub Lists", { ns: "admin" }),
    route: subListsPaths.main.fullPath,
  },
  create: {
    id: "sub-list-new",
    name: t("New", { ns: "admin" }),
  },
  update: {
    id: "sub-list-update",
    name: t("Edit", { ns: "admin" }),
  },
  itemTitle: t("Sub List Item"),
  updateItem: {
    id: "sub-list-item-update",
    name: t("Item", { ns: "admin" }),
  },
});
