import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ChatFlowDocument = gql `
    query ChatFlow($chatFlowId: ID!) {
  chatFlow(id: $chatFlowId) {
    code
    conditionsAndRules
    createdAt
    description
    id
    name
    updatedAt
  }
}
    `;
/**
 * __useChatFlowQuery__
 *
 * To run a query within a React component, call `useChatFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatFlowQuery({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *   },
 * });
 */
export function useChatFlowQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ChatFlowDocument, options);
}
export function useChatFlowLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ChatFlowDocument, options);
}
