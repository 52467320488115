import { replacePathParams } from "utils";

export const healthProgramsNetworksRoute = "/networks";
const healthProgramsNetworksNewRoute = "new";
const healthProgramsNetworksEditRoute = "edit/:id";

const healthProgramsNetworksProvidersRoute = ":id/providers";
const healthProgramsNetworksProvidersNewRoute = `${healthProgramsNetworksProvidersRoute}/new`;
const healthProgramsNetworksProvidersEditRoute = `${healthProgramsNetworksProvidersRoute}/edit/:providerId`;
const healthProgramsNetworksProvidersProgramRoute = `${healthProgramsNetworksProvidersRoute}/:programId`;
const healthProgramsNetworksProvidersProfessionalsRoute = `${healthProgramsNetworksProvidersRoute}/:providerId/professionals`;

export const networksPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthProgramsNetworksRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthProgramsNetworksNewRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
  },
  editPath: {
    route: healthProgramsNetworksEditRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  providersPath: {
    route: healthProgramsNetworksProvidersRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  providersNewPath: {
    route: healthProgramsNetworksProvidersNewRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  providersEditPath: {
    route: healthProgramsNetworksProvidersEditRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, providerId: string) {
      return replacePathParams(this.fullPath, { id, providerId });
    },
  },
  providersProgramPath: {
    route: healthProgramsNetworksProvidersProgramRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, programId: string) {
      return replacePathParams(this.fullPath, { id, programId });
    },
  },
  providersProfessionalsPath: {
    route: healthProgramsNetworksProvidersProfessionalsRoute,
    get fullPath() {
      return `${healthProgramsNetworksRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string, providerId: string) {
      return replacePathParams(this.fullPath, { id, providerId });
    },
  },
};
