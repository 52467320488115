import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const teamLeaderDoctorAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const TeamLeaderDoctorAutocompleteSchemas = {
    teamLeaderDoctorAutocomplete,
    teamLeaderDoctorAutocompleteOptional: teamLeaderDoctorAutocomplete.nullable().optional(),
};
