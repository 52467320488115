import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthProgramMemberAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const HealthProgramMemberAutocompleteSchemas = {
    healthProgramMemberAutocomplete,
    healthProgramMemberAutocompleteOptional: healthProgramMemberAutocomplete.nullable().optional(),
};
