import { Branch } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomDialog,
  CustomIcon,
  formatTime,
  getDayString,
  Grid,
  ShowButton,
  TextField,
  Tooltip,
  Typography,
} from "@toolkit/ui";
import React, { FC } from "react";
import { getWorkingHoursWithTimeZone } from "../../../shared/components";
import { useGetTimeZoneQuery } from "../../UserManagement/gql";
import { useWorkingHoursStyles } from "./WorkingHoursBranch.styles";

export const WorkingHoursBranch: FC<{ branch: Branch }> = ({ branch: { workingHours, name } }) => {
  const { t, i18n } = useTranslation("admin");
  const { classes } = useWorkingHoursStyles();
  const { data: setting } = useGetTimeZoneQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;
  const getWorkingHours = getWorkingHoursWithTimeZone(workingHours, timeZone);
  const currentDir = i18n.dir(i18n.language);

  return (
    <CustomDialog
      type='info'
      button={<ShowButton />}
      title={t("Branch Working Hours Info")}
      DialogContentProps={{ classes: { root: classes.dialogContent } }}
    >
      {!workingHours?.length ? (
        <Typography dir={currentDir} variant={"body1"}>
          {t("There is No Data")}
        </Typography>
      ) : (
        <>
          <Accordion square elevation={0}>
            <AccordionSummary
              expandIcon={<CustomIcon icon={"expandArrow"} />}
              aria-controls='WorkingHours'
              id='WorkingHours'
              classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
            >
              <Tooltip
                title={t("Working Hours for {{ branch }} Branch", { branch: String(name) })}
                sx={{
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              >
                <Typography dir={currentDir} component='div' overflow='hidden' textOverflow='ellipsis'>
                  {t("Working Hours for {{ branch }} Branch", { branch: String(name) })}
                </Typography>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {getWorkingHours?.map(workingHours =>
                  workingHours?.openTimeRanges?.map((timeRange, index: number) => (
                    <React.Fragment key={String(workingHours?.day) + index}>
                      <Grid item xs={4}>
                        <TextField
                          dir={currentDir}
                          size='small'
                          label={t("Day")}
                          value={t(getDayString(String(workingHours?.day)))}
                          className={index !== 0 ? classes.hiddenInput : classes.input}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          dir={currentDir}
                          size='small'
                          placeholder={t(timeRange?.openTime)}
                          label={t("Open Time")}
                          value={formatTime(timeRange?.openTime, i18n.language)}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          dir={currentDir}
                          size='small'
                          placeholder={t(timeRange?.closeTime)}
                          label={t("Close Time")}
                          value={formatTime(timeRange?.closeTime, i18n.language)}
                          disabled
                        />
                      </Grid>
                    </React.Fragment>
                  ))
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </CustomDialog>
  );
};
