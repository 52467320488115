import { Dispatch, SetStateAction } from "react";
import { CustomTableColumnProps, OrderDirection } from "../types";
import { handleSortGeneric, nextSortDirection } from "../utils/countableConnection";
import { get } from "lodash";

export const useTableSort = <XQueryVariables, Z extends object>(
  variables: XQueryVariables,
  pageSize: number,
  setVariables: Dispatch<SetStateAction<XQueryVariables>>,
  setColumns: Dispatch<SetStateAction<CustomTableColumnProps<Z>[]>>
) => {
  const doSort = (field: string, _input?: SetStateAction<XQueryVariables>) => {
    setVariables(
      _input ||
        (state => {
          const direction = nextSortDirection(get(state, "sortBy.direction") as OrderDirection);
          return {
            ...state,
            first: pageSize,
            after: null,
            last: null,
            before: null,
            sortBy: direction
              ? {
                  direction,
                  field,
                }
              : null,
          };
        })
    );
  };
  const handleSortData = handleSortGeneric({ setColumns, doSort, direction: get(variables, "sortBy.direction") as OrderDirection });

  return {
    handleSortData,
  };
};
