/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type PayerFragmentFragment = { __typename?: 'Payer', id: string, created: any, name: string, nameAr?: string | null, contactName?: string | null, contactPhoneNumber?: string | null, contactMobileNumber?: string | null, contactEmail?: string | null, isActive: boolean, licenseNumber: string, logo?: string | null, backgroundImage?: string | null, tpo: Types.PayerTpo, type: Types.PayerType, parent?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null };

export const PayerFragmentFragmentDoc = gql`
    fragment PayerFragment on Payer {
  id
  created
  name
  nameAr
  contactName
  contactPhoneNumber
  contactMobileNumber
  contactEmail
  isActive
  licenseNumber
  logo
  backgroundImage
  tpo
  type
  parent {
    id
    name
    nameAr
  }
}
    `;