import { PaletteColor } from "@mui/material";
import { Theme } from "../../base/mui";
import { commonColors, gray, green, midnightBlue, orange, red, roseBudCherry, stale, yellow } from "./colors";

export const CustomThemePalette = (theme: Theme) => ({
  mode: "light",
  divider: "#DFE8F2",

  background: {
    default: gray[50]!,
    paper: commonColors.White,
    loginFields: "#F1F2FB",
  },
  text: {
    primary: midnightBlue.main,
    secondary: roseBudCherry.main,
    disabled: gray[500]!,
  },
  primary: {
    ...(midnightBlue as PaletteColor),
    light: midnightBlue[50]!,
    main: midnightBlue.main,
    dark: midnightBlue[1300]!,
  },
  secondary: {
    ...(roseBudCherry as PaletteColor),
    main: roseBudCherry.main,
    dark: roseBudCherry[1300]!,
  },
  success: {
    ...(green as PaletteColor),
    main: green.main,
  },
  warning: {
    ...(orange as PaletteColor),
    main: orange.main,
  },
  error: {
    ...(red as PaletteColor),
    light: red[200]!,
    main: red.main,
  },
  info: {
    ...(stale as PaletteColor),
    main: stale.main,
  },
  common: {
    black: commonColors.Black,
    white: commonColors.White,
    ...commonColors,
  },

  // ** Custom Colors **
  gray: theme.palette.augmentColor({
    color: {
      ...gray,
      main: gray.main,
      light: gray[200],
    },
    name: "gray",
  }),
  stale: theme.palette.augmentColor({
    color: {
      ...stale,
      main: stale.main,
    },
    name: "stale",
  }),
  yellow: theme.palette.augmentColor({
    color: {
      ...yellow,
      main: yellow.main,
    },
    name: "yellow",
  }),
});
