import React, { FC, Fragment, useContext } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "../../base/mui";
import { MuiExpandMoreIcon } from "../../base/mui-icons";
import { MenuItemContext } from "./context";
import { useSidebarStyles } from "./Sidebar.styles";
import { SidebarItemProps, SidebarListProps } from "./Sidebar.types";

export const SidebarItem: FC<SidebarItemProps> = props => {
  const {
    onNavigate,
    data: { icon, text, onClick: onItemClick = undefined, subItems, selected, fullPath, id },
    itemLevel,
  } = props;
  const subItemsFiltered = subItems?.filter(item => item?.hidden !== true) || [];
  const { id: selectedId, setId } = useContext(MenuItemContext);
  const isItemSelected = Boolean(id == selectedId || selected || subItems?.some(item => item?.selected));

  const { classes } = useSidebarStyles({ selected: isItemSelected, itemLevel });

  const handleItemPressed = () => {
    setId?.(id == selectedId ? undefined : id);
    if (!subItemsFiltered?.length) {
      if (onNavigate) {
        onNavigate(fullPath!);
      } else {
        onItemClick && onItemClick(fullPath!);
      }
    }
  };

  return (
    <Fragment>
      <ListItem selected={isItemSelected} className={classes.listItem} button onClick={handleItemPressed}>
        {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
        <ListItemText className={classes.listItemText}>{text}</ListItemText>
        {!!subItemsFiltered?.length && itemLevel <= 1 && <MuiExpandMoreIcon fontSize='small' />}
      </ListItem>
      {subItems &&
        (itemLevel <= 1 ? (
          <Collapse timeout='auto' unmountOnExit classes={{ root: classes.root }} in={isItemSelected}>
            <SidebarList itemLevel={itemLevel} data={subItems} disablePadding />
          </Collapse>
        ) : (
          <SidebarList itemLevel={itemLevel} data={subItems} disablePadding />
        ))}
    </Fragment>
  );
};

export const SidebarList: FC<SidebarListProps> = props => {
  const { data, onNavigate: handleNavigate, itemLevel = 0, ...rest } = props;
  return (
    <List
      {...rest}
      sx={{
        "ul:first-of-type div": {
          paddingLeft: "23px",
        },
      }}
    >
      {data?.map(item => {
        let itemData = item;
        if (item?.subItems?.length && item?.subItems?.filter(itemRow => !itemRow?.hidden)?.length <= 1) {
          itemData = item?.subItems[0];
          itemData.icon = item?.icon;
          itemData.text = item?.text;
          itemData.selected = false;
        }
        return (
          !item.hidden &&
          !item?.isProhibited && <SidebarItem itemLevel={itemLevel + 1} key={itemData?.id} data={itemData} onNavigate={handleNavigate} />
        );
      })}
    </List>
  );
};
