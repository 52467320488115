import { Components, Theme } from "@mui/material/styles";

export const MuiInputLabelOverride: Components<Theme>["MuiInputLabel"] = {
  // Check it
  styleOverrides: {
    root: ({ theme }) => ({
      marginInline: 5,
      color: theme.palette.stale?.main,
      fontSize: theme.mixins.fonts.fontSize.sm,
      "&.MuiFormLabel-colorSecondary": {
        color: theme.palette.success.contrastText,
      },
      "&.MuiFormLabel-colorError": {
        color: theme.palette.error.contrastText,
      },
    }),
    shrink: ({ theme }) => ({
      fontSize: theme.mixins.fonts.fontSize.xxs,
    }),
  },
};
