import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, ToggleButtonController } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formButtonTitle } from "shared/utils";
import { healthProgramNetworkProviderProps } from "../types";
import { Providers } from "./Providers";
import { ServicesAutocomplete } from "./ServicesAutocomplete";

export const HealthProgramNetworkProviderForm: FC<healthProgramNetworkProviderProps> = props => {
  const { defaultValue, isLoading, errors, onDone, isSubmitting } = props;
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const { ...methods } = useForm();
  const { handleSubmit, register, control } = methods;

  const onSubmit = data => {
    onDone(data);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle(t, isLoading, defaultValue)}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <FormCard loading={isLoading} doYouHaveData title={t("General Information")}>
            <Grid container spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <Providers defaultValue={defaultValue} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                  <ServicesAutocomplete defaultValue={defaultValue} errors={errors} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <ToggleButtonController
                  {...register("isActive")}
                  control={control}
                  defaultValue={defaultValue?.isActive}
                  label={t("Is Active")}
                />
              </Grid>

              <Grid item xs={4}>
                <ToggleButtonController
                  {...register("virtualCallEnabled")}
                  control={control}
                  defaultValue={defaultValue?.virtualCallEnabled}
                  label={t("Virtual Call Enabled")}
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
