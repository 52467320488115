import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ChatFlowDeleteDocument = gql `
    mutation ChatFlowDelete($chatFlowDeleteId: ID!) {
  chatFlowDelete(id: $chatFlowDeleteId) {
    documents {
      id
    }
  }
}
    `;
/**
 * __useChatFlowDeleteMutation__
 *
 * To run a mutation, you first call `useChatFlowDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowDeleteMutation, { data, loading, error }] = useChatFlowDeleteMutation({
 *   variables: {
 *      chatFlowDeleteId: // value for 'chatFlowDeleteId'
 *   },
 * });
 */
export function useChatFlowDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChatFlowDeleteDocument, options);
}
