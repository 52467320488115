import { ExclusionOption } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, makeStyles } from "@toolkit/ui";
import moment from "moment";
import { FC, useMemo } from "react";

const useStyle = makeStyles()(theme => ({
  textStyle: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
const TextHeader: FC<{ label: string }> = ({ label }) => {
  const { classes } = useStyle();
  return <div className={classes.textStyle}>{label}</div>;
};

export const useExclusionsColumn = (): CustomTableColumnProps<ExclusionOption>[] => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: <TextHeader label={t("Name")} />,
        accessor: ({ name }) => name,
        type: "string",
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      {
        key: "description",
        header: <TextHeader label={t("Description")} />,
        accessor: "description",
        type: "string",
      },
      {
        key: "created",
        header: <TextHeader label={t("Creation Date")} />,
        accessor: ({ created }) => moment(created).format("DD MMM YYYY"),
        type: "string",
      },
    ];
  }, []);
};
