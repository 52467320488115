import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  noPhoto: {
    width: 500,
    padding: 10,
  },
  imageListItem: {
    "&.MuiImageListItem-img": {
      width: "359px",
      height: "370px",
      margin: "10px",
      objectFit: "contain",
    },
  },
  mainImage: {
    width: "50%",
  },
});
