import moment from "moment-timezone";
export const getWorkingHoursWithTimeZone = (workingHours, timeZone) => {
  return workingHours.map(item => {
    const openTimeRanges = item?.openTimeRanges?.map(timeRange => {
      const openTime = moment(timeRange?.openTime, "HH:mm:ss");
      const timeWithoutTimeZone = openTime.subtract(timeZone, "hours").format("HH:mm:ss");
      const momentOpenTime = moment.tz(timeWithoutTimeZone, "HH:mm:ss", "UTC");
      const currentOpenTimeZone = momentOpenTime.clone().local().format("HH:mm:ss");

      const closeTime = moment(timeRange?.closeTime, "HH:mm:ss");
      const timeCloseTimeWithoutTimeZone = closeTime.subtract(timeZone, "hours").format("HH:mm:ss");
      const momentCloseTime = moment.tz(timeCloseTimeWithoutTimeZone, "HH:mm:ss", "UTC");
      const currentCloseTimeZone = momentCloseTime.clone().local().format("HH:mm:ss");

      return {
        ...timeRange,
        openTime: timeRange?.openTime ? currentOpenTimeZone : null,
        closeTime: timeRange?.closeTime ? currentCloseTimeZone : null,
      };
    });

    return {
      ...item,
      openTimeRanges,
    };
  });
};

export const requestWorkingHoursWithTimeZone = (workingHours, timeZone = 3) => {
  return workingHours.map(item => {
    const now = moment();

    const currentOffsetHours = now.utcOffset() / 60;

    const openTimeRanges = item?.openTimeRanges?.map(timeRange => {
      const openTime = moment(timeRange?.openTime, "HH:mm:ss");
      const timeWithoutTimeZone = openTime.subtract(currentOffsetHours, "hours");
      const currentOpenTimeZone = timeWithoutTimeZone.add(timeZone, "hours").format("HH:mm:ss");

      const closeTime = moment(timeRange?.closeTime, "HH:mm:ss");
      const timeCloseTimeWithoutTimeZone = closeTime.subtract(currentOffsetHours, "hours");
      const currentCloseTimeZone = timeCloseTimeWithoutTimeZone.add(timeZone, "hours").format("HH:mm:ss");

      return {
        ...timeRange,
        openTime: timeRange?.openTime ? currentOpenTimeZone : null,
        closeTime: timeRange?.closeTime ? currentCloseTimeZone : null,
      };
    });

    return {
      ...item,
      openTimeRanges,
    };
  });
};
