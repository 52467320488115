import { BulkFilesOrderDirection, BulkFileSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { reviewPaeUploadTitleBreadcrumb } from "pages/UploadPages/constants/review-page-upload-breadcrumbs";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { useBulkFilesQuery } from "./gql";
import { useUploadBulkFilesColumns } from "./UploadFileColumns";

export const UploadPageReviewList: FC = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: reviewPaeUploadTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='bulkFiles'
      columns={useUploadBulkFilesColumns()}
      variables={{
        sortBy: {
          direction: BulkFilesOrderDirection.Desc,
          field: BulkFileSortField.UpdatedDate,
        },
      }}
      query={useBulkFilesQuery}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
