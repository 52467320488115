import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DownloadCodeSystemFileDocument = gql `
    query downloadCodeSystemFile($code: CodeSystemCode!, $codeSystemId: ID) {
  downloadCodeSystemFile(code: $code, codeSystemId: $codeSystemId)
}
    `;
/**
 * __useDownloadCodeSystemFileQuery__
 *
 * To run a query within a React component, call `useDownloadCodeSystemFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCodeSystemFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCodeSystemFileQuery({
 *   variables: {
 *      code: // value for 'code'
 *      codeSystemId: // value for 'codeSystemId'
 *   },
 * });
 */
export function useDownloadCodeSystemFileQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadCodeSystemFileDocument, options);
}
export function useDownloadCodeSystemFileLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadCodeSystemFileDocument, options);
}
