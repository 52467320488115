import { AudioExtensions, FileType, ImageExtensions, VideoExtensions } from "../types/files";

export const detectFileType = (url: string): FileType => {
  const extension = url?.split(".")?.pop()?.toLowerCase();

  if (extension && Object.values(ImageExtensions).includes(extension as ImageExtensions)) {
    return FileType.IMAGE;
  }

  if (extension && Object.values(AudioExtensions).includes(extension as AudioExtensions)) {
    return FileType.AUDIO;
  }

  if (extension && Object.values(VideoExtensions).includes(extension as VideoExtensions)) {
    return FileType.VIDEO;
  }

  return FileType.UNKNOWN;
};
