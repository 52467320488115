import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal, ShowButton, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { ProductCategoriesQueryNode } from "../../types";
import { MarketplaceProductSortingField, SortDirection } from "@health/queries/types";
import { useNavigate } from "react-router-dom";
import { categoriesPaths } from "pages/MarketplaceProductCategories/constants";
import { getYesNoAutocompleteFilter } from "@health/enum-options";
import { getMarketplaceProductCategoriesAutocompleteFilter } from "@health/autocompletes";

export const useCategoriesListTableColumns = (): CustomTableColumnProps<ProductCategoriesQueryNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const onShowSubCategoriesClick = (id: string) => {
    navigate(categoriesPaths.productCategoryCategories.fullPathWithParams({ categoryId: id }));
  };

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ image }) => (
          <GridImageModal
            image={image!}
            title={t("Category Image")}
            fallbackText={t("Category Image Not Provided")}
            altText={t("Product Category")}
          />
        ),
      },
      {
        key: "name",
        header: t("Name"),
        accessor: pickLocalizedValue("name", "nameAr"),
        filter: {
          type: "string",
          name: pickLocalizedValue("name", "nameAr"),
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: pickLocalizedValue(MarketplaceProductSortingField.Name, MarketplaceProductSortingField.NameAr),
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description, descriptionAr }) => <TruncateTypography text={pickLocalizedValue(description!, descriptionAr!)} />,
      },
      {
        key: "subcategories",
        header: t("Sub Categories"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowSubCategoriesClick(id)} />,
      },
      {
        key: "isParent",
        header: t("Is Parent"),
        filter: {
          ...getYesNoAutocompleteFilter({ name: "parent" }),
          label: t("Is Parent"),
        },
        showOnlyForFilterField: true,
      },
      {
        key: "parentId",
        header: t("Parent"),
        showOnlyForFilterField: true,
        filter: getMarketplaceProductCategoriesAutocompleteFilter({ name: "parentId" }),
      },
    ];
  }, []);
};
