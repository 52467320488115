import { Base64 } from "js-base64";

const getWindowRunTimeEnv = () => {
  const CONFIG_FUNCTION_NAME = "getEncodedRuntimeEnv";
  if (typeof window === "undefined") {
    return null;
  }
  if (typeof (window as any)[CONFIG_FUNCTION_NAME] === "function") {
    const getEncodedRuntimeEnv: () => string = (window as any)[CONFIG_FUNCTION_NAME];

    return JSON.parse(Base64.decode(getEncodedRuntimeEnv()).toString());
  }
};

/**
 * this function must be used in the app were ever you need to read form .env, or unexpected behaviour might happen
 * please focus in review that no direct process.env.something exist any where or feature will not work in many envs
 *
 * @param baseKey  the key that you expect to have in .env/.env.js file.
 * @returns
 */
export const setDefaultEnvVariables = <T extends Record<string, string | undefined>>(defaultVariables: T) => {
  type EnvVariableKey = keyof T;

  // eslint-disable-next-line max-statements
  return (baseKey: EnvVariableKey, fallBack?: string): string => {
    if (typeof window === "undefined") {
      return defaultVariables?.[baseKey] || fallBack || "";
    }

    let runTimeVars = getWindowRunTimeEnv();
    if (!runTimeVars) {
      runTimeVars = getWindowRunTimeEnv();
    }
    if (runTimeVars && runTimeVars?.[baseKey]?.trim()) {
      return runTimeVars?.[baseKey]?.trim() || fallBack || "";
    }
    if (defaultVariables?.[baseKey]) {
      return defaultVariables?.[baseKey] || fallBack || "";
    } else {
      if (fallBack) return fallBack;
      console.error({ seriousError: `env variable: ${String(baseKey)} was not found`, env: process.env });
      return "";
    }
  };
};
