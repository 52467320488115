import React from "react";
import { useTheme } from "../../base/mui";
export const ProgramLogicIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
      <path
        id='tune_FILL1_wght400_GRAD0_opsz48'
        d='M15.807,29V21.813h1.917v2.651H29v1.917H17.724V29ZM6,26.381V24.464h7.89v1.917Zm5.974-5.3V18.458H6V16.542h5.974V13.858H13.89v7.219Zm3.833-2.619V16.542H29v1.917Zm5.3-5.271V6h1.917V8.619H29v1.917H23.026v2.651ZM6,10.536V8.619H19.193v1.917Z'
        transform='translate(-6 -6)'
        fill={palette.primary.main}
      />
    </svg>
  );
};
