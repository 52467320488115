/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
}>;


export type ProviderListQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string, name: string } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const ProviderListDocument = gql`
    query providerList($after: String, $before: String, $first: Int, $last: Int, $filter: VendorFilterInput) {
  vendors(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    totalCount
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useProviderListQuery__
 *
 * To run a query within a React component, call `useProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProviderListQuery(baseOptions?: Apollo.QueryHookOptions<ProviderListQuery, ProviderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderListQuery, ProviderListQueryVariables>(ProviderListDocument, options);
      }
export function useProviderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderListQuery, ProviderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderListQuery, ProviderListQueryVariables>(ProviderListDocument, options);
        }
export type ProviderListQueryHookResult = ReturnType<typeof useProviderListQuery>;
export type ProviderListLazyQueryHookResult = ReturnType<typeof useProviderListLazyQuery>;
export type ProviderListQueryResult = Apollo.QueryResult<ProviderListQuery, ProviderListQueryVariables>;