/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemDefinitionsSummeryQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetCodeSystemDefinitionsSummeryQuery = { __typename?: 'Query', getCodeSystemDefinitionsSummery?: Array<{ __typename?: 'CodeSystemSummery', iconUrl?: string | null, activeCodeSystem?: { __typename?: 'CodeSystem', id?: string | null, display?: string | null, processingStatus?: Types.ProcessingStatus | null, isActive?: boolean | null, version?: number | null, codeSystemHistory?: Array<{ __typename?: 'CodeSystemHistory', id?: string | null, updateDate?: string | null, processingStatus?: Types.ProcessingStatus | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null> | null } | null, codeSystemDefinition?: { __typename?: 'CodeSystemDefinition', id?: string | null, code?: Types.CodeSystemCode | null, display?: string | null, editorUserId?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, reviewerUserId?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null, lastCodeSystem?: { __typename?: 'CodeSystem', id?: string | null, processingStatus?: Types.ProcessingStatus | null, codeSystemHistory?: Array<{ __typename?: 'CodeSystemHistory', id?: string | null, processingStatus?: Types.ProcessingStatus | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null> | null } | null } | null> | null };


export const GetCodeSystemDefinitionsSummeryDocument = gql`
    query getCodeSystemDefinitionsSummery($name: String) {
  getCodeSystemDefinitionsSummery(name: $name) {
    activeCodeSystem {
      id
      display
      processingStatus
      codeSystemHistory {
        id
        updateDate
        processingStatus
        user {
          firstName
          lastName
        }
      }
      isActive
      version
    }
    codeSystemDefinition {
      id
      code
      display
      editorUserId {
        firstName
        lastName
      }
      reviewerUserId {
        firstName
        lastName
      }
    }
    lastCodeSystem {
      id
      processingStatus
      codeSystemHistory {
        id
        processingStatus
        user {
          firstName
          lastName
        }
      }
    }
    iconUrl
  }
}
    `;

/**
 * __useGetCodeSystemDefinitionsSummeryQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemDefinitionsSummeryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemDefinitionsSummeryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemDefinitionsSummeryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCodeSystemDefinitionsSummeryQuery(baseOptions?: Apollo.QueryHookOptions<GetCodeSystemDefinitionsSummeryQuery, GetCodeSystemDefinitionsSummeryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemDefinitionsSummeryQuery, GetCodeSystemDefinitionsSummeryQueryVariables>(GetCodeSystemDefinitionsSummeryDocument, options);
      }
export function useGetCodeSystemDefinitionsSummeryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemDefinitionsSummeryQuery, GetCodeSystemDefinitionsSummeryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemDefinitionsSummeryQuery, GetCodeSystemDefinitionsSummeryQueryVariables>(GetCodeSystemDefinitionsSummeryDocument, options);
        }
export type GetCodeSystemDefinitionsSummeryQueryHookResult = ReturnType<typeof useGetCodeSystemDefinitionsSummeryQuery>;
export type GetCodeSystemDefinitionsSummeryLazyQueryHookResult = ReturnType<typeof useGetCodeSystemDefinitionsSummeryLazyQuery>;
export type GetCodeSystemDefinitionsSummeryQueryResult = Apollo.QueryResult<GetCodeSystemDefinitionsSummeryQuery, GetCodeSystemDefinitionsSummeryQueryVariables>;