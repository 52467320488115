import { Components, Theme } from "../../../base/mui";

export const MuiButtonOverride: Components<Theme>["MuiButton"] = {
  defaultProps: {
    variant: "contained",
  },
  styleOverrides: {
    root: {
      borderRadius: 10,
      fontWeight: 500,
      boxShadow: "none",
      paddingInline: 15,
      paddingTop: 11,
      paddingBottom: 11,
      marginInline: 5,
      textTransform: "unset",
    },
    outlined: ({ theme }) => ({
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        "&.MuiButton-outlinedPrimary": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        "&.MuiButton-outlinedError": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
        "&.MuiButton-outlinedSuccess": {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
        },
        "&.MuiButton-endIcon": {
          color: theme.palette.common.white,
          fill: theme.palette.common.white,
        },
        "&.MuiButton-startIcon": {
          color: theme.palette.common.white,
          fill: theme.palette.common.white,
        },
      },
      "&.MuiButton-outlinedPrimary": {
        "&.Mui-disabled": {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          opacity: "50%",
        },
      },
      "&.MuiButton-outlinedSuccess": {
        "&.Mui-disabled": {
          borderColor: theme.palette.success.main,
          color: theme.palette.success.main,
          opacity: "50%",
        },
      },
      "&.MuiButton-outlinedError": {
        "&.Mui-disabled": {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
          opacity: "50%",
        },
      },
      "&.MuiButton-outlinedInfo": {
        "&.Mui-disabled": {
          borderColor: theme.palette.info.main,
          color: theme.palette.info.main,
          opacity: "50%",
        },
      },
      "&.MuiButton-outlinedWarning": {
        "&.Mui-disabled": {
          borderColor: theme.palette.warning.main,
          color: theme.palette.warning.main,
          opacity: "50%",
        },
      },
    }),
    contained: ({ theme }) => ({
      "&.MuiButton-containedPrimary": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          opacity: "50%",
        },
      },
      "&.MuiButton-containedSuccess": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
          opacity: "50%",
        },
      },
      "&.MuiButton-containedError": {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          opacity: "50%",
        },
      },
      "&.MuiButton-containedInfo": {
        color: theme.palette.common.white,
        "&.Mui-disabled": {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.common.white,
          opacity: "50%",
        },
      },
      "&.MuiButton-containedWarning": {
        color: theme.palette.common.white,
        "&.Mui-disabled": {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.common.white,
          opacity: "50%",
        },
      },
    }),
  },
};
