/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCategoryCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceCategoryInput;
}>;


export type MarketplaceProductCategoryCreateMutation = { __typename?: 'Mutation', marketplaceProductCategoryCreate?: { __typename?: 'MarketplaceProductCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceProductCategory', id: string } | null } | null };


export const MarketplaceProductCategoryCreateDocument = gql`
    mutation MarketplaceProductCategoryCreate($input: MarketplaceCategoryInput!) {
  marketplaceProductCategoryCreate(input: $input) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      id
    }
  }
}
    `;
export type MarketplaceProductCategoryCreateMutationFn = Apollo.MutationFunction<MarketplaceProductCategoryCreateMutation, MarketplaceProductCategoryCreateMutationVariables>;

/**
 * __useMarketplaceProductCategoryCreateMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductCategoryCreateMutation, { data, loading, error }] = useMarketplaceProductCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceProductCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductCategoryCreateMutation, MarketplaceProductCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductCategoryCreateMutation, MarketplaceProductCategoryCreateMutationVariables>(MarketplaceProductCategoryCreateDocument, options);
      }
export type MarketplaceProductCategoryCreateMutationHookResult = ReturnType<typeof useMarketplaceProductCategoryCreateMutation>;
export type MarketplaceProductCategoryCreateMutationResult = Apollo.MutationResult<MarketplaceProductCategoryCreateMutation>;
export type MarketplaceProductCategoryCreateMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductCategoryCreateMutation, MarketplaceProductCategoryCreateMutationVariables>;