import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const languageAutocomplete = createZodAutocompleteObject(z.object({
    code: z.string(),
    display: z.string(),
    displayAr: z.string().nullable().optional(),
    id: z.string().nullable().optional(),
}));
export const LanguageAutocompleteSchemas = {
    languageAutocomplete,
    languageAutocompleteOptional: languageAutocomplete.nullable().optional(),
};
