import { AppTypes, OrderDirection, PermissionEnum, User, UserSortField } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { providerUserManagementTitleBreadcrumb } from "pages/UserManagement/constants/provider-user-management-breadcrumbs";
import { providerUserManagementPaths } from "pages/UserManagement/constants/provider-user-management-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useDeleteUserMutation, useGetAdminUsersQuery } from "../../gql";
import { useProviderUserManagementColumns } from "./Columns/useProviderUserManagementColumns";

const HealthProviderManagementList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const canManageUser = hasPermission(PermissionEnum.ManageUsers);
  const UsersColumns = useProviderUserManagementColumns(canManageUser);

  const handleAddNewUser = () => {
    navigate(providerUserManagementPaths.newPath.fullPath);
  };

  const handleEditUser = (user: User) => {
    navigate(providerUserManagementPaths.editPath.fullPathWithParams(user?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: providerUserManagementTitleBreadcrumb(t),
    });
  }, [setBreadCrumb, t]);

  return (
    <GridProvider
      filterInput={{
        appType: AppTypes.Vendor,
      }}
      hideFilterInput
      hasTableSetting
      gridName='users'
      columns={UsersColumns}
      query={useGetAdminUsersQuery}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
        filter: {
          appType: [AppTypes.Vendor],
        },
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEditUser,
        isDeleteVisible: true,
        isRowDeletable: canManageUser,
        isRowEditable: canManageUser,
      }}
      deleteItemProps={{
        entityTypeName: "User",
        name: "Health providers user",
        useDeleteMutation: useDeleteUserMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canManageUser ? <MainListActions hasAddNew onAddNewItem={handleAddNewUser} /> : ""}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default HealthProviderManagementList;
