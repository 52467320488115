type PermissionsArrayType = Array<{ name: string; code: string }>;
export function not(a: PermissionsArrayType, b: PermissionsArrayType) {
  const _b = b.map(el => el.code);
  return a.filter(value => _b.indexOf(value.code) === -1);
}

export function intersection(a: PermissionsArrayType, b: PermissionsArrayType) {
  const _b = b.map(el => el.code);
  return a.filter(value => _b.indexOf(value.code) !== -1);
}

export function union(a: PermissionsArrayType, b: PermissionsArrayType) {
  return [...a, ...not(b, a)];
}
