import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { UsersAutocomplete } from "@health/autocompletes";
import { AppTypes } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, createAutocompleteOption, CustomDialog, IconButton, ShareIcon, Tooltip, useAddToast } from "@toolkit/ui";
import { difference } from "lodash";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useChatFlowAddContributorsMutation, useChatFlowRemoveContributorsMutation } from "../gql";
export const ShareButtons = ({ agentId, users }) => {
    const { open, handleOpen, handleClose } = useOpenState();
    const { t } = useTranslation("domains");
    const form = useForm({ mode: "onChange" });
    const { setValue, handleSubmit } = form;
    const { succeeded, failed } = useAddToast();
    const [deleteContributorsToChatFlow, { loading: deleteLoading }] = useChatFlowRemoveContributorsMutation({
        onCompleted: () => {
            succeeded("Contributors shared successfully");
            handleClose();
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const [addContributorsToChatFlow, { loading }] = useChatFlowAddContributorsMutation({
        onCompleted: () => {
            deleteContributorsToChatFlow({
                variables: {
                    chatFlowId: agentId,
                    contributors: [],
                },
            });
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    useEffect(() => {
        setValue("users", (users === null || users === void 0 ? void 0 : users.length) ? users === null || users === void 0 ? void 0 : users.map(user => createAutocompleteOption(user, "id", "fullName")) : undefined);
    }, [users, setValue]);
    const onSubmit = values => {
        var _a;
        const defaultUser = (users === null || users === void 0 ? void 0 : users.map(user => user === null || user === void 0 ? void 0 : user.id)) || [];
        const formUser = (_a = values === null || values === void 0 ? void 0 : values.users) === null || _a === void 0 ? void 0 : _a.map(user => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.value) === null || _a === void 0 ? void 0 : _a.id; });
        const newUsers = difference(formUser, defaultUser);
        const deletedUsers = difference(defaultUser, formUser);
        addContributorsToChatFlow({
            variables: {
                chatFlowId: agentId,
                contributors: newUsers,
            },
            onCompleted: () => {
                deletedUsers &&
                    deleteContributorsToChatFlow({
                        variables: {
                            chatFlowId: agentId,
                            contributors: deletedUsers,
                        },
                    });
            },
        });
    };
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(CustomDialog, { type: 'base', open: open, onClose: handleClose, DialogTitleProps: {
                    title: t("Share with users"),
                }, button: _jsx(Tooltip, { title: t("Share agent with users"), children: _jsx(IconButton, { onClick: handleOpen, children: _jsx(ShareIcon, {}) }) }), DialogActionsProps: {
                    children: (_jsxs(Box, { display: 'flex', flexDirection: 'row', gap: 0.5, children: [_jsx(Button, { color: 'primary', variant: 'contained', disabled: loading || deleteLoading, type: 'submit', sx: {
                                    paddingX: "40px",
                                    borderRadius: "100px",
                                    height: "40px",
                                }, onClick: handleSubmit(onSubmit), children: t("Share") }), _jsx(Button, { color: 'primary', sx: {
                                    paddingX: "35px",
                                    borderRadius: "100px",
                                    height: "40px",
                                }, variant: 'outlined', onClick: handleClose, children: t("Cancel") })] })),
                }, children: _jsx(UsersAutocomplete, { name: "users", multiple: true, filter: { appType: AppTypes.Vendor } }) }) }) })));
};
