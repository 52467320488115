/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadBulkFileQueryVariables = Types.Exact<{
  fileId: Types.Scalars['ID'];
}>;


export type DownloadBulkFileQuery = { __typename?: 'Query', downloadBulkFile?: string | null };


export const DownloadBulkFileDocument = gql`
    query downloadBulkFile($fileId: ID!) {
  downloadBulkFile(fileId: $fileId)
}
    `;

/**
 * __useDownloadBulkFileQuery__
 *
 * To run a query within a React component, call `useDownloadBulkFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadBulkFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadBulkFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDownloadBulkFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadBulkFileQuery, DownloadBulkFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadBulkFileQuery, DownloadBulkFileQueryVariables>(DownloadBulkFileDocument, options);
      }
export function useDownloadBulkFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadBulkFileQuery, DownloadBulkFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadBulkFileQuery, DownloadBulkFileQueryVariables>(DownloadBulkFileDocument, options);
        }
export type DownloadBulkFileQueryHookResult = ReturnType<typeof useDownloadBulkFileQuery>;
export type DownloadBulkFileLazyQueryHookResult = ReturnType<typeof useDownloadBulkFileLazyQuery>;
export type DownloadBulkFileQueryResult = Apollo.QueryResult<DownloadBulkFileQuery, DownloadBulkFileQueryVariables>;