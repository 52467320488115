import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Grid, makeStyles } from "@toolkit/ui";
import ContentLoader from "react-content-loader";
const useSimpleCardLoaderStyles = makeStyles()({
    card: {
        padding: 10,
        height: 250,
        width: "100%",
    },
});
const ManagedListCardLoader = () => {
    const { classes } = useSimpleCardLoaderStyles();
    return (_jsx(_Fragment, { children: _jsx(Grid, { container: true, padding: 4, spacing: 2, children: new Array(10).fill(0).map((el, key) => (_jsx(Grid, { item: true, xs: 12, sm: 6, lg: 4, xl: 3, children: _jsx(Card, { elevation: 1, className: classes.card, children: _jsxs(ContentLoader, { width: 334, viewBox: '0 0 350 160', children: [_jsx("rect", { x: '10', y: '73', rx: '3', ry: '3', width: '300', height: '8' }), _jsx("rect", { x: '8', y: '88', rx: '3', ry: '3', width: '300', height: '8' }), _jsx("rect", { x: '8', y: '103', rx: '3', ry: '3', width: '300', height: '8' }), _jsx("rect", { x: '8', y: '118', rx: '3', ry: '3', width: '300', height: '8' }), _jsx("rect", { x: '105', y: '133', rx: '3', ry: '3', width: '118', height: '8' }), _jsx("circle", { cx: '170', cy: '27', r: '22' }), _jsx("circle", { cx: '130', cy: '151', r: '6' }), _jsx("circle", { cx: '160', cy: '151', r: '6' }), _jsx("circle", { cx: '190', cy: '151', r: '6' })] }) }) }, key))) }) }));
};
export default ManagedListCardLoader;
