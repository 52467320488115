/* eslint-disable max-lines */
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { BrandCreateContainer } from "../containers/BrandCerate/BrandCreateContainer";
import { BrandsList } from "../containers/BrandsList/BrandsListPage";
import { BrandUpdateContainer } from "../containers/BrandUpdate/BrandUpdateContainer";
import { brandsPaths, brandsRoute } from "./BrandsPaths";

export const BrandsRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({ navigate, enabled }) => {
  return {
    id: "marketplace-product-brands",
    text: i18n.t("Brands", { ns: "admin" }),
    route: brandsRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManageBrands),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageBrands),
    subItems: [
      {
        id: "brands-list",
        text: i18n.t("Brands", { ns: "admin" }),
        route: brandsPaths.main.route,
        fullPath: brandsPaths.main.fullPath,
        element: <BrandsList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "brands-new",
        text: i18n.t("New", { ns: "admin" }),
        element: <BrandCreateContainer />,
        route: brandsPaths.new.route,
        fullPath: brandsPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "brands-update",
        text: i18n.t("Update", { ns: "admin" }),
        element: <BrandUpdateContainer />,
        route: brandsPaths.update.route,
        fullPath: brandsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
