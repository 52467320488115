import React, { FC, useRef } from "react";
import { Tooltip, Typography, type TypographyProps, useTheme } from "../../base/mui";

export const TruncateTypography: FC<{ text: string } & TypographyProps> = ({ text, ...restOfProps }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    const element = ref.current!;
    setOpen(element?.scrollWidth > element?.clientWidth);
  };

  return (
    <Tooltip title={text} open={open} onClose={handleClose} onOpen={handleOpen}>
      <Typography
        ref={ref}
        sx={{
          maxWidth: 250,
          fontSize: theme.mixins.fonts.fontSize.sm,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        {...restOfProps}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
