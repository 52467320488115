import React, { FC } from "react";
import { makeStyles } from "../../base/mui";
import { IconProps } from "./types";

const useStyles = makeStyles()(theme => ({
  a: {
    fill: theme.palette.common.white,
  },
  b: {
    filter: "url(#a)",
  },
}));

export const ArrowLeftSliderIcon: FC<IconProps> = ({ width = "38.821", height = "58", viewBox = "5 0 30 50" }) => {
  const { classes } = useStyles();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
      <defs>
        <filter id='a' x='0' y='0' width='38.821' height='58' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='b' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g className={classes.b} transform='matrix(1, 0, 0, 1, 0, 0)'>
        <path
          className={classes.a}
          d='M3.807,6.25,1.344,9.065,20.112,25.957l1.232,1.114,1.232-1.114L41.344,9.065,38.88,6.25,21.344,22.027Z'
          transform='translate(36.07 4.66) rotate(90)'
        />
      </g>
    </svg>
  );
};
