import { formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";
import { formGirdBreakPoints } from "./constants";

export const MobileVersionsSettings: React.FC<ISiteSetting> = ({ loading, errors: formErrors, siteSettings }) => {
  const { register } = useFormContext();
  const { t } = useTranslation("admin");

  return (
    <Grid item xs={12}>
      <FormCard title={t("Mobile App Versions")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              type='number'
              defaultValue={siteSettings?.androidVersion}
              label={t("Android Version")}
              {...register("androidVersion")}
              error={Boolean(formErrors?.androidVersion?.message)}
              helperText={formErrors?.androidVersion?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.androidPackageName}
              label={t("Android Package Name")}
              {...register("androidPackageName")}
              error={Boolean(formErrors?.androidPackageName?.message)}
              helperText={formErrors?.androidPackageName?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              type='number'
              defaultValue={siteSettings?.iosVersion}
              label={t("iOS Version")}
              {...register("iosVersion", {
                required: getRequiredValidation(t, true),
              })}
              error={Boolean(formErrors?.iosVersion?.message)}
              helperText={formErrors?.iosVersion?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              type='number'
              defaultValue={siteSettings?.iosAppId}
              label={t("iOS App Ip")}
              {...register("iosAppId", {
                required: getRequiredValidation(t, true),
              })}
              error={Boolean(formErrors?.iosAppId?.message)}
              helperText={formErrors?.iosAppId?.message}
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
