/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramBenefitGroupOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthProgramBenefitGroupOptionsQuery = { __typename?: 'Query', healthProgramBenefitGroupOptions?: Array<{ __typename?: 'BenefitOption', id: string, name?: string | null, code?: Types.HealthProgramBenefitOptionCode | null } | null> | null };


export const HealthProgramBenefitGroupOptionsDocument = gql`
    query healthProgramBenefitGroupOptions {
  healthProgramBenefitGroupOptions {
    id
    name
    code
  }
}
    `;

/**
 * __useHealthProgramBenefitGroupOptionsQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitGroupOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitGroupOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitGroupOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthProgramBenefitGroupOptionsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramBenefitGroupOptionsQuery, HealthProgramBenefitGroupOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramBenefitGroupOptionsQuery, HealthProgramBenefitGroupOptionsQueryVariables>(HealthProgramBenefitGroupOptionsDocument, options);
      }
export function useHealthProgramBenefitGroupOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramBenefitGroupOptionsQuery, HealthProgramBenefitGroupOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramBenefitGroupOptionsQuery, HealthProgramBenefitGroupOptionsQueryVariables>(HealthProgramBenefitGroupOptionsDocument, options);
        }
export type HealthProgramBenefitGroupOptionsQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupOptionsQuery>;
export type HealthProgramBenefitGroupOptionsLazyQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupOptionsLazyQuery>;
export type HealthProgramBenefitGroupOptionsQueryResult = Apollo.QueryResult<HealthProgramBenefitGroupOptionsQuery, HealthProgramBenefitGroupOptionsQueryVariables>;