/* eslint-disable max-lines */
import { getRequiredValidation, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  ArrowButtonRight,
  ArrowLeftSliderIcon,
  Box,
  Button,
  Checkbox,
  CheckmarkIcon,
  CircleStrokedIcon,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useBenefitsTypeHooks } from "../../hooks/useBenifitsTypeHooks";
import { useStyles } from "../styles";
import { IProgramProps } from "../types";

export const TypeBenefits: FC<IProgramProps> = ({ defaultValues }) => {
  const { t, i18n } = useTranslation("admin");
  const { classes, theme } = useStyles();
  const { setValue, register, formState, watch } = useFormContext();
  const { state, handleOptionsFiled, handleChangeBenefitsGroup, handleCheckOptionValue, handleAddBenefitGroup } =
    useBenefitsTypeHooks(defaultValues);

  useEffect(() => {
    setValue("benefitGroups", state);
  }, [JSON.stringify(state)]);

  const selectedTab = state?.find(item => item?.isSelected);

  const benefitMap = watch("benefitGroups")?.filter(item => item?.isAdded) || [];
  const benefitGroups = benefitMap?.map(item => ({
    benefitGroup: item?.id,
    name: item?.name,
    optionsValues: item?.optionsValues
      ?.filter(item => item.isChecked)
      .map(value => ({ value: value?.value, name: value?.nameOption, option: value?.id })),

    hasEmptyValue: item?.optionsValues?.filter(item => item.isChecked).some(item => item?.value == "" || !item?.nameOption),
  }));
  const checkValue = benefitGroups?.find(item => item?.hasEmptyValue);

  const helperTextMessage = item => {
    if (item.value < 0) {
      return t("please enter positive number");
    } else if (item.isChecked && item?.errorValue) return getRequiredValidation(t, true)?.message;
    else return undefined;
  };

  return (
    <>
      <input type='hidden' {...register("benefitGroups", { required: required(t) })} />
      <Box sx={{ display: "flex", overflowX: "auto" }}>
        <div>
          <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.regular}>
            {t("Benefit Groups")}
          </Typography>

          <div>
            {state?.map((item, index) => {
              return (
                <Button
                  key={item?.id + index}
                  fullWidth
                  onClick={() => handleChangeBenefitsGroup(index, item?.id)}
                  variant='text'
                  className={state[index]?.isSelected ? `${classes.benefitGroups} ${classes.selected}` : classes.benefitGroups}
                  startIcon={
                    !item?.isAdded ? (
                      <Box sx={{ display: "flex", margin: "10px" }}>
                        <CircleStrokedIcon />
                      </Box>
                    ) : (
                      <IconButton
                        onClick={e => handleAddBenefitGroup(e, item?.id)}
                        sx={{ display: "flex", margin: "10px", width: "25px", height: "25px" }}
                      >
                        <CheckmarkIcon />
                      </IconButton>
                    )
                  }
                  endIcon={state[index]?.isSelected && (i18n?.language === "en" ? <ArrowButtonRight /> : <ArrowLeftSliderIcon />)}
                >
                  {t(item?.name)}
                </Button>
              );
            })}
          </div>
        </div>

        <Box marginX={2}>
          {selectedTab && (
            <>
              {!state?.length && (
                <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                  {t("There is no benefit Group")}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    margin: "0px 10px",
                    width: 460,
                  }}
                  fontSize={theme.mixins.fonts.fontSize.md}
                  fontWeight={theme.mixins.fonts.fontWeight.medium}
                >
                  {t("Basic Benefits")}
                </Typography>
                <Typography
                  sx={{ minWidth: 230, margin: "0px 22px" }}
                  fontSize={theme.mixins.fonts.fontSize.md}
                  fontWeight={theme.mixins.fonts.fontWeight.medium}
                >
                  {t("Value")}
                </Typography>
                <Typography
                  sx={{ minWidth: 230, margin: "0px 22px" }}
                  fontSize={theme.mixins.fonts.fontSize.md}
                  fontWeight={theme.mixins.fonts.fontWeight.medium}
                >
                  {t("Name")}
                </Typography>
              </Box>

              {selectedTab?.optionsValues?.map(item => {
                return (
                  <Box key={item?.id} sx={{ display: "flex", justifyContent: "space-between", margin: "10px", alignItems: "center" }}>
                    <FormControlLabel
                      key={item?.id}
                      control={
                        <Checkbox
                          id={item?.id}
                          color='primary'
                          name={item?.name}
                          checked={item?.isChecked || false}
                          onChange={e => handleCheckOptionValue(e, item?.id)}
                        />
                      }
                      sx={{
                        minWidth: 460,
                      }}
                      label={t(item?.name!)}
                    />
                    <TextField
                      sx={{
                        marginInline: 2,
                        minWidth: 230,
                      }}
                      type='number'
                      disabled={!item.isChecked}
                      value={!isNaN(item?.value) ? item?.value : ""}
                      label={t("Value")}
                      onChange={e => handleOptionsFiled(e, item?.id, "value")}
                      error={item.value < 0 || (item.isChecked && item?.errorValue)}
                      helperText={helperTextMessage(item)}
                    />
                    <TextField
                      sx={{
                        marginInline: 2,
                        minWidth: 230,
                      }}
                      disabled={!item.isChecked}
                      value={item?.nameOption ? item?.nameOption : ""}
                      label={t("Name")}
                      onChange={e => handleOptionsFiled(e, item?.id, "nameOption")}
                      error={item.isChecked && item?.errorName}
                      helperText={item.isChecked && item?.errorName && getRequiredValidation(t, true)?.message}
                    />
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      {formState?.isSubmitted && !selectedTab && (
        <Typography color={theme.palette.error.main} marginX={1} variant='caption'>
          {t("Please select at least one benefit Groups")}
        </Typography>
      )}
      {formState?.isSubmitted && checkValue && (
        <Typography color={theme.palette.error.main} marginX={1} variant='caption'>
          {t(checkValue?.name)} {t("has Required Values")}
        </Typography>
      )}
    </>
  );
};
