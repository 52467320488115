/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductRejectMutationVariables = Types.Exact<{
  marketplaceProductRejectId: Types.Scalars['ID'];
  rejectionReason: Types.Scalars['String'];
}>;


export type ProductRejectMutation = { __typename?: 'Mutation', marketplaceProductReject?: { __typename?: 'MarketplaceProduct', id: string, approvalStatus?: Types.MarketplaceApprovalStatus | null } | null };


export const ProductRejectDocument = gql`
    mutation productReject($marketplaceProductRejectId: ID!, $rejectionReason: String!) {
  marketplaceProductReject(
    id: $marketplaceProductRejectId
    rejectionReason: $rejectionReason
  ) {
    id
    approvalStatus
  }
}
    `;
export type ProductRejectMutationFn = Apollo.MutationFunction<ProductRejectMutation, ProductRejectMutationVariables>;

/**
 * __useProductRejectMutation__
 *
 * To run a mutation, you first call `useProductRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productRejectMutation, { data, loading, error }] = useProductRejectMutation({
 *   variables: {
 *      marketplaceProductRejectId: // value for 'marketplaceProductRejectId'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useProductRejectMutation(baseOptions?: Apollo.MutationHookOptions<ProductRejectMutation, ProductRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductRejectMutation, ProductRejectMutationVariables>(ProductRejectDocument, options);
      }
export type ProductRejectMutationHookResult = ReturnType<typeof useProductRejectMutation>;
export type ProductRejectMutationResult = Apollo.MutationResult<ProductRejectMutation>;
export type ProductRejectMutationOptions = Apollo.BaseMutationOptions<ProductRejectMutation, ProductRejectMutationVariables>;