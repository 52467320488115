import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const Globe: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.743' height='19.433' viewBox='0 0 17.743 19.433' {...props}>
      <path
        id='icons8-location_3_'
        d='M17.1,2a3.368,3.368,0,0,0-2.936,1.8,8.358,8.358,0,0,0-1.2-.109v0h-.084a8.942,8.942,0,1,0,7.114,3.617,4.875,4.875,0,0,0,.49-1.758A3.484,3.484,0,0,0,17.1,2Zm0,.845a2.636,2.636,0,0,1,2.535,2.7c0,1.013-1.352,3.378-2.535,5.111-1.183-1.733-2.535-4.1-2.535-5.111A2.636,2.636,0,0,1,17.1,2.845Zm0,.845a1.69,1.69,0,1,0,1.69,1.69A1.7,1.7,0,0,0,17.1,3.69Zm0,.845a.845.845,0,1,1-.845.845A.838.838,0,0,1,17.1,4.535Zm-3.8.02c.18.008.365.025.55.046a3.75,3.75,0,0,0-.127.949,6.187,6.187,0,0,0,.772,2.365H13.294Zm-.845.036V7.914H9.8C10.413,6.117,11.367,4.855,12.449,4.591Zm-1.944.3A8.164,8.164,0,0,0,8.895,7.914H6.333A8.038,8.038,0,0,1,10.505,4.89ZM19.57,8.174a7.761,7.761,0,0,1,1.312,3.966H17.516c0-.193-.01-.38-.021-.571A32.354,32.354,0,0,0,19.57,8.174ZM5.8,8.759H8.66a16.088,16.088,0,0,0-.424,3.38H4.866A7.953,7.953,0,0,1,5.8,8.759Zm3.736,0h2.911v3.38H9.081A14.984,14.984,0,0,1,9.538,8.759Zm3.756,0h1.647c.609,1.094,1.257,2.083,1.692,2.713.013.218.021.442.028.667H13.294ZM4.866,12.984h3.37a16.088,16.088,0,0,0,.424,3.38H5.8A7.953,7.953,0,0,1,4.866,12.984Zm4.215,0h3.368v3.38H9.538A14.984,14.984,0,0,1,9.081,12.984Zm4.213,0h3.368a14.984,14.984,0,0,1-.457,3.38H13.294Zm4.213,0h3.37a7.953,7.953,0,0,1-.936,3.38H17.083A16.088,16.088,0,0,0,17.507,12.984ZM6.333,17.209H8.895a8.164,8.164,0,0,0,1.611,3.025A8.038,8.038,0,0,1,6.333,17.209Zm3.462,0h2.654v3.325C11.368,20.268,10.413,19.006,9.8,17.209Zm3.5,0h2.654c-.617,1.8-1.573,3.06-2.654,3.325Zm3.555,0H19.41a8.038,8.038,0,0,1-4.172,3.025A8.164,8.164,0,0,0,16.849,17.209Z'
        transform='translate(-4 -2)'
      />
    </SvgIcon>
  );
};
