import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const doctorTeamMemberAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const DoctorTeamMemberAutocompleteSchemas = {
    doctorTeamMemberAutocomplete,
    doctorTeamMemberAutocompleteOptional: doctorTeamMemberAutocomplete.nullable().optional(),
};
