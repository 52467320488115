/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HealthProgramsFragmentFragment = { __typename?: 'HealthProgram', id: string, name?: string | null, copay?: boolean | null, isActive?: boolean | null, careFor?: Array<string | null> | null, targetGroup?: string | null, subscriptionPeriod?: Types.HealthProgramSubscriptionPeriod | null, type?: Types.HealthProgramType | null, currency?: string | null, priceAmount?: number | null, description?: string | null, pointsDuringNormalDemand?: number | null, pointsDuringUrgentDemand?: number | null, baselineAvailabilityHours?: number | null, unattendedCallPenaltyFactor?: number | null, callQualityRatingFactors?: string | null, mainSpecializationsRateFactors?: string | null, images?: Array<string | null> | null, copayAmount?: number | null, copayPercentage?: number | null, prescriptionEnabled?: boolean | null, referralsToSpecialistsEnabled?: boolean | null, insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null, specializations?: Array<{ __typename?: 'HealthProgramSpecialization', created?: any | null, id: string, modified?: any | null, specialization?: { __typename?: 'T_Specialization', code: string, display?: string | null } | null } | null> | null, benefitGroups?: Array<{ __typename?: 'BenefitGroupComplex', benefitGroup?: { __typename?: 'BenefitGroup', id: string, name?: string | null, description?: string | null, created?: any | null } | null, optionsValues?: Array<{ __typename?: 'BenefitOptionComplex', id: string, name?: string | null, value?: number | null } | null> | null } | null> | null };

export const HealthProgramsFragmentFragmentDoc = gql`
    fragment HealthProgramsFragment on HealthProgram {
  id
  name
  copay
  isActive
  careFor
  targetGroup
  subscriptionPeriod
  type
  insuranceNetworks(first: 1) {
    edges {
      node {
        id
        name
      }
    }
  }
  currency
  priceAmount
  description
  pointsDuringNormalDemand
  pointsDuringUrgentDemand
  baselineAvailabilityHours
  unattendedCallPenaltyFactor
  callQualityRatingFactors
  mainSpecializationsRateFactors
  specializations {
    created
    id
    modified
    specialization {
      code
      display
    }
  }
  images
  copayAmount
  copayPercentage
  prescriptionEnabled
  referralsToSpecialistsEnabled
  benefitGroups {
    benefitGroup {
      id
      name
      description
      created
    }
    optionsValues {
      id
      name
      value
    }
  }
}
    `;