/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProvidersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramNetworkProviderFilterInput>;
}>;


export type HealthProgramNetworkProvidersQuery = { __typename?: 'Query', healthProgramNetworkProviders?: { __typename?: 'HealthProgramNetworkProviderCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkProviderCountableEdge', node: { __typename?: 'HealthProgramNetworkProvider', id: string, isActive?: boolean | null, services?: Array<string | null> | null, virtualCallEnabled?: boolean | null, provider?: { __typename?: 'Vendor', isActive: boolean, name: string, id: string, contactPhoneNumber?: string | null, taxLicenseNumber: string, address?: { __typename?: 'Address', streetAddress1: string, streetAddress2: string, city: { __typename?: 'City', name: string, id: string } } | null } | null } }> } | null };


export const HealthProgramNetworkProvidersDocument = gql`
    query healthProgramNetworkProviders($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramNetworkProviderFilterInput) {
  healthProgramNetworkProviders(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        isActive
        services
        provider {
          isActive
          name
          id
          contactPhoneNumber
          taxLicenseNumber
          address {
            streetAddress1
            streetAddress2
            city {
              name
              id
            }
          }
        }
        virtualCallEnabled
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkProvidersQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkProvidersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworkProvidersQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkProvidersQuery, HealthProgramNetworkProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkProvidersQuery, HealthProgramNetworkProvidersQueryVariables>(HealthProgramNetworkProvidersDocument, options);
      }
export function useHealthProgramNetworkProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkProvidersQuery, HealthProgramNetworkProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkProvidersQuery, HealthProgramNetworkProvidersQueryVariables>(HealthProgramNetworkProvidersDocument, options);
        }
export type HealthProgramNetworkProvidersQueryHookResult = ReturnType<typeof useHealthProgramNetworkProvidersQuery>;
export type HealthProgramNetworkProvidersLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkProvidersLazyQuery>;
export type HealthProgramNetworkProvidersQueryResult = Apollo.QueryResult<HealthProgramNetworkProvidersQuery, HealthProgramNetworkProvidersQueryVariables>;