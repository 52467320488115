import { getMaxLengthValidation, patternEmail } from "@toolkit/core";

export const managersContactInfoFields = (t, defaultValues) => [
  {
    name: "ownerName",
    label: t("General Manager Name"),
    defaultValue: defaultValues?.ownerName,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "nationalId",
    label: t("National Id"),
    defaultValue: defaultValues?.nationalId,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    pattern: patternEmail(t),
    name: "managersContactInfo.generalManagerEmail",
    label: t("General Manager Email"),
    defaultValue: defaultValues?.managersContactInfo?.generalManagerEmail,
  },
];

export const purchasingManagerFields = (t, defaultValues) => [
  {
    isRequired: false,
    name: "managersContactInfo.purchasingManagerName",
    label: t("Purchasing Manager Name"),
    defaultValue: defaultValues?.managersContactInfo?.purchasingManagerName,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    isRequired: false,
    pattern: patternEmail(t),
    name: "managersContactInfo.purchasingManagerEmail",
    label: t("Purchasing Manager Email"),
    defaultValue: defaultValues?.managersContactInfo?.purchasingManagerEmail,
  },
];

export const financialManagerFields = (t, defaultValues) => [
  {
    isRequired: false,
    name: "managersContactInfo.financialManagerName",
    label: t("Financial Manager Name"),
    defaultValue: defaultValues?.managersContactInfo?.financialManagerName,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },

  {
    isRequired: false,
    pattern: patternEmail(t),
    name: "managersContactInfo.financialManagerEmail",
    label: t("Financial Manager Email"),
    defaultValue: defaultValues?.managersContactInfo?.financialManagerEmail,
  },
];
