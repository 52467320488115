import { TFunction, i18n } from "@toolkit/i18n";
import React from "react";
import { Typography } from "../../base/mui";
import { useStatusStyles } from "./useStatusStyles";

type StatusProps<T> = {
  status: T;
  color: keyof ReturnType<typeof useStatusStyles>["classes"];
  t?: TFunction;
};

export const OrderStatusTypography = <T,>(props: StatusProps<T>) => {
  const { status: statusData, color, t = i18n.t } = props;
  const { classes } = useStatusStyles();

  const arrayStatus = String(statusData).split("_");
  const status = arrayStatus.map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ");

  return <Typography className={`${classes.text} ${classes[color]}`}>{t(status)}</Typography>;
};

OrderStatusTypography.defaultProps = {
  t: (text: string) => text,
};
