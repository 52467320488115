/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberListFilterInput>;
}>;


export type HealthProgramMemberListsQuery = { __typename?: 'Query', healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, isActive?: boolean | null, modified?: any | null, name?: string | null, description?: string | null, createdByUser?: { __typename?: 'User', id: string, firstName?: string | null } | null, healthProgramMembers?: { __typename?: 'HealthProgramMemberCountableConnection', totalCount?: number | null } | null } }> } | null };


export const HealthProgramMemberListsDocument = gql`
    query healthProgramMemberLists($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramMemberListFilterInput) {
  healthProgramMemberLists(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        isActive
        modified
        name
        description
        createdByUser {
          id
          firstName
        }
        healthProgramMembers {
          totalCount
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberListsQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramMemberListsQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListsQuery, HealthProgramMemberListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListsQuery, HealthProgramMemberListsQueryVariables>(HealthProgramMemberListsDocument, options);
      }
export function useHealthProgramMemberListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListsQuery, HealthProgramMemberListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListsQuery, HealthProgramMemberListsQueryVariables>(HealthProgramMemberListsDocument, options);
        }
export type HealthProgramMemberListsQueryHookResult = ReturnType<typeof useHealthProgramMemberListsQuery>;
export type HealthProgramMemberListsLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListsLazyQuery>;
export type HealthProgramMemberListsQueryResult = Apollo.QueryResult<HealthProgramMemberListsQuery, HealthProgramMemberListsQueryVariables>;