import { useTranslation } from "@toolkit/i18n";
import React, { FC, MouseEvent } from "react";
import { Box, Button, DialogContent, DialogContentText, Typography, useTheme } from "../../../base/mui";
import { WarningIcon } from "../../../icons";
import { CustomMuiDialog, CustomMuiDialogProps } from "../CustomMuiDialog";

export interface WarningConfirmationDialogProps extends Pick<CustomMuiDialogProps, "button"> {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isConfirmButtonDisabled?: boolean;
  text: {
    title?: string;
    body?: string;
    subBody?: string;
  };
}

export const WarningConfirmationDialog: FC<WarningConfirmationDialogProps> = props => {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose: handleClose,
    onConfirm: handleConfirm,
    isConfirmButtonDisabled = false,
    button,
    text = {
      body: t("Are you sure you want to proceed with this action"),
      title: undefined,
      subBody: undefined,
    },
  } = props;

  const theme = useTheme();
  const handleConfirmation = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleConfirm();
    handleClose?.();
  };

  return (
    <CustomMuiDialog
      button={button}
      maxWidth={"xs"}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='delete-confirmation-dialog'
      aria-describedby='delete-confirmation-dialog'
      DialogTitleProps={{
        hasCloseButton: false,
        hidden: !text.title,
        title: (
          <Typography
            color={theme.palette.warning.main}
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
          >
            {text.title}
          </Typography>
        ),
      }}
      DialogActionsProps={{
        children: (
          <>
            <Box flex={1} />
            <Button variant='text' onClick={handleClose} data-testid='warningConfirmationDialogCancelButton'>
              {t("Cancel")}
            </Button>
            <Button
              color={"warning"}
              variant='contained'
              onClick={handleConfirmation}
              disabled={isConfirmButtonDisabled}
              data-testid='warningConfirmationDialogConfirmButton'
            >
              {t("Proceed")}
            </Button>
          </>
        ),
      }}
    >
      <DialogContent>
        <DialogContentText id='scroll-dialog-description'>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
            <WarningIcon fill={theme.palette.warning.main} sx={{ width: 100, height: 100 }} />
            <Typography color={theme.palette.warning.main} fontSize={theme.mixins.fonts.fontSize.xl} marginTop={3} textAlign='center'>
              {text.body}
            </Typography>
            {text?.subBody && (
              <Typography color={theme.palette.warning.main} fontSize={theme.mixins.fonts.fontSize.xl} textAlign='center'>
                {text?.subBody}
              </Typography>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
    </CustomMuiDialog>
  );
};
