import { coreConfig } from "../coreConfig";
import { UserTokenPayload } from "../types/UserTokenPayload";

const ISSERVER = typeof window === "undefined";

export function getTokenPayloadData(): UserTokenPayload | null {
  try {
    return ISSERVER ? null : coreConfig.getTokenPayloadData();
  } catch (e) {
    return null;
  }
}
