/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-statements */
import { useFileUpload } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { ChangeEvent, useEffect, useState, forwardRef } from "react";
import { Box, Tooltip, Typography } from "../../base/mui";
import { DragAndDropImageIcon } from "../../icons";
import { getImage } from "../FormInputs/utils";
import { ImageDisplay } from "./ImageDisplay";
import { styles } from "./styles";
import { IImageUploadProps } from "./types";
import { useAddToast } from "../../hooks";

const ImageUpload = forwardRef<HTMLInputElement, IImageUploadProps>(
  (
    {
      handleImageUploaded,
      handleImageProgress,
      handleImageError,
      image,
      label,
      id,
      ratioImage = {
        width: 150,
        height: 210,
      },
      errorImage,
      helperText,
      validationRation = false,
      canDeleteImage = false,
      onDelete = () => {},
      hasMultipleImages = false,
      token,
      acceptsTypes,
      enableToastMessage,
      classes: _classes,
    },
    ref
  ): React.ReactElement => {
    const { t } = useTranslation();
    const { classes, theme, cx } = styles();
    const [isDragging, setIsDragging] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string | string[]>();
    const [isFormatError, setFormatError] = useState(false);
    const [error, setError] = useState<string>();
    const { fetchUploadFile } = useFileUpload({ token });
    const { failed } = useAddToast();

    useEffect(() => {
      setUploadedImage(image);
    }, [image]);

    const handleDelete = (index?: number) => {
      if (index === undefined && !hasMultipleImages) {
        setUploadedImage(undefined);
      }
      if (hasMultipleImages && index !== undefined) {
        const newImages = uploadedImage as string[];
        newImages.splice(index, 1);
        setUploadedImage(newImages);
        handleImageUploaded(newImages, undefined);
      }
      onDelete(null);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);
      handleFileUpload(e);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(!isDragging);
    };
    const handleFileUpload = (e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
      const validImagesType = ["image/jpeg", "image/png", "image/svg+xml", "image/apng", "image/bmp", "image/gif"];

      const files = "dataTransfer" in e ? e.dataTransfer?.files! : e.target.files!;
      const file = files?.[0]!;
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = function () {
        const ratio = img.width / img.height;
        const isValidRatio = ratio >= ratioImage.width / ratioImage.height || validationRation;
        const fileSizeMB = (file.size / 1024 / 1024).toFixed(2);

        if (!isValidRatio) {
          setError(`Invalid image size: ${fileSizeMB} MB.`);
        } else {
          setError("");
        }
        enableToastMessage && !isValidRatio && failed(t("The size of uploaded image is small, please try to upload another image"));
      };

      const isFileTypeValid = acceptsTypes ? acceptsTypes.includes(file.type) : validImagesType.includes(file.type);

      if (isFileTypeValid) {
        fetchUploadFile({
          files,
          onComplete: fileName => {
            const newImage = hasMultipleImages ? [...(uploadedImage || []), getImage(fileName)] : getImage(fileName);
            handleImageUploaded(newImage || fileName, e as any);
          },
          onProgress: handleImageProgress,
          onError: (error: string) => {
            handleImageError?.();
            setError(error);
          },
        });
        setFormatError(false);
      } else {
        setFormatError(true);
        enableToastMessage && failed(t("Unsupported file type"));
      }
    };

    const isItEmpty = hasMultipleImages ? uploadedImage?.length! === 0 : !uploadedImage;
    return (
      <Box className={cx(classes.root, _classes?.root)}>
        {label && <Typography mb={"8px"}>{label}</Typography>}
        <div onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave} className={classes.uploadContainer}>
          <div className={errorImage || isFormatError || !!error ? classes.errorsContainer : classes.container}>
            <input
              ref={ref}
              accept={acceptsTypes ? acceptsTypes.join(",") : "image/*"}
              className={classes.input}
              id={`contained-button-file${id}`}
              name={`contained-button-file${id}`}
              multiple
              type='file'
              onChange={handleFileUpload}
            />
            <span className={classes.button}></span>
            <label htmlFor={`contained-button-file${id}`} className={classes.label}>
              <DragAndDropImageIcon
                width={40}
                height={40}
                color={isFormatError ? "error" : "secondary"}
                className={`${classes.imageIcon} `}
              />
              <Tooltip
                title={t("Expected Image Size 150x210 ", {
                  width: ratioImage.width,
                  height: ratioImage.height,
                })}
              >
                <Typography variant='body1' align={"center"} dir={"ltr"} color={theme.palette.stale.main}>
                  {t("Drag and Drop , or")} <br />
                  <span className={classes.span}>{t("browse")} </span>
                  {t("your Thumbnails")}
                  {!!error && (
                    <Typography variant='subtitle1' color='error'>
                      {t(error)}
                    </Typography>
                  )}
                  {isFormatError && (
                    <Typography variant='subtitle1' color='error' align='center'>
                      {t("Unsupported file type")}
                    </Typography>
                  )}
                </Typography>
              </Tooltip>
            </label>
          </div>
          {helperText && (
            <Typography variant='caption' color='error' align='center' className={classes.imageIconError}>
              {helperText}
            </Typography>
          )}

          {!hasMultipleImages && !isItEmpty && (
            <ImageDisplay canDeleteImage={canDeleteImage} uploadedImage={uploadedImage as string} onDelete={handleDelete} />
          )}
          <div className={classes.images}>
            {hasMultipleImages &&
              (uploadedImage as string[])?.map((item, index) => (
                <div key={item + index}>
                  <ImageDisplay
                    icon={{ width: 20, height: 20 }}
                    canDeleteImage={canDeleteImage}
                    uploadedImage={item}
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              ))}
          </div>
        </div>
      </Box>
    );
  }
);

export default ImageUpload;
