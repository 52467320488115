/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCallbackDetailsQueryVariables = Types.Exact<{
  callbackRequestId: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetCallbackDetailsQuery = { __typename?: 'Query', callbackRequestUpdateHistory: { __typename?: 'CallbackRequestUpdateHistoryCountableConnection', edges: Array<{ __typename?: 'CallbackRequestUpdateHistoryCountableEdge', node: { __typename?: 'CallbackRequestUpdateHistory', callbackRequestId?: string | null, comments?: string | null, created?: any | null, id: string, status?: Types.CallbackRequestStatus | null, user?: { __typename?: 'User', fullName?: string | null } | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } };


export const GetCallbackDetailsDocument = gql`
    query GetCallbackDetails($callbackRequestId: ID!, $after: String, $before: String, $first: Int, $last: Int) {
  callbackRequestUpdateHistory(
    callbackRequestId: $callbackRequestId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        callbackRequestId
        comments
        created
        id
        status
        user {
          fullName
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useGetCallbackDetailsQuery__
 *
 * To run a query within a React component, call `useGetCallbackDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallbackDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallbackDetailsQuery({
 *   variables: {
 *      callbackRequestId: // value for 'callbackRequestId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetCallbackDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCallbackDetailsQuery, GetCallbackDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCallbackDetailsQuery, GetCallbackDetailsQueryVariables>(GetCallbackDetailsDocument, options);
      }
export function useGetCallbackDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallbackDetailsQuery, GetCallbackDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCallbackDetailsQuery, GetCallbackDetailsQueryVariables>(GetCallbackDetailsDocument, options);
        }
export type GetCallbackDetailsQueryHookResult = ReturnType<typeof useGetCallbackDetailsQuery>;
export type GetCallbackDetailsLazyQueryHookResult = ReturnType<typeof useGetCallbackDetailsLazyQuery>;
export type GetCallbackDetailsQueryResult = Apollo.QueryResult<GetCallbackDetailsQuery, GetCallbackDetailsQueryVariables>;