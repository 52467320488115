/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserMutationVariables = Types.Exact<{
  userUpdateId: Types.Scalars['ID'];
  input: Types.UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', userUpdate?: { __typename?: 'UserUpdate', accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }>, user?: { __typename?: 'User', appRole?: Types.AppRoleTypes | null, appType?: Types.AppTypes | null, id: string, firstName?: string | null, lastName?: string | null, email?: string | null, isActive: boolean, mobile?: string | null, isStaff?: boolean | null, isVendorAdmin?: boolean | null, nationalId?: string | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, doctor?: { __typename?: 'Doctor', specializations?: Array<{ __typename?: 'DoctorSpecialization', code: string, display?: string | null, id: string } | null> | null } | null } | null } | null };


export const UpdateUserDocument = gql`
    mutation updateUser($userUpdateId: ID!, $input: UserUpdateInput!) {
  userUpdate(id: $userUpdateId, input: $input) {
    accountErrors {
      code
      field
      message
    }
    user {
      appRole
      appType
      id
      firstName
      lastName
      email
      isActive
      mobile
      isStaff
      isVendorAdmin
      nationalId
      branches {
        id
        name
      }
      doctor {
        specializations {
          code
          display
          id
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userUpdateId: // value for 'userUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;