import { H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  networkListBreadcrumb,
  networkNewBreadcrumb,
  networkTitleBreadcrumb,
} from "pages/HealthProgramNetworks/constants/network-breadcrumbs";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramNetworksListDocument } from "../../HealthProgram/gql";
import { useHealthProgramNetworkCreateMutation } from "../gql";
import { HealthProgramNetworkForm } from "./HealthProgramNetworkForm";

export const HealthProgramNetworkNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  useEffect(() => {
    setBreadCrumb({
      title: t("Networks"),
      values: [{ name: t("Create New Network"), id: "Create New Network", route: "networks/new" }],
    });
  }, []);
  const [healthProgramNetworkCreate, { loading: isSubmitting }] = useHealthProgramNetworkCreateMutation({
    onCompleted: data => {
      const id = data?.healthProgramNetworkCreate?.entity?.id;
      const healthProgramMemberListErrors = data?.healthProgramNetworkCreate?.entityErrors!;
      if (healthProgramMemberListErrors?.length === 0) {
        succeeded(t("Network Created Successfully"));
        navigate(networksPaths.providersPath.fullPathWithParams(id!));
      } else {
        setError(healthProgramMemberListErrors);
        failed(formatMessageErrors(healthProgramMemberListErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
    refetchQueries: [
      {
        query: HealthProgramNetworksListDocument,
        variables: {
          first: 10,
          filter: {
            isActive: true,
          },
        },
      },
    ],
  });

  const handleSubmit = data => {
    const { payerId, ...rest } = data;
    healthProgramNetworkCreate({
      variables: {
        input: {
          ...rest,
          name: data?.name.trim(),
          payerId: payerId?.id,
          isActive: !!data?.isActive,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: networkTitleBreadcrumb(t),
      values: [networkListBreadcrumb(t), networkNewBreadcrumb(t)],
    });
  }, []);

  return <HealthProgramNetworkForm isSubmitting={isSubmitting} errors={errors} onDone={handleSubmit} />;
};
