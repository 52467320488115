import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";
import { replacePathParams } from "utils";

export const subscriptionsManagementRoute = `${marketplaceRoute}/subscriptions-management/subscriptions`;
const subscriptionsManagementNewRoute = "new";
const subscriptionsManagementEditRoute = "edit/:id";

export const subscriptionsManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${subscriptionsManagementRoute}${this.route}`;
    },
  },
  newPath: {
    route: subscriptionsManagementNewRoute,
    get fullPath() {
      return `${subscriptionsManagementRoute}/${this.route}`;
    },
  },
  editPath: {
    route: subscriptionsManagementEditRoute,
    get fullPath() {
      return `${subscriptionsManagementRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
