/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderProfessionalCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramNetworkProviderProfessionalInput;
}>;


export type HealthProgramNetworkProviderProfessionalCreateMutation = { __typename?: 'Mutation', healthProgramNetworkProviderProfessionalCreate?: { __typename?: 'HealthProgramNetworkProviderProfessionalCRUD', entity?: { __typename?: 'HealthProgramNetworkProviderProfessional', id: string } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const HealthProgramNetworkProviderProfessionalCreateDocument = gql`
    mutation healthProgramNetworkProviderProfessionalCreate($input: HealthProgramNetworkProviderProfessionalInput!) {
  healthProgramNetworkProviderProfessionalCreate(input: $input) {
    entity {
      id
    }
    entityErrors {
      field
      message
    }
  }
}
    `;
export type HealthProgramNetworkProviderProfessionalCreateMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderProfessionalCreateMutation, HealthProgramNetworkProviderProfessionalCreateMutationVariables>;

/**
 * __useHealthProgramNetworkProviderProfessionalCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderProfessionalCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderProfessionalCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderProfessionalCreateMutation, { data, loading, error }] = useHealthProgramNetworkProviderProfessionalCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderProfessionalCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderProfessionalCreateMutation, HealthProgramNetworkProviderProfessionalCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderProfessionalCreateMutation, HealthProgramNetworkProviderProfessionalCreateMutationVariables>(HealthProgramNetworkProviderProfessionalCreateDocument, options);
      }
export type HealthProgramNetworkProviderProfessionalCreateMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderProfessionalCreateMutation>;
export type HealthProgramNetworkProviderProfessionalCreateMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderProfessionalCreateMutation>;
export type HealthProgramNetworkProviderProfessionalCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderProfessionalCreateMutation, HealthProgramNetworkProviderProfessionalCreateMutationVariables>;