import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const ResponseIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox, fill } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "45px"}
      height={height ? height : "45px"}
      viewBox={viewBox ? viewBox : "0 0 30 30"}
      {...props}
    >
      <path
        id='icons8-response'
        d='M 15 3 C 7.82 3 2 7.925 2 14 C 2 17.368 3.7933281 20.378484 6.6113281 22.396484 C 6.6832805 23.932468 6.4452784 26.053382 4.3261719 27.03125 A 0.5 0.5 0 0 0 4.3222656 27.033203 A 0.5 0.5 0 0 0 4 27.5 A 0.5 0.5 0 0 0 4.5 28 A 0.5 0.5 0 0 0 4.5429688 27.998047 C 7.0049381 27.982249 9.0910167 26.637148 10.517578 25.230469 C 10.936578 24.817469 11.525516 24.612609 12.103516 24.724609 C 13.034516 24.904609 14.004 25 15 25 C 22.18 25 28 20.075 28 14 C 28 7.925 22.18 3 15 3 z M 13 8 C 13.25575 8 13.511531 8.0974688 13.707031 8.2929688 C 14.098031 8.6839688 14.098031 9.3160312 13.707031 9.7070312 L 11.414062 12 L 18 12 C 20.206 12 22 13.794 22 16 L 22 18 C 22 18.552 21.552 19 21 19 C 20.448 19 20 18.552 20 18 L 20 16 C 20 14.897 19.103 14 18 14 L 11.414062 14 L 13.707031 16.292969 C 14.098031 16.683969 14.098031 17.316031 13.707031 17.707031 C 13.512031 17.902031 13.256 18 13 18 C 12.744 18 12.487969 17.902031 12.292969 17.707031 L 8.2929688 13.707031 C 7.9019687 13.316031 7.9019688 12.683969 8.2929688 12.292969 L 12.292969 8.2929688 C 12.488469 8.0974688 12.74425 8 13 8 z'
        fill={fill}
      />
    </SvgIcon>
  );
};
