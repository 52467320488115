import { useTranslation } from "@toolkit/i18n";
import { default as Moment } from "moment/moment";

const defaultFormat = "DD MMM YYYY";

export const useFormatDate = () => {
  const { i18n } = useTranslation();

  const { language } = i18n;

  const formatLocalizedDate = (date: string) => {
    if (!date) return "";

    const localeDateFormat = language === "en" ? defaultFormat : "D MMM YYYY";
    return Moment(date).locale(language).format(localeDateFormat);
  };

  return {
    formatLocalizedDate,
  };
};
