export enum ImageExtensions {
  JPG = "jpg",
  JPEG = "jpeg",
  PNG = "png",
}

export enum AudioExtensions {
  MP3 = "mp3",
  WAV = "wav",
  OGG = "ogg",
}

export enum VideoExtensions {
  MP4 = "mp4",
  MOV = "mov",
  MKV = "mkv",
  WMV = "wmv",
}

export enum FileType {
  IMAGE = "Image",
  AUDIO = "Audio",
  VIDEO = "Video",
  UNKNOWN = "Unknown",
}
