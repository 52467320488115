import { TFunction } from "@toolkit/i18n";

export const permissionsManagementTitleBreadcrumb = (t: TFunction) => {
  return t("Permissions Management", { ns: "admin" });
};

export const permissionsManagementGroupListBreadcrumb = (t: TFunction) => {
  return {
    id: "permissions-management-group-list",
    name: t("Permission Groups", { ns: "admin" }),
  };
};

export const permissionsManagementNewBreadcrumb = (t: TFunction) => {
  return {
    id: "permissions-management-group-new",
    name: t("New", { ns: "admin" }),
  };
};

export const permissionsManagementFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "permissions-management-group-field",
    name: fieldName,
  };
};
