import { makeStyles } from "@toolkit/ui";
export const useDecisionConditionsFormStyle = makeStyles()((theme, params) => ({
    gridItem: {
        ["@media (min-width:1200px)"]: {
            paddingRight: "10px",
            borderRight: "dashed 2px" + theme.palette.stale[400],
            margin: "24px 0 30px 0",
        },
        display: "flex",
        alignItems: "center",
        minWidth: 110,
    },
    container: {
        padding: "0px 10px !important",
    },
    div: { display: "flex" },
    formHelperText: { color: theme.palette.secondary.main },
    conditionInfoGrid: {
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        margin: 0,
        paddingBottom: "5px",
    },
    dashedConditionInfoGrid: {
        ["@media (max-width:1200px)"]: {
            borderTop: params.isFieldsMoreThanOne ? "dashed 2px" + theme.palette.stale[400] : undefined,
            marginTop: 30,
        },
    },
}));
