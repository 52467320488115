import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const guidedCareProgramAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const GuidedCareProgramAutocompleteSchemas = {
    guidedCareProgramAutocomplete,
    guidedCareProgramAutocompleteOptional: guidedCareProgramAutocomplete.nullable().optional(),
};
