import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCustomForm } from "@toolkit/core";
import { useEffect } from "react";
import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { convertOptimaActionToFormValues } from "../../../OptimaActions/others";
import { optimaActionUpsertFormDefaultValues, optimaActionUpsertFormSchema, } from "./OptimaActionUpsertFormSchema";
export const OptimaActionUpsertForm = props => {
    const { optimaAction, buttonLabel, isLoading, isButtonDisabled, onChange } = props;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const form = useCustomForm({
        defaultValues: optimaActionUpsertFormDefaultValues,
        schema: optimaActionUpsertFormSchema,
    });
    const { setValues, handleSubmit } = form;
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: { values },
        });
    };
    const handleCancel = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (optimaAction) {
            const _optimaAction = convertOptimaActionToFormValues(optimaAction);
            setValues(_optimaAction);
        }
    }, [optimaAction]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(PageWrapper, { actions: _jsx(FormActions, { hasCancel: true, hasFormButton: true, isLoading: isLoading, formButtonTitle: buttonLabel, newButtonDisabled: isButtonDisabled, onNavigation: handleCancel }), children: _jsx(FormCard, { title: t("Optima Action Information"), loading: isLoading, doYouHaveData: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, children: _jsx(FormTextField, { name: "action", label: t("Action"), placeholder: t("Action") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, children: _jsx(FormTextField, { name: "link", label: t("Link"), placeholder: t("Link") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormTextField, { name: "summary", label: t("Summary"), placeholder: t("Summary"), multiline: true, rows: 4 }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormSwitch, { name: "isActive", label: t("Active") }) })] }) }) }) }) })));
};
