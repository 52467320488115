import { replacePathParams } from "utils";

export const tradeDrugTypesRoute = "/trade-drag-types";
const tradeDrugTypesNewRoute = "new";
const tradeDrugTypesEditRoute = "edit/:id";

export const tradeDrugTypesPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${tradeDrugTypesRoute}${this.route}`;
    },
  },
  newPath: {
    route: tradeDrugTypesNewRoute,
    get fullPath() {
      return `${tradeDrugTypesRoute}/${this.route}`;
    },
  },
  editPath: {
    route: tradeDrugTypesEditRoute,
    get fullPath() {
      return `${tradeDrugTypesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
