import { BranchInput, CodeSystemConceptProperty, Type } from "@health/queries/types";
import { mobileStartRegex } from "@toolkit/core";
import { UseFormReturn } from "react-hook-form";

type ProviderCentralListFieldName =
  | "display"
  | "arabic_name"
  | "code"
  | "location"
  | "location_arabic"
  | "group"
  | "area"
  | "district"
  | "street"
  | "street_arabic"
  | "latitude"
  | "longitude"
  | "username"
  | "temp_password"
  | "email"
  | "phone"
  | "license_start"
  | "license_end";

const getPhoneNumber = (phone?: string | null) => {
  if (!phone) return undefined;
  if (mobileStartRegex.test(phone)) return phone;
  if (phone.startsWith("+")) return phone;
  else return `+${phone}`;
};

export const convertProviderCentralListFieldIntoFormFields = (values: Record<ProviderCentralListFieldName, any>): BranchInput => {
  const branchInput: BranchInput = {
    name: values.display,
    nameAr: values.arabic_name,
    addressInput: {
      streetAddress1: values.street,
      streetAddress2: values.street_arabic,
      area: values.area,
      name: values.display,
      phone: getPhoneNumber(values.phone),
      district: values.district,
      coordinates:
        isNaN(values.latitude as any) || isNaN(values.longitude as any)
          ? undefined
          : {
              lat: Number(values.latitude),
              lng: Number(values.longitude),
            },
      region: values.district,
      city: values.location,
      // undefined
      streetAddress1Ar: values.street_arabic,

      buildingName: undefined,
      buildingNumber: undefined,
      unitNumber: undefined,
      postalCode: undefined,
    },
    contactEmail: values.email,
    contactNumber: getPhoneNumber(values.phone),
    healthLicense: values.code,
    healthLicenseStartDate: values.license_start,
    healthLicenseEndDate: values.license_end,
    // undeifiend
    isActive: undefined,
    type: undefined,
    description: undefined,
    isTrained: undefined,
    workingHours: undefined,
    workingHoursOverride: undefined,
  };

  // branchInput["arabicStreetAddress"] = values.street_arabic;

  return branchInput;
};

export const fillMappedDataToForm = (mappedValues: BranchInput, methods: UseFormReturn) => {
  // vendorFromMethods.reset(mappedValues, {});
  Object.entries(mappedValues).forEach(([key, value]) => {
    if (!value) return;
    if (typeof value === "object") {
      Object.entries(value).forEach(([key2, value2]) => {
        // vendorFromMethods.setValue(`${key}.${key2}` as any, value2, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
        methods.setValue(`${key}.${key2}` as any, value2);
      });
      methods.setValue("arabicStreetAddress", mappedValues.addressInput?.streetAddress1Ar);

      return;
    }
    methods.setValue(key as any, value);
  });
};
export const getCentralListPropValue = (item: Partial<CodeSystemConceptProperty>) => {
  switch (item?.fieldType) {
    case Type.String:
      return item?.valueString;
      break;
    case Type.Boolean:
      return item?.valueBoolean ? "Yes" : "No";
      break;
    case Type.Float:
      return item?.valueFloat;
      break;
    case Type.Integer:
      return item?.valueInteger;
      break;
    case Type.Date:
      return item?.valueDate;
      break;
    default:
      return "-";
  }
};
