import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const MarketplaceBrandAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const MarketplaceBrandAutocompleteSchemas = {
    MarketplaceBrandAutocomplete,
    MarketplaceBrandAutocompleteOptional: MarketplaceBrandAutocomplete.nullable().optional(),
};
