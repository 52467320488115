import { BACKEND_DATE_FORMAT, formGirdBreakPoints, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, Grid } from "@toolkit/ui";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useGetBackendErrorsForInputsValues } from "../setBackendsErrors";

interface HealthLicenseProps {
  errorsInput: any;
  defaultValue;
}

export const BranchHealthLicense: FC<HealthLicenseProps> = props => {
  const { t } = useTranslation("admin");
  const { defaultValue, errorsInput } = props;
  const {
    control,
    setValue,
    formState: { errors },
    register,
  } = useFormContext();
  const [startValue, endValue] = useWatch({ control, name: ["healthLicenseStartDate", "healthLicenseEndDate"] });
  useEffect(() => {
    setValue("healthLicenseStartDate", defaultValue?.healthLicenseStartDate || null);
    setValue("healthLicenseEndDate", defaultValue?.healthLicenseEndDate || null);
  }, []);
  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errorsInput, ["healthLicenseStartDate", "healthLicenseEndDate"]);
  const todayDate = moment().startOf("day");

  return (
    <>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          error={Boolean(errors?.healthLicenseStartDate?.message)}
          helperText={t(errors?.healthLicenseStartDate?.message)}
          format={BACKEND_DATE_FORMAT}
          {...register("healthLicenseStartDate", {
            validate: value => {
              if (backendErrorStorage["healthLicenseStartDate"][value]) return backendErrorStorage["healthLicenseStartDate"][value];
              const start = moment(value, BACKEND_DATE_FORMAT);
              const end = moment(endValue, BACKEND_DATE_FORMAT);
              if (start.isValid() && end.isValid() && start.isSameOrAfter(end)) {
                return t("health-license-start-date", { end: end.format(BACKEND_DATE_FORMAT) });
              }
              if (start.isSameOrAfter(todayDate)) return t("health-license-start-date", { end: todayDate.format(BACKEND_DATE_FORMAT) });
            },
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          label={t("Health License End Date")}
          placeholder={t("Health License End Date")}
          error={Boolean(errors?.healthLicenseEndDate?.message)}
          helperText={t(errors?.healthLicenseEndDate?.message)}
          format={BACKEND_DATE_FORMAT}
          {...register("healthLicenseEndDate", {
            validate: value => {
              if (backendErrorStorage["healthLicenseEndDate"][value]) return backendErrorStorage["healthLicenseEndDate"][value];
              const start = moment(startValue, BACKEND_DATE_FORMAT);
              const end = moment(value, BACKEND_DATE_FORMAT);
              if (start.isValid() && end.isValid() && end.isSameOrBefore(start))
                return t("health-license-end-date", { start: start.format(BACKEND_DATE_FORMAT) });
              if (end.isSameOrBefore(todayDate)) return t("health-license-end-date", { start: todayDate.format(BACKEND_DATE_FORMAT) });
            },
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
    </>
  );
};
