/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
}>;


export type VendorsQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string, name: string } }> } | null };


export const VendorsDocument = gql`
    query vendors($first: Int, $before: String, $after: String, $last: Int, $filter: VendorFilterInput) {
  vendors(
    first: $first
    before: $before
    after: $after
    last: $last
    filter: $filter
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;