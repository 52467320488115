import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
export const useOptimaEditActionsColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "id",
                header: t("ID"),
                accessor: "id",
            },
            {
                key: "action",
                header: t("Action"),
                accessor: "action",
            },
        ];
    }, [t]);
};
