import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SublistUploadDocument = gql `
    mutation SublistUpload($subListId: ID!, $file: String!) {
  uploadSubList(fileName: $file, subListId: $subListId) {
    errors {
      message
      field
    }
  }
}
    `;
/**
 * __useSublistUploadMutation__
 *
 * To run a mutation, you first call `useSublistUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistUploadMutation, { data, loading, error }] = useSublistUploadMutation({
 *   variables: {
 *      subListId: // value for 'subListId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSublistUploadMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SublistUploadDocument, options);
}
