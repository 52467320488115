import { combineErrors, formGirdBreakPoints, getPositiveNumber } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";

export const PlatformProfitSharePercentage: FC<IProgramProps> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    getValues,
    clearErrors,
    setError,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);

  // eslint-disable-next-line max-statements
  const validatePercentageValue = (value: string) => {
    if (Number(value) > 100) {
      return t("Percentage value must be less than 100");
    }
    const platformPercentage = getValues("platformProfitSharePercentage");
    const vendorPercentage = getValues("vendorProfitSharePercentage");
    const operatorPercentage = getValues("operatorProfitSharePercentage");
    const sumOfPercentages = Number(platformPercentage) + Number(vendorPercentage) + Number(operatorPercentage);
    if (platformPercentage && vendorPercentage && operatorPercentage && sumOfPercentages != 100) {
      ["platformProfitSharePercentage", "vendorProfitSharePercentage", "operatorProfitSharePercentage"].forEach(fieldName => {
        setError(fieldName, {
          message: t("sumOfPercentagesMustBe100"),
        });
      });
      return t("sumOfPercentagesMustBe100");
    } else {
      clearErrors(["platformProfitSharePercentage", "vendorProfitSharePercentage", "operatorProfitSharePercentage"]);
    }
  };

  return (
    <>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          type='number'
          size='small'
          inputProps={{ step: "0.1" }}
          label={t("Platform Profit Share Percentage")}
          error={Boolean(formErrors.platformProfitSharePercentage?.message)}
          helperText={t(formErrors.platformProfitSharePercentage?.message)}
          defaultValue={defaultValues?.platformProfitSharePercentage}
          {...register("platformProfitSharePercentage", {
            min: getPositiveNumber(t, 0),
            validate: validatePercentageValue,
          })}
          InputProps={{
            startAdornment: "%",
          }}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          type='number'
          size='small'
          inputProps={{ step: "0.1" }}
          label={t("Vendor Profit Share Percentage")}
          error={Boolean(formErrors.vendorProfitSharePercentage?.message)}
          helperText={t(formErrors.vendorProfitSharePercentage?.message)}
          defaultValue={defaultValues?.vendorProfitSharePercentage}
          {...register("vendorProfitSharePercentage", {
            min: getPositiveNumber(t, 0),
            validate: validatePercentageValue,
          })}
          InputProps={{
            startAdornment: "%",
          }}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          type='number'
          size='small'
          inputProps={{ step: "0.1" }}
          label={t("Operator Profit Share Percentage")}
          error={Boolean(formErrors.operatorProfitSharePercentage?.message)}
          helperText={t(formErrors.operatorProfitSharePercentage?.message)}
          defaultValue={defaultValues?.operatorProfitSharePercentage}
          {...register("operatorProfitSharePercentage", {
            min: getPositiveNumber(t, 0),
            validate: validatePercentageValue,
          })}
          InputProps={{
            startAdornment: "%",
          }}
        />
      </Grid>
    </>
  );
};
