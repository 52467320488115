import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const riskFactorTemplateAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullable().optional(),
}));
export const RiskFactorTemplateAutocompleteSchemas = {
    riskFactorTemplateAutocomplete,
    riskFactorTemplateAutocompleteOptional: riskFactorTemplateAutocomplete.nullable().optional(),
};
