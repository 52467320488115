import React, { useState } from "react";
import { PageInfo } from "../utils/countableConnection";

export const useTablePagination = <XQueryVariables,>(
  size: number,
  pageInfo: PageInfo,
  setVariables: React.Dispatch<React.SetStateAction<XQueryVariables>>
) => {
  const [pageSize, setPageSize] = useState<number>(size);

  const handleGoToNext = (_input?: React.SetStateAction<XQueryVariables>) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          first: pageSize,
          after: pageInfo?.endCursor,
          last: null,
          before: null,
        }))
    );
  };

  const handleGoToPrevious = (_input?: React.SetStateAction<XQueryVariables>) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          last: pageSize,
          before: pageInfo?.startCursor,
          first: null,
          after: null,
        }))
    );
  };

  const handleChangePageSize = (_size: number): void => {
    setPageSize(_size);
    setVariables(state => ({
      ...state,
      first: _size,
      after: null,
      last: null,
      before: null,
    }));
  };

  return {
    pageSize,
    handleGoToNext,
    handleGoToPrevious,
    handleChangePageSize,
  };
};
