import { jsx as _jsx } from "react/jsx-runtime";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, TruncateTooltipTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { getMediaLink } from "@toolkit/core";
export const useProductsColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "img",
                header: t("Image"),
                accessor: ({ marketPlaceItem }) => (_jsx(Avatar, { sx: { width: 50, height: 50, borderRadius: "0px" }, src: getMediaLink((marketPlaceItem === null || marketPlaceItem === void 0 ? void 0 : marketPlaceItem.mainImageUrl) || "") })),
            },
            {
                key: "type",
                header: t("Item type"),
                accessor: "type",
            },
            {
                key: "productName",
                header: t("Item Name"),
                accessor: ({ marketPlaceItem }) => pickLocalizedValue(marketPlaceItem === null || marketPlaceItem === void 0 ? void 0 : marketPlaceItem.name, marketPlaceItem === null || marketPlaceItem === void 0 ? void 0 : marketPlaceItem.nameAr),
            },
            {
                key: "description",
                header: t("Description"),
                accessor: ({ marketPlaceItem: { shortDescription, shortDescriptionAr } }) => (_jsx(TruncateTooltipTypography, { text: pickLocalizedValue(shortDescription, shortDescriptionAr) || shortDescription })),
            },
            {
                key: "quantity",
                header: t("Quantity"),
                accessor: "quantity",
            },
            {
                key: "price",
                header: t("Price"),
                accessor: "unitPriceNetAmount",
            },
        ];
    }, []);
};
