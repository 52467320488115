import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetCodeSystemConceptsDocument = gql `
    query getCodeSystemConcepts($first: Int, $after: String, $last: Int, $before: String, $codeSystemId: ID, $searchParameters: [SearchParameter]!, $showOnlyVisibleProperties: Boolean) {
  getCodeSystemConcepts(
    first: $first
    after: $after
    last: $last
    before: $before
    codeSystemId: $codeSystemId
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
  ) {
    edges {
      node {
        id
        code
        display
        properties {
          code
          fieldType
          valueDate
          valueBoolean
          valueFloat
          valueInteger
          valueString
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useGetCodeSystemConceptsQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      codeSystemId: // value for 'codeSystemId'
 *      searchParameters: // value for 'searchParameters'
 *      showOnlyVisibleProperties: // value for 'showOnlyVisibleProperties'
 *   },
 * });
 */
export function useGetCodeSystemConceptsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCodeSystemConceptsDocument, options);
}
export function useGetCodeSystemConceptsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCodeSystemConceptsDocument, options);
}
