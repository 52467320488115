import { H_IdTypes } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const idTypeOptionsMap = {
    [H_IdTypes.NationalCard]: {
        key: H_IdTypes.NationalCard,
        get label() {
            return i18n.t("National Card", { ns: "domains" });
        },
        value: H_IdTypes.NationalCard,
    },
    [H_IdTypes.Passport]: {
        key: H_IdTypes.Passport,
        get label() {
            return i18n.t("Passport", { ns: "domains" });
        },
        value: H_IdTypes.Passport,
    },
    [H_IdTypes.ResidentCard]: {
        key: H_IdTypes.ResidentCard,
        get label() {
            return i18n.t("Resident Card", { ns: "domains" });
        },
        value: H_IdTypes.ResidentCard,
    },
};
export const idTypeOptions = Object.values(idTypeOptionsMap);
