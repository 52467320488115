import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const UploadIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='23' height='29.389' viewBox='0 0 23 29.389' {...props}>
      <path
        id='icons8-xls'
        d='M7,2V31.389H30V10.568L21.173,2ZM8.278,3.278H20.417v8.306h8.306V30.111H8.278ZM21.694,4.286l6.2,6.02h-6.2ZM22.73,15.714a1.853,1.853,0,0,0-2.041,1.9,2.181,2.181,0,0,0,1.218,1.8c.547.354.834.584.834,1.056,0,.419-.287.729-.881.729a2.463,2.463,0,0,1-1.063-.25l-.182,1.081a2.819,2.819,0,0,0,1.235.26,1.945,1.945,0,0,0,2.139-1.954,2.111,2.111,0,0,0-1.17-1.852c-.7-.419-.891-.621-.891-1.006,0-.344.257-.671.8-.671a1.528,1.528,0,0,1,.821.222l.2-1.083A2.221,2.221,0,0,0,22.73,15.714Zm-9.87.057,1.16,3.152L12.812,22.23h1.245l.347-1.168c.095-.364.2-.756.3-1.17h.02c.115.414.22.776.344,1.15l.412,1.188h1.3L15.505,18.98l1.208-3.209H15.458l-.324,1.18c-.087.352-.165.659-.27,1.051h-.017c-.107-.362-.192-.7-.3-1.033l-.372-1.2Zm4.437,0V22.23H20.21V21.167H18.552V15.774Z'
        transform='translate(-7 -2)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
