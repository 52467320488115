import React, { FC, PropsWithChildren } from "react";
import { MuiDrawer } from "../../base/mui";
import { ShowButton } from "../ShowButton";
import { AuditHistoryProps } from "./types";

export const AuditHistoryDrawer: FC<PropsWithChildren<AuditHistoryProps>> = ({
  children,
  open: isOpen,
  onClickClose: handleClickClose,
  onClickOpen: handleClickOpen,
}) => {
  return (
    <>
      <ShowButton onClick={handleClickOpen} />
      {isOpen && (
        <MuiDrawer
          anchor='right'
          open={isOpen}
          onClose={handleClickClose}
          PaperProps={{
            sx: {
              width: 554,
            },
          }}
        >
          {children}
        </MuiDrawer>
      )}
    </>
  );
};
