import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { customerSupportOrderStatusOptions } from "@health/enum-options";
import { combineErrors, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Button, Grid, Typography, useTheme } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";
export const OrderHandlingForm = ({ errors, loading }) => {
    var _a, _b;
    const { t } = useTranslation("domains");
    const theme = useTheme();
    const { formState: { errors: formErrors }, control, } = useFormContext();
    const combinedErrors = combineErrors(formErrors, errors);
    const name = "status";
    return (_jsxs(Box, { sx: { width: "100%" }, children: [_jsx(Box, { children: _jsx(Typography, { component: 'span', fontWeight: 'bold', fontSize: theme.mixins.fonts.fontSize.md, children: t("Handle Order") }) }), _jsx(Box, { sx: { marginTop: 2 }, children: _jsxs(Grid, { container: true, spacing: formGirdSpacing, justifyContent: 'space-between', children: [_jsx(Grid, { item: true, xs: 6, textAlign: 'left', children: _jsx(AutocompleteController, { ControllerProps: {
                                    name: name,
                                    control: control,
                                    rules: { required: true },
                                }, TextFieldProps: {
                                    placeholder: t("Order Status"),
                                    error: Boolean((_a = combinedErrors === null || combinedErrors === void 0 ? void 0 : combinedErrors[name]) === null || _a === void 0 ? void 0 : _a.type),
                                    helperText: t(((_b = combinedErrors === null || combinedErrors === void 0 ? void 0 : combinedErrors[name]) === null || _b === void 0 ? void 0 : _b.type) && t("Required")),
                                    label: t("Order Status"),
                                }, getOptionLabel: option => option === null || option === void 0 ? void 0 : option.label, options: customerSupportOrderStatusOptions }) }), _jsx(Grid, { item: true, xs: 6, textAlign: 'right', children: _jsx(Button, { type: 'submit', disabled: loading, children: t("Handle") }) })] }) })] }));
};
