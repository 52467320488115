import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { InsuranceNetworkDocument, InsuranceNetworkUpdateMutation, useInsuranceNetworkUpdateMutation } from "pages/HealthInsurance/gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useInsuranceUpdateForm = (networkId: string) => {
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any[]>();
  const [insuranceNetworkUpdate, { loading: isUpdateLoading }] = useInsuranceNetworkUpdateMutation({
    onCompleted: (data: InsuranceNetworkUpdateMutation) => {
      setErrors(data.insuranceNetworkUpdate?.insuranceNetworkErrors!);
      if (data?.insuranceNetworkUpdate?.insuranceNetworkErrors?.length === 0) {
        succeeded(t(" network Updated Successfully"));
        navigate(-1);
      } else {
        const message: string[] | undefined = data.insuranceNetworkUpdate?.insuranceNetworkErrors?.map(error => {
          return `${error.field || ""} , $${error.message || ""}`;
        });
        const dataFailed = message && (message[0] as string).split(",,");
        dataFailed && failed(t(dataFailed[1], dataFailed[1]) as any, dataFailed[0]);
      }
    },
    onError: ({ graphQLErrors }) => {
      const message: string[] = graphQLErrors?.map(error => {
        return `${error.message || ""}`;
      });
      failed(t(message[0], message[0]) as any);
      console.error("network update", graphQLErrors);
    },
    refetchQueries: [
      {
        query: InsuranceNetworkDocument,
        variables: {
          id: networkId,
        },
      },
    ],
  });
  const onUpdate = async (data: any) => {
    const request = {
      ...data,
    };

    await insuranceNetworkUpdate({
      variables: {
        id: networkId,
        input: {
          ...request,
        },
      },
    });
  };
  return {
    onUpdate,
    errors,
    isUpdateLoading,
  };
};
export default useInsuranceUpdateForm;
