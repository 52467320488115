var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useDoctorTeamPatientsMembersAutocompleteQuery } from "./gql";
export const DoctorTeamPatientsMembersAutocomplete = props => {
    const { name, filter, skip, label } = props, rest = __rest(props, ["name", "filter", "skip", "label"]);
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMore, fetchMoreData } = useRelayStyleConnection({
        useQuery: useDoctorTeamPatientsMembersAutocompleteQuery,
        variables: Object.assign({}, filter),
        skip: props.disabled || skip,
    });
    const _data = (data === null || data === void 0 ? void 0 : data.map(item => {
        var _a, _b;
        return ({
            id: (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.id,
            name: (_b = item === null || item === void 0 ? void 0 : item.user) === null || _b === void 0 ? void 0 : _b.fullName,
        });
    })) || [];
    const options = mapToAutocompleteOptions(_data, "id", "name");
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || (props.multiple ? t("Members") : t("Member")), options: options, hasMore: hasMore, loading: isLoading, fetchMoreData: fetchMoreData })));
};
