import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const FetchDataForEditCodeSystemConceptDocument = gql `
    query fetchDataForEditCodeSystemConcept($id: ID!, $codeSystemConceptId: ID!) {
  schemaData: getCodeSystem(id: $id) {
    id
    codeSystemDefinition {
      id
      code
      codeSystemConceptFields {
        code
        display
        fieldType
        isMandatory
      }
    }
  }
  propertiesData: getCodeSystemConcept(id: $codeSystemConceptId) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;
/**
 * __useFetchDataForEditCodeSystemConceptQuery__
 *
 * To run a query within a React component, call `useFetchDataForEditCodeSystemConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDataForEditCodeSystemConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDataForEditCodeSystemConceptQuery({
 *   variables: {
 *      id: // value for 'id'
 *      codeSystemConceptId: // value for 'codeSystemConceptId'
 *   },
 * });
 */
export function useFetchDataForEditCodeSystemConceptQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchDataForEditCodeSystemConceptDocument, options);
}
export function useFetchDataForEditCodeSystemConceptLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchDataForEditCodeSystemConceptDocument, options);
}
