/* eslint-disable max-lines */
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";
import { fieldPattern, requiredPositiveIntegerPattern } from "../utils";
import { formGirdBreakPoints } from "./constants";

export const OrdersSettings: React.FC<ISiteSetting> = ({ loading, errors: formErrors, siteSettings }) => {
  const { register } = useFormContext();
  const { t } = useTranslation("admin");

  return (
    <Grid item xs={12}>
      <FormCard title={t("Orders Settings")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderAcceptanceTimeout}
              label={t("Order Acceptance Timeout")}
              {...register("orderAcceptanceTimeout", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderAcceptanceTimeout?.message)}
              helperText={formErrors?.orderAcceptanceTimeout?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderDeliveryTimeout}
              label={t("Order Delivery Timeout")}
              {...register("orderDeliveryTimeout", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderDeliveryTimeout?.message)}
              helperText={formErrors?.orderDeliveryTimeout?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderDispensingTimeoutAlert}
              label={t("Order Dispensing Timeout Alert")}
              {...register("orderDispensingTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderDispensingTimeoutAlert?.message)}
              helperText={formErrors?.orderDispensingTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderDispensingTimeout}
              label={t("Order Dispensing Timeout")}
              {...register("orderDispensingTimeout", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderDispensingTimeout?.message)}
              helperText={formErrors?.orderDispensingTimeout?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.prescriptionOrderPaymentTimeoutSeconds}
              label={t("Prescription Order Payment Timeout Seconds")}
              {...register("prescriptionOrderPaymentTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.prescriptionOrderPaymentTimeoutSeconds?.message)}
              helperText={formErrors?.prescriptionOrderPaymentTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderErxHubApprovalResubmitTimeout}
              label={t("Order Erx Hub Approval Resubmit Timeout")}
              {...register("orderErxHubApprovalResubmitTimeout", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderErxHubApprovalResubmitTimeout?.message)}
              helperText={formErrors?.orderErxHubApprovalResubmitTimeout?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderErxHubApprovalTimeoutAlert}
              label={t("order Erx Hub Approval Timeout Alert")}
              {...register("orderErxHubApprovalTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderErxHubApprovalTimeoutAlert?.message)}
              helperText={formErrors?.orderErxHubApprovalTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.orderErxHubApprovalTimeout}
              label={t("Order Erx Hub Approval Timeout")}
              {...register("orderErxHubApprovalTimeout", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.orderErxHubApprovalTimeout?.message)}
              helperText={formErrors?.orderErxHubApprovalTimeout?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              defaultValue={siteSettings?.orderBaseDeliveryFee ?? 0}
              fullWidth
              label={t("Order Base Delivery Fee")}
              InputProps={{
                endAdornment: siteSettings?.defaultCurrency,
              }}
              {...register("orderBaseDeliveryFee", {
                ...fieldPattern(t),
              })}
              error={Boolean(formErrors?.orderBaseDeliveryFee?.message)}
              helperText={formErrors?.orderBaseDeliveryFee?.message}
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
