/* eslint-disable sonarjs/cognitive-complexity */
import { ApolloQueryResult, DefaultContext, NetworkStatus, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { useCallback, useState } from "react";
import { ExtractFilter, ExtractNodeType, ResponseDataAccessor } from "../types";

export type IRelayStyleConnectionProps<T, V extends OperationVariables> = {
  useQuery: (baseOptions?: QueryHookOptions<T, V>) => QueryResult<T, V>;
  variables?: V;
  searchKey?: keyof ExtractFilter<V>;
  skip?: boolean;
  context?: DefaultContext;
};

export type IRelayStyleConnectionReturn<T, V extends OperationVariables> = {
  data: ExtractNodeType<T>[];
  isLoading: boolean;
  hasMore: boolean;
  fetchMoreData: () => void;
  handleSearch: (value: string) => void;
  refetch: (variables?: Partial<V>) => Promise<ApolloQueryResult<T>>;
};

export const useRelayStyleConnection = <T, V extends OperationVariables>(
  props: IRelayStyleConnectionProps<T, V>
): IRelayStyleConnectionReturn<T, V> => {
  const { useQuery, variables, searchKey, skip, context } = props;

  const [localVariables, setLocalVariables] = useState<V | undefined>({ first: 10, ...variables! });

  const {
    data: response,
    loading,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery({
    variables: localVariables,
    skip,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context,
  });

  const responseDataAccessorKey = response ? (Object.keys(response)[0] as keyof T) : undefined;
  const responseDataAccessor = responseDataAccessorKey ? (response?.[responseDataAccessorKey] as ResponseDataAccessor<T>) : undefined;
  const data = responseDataAccessor?.edges?.map(item => item?.node) || [];

  const hasMore = !!responseDataAccessor?.pageInfo?.hasNextPage;
  const isLoading = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const fetchMoreData = useCallback(() => {
    if (hasMore && !isLoading) {
      fetchMore({
        variables: {
          ...variables,
          after: responseDataAccessor?.pageInfo?.endCursor,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousQueryResult;
          }

          const fetchMoreResultDataAccessorKey = response ? (Object.keys(fetchMoreResult)[0] as keyof T) : undefined;
          const fetchMoreResultDataAccessor = fetchMoreResultDataAccessorKey
            ? (response?.[fetchMoreResultDataAccessorKey] as ResponseDataAccessor<T>)
            : undefined;

          return {
            ...fetchMoreResult,
            [responseDataAccessorKey!]: {
              ...fetchMoreResult?.[responseDataAccessorKey!],
              edges: [...(fetchMoreResultDataAccessor?.edges || []), ...(fetchMoreResultDataAccessor?.edges || [])],
            },
          };
        },
      });
    }
  }, [fetchMore, hasMore, loading, variables]);

  const handleSearch = (value: string) => {
    if (!searchKey) return;

    // todo: Once the backend adopts a consistent filter structure across all queries, we will replace the current implementation with this approach.
    // setLocalVariables(prevState => ({ ...prevState, filter: { ...prevState?.filter, [searchKey]: value } } as unknown as V));

    setLocalVariables(prevState => {
      const newFilter = {
        [searchKey]: value,
      };

      if ("filter" in (prevState || {})) {
        return {
          ...prevState!,
          filter: {
            ...prevState?.filter,
            ...newFilter,
          },
        } as V;
      }

      return {
        ...prevState,
        ...newFilter,
      } as V;
    });
  };

  return {
    data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleSearch,
    refetch,
  };
};
