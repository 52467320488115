import { HealthProgramSubscriptionPeriod } from "@health/queries/types";

export const careForOptions = [
  { label: "Allergies", value: "Allergies" },
  { label: "Cold & Flu", value: "Cold & Flu" },
  { label: "Sinus Infections", value: "Sinus Infections" },
  { label: "Bronchitis", value: "Bronchitis" },
  { label: "Stomach Pain", value: "Stomach Pain" },
  { label: "UTI", value: "UTI" },
  { label: "Pink-eye", value: "Pink-eye" },
  { label: "General Pain", value: "General Pain" },
  { label: "Cold Sores", value: "Cold Sores" },
  { label: "Rashes", value: "Rashes" },
  { label: "Fatigue", value: "Fatigue" },
  { label: "Acne", value: "Acne" },
];

export const membersOptions = [
  { label: "Pregnant", value: "Pregnant" },
  { label: "Non-Smokers", value: "Non-Smokers" },
  { label: "insured", value: "insured" },
  { label: "+18 age", value: "+18 age" },
];

export const basicBenefitsOptions = [
  { label: "Proactive Care", value: "proActive", id: 1 },
  { label: "Reactive Care", value: "reactive", id: 2 },
  { label: "eRx Coverage (AED)", value: "coverage", id: 3 },
];

export const healthProgramSubscriptionPeriods = [
  { name: "Monthly", value: HealthProgramSubscriptionPeriod.Monthly },
  { name: "Yearly", value: HealthProgramSubscriptionPeriod.Yearly },
];
