import { CallbackRequestStatus } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const callbackRequestStatusOptionsMap = {
    [CallbackRequestStatus.InProgress]: {
        key: CallbackRequestStatus.InProgress,
        get label() {
            return i18n.t("InProgress", { ns: "domains" });
        },
        value: CallbackRequestStatus.InProgress,
    },
    [CallbackRequestStatus.New]: {
        key: CallbackRequestStatus.New,
        get label() {
            return i18n.t("New", { ns: "domains" });
        },
        value: CallbackRequestStatus.New,
    },
    [CallbackRequestStatus.Resolved]: {
        key: CallbackRequestStatus.Resolved,
        get label() {
            return i18n.t("Resolved", { ns: "domains" });
        },
        value: CallbackRequestStatus.Resolved,
    },
};
export const callbackRequestStatusOptions = Object.values(callbackRequestStatusOptionsMap);
