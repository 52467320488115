import { BranchError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { branchListBreadcrumb, branchNewBreadcrumb, branchesTitleBreadcrumb } from "pages/Branches/constants/branches-breadcrumbs";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateBranchMutation } from "../../gql";

export const useBranchNewPageHook = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const [errors, setError] = useState<BranchError[]>();
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const translateBranch = t("Branch Create Successfully");
  const params = useParams();
  const [createBranch, { loading: isSubmitting }] = useCreateBranchMutation({
    onCompleted: data => {
      const errors = data?.branchCreate?.branchErrors;
      if (errors?.length === 0) {
        succeeded(translateBranch);
        navigate(providersManagementPaths.branchesPath.fullPathWithParams(String(params?.id)));
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as BranchError[]);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleCreateBranch = data => {
    createBranch({
      variables: {
        vendorId: params.id as string,
        branch: data,
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: branchesTitleBreadcrumb(),
      values: [branchListBreadcrumb(String(params?.id)), branchNewBreadcrumb()],
    });
  }, []);

  return {
    errors,
    handleCreateBranch,
    isSubmitting,
  };
};
