import { FormCard, FormSwitch, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type NotificationSettingsProps = {
  isLoading?: boolean;
};

export const NotificationSettings: FC<NotificationSettingsProps> = props => {
  const { isLoading } = props;

  const { t } = useTranslation("admin");

  return (
    <FormCard title={t("Notifications")} loading={isLoading} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSwitch name={"notifyByEmail"} label={t("Notify By Email?")} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
