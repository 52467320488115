import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthConditionAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullable().optional(),
}));
export const HealthConditionAutocompleteSchemas = {
    healthConditionAutocomplete,
    healthConditionAutocompleteOptional: healthConditionAutocomplete.nullable().optional(),
};
