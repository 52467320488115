import { DecisionMakerOrderDirection, SubListSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAllListFieldListQuery } from "../../../Sublists/gql";
import { useMemo } from "react";
export const useSublistListTableColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "name",
                header: t("Name"),
                accessor: ({ display }) => display,
                isHidden: false,
                isSortable: true,
                hideFromSettings: true,
                disableToggleVisibility: true,
                sortDirection: DecisionMakerOrderDirection.Asc,
                sortColumnEnum: SubListSortField.Display,
            },
            {
                key: "display",
                header: t("Display"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "display",
                },
            },
            {
                key: "Field",
                header: t("Field"),
                accessor: ({ field }) => field === null || field === void 0 ? void 0 : field.display,
                type: "string",
                disableToggleVisibility: true,
                isSortable: true,
                sortDirection: undefined,
                sortColumnEnum: SubListSortField.Field,
                filter: {
                    type: "autocomplete",
                    query: useAllListFieldListQuery,
                    getOptionLabel: option => option === null || option === void 0 ? void 0 : option.display,
                    getValueForBackend: options => options === null || options === void 0 ? void 0 : options.id,
                    name: "field",
                    accessor: "fields",
                },
            },
        ];
    }, []);
};
