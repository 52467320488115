import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthProgramMemberListAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const HealthProgramMemberListAutocompleteSchemas = {
    healthProgramMemberListAutocomplete,
    healthProgramMemberListAutocompleteOptional: healthProgramMemberListAutocomplete.nullable().optional(),
};
