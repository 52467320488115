/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc, PermissionGroupErrorFragmentFragmentDoc } from '../../fragments/__generated__/permissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdminPermissionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.PermissionGroupFilterInput>;
  sortBy?: Types.InputMaybe<Types.PermissionGroupSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  appType: Types.AppTypes;
}>;


export type GetAdminPermissionsQuery = { __typename?: 'Query', permissionGroups?: { __typename?: 'GroupCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'GroupCountableEdge', node: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null } }> } | null };


export const GetAdminPermissionsDocument = gql`
    query getAdminPermissions($first: Int, $last: Int, $filter: PermissionGroupFilterInput, $sortBy: PermissionGroupSortingInput, $before: String, $after: String, $appType: AppTypes!) {
  permissionGroups(
    first: $first
    last: $last
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
    appType: $appType
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        ...PermissionGroupFragment
      }
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}`;

/**
 * __useGetAdminPermissionsQuery__
 *
 * To run a query within a React component, call `useGetAdminPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminPermissionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      appType: // value for 'appType'
 *   },
 * });
 */
export function useGetAdminPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminPermissionsQuery, GetAdminPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminPermissionsQuery, GetAdminPermissionsQueryVariables>(GetAdminPermissionsDocument, options);
      }
export function useGetAdminPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminPermissionsQuery, GetAdminPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminPermissionsQuery, GetAdminPermissionsQueryVariables>(GetAdminPermissionsDocument, options);
        }
export type GetAdminPermissionsQueryHookResult = ReturnType<typeof useGetAdminPermissionsQuery>;
export type GetAdminPermissionsLazyQueryHookResult = ReturnType<typeof useGetAdminPermissionsLazyQuery>;
export type GetAdminPermissionsQueryResult = Apollo.QueryResult<GetAdminPermissionsQuery, GetAdminPermissionsQueryVariables>;