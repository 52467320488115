/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkFilesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BulkFilesFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.BulkFilesSortingInput>;
}>;


export type BulkFilesQuery = { __typename?: 'Query', bulkFiles?: { __typename?: 'BulkFileConnection', edges?: Array<{ __typename?: 'BulkFileEdge', node?: { __typename?: 'BulkFile', id?: string | null, failedRecordCount?: number | null, code?: Types.BulkTemplateCode | null, processedRecordCount?: number | null, recordsCount?: number | null, createdDate?: any | null, fileName?: string | null, updatedDate?: any | null, processingStatus?: Types.BulkProcessingStatus | null, createdBy?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, fullName?: string | null, id: string } | null, errors?: Array<{ __typename?: 'BulkGraphqlError', code?: Types.BulkErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const BulkFilesDocument = gql`
    query BulkFiles($filter: BulkFilesFilterInput, $first: Int, $after: String, $before: String, $last: Int, $sortBy: BulkFilesSortingInput) {
  bulkFiles(
    filters: $filter
    first: $first
    after: $after
    before: $before
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        failedRecordCount
        code
        processedRecordCount
        recordsCount
        createdBy {
          firstName
          lastName
          fullName
          id
        }
        createdDate
        errors {
          code
          errorType
          field
          message
        }
        fileName
        updatedDate
        processingStatus
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useBulkFilesQuery__
 *
 * To run a query within a React component, call `useBulkFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBulkFilesQuery(baseOptions?: Apollo.QueryHookOptions<BulkFilesQuery, BulkFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkFilesQuery, BulkFilesQueryVariables>(BulkFilesDocument, options);
      }
export function useBulkFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkFilesQuery, BulkFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkFilesQuery, BulkFilesQueryVariables>(BulkFilesDocument, options);
        }
export type BulkFilesQueryHookResult = ReturnType<typeof useBulkFilesQuery>;
export type BulkFilesLazyQueryHookResult = ReturnType<typeof useBulkFilesLazyQuery>;
export type BulkFilesQueryResult = Apollo.QueryResult<BulkFilesQuery, BulkFilesQueryVariables>;