import { AMLDetailsEditPage, AMLDetailsPage, AdminManagedLists, adminManagedListsPaths, adminManagedListsRoute } from "@health/domains";
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { MedicationIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const adminManagedListsRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "admin-managed-lists-routes",
    text: i18n.t("Admin Managed Lists", { ns: "admin" }),
    icon: <MedicationIcon />,
    route: adminManagedListsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageCodeSystemLists),
    subItems: [
      {
        id: "admin-managed-lists-list-route",
        text: i18n.t("Admin Managed Lists", { ns: "admin" }),
        route: adminManagedListsPaths.listPath.route,
        fullPath: adminManagedListsPaths.listPath.fullPath,
        element: <AdminManagedLists />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "admin-managed-lists-details-route",
        text: i18n.t("Admin Managed List Details", { ns: "admin" }),
        route: adminManagedListsPaths.detailsPath.route,
        fullPath: adminManagedListsPaths.detailsPath.fullPath,
        element: <AMLDetailsPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "admin-managed-lists-details-edit-route",
        text: i18n.t("Admin Managed List Details Edit", { ns: "admin" }),
        route: adminManagedListsPaths.detailsEditPath.route,
        fullPath: adminManagedListsPaths.detailsEditPath.fullPath,
        element: <AMLDetailsEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
