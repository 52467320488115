import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography, useAddToast } from "@toolkit/ui";
import { useSendCodeSystemToReviewMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
export const SendToReviewButton = ({ onClick }) => {
    const { t } = useTranslation("domains");
    const { failed } = useAddToast();
    const [sendCodeSystemToReview, { loading }] = useSendCodeSystemToReviewMutation({
        onCompleted: (data) => {
            var _a;
            const mutationErrors = (_a = data === null || data === void 0 ? void 0 : data.sendCodeSystemToReview) === null || _a === void 0 ? void 0 : _a.errors;
            if (mutationErrors && (mutationErrors === null || mutationErrors === void 0 ? void 0 : mutationErrors.length) > 0) {
                failed(t(formatMessageErrors(mutationErrors)));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const { refetchCodeSystem, versionParamId } = useAdminManagedListDetailsPageContext();
    const handleSendToReview = () => {
        sendCodeSystemToReview({
            variables: {
                codeSystemId: versionParamId,
            },
        }).then(() => {
            refetchCodeSystem === null || refetchCodeSystem === void 0 ? void 0 : refetchCodeSystem();
        });
        onClick();
    };
    return (_jsxs(MenuItem, { onClick: handleSendToReview, disabled: loading, children: [_jsx(CustomIcon, { icon: 'share' }), _jsxs(Typography, { marginLeft: '2px', children: [" ", t("Send To Review")] })] }));
};
