/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSurveysQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.SurveyFilter>;
  sortBy?: Types.InputMaybe<Types.SurveySorting>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchSurveysQuery = { __typename?: 'Query', surveys?: { __typename?: 'SurveyConnection', pageInfo?: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'SurveyEdge', cursor?: string | null, node?: { __typename?: 'Survey', id?: string | null, label?: string | null, createdDate?: any | null, status?: Types.SurveyStatus | null, createdBy?: { __typename?: 'User', id: string, fullName?: string | null } | null, publishCriteria?: Array<{ __typename?: 'PublishCriteria', id?: string | null, expirationDate?: any | null, createdDate?: any | null, participantsCount?: number | null } | null> | null } | null } | null> | null } | null };


export const FetchSurveysDocument = gql`
    query fetchSurveys($first: Int, $last: Int, $filter: SurveyFilter, $sortBy: SurveySorting, $after: String) {
  surveys(
    first: $first
    last: $last
    filter: $filter
    sortBy: $sortBy
    after: $after
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        label
        createdDate
        createdBy {
          id
          fullName
        }
        status
        publishCriteria {
          id
          expirationDate
          createdDate
          participantsCount
        }
      }
    }
  }
}
    `;

/**
 * __useFetchSurveysQuery__
 *
 * To run a query within a React component, call `useFetchSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveysQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchSurveysQuery(baseOptions?: Apollo.QueryHookOptions<FetchSurveysQuery, FetchSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSurveysQuery, FetchSurveysQueryVariables>(FetchSurveysDocument, options);
      }
export function useFetchSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSurveysQuery, FetchSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSurveysQuery, FetchSurveysQueryVariables>(FetchSurveysDocument, options);
        }
export type FetchSurveysQueryHookResult = ReturnType<typeof useFetchSurveysQuery>;
export type FetchSurveysLazyQueryHookResult = ReturnType<typeof useFetchSurveysLazyQuery>;
export type FetchSurveysQueryResult = Apollo.QueryResult<FetchSurveysQuery, FetchSurveysQueryVariables>;