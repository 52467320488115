import { TFunction } from "@toolkit/i18n";
import { surveysPaths } from "pages/Surveys/constants/surveys-paths";

export const surveysTitleBreadcrumb = (t: TFunction) => {
  return t("Surveys", { ns: "admin" });
};

export const surveysListBreadcrumb = (t: TFunction) => {
  return {
    id: "surveys-list",
    name: t("Surveys", { ns: "admin" }),
    route: surveysPaths.listPath.fullPath,
  };
};

export const surveysNewBreadcrumb = (t: TFunction) => {
  return {
    id: "surveys-new",
    name: t("New Survey", { ns: "admin" }),
  };
};

export const surveysFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "surveys-field",
    name: fieldName,
  };
};
