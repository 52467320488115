/* eslint-disable max-lines */
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation, patternEmail } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormPhoneInput, Grid, TextField, ToggleButtonController, createAutocompleteOption } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IForm, IFormData } from "../types";
import { PayerAutocomplete } from "./PayerAutocomplete";
import { useGetBackendErrorsForInputsValues } from "../hooks/setBackendsErrors";
import { payerTpoOptions, payerTpoOptionsMap, payerTypeOptions, payerTypeOptionsMap } from "@health/enum-options";
import { PayersAutocomplete } from "@health/autocompletes";
import { PayerTypeEnum } from "@health/queries/types";

export const GeneralInformation: FC<IForm> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");
  const {
    formState: { errors: reactFormErrors },
    setValue,
    register,
    control,
    watch,
  } = useFormContext<IFormData>();

  useEffect(() => {
    setValue("isActive", defaultValue?.isActive ?? false);
    setValue("contactMobileNumber", defaultValue?.contactMobileNumber || "");
    setValue("contactPhoneNumber", defaultValue?.contactPhoneNumber || "");
    defaultValue?.parent
      ? setValue(
          "parent",
          createAutocompleteOption(defaultValue?.parent, "id", item => pickLocalizedValue(item?.name, item?.nameAr!))
        )
      : null;
  }, [defaultValue, setValue]);

  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errors, [
    "name",
    "nameAr",
    "contactName",
    "contactEmail",
    "contactMobileNumber",
    "contactPhoneNumber",
    "licenseNumber",
  ]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Name")}
          error={Boolean(reactFormErrors?.name?.message!)}
          helperText={t(reactFormErrors?.name?.message!)}
          defaultValue={defaultValue?.name}
          {...register("name", { required: getRequiredValidation(t, true), validate: value => backendErrorStorage["name"][value] })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Arabic Name")}
          error={Boolean(reactFormErrors?.nameAr?.message!)}
          helperText={t(reactFormErrors?.nameAr?.message!)}
          defaultValue={defaultValue?.nameAr}
          {...register("nameAr", {
            validate: value => backendErrorStorage["nameAr"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Contact Name")}
          error={Boolean(reactFormErrors?.contactName?.message!)}
          helperText={t(reactFormErrors?.contactName?.message!)}
          defaultValue={defaultValue?.contactName}
          {...register("contactName", {
            required: getRequiredValidation(t, true),
            validate: value => backendErrorStorage["contactName"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Contact Email")}
          error={Boolean(reactFormErrors?.contactEmail?.message!)}
          helperText={t(reactFormErrors?.contactEmail?.message!)}
          defaultValue={defaultValue?.contactEmail}
          {...register("contactEmail", {
            required: getRequiredValidation(t, true),
            pattern: patternEmail(t),
            validate: value => backendErrorStorage["contactEmail"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactMobileNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Mobile Number")}
          error={Boolean(reactFormErrors?.["contactMobileNumber"]?.message)}
          helperText={t(reactFormErrors?.["contactMobileNumber"]?.message as string)}
          fullWidth
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactPhoneNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Phone Number")}
          error={Boolean(reactFormErrors?.["contactPhoneNumber"]?.message)}
          helperText={t(reactFormErrors?.["contactPhoneNumber"]?.message as string)}
          fullWidth
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("License Number")}
          error={Boolean(reactFormErrors?.licenseNumber?.message!)}
          helperText={t(reactFormErrors.licenseNumber?.message!)}
          defaultValue={defaultValue?.licenseNumber}
          {...register("licenseNumber", {
            required: getRequiredValidation(t, true),
            validate: value => backendErrorStorage["licenseNumber"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <PayerAutocomplete
          name='tpo'
          placeholder={t("TPO")}
          errors={errors}
          options={payerTpoOptions}
          defaultValue={payerTpoOptionsMap[defaultValue?.tpo || ""] || undefined}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <PayerAutocomplete
          name='type'
          placeholder={t("Type")}
          errors={errors}
          options={payerTypeOptions}
          defaultValue={payerTypeOptionsMap[defaultValue?.type || ""] || undefined}
          isDisabled={Boolean(defaultValue?.type)}
        />
      </Grid>
      {watch("type")?.value === PayerTypeEnum.Tpa && (
        <Grid item {...formGirdBreakPoints}>
          <PayersAutocomplete name={"parent"} />
        </Grid>
      )}
      <Grid container columnSpacing={formGirdSpacing} item alignItems='center'>
        <Grid item {...formGirdBreakPoints}>
          <ToggleButtonController
            {...register("isActive")}
            control={control}
            defaultValue={defaultValue?.isActive ?? false}
            label={t("Is Active")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
