import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormPhoneInput, Grid, ToggleButtonController } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "../../../../ProvidersManagement/components/Form/components/CustomTextField.components";
import { useGetBackendErrorsForInputsValues } from "../setBackendsErrors";
import { BranchesType } from "./BranchesType.component";
import { BranchHealthLicense } from "./BranchHealthLicense";
import { generalInformationFields } from "./generalInformationUtils";

interface GeneralInfoProps {
  errorsInput: any;
  defaultValue;
  isEditMode?: boolean;
}

export const GeneralInformation: FC<GeneralInfoProps> = props => {
  const { t } = useTranslation("admin");
  const { defaultValue, errorsInput } = props;
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue("healthLicenseStartDate", defaultValue?.healthLicenseStartDate || null);
    setValue("healthLicenseEndDate", defaultValue?.healthLicenseEndDate || null);
    setValue("contactNumber", defaultValue?.contactNumber || null);
  }, []);
  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errorsInput, [
    "name",
    "nameAr",
    "description",
    "contactEmail",
    "contactNumber",
    "healthLicense",
  ]);
  return (
    <Grid container spacing={formGirdSpacing}>
      {generalInformationFields(t, defaultValue, backendErrorStorage)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item.defaultValue}
            pattern={item.pattern}
            errorsInput={errors}
            registerProps={item?.registerProps}
          />
        </Grid>
      ))}
      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Number")}
          error={Boolean(errorsInput?.["contactNumber"]?.message)}
          helperText={t(errorsInput?.["contactNumber"]?.message)}
          fullWidth
        />
      </Grid>
      <BranchHealthLicense errorsInput={errorsInput} defaultValue={defaultValue} />
      <BranchesType defaultValue={defaultValue} />
      <Grid item xs={12}>
        <Box display='flex' flexWrap='wrap'>
          <Box display='flex' alignItems='center'>
            <ToggleButtonController
              control={control}
              name='isActive'
              label={t("Is Active")}
              defaultValue={defaultValue?.isActive ?? true}
            />
            <ToggleButtonController
              control={control}
              name='acceptsDelivery'
              label={t("Accepts Delivery")}
              defaultValue={defaultValue?.acceptsDelivery ?? false}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
