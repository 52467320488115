import { H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  memberNewBreadcrumb,
  membersBreadcrumb,
  membersTitleBreadcrumb,
} from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramMemberListsDocument, useHealthProgramMemberListCreateMutation } from "../gql";
import { HealthProgramMemberListsForm } from "./components/HealthProgramMemberListsForm";

export const HealthProgramMemberListNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  const [healthProgramMemberListCreate, { loading: isSubmitting }] = useHealthProgramMemberListCreateMutation({
    onCompleted: data => {
      const healthProgramMemberListErrors = data?.healthProgramMemberListCreate?.entityErrors!;
      if (healthProgramMemberListErrors?.length === 0) {
        succeeded(t("Member List Created Successfully"));
        navigate(-1);
      } else {
        setError(healthProgramMemberListErrors);
        failed(formatMessageErrors(healthProgramMemberListErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
    refetchQueries: [
      {
        query: HealthProgramMemberListsDocument,
        variables: {
          first: 10,
          filter: {
            isActive: true,
          },
        },
      },
    ],
  });

  const handleSubmit = data => {
    const { payerId, ...rest } = data;

    healthProgramMemberListCreate({
      variables: {
        input: { payerId: payerId?.id, ...rest, isActive: data?.isActive ? data?.isActive : false },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: membersTitleBreadcrumb(t),
      values: [membersBreadcrumb(t), memberNewBreadcrumb(t)],
    });
  }, []);

  return <HealthProgramMemberListsForm isSubmitting={isSubmitting} errors={errors} onDone={handleSubmit} />;
};
