import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthPackageAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const HealthPackageAutocompleteSchemas = {
    healthPackageAutocomplete,
    healthPackageAutocompleteOptional: healthPackageAutocomplete.nullable().optional(),
};
