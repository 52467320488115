import { SublistListContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { SubList } from "@health/queries/types";
import { subListsBreadcrumbs, subListsPaths } from "pages/Sublists/constants";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AdminSublistListContainer: FC = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const onAddNewClick = () => {
    navigate(subListsPaths.new.fullPath);
  };

  const onEditRowClick = (item: SubList) => {
    if (!item) return;
    navigate(subListsPaths.edit.fullPathWithParams({ id: item.id! }));
  };

  useEffect(() => {
    const { title } = subListsBreadcrumbs(t);

    setBreadCrumb({ title });
  }, [setBreadCrumb, t]);

  return <SublistListContainer onAddNewClick={onAddNewClick} onEditRowClick={onEditRowClick} />;
};
