/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramTotalPointsReportQueryVariables = Types.Exact<{
  filter: Types.HealthProgramPointsFilterInput;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type HealthProgramTotalPointsReportQuery = { __typename?: 'Query', healthProgramTotalPointsReport: { __typename?: 'HealthProgramTotalPointsTrackingConnection', edges: Array<{ __typename?: 'HealthProgramTotalPointsTrackingCountableEdge', node: { __typename?: 'HealthProgramTotalPointsTrackingResponse', totalPoints?: number | null, healthProgramId?: string | null, percentage?: number | null, healthProgramName?: string | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, photo?: string | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string } } | null } }> } };


export const HealthProgramTotalPointsReportDocument = gql`
    query HealthProgramTotalPointsReport($filter: HealthProgramPointsFilterInput!, $before: String, $after: String, $first: Int, $last: Int) {
  healthProgramTotalPointsReport(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        totalPoints
        healthProgramId
        percentage
        healthProgramName
        doctor {
          id
          user {
            firstName
            lastName
            photo
          }
          vendor {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramTotalPointsReportQuery__
 *
 * To run a query within a React component, call `useHealthProgramTotalPointsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramTotalPointsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramTotalPointsReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useHealthProgramTotalPointsReportQuery(baseOptions: Apollo.QueryHookOptions<HealthProgramTotalPointsReportQuery, HealthProgramTotalPointsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramTotalPointsReportQuery, HealthProgramTotalPointsReportQueryVariables>(HealthProgramTotalPointsReportDocument, options);
      }
export function useHealthProgramTotalPointsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramTotalPointsReportQuery, HealthProgramTotalPointsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramTotalPointsReportQuery, HealthProgramTotalPointsReportQueryVariables>(HealthProgramTotalPointsReportDocument, options);
        }
export type HealthProgramTotalPointsReportQueryHookResult = ReturnType<typeof useHealthProgramTotalPointsReportQuery>;
export type HealthProgramTotalPointsReportLazyQueryHookResult = ReturnType<typeof useHealthProgramTotalPointsReportLazyQuery>;
export type HealthProgramTotalPointsReportQueryResult = Apollo.QueryResult<HealthProgramTotalPointsReportQuery, HealthProgramTotalPointsReportQueryVariables>;