import { MarketplaceHealthPackagesCategoriesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { ICategoryUpsertFormValues } from "../CategoryFormUpsert";
import { useFormContext } from "react-hook-form";

type CategoryInformationFormProps = {
  hasParent?: boolean;
};

export const CategoryInformationForm: FC<CategoryInformationFormProps> = props => {
  const { hasParent } = props;
  const { t } = useTranslation("admin");
  const {
    formState: { errors },
  } = useFormContext<ICategoryUpsertFormValues>();
  const theme = useTheme();

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid container spacing={formGirdSpacing} item xs={12}>
        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"name"} label={t("Name")} />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"nameAr"} label={t("Arabic Name")} />
        </Grid>
      </Grid>
      <Grid container spacing={formGirdSpacing} item xs={12}>
        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"description"} label={t("Description")} multiline rows={3} />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"descriptionAr"} label={t("Arabic Description")} multiline rows={3} />
        </Grid>
        {!hasParent && (
          <Grid item {...formGirdBreakPoints}>
            <MarketplaceHealthPackagesCategoriesAutocomplete
              name={"parentCategory"}
              label={t("Parent Category")}
              TextFieldProps={{
                helperText: !errors
                  ? t("Choose a category. If not, this will be a Parent Category.")
                  : (errors?.parentCategory as any)?.message,
                sx: { "& .MuiFormHelperText-root": { color: theme.palette.primary.main } },
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
