import { YesNo, yesNoOptions } from "./YesNo";
export const getYesNoAutocompleteFilter = ({ name, reverse }) => {
    return {
        type: "autocomplete",
        name,
        options: yesNoOptions,
        getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.label,
        getValueForBackend: (option) => {
            if (option) {
                if (!reverse) {
                    return (option === null || option === void 0 ? void 0 : option.value) === YesNo.Yes;
                }
                else {
                    return (option === null || option === void 0 ? void 0 : option.value) !== YesNo.Yes;
                }
            }
            else {
                return undefined;
            }
        },
    };
};
