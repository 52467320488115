/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramSubscribeMutationVariables = Types.Exact<{
  input: Types.HealthProgramSubscribeInput;
}>;


export type HealthProgramSubscribeMutation = { __typename?: 'Mutation', healthProgramSubscribe?: { __typename?: 'HealthProgramSubscribe', healthProgramUserSubscription?: { __typename?: 'HealthProgramUserSubscription', id: string, currency?: string | null, isActive?: boolean | null } | null, healthProgramErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const HealthProgramSubscribeDocument = gql`
    mutation healthProgramSubscribe($input: HealthProgramSubscribeInput!) {
  healthProgramSubscribe(input: $input) {
    healthProgramUserSubscription {
      id
      currency
      isActive
    }
    healthProgramErrors {
      code
      field
      message
    }
  }
}
    `;
export type HealthProgramSubscribeMutationFn = Apollo.MutationFunction<HealthProgramSubscribeMutation, HealthProgramSubscribeMutationVariables>;

/**
 * __useHealthProgramSubscribeMutation__
 *
 * To run a mutation, you first call `useHealthProgramSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramSubscribeMutation, { data, loading, error }] = useHealthProgramSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramSubscribeMutation, HealthProgramSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramSubscribeMutation, HealthProgramSubscribeMutationVariables>(HealthProgramSubscribeDocument, options);
      }
export type HealthProgramSubscribeMutationHookResult = ReturnType<typeof useHealthProgramSubscribeMutation>;
export type HealthProgramSubscribeMutationResult = Apollo.MutationResult<HealthProgramSubscribeMutation>;
export type HealthProgramSubscribeMutationOptions = Apollo.BaseMutationOptions<HealthProgramSubscribeMutation, HealthProgramSubscribeMutationVariables>;