class Config {
    constructor() {
        this.config = null;
    }
    static getInstance() {
        if (!Config.instance) {
            Config.instance = new Config();
        }
        return Config.instance;
    }
    loadConfig(config) {
        this.config = config;
    }
    getConfig() {
        if (!this.config) {
            throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
        }
        return this.config;
    }
    get(key) {
        const config = this.getConfig();
        if (config[key] !== undefined) {
            return config[key];
        }
        else {
            throw new Error(`Key ${key} is not present in the configuration.`);
        }
    }
}
export const domainsConfig = Config.getInstance();
export const initializeDomainsConfig = (config) => {
    domainsConfig.loadConfig(config);
};
