import { AccountError, User } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, Chip, Grid, TextField } from "@toolkit/ui";
import { FC, Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const DoctorSocialLink: FC<{ errors: AccountError[]; defaultValues?: User | undefined }> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("admin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);
  return (
    <Grid item {...formGirdBreakPoints}>
      <Controller
        control={control}
        name='doctorInfo.socialLinks'
        rules={{ required: false }}
        defaultValue={defaultValues?.doctor?.socialLinks ? JSON.parse(defaultValues?.doctor?.socialLinks) : []}
        render={({ field: { onChange: handleChange } }) => (
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            defaultValue={defaultValues?.doctor?.socialLinks ? JSON.parse(defaultValues?.doctor?.socialLinks) : []}
            renderTags={(value, getTagProps) =>
              value?.map((option, index) => (
                <Fragment key={index}>
                  <Chip variant='outlined' sx={{ height: "23px" }} label={option} {...getTagProps({ index })} />
                </Fragment>
              ))
            }
            // eslint-disable-next-line react/jsx-handler-names
            onChange={(event, values) => {
              handleChange(values);
            }}
            renderInput={params => (
              <TextField
                {...params}
                size='small'
                sx={{ input: { marginTop: "-5px" } }}
                label={t("Social Links")}
                InputLabelProps={{
                  style: { marginTop: "5px" },
                }}
                placeholder={t("Social Links")}
                helperText={t(combinedErrors?.doctorInfo?.socialLinks?.message)}
                error={Boolean(combinedErrors?.doctorInfo?.socialLinks?.message)}
              />
            )}
          />
        )}
      />
    </Grid>
  );
};
