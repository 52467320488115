import { FC } from "react";
import { CitiesManagementForm } from "../Form/CitiesManagement.form";
import { useCitiesManagementEditFormPageHooks } from "./CitiesManagementEdit.hook";

export const CitiesManagementEditFormPage: FC = () => {
  const { city, errors, handleSubmit, isCityDataLoading, isSubmitting } = useCitiesManagementEditFormPageHooks();
  return (
    <CitiesManagementForm
      errors={errors}
      onDone={handleSubmit}
      isLoading={isCityDataLoading}
      defaultValues={city}
      isEditMode={true}
      isSubmitting={isSubmitting}
    />
  );
};
