import { makeStyles } from "@toolkit/ui";
export const useMedicalFormClassificationUpsertStyle = makeStyles()(theme => ({
    titleScore: {
        fontSize: theme.mixins.fonts.fontSize.lg,
        fontWeight: theme.mixins.fonts.fontWeight.medium,
    },
    minMaxScore: {
        fontSize: theme.mixins.fonts.fontSize.sm,
    },
}));
