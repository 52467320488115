import { BaseGridVariables } from "../types";

export const defaultVariables: BaseGridVariables = {
  first: 10,
  after: null,
  before: null,
  last: null,
  filter: undefined,
  sortBy: null,
};

export const defaultValue = {
  customTableProps: {
    data: [],
    columns: [],
    defaultColumns: [],
    pageIndex: 1,
    pagesCount: 1,
    pageSize: 1,
    totalCount: 10,
    isLoading: false,
    hasNextPage: false,
    hasPreviousPage: false,
    isCardView: false,
    onSortColumn: () => {},
    onGoToNext: () => {},
    onGoToPrevious: () => {},
  },
  staticFiltersProps: {
    isOpen: false,
    activeFilters: [],
    onToggleDialog: () => {},
    onApplyFilters: () => {},
    onDismissFilters: () => {},
  },
  activeFiltersProps: {
    requestedFilters: {},
    filters: {},
    activeFilters: [],
    onOpenFilters: () => {},
  },
  tablePreference: {
    isColumnsLoading: false,
    onColumnsChanged: () => {},
    reOrderColumns: [],
  },
  fields: [],
  selectedRows: [],
};
