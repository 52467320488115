import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import moment from "moment";
import { callsPerVendorTitleBreadcrumb } from "pages/HealthProgramCallsPerVendor/constants/call-per-vendor-breadcrumbs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useHealthProgramCallsForVendorQuery } from "./gql";
import { useHealthProgramCallsPerVendorColumns } from "./useHealthProgramCallsPerVendorColumns";

export const HealthProgramCallsPerVendor = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const params = useParams();

  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment().endOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    setBreadCrumb({
      title: callsPerVendorTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='healthProgramCallsForVendor'
      filterInput={{ fromDate, toDate, healthProgramId: Number(params?.programId)!, vendorId: params?.vendorId! }}
      variables={{
        filter: { fromDate, toDate, healthProgramId: Number(params?.programId)!, vendorId: params?.vendorId! },
      }}
      columns={useHealthProgramCallsPerVendorColumns()}
      query={useHealthProgramCallsForVendorQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper filters={<FilterGrid />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
