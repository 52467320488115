import { useState } from "react";

export const useOpenState = (isOpen: boolean | undefined = false) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleToggle = (): void => {
    setOpen(openState => !openState);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return { open, handleOpen, handleClose, handleToggle };
};
