import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { BankingInfoProps } from "./VendorList.types";

export const BankingInfo: FC<BankingInfoProps> = props => {
  const { iban, accountNumber, bankName, accountName } = props;
  const { t, i18n } = useTranslation("admin");

  const currentDir = i18n.dir(i18n.language);

  return (
    <CustomDialog type='info' title={t("Banking Information")}>
      <Grid container spacing={2}>
        {bankName && (
          <Grid item xs={6}>
            <TextField disabled dir={currentDir} fullWidth placeholder={t("Bank Name")} value={t(bankName)} label={t("Bank Name")} />
          </Grid>
        )}
        {accountName && (
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              dir={currentDir}
              placeholder={t("Account Name")}
              value={t(accountName)}
              label={t("Account Name")}
            />
          </Grid>
        )}
        {accountNumber && (
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              dir={currentDir}
              placeholder={t("Account Number")}
              value={t(accountNumber)}
              label={t("Account Number")}
            />
          </Grid>
        )}
        {iban && (
          <Grid item xs={6}>
            <TextField disabled fullWidth dir={currentDir} placeholder={t("IBAN")} value={t(iban)} label={t("IBAN")} />
          </Grid>
        )}
      </Grid>
    </CustomDialog>
  );
};
