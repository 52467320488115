import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { OptimaActionUpsertForm } from "../../../OptimaActions/forms/OptimaActionUpsert/OptimaActionUpsertForm";
import { OptimaActionListDocument, useOptimaActionCreateMutation } from "../../../OptimaActions/gql";
import { convertOptimaActionFormValuesToBackEndValues } from "../../../OptimaActions/others";
export const OptimaActionCreateContainer = props => {
    const { onChange } = props;
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const [createOptimaAction, { loading: isSubmitting }] = useOptimaActionCreateMutation({
        onCompleted: () => {
            succeeded(t("Optima Action created successfully"));
            onChange({
                type: "SUBMIT_SUCCESS",
            });
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
        refetchQueries: [OptimaActionListDocument],
    });
    const onOptimaActionUpsertFormChange = (event) => {
        if (event.type === "SUBMIT") {
            const values = convertOptimaActionFormValuesToBackEndValues(event.payload.values);
            createOptimaAction({
                variables: {
                    input: values,
                },
            });
        }
    };
    return _jsx(OptimaActionUpsertForm, { buttonLabel: t("Create"), isButtonDisabled: isSubmitting, onChange: onOptimaActionUpsertFormChange });
};
