import { AppTypes, Group } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PermissionGroupInformation } from "./components/PermissionGroupInfo.component";
import { PermissionGroupTransferListForm } from "./components/PermissionGroupTransferListForm.component";

export const PermissionsGroupForm: FC<{
  isEdit?: boolean;
  onDone;
  errors;
  groupInformation?: Group | any;
  isLoading?: boolean;
  appType: AppTypes;
  isSubmitting?: boolean;
}> = ({ isEdit, onDone, errors, groupInformation, isLoading, appType, isSubmitting }) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const methods = useForm<FormData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = data => {
    const addPermissions = data?.rightChecked?.map(item => item.code) || [];
    const removePermissions = groupInformation?.permissions
      ?.filter(PermissionsItem => !addPermissions?.includes(PermissionsItem?.code))
      ?.map(i => i?.code);
    const request = {
      name: data?.name,
      addPermissions: addPermissions || [],
      removePermissions: removePermissions?.length == 0 ? undefined : removePermissions,
      groupConfiguration: {
        groupType: appType,
      },
    };

    onDone(request);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const doYouHaveData = isEdit ? groupInformation != undefined : true;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={isLoading}
              hasCancel
              hasFormButton
              formButtonTitle={isEdit ? t("Save") : t("Create")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <PermissionGroupInformation
                errors={errors}
                doYouHaveData={doYouHaveData}
                isLoading={isLoading}
                defaultValues={groupInformation}
              />
            </Grid>
            <PermissionGroupTransferListForm errors={errors} defaultValues={groupInformation} />
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
PermissionsGroupForm.defaultProps = {
  isEdit: false,
  groupInformation: undefined,
  isLoading: false,
};
