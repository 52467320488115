import { startCase, toLower } from "lodash-es";

export const toCamelCase = (str: string) => {
  return (str || "")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const toPascalCase = (str: string) => {
  return (str || "")
    .replace("_", " ")
    .replace(/([A-Z])/g, " $1")
    .replace(/(\w)(\w*)/g, (g0, g1, g2) => {
      return g1.toUpperCase() + g2.toLowerCase();
    });
};

export const capitalize = (arrayStr: string[]) => {
  const result = arrayStr.map(str => str?.charAt(0)?.toUpperCase() + str?.slice(1));
  return result.join(" ");
};

export const toSentenceCase = (str: string) => {
  return (str || "")
    .split(/[_\-\s]/g)
    .map(s => s.toLowerCase())
    .map(toPascalCase)
    .join(" ");
};

export const addSpacesBetweenWords = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
};
export const convertToTitleCase = (inputString: string) => {
  const lowerCaseString = toLower(inputString);
  return startCase(lowerCaseString);
};
