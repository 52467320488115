import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const departmentAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const DepartmentAutocompleteSchemas = {
    departmentAutocomplete,
    departmentAutocompleteOptional: departmentAutocomplete.nullable().optional(),
};
