import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";

export const BranchCard: FC<{ loading; branch }> = ({ loading, branch }) => {
  const { t } = useTranslation("admin");

  return (
    <FormCard loading={loading} doYouHaveData={true} title={t("Branch")} sx={{ marginTop: "15px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField disabled fullWidth size='small' placeholder={t("Name")} label={t("Name")} name='name' value={branch?.name} />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            size='small'
            fullWidth
            name='healthLicense'
            value={branch?.healthLicense}
            placeholder={t("Health License")}
            label={t("Health License")}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            disabled
            size='small'
            fullWidth
            name='city'
            value={pickLocalizedValue(branch?.address?.city?.name, branch?.address.city?.nameAr)}
            placeholder={t("City")}
            label={t("City")}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            disabled
            size='small'
            fullWidth
            name='description'
            multiline
            maxRows={4}
            value={branch?.description}
            placeholder={t("Description")}
            label={t("Description")}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
