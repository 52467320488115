import { AccountError, User } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { AppointmentInformation } from "./AppointmentInformation.component";

export const DoctorAppointmentInformation: FC<{ errors: AccountError[]; defaultValues?: User | undefined }> = ({
  errors,
  defaultValues,
}) => {
  const { t } = useTranslation("admin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);
  const appointmentSlotTimeOptions = [15, 20, 30, 45];
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            name: "doctorInfo.appointmentSlotTimePeriod",
            control: control,
            rules: { required: true },
            defaultValue: defaultValues?.doctor?.appointmentSlotTimePeriod,
          }}
          TextFieldProps={{
            error: Boolean(combinedErrors?.doctorInfo?.appointmentSlotTimePeriod),
            helperText: combinedErrors?.doctorInfo?.appointmentSlotTimePeriod && t("Required"),
            label: t("Appointment Slot Time Period"),
          }}
          getOptionLabel={o => o?.name || o}
          options={appointmentSlotTimeOptions || []}
        />
      </Grid>
      <AppointmentInformation defaultValues={defaultValues} errors={errors} />
    </Grid>
  );
};
