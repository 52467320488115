/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KeyCloakPermissionsQueryVariables = Types.Exact<{
  appType: Types.AppTypes;
}>;


export type KeyCloakPermissionsQuery = { __typename?: 'Query', keyCloakPermissions?: Array<{ __typename?: 'Permission', id: string, name: string, code?: Types.PermissionEnum | null, keyCloakPermission?: { __typename?: 'KeyCloakPermission', id: string, isStaff?: boolean | null, isVendor?: boolean | null, isClient?: boolean | null, keycloakRoleId?: string | null } | null } | null> | null };


export const KeyCloakPermissionsDocument = gql`
    query keyCloakPermissions($appType: AppTypes!) {
  keyCloakPermissions(appType: $appType) {
    id
    name
    code
    keyCloakPermission {
      id
      isStaff
      isVendor
      isClient
      keycloakRoleId
    }
  }
}
    `;

/**
 * __useKeyCloakPermissionsQuery__
 *
 * To run a query within a React component, call `useKeyCloakPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyCloakPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyCloakPermissionsQuery({
 *   variables: {
 *      appType: // value for 'appType'
 *   },
 * });
 */
export function useKeyCloakPermissionsQuery(baseOptions: Apollo.QueryHookOptions<KeyCloakPermissionsQuery, KeyCloakPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeyCloakPermissionsQuery, KeyCloakPermissionsQueryVariables>(KeyCloakPermissionsDocument, options);
      }
export function useKeyCloakPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeyCloakPermissionsQuery, KeyCloakPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeyCloakPermissionsQuery, KeyCloakPermissionsQueryVariables>(KeyCloakPermissionsDocument, options);
        }
export type KeyCloakPermissionsQueryHookResult = ReturnType<typeof useKeyCloakPermissionsQuery>;
export type KeyCloakPermissionsLazyQueryHookResult = ReturnType<typeof useKeyCloakPermissionsLazyQuery>;
export type KeyCloakPermissionsQueryResult = Apollo.QueryResult<KeyCloakPermissionsQuery, KeyCloakPermissionsQueryVariables>;