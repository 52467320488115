export const callbackRequestsRoute = "/callback-requests";

export const callbackRequestsPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${callbackRequestsRoute}${this.route}`;
    },
  },
};
