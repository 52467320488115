import React, { CSSProperties, FC, useEffect, useRef } from "react";
import { ECharts, EChartsOption, getInstanceByDom, init, SetOptionOpts } from "echarts";

export type EChartOption = EChartsOption;

type EChartProps = {
  option: EChartOption;
  settings?: SetOptionOpts;
  height?: number;
  isLoading?: boolean;
  styleChart?: CSSProperties;
};

export const EChart: FC<EChartProps> = props => {
  const { option, settings, height = 200, isLoading, styleChart } = props;

  const chartRef = useRef<HTMLDivElement>(null);

  const defaultGrid = {
    top: "5%",
    left: "2%",
    right: "2%",
    bottom: "10%",
    containLabel: true,
  };

  useEffect(() => {
    let chart: ECharts | undefined;

    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    const resizeChart = () => {
      chart?.resize();
    };

    window.addEventListener("resize", resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);

      chart?.setOption({ ...option, grid: { ...defaultGrid, ...option.grid } }, settings);
    }
  }, [option, settings]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);

      isLoading ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [isLoading]);

  // eslint-disable-next-line react/forbid-dom-props
  return <div ref={chartRef} style={{ width: "100%", height, ...styleChart }} />;
};
