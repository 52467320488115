import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const DragAndDropImageIcon: FC<IconProps> = ({ width = 47.961, height = 42.822, viewBox = "0 0 47.961 42.822", ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon color={"inherit"} width={width} height={height} viewBox={viewBox} {...props}>
      <path
        d={
          "M7.71,4A3.709,3.709,0,0,0,4,7.71V9.565a1.855,1.855,0,1,0,3.71,0V7.71H9.565a1.855,1.855,0,1,0,0-3.71Zm9.276,0a1.855,1.855,0,1,0,0,3.71H20.7A1.855,1.855,0,0,0,20.7,4ZM28.116,4a1.855,1.855,0,1,0,0,3.71h3.71a1.855,1.855,0,0,0,0-3.71ZM39.247,4a1.855,1.855,0,1,0,0,3.71H41.1V9.565a1.855,1.855,0,0,0,3.71,0V7.71A3.709,3.709,0,0,0,41.1,4ZM5.855,15.131A1.856,1.856,0,0,0,4,16.986V20.7a1.855,1.855,0,0,0,3.71,0v-3.71A1.856,1.856,0,0,0,5.855,15.131Zm37.1,0A1.856,1.856,0,0,0,41.1,16.986V20.7a1.855,1.855,0,0,0,3.71,0V16.986A1.856,1.856,0,0,0,42.957,15.131Zm-17.406,7.42A1.848,1.848,0,0,0,23.9,23.577l-.319.634a3.714,3.714,0,0,1-3.319,2.051H16.986a1.856,1.856,0,0,0-1.855,1.855V42.957a1.856,1.856,0,0,0,1.855,1.855H42.957a1.856,1.856,0,0,0,1.855-1.855V28.116a1.856,1.856,0,0,0-1.855-1.855H39.686a3.709,3.709,0,0,1-3.319-2.051l-.319-.634a1.851,1.851,0,0,0-1.656-1.025Zm-19.7,3.71A1.856,1.856,0,0,0,4,28.116v3.71a1.855,1.855,0,0,0,3.71,0v-3.71A1.856,1.856,0,0,0,5.855,26.261Zm24.116,1.855a7.42,7.42,0,1,1-7.42,7.42A7.421,7.421,0,0,1,29.972,28.116Zm0,3.71a3.71,3.71,0,1,0,3.71,3.71A3.71,3.71,0,0,0,29.972,31.827ZM5.855,37.392A1.856,1.856,0,0,0,4,39.247V41.1a3.709,3.709,0,0,0,3.71,3.71H9.565a1.855,1.855,0,0,0,0-3.71H7.71V39.247A1.856,1.856,0,0,0,5.855,37.392Z"
        }
        transform='translate(-2 -5)'
        fill={palette.stale.main}
      />
    </SvgIcon>
  );
};
