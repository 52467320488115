import { combineErrors, getPositiveNumber, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, HintComponent, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "../types";

export const CallPoints: FC<IProgramProps> = ({ errors, defaultValues, readOnly }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("Normal Demand")}
          error={Boolean(formErrors.pointsDuringNormalDemand?.message)}
          helperText={t(formErrors.pointsDuringNormalDemand?.message)}
          {...register("pointsDuringNormalDemand", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValues?.pointsDuringNormalDemand || 10}
          InputProps={{
            endAdornment: <HintComponent title={t("Total number of points gained per call during normal demand times")} />,
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          fullWidth
          type='number'
          size='small'
          label={t("Urgent Demand")}
          error={Boolean(formErrors.pointsDuringUrgentDemand?.message)}
          helperText={t(formErrors.pointsDuringUrgentDemand?.message)}
          {...register("pointsDuringUrgentDemand", {
            required: required(t),
            min: getPositiveNumber(t, 0),
          })}
          defaultValue={defaultValues?.pointsDuringUrgentDemand || 15}
          InputProps={{
            endAdornment: <HintComponent title={t("Total number of points gained per call during urgent (High) demand times")} />,
            readOnly: readOnly,
          }}
        />
      </Grid>
    </Grid>
  );
};
