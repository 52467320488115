import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import arrayMove from "array-move";
import React, { FC } from "react";
import { List } from "../../../../base/mui";
import { useTablePreferencesContext } from "../../TablePreferencesDialog.hooks";
import { SortableItem } from "../SortableItem";

export const SortableList: FC = () => {
  const { columns, setColumns } = useTablePreferencesContext();

  const handleDropItem = (result: DropResult) => {
    setColumns?.(arrayMove(columns, result.source.index, result.destination?.index!));
  };

  const handleChangeVisibility = (key: string) => {
    const changedColumns = columns?.map(item => {
      return item?.key === key ? { ...item, isHidden: !item?.isHidden } : item;
    });
    setColumns?.(changedColumns);
  };

  return (
    <DragDropContext onDragEnd={handleDropItem}>
      <List>
        <Droppable droppableId='droppable'>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!columns?.length &&
                columns?.map(({ key, header, isHidden }, index) => (
                  <SortableItem
                    key={key}
                    id={key}
                    header={header}
                    itemIndex={index}
                    isHidden={isHidden}
                    onChangeVisibility={handleChangeVisibility}
                  />
                ))}
            </div>
          )}
        </Droppable>
      </List>
    </DragDropContext>
  );
};
