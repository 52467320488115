import { OrderDirection, UserSortField } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { ProviderUsersQuery } from "gql/queries";
import { ellipseName } from "utils";
import { ActiveToggleButton } from "../components/ActiveToggleButton.component";

export type UsersManagementColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<ProviderUsersQuery["vendor"], "users">>[];

export const staffUsersColumns = (t: TFunction): UsersManagementColumnsTypes => [
  {
    key: "FirstName",
    header: t("First Name", { ns: "admin" }),
    accessor: ({ firstName }) => <Typography>{ellipseName(String(firstName), 30)}</Typography>,
    isSortable: true,
    sortDirection: OrderDirection.Desc,
    hideFromSettings: true,
    disableToggleVisibility: true,
    sortColumnEnum: UserSortField.FirstName,
  },
  {
    key: "LastName",
    header: t("Last Name", { ns: "admin" }),
    accessor: ({ lastName }) => <Typography>{ellipseName(String(lastName), 30)}</Typography>,
    sortDirection: OrderDirection.Desc,
    sortColumnEnum: UserSortField.LastName,
    isSortable: true,
  },
  {
    key: "Email",
    header: t("Email", { ns: "admin" }),
    accessor: "email",
    isSortable: true,
    sortDirection: OrderDirection.Desc,
    sortColumnEnum: UserSortField.Email,
  },
  {
    key: "Mobile",
    header: t("Mobile", { ns: "admin" }),
    accessor: "mobile",
  },
  {
    key: "isActive",
    header: t("Is Active", { ns: "admin" }),
    accessor: row => <ActiveToggleButton id={row?.id} isActive={row?.isActive} />,
  },
];
