import { pickLocalizedValue } from "@toolkit/i18n";
import React, { FC } from "react";
import { DialogTitle, Divider, IconButton, Typography } from "../../../base/mui";
import { ModalCloseIcon } from "../../../icons";
import { useCustomMuiDialogStyle } from "./CustomDialog.style";
import { CustomMuiDialogTitleProps } from "./CustomMuiDialog.types";

export const CustomMuiDialogTitle: FC<CustomMuiDialogTitleProps> = props => {
  const { onClose: handleClose, title, modalCloseIcon, children, hasCloseButton = true, ...rest } = props;

  const { classes } = useCustomMuiDialogStyle();

  return (
    <React.Fragment>
      <DialogTitle className={classes.header} id='scroll-dialog-title' {...rest}>
        {title && <Typography className={pickLocalizedValue(classes.title, `${classes.title} ${classes.arabicTitle}`)}>{title}</Typography>}

        {children}

        {hasCloseButton && (
          <IconButton onClick={handleClose} sx={{ height: "30px", width: "30px" }}>
            {modalCloseIcon ? (
              React.cloneElement(modalCloseIcon, { sx: { width: "unset", height: "unset" } })
            ) : (
              <ModalCloseIcon sx={{ width: "unset", height: "unset" }} />
            )}
          </IconButton>
        )}
      </DialogTitle>

      <Divider className={classes.divider} />
    </React.Fragment>
  );
};
