import { useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { first, last } from "lodash-es";
import { useEffect, useState } from "react";
import { domainsConfig } from "../../../../domainsConfig";
import { getCodeSystemProcessingStatusColor, getCodeSystemProcessingStatusText } from "../../shared/utils";
import { formatDate } from "./utils";
export const useManagedListCardHook = ({ data }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation("domains");
    const { activeCodeSystem, lastCodeSystem, codeSystemDefinition, iconUrl } = data;
    const { id, codeSystemHistory } = activeCodeSystem || {};
    const { code, display, editorUserId, reviewerUserId } = codeSystemDefinition || {};
    const lastCadeSystemProcessingStatus = lastCodeSystem === null || lastCodeSystem === void 0 ? void 0 : lastCodeSystem.processingStatus;
    const uploadDate = formatDate((_a = first(codeSystemHistory)) === null || _a === void 0 ? void 0 : _a.updateDate);
    const updateDate = formatDate((_b = last(codeSystemHistory)) === null || _b === void 0 ? void 0 : _b.updateDate);
    const creator = (_d = (_c = last(lastCodeSystem === null || lastCodeSystem === void 0 ? void 0 : lastCodeSystem.codeSystemHistory)) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.firstName;
    const [svgIcon, setSvgIcon] = useState("");
    useEffect(() => {
        const baseURL = domainsConfig.get("serverUrl");
        const url = baseURL + "/" + iconUrl;
        fetch(url)
            .then(res => res.text())
            .then(text => setSvgIcon(text));
    }, []);
    const theme = useTheme();
    const statusColor = getCodeSystemProcessingStatusColor(lastCadeSystemProcessingStatus, theme.palette);
    const statusText = getCodeSystemProcessingStatusText(t, lastCadeSystemProcessingStatus);
    return {
        code,
        creator,
        display,
        editorUserId,
        id,
        lastCadeSystemProcessingStatus,
        lastCodeSystem,
        reviewerUserId,
        statusColor,
        statusText,
        svgIcon,
        updateDate,
        uploadDate,
    };
};
