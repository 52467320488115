import { Components, Theme } from "@mui/material/styles";

const fontFamiliesTypes: Array<keyof typeof fontWeightMap> = ["light", "regular", "medium", "semiBold", "bold", "extraBold", "black"];
const fontSizes: Array<keyof Theme["mixins"]["fonts"]["fontSize"]> = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"];

const fontWeightMap = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};
export const MuiTypographyOverride: Components<Theme>["MuiTypography"] = {
  defaultProps: {
    fontWeight: "normal",
    fontFamily: ({ mixins }) => mixins.fonts.fontFamily,
  },
  variants: fontFamiliesTypes
    .map(type => {
      return fontSizes?.map(size => {
        return ["", "secondary", "white"].map(color => {
          return {
            props: { variant: `${type}-${size}${color ? "-" + color : ""}` as any },
            style: ({ theme }) => ({
              fontWeight: fontWeightMap[type],
              fontFamily: theme.mixins.fonts.fontFamily!,
              fontSize: theme.mixins.fonts.fontSize[size as keyof typeof theme.mixins.fonts.fontSize],
              color: {
                "": theme.palette.text.primary,
                secondary: theme.palette.text.secondary,
                white: theme.palette.common.white,
              }[color],
            }),
          } as NonNullable<NonNullable<Components<Theme>["MuiTypography"]>["variants"]>[number];
        });
      });
    })
    .flat(2),
};
