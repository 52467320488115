/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { CityFragmentFragmentDoc } from '../../fragments/__generated__/cityFragment';
import { CityErrorsFragmentDoc } from '../../fragments/__generated__/cityErrorsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityCreateMutationVariables = Types.Exact<{
  input: Types.CityInput;
}>;


export type CityCreateMutation = { __typename?: 'Mutation', cityCreate?: { __typename?: 'CityCreate', city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null, created: any } | null, cityErrors: Array<{ __typename?: 'BlockError', code: Types.BlockErrorCode, field?: string | null, message?: string | null }> } | null };


export const CityCreateDocument = gql`
    mutation cityCreate($input: CityInput!) {
  cityCreate(input: $input) {
    city {
      ...CityFragment
    }
    cityErrors {
      ...CityErrors
    }
  }
}
    ${CityFragmentFragmentDoc}
${CityErrorsFragmentDoc}`;
export type CityCreateMutationFn = Apollo.MutationFunction<CityCreateMutation, CityCreateMutationVariables>;

/**
 * __useCityCreateMutation__
 *
 * To run a mutation, you first call `useCityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityCreateMutation, { data, loading, error }] = useCityCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCityCreateMutation(baseOptions?: Apollo.MutationHookOptions<CityCreateMutation, CityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CityCreateMutation, CityCreateMutationVariables>(CityCreateDocument, options);
      }
export type CityCreateMutationHookResult = ReturnType<typeof useCityCreateMutation>;
export type CityCreateMutationResult = Apollo.MutationResult<CityCreateMutation>;
export type CityCreateMutationOptions = Apollo.BaseMutationOptions<CityCreateMutation, CityCreateMutationVariables>;