import { useReactiveVar } from "@apollo/client";
import { Card, makeStyles } from "@toolkit/ui";
import { PropsWithChildren } from "react";
import { openVar as isDrawerOpenVar } from "shared/reactiveVariables";

export const useSurveyQuestionsStyle = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  footer: {
    borderRadius: 0,
    position: "fixed",
    bottom: 0,
    height: 8 * 8,
    width: isOpen ? "calc( 100% - 240px) " : "100%",
    zIndex: 2,
  },
}));

// eslint-disable-next-line react/prop-types
export const FormFooterWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const isDrawerOpen = useReactiveVar(isDrawerOpenVar);
  const { classes } = useSurveyQuestionsStyle({ isOpen: isDrawerOpen });
  return <Card className={classes.footer}>{children}</Card>;
};
export default FormFooterWrapper;
