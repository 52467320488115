/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemConceptsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  codeSystemId?: Types.InputMaybe<Types.Scalars['ID']>;
  searchParameters: Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>;
  showOnlyVisibleProperties?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetCodeSystemConceptsQuery = { __typename?: 'Query', getCodeSystemConcepts?: { __typename?: 'CodeSystemConceptConnection', edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', code?: string | null, fieldType?: Types.Type | null, valueDate?: any | null, valueFloat?: number | null, valueString?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const GetCodeSystemConceptsDocument = gql`
    query getCodeSystemConcepts($first: Int, $after: String, $last: Int, $before: String, $codeSystemId: ID, $searchParameters: [SearchParameter]!, $showOnlyVisibleProperties: Boolean) {
  getCodeSystemConcepts(
    first: $first
    after: $after
    last: $last
    before: $before
    codeSystemId: $codeSystemId
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
  ) {
    edges {
      node {
        id
        code
        display
        properties {
          code
          fieldType
          valueDate
          valueFloat
          valueString
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetCodeSystemConceptsQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      codeSystemId: // value for 'codeSystemId'
 *      searchParameters: // value for 'searchParameters'
 *      showOnlyVisibleProperties: // value for 'showOnlyVisibleProperties'
 *   },
 * });
 */
export function useGetCodeSystemConceptsQuery(baseOptions: Apollo.QueryHookOptions<GetCodeSystemConceptsQuery, GetCodeSystemConceptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemConceptsQuery, GetCodeSystemConceptsQueryVariables>(GetCodeSystemConceptsDocument, options);
      }
export function useGetCodeSystemConceptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemConceptsQuery, GetCodeSystemConceptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemConceptsQuery, GetCodeSystemConceptsQueryVariables>(GetCodeSystemConceptsDocument, options);
        }
export type GetCodeSystemConceptsQueryHookResult = ReturnType<typeof useGetCodeSystemConceptsQuery>;
export type GetCodeSystemConceptsLazyQueryHookResult = ReturnType<typeof useGetCodeSystemConceptsLazyQuery>;
export type GetCodeSystemConceptsQueryResult = Apollo.QueryResult<GetCodeSystemConceptsQuery, GetCodeSystemConceptsQueryVariables>;