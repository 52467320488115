import { AppTypes } from "@health/queries/types";
import { FC } from "react";
import { PermissionsGroupForm } from "../form";
import { usePermissionGroupEditHook } from "./PermissionGroupEdit.hook";

export const PermissionGroupEditPage: FC<{ appType: AppTypes }> = ({ appType }) => {
  const { errors, handleSubmit, loading, permission, isSubmitting } = usePermissionGroupEditHook();
  return (
    <PermissionsGroupForm
      appType={appType}
      isEdit
      isLoading={loading}
      groupInformation={permission}
      onDone={handleSubmit}
      errors={errors}
      isSubmitting={isSubmitting}
    />
  );
};
