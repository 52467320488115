import { HealthProgramInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import {
  healthProgramListBreadcrumb,
  healthProgramNewBreadcrumb,
  healthProgramTitleBreadcrumb,
} from "pages/HealthProgram/constants/health-program-breadcrumbs";
import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import HealthProgramForm from "../form/components/HealthProgramForm";
import { useHealthProgramAdd } from "./HealthProgramsAdd.hook";

interface HealthProgramsFormNewPageProps {}

export const HealthProgramsFormNewPage: FC<HealthProgramsFormNewPageProps> = () => {
  const { t } = useTranslation("admin");
  const methods = useForm<HealthProgramInput>({
    mode: "onChange",
  });
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, onSubmit, handleCancel, isSubmitting } = useHealthProgramAdd();
  const { handleSubmit } = methods;

  useEffect(() => {
    setBreadCrumb({
      title: healthProgramTitleBreadcrumb(t),
      values: [healthProgramListBreadcrumb(t), healthProgramNewBreadcrumb(t)],
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={t("Create")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <HealthProgramForm errors={errors} />
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
