/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { SiteSettingsFragmentFragmentDoc } from '../../fragments/__generated__/siteSettingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramsSiteSettingsUpdateMutationVariables = Types.Exact<{
  input: Types.H_SiteSettingsInput;
}>;


export type HealthProgramsSiteSettingsUpdateMutation = { __typename?: 'Mutation', healthProgramsSiteSettingsUpdate?: { __typename?: 'SiteSettings', vatPercentage?: number | null, orderBaseDeliveryFee?: number | null, defaultCurrency: string, doctorVisitAcceptanceTimeoutSeconds?: number | null, healthProgramVisitWaitTimeBeforeCallSeconds?: number | null, messengerCallMinimumDurationSeconds?: number | null, appointmentReminderTimeSeconds?: number | null, prescriptionOrderPaymentTimeoutSeconds?: number | null, orderErxHubApprovalTimeoutAlert?: number | null, orderErxHubApprovalTimeout?: number | null, orderErxHubApprovalResubmitTimeout?: number | null, orderDispensingTimeoutAlert?: number | null, orderDispensingTimeout?: number | null, orderDeliveryTimeout?: number | null, orderAcceptanceTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, iosVersion?: number | null, androidVersion?: number | null, marketPlacePharmacyFirst?: boolean | null, vatPercentageForMedicalServices?: number | null, androidPackageName?: string | null, iosAppId?: string | null } | null };


export const HealthProgramsSiteSettingsUpdateDocument = gql`
    mutation healthProgramsSiteSettingsUpdate($input: H_SiteSettingsInput!) {
  healthProgramsSiteSettingsUpdate(input: $input) {
    ...siteSettingsFragment
  }
}
    ${SiteSettingsFragmentFragmentDoc}`;
export type HealthProgramsSiteSettingsUpdateMutationFn = Apollo.MutationFunction<HealthProgramsSiteSettingsUpdateMutation, HealthProgramsSiteSettingsUpdateMutationVariables>;

/**
 * __useHealthProgramsSiteSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramsSiteSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramsSiteSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramsSiteSettingsUpdateMutation, { data, loading, error }] = useHealthProgramsSiteSettingsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramsSiteSettingsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramsSiteSettingsUpdateMutation, HealthProgramsSiteSettingsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramsSiteSettingsUpdateMutation, HealthProgramsSiteSettingsUpdateMutationVariables>(HealthProgramsSiteSettingsUpdateDocument, options);
      }
export type HealthProgramsSiteSettingsUpdateMutationHookResult = ReturnType<typeof useHealthProgramsSiteSettingsUpdateMutation>;
export type HealthProgramsSiteSettingsUpdateMutationResult = Apollo.MutationResult<HealthProgramsSiteSettingsUpdateMutation>;
export type HealthProgramsSiteSettingsUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramsSiteSettingsUpdateMutation, HealthProgramsSiteSettingsUpdateMutationVariables>;