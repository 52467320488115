import { getAutocompleteFilter } from "@toolkit/ui";
import { useMarketplaceHealthPackageCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getMarketplaceHealthPackageCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceHealthPackageCategories",
        query: useMarketplaceHealthPackageCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
