import React, { FC } from "react";
import { DialogContent } from "../../../base/mui";
import { CustomMuiDialogContentProps } from "./CustomMuiDialog.types";

export const CustomMuiDialogContent: FC<CustomMuiDialogContentProps> = props => {
  const { children, ...rest } = props;
  return (
    <DialogContent
      sx={{
        padding: "15px",
      }}
      {...rest}
    >
      {children}
    </DialogContent>
  );
};
