import { CustomUseFormProps, CustomUseFormReturn } from "../types/customUseForm";
import { get } from "../utils";
import { getZodErrorMapMessages } from "../utils/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm as RHFUseForm, FieldValues, Resolver, useFormContext, Path } from "react-hook-form";

export const useCustomFormContext = <TFieldValues extends FieldValues, TContext = any>(): CustomUseFormReturn<TFieldValues, TContext> => {
  const formContext = useFormContext() as CustomUseFormReturn<TFieldValues, TContext>;

  return {
    ...formContext,
  };
};

export const useCustomForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props: CustomUseFormProps<TFieldValues, TContext>
): CustomUseFormReturn<TFieldValues, TContext> => {
  const { schema, ...rest } = props;

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const errorMap = getZodErrorMapMessages();

  const form = RHFUseForm<TFieldValues, TContext>({
    mode: "all",
    resolver: zodResolver(schema, { errorMap }) as unknown as Resolver<TFieldValues, TContext>,
    ...rest,
  });

  const setValues = useCallback(
    (values: TFieldValues) => {
      Object.keys(values).forEach(key => form.setValue(key as Path<TFieldValues>, get(values, key)));
    },
    [form?.setValue]
  );

  return { ...form, setValues, isFormDisabled, setFormDisabled: setIsFormDisabled };
};
