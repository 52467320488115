import { i18n } from "@toolkit/i18n";
import { IFilter } from "@toolkit/ui";

export const getActiveOptions = () => [
  {
    get name() {
      return i18n.t("active", { ns: "admin" });
    },
    value: true,
  },
  {
    get name() {
      return i18n.t("In active", { ns: "admin" });
    },
    value: false,
  },
];
export const activeInActiveFilter: IFilter = {
  type: "autocomplete",
  name: "isActive",
  options: getActiveOptions(),
  getOptionLabel: option => option?.name,
  getValueForBackend: option => option?.value,
};

export const getActiveInActiveFilter = () => {
  return {
    options: [
      { name: i18n.t("Active", { ns: "admin" }), value: true },
      { name: i18n.t("In active", { ns: "admin" }), value: false },
    ],
    ...activeInActiveFilter,
  };
};
