import { i18n } from "@toolkit/i18n";

export const branchUsersTitleBreadcrumb = () => {
  return i18n.t("Branch Users", { ns: "admin" });
};

export const branchUserListBreadcrumb = () => {
  return {
    id: "branch-user-list",
    name: i18n.t("Branch Users", { ns: "admin" }),
  };
};

export const branchUserFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "branch-user-field",
    name: fieldName,
  };
};
