import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { ISiteSetting } from "../components/types";
import { HealthProgramSettingForm } from "./HealthProgramSettingForm";

export const HealthProgramSetting: FC<ISiteSetting> = ({ loading, errors, siteSettings }) => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormCard title={t("Health Program Setting")} loading={loading} doYouHaveData={Boolean(siteSettings)}>
          <HealthProgramSettingForm errors={errors} defaultValue={siteSettings} />
        </FormCard>
      </Grid>
    </Grid>
  );
};
