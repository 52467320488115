import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Dot, Grid, Tooltip, Typography } from "@toolkit/ui";
import { memo } from "react";
import { AMLAssignReviewerDialog } from "../../shared/AMLAssignReviewerDialog";
import { useManagedListCardStyle } from "./ManagedListCard.style";
import { useManagedListCardHook } from "./useManagedListCardHook";
import { getCreatorName } from "./utils";
const ManagedListCard = props => {
    const { data, handleDetailsClick } = props;
    const { t } = useTranslation("domains");
    const { id, code, creator, display, svgIcon, statusText, updateDate, uploadDate, statusColor, editorUserId, lastCodeSystem, reviewerUserId, lastCadeSystemProcessingStatus, } = useManagedListCardHook({ data });
    const { classes, theme } = useManagedListCardStyle();
    return (_jsx(_Fragment, { children: _jsx(Card, { elevation: 0, children: _jsxs(Box, { minHeight: 330, padding: 1, paddingBottom: 0, children: [_jsx(Grid, { container: true, alignItems: 'center', children: lastCadeSystemProcessingStatus ? (_jsxs(_Fragment, { children: [lastCodeSystem === null ? undefined : _jsx(Dot, { background: statusColor }), _jsx(Typography, { paddingLeft: 1, color: statusColor, fontSize: theme.mixins.fonts.fontSize.xs, children: getCreatorName(statusText, creator, t) })] })) : (_jsx(Box, { height: '18px' })) }), _jsxs(Grid, { container: true, flexDirection: "column", alignItems: 'center', paddingTop: 3, children: [_jsx("div", { dangerouslySetInnerHTML: { __html: svgIcon.replace(/height=*"/, 'height="70px"') }, className: classes.imgHeight }), _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.lg, marginTop: 1, marginBottom: 1, children: display }), _jsx(Grid, { container: true, justifyContent: "center", spacing: 2, children: _jsx(Grid, { item: true, container: true, flex: 1, display: "flex", direction: 'column', rowSpacing: 0.5, children: [
                                        { id: "Upload Date", title: t("Upload Date"), value: t(uploadDate) || "-" },
                                        { id: "Last Update", title: t("Last Update"), value: t(updateDate) || "-" },
                                        { id: "Assigned Editor", title: _jsx("b", { children: t("Assigned Editor") }), value: formatUserNameFull(editorUserId) },
                                        { id: "Assigned Reviewer", title: _jsx("b", { children: t("Assigned Reviewer") }), value: formatUserNameFull(reviewerUserId) },
                                    ].map(item => {
                                        return (_jsxs(Grid, { item: true, container: true, columnSpacing: 2, children: [_jsx(Grid, { item: true, xs: 6, sx: { textAlign: "end" }, children: _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.xs, children: item.title }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(Tooltip, { title: item.value, children: _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.xs, sx: { whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%!important", overflow: "hidden" }, children: item.value }) }) })] }, item.id));
                                    }) }) }), _jsxs(Grid, { container: true, justifyContent: "center", marginTop: 2, children: [_jsx(Button, { variant: 'contained', onClick: () => handleDetailsClick(code, id), children: t("Details") }), _jsx(AMLAssignReviewerDialog, { code: code, editorUser: editorUserId, reviewerUser: reviewerUserId, buttonProps: {
                                            variant: "contained",
                                            color: "success",
                                            children: editorUserId || reviewerUserId ? t("Reassign") : t("Assign"),
                                        } })] })] })] }) }) }));
};
export default memo(ManagedListCard);
