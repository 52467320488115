import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";

export const BrandInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"nameAr"} label={t("Arabic Name")} />
      </Grid>
    </Grid>
  );
};
