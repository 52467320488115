import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Dot, Grid, Tooltip, Typography } from "@toolkit/ui";
import { FC, memo } from "react";
import { getSurveyStatusColor, getSurveyStatusText } from "../../utils";
import { useSurveyCardStyle } from "./SurveyCard.style";
import { SurveyCardProps } from "./SurveyCard.types";
import { useSurveyCard } from "./useSurveyCard";

const SurveyCard: FC<SurveyCardProps> = ({ survey, onDetailsClick }) => {
  const { t } = useTranslation("admin");

  const { id, status, label, creatorName, endDateText, publishDateText, createdDateText, participantsCount } = useSurveyCard({ survey });
  const { classes, theme } = useSurveyCardStyle();

  const surveyStatusColor = getSurveyStatusColor(status, theme.palette);
  const surveyStatusText = getSurveyStatusText(status);

  return (
    <>
      <Card elevation={0}>
        <Box minHeight={330} padding={1} paddingBottom={0}>
          <Grid container alignItems='center'>
            {status ? (
              <>
                {status === null ? undefined : <Dot background={surveyStatusColor} />}
                <Typography paddingLeft={1} color={surveyStatusColor} fontSize={theme.mixins.fonts.fontSize.xs} textTransform='capitalize'>
                  {t(surveyStatusText)}
                </Typography>
              </>
            ) : (
              <Box height='18px'></Box>
            )}
          </Grid>
          <Grid container flexDirection={"column"} alignItems='center'>
            <div className={classes.participants}>
              <div>
                <Typography fontSize={30} fontWeight='bold'>
                  {participantsCount}
                </Typography>
                <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{t("Participants")}</Typography>
              </div>
            </div>

            <Tooltip title={label!}>
              <Typography
                fontSize={theme.mixins.fonts.fontSize.lg}
                marginTop={1}
                marginBottom={1}
                sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%!important", overflow: "hidden" }}
              >
                {label}
              </Typography>
            </Tooltip>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item container flex={1} display={"flex"} direction='column' rowSpacing={0.5}>
                {[
                  { id: "Created Date", title: t("Created Date"), value: t(createdDateText) || "-" },
                  { id: "Publish Date", title: t("Publish Date"), value: t(publishDateText) || "-" },
                  { id: "End Date", title: t("End Date"), value: t(endDateText) || "-" },
                  { id: "Creator", title: <b>{t("Creator")}</b>, value: creatorName },
                ].map(item => {
                  return (
                    <Grid key={item.id} item container columnSpacing={2}>
                      <Grid item xs={6} sx={{ textAlign: "end" }}>
                        <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip title={item.value}>
                          <Typography
                            fontSize={theme.mixins.fonts.fontSize.xs}
                            sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%!important", overflow: "hidden" }}
                          >
                            {item.value}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} marginTop={2}>
              <Button variant='contained' onClick={() => onDetailsClick(id!)}>
                {t("Details")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default memo(SurveyCard);
