import { VendorBranchTypeEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";

const types = Object.keys(VendorBranchTypeEnum).map(key => ({ value: VendorBranchTypeEnum[key], name: key }));
export const typeValue = value => types?.find(item => item?.value === value);

export const activeOptions = [
  { label: i18n.t("Active", { ns: "domains" }), value: true },
  { label: i18n.t("Not Active", { ns: "domains" }), value: false },
];

export const acceptsDeliveryOptions = [
  { label: i18n.t("Accepts Delivery", { ns: "domains" }), value: true },
  { label: i18n.t("Doesn't accept delivery", { ns: "domains" }), value: false },
];
