import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const MedlistUsersDocument = gql `
    query medlistUsers($filter: UserFilterInput, $before: String, $after: String, $sortBy: UserSortingInput, $first: Int, $last: Int) {
  medlistUsers(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        firstName
        lastName
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useMedlistUsersQuery__
 *
 * To run a query within a React component, call `useMedlistUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedlistUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedlistUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMedlistUsersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MedlistUsersDocument, options);
}
export function useMedlistUsersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MedlistUsersDocument, options);
}
