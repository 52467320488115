/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworksQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramNetworkSortingInput>;
}>;


export type HealthProgramNetworksQuery = { __typename?: 'Query', healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, isActive?: boolean | null, modified?: any | null, name?: string | null, categoryCode?: string | null, policyNumber?: string | null, providerCount?: number | null, createdByUser?: { __typename?: 'User', firstName?: string | null } | null, payer?: { __typename?: 'Payer', id: string, name: string } | null } }> } | null };


export const HealthProgramNetworksDocument = gql`
    query healthProgramNetworks($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramNetworkFilterInput, $sortBy: HealthProgramNetworkSortingInput) {
  healthProgramNetworks(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        isActive
        modified
        name
        categoryCode
        policyNumber
        providerCount
        createdByUser {
          firstName
        }
        payer {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworksQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramNetworksQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworksQuery, HealthProgramNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworksQuery, HealthProgramNetworksQueryVariables>(HealthProgramNetworksDocument, options);
      }
export function useHealthProgramNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworksQuery, HealthProgramNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworksQuery, HealthProgramNetworksQueryVariables>(HealthProgramNetworksDocument, options);
        }
export type HealthProgramNetworksQueryHookResult = ReturnType<typeof useHealthProgramNetworksQuery>;
export type HealthProgramNetworksLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworksLazyQuery>;
export type HealthProgramNetworksQueryResult = Apollo.QueryResult<HealthProgramNetworksQuery, HealthProgramNetworksQueryVariables>;