import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const PublishCodeSystemDocument = gql `
    mutation publishCodeSystem($codeSystemId: ID!) {
  publishCodeSystem(codeSystemId: $codeSystemId) {
    id
    errors {
      code
      errorType
      field
      message
    }
    display
    isActive
    version
  }
}
    `;
/**
 * __usePublishCodeSystemMutation__
 *
 * To run a mutation, you first call `usePublishCodeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCodeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCodeSystemMutation, { data, loading, error }] = usePublishCodeSystemMutation({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *   },
 * });
 */
export function usePublishCodeSystemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(PublishCodeSystemDocument, options);
}
