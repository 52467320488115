import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { Box, Button, Card, Divider, Typography } from "../../../base/mui";
import { FlowerIcon } from "../../../icons";
import { useCommonCardStyles } from "./ComingSoonCardStyle";
import { CommonCardProps } from "./comingSoonCardType";

const ComingSoonCard: FC<CommonCardProps> = ({ handleClick }) => {
  const { t } = useTranslation();
  const { classes } = useCommonCardStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.header}>
          <div className={classes.container}>
            <Typography gutterBottom className={classes.headerIcon}>
              <FlowerIcon sx={{ width: "unset", height: "unset" }} />
            </Typography>
            <Box textAlign='center'>
              <Typography variant='body2' className={classes.gutterBottom} gutterBottom>
                <strong>{t("Coming Soon!")}</strong>
              </Typography>
              <Typography variant='body2' className={classes.subGutter}>
                {t("We will be with you very soon!")}
              </Typography>
              <Divider variant='middle' className={classes.divider} />
              <Typography variant='body2' className={classes.subGutter2}>
                {t("Enjoy with us with other services and marketplaces")}
              </Typography>
            </Box>
          </div>

          {handleClick && (
            <Button className={classes.button} onClick={handleClick}>
              {t("Back To Home")}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};
export default ComingSoonCard;
