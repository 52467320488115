import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ChatFlowRemoveContributorsDocument = gql `
    mutation ChatFlowRemoveContributors($chatFlowId: ID!, $contributors: [ID!]!) {
  chatFlowRemoveContributors(chatFlowId: $chatFlowId, contributors: $contributors) {
    id
    contributors {
      id
      fullName
    }
    updatedAt
  }
}
    `;
/**
 * __useChatFlowRemoveContributorsMutation__
 *
 * To run a mutation, you first call `useChatFlowRemoveContributorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowRemoveContributorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowRemoveContributorsMutation, { data, loading, error }] = useChatFlowRemoveContributorsMutation({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *      contributors: // value for 'contributors'
 *   },
 * });
 */
export function useChatFlowRemoveContributorsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChatFlowRemoveContributorsDocument, options);
}
