import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ConsumerViewPreferenceDocument = gql `
    query consumerViewPreference($view: String!) {
  consumerViewPreference(view: $view) {
    id
    user {
      id
    }
    view
    data
  }
}
    `;
/**
 * __useConsumerViewPreferenceQuery__
 *
 * To run a query within a React component, call `useConsumerViewPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerViewPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerViewPreferenceQuery({
 *   variables: {
 *      view: // value for 'view'
 *   },
 * });
 */
export function useConsumerViewPreferenceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ConsumerViewPreferenceDocument, options);
}
export function useConsumerViewPreferenceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ConsumerViewPreferenceDocument, options);
}
