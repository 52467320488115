import { planPeriodOptions, planPeriodOptionsMap } from "@health/enum-options";
import { formGirdBreakPoints, getMaxLengthValidation, getMinLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { SubscriptionInfoProps } from "./SubscriptionInfo.type";
import { SubscriptionValidFromTillForm } from "./SubscriptionValidFromTill.component";

export const SubscriptionInfo: FC<SubscriptionInfoProps> = ({ formData, formErrors }) => {
  const { t } = useTranslation("admin");
  const { register, control } = useFormContext();

  return (
    <React.Fragment>
      <Grid item container xs={12} spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Plan Name ")}
            placeholder={t("Plan Name")}
            fullWidth
            defaultValue={formData?.name}
            {...register("name", {
              required: getRequiredValidation(t, true),
              minLength: getMinLengthValidation(t, 1),
              maxLength: getMaxLengthValidation(t, 36),
            })}
            helperText={t(formErrors?.name?.message)}
            error={Boolean(formErrors.name?.message)}
          />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <AutocompleteController
            options={planPeriodOptions}
            getOptionLabel={o => o?.label}
            ControllerProps={{
              name: "period",
              control: control,
              defaultValue: formData?.period ? planPeriodOptionsMap[formData?.period]?.label : "",
            }}
            TextFieldProps={{
              placeholder: t("Period (Monthly, Daily, Annually)"),
              error: Boolean(formErrors.period?.message),
              helperText: formErrors.period?.message,
            }}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Fixed cost amount")}
            placeholder={t("Fixed cost amount")}
            fullWidth
            defaultValue={formData?.fixedCostAmount}
            error={Boolean(formErrors.fixedCostAmount?.message)}
            helperText={formErrors.fixedCostAmount?.message}
            {...register("fixedCostAmount", {
              required: getRequiredValidation(t, true),
              maxLength: getMaxLengthValidation(t, 12),
              pattern: {
                message: t("The value must be a number"),
                value: /^[0-9]+?\.?[0-9]*?$/,
              },
            })}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Fixed order percentage")}
            placeholder={t("Fixed order percentage")}
            fullWidth
            key={formData?.id + "Order Percentage"}
            error={Boolean(formErrors.fixedOrderPercentage?.message)}
            helperText={t(formErrors.fixedOrderPercentage?.message)}
            defaultValue={formData?.fixedOrderPercentage}
            InputProps={{
              endAdornment: "%",
            }}
            {...register("fixedOrderPercentage", {
              required: getRequiredValidation(t, true),
              max: { value: 100, message: t("Fixed order percentage is more than 100") },
              maxLength: getMaxLengthValidation(t, 12),
              pattern: {
                message: t("The value must be a number"),
                value: /^[0-9]+?\.?[0-9]*?$/,
              },
            })}
          />
        </Grid>
        <SubscriptionValidFromTillForm formData={formData} formErrors={formErrors} />
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            size='small'
            label={t("Description")}
            {...register("description", {
              required: getRequiredValidation(t, true),
              maxLength: getMaxLengthValidation(t, 300),
              minLength: getMinLengthValidation(t, 0),
            })}
            defaultValue={formData?.description}
            error={Boolean(formErrors?.description?.message)}
            helperText={t(formErrors?.description?.message)}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
