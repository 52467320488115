import { FC } from "react";
import { VendorForm } from "../components/Form/VendorForm";
import { useVendorAddHook } from "./VendorAdd.hook";

const VendorsFormNewPage: FC = () => {
  const { handleSubmit, errors, isCreateLoading } = useVendorAddHook();

  return <VendorForm vendorErrors={errors} onDone={handleSubmit} isSubmitting={isCreateLoading} />;
};

export default VendorsFormNewPage;
