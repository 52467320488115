/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCategoryQueryVariables = Types.Exact<{
  marketplaceProductCategoryId: Types.Scalars['ID'];
}>;


export type MarketplaceProductCategoryQuery = { __typename?: 'Query', marketplaceProductCategory?: { __typename?: 'MarketplaceProductCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceProductCategory', name?: string | null, nameAr?: string | null, id: string } | null } | null };


export const MarketplaceProductCategoryDocument = gql`
    query MarketplaceProductCategory($marketplaceProductCategoryId: ID!) {
  marketplaceProductCategory(id: $marketplaceProductCategoryId) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      name
      nameAr
      id
    }
  }
}
    `;

/**
 * __useMarketplaceProductCategoryQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductCategoryQuery({
 *   variables: {
 *      marketplaceProductCategoryId: // value for 'marketplaceProductCategoryId'
 *   },
 * });
 */
export function useMarketplaceProductCategoryQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceProductCategoryQuery, MarketplaceProductCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductCategoryQuery, MarketplaceProductCategoryQueryVariables>(MarketplaceProductCategoryDocument, options);
      }
export function useMarketplaceProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductCategoryQuery, MarketplaceProductCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductCategoryQuery, MarketplaceProductCategoryQueryVariables>(MarketplaceProductCategoryDocument, options);
        }
export type MarketplaceProductCategoryQueryHookResult = ReturnType<typeof useMarketplaceProductCategoryQuery>;
export type MarketplaceProductCategoryLazyQueryHookResult = ReturnType<typeof useMarketplaceProductCategoryLazyQuery>;
export type MarketplaceProductCategoryQueryResult = Apollo.QueryResult<MarketplaceProductCategoryQuery, MarketplaceProductCategoryQueryVariables>;