import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetMarketplaceOrdersDocument = gql `
    query getMarketplaceOrders($first: Int, $after: String, $before: String, $filter: MarketplaceOrderFilterInput, $last: Int, $sortBy: MarketplaceOrderSortingInput, $isAdmin: Boolean!) {
  marketplaceOrders(
    first: $first
    after: $after
    before: $before
    filter: $filter
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        status
        totalNetAmount
        user {
          nationalId
          fullName
          mobile
          email
          id
        }
        vendor @include(if: $isAdmin) {
          id
          name
          nameAr
        }
        createdDate
        type
      }
    }
  }
}
    `;
/**
 * __useGetMarketplaceOrdersQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetMarketplaceOrdersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetMarketplaceOrdersDocument, options);
}
export function useGetMarketplaceOrdersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetMarketplaceOrdersDocument, options);
}
