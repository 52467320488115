import { formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, ToggleButtonController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { VendorValueProps } from "../types";

export const GeneralInfoToggleButton: FC<VendorValueProps> = props => {
  const { defaultValues } = props;
  const { control } = useFormContext();
  const { t } = useTranslation("admin");

  return (
    <Grid container columnSpacing={4} item alignItems='center' sx={{ padding: "8px" }}>
      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController
          control={control}
          name='hasMultipleBranches'
          label={t("Multiple Branches")}
          defaultValue={defaultValues?.hasMultipleBranches ?? false}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController
          control={control}
          name='hasOwnDrivers'
          label={t("Has Own Drivers")}
          defaultValue={defaultValues?.hasOwnDrivers ?? false}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController name='isActive' control={control} label={t("Active")} defaultValue={defaultValues?.isActive ?? false} />
      </Grid>
      {!defaultValues && (
        <Grid item {...formGirdBreakPoints}>
          <ToggleButtonController control={control} name='acceptsDelivery' label={t("Accepts Delivery")} defaultValue={false} />
        </Grid>
      )}
      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController
          control={control}
          name='supportOutpatientJourney'
          label={t("Support Out Patient Delivery")}
          defaultValue={defaultValues?.supportOutpatientJourney ?? false}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController
          control={control}
          name='isIntegrated'
          label={t("Integrated")}
          defaultValue={defaultValues?.isIntegrated ?? false}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <ToggleButtonController
          control={control}
          name='enableVisitDetails'
          label={t("Visit Details")}
          defaultValue={defaultValues?.enableVisitDetails ?? false}
        />
      </Grid>
    </Grid>
  );
};
