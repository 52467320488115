import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { DecisionUpsertForm } from "../../../Decisions/forms/DecisionUpsert/DecisionUpsertForm";
import { useDecisionGetQuery, useDecisionUpdateMutation } from "../../../Decisions/gql";
import { convertDecisionFormValuesToBackEndValues } from "../../../Decisions/others/utils";
import { useNavigate, useParams } from "react-router-dom";
export const DecisionUpdateContainer = props => {
    const { category, healthParameter, healthParameterDecisionPlan } = props;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const { succeeded, failed } = useAddToast();
    const { id } = useParams();
    const { data, loading } = useDecisionGetQuery({
        variables: { id: id },
        skip: !id,
        fetchPolicy: "no-cache",
    });
    const [fetchDecisionUpdate, { loading: isSubmitting }] = useDecisionUpdateMutation({
        onCompleted: (mutationData) => {
            var _a;
            const mutationErrors = (_a = mutationData === null || mutationData === void 0 ? void 0 : mutationData.updateDecision) === null || _a === void 0 ? void 0 : _a.errors;
            if (mutationErrors === null || mutationErrors === void 0 ? void 0 : mutationErrors.length) {
                failed(t(formatMessageErrors(mutationErrors)));
            }
            else {
                succeeded(t("Decision updated successfully"));
                navigate(-1);
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const onDecisionUpsertFormChange = (event) => {
        if (!id)
            return;
        if (event.type === "SUBMIT" && event.payload) {
            const values = convertDecisionFormValuesToBackEndValues(event.payload.values, event.payload.decisionPlan);
            fetchDecisionUpdate({
                variables: {
                    decision: Object.assign(Object.assign({}, values), { id }),
                },
            });
        }
    };
    return (_jsx(DecisionUpsertForm, { submitButtonLabel: t("Update"), decision: data === null || data === void 0 ? void 0 : data.getDecision, category: category, isLoading: loading, isButtonDisabled: isSubmitting, healthParameter: healthParameter, healthParameterDecisionPlan: healthParameterDecisionPlan, onChange: onDecisionUpsertFormChange }));
};
