import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Divider, Stack } from "@toolkit/ui";
import { FormProvider, useForm } from "react-hook-form";
import { OrderDetails } from "./OrderDetails";
import { OrderHandlingForm } from "./OrderHandlingForm";
import { useHandleOrder } from "./useHandleOrder.hook";
export const OrderHandlingModal = ({ order }) => {
    var _a;
    const { t } = useTranslation("domains");
    const methods = useForm();
    const { handleSubmit, reset } = methods;
    const { handleUpdate, handleOpen, isOpen, handleToggleDialog, loading } = useHandleOrder(reset, order === null || order === void 0 ? void 0 : order.id);
    const isPrescriptionOrder = (_a = order === null || order === void 0 ? void 0 : order.prescription) === null || _a === void 0 ? void 0 : _a.id;
    const onSubmit = data => {
        const request = Object.assign({}, data);
        handleUpdate(request);
    };
    return (_jsx(CustomDialog, { type: 'base', open: isOpen, DialogTitleProps: {
            title: t("Handle Order"),
            onClose: handleToggleDialog,
        }, button: _jsx(Button, { disabled: !isPrescriptionOrder, onClick: handleOpen, children: t("Handle") }), DialogActionsProps: {
            children: (_jsx(Button, { variant: 'outlined', onClick: handleToggleDialog, children: t("Close") })),
        }, children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(Box, { children: _jsxs(Stack, { direction: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', spacing: 2, divider: _jsx(Divider, { sx: { width: "100%" } }), children: [_jsx(OrderDetails, { order: order }), _jsx(OrderHandlingForm, { errors: [], loading: loading })] }) }) }) })) }));
};
