import { TFunction } from "@toolkit/i18n";
import { tradeDrugTypesPaths } from "./trade-drug-types-paths";

export const tradeDrugTypesTitleBreadcrumb = (t: TFunction) => {
  return t("Trade Drug Types Tracker", { ns: "admin" });
};

export const tradeDrugTypesListBreadcrumb = (t: TFunction) => {
  return {
    id: "supplement-types-list",
    name: t("Trade Drug Types Tracker", { ns: "admin" }),
    route: tradeDrugTypesPaths.listPath.fullPath,
  };
};

export const tradeDrugTypeNewBreadcrumb = (t: TFunction) => {
  return {
    id: "supplement-type-new",
    name: t("New", { ns: "admin" }),
  };
};

export const tradeDrugTypeFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: `supplement-type-field-${fieldName}`,
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
