import { makeStyles } from "tss-react/mui";

type VideoPlayerStyleProps = {
  maxHeight: number;
};

export const useVideoPlayerStyle = makeStyles<VideoPlayerStyleProps>()((_, params) => ({
  root: {
    width: "100%",
    maxHeight: params.maxHeight,
  },
}));
