import { makeStyles } from "../../../base/mui";

export const useStyles = makeStyles()(theme => ({
  paper: {
    margin: 0,
    bottom: 0,
    position: "fixed",
    width: "100%",
    maxWidth: "600px",
    borderRadius: "16px 16px 0 0",
    boxShadow: theme.mixins.shadows.sm,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.05s ease, opacity 0.05s ease",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    overflowY: "auto",
    flex: 1,
    maxHeight: "calc(100vh - 250px)",
  },
  dragBar: {
    width: "50px",
    height: "5px",
    backgroundColor: theme.palette.grey[300],
    borderRadius: "5px",
  },
  footer: {
    display: "flex",
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    height: "72px",
    alignContent: "center",
    justifyItems: "center",
  },
  title: {
    textAlign: "center",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    backgroundColor: theme.palette.background.paper,
  },
  dragHandle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    cursor: "grab",
  },
}));
