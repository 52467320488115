import { Components, Theme } from "../../../base/mui";

export const MuiRadioOverride: Components<Theme>["MuiRadio"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary?.main,
      // "&.Mui-disabled": {
      //   color: theme.palette.stale?.main,
      // },
    }),
  },
};
