/* eslint-disable max-statements */

import { coreConfig } from "../coreConfig";

export const getMediaLink = (file: string | null | undefined) => {
  const DOWNLOAD_SERVICE: string = coreConfig.get("fileServiceApiUrl") + "/download/";

  if (!file) return "";
  if (typeof file !== "string") {
    if (Object.prototype.hasOwnProperty.call(file, "url") && file?.["url"]) {
      return `${DOWNLOAD_SERVICE}${file?.["url"]}`;
    }
    if (file?.["name"]) {
      return `${DOWNLOAD_SERVICE}${file?.["name"]}`;
    }
    return file;
  }
  if (file.startsWith("http")) return file;
  if (file.includes(coreConfig.get("baseMediaUrl")!)) return file;
  return `${DOWNLOAD_SERVICE}${file}`;
};

export const getAssetsFromFileStorage = (name: string) => coreConfig.get("fileServiceApiUrl") + "/download/" + name;
