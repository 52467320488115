import { BenefitGroupInput, H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBenefitGroupUpdateMutation, useHealthProgramBenefitGroupsQuery } from "../../gql";
import { ProgramBenefitForm } from "./ProgramBenefitForm";

export const ProgramBenefitEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setError] = useState<H_EntityError[]>([]);

  const { data, loading } = useHealthProgramBenefitGroupsQuery({
    skip: !params?.id,
  });

  const healthProgramBenefitGroup = data?.healthProgramBenefitGroups?.find(item => item?.id === params?.id)!;
  const [benefitGroupUpdate] = useBenefitGroupUpdateMutation({
    onCompleted: input => {
      const healthProgramErrors = input?.benefitGroupUpdate?.healthProgramErrors!;
      if (healthProgramErrors?.length === 0) {
        succeeded(t("Benefit Group Update Successfully"));
        navigate(-1);
      } else {
        setError(healthProgramErrors);
        failed(formatMessageErrors(healthProgramErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });
  const handleSubmit = (input: BenefitGroupInput) => {
    benefitGroupUpdate({
      variables: {
        id: healthProgramBenefitGroup?.id!,
        input,
      },
    });
  };

  return <ProgramBenefitForm isLoading={loading} errors={errors} defaultValue={healthProgramBenefitGroup} onDone={handleSubmit} />;
};
