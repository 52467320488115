export const insuranceApprovalsRoute = "/insurance-approvals";

export const insuranceApprovalsPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${insuranceApprovalsRoute}${this.route}`;
    },
  },
};
