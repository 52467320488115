var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from "react";
import { createScriptElement, createStyleElement } from "./util";
export const useLoadAssets = (baseUrl, isLoading) => {
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState();
    const [assets, setAssets] = useState();
    const getAssets = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setError(null);
            const res = yield fetch(`${baseUrl}/assets/assets.json`, { cache: "force-cache" });
            setAssets(yield res.json());
        }
        catch (err) {
            setError(err);
        }
    }), []);
    useEffect(() => {
        if (isLoading || isReady) {
            return;
        }
        const timeoutId = setTimeout(() => setIsReady(true), 200);
        return () => clearTimeout(timeoutId);
    }, [isLoading, isReady]);
    useEffect(() => {
        if (!assets || !isReady) {
            return;
        }
        const elements = Object.keys(assets).map(key => {
            if (assets[key].js) {
                return createScriptElement(`${baseUrl}/${assets[key].js}`, assets[key].type);
            }
            else {
                return createStyleElement(`${baseUrl}/${assets[key].css}`);
            }
        });
        elements.forEach(element => document.body.appendChild(element));
        return () => {
            elements.forEach(element => document.body.removeChild(element));
        };
    }, [assets, isReady]);
    useEffect(() => {
        if (!baseUrl || assets) {
            return;
        }
        getAssets();
    }, [baseUrl, assets, getAssets]);
    return { assets, handleGetAssets: getAssets, assetsError: error };
};
