import { HealthProgramType } from "@health/queries/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormControlLabel, Radio, RadioGroup, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProgramProps } from "./types";

export const ProgramTypes: FC<IProgramProps> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("admin");

  const types = Object.keys(HealthProgramType).map(key => ({
    value: HealthProgramType[key],
    name: HealthProgramType[key].replace("_", " ").toLowerCase(),
  }));
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const type = types.find(item => item.value === defaultValues?.type);

  const formErrors = combineErrors(reactFormErrors, errors);
  const theme = useTheme();
  return (
    <Box>
      <RadioGroup defaultValue={type?.value} sx={{ flexDirection: "row", width: "50%" }}>
        {types?.map(item => (
          <FormControlLabel
            value={item.value}
            key={item?.value}
            disabled={Boolean(defaultValues?.type)!}
            control={
              <Radio defaultValue={type?.value} {...register("type", { required: getRequiredValidation(t, !defaultValues?.type) })} />
            }
            label={<Box sx={{ textTransform: "capitalize", color: theme.palette.primary.main }}>{t(item.name)}</Box>}
          />
        ))}
      </RadioGroup>
      {!defaultValues && (
        <Box sx={{ color: theme.palette.error.main, fontSize: theme.mixins.fonts.fontSize.xs }}>{formErrors?.type?.message}</Box>
      )}
    </Box>
  );
};
