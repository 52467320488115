import { Components, Theme } from "@mui/material/styles";

export const MuiButtonBaseOverride: Components<Theme>["MuiButtonBase"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 40,
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      borderRadius: 10,
    }),
  },
};
