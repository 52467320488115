/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMembershipRequestRejectMutationVariables = Types.Exact<{
  healthProgramMembershipRequestRejectId: Types.Scalars['ID'];
  rejectionReason: Types.Scalars['String'];
}>;


export type HealthProgramMembershipRequestRejectMutation = { __typename?: 'Mutation', healthProgramMembershipRequestReject?: { __typename?: 'HealthProgramMembershipRequestCRUD', healthProgramMembershipRequestErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null, healthProgramMembershipRequest?: { __typename?: 'HealthProgramMembershipRequest', id: string } | null } | null };


export const HealthProgramMembershipRequestRejectDocument = gql`
    mutation HealthProgramMembershipRequestReject($healthProgramMembershipRequestRejectId: ID!, $rejectionReason: String!) {
  healthProgramMembershipRequestReject(
    id: $healthProgramMembershipRequestRejectId
    rejectionReason: $rejectionReason
  ) {
    healthProgramMembershipRequestErrors {
      code
      field
      message
    }
    healthProgramMembershipRequest {
      id
    }
  }
}
    `;
export type HealthProgramMembershipRequestRejectMutationFn = Apollo.MutationFunction<HealthProgramMembershipRequestRejectMutation, HealthProgramMembershipRequestRejectMutationVariables>;

/**
 * __useHealthProgramMembershipRequestRejectMutation__
 *
 * To run a mutation, you first call `useHealthProgramMembershipRequestRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMembershipRequestRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMembershipRequestRejectMutation, { data, loading, error }] = useHealthProgramMembershipRequestRejectMutation({
 *   variables: {
 *      healthProgramMembershipRequestRejectId: // value for 'healthProgramMembershipRequestRejectId'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useHealthProgramMembershipRequestRejectMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMembershipRequestRejectMutation, HealthProgramMembershipRequestRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMembershipRequestRejectMutation, HealthProgramMembershipRequestRejectMutationVariables>(HealthProgramMembershipRequestRejectDocument, options);
      }
export type HealthProgramMembershipRequestRejectMutationHookResult = ReturnType<typeof useHealthProgramMembershipRequestRejectMutation>;
export type HealthProgramMembershipRequestRejectMutationResult = Apollo.MutationResult<HealthProgramMembershipRequestRejectMutation>;
export type HealthProgramMembershipRequestRejectMutationOptions = Apollo.BaseMutationOptions<HealthProgramMembershipRequestRejectMutation, HealthProgramMembershipRequestRejectMutationVariables>;