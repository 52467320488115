import { Components, Theme } from "../../../base/mui";

export const MuiTabOverride: Components<Theme>["MuiTab"] = {
  // Check it
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.common.white,
      width: "90px",
      height: "45px",
      fontSize: theme.mixins.fonts.fontSize.sm,
      whiteSpace: "nowrap",
      textTransform: "none",
    }),
  },
};
