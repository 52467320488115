import { Components, Theme } from "@mui/material/styles";
import {
  MuiAutocompleteOverride,
  MuiButtonBaseOverride,
  MuiButtonOverride,
  MuiCardOverride,
  MuiCssBaselineOverride,
  MuiFilledInputOverride,
  MuiIconButtonOverride,
  MuiInputAdornmentOverride,
  MuiInputBaseOverride,
  MuiInputLabelOverride,
  MuiPaginationItemOverride,
  MuiRadioOverride,
  MuiTabOverride,
  MuiTabsOverride,
  MuiTextFieldOverride,
  MuiTypographyOverride,
} from "./components-overrides";

export const CustomComponentsOverride: Components<Theme> = {
  MuiCssBaseline: MuiCssBaselineOverride,
  MuiAutocomplete: MuiAutocompleteOverride,
  MuiButton: MuiButtonOverride,
  MuiButtonBase: MuiButtonBaseOverride,
  MuiCard: MuiCardOverride,
  MuiFilledInput: MuiFilledInputOverride,
  MuiIconButton: MuiIconButtonOverride,
  MuiInputAdornment: MuiInputAdornmentOverride,
  MuiInputBase: MuiInputBaseOverride,
  MuiInputLabel: MuiInputLabelOverride,
  MuiPaginationItem: MuiPaginationItemOverride,
  MuiRadio: MuiRadioOverride,
  MuiTab: MuiTabOverride,
  MuiTabs: MuiTabsOverride,
  MuiTextField: MuiTextFieldOverride,
  MuiTypography: MuiTypographyOverride,
};
