/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllListFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllListFieldsQuery = { __typename?: 'Query', fields?: Array<{ __typename?: 'Field', code?: string | null, display?: string | null, id?: string | null } | null> | null };


export const GetAllListFieldsDocument = gql`
    query getAllListFields {
  fields: getAllListFields {
    code
    display
    id
  }
}
    `;

/**
 * __useGetAllListFieldsQuery__
 *
 * To run a query within a React component, call `useGetAllListFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllListFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllListFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllListFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllListFieldsQuery, GetAllListFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllListFieldsQuery, GetAllListFieldsQueryVariables>(GetAllListFieldsDocument, options);
      }
export function useGetAllListFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllListFieldsQuery, GetAllListFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllListFieldsQuery, GetAllListFieldsQueryVariables>(GetAllListFieldsDocument, options);
        }
export type GetAllListFieldsQueryHookResult = ReturnType<typeof useGetAllListFieldsQuery>;
export type GetAllListFieldsLazyQueryHookResult = ReturnType<typeof useGetAllListFieldsLazyQuery>;
export type GetAllListFieldsQueryResult = Apollo.QueryResult<GetAllListFieldsQuery, GetAllListFieldsQueryVariables>;