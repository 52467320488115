import { makeStyles } from "tss-react/mui";

export const useInfiniteScrollStyles = makeStyles()(theme => ({
  scroll: {
    color: theme.palette.primary.main,
    listStyle: "none",
    borderRadius: "0px 0px 10px 10px",
    marginBottom: 2,
    // overflow: "hidden !important",
  },

  loading: {
    padding: "5px 18px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: "bold",
  },
}));
