import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { MarketplaceDefaultSortingField, MarketplaceSortDirection } from "@health/queries/types";
import { brandsBreadcrumb } from "../../constants/BrandsBreadcrumbs";
import { useMarketplaceBrandsQuery } from "../../gql/queries";
import { useBrandsListTableColumns } from "./useBrandsListTableColumns";
import { BrandsQueryNode } from "../../types";
import { brandsPaths } from "../../constants/BrandsPaths";
import { useNavigate } from "react-router-dom";

export const BrandsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: BrandsQueryNode) => {
    navigate(brandsPaths.update.fullPathWithParams({ brandId: item?.id }));
  };
  const onAddNewItemClick = () => {
    navigate(brandsPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: brandsBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='marketplace-brands'
      query={useMarketplaceBrandsQuery}
      columns={useBrandsListTableColumns()}
      hasTableSetting
      variables={{
        sortBy: {
          field: MarketplaceDefaultSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
