/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPharmacyCredentialsQueryVariables = Types.Exact<{
  branch: Types.Scalars['ID'];
}>;


export type GetPharmacyCredentialsQuery = { __typename?: 'Query', getPharmacyCredentials?: { __typename?: 'PharmacyCredentials', userName: string, branch: { __typename?: 'Branch', name: string, id: string } } | null };


export const GetPharmacyCredentialsDocument = gql`
    query getPharmacyCredentials($branch: ID!) {
  getPharmacyCredentials(branch: $branch, destinationType: IO) {
    userName
    branch {
      name
      id
    }
  }
}
    `;

/**
 * __useGetPharmacyCredentialsQuery__
 *
 * To run a query within a React component, call `useGetPharmacyCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPharmacyCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPharmacyCredentialsQuery({
 *   variables: {
 *      branch: // value for 'branch'
 *   },
 * });
 */
export function useGetPharmacyCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>(GetPharmacyCredentialsDocument, options);
      }
export function useGetPharmacyCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>(GetPharmacyCredentialsDocument, options);
        }
export type GetPharmacyCredentialsQueryHookResult = ReturnType<typeof useGetPharmacyCredentialsQuery>;
export type GetPharmacyCredentialsLazyQueryHookResult = ReturnType<typeof useGetPharmacyCredentialsLazyQuery>;
export type GetPharmacyCredentialsQueryResult = Apollo.QueryResult<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>;