import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";
import { fieldPattern } from "../utils";
import { formGirdBreakPoints } from "./constants";
import { GeneralToggleSettingsGroups } from "./GeneralToggleSettingsGroups.comonent";

export const GeneralSiteSetting: React.FC<ISiteSetting> = ({ loading, errors, siteSettings }) => {
  const { register } = useFormContext();
  const { t } = useTranslation("admin");
  return (
    <Grid item xs={12}>
      <FormCard title={t("General Settings")} loading={loading} doYouHaveData={Boolean(siteSettings)}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              label={t("Vat Percentage")}
              defaultValue={siteSettings?.vatPercentage}
              fullWidth
              InputProps={{
                endAdornment: "%",
              }}
              {...register("vatPercentage", {
                ...fieldPattern(t),
              })}
              error={Boolean(errors?.vatPercentage?.message)}
              helperText={errors?.vatPercentage?.message}
            />
          </Grid>
        </Grid>
        <GeneralToggleSettingsGroups siteSettings={siteSettings} />
      </FormCard>
    </Grid>
  );
};
