import { Branch } from "@health/queries/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, useAutocompleteCustomHook } from "@toolkit/ui";
import { useGetBranchesQuery } from "pages/UserManagement/gql";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
// can't use branch autocomplete twice in the same form
export const Branches: FC<{ errors; vendorValue }> = ({ errors, vendorValue }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: reactFormError },
  } = useFormContext();
  const combinedErrors = combineErrors(reactFormError, errors);
  const {
    data: branchesData,
    isLoading: isBranchLoading,
    onFetchMoreData,
    refetch: refetchBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetBranchesQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
    dataAccessor: "branches",
  });
  const handleBranchesSearch = (filter: string) => {
    refetchBranches({
      filter: {
        name_Icontains: filter,
        vendors: vendorValue?.value?.id ? [vendorValue?.value?.id] : null,
      },
    });
  };
  const branches = branchesData?.branches?.edges?.map(branch => createAutocompleteOption(branch?.node, "id", "name"))! as Branch[];
  const pageInfo = branchesData?.branches?.pageInfo;
  return (
    <>
      <AutocompleteController
        ControllerProps={{
          name: "defaultBranch",
          control: control,
          rules: { required: getRequiredValidation(t, true) },
        }}
        fullWidth
        multiple={false}
        loading={isBranchLoading}
        hasMore={Boolean(pageInfo?.hasNextPage)}
        fetchMoreData={onFetchMoreData}
        TextFieldProps={{
          placeholder: t("Default Branch"),
          error: Boolean(combinedErrors?.defaultBranch?.type),
          helperText: t(combinedErrors?.defaultBranch?.type && t("Required")),
          label: t("Default Branch"),
        }}
        getOptionLabel={option => option?.label}
        options={branches || []}
        isOptionEqualToValue={(option, value) => option?.value?.id === value?.value?.id}
        onSearchInput={handleBranchesSearch}
      />
    </>
  );
};
