import { IOptimaEditCreateContainerEvent, OptimaEditCreateContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { optimaEditsPaths } from "../../constants";

export const AdminOptimaEditCreateContainer = () => {
  const navigate = useNavigate();

  const onOptimaEditCreateContainerChange = (event: IOptimaEditCreateContainerEvent) => {
    if (event.type === "SUBMIT_SUCCESS") {
      navigate(optimaEditsPaths.list.fullPath);
    }
  };

  return <OptimaEditCreateContainer onChange={onOptimaEditCreateContainerChange} />;
};
