export const adminPermissionsManagementRoute = "/admin-permissions-management";

export const adminPermissionsManagementPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${adminPermissionsManagementRoute}${this.route}`;
    },
  },
};
