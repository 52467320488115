/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramExclusionOptionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthProgramExclusionOptionQuery = { __typename?: 'Query', healthProgramExclusionOption?: { __typename?: 'ExclusionOption', id: string, name?: string | null, description?: string | null } | null };


export const HealthProgramExclusionOptionDocument = gql`
    query healthProgramExclusionOption($id: ID!) {
  healthProgramExclusionOption(id: $id) {
    id
    name
    description
  }
}
    `;

/**
 * __useHealthProgramExclusionOptionQuery__
 *
 * To run a query within a React component, call `useHealthProgramExclusionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramExclusionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramExclusionOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramExclusionOptionQuery(baseOptions: Apollo.QueryHookOptions<HealthProgramExclusionOptionQuery, HealthProgramExclusionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramExclusionOptionQuery, HealthProgramExclusionOptionQueryVariables>(HealthProgramExclusionOptionDocument, options);
      }
export function useHealthProgramExclusionOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramExclusionOptionQuery, HealthProgramExclusionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramExclusionOptionQuery, HealthProgramExclusionOptionQueryVariables>(HealthProgramExclusionOptionDocument, options);
        }
export type HealthProgramExclusionOptionQueryHookResult = ReturnType<typeof useHealthProgramExclusionOptionQuery>;
export type HealthProgramExclusionOptionLazyQueryHookResult = ReturnType<typeof useHealthProgramExclusionOptionLazyQuery>;
export type HealthProgramExclusionOptionQueryResult = Apollo.QueryResult<HealthProgramExclusionOptionQuery, HealthProgramExclusionOptionQueryVariables>;