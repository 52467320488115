import React, { FC } from "react";
import { makeStyles, SvgIcon, SvgIconProps } from "../../base/mui";

const useStyles = makeStyles()(() => ({
  root: {
    width: 13,
    height: 13,
  },
}));

export const AlignLeftIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { classes, theme } = useStyles();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "13px"}
      height={height ? height : "13px"}
      viewBox={viewBox ? viewBox : "0 0 13.984 13.984"}
      fill={fill ? fill : theme.palette.primary.main}
      classes={{ root: classes.root }}
      {...props}
    >
      <path
        id='icons8-align-left'
        d='M4.636,4a.636.636,0,1,0,0,1.271H17.348a.636.636,0,1,0,0-1.271Zm0,3.178a.636.636,0,1,0,0,1.271h7.628a.636.636,0,1,0,0-1.271Zm0,3.178a.636.636,0,1,0,0,1.271H17.348a.636.636,0,1,0,0-1.271Zm0,3.178a.636.636,0,1,0,0,1.271h7.628a.636.636,0,1,0,0-1.271Zm0,3.178a.636.636,0,1,0,0,1.271H17.348a.636.636,0,1,0,0-1.271Z'
        transform='translate(-4 -4)'
        fill={fill ? fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
