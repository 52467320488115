import { i18n } from "@toolkit/i18n";
export var YesNo;
(function (YesNo) {
    YesNo["Yes"] = "Yes";
    YesNo["No"] = "No";
})(YesNo || (YesNo = {}));
export const yesNoOptionsMap = {
    [YesNo.Yes]: {
        key: YesNo.Yes,
        get label() {
            return i18n.t("Yes", { ns: "domains" });
        },
        value: YesNo.Yes,
    },
    [YesNo.No]: {
        key: YesNo.No,
        get label() {
            return i18n.t("No", { ns: "domains" });
        },
        value: YesNo.No,
    },
};
export const yesNoOptions = Object.values(yesNoOptionsMap);
