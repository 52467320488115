import { i18n } from "@toolkit/i18n";
import moment, { default as Moment } from "moment";
import "moment/locale/ar";
import "moment/locale/en-il";

export const formatDate = (date: string, locale = "en") => (date ? Moment(date).locale(locale).format("DD MMM YYYY") : "");

export const format = (date: string, format?: string, locale = "en") =>
  date
    ? moment(date)
        .locale(locale)
        .format(format || "DD MMM YYYY")
    : "";

export const formatTime = (time: string, locale = "en"): string => {
  return moment(time, "HH:mm:ss").locale(locale).format("hh:mmA");
};

export const getDayString = (shortDay: string): string => {
  switch (shortDay) {
    case "SAT":
      return i18n.t("Saturday");
    case "SUN":
      return i18n.t("Sunday");
    case "MON":
      return i18n.t("Monday");
    case "TUE":
      return i18n.t("Tuesday");
    case "WED":
      return i18n.t("Wednesday");
    case "THU":
      return i18n.t("Thursday");
    default:
      return i18n.t("Friday");
  }
};
