/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramBenefitGroupsListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthProgramBenefitGroupsListQuery = { __typename?: 'Query', healthProgramBenefitGroups?: Array<{ __typename?: 'BenefitGroup', id: string, name?: string | null } | null> | null };


export const HealthProgramBenefitGroupsListDocument = gql`
    query healthProgramBenefitGroupsList {
  healthProgramBenefitGroups {
    id
    name
  }
}
    `;

/**
 * __useHealthProgramBenefitGroupsListQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitGroupsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthProgramBenefitGroupsListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramBenefitGroupsListQuery, HealthProgramBenefitGroupsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramBenefitGroupsListQuery, HealthProgramBenefitGroupsListQueryVariables>(HealthProgramBenefitGroupsListDocument, options);
      }
export function useHealthProgramBenefitGroupsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramBenefitGroupsListQuery, HealthProgramBenefitGroupsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramBenefitGroupsListQuery, HealthProgramBenefitGroupsListQueryVariables>(HealthProgramBenefitGroupsListDocument, options);
        }
export type HealthProgramBenefitGroupsListQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupsListQuery>;
export type HealthProgramBenefitGroupsListLazyQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupsListLazyQuery>;
export type HealthProgramBenefitGroupsListQueryResult = Apollo.QueryResult<HealthProgramBenefitGroupsListQuery, HealthProgramBenefitGroupsListQueryVariables>;