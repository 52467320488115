import React, { FC } from "react";
import { Box } from "../../base/mui";
import AppMenu from "../Header/AdminHeader.component";
import Sidebar from "../Sidebar/Sidebar.component";
import { useLayoutStyles } from "./Layout.styles";
import { LayoutProps } from "./Layout.types";
import { leftIcons, rightIcons } from "./routes";

const Layout: FC<LayoutProps> = props => {
  const {
    position = "fixed",
    leftItems = leftIcons,
    rightItems = rightIcons,
    title,
    breadCrumb,
    drawerItems = undefined,
    onNavigate: handleNavigate = undefined,
    children,
    onGoToHome: handleGoToHome,
    isOpen,
    onToggleDrawer: handleToggleDrawer,
  } = props;

  const { classes } = useLayoutStyles({ isOpen });

  return (
    <Box sx={{ display: "flex", position: "static", marginTop: 0 }}>
      <AppMenu
        position={position}
        leftItems={leftItems}
        rightItems={rightItems}
        title={title!}
        breadCrumb={breadCrumb}
        isOpen={isOpen}
        onToggle={handleToggleDrawer}
      />

      {isOpen && (
        <Sidebar
          isOpen={isOpen}
          data={drawerItems!}
          onNavigate={handleNavigate}
          onToggleDrawer={handleToggleDrawer}
          onGoToHome={handleGoToHome}
        />
      )}
      <main className={classes.mainContainer}>{children}</main>
    </Box>
  );
};

export default Layout;
