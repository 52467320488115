import { ShowButton } from "@toolkit/ui";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface BranchesInfoProps {
  id: string;
}

export const BranchesInfo: FC<BranchesInfoProps> = ({ id }) => {
  const navigate = useNavigate();

  const handleOpen = () => {
    navigate(providersManagementPaths.branchesPath.fullPathWithParams(id));
  };

  return <ShowButton onClick={handleOpen} />;
};

export default BranchesInfo;
