import { Question, QuestionType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, TextField, useIsMobileView } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { OptionItemIcon } from "./OptionItemIcon";

const AddOptionForm: FC<{
  questionType: QuestionType;
  option: string;
  allOptions: Question["options"];
  onAddOption: () => void;
  onOptionChange: (o: string) => void;
}> = ({ option, allOptions, questionType, onAddOption, onOptionChange }) => {
  const { t } = useTranslation("admin");
  const [error, setError] = useState("");
  const isMobileView = useIsMobileView();
  const handleOptionChange = event => {
    const newValue = event.target.value;
    onOptionChange(newValue);
    if (!newValue) {
      setError("");
    }
  };

  const handleAddOption = () => {
    if (!allOptions?.includes(option)) {
      onAddOption();
      setError("");
    } else {
      setError(t("Option Already Exist"));
    }
  };

  return (
    <Grid container alignItems='center' flexDirection={"row"} flexWrap={isMobileView ? "nowrap" : "wrap"} paddingRight='0px !important'>
      <Grid item sx={{ marginInlineEnd: 1 }}>
        <OptionItemIcon type={questionType!} isChecked={false} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          value={option}
          placeholder={t("Add new option")}
          label={t("Option")}
          onChange={handleOptionChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
      <Grid item xs={4} alignSelf='flex-start' container alignItems='center'>
        <Button disabled={!option || !questionType} onClick={handleAddOption} sx={{ display: "flex", height: 50, whiteSpace: "nowrap" }}>
          + {t("Add Option")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddOptionForm;
