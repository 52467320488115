import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const medicalFormAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const MedicalFormAutocompleteSchemas = {
    medicalFormAutocomplete,
    medicalFormAutocompleteOptional: medicalFormAutocomplete.nullable().optional(),
};
