import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import { useSurveyDetailsContext } from "pages/Surveys/state/context";
import React from "react";
import { useFormContext } from "react-hook-form";
import { SurveyInformationProps } from "./types";

export const SurveyInformation: React.FC<SurveyInformationProps> = ({ isLoading }) => {
  const { t } = useTranslation("admin");
  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);
  const {
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const { survey } = useSurveyDetailsContext();

  return (
    <FormCard title={t("Survey Information")} loading={isLoading} doYouHaveData={true}>
      <Grid container spacing={2}>
        <Grid container item direction='column' spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              size='small'
              label={t("Survey Title")}
              disabled={!canManageSurvey}
              error={Boolean(reactFormErrors.label?.message)}
              helperText={t(reactFormErrors.label?.message)}
              defaultValue={survey?.label}
              {...register("label")}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size='small'
              label={t("Survey Notification")}
              disabled={!canManageSurvey}
              error={Boolean(reactFormErrors.notification?.message)}
              helperText={t(reactFormErrors.notification?.message)}
              defaultValue={survey?.notification}
              {...register("notification")}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              size='small'
              rows={5}
              disabled={!canManageSurvey}
              fullWidth
              placeholder={t("Survey Description")}
              label={t("Description")}
              error={Boolean(reactFormErrors.description?.message)}
              helperText={t(reactFormErrors.description?.message)}
              defaultValue={survey?.description}
              {...register("description")}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default SurveyInformation;
