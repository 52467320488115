import { useEffect, useMemo } from "react";
import { useBreadCrumbs } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { SadaProvider, SadaApp } from "@health/sada";
import { useLocation } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const StreamingPage: React.FC = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const location = useLocation();
  const { accessToken } = useOidcUserProfile();
  const currentUrl = useMemo(() => ({ url: `${location.pathname}${location.search}${location.hash}` }), [location]);

  useEffect(() => {
    setBreadCrumb({ title: t("Streaming"), values: [] });
  }, [setBreadCrumb, t]);

  return (
    <SadaProvider token={accessToken || ""}>
      <SadaApp currentUrl={currentUrl} appType='admin' />
    </SadaProvider>
  );
};

export default StreamingPage;
