import { TFunction } from "@toolkit/i18n";
import { supplementTypesPaths } from "./supplement-types-paths";

export const supplementTypesTitleBreadcrumb = (t: TFunction) => {
  return t("Tracker Supplement Types", { ns: "admin" });
};

export const supplementTypesListBreadcrumb = (t: TFunction) => {
  return {
    id: "supplement-types-list",
    name: t("Tracker Supplement Types", { ns: "admin" }),
    route: supplementTypesPaths.listPath.fullPath,
  };
};

export const supplementTypeNewBreadcrumb = (t: TFunction) => {
  return {
    id: "supplement-type-new",
    name: t("New", { ns: "admin" }),
  };
};

export const supplementTypeFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: `supplement-type-field-${fieldName}`,
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
