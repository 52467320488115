import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import React from "react";

export interface UnPublishDialogProps {
  isOpen: boolean;
  onUnPublish: () => void;
  onClose: () => void;
  isUnPublishLoading: boolean;
}

export const UnPublishDialog: React.FC<UnPublishDialogProps> = ({ isOpen, onUnPublish, onClose: handleClose, isUnPublishLoading }) => {
  const { t } = useTranslation("admin");

  const handleUnPublishConfirmation = () => {
    onUnPublish();
    handleClose();
  };

  return (
    <CustomDialog
      type='warning'
      text={{
        body: t("Are you sure you want to unpublish this survey?"),
        title: t("UnPublish Confirmation"),
      }}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleUnPublishConfirmation}
      isConfirmButtonDisabled={isUnPublishLoading}
    />
  );
};

export default UnPublishDialog;
