import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  benefitGroups: {
    margin: "10px 0px",
    height: 50,
    padding: 5,
    display: "flex",
    paddingInline: 20,
    justifyContent: "flex-start",
    background: theme.palette.gray.light,
    borderRadius: 5,
    width: 220,
    textTransform: "capitalize",
    "& svg": {
      width: 25,
      height: 25,
    },
    "& .MuiButton-endIcon": {
      display: "flex",
      justifyContent: "end",
      width: "100%",
    },
    "& path": {
      fill: `${theme.palette.primary.main} !important`,
    },
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& path": {
      fill: `${theme.palette.common.white} !important`,
    },
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  checkBox: {
    background: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  save: {
    width: 100,
  },
  button: {
    margin: 5,
    height: 35,
    padding: 10,
    borderRadius: 10,
    "& .MuiButton-label": {
      width: "fit-content",
    },
  },
}));
