import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { TextField, TextFieldProps } from "../../../base/mui";

type FormTextFieldProps = Omit<TextFieldProps, "defaultValue" | "error" | "helperText"> & {
  name: string;
};

export const FormTextField: FC<FormTextFieldProps> = props => {
  const { name, variant = "filled", size = "small", fullWidth = true, disabled, ...rest } = props;

  const {
    register,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <TextField
      {...rest}
      {...register(name, {
        setValueAs: (value: string) => (value ? value.trim() : undefined),
      })}
      size={size}
      fullWidth={fullWidth}
      variant={variant}
      error={!!error}
      helperText={error}
      disabled={isFormDisabled || disabled}
    />
  );
};
