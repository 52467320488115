import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthParameterAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullable().optional(),
}));
export const HealthParameterAutocompleteSchemas = {
    healthParameterAutocomplete,
    healthParameterAutocompleteOptional: healthParameterAutocomplete.nullable().optional(),
};
