import { jsx as _jsx } from "react/jsx-runtime";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionActionRecommendationsAutocompleteQuery } from "../../../Decisions/gql";
export const DecisionActionRecommendationsAutocomplete = props => {
    const { name, label } = props;
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMore, fetchMoreData, handleSearch } = useRelayStyleConnection({
        useQuery: useDecisionActionRecommendationsAutocompleteQuery,
        searchKey: "name",
    });
    const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
    return (_jsx(FormAutocomplete, { name: name, label: label || t("Recommendation"), options: options, hasMore: hasMore, loading: isLoading, fetchMoreData: fetchMoreData, onSearchInput: handleSearch }));
};
