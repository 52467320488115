import { replacePathParams } from "utils";

export const healthInsuranceNetworkRoute = "/health-insurance/network";
const healthInsuranceNetworkNewRoute = "new";
const healthInsuranceNetworkEditRoute = "edit/:id";

export const healthInsuranceNetworkPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${healthInsuranceNetworkRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthInsuranceNetworkNewRoute,
    get fullPath() {
      return `${healthInsuranceNetworkRoute}${this.route}`;
    },
  },
  editPath: {
    route: healthInsuranceNetworkEditRoute,
    get fullPath() {
      return `${healthInsuranceNetworkRoute}${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
