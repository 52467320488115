import React, { FC } from "react";
import { Box, ClickAwayListener, IconButton, Tooltip } from "../../base/mui";
import { QuastionMarkIcon } from "../../icons/icons/QuastionMarkIcon";
import { useHintComponentStyles } from "./HintComponent.styles";
export interface HintComponentProps {
  title?: string;
}

const HintComponent: FC<HintComponentProps> = ({ title }) => {
  const { classes } = useHintComponentStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box display='flex' justifyContent='center' alignContent='center'>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={title!}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
        >
          <IconButton className={open ? classes.openIcon : classes.icon} onClick={handleTooltipOpen}>
            <QuastionMarkIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default HintComponent;

HintComponent.defaultProps = {
  title: undefined,
};
