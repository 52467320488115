import { ApolloClient, DocumentNode } from "@apollo/client";
import _ from "lodash";

export const handleDeleteEntityFromCache = (typeName: string, id: string | undefined | null, client: ApolloClient<object>) => {
  if (!id) return;
  const normalizedId = client.cache.identify({ id, __typename: typeName });
  if (!normalizedId) return;
  client.cache.evict({
    id: normalizedId,
  });
  client.cache.gc();
};
export const readItems = <T>(client: ApolloClient<object>, typeName: string): T[] => {
  const items: T[] = Object.entries(_.get(client.cache, "data.data", {}))
    .filter(([key]) => key.includes(typeName))
    .map<T>(([, val]) => val as T);

  return items;
};
export const getItemByPredicate = <T>(client: ApolloClient<object>, typeName: string, predicate: (item: T) => boolean): T | undefined => {
  return readItems<T>(client, typeName)?.find(predicate);
};

export const updateCache = <TData>(cache: ApolloClient<object>["cache"], data: TData, queryDocument: DocumentNode) => {
  cache.modify({
    fields: {
      queryDocument(existingArray = []) {
        const newArr = cache.writeFragment({
          data: data,
          fragment: queryDocument,
        });
        return [...existingArray, newArr];
      },
    },
  });
};
