import { i18n } from "@toolkit/i18n";
export var GuidedCareActivityDurationPeriod;
(function (GuidedCareActivityDurationPeriod) {
    GuidedCareActivityDurationPeriod["Annual"] = "Annual";
    GuidedCareActivityDurationPeriod["Quarterly"] = "Quarterly";
})(GuidedCareActivityDurationPeriod || (GuidedCareActivityDurationPeriod = {}));
export const guidedCareActivityDurationPeriodOptionsMap = {
    [GuidedCareActivityDurationPeriod.Annual]: {
        key: GuidedCareActivityDurationPeriod.Annual,
        get label() {
            return i18n.t("Annual", { ns: "domains" });
        },
        value: GuidedCareActivityDurationPeriod.Annual,
    },
    [GuidedCareActivityDurationPeriod.Quarterly]: {
        key: GuidedCareActivityDurationPeriod.Quarterly,
        get label() {
            return i18n.t("3 Months", { ns: "domains" });
        },
        value: GuidedCareActivityDurationPeriod.Quarterly,
    },
};
export const guidedCareActivityDurationPeriodOptions = Object.values(guidedCareActivityDurationPeriodOptionsMap);
