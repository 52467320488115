import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useHealthProgramUpdateMutation } from "pages/HealthProgram/gql";
import { FC, useEffect, useState } from "react";

const ActiveToggleSwitch: FC<{ row; isDisabled: boolean }> = ({ row, isDisabled }) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(row.isActive);
  const [healthProgramUpdate] = useHealthProgramUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramUpdate?.healthProgramErrors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        setCheck(data?.healthProgramUpdate?.healthProgram?.isActive as boolean);
        succeeded(t("Health Program Update Successfully"));
      }
    },
    onError: () => {
      failed(t("Health Program Update Failed"));
    },
  });

  const handleChangeToggle = (event, isActive) => {
    const subscriptionPeriod = row?.subscriptionPeriod;
    healthProgramUpdate({
      variables: {
        id: row.id,
        input: {
          isActive,
          subscriptionPeriod,
          code: row?.code!,
          name: row?.name,
        },
      },
    });
  };
  useEffect(() => {
    setCheck(row?.isActive);
  }, [row?.isActive]);

  return <CustomToggleButton disabled={isDisabled} value={check || false} defaultChecked={row?.isActive} onChange={handleChangeToggle} />;
};

export default ActiveToggleSwitch;
