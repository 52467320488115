import { formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useToasts } from "@toolkit/ui";
import { useVendorUpdateMutation } from "gql/mutations";
import { FC, useEffect, useState } from "react";

interface ActiveToggleProps {
  id: string;
  isActive: boolean;
}

export const IsActiveToggleButton: FC<ActiveToggleProps> = props => {
  const { id, isActive } = props;
  const { t } = useTranslation("admin");
  const [active, setActive] = useState(isActive);
  const { addToast } = useToasts();
  const { open, handleToggle } = useOpenState();
  const [vendorUpdate, { loading }] = useVendorUpdateMutation({
    onCompleted: data => {
      const errors = data?.vendorUpdate?.vendorErrors;
      if (errors?.length == 0) {
        setActive(data?.vendorUpdate?.vendor?.isActive as boolean);
        addToast(t("Health provider updated successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        handleToggle();
      } else {
        addToast(formatMessageErrors(errors), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    onError: () => {
      addToast(t("Health provider update error"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });
  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const handleConfirmToggleActivation = () => {
    vendorUpdate({
      variables: {
        id: id,
        input: {
          isActive: !active,
        },
      },
    });
    handleToggle();
  };

  const dialogBodyText = active
    ? t("Deactivating health provider will deactivate all it's branches implicitly")
    : t("By Proceeding with this action, health provider will be active");

  return (
    <>
      <CustomToggleButton checked={active} disabled={loading} onChange={handleToggle} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: t(dialogBodyText),
            title: active ? t("Deactivate Vendor Confirmation") : t("Activate Vendor Confirmation"),
          }}
          onClose={handleToggle}
          onConfirm={handleConfirmToggleActivation}
        />
      )}
    </>
  );
};
