import React, { FC } from "react";
import { ActiveFilters } from "../ActiveFilters/ActiveFilters.component";
import { StaticFilterDialog } from "../StaticFilterDialog";
import { useGridContext } from "./GridContext";
import { noop } from "lodash";

export const FilterGrid: FC<{ ignoreTranslations?: boolean; isDynamicFilters?: boolean }> = ({ ignoreTranslations, isDynamicFilters }) => {
  const {
    staticFiltersProps = {
      isOpen: false,
      activeFilters: {},
      onToggleDialog: noop,
      onApplyFilters: noop,
      onDismissFilters: noop,
    },
    activeFiltersProps,
    fields,
  } = useGridContext();

  return (
    <>
      <ActiveFilters ignoreTranslations={ignoreTranslations} activeFilters={activeFiltersProps?.activeFilters!} {...activeFiltersProps} />
      <StaticFilterDialog
        isDynamicFilters={isDynamicFilters}
        filterFields={fields!}
        filters={activeFiltersProps?.filters}
        {...staticFiltersProps}
      />
    </>
  );
};
FilterGrid.defaultProps = {
  ignoreTranslations: false,
  isDynamicFilters: false,
};
