import { Permission } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const PermissionsGroup: FC<{ permissions: Permission[] | any }> = ({ permissions }) => {
  const { t } = useTranslation("admin");
  const theme = useTheme();
  return (
    <CustomDialog type='info' maxWidth='md' title={t("Permissions")}>
      {permissions?.length ? (
        permissions?.map(item => (
          <Box key={item?.code}>
            <Typography color={theme.palette.primary.main} fontSize={theme.mixins.fonts.fontSize.lg}>
              {item?.name}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography color={theme.palette.primary.main}> {t("No Permissions Group to show.")}</Typography>
      )}
    </CustomDialog>
  );
};
