/* eslint-disable max-lines */
import { PlanInput, SubscriptionErrorCode } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs, useToasts } from "@toolkit/ui";
import { CreatePlanMutation, UpdatePlanMutation, useCreatePlanMutation, useUpdatePlanMutation } from "gql/mutations";
import { PlansDocument, usePlanQuery } from "gql/queries";
import {
  subscriptionsManagementFieldBreadCrumb,
  subscriptionsManagementListBreadCrumb,
  subscriptionsManagementNewBreadCrumb,
  subscriptionsManagementTitleBreadcrumb,
} from "pages/SubscriptionsMangment/constants/subscriptions-management-breadcrumbs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { PlanForm } from "./types";
import { getDateTime } from "./utils";

export const useSubscriptionPlanFormState = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const [mutationErrors, setMutationErrors] = useState<
    {
      __typename?: "SubscriptionError" | undefined;
      field?: string | null | undefined;
      message?: string | null | undefined;
      code: SubscriptionErrorCode;
    }[]
  >([]);

  const {
    data: planQueryResponse,
    loading: isPlanQueryLoading,
    error: planQueryError,
  } = usePlanQuery({
    variables: {
      id: (id as string) || "",
    },
    skip: !id,
  });
  const { ...methods } = useForm<PlanForm>({
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: reactFromErrors },
  } = methods;

  const [createPlanMutation, { loading: isCreateLoading }] = useCreatePlanMutation({
    onCompleted: (data: CreatePlanMutation) => {
      const errors = data?.planCreate?.subscriptionErrors;
      if (errors?.length) {
        setMutationErrors(errors);
        const formattedError = formatMessageErrors(errors);
        addToast(t(formattedError), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("Your plan has been created successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const message = t(formattedError) || t("Create plan Error");
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
      console.error("Create plan Error", formattedError);
    },
    refetchQueries: [
      {
        query: PlansDocument,
        variables: {
          first: 1,
        },
      },
    ],
  });
  const [updatePlanMutation, { loading: isUpdateLoading }] = useUpdatePlanMutation({
    onCompleted: (data: UpdatePlanMutation) => {
      const errors = data?.planUpdate?.subscriptionErrors;
      if (errors?.length) {
        setMutationErrors(errors as any);
        const formattedError = formatMessageErrors(errors);
        addToast(t(formattedError), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("Your plan has been updated successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const message = formattedError || "Update plan error";
      addToast(t(message), {
        appearance: "error",
        autoDismiss: true,
      });
      console.error("Update plan error", formattedError);
    },
    refetchQueries: [
      {
        query: PlansDocument,
        variables: {
          first: 1,
        },
      },
    ],
  });

  const onSubmit = (plan?: any) => {
    const request: PlanInput = {
      name: plan?.name,
      period: plan?.period?.value,
      fixedCostAmount: parseFloat(plan.fixedCostAmount) || null,
      fixedOrderPercentage: parseFloat(plan.fixedOrderPercentage) || null,
      validFrom: getDateTime(plan.validFrom),
      validTill: getDateTime(plan.validTill),
      description: plan?.description,
    };
    if (planData) {
      planData?.id &&
        updatePlanMutation({
          variables: {
            id: planData?.id,
            planInput: request,
          },
        });
    } else {
      createPlanMutation({
        variables: {
          planInput: request,
        },
      });
    }
  };
  const loading = isUpdateLoading || isCreateLoading || isPlanQueryLoading;
  const formButtonTitle = () => {
    if (loading) {
      return t("Loading...");
    } else if (planData != undefined) {
      return t("Update");
    } else {
      return t("Create");
    }
  };

  const planData = planQueryResponse?.plan;

  useEffect(() => {
    setBreadCrumb({
      title: subscriptionsManagementTitleBreadcrumb(t),
      values: [
        subscriptionsManagementListBreadCrumb(t),
        planData ? subscriptionsManagementFieldBreadCrumb(planData?.name) : subscriptionsManagementNewBreadCrumb(t),
      ],
    });
  }, [planData]);

  return {
    formButtonTitle,
    handleSubmit,
    loading,
    methods,
    mutationErrors,
    onSubmit,
    planData,
    planQueryError,
    reactFromErrors,
    register,
    reset,
    isSubmitting: isUpdateLoading || isCreateLoading,
  };
};
