import { useTranslation } from "@toolkit/i18n";
import React, { ChangeEvent, FC } from "react";
import { Button } from "../../base/mui";
import { CustomDialog } from "../Dialogs";
import { ImageUpload } from "../ImageUploads";

export const UploadImageModal: FC<{
  handleClose?: () => void;
  open?: boolean;
  image?: string;
  handleImageUploaded: (fileName: string | string[], e?: ChangeEvent<HTMLInputElement>) => void;
  executeCommandCallback?: (() => void) | null;
  token?: string;
}> = ({ handleClose, open, image, token, handleImageUploaded: onImageUploaded, executeCommandCallback: handleExecuteCommandCallback }) => {
  const { t } = useTranslation();
  const handleExecuteCommand = () => {
    handleExecuteCommandCallback && handleExecuteCommandCallback();
  };
  return (
    <CustomDialog
      type='base'
      open={open}
      onClose={handleClose}
      DialogTitleProps={{
        title: t("Add Image"),
      }}
      DialogActionsProps={{
        children: (
          <>
            <Button variant='outlined' onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button disabled={!image} onClick={handleExecuteCommand}>
              {t("Add Image")}
            </Button>
          </>
        ),
      }}
    >
      <ImageUpload id={4} label={t("Upload Image")} token={token} image={image} handleImageUploaded={onImageUploaded} />
    </CustomDialog>
  );
};
