/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorVisitHealthProgramTrackingQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.DoctorHealthProgramPointsFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DoctorVisitHealthProgramTrackingQuery = { __typename?: 'Query', doctorVisitHealthProgramTracking: { __typename?: 'DoctorHealthProgramTotalPointsTrackingConnection', totalCount?: number | null, edges: Array<{ __typename?: 'DoctorHealthProgramTotalPointsTrackingCountableEdge', node: { __typename?: 'DoctorHealthProgramTotalPointsTrackingResponse', callQualityRatingFactor?: number | null, availabilityRatingFactor?: number | null, mainSpecializationsRateFactor?: number | null, pointsDuringNormalDemand?: number | null, pointsDuringUrgentDemand?: number | null, totalPoints?: number | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, photo?: string | null } | null } | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } };


export const DoctorVisitHealthProgramTrackingDocument = gql`
    query DoctorVisitHealthProgramTracking($after: String, $before: String, $filter: DoctorHealthProgramPointsFilterInput, $first: Int, $last: Int) {
  doctorVisitHealthProgramTracking(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      node {
        doctor {
          id
          user {
            firstName
            lastName
            photo
          }
        }
        callQualityRatingFactor
        availabilityRatingFactor
        mainSpecializationsRateFactor
        pointsDuringNormalDemand
        pointsDuringUrgentDemand
        totalPoints
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

/**
 * __useDoctorVisitHealthProgramTrackingQuery__
 *
 * To run a query within a React component, call `useDoctorVisitHealthProgramTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorVisitHealthProgramTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorVisitHealthProgramTrackingQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useDoctorVisitHealthProgramTrackingQuery(baseOptions?: Apollo.QueryHookOptions<DoctorVisitHealthProgramTrackingQuery, DoctorVisitHealthProgramTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorVisitHealthProgramTrackingQuery, DoctorVisitHealthProgramTrackingQueryVariables>(DoctorVisitHealthProgramTrackingDocument, options);
      }
export function useDoctorVisitHealthProgramTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorVisitHealthProgramTrackingQuery, DoctorVisitHealthProgramTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorVisitHealthProgramTrackingQuery, DoctorVisitHealthProgramTrackingQueryVariables>(DoctorVisitHealthProgramTrackingDocument, options);
        }
export type DoctorVisitHealthProgramTrackingQueryHookResult = ReturnType<typeof useDoctorVisitHealthProgramTrackingQuery>;
export type DoctorVisitHealthProgramTrackingLazyQueryHookResult = ReturnType<typeof useDoctorVisitHealthProgramTrackingLazyQuery>;
export type DoctorVisitHealthProgramTrackingQueryResult = Apollo.QueryResult<DoctorVisitHealthProgramTrackingQuery, DoctorVisitHealthProgramTrackingQueryVariables>;