import { MarketplaceHealthPackageCategory } from "@health/queries/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { CategoryInformationForm } from "../CategoryFromInformation";
import { useNavigate, useParams } from "react-router-dom";
import { healthPackageCategoriesPaths } from "../../constants";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { CategoryUpsertFormSchema, categoryUpsertFormSchemaDefaultValues, ICategoryUpsertFormValues } from "./CategoryUpsertFormSchema";
import { convertCategoryToFormValues } from "../../utils";

type CategoryUpsertFormProps = {
  category?: MarketplaceHealthPackageCategory;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: ICategoryUpsertFormValues) => void;
  hasParent?: boolean;
};

export const CategoryUpsertForm: FC<CategoryUpsertFormProps> = props => {
  const { category, isLoading, isSubmitting, submitButtonLabel, hasParent, onChange } = props;
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: string }>();
  const form = useCustomForm<ICategoryUpsertFormValues>({
    schema: CategoryUpsertFormSchema,
    defaultValues: categoryUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    setValues,
  } = form;
  const { t } = useTranslation("admin");
  const isButtonDisabled = isLoading || isSubmitting;
  const { accessToken } = useOidcUserProfile();

  const onSubmit = (values: ICategoryUpsertFormValues) => {
    onChange(values);
  };
  const handleCancel = () => {
    hasParent || category?.parent?.id
      ? navigate(
          healthPackageCategoriesPaths.healthPackageCategoryCategories.fullPathWithParams({
            categoryId: category?.parent?.id || categoryId!,
          })
        )
      : navigate(healthPackageCategoriesPaths.main.fullPath);
  };
  useEffect(() => {
    if (category) {
      const _category = convertCategoryToFormValues(category);
      setValues(_category);
    }
  }, [category, setValues, categoryId]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Category Information")} loading={isLoading} doYouHaveData>
                <CategoryInformationForm hasParent={hasParent} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"image"}
                      validationRation
                      error={!!errors?.image}
                      helperText={errors?.image?.message}
                      token={accessToken!}
                      defaultValue={category?.image}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
