/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCategoryUpdateMutationVariables = Types.Exact<{
  marketplaceProductCategoryUpdateId: Types.Scalars['ID'];
  input: Types.MarketplaceCategoryInput;
}>;


export type MarketplaceProductCategoryUpdateMutation = { __typename?: 'Mutation', marketplaceProductCategoryUpdate?: { __typename?: 'MarketplaceProductCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null } | null };


export const MarketplaceProductCategoryUpdateDocument = gql`
    mutation MarketplaceProductCategoryUpdate($marketplaceProductCategoryUpdateId: ID!, $input: MarketplaceCategoryInput!) {
  marketplaceProductCategoryUpdate(
    id: $marketplaceProductCategoryUpdateId
    input: $input
  ) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      id
      name
      nameAr
    }
  }
}
    `;
export type MarketplaceProductCategoryUpdateMutationFn = Apollo.MutationFunction<MarketplaceProductCategoryUpdateMutation, MarketplaceProductCategoryUpdateMutationVariables>;

/**
 * __useMarketplaceProductCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductCategoryUpdateMutation, { data, loading, error }] = useMarketplaceProductCategoryUpdateMutation({
 *   variables: {
 *      marketplaceProductCategoryUpdateId: // value for 'marketplaceProductCategoryUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceProductCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductCategoryUpdateMutation, MarketplaceProductCategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductCategoryUpdateMutation, MarketplaceProductCategoryUpdateMutationVariables>(MarketplaceProductCategoryUpdateDocument, options);
      }
export type MarketplaceProductCategoryUpdateMutationHookResult = ReturnType<typeof useMarketplaceProductCategoryUpdateMutation>;
export type MarketplaceProductCategoryUpdateMutationResult = Apollo.MutationResult<MarketplaceProductCategoryUpdateMutation>;
export type MarketplaceProductCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductCategoryUpdateMutation, MarketplaceProductCategoryUpdateMutationVariables>;