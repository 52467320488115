import { getMaxLengthValidation, getPositiveNumber, patternEmail } from "@toolkit/core";
import type { CustomTextFieldProps } from "../CustomTextField.components";

export const generalInformationFields = (t, defaultValues): Array<CustomTextFieldProps> => [
  {
    name: "name",
    label: t("English Name"),
    defaultValue: defaultValues?.name,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "arabicName",
    label: t("Arabic Name"),
    defaultValue: defaultValues?.nameAr,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "tradeName",
    label: t("Trade Name"),
    defaultValue: defaultValues?.tradeName,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "commercialRegistrationNumber",
    label: t("Commercial Registration Number"),
    defaultValue: defaultValues?.commercialRegistrationNumber,
    pattern: {
      value: /(\d{10})/,
      message: t("Commercial Registration Number must be 10 digits"),
    },
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "taxLicenseNumber",
    label: t("Tax License Number"),
    defaultValue: defaultValues?.taxLicenseNumber,
    pattern: {
      value: /(\d{15})/,
      message: t("Tax License Number must be 15 digits"),
    },
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
    },
  },
  {
    name: "email",
    label: t("Email"),
    pattern: patternEmail(t),
  },
  {
    name: "deliveryPrice",
    label: t("Delivery Price"),
    defaultValue: defaultValues?.deliveryPrice,
    registerProps: {
      valueAsNumber: true,
      min: getPositiveNumber(t, 0),
    },
  },
];
