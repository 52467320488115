import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const AssignCodeSystemEditorAndReviewerDocument = gql `
    mutation assignCodeSystemEditorAndReviewer($code: CodeSystemCode!, $editorUser: ID!, $reviewerUser: ID!) {
  assignCodeSystemEditorAndReviewer(
    code: $code
    editorUser: $editorUser
    reviewerUser: $reviewerUser
  ) {
    id
    code
    codeSystemConceptFields {
      code
      display
      fieldType
      isMandatory
    }
    display
    editorUserId {
      id
      firstName
      lastName
    }
    reviewerUserId {
      id
      firstName
      lastName
    }
  }
}
    `;
/**
 * __useAssignCodeSystemEditorAndReviewerMutation__
 *
 * To run a mutation, you first call `useAssignCodeSystemEditorAndReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCodeSystemEditorAndReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCodeSystemEditorAndReviewerMutation, { data, loading, error }] = useAssignCodeSystemEditorAndReviewerMutation({
 *   variables: {
 *      code: // value for 'code'
 *      editorUser: // value for 'editorUser'
 *      reviewerUser: // value for 'reviewerUser'
 *   },
 * });
 */
export function useAssignCodeSystemEditorAndReviewerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AssignCodeSystemEditorAndReviewerDocument, options);
}
