import { formGirdBreakPoints, formGirdSpacing, getMaxLengthValidation, required, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { CityErrorsFragment, CityFragmentFragment } from "pages/CitiesManagement/gql";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGetBackendErrorsForInputsValues } from "./getBackendErrorsForInputsValues";

export const CityInformation: FC<{ defaultValues?: CityFragmentFragment; errors: CityErrorsFragment[] }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: formErrors },
    trigger,
  } = useFormContext();
  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errors);

  useEffect(() => {
    if (!errors.length) return;
    trigger();
  }, [backendErrorStorage]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Name")}
          fullWidth
          placeholder={t("Name")}
          defaultValue={defaultValues?.name}
          helperText={t(formErrors?.name?.message)}
          error={Boolean(formErrors?.name?.message)}
          {...register("name", {
            required: required(t),
            validate: value => backendErrorStorage["name"][value] || validateValueIsNotEmpty(value, t),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Arabic Name")}
          fullWidth
          placeholder={t("Arabic Name")}
          defaultValue={defaultValues?.nameAr}
          helperText={t(formErrors?.nameAr?.message)}
          error={Boolean(formErrors?.nameAr?.message)}
          {...register("nameAr", {
            required: required(t),
            maxLength: getMaxLengthValidation(t, 255),
            validate: value => backendErrorStorage["nameAr"][value] || validateValueIsNotEmpty(value, t),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Area")}
          fullWidth
          placeholder={t("Area")}
          defaultValue={defaultValues?.area}
          helperText={t(formErrors?.area?.message)}
          error={Boolean(formErrors?.area?.message)}
          {...register("area", {
            required: required(t),
            validate: value => backendErrorStorage["area"][value] || validateValueIsNotEmpty(value, t),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>
    </Grid>
  );
};
CityInformation.defaultProps = {
  defaultValues: undefined,
};
