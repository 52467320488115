import { makeStyles } from "@toolkit/ui";

export const useSurveyQuestionsStyle = makeStyles()(() => ({
  root: {
    height: "calc(100vh - 125px)",
  },
  addQuestionButton: {
    borderRadius: 24,
  },
}));
