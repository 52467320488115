import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const payerAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const PayerAutocompleteSchemas = {
    payerAutocomplete,
    payerAutocompleteOptional: payerAutocomplete.nullable().optional(),
};
