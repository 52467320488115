import { BenefitGroup } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export type BenefitGroupColumnsTypes = CustomTableColumnProps<BenefitGroup>[];

export const useBenefitGroupColumns = (): BenefitGroupColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        type: "string",
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
        type: "string",
      },
    ];
  }, []);
};
