import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { Avatar, Box } from "../../base/mui";
import { ImageIcon } from "../../icons";
import { CustomDialog } from "../Dialogs";

type GridImageModalProps = {
  title?: string;
  image?: string;
  altText?: string;
  fallbackText?: string;
};

export const GridImageModal: React.FC<GridImageModalProps> = ({ title, image, altText, fallbackText }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      type='info'
      title={title || "Image"}
      button={
        <Box>
          {image ? (
            <Avatar sx={{ width: 40, height: 40, borderRadius: "0px" }} src={getMediaLink(image)} alt={altText} />
          ) : (
            <Avatar sx={{ width: 40, height: 40, borderRadius: "0px" }}>
              <ImageIcon />
            </Avatar>
          )}
        </Box>
      }
    >
      {image ? (
        <Box
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img src={getMediaLink(image)} alt={altText} />
        </Box>
      ) : (
        fallbackText || t("Image Not Provided")
      )}
    </CustomDialog>
  );
};
