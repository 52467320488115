import React from "react";
import { ShowButton } from "../../ShowButton";
import { CustomMuiDialog, CustomMuiDialogProps } from "../CustomMuiDialog";

export interface CustomInfoDialogProps extends Pick<CustomMuiDialogProps, "button" | "maxWidth" | "sx" | "DialogContentProps"> {
  title: string;
  children: React.ReactNode;
}

export const CustomInfoDialog: React.FC<CustomInfoDialogProps> = ({ title, button, children, DialogContentProps, ...reset }) => {
  return (
    <CustomMuiDialog
      {...reset}
      button={button || <ShowButton />}
      DialogTitleProps={{
        title,
      }}
      DialogActionsProps={{
        hasClose: false,
      }}
      DialogContentProps={{
        sx: { minHeight: "100px" },
        ...DialogContentProps,
      }}
    >
      {children}
    </CustomMuiDialog>
  );
};
