import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Badge } from "@toolkit/ui";
import { getCodeSystemProcessingStatusText } from "../../../shared/utils";
import { useDotBadgeStyle } from "./DotBadge.style";
import { getProcessDotColor } from "./util";
export const DotBadge = ({ processingStatus }) => {
    const { classes } = useDotBadgeStyle();
    const { t } = useTranslation("domains");
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { color: getProcessDotColor(processingStatus), variant: 'dot', classes: {
                    colorSuccess: classes.colorApproved,
                    colorWarning: classes.colorUnderReview,
                    colorSecondary: classes.colorPublished,
                    colorError: classes.colorRejected,
                    colorPrimary: classes.colorPrimary,
                    colorInfo: classes.colorDraft,
                } }), "\u00A0 \u00A0", getCodeSystemProcessingStatusText(t, processingStatus)] }));
};
