import { VendorError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useCreateVendorMutation } from "gql/mutations";
import {
  providerListBreadcrumb,
  providerNewBreadcrumb,
  providersTitleBreadcrumb,
} from "pages/ProvidersManagement/constants/providers-management-breadcrumbs";
import { providersManagementPaths } from "pages/ProvidersManagement/constants/providers-management-paths";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useVendorAddHook = () => {
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<VendorError[]>([]);

  const [createVendor, { loading: isCreateLoading }] = useCreateVendorMutation({
    onCompleted: data => {
      const errors = data.vendorCreate?.vendorErrors;
      if (errors && errors?.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
        setError(errors as VendorError[]);
      } else {
        succeeded(t("Health Provider created successfully"));
        return data?.vendorCreate?.vendor?.hasMultipleBranches
          ? navigate(providersManagementPaths.branchesNewPath.fullPathWithParams(String(data?.vendorCreate?.vendor?.id)))
          : navigate(-1);
      }
    },
    onError: error => {
      failed(t("Failed to create health provider"));
      console.error(t("error"), error);
    },
  });

  const handleSubmit = data => {
    createVendor({
      variables: {
        vendor: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: providersTitleBreadcrumb(t),
      values: [providerListBreadcrumb(t), providerNewBreadcrumb(t)],
    });
  }, []);

  return {
    errors,
    isCreateLoading,
    handleSubmit,
  };
};
