import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SublistCreateDocument = gql `
    mutation SublistCreate($subList: SubListInput) {
  addSubList(subList: $subList) {
    errors {
      field
      message
    }
  }
}
    `;
/**
 * __useSublistCreateMutation__
 *
 * To run a mutation, you first call `useSublistCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistCreateMutation, { data, loading, error }] = useSublistCreateMutation({
 *   variables: {
 *      subList: // value for 'subList'
 *   },
 * });
 */
export function useSublistCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SublistCreateDocument, options);
}
