import { combineErrors, lodashGet } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, Chip, CustomIcon, IconButton, TextField, Tooltip } from "@toolkit/ui";
import React, { Fragment, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface IProps<T, M> {
  errors?: Partial<T>[];
  defaultValue?: Partial<M>;
  name: string;
  placeholder: string;
  isRequired?: boolean;
  validateInputTextValue?: (value: string) => string | true;
}

export const ArrayOfValueAutocomplete = <T, M>({
  defaultValue,
  errors,
  name,
  placeholder,
  isRequired,
  validateInputTextValue,
}: IProps<T, M>) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const { t } = useTranslation("admin");

  const formErrors = combineErrors(reactFormErrors, errors);

  const services = watch(name) || [];
  const [inputValue, setInputValue] = useState<string>("");
  const [newOptions, setNewOptions] = useState<string[]>([]);
  const [inputError, setInputError] = useState<string>("");

  const handleInputChange = (_, newInputValue) => {
    if (validateInputTextValue) {
      const validateResult = validateInputTextValue(newInputValue);
      validateResult === true && inputError && setInputError("");
      validateResult !== true && setInputError(validateResult);
    }
    setInputValue(newInputValue);
  };

  const handleAddOption = () => {
    if (inputValue === "" || inputError) return;
    if (!newOptions.some(option => option === inputValue)) {
      setNewOptions([...newOptions, inputValue]);
    }
    const selectedOption: string | undefined = newOptions.find(option => option === inputValue);
    setValue(name, selectedOption ? [...services, selectedOption] : [...services, inputValue]);
    setInputValue("");
  };

  const handleChange = onChange => (_, data) => {
    onChange(data);
  };
  const helperText = lodashGet(formErrors, `${name}.message`);

  const defaultValues = lodashGet(defaultValue, name) || [];

  const renderInput = params => (
    <TextField
      {...params}
      hiddenLabel
      fullWidth
      variant='filled'
      placeholder={placeholder}
      error={Boolean(inputError) || Boolean(helperText)}
      helperText={inputError || helperText}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            <Tooltip title={t("Please Click To add {{placeholder}}", { placeholder })}>
              <IconButton onClick={handleAddOption}>
                <CustomIcon icon={"add"} />
              </IconButton>
            </Tooltip>
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValues}
      rules={{
        required: !isRequired ? isRequired : { value: true, message: t("Required") },
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            multiple
            freeSolo
            disabled={Boolean(defaultValue)}
            options={[]}
            onChange={handleChange(onChange)}
            renderTags={(value: readonly string[], getTagProps) =>
              services.map((option: string, index: number) => (
                <Fragment key={index}>
                  <Chip sx={{ height: "30px" }} variant='outlined' label={option} {...getTagProps({ index })} />
                </Fragment>
              ))
            }
            value={value || []}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={renderInput}
          />
        );
      }}
    />
  );
};
