import { IOptimaEditListContainerEvent, OptimaEditListContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { optimaEditsPaths } from "../../constants";

export const AdminOptimaEditListContainer = () => {
  const navigate = useNavigate();

  const onOptimaEditListContainerChange = (event: IOptimaEditListContainerEvent) => {
    if (event.type === "ADD_CLICK") {
      navigate(optimaEditsPaths.create.fullPath);
    } else if (event.type === "UPDATE_CLICK") {
      navigate(optimaEditsPaths.update.fullPathWithParams({ editId: event.payload.item?.id }));
    }
  };

  return <OptimaEditListContainer onChange={onOptimaEditListContainerChange} />;
};
