import { NetworkStatus } from "@apollo/client";
import { Survey } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs, useGridFilter } from "@toolkit/ui";
import { surveysTitleBreadcrumb } from "pages/Surveys/constants/surveys-breadcrumbs";
import { surveysPaths } from "pages/Surveys/constants/surveys-paths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FetchSurveysQuery, useFetchSurveysQuery } from "../gql/queries";

export const useSurveysListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { data, networkStatus, loading, fetchMore, refetch } = useFetchSurveysQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 8,
    },
    fetchPolicy: "network-only",
  });

  const onDetailsClick = (id: string) => {
    navigate(surveysPaths.detailsPath.fullPathWithParams(id));
  };

  const onAddNewClick = () => {
    navigate(surveysPaths.newPath.fullPath);
  };
  const handleFilter = filter => {
    refetch({
      filter,
    });
  };

  const { staticFiltersProps } = useGridFilter(handleFilter, {});
  const hasMore = data?.surveys?.pageInfo?.hasNextPage;
  const fetchMoreSurveys = () => {
    if (!hasMore) {
      return;
    }
    fetchMore({
      variables: {
        first: 8,
        after: data?.surveys?.pageInfo?.endCursor,
      },

      updateQuery: (prev: FetchSurveysQuery, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          ...fetchMoreResult,
          surveys: {
            ...fetchMoreResult.surveys,
            edges: [...prev?.surveys?.edges!, ...fetchMoreResult?.surveys?.edges],
          },
        });
      },
    });
  };

  const isLoading = NetworkStatus.loading === networkStatus || loading;

  useEffect(() => {
    setBreadCrumb({
      title: surveysTitleBreadcrumb(t),
    });
  }, []);

  return {
    surveys: (data?.surveys?.edges?.map(item => item?.node).filter(Boolean) ?? []) as Survey[],
    isLoading,
    onDetailsClick,
    onAddNewClick,
    fetchMoreSurveys,
    hasMore,
    staticFiltersProps,
  };
};
