import { HealthProgramSubscriptionPeriod } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const healthProgramSubscriptionPeriodOptionsMap = {
    [HealthProgramSubscriptionPeriod.Monthly]: {
        key: HealthProgramSubscriptionPeriod.Monthly,
        get label() {
            return i18n.t("Monthly", { ns: "domains" });
        },
        value: HealthProgramSubscriptionPeriod.Monthly,
    },
    [HealthProgramSubscriptionPeriod.Yearly]: {
        key: HealthProgramSubscriptionPeriod.Yearly,
        get label() {
            return i18n.t("Yearly", { ns: "domains" });
        },
        value: HealthProgramSubscriptionPeriod.Yearly,
    },
};
export const healthProgramSubscriptionPeriodOptions = Object.values(healthProgramSubscriptionPeriodOptionsMap);
