import { makeVar, useReactiveVar } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

interface IMenuProps {
  anchorEl: null | HTMLElement;
  anchorElRef: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
}

export const isNotificationMenuOpenedVar = makeVar(false);
export const useMenu = (): IMenuProps => {
  const anchorElRef = useRef<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = useReactiveVar(isNotificationMenuOpenedVar);

  useEffect(() => {
    setAnchorEl(anchorElRef?.current);
  }, []);

  const handleClick = (): void => {
    isNotificationMenuOpenedVar(true);
  };

  const handleClose = (): void => {
    isNotificationMenuOpenedVar(false);
  };

  return {
    anchorElRef,
    anchorEl,
    isOpen,
    handleClick,
    handleClose,
  };
};
