import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./IconProps";
import { paths } from "./paths";
import { useStyles } from "./styles";

export const CustomIcon: FC<IconProps> = ({ icon, width = 26, height = 26, viewBox = "0 0 27 27", color, transform, margin }) => {
  const { classes } = useStyles({ width, height, color, transform, margin } as IconProps);

  return (
    <SvgIcon color='inherit' width={width} height={height} viewBox={viewBox} className={classes.root}>
      <path d={paths[icon]} />
    </SvgIcon>
  );
};
