import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const patientAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const PatientAutocompleteSchemas = {
    patientAutocomplete,
    patientAutocompleteOptional: patientAutocomplete.nullable().optional(),
};
