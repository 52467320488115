import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { questionTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { useCustomForm } from "@toolkit/core";
import { Box, Divider, FormAutocomplete, FormSwitch, FormTextField, Grid, ImageUploadController } from "@toolkit/ui";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import { useOidcUserProfile } from "../../../../shared/hooks/useOidcUserProfile";
import { createDefaultQuestionOptions, getOptionIcon, shouldQuestionHaveOptions } from "../../../MedicalForms/others";
import { MedicalFormQuestionOptionUpsertForm } from "../../../MedicalForms/forms/MedicalFormQuestionOptionUpsert/MedicalFormQuestionOptionUpsertForm";
import { QuestionType } from "@health/queries/types";
import { medicalFormQuestionUpsertFormDefaultValues, medicalFormQuestionUpsertFormSchema, } from "./MedicalFormQuestionUpsertFormSchema";
import { useMedicalFormQuestionUpsertFormStyle } from "./useMedicalFormQuestionUpsertFormStyle";
const MedicalFormQuestionUpsertFormForwardRef = (props, ref) => {
    var _a, _b;
    const { question, onChange } = props;
    const { t } = useTranslation("domains");
    const { accessToken } = useOidcUserProfile();
    const { classes, theme } = useMedicalFormQuestionUpsertFormStyle();
    const form = useCustomForm({
        defaultValues: medicalFormQuestionUpsertFormDefaultValues,
        schema: medicalFormQuestionUpsertFormSchema,
    });
    const { setValue, watch, handleSubmit, formState: { errors }, } = form;
    const questionType = (_a = watch("questionType")) === null || _a === void 0 ? void 0 : _a.value;
    const onSubmit = (values) => {
        if (question) {
            onChange({
                type: "UPDATE",
                payload: { values },
            });
        }
        else {
            onChange({
                type: "CREATE",
                payload: { values },
            });
        }
    };
    useImperativeHandle(ref, () => ({
        getForm: () => form,
        submit: handleSubmit(onSubmit),
    }));
    useEffect(() => {
        if (question) {
            setValue("question", question.question);
            setValue("questionType", question.questionType);
            setValue("required", question.required);
            setValue("image", question.image);
        }
    }, [question, setValue]);
    useEffect(() => {
        var _a;
        if (questionType === QuestionType.String) {
            setValue("options", []);
        }
        else if (question && ((_a = question.questionType) === null || _a === void 0 ? void 0 : _a.key) === questionType) {
            setValue("options", question.options);
        }
        else {
            setValue("options", createDefaultQuestionOptions(questionType));
        }
    }, [question, questionType]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 7, children: _jsx(FormTextField, { name: "question", label: t("Question") }) }), _jsx(Grid, { item: true, xs: 5, children: _jsx(FormAutocomplete, { name: "questionType", label: t("Question Type"), options: questionTypeOptions, renderOption: (_, option) => {
                                return (_jsxs(Box, Object.assign({ component: "li", className: classes.questionTypeOptionWrapper }, _, { children: [_jsx(Box, { className: classes.questionTypeOptionValue, children: getOptionIcon(option === null || option === void 0 ? void 0 : option.value, theme.palette.primary.main) }), _jsx(Box, { children: option === null || option === void 0 ? void 0 : option.label })] })));
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormSwitch, { name: "required", label: t("Required") }) }), shouldQuestionHaveOptions(questionType) && (_jsx(Grid, { item: true, xs: 12, children: _jsx(MedicalFormQuestionOptionUpsertForm, {}) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { className: classes.divider }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ImageUploadController, { control: form.control, name: "image", label: t("Image"), validationRation: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.image), helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.image) === null || _b === void 0 ? void 0 : _b.message, token: accessToken }) })] }) }) })));
};
export const MedicalFormQuestionUpsertForm = forwardRef(MedicalFormQuestionUpsertFormForwardRef);
