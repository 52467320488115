import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { getYesNoAutocompleteFilter, priorityOptionsMap } from "@health/enum-options";
import { OptimaEditActionsModal } from "../../../OptimaEdits/modals/OptimaEditActions/OptimaEditActionsModal";
import { OptimaEditActivation } from "../../../OptimaEdits/components/OptimaEditActivation/OptimaEditActivation";
export const useOptimaEditListContainerColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "code",
                header: t("Code"),
                accessor: "code",
            },
            {
                key: "category",
                header: t("Category"),
                accessor: ({ category }) => category || "-",
            },
            {
                key: "subCategory",
                header: t("Sub Category"),
                accessor: ({ subCategory }) => subCategory || "-",
            },
            {
                key: "source",
                header: t("Source"),
                accessor: ({ source }) => source || "-",
            },
            {
                key: "priority",
                header: t("Priority"),
                accessor: ({ priority }) => { var _a; return ((_a = priorityOptionsMap[priority]) === null || _a === void 0 ? void 0 : _a.label) || "-"; },
            },
            {
                key: "actions",
                header: t("Actions"),
                accessor: ({ actions }) => _jsx(OptimaEditActionsModal, { actions: actions }),
            },
            {
                key: "active",
                header: t("Active"),
                accessor: row => _jsx(OptimaEditActivation, { id: row.id, isActive: !!(row === null || row === void 0 ? void 0 : row.isActive) }),
                filter: getYesNoAutocompleteFilter({ name: "isActive" }),
            },
        ];
    }, [t]);
};
