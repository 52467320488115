import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { payersPaths } from "../Constants/payersPaths";
import { payersTitleBreadcrumb } from "../Constants/payersBreadcrumbs";
import { usePayersColumns } from "./usePayersColumns";
import { useGetPayersQuery } from "../gql";
import { OrderDirection, PayerOrderField } from "@health/queries/types";

export const PayersList: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEditRow = data => {
    navigate(payersPaths.editPath.fullPathWithParams({ payerId: data?.id }));
  };

  const handleAddNew = () => {
    navigate(payersPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({
      title: payersTitleBreadcrumb(),
    });
  }, []);

  return (
    <GridProvider
      gridName='getPayers'
      query={useGetPayersQuery}
      columns={usePayersColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
      }}
      variables={{
        sortBy: {
          field: PayerOrderField.Created,
          direction: OrderDirection.Desc,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
