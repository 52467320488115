import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useHealthProgramMemberListsQuery } from "../../../HealthProgramMemberLists";

export const HealthProgramMemberLists: FC<any> = ({ errors, memberId }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    setValue,
    formState: { errors: reactErrors },
  } = useFormContext();

  const { data, fetchMore, loading } = useHealthProgramMemberListsQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
  });

  const { data: healthProgramMemberListsData, loading: loadingMemberLists } = useHealthProgramMemberListsQuery({
    variables: {
      first: 10,
      filter: {
        members: [String(memberId)],
      },
    },
  });

  const hasMore = data?.healthProgramMemberLists?.pageInfo?.hasNextPage;
  const fetchMoreData = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          first: 5,
          after: data?.healthProgramMemberLists?.pageInfo?.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            healthProgramMemberLists: {
              ...fetchMoreResult.healthProgramMemberLists,
              edges: [...prev?.healthProgramMemberLists?.edges, ...fetchMoreResult?.healthProgramMemberLists?.edges],
            },
          });
        },
      });
    }
  };
  const formErrors = combineErrors(reactErrors, errors);
  const healthProgramMemberLists = data?.healthProgramMemberLists?.edges?.map(item => item?.node);

  const healthProgramMemberListsDefaultValue = healthProgramMemberListsData?.healthProgramMemberLists?.edges?.map(item => item?.node);

  useEffect(() => {
    Boolean(healthProgramMemberListsDefaultValue?.length) && setValue("healthProgramMemberLists", healthProgramMemberListsDefaultValue);
  }, [memberId, healthProgramMemberListsDefaultValue?.length]);

  return (
    <AutocompleteController
      multiple
      loading={loading || loadingMemberLists}
      ControllerProps={{
        control: control,
        name: "healthProgramMemberLists",
        defaultValue: healthProgramMemberListsDefaultValue || [],
        rules: {
          value: false,
        },
      }}
      TextFieldProps={{
        label: t("Member Lists"),
        helperText: t(formErrors.healthProgramMemberLists?.message),
        error: Boolean(formErrors.healthProgramMemberLists?.message),
      }}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
      getOptionLabel={(o: { name?: string }) => o?.name!}
      options={healthProgramMemberLists || []}
    />
  );
};
