import { getRequiredValidation, maxRequired, minRequired, patternEmail } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";

export const userFields = (defaultValues, isEdit) => [
  {
    name: "firstName",
    label: i18n.t("First Name", { ns: "admin" }),
    defaultValue: defaultValues?.firstName,
    isRequired: true,
    minLength: minRequired(i18n.t, 3),
    maxLength: maxRequired(i18n.t, 30),
  },
  {
    name: "lastName",
    label: i18n.t("Last Name", { ns: "admin" }),
    defaultValue: defaultValues?.lastName,
    isRequired: true,
    minLength: minRequired(i18n.t, 3),
    maxLength: maxRequired(i18n.t, 30),
  },
  {
    name: "email",
    label: i18n.t("Email", { ns: "admin" }),
    pattern: patternEmail(i18n.t),
    defaultValue: defaultValues?.email,
    disabled: !!defaultValues?.email,
    isRequired: !defaultValues?.email,
  },
  {
    name: "password",
    label: i18n.t("Password", { ns: "admin" }),
    type: "password",
    isRequired: !isEdit,
    disabled: isEdit,
    autoComplete: "new-password",
  },
  {
    name: "nationalId",
    label: i18n.t("National ID", { ns: "admin" }),
    required: getRequiredValidation(i18n.t, true),
    defaultValue: defaultValues?.nationalId,
    isRequired: !isEdit,
    disabled: !!defaultValues?.nationalId,
  },
];
