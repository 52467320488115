import { createPathDefinition } from "@toolkit/core";
import { marketplaceProductsRoute } from "pages/MarketplaceProducts/constants/MarketPlaceProductsPaths";

export const brandsRoute = `${marketplaceProductsRoute}/brands`;

export const brandsPaths = createPathDefinition(brandsRoute, {
  main: "",
  new: "new",
  update: ":brandId",
});
