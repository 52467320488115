/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog, Grid, TextField } from "@toolkit/ui";
import { FC, memo, useState } from "react";
import { CloneDialogProps } from "./CloneDialog.types";

const CloneDialog: FC<CloneDialogProps> = ({ isOpen, isLoading, onClose, onClone }) => {
  const [label, setLabel] = useState<string>("");
  const { t } = useTranslation("admin");

  const handleClone = () => {
    onClone(label);
    setLabel("");
  };

  const handleClose = () => {
    setLabel("");
    onClose();
  };

  const handleLabelChange = event => {
    setLabel(event.target.value);
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      maxWidth='md'
      DialogTitleProps={{
        onClose: handleClose,
        title: t("Clone Survey"),
      }}
      keepMounted={false}
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handleClone} disabled={!label} startIcon={isLoading && <CircularProgress size={15} />}>
              {t("Clone")}
            </Button>
            <Button variant='outlined' onClick={handleClose} autoFocus>
              {t("Close")}
            </Button>
          </>
        ),
      }}
      DialogContentProps={{ sx: { maxHeight: "60vh" } }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={label}
            placeholder={t("New Survey Label")}
            label={t("New Survey Label")}
            onChange={handleLabelChange}
          />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
export default memo(CloneDialog);
