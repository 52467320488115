import { City, CityOrderField } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, useFormatDate } from "@toolkit/ui";
import { useMemo } from "react";

export type CityColumnTypes = CustomTableColumnProps<City>[];

export const useCitiesManagementColumns = (): CityColumnTypes => {
  const { t } = useTranslation("admin");
  const { formatLocalizedDate } = useFormatDate();

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Name,
      },
      {
        key: "area",
        header: t("Area"),
        accessor: "area",
      },
      {
        key: "created",
        header: t("Created"),
        accessor: ({ created }) => formatLocalizedDate(created),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Created,
      },
      {
        key: "name_Icontains",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
    ];
  }, [formatLocalizedDate, t]);
};
