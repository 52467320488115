import { Components, Theme } from "@mui/material/styles";

export const MuiIconButtonOverride: Components<Theme>["MuiIconButton"] = {
  styleOverrides: {
    root: {
      borderRadius: 10,
      marginRight: "-4px",
      // border: "1px solid",
      // "& > svg > path": {
      //   fill: "inherit",
      // },
      "&.Mui-disabled": {
        opacity: "50%",
      },
    },
    // disabled: {
    //   opacity: "50%",
    // },
  },
};
