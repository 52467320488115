import { ExclusionOptionInput } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { ExclusionOptionUpdateMutation, useExclusionOptionUpdateMutation } from "pages/HealthProgram/gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useExclusionUpdateForm = (exclusionId: string) => {
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any[] | undefined>();
  const [exclusionOptionsUpdate, { loading: isUpdateLoading }] = useExclusionOptionUpdateMutation({
    onCompleted: (data: ExclusionOptionUpdateMutation) => {
      const mutationErrors = data.exclusionOptionUpdate?.healthProgramErrors!;

      if (mutationErrors?.length == 0) {
        succeeded(t("Exclusions option updated successfully"));
        navigate("/health-program/exclusion");
      } else {
        setErrors(mutationErrors! || null);
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t("Failed to update exclusion"));
      failed(t(message));
    },
  });
  const onUpdate = async (data: ExclusionOptionInput) => {
    const request = {
      ...data,
    };
    await exclusionOptionsUpdate({
      variables: {
        id: exclusionId,
        input: {
          ...request,
        },
      },
    });
  };
  return {
    onUpdate,
    errors,
    isUpdateLoading,
  };
};
export default useExclusionUpdateForm;
