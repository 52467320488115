import { z } from "zod";
import { isNil } from "lodash";
import { i18n } from "@toolkit/i18n";
export const MedicalFormQuestionOptionUpsertFormSchema = z
    .object({
    id: z.string(),
    score: z.coerce.number().min(0).nullable(),
    value: z.string().min(1).max(255),
})
    .superRefine((data, ctx) => {
    if (isNil(data.score)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["score"],
        });
    }
});
