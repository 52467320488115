import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { Box, FormHelperText, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "../../../base/mui";
import { IToggleButtonGroupOption } from "../types";
import { useFormToggleButtonGroupStyle } from "./useFormToggleButtonGroupStyle";

type FormToggleButtonGroupProps = ToggleButtonGroupProps & {
  name: string;
  options: IToggleButtonGroupOption[];
};

export const FormToggleButtonGroup: FC<FormToggleButtonGroupProps> = props => {
  const { name, options, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  const { classes } = useFormToggleButtonGroupStyle();

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const handleToggleButtonGroupOnChange = (event: React.MouseEvent<HTMLElement>, option: IToggleButtonGroupOption) => {
            field.onChange(option);
          };

          return (
            <ToggleButtonGroup
              {...rest}
              className={classes.toggleButtonGroup}
              exclusive
              value={field.value}
              disabled={isFormDisabled || disabled}
              onChange={handleToggleButtonGroupOnChange}
            >
              {options.map(option => (
                <ToggleButton key={option.key} value={option} selected={option?.key === field?.value?.key}>
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          );
        }}
      />

      {!!error && <FormHelperText error> {error} </FormHelperText>}
    </Box>
  );
};
