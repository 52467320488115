import { PayerTpo } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const payerTpoOptionsMap = {
    [PayerTpo.Io]: {
        key: PayerTpo.Io,
        get label() {
            return i18n.t("iO", { ns: "domains" });
        },
        value: PayerTpo.Io,
    },
    [PayerTpo.Eclaim]: {
        key: PayerTpo.Eclaim,
        get label() {
            return i18n.t("EClaim", { ns: "domains" });
        },
        value: PayerTpo.Eclaim,
    },
};
export const payerTpoOptions = Object.values(payerTpoOptionsMap);
