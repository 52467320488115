import { SimplePaletteColorOptions } from "../../../base/mui";

export const green: SimplePaletteColorOptions = {
  // Fruit Salad
  main: "#4fa043",
  50: "#f6fbf5",
  100: "#edf7eb",
  200: "#dbeed7",
  300: "#c8e6c4",
  400: "#b6deb0",
  500: "#a4d69c",
  600: "#92cd88",
  700: "#7fc575",
  800: "#6dbd61",
  900: "#5bb54d",
  1100: "#48923d",
  1200: "#408136",
  1300: "#38712f",
  1400: "#306129",
  1500: "#285122",
  1600: "#20411b",
  1700: "#183114",
  1800: "#10200e",
  1900: "#081007",
};
