/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type CityFragmentFragment = { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null, created: any };

export const CityFragmentFragmentDoc = gql`
    fragment CityFragment on City {
  id
  name
  nameAr
  area
  created
}
    `;