import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { CustomDatePicker, CustomDatePickerProps } from "../CustomDatePicker";

type FormDatePickerFieldProps = Omit<
  CustomDatePickerProps,
  "defaultValue" | "error" | "helperText" | "type" | "control" | "controllerProps"
>;

export const FormDatePickerField: FC<FormDatePickerFieldProps> = props => {
  const { name, variant = "filled", size = "small", fullWidth = true, disabled, ...rest } = props;

  const {
    control,
    register,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <CustomDatePicker
      {...rest}
      control={control}
      {...register(name)}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      error={!!error}
      helperText={error}
      disabled={isFormDisabled || disabled}
      shouldHaveDefaultValidation={false}
    />
  );
};
