import { HealthProgramsFormEditPage as HealthProgramsFormEditPageWithOutContext } from "../edit/HealthProgramEdit.screen";
import { HealthProgramsFormNewPage as HealthProgramsFormNewPageWithOutContext } from "../new/HealthProgramsAdd.screen";
import { ProgramsContextProvider } from "./context";

export { ProgramTrackingPage } from "./ProgramTrackingPage";

export const HealthProgramsFormEditPage = () => {
  return (
    <ProgramsContextProvider>
      <HealthProgramsFormEditPageWithOutContext />
    </ProgramsContextProvider>
  );
};

export const HealthProgramsFormNewPage = () => {
  return (
    <ProgramsContextProvider>
      <HealthProgramsFormNewPageWithOutContext />
    </ProgramsContextProvider>
  );
};
