import { AppRoleTypes } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, Grid, PageWrapper } from "@toolkit/ui";
import { adminUserManagementPaths } from "pages/UserManagement/constants/admin-user-management-paths";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PermissionsGroupInformation } from "./components/PermissionsInfo.components";
import { AdminUserInputData, UserFormProps } from "./components/UserForm.types";
import { UserInformation } from "./components/UserInformation.components";

export const AdminUserManagementForm: FC<UserFormProps> = ({ errors, onDone, isEdit, isLoading, defaultValues, isFormLoading }) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const methods = useForm<AdminUserInputData>({
    mode: "onChange",
  });
  const { handleSubmit, watch } = methods;
  const onSubmit = (data: AdminUserInputData) => {
    const request = {
      ...data,
    };
    if (onDone) {
      onDone(request);
    }
  };
  const appRole = watch("appRole");
  const isPermissionGroupsDisplayed = appRole?.value === AppRoleTypes.User ? true : defaultValues?.appRole == AppRoleTypes.User && !appRole;

  const handleCancel = () => {
    navigate(adminUserManagementPaths.listPath.fullPath);
  };

  const doYouHaveUser = isEdit ? defaultValues != undefined : true;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box display='inline-block'>
              <FormActions
                isLoading={isLoading}
                hasCancel
                hasFormButton
                formButtonTitle={isEdit ? t("Update") : t("Create")}
                onNavigation={handleCancel}
                newButtonDisabled={isFormLoading}
              />
            </Box>
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <UserInformation
                errors={errors}
                defaultValues={defaultValues}
                isEdit={isEdit}
                doYouHaveUser={doYouHaveUser}
                isLoading={isLoading}
              />
            </Grid>
            {isPermissionGroupsDisplayed && (
              <Grid item xs={12}>
                <PermissionsGroupInformation
                  errors={errors}
                  defaultValues={defaultValues}
                  doYouHaveUser={doYouHaveUser}
                  isLoading={isLoading}
                />
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
