import { H_EntityError, HealthProgramMemberList } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  memberFieldBreadcrumb,
  membersBreadcrumb,
  membersTitleBreadcrumb,
} from "pages/HealthProgramMemberLists/constants/member-list-breadcrumbs";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthProgramMemberListUpdateMutation, useHealthProgramMemberListsQuery } from "../gql";
import { HealthProgramMemberListsForm } from "./components/HealthProgramMemberListsForm";

export const HealthProgramMemberListEditPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<Partial<H_EntityError>[]>([]);

  const { data, loading } = useHealthProgramMemberListsQuery({
    skip: !params?.id,
    variables: {
      first: 10,
      filter: {
        ids: [params?.id!],
      },
    },
  });

  const healthProgramMemberList = data?.healthProgramMemberLists?.edges.find(item => item?.node?.id === params?.id)!;
  const [healthProgramMemberListUpdate, { loading: isSubmitting }] = useHealthProgramMemberListUpdateMutation({
    onCompleted: input => {
      const entityErrors = input?.healthProgramMemberListUpdate?.entityErrors!;
      if (entityErrors?.length === 0) {
        succeeded(t("Member List Updated Successfully"));
        navigate(-1);
      } else {
        setError(entityErrors);
        failed(formatMessageErrors(entityErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });

  const handleSubmit = input => {
    delete input.payerId;
    healthProgramMemberListUpdate({
      variables: {
        id: healthProgramMemberList?.node?.id!,
        input: {
          ...input,
          isActive: input?.isActive ? input?.isActive : false,
        },
      },
    });
  };

  useEffect(() => {
    if (healthProgramMemberList?.node?.name) {
      setBreadCrumb({
        title: membersTitleBreadcrumb(t),
        values: [membersBreadcrumb(t), memberFieldBreadcrumb(healthProgramMemberList.node.name)],
      });
    }
  }, [healthProgramMemberList?.node?.name]);

  return (
    <HealthProgramMemberListsForm
      isLoading={loading}
      errors={errors}
      defaultValue={healthProgramMemberList?.node as Partial<HealthProgramMemberList>}
      onDone={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
