import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, Typography } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { domainsConfig } from "../../domainsConfig";
export const FileDownload = props => {
    const { label, templateCode, isButton = true } = props;
    const [isDownloading, setIsDownloading] = useState(false);
    const { t } = useTranslation("domains");
    const { user } = useAuth();
    const handleDownloadFile = () => {
        if (isDownloading)
            return;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${user === null || user === void 0 ? void 0 : user.access_token}`);
        setIsDownloading(true);
        const fileServiceApiUrl = domainsConfig.get("fileServiceApiUrl");
        const baseURL = `${fileServiceApiUrl}/bulk/template/${templateCode}`;
        fetch(baseURL, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        })
            .then(response => response.blob())
            .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", `${templateCode}.xlsx`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
        })
            .catch(error => {
            console.error("Error fetching template:", error);
            setIsDownloading(false);
        });
    };
    return isButton ? (_jsx(Button, { sx: { margin: "10px", paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }, startIcon: _jsx(ExcelIcon, {}), disabled: isDownloading, onClick: handleDownloadFile, children: label || t("Download .xlx") })) : (_jsx(Typography, { fontSize: 14, sx: { textDecoration: "underline", cursor: "pointer" }, onClick: handleDownloadFile, children: label || t("Download .xlx") }));
};
