import { CallbackRequest } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, TextField, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const PatientNote: FC<{ note?: CallbackRequest["patientNotes"] }> = ({ note }) => {
  const { t } = useTranslation("admin");
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
          {t("Patient Notes")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <TextField
          multiline
          fullWidth
          minRows={5}
          InputProps={{
            readOnly: true,
          }}
          value={note || t("Patient didn't provide notes")}
        />
      </Box>
    </Box>
  );
};
