import { useAuth } from "react-oidc-context";
import { UserInfoType } from "shared/configs/oidc";

export const useOidcUserProfile = () => {
  const auth = useAuth();
  const userInfo = auth.user?.profile! as unknown as UserInfoType;
  return {
    accessToken: auth?.user?.access_token,
    userInfo,
  };
};
