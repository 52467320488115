import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GridProvider } from "../../../../shared/components";
import { useDecisionDeleteMutation, useDecisionListQuery } from "../../../Decisions/gql";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useDecisionListTableColumns } from "./useDecisionListTableColumns";
export const DecisionListContainer = props => {
    const { category, filter, onAddNewClick, onEditRowClick } = props;
    return (_jsx(GridProvider, { gridName: 'decisionList', hasTableSetting: true, columns: useDecisionListTableColumns({ category }), query: useDecisionListQuery, filterInput: Object.assign({ categories: [category] }, filter), hideFilterInput: true, tableAction: {
            isEditVisible: true,
            onEditRow: onEditRowClick,
            isDeleteVisible: true,
        }, deleteItemProps: {
            name: "Decision",
            entityTypeName: "Decision",
            useDeleteMutation: useDecisionDeleteMutation,
        }, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsxs(_Fragment, { children: [_jsx(TableSettingComponent, {}), _jsx(MainListActions, { hasAddNew: true, onAddNewItem: onAddNewClick })] }), children: _jsx(TableGrid, {}) }) }));
};
