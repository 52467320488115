import { z } from "zod";
import { zodSchema } from "../../../../zod";
export const optimaEditUpsertFormSchema = z.object({
    code: z.string().min(1),
    category: z.string().min(1),
    subCategory: z.string().min(1),
    source: z.string().min(1),
    priority: zodSchema.optimaPriorityAutocompleteEnum,
    details: z.string().min(1),
    message: z.string().min(1),
    actions: z.array(zodSchema.optimaActionAutocomplete),
    isActive: z.boolean(),
});
export const optimaEditUpsertFormDefaultValues = {
    code: undefined,
    category: undefined,
    subCategory: undefined,
    source: undefined,
    priority: undefined,
    details: undefined,
    message: undefined,
    actions: [],
    isActive: false,
};
