import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionActivationDocument = gql `
    mutation DecisionActivation($id: ID!, $isActive: Boolean!) {
  updateDecisionStatus(id: $id, isActive: $isActive) {
    errors {
      field
      message
    }
  }
}
    `;
/**
 * __useDecisionActivationMutation__
 *
 * To run a mutation, you first call `useDecisionActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecisionActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decisionActivationMutation, { data, loading, error }] = useDecisionActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useDecisionActivationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DecisionActivationDocument, options);
}
