import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTrackerSupplementTypeCreateMutation } from "../gql";
import {
  supplementTypeNewBreadcrumb,
  supplementTypesListBreadcrumb,
  supplementTypesTitleBreadcrumb,
} from "../constants/supplement-types-breadcrumbs";
import { SupplementTypeTrackerForm } from "./components/SupplementTypeTrackerForm";

export const SupplementTypeTrackerNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [supplementTypeCreate, { loading: isSubmitting }] = useTrackerSupplementTypeCreateMutation({
    onCompleted: data => {
      const id = data?.trackerSupplementTypeCreate?.id;
      if (id) {
        succeeded(t("Supplement Type Created Successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });

  const handleSubmit = data => {
    supplementTypeCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: supplementTypesTitleBreadcrumb(t),
      values: [supplementTypesListBreadcrumb(t), supplementTypeNewBreadcrumb(t)],
    });
  }, []);

  return <SupplementTypeTrackerForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};
