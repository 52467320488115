/* eslint-disable max-lines */
import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const IoHealthLogo: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "793.70135"}
      height={height ? height : "312"}
      viewBox={viewBox ? viewBox : "0 0 793.70135 312"}
      direction='ltr'
      {...props}
    >
      <defs id='defs6'>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath18'>
          <path d='M 0,234 H 595.276 V 0 H 0 Z' id='path16' />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath26'>
          <path d='M 5.97981,228.618 H 226.301 V 5.51051 H 5.97981 Z' id='path24' />
        </clipPath>
        <linearGradient
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(-44.508861,199.48941,199.48941,44.508861,140.62817,-14.786816)'
          spreadMethod='pad'
          id='linearGradient56'
        >
          <stop stopOpacity={1} stopColor='#913264' offset='0' id='stop48' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='0.54753492' id='stop50' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='0.732317' id='stop52' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='1' id='stop54' />
        </linearGradient>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath66'>
          <path d='M 0,234 H 595.276 V 0 H 0 Z' id='path64' />
        </clipPath>
      </defs>
      <g id='g10' transform='matrix(1.3333333,0,0,-1.3333333,0,312)'>
        <g id='g12'>
          <g id='g14' clipPath='url(#clipPath18)'>
            <g id='g20'>
              <g id='g22' />
              <g id='g34'>
                <g clipPath='url(#clipPath26)' opacity='0.100006' id='g32'>
                  <g transform='translate(147.0298,7.7798)' id='g30'>
                    <path
                      d='m 0,0 c -25.957,-3.68 -50.763,-3.323 -76.452,4.344 -25.461,7.677 -51.735,22.446 -60.85,44.336 -8.877,21.92 -0.665,50.823 10.871,75.46 10.821,24.082 26.393,45.724 45.793,63.617 19.867,18.677 45.566,35.677 68.676,32.751 23.011,-3.045 43.443,-26.026 58.599,-48.749 14.828,-21.047 25.034,-44.991 29.974,-70.263 4.741,-25.332 4.096,-53.57 -9.83,-71.582 C 52.995,11.803 25.927,3.898 0,0'
                      fillRule='nonzero'
                      fillOpacity={1}
                      fill='#2b3078'
                      stroke='none'
                      id='path28'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id='g36'>
          <g id='g38'>
            <g id='g44'>
              <g id='g46'>
                <path
                  d='M 104.509,207.132 C 80.824,197.6 59.32,183.377 41.288,165.316 v 0 C 22.414,147.006 5.066,123.678 5.562,100.815 v 0 C 6.296,77.904 24.992,55.527 45.375,39.866 v 0 C 65.965,24.135 88.293,15.308 113.019,9.724 v 0 l 0.01,-0.01 c 24.776,-5.366 51.954,-7.508 70.639,4.146 v 0 c 18.776,11.525 29.033,36.827 33.426,61.356 v 0 c 4.205,24.538 3.174,49.691 -3.006,73.803 v 0 c -5.911,25.739 -16.504,53.521 -36.261,64.173 v 0 c -7.163,3.808 -15.514,5.394 -24.407,5.394 v 0 c -15.8,0 -33.312,-5.006 -48.911,-11.454'
                  // style='fill:url(#linearGradient56);stroke:none'
                  fill={"url(#linearGradient56)"}
                  stroke='none'
                  id='path58'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='g60'>
          <g id='g62' clipPath='url(#clipPath66)'>
            <g id='g68' transform='translate(139.0354,80.656)'>
              <path
                d='m 0,0 c -16.157,0 -29.25,13.092 -29.25,29.25 0,16.157 13.093,29.249 29.25,29.249 16.157,0 29.25,-13.092 29.25,-29.249 C 29.25,13.092 16.157,0 0,0 m 0,71.007 c -23.061,0 -41.747,-18.697 -41.747,-41.747 0,-23.051 18.696,-41.747 41.747,-41.747 23.051,0 41.747,18.696 41.747,41.747 0,23.06 -18.696,41.747 -41.747,41.747'
                fill='#ffffff'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path70'
              />
            </g>
            <g id='g72' transform='translate(80.5063,71.769)'>
              <path
                d='m 0,0 v 0 c -4.205,-0.099 -7.697,3.233 -7.806,7.439 v 43.959 c 0.228,4.314 3.908,7.617 8.223,7.389 3.987,-0.208 7.181,-3.402 7.389,-7.389 V 7.439 C 7.707,3.233 4.205,-0.099 0,0 m 0,82.591 c 4.9,0.169 9.016,-3.679 9.175,-8.579 v -0.169 c 0.039,-4.8 -3.819,-8.728 -8.62,-8.758 -0.188,0 -0.376,0 -0.555,0.01 -4.791,-0.268 -8.897,3.392 -9.175,8.183 -0.01,0.188 -0.019,0.377 -0.01,0.565 0.04,4.87 4.017,8.788 8.887,8.758 H 0'
                fill='#ffffff'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path74'
              />
            </g>
            <g id='g76' transform='translate(139.0255,85.2285)'>
              <path
                d='m 0,0 c -13.628,0 -24.667,11.049 -24.667,24.667 0,13.628 11.049,24.668 24.667,24.668 13.628,0 24.667,-11.05 24.667,-24.668 C 24.667,11.049 13.618,0 0,0 m 0.198,55.028 c -16.871,0 -30.549,-13.678 -30.549,-30.549 0,-16.872 13.678,-30.549 30.549,-30.549 16.872,0 30.549,13.677 30.549,30.549 0,16.871 -13.677,30.549 -30.549,30.549'
                fill='#ffffff'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path78'
              />
            </g>
            <g id='g80' transform='translate(250.0546,151.1718)'>
              <path
                d='m 0,0 h 15.096 v -27.494 c 3.878,5.068 10.573,8.302 18.548,8.302 13.479,0 23.288,-9.056 23.288,-25.56 V -79.804 H 41.836 v 32.999 c 0,9.601 -5.286,14.768 -13.261,14.768 -7.974,0 -13.479,-5.177 -13.479,-14.768 V -79.804 H 0 Z'
                fill='#2b3078'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path82'
              />
            </g>
            <g id='g84' transform='translate(332.8742,106.8459)'>
              <path
                d='M 0,0 H 28.357 C 28.139,7.875 21.89,12.835 14.124,12.835 6.893,12.835 1.18,8.203 0,0 m 14.56,-36.451 c -17.357,0 -29.983,12.081 -29.983,30.837 0,18.756 12.289,30.837 29.983,30.837 17.695,0 29.439,-11.754 29.439,-29.547 0,-1.945 -0.11,-3.879 -0.437,-5.823 H -0.109 c 0.754,-8.847 6.794,-13.806 14.342,-13.806 6.467,0 10.028,3.233 11.972,7.23 H 42.491 C 39.257,-27.722 29.339,-36.46 14.56,-36.46 Z'
                fill='#2b3078'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path86'
              />
            </g>
            <g id='g88' transform='translate(415.1483,118.8176)'>
              <path
                d='m 0,0 c -8.084,0 -15.741,-6.04 -15.741,-17.357 0,-11.317 7.657,-17.794 15.741,-17.794 8.084,0 15.85,6.248 15.85,17.575 C 15.85,-6.249 8.302,0 0,0 m -3.878,13.261 c 9.601,0 16.177,-4.533 19.738,-9.492 v 8.52 H 31.065 V -47.45 H 15.86 v 8.738 c -3.561,-5.177 -10.355,-9.7 -19.847,-9.7 -15.096,0 -27.177,12.398 -27.177,31.055 0,18.656 12.081,30.628 27.286,30.628 z'
                fill='#2b3078'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path90'
              />
            </g>
            <path
              d='m 460.972,151.172 h 15.096 V 71.368 h -15.096 z'
              fill='#2b3078'
              fillOpacity={1}
              fillRule='nonzero'
              stroke='none'
              id='path92'
            />
            <g id='g94' transform='translate(493.326,118.7085)'>
              <path
                d='M 0,0 H -7.121 V 12.398 H 0 V 27.177 H 15.205 V 12.398 h 13.37 V 0 h -13.37 v -28.903 c 0,-3.987 1.617,-5.713 6.368,-5.713 h 7.012 v -12.725 h -9.492 c -11.426,0 -19.083,4.85 -19.083,18.548 L 0.01,0 Z'
                fill='#2b3078'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path96'
              />
            </g>
            <g id='g98' transform='translate(532.7917,151.1718)'>
              <path
                d='m 0,0 h 15.096 v -27.494 c 3.878,5.068 10.573,8.302 18.548,8.302 13.479,0 23.288,-9.056 23.288,-25.56 V -79.804 H 41.836 v 32.999 c 0,9.601 -5.286,14.768 -13.261,14.768 -7.974,0 -13.479,-5.177 -13.479,-14.768 V -79.804 H 0 Z'
                fill='#2b3078'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path100'
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
