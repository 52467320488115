/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HandelCallbackRequestMutationVariables = Types.Exact<{
  callbackRequestUpdateId: Types.Scalars['ID'];
  input: Types.CallbackRequestAdminInput;
}>;


export type HandelCallbackRequestMutation = { __typename?: 'Mutation', callbackRequestUpdate?: { __typename?: 'CallbackRequestCRUD', callbackRequest?: { __typename?: 'CallbackRequest', comments?: Array<string | null> | null, id: string, patientNotes?: string | null, serviceName?: string | null, status?: Types.CallbackRequestStatus | null, created?: any | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const HandelCallbackRequestDocument = gql`
    mutation handelCallbackRequest($callbackRequestUpdateId: ID!, $input: CallbackRequestAdminInput!) {
  callbackRequestUpdate(id: $callbackRequestUpdateId, input: $input) {
    callbackRequest {
      comments
      id
      patientNotes
      serviceName
      status
      created
    }
    entityErrors {
      message
      field
      code
    }
  }
}
    `;
export type HandelCallbackRequestMutationFn = Apollo.MutationFunction<HandelCallbackRequestMutation, HandelCallbackRequestMutationVariables>;

/**
 * __useHandelCallbackRequestMutation__
 *
 * To run a mutation, you first call `useHandelCallbackRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandelCallbackRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handelCallbackRequestMutation, { data, loading, error }] = useHandelCallbackRequestMutation({
 *   variables: {
 *      callbackRequestUpdateId: // value for 'callbackRequestUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandelCallbackRequestMutation(baseOptions?: Apollo.MutationHookOptions<HandelCallbackRequestMutation, HandelCallbackRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandelCallbackRequestMutation, HandelCallbackRequestMutationVariables>(HandelCallbackRequestDocument, options);
      }
export type HandelCallbackRequestMutationHookResult = ReturnType<typeof useHandelCallbackRequestMutation>;
export type HandelCallbackRequestMutationResult = Apollo.MutationResult<HandelCallbackRequestMutation>;
export type HandelCallbackRequestMutationOptions = Apollo.BaseMutationOptions<HandelCallbackRequestMutation, HandelCallbackRequestMutationVariables>;