/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramCallsForVendorQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CallsForVendorFilter>;
}>;


export type HealthProgramCallsForVendorQuery = { __typename?: 'Query', healthProgramCallsForVendor: Array<{ __typename?: 'HealthProgramCallsForVendorResponse', isUrgentDemandCall?: boolean | null, numberOfCalls?: number | null } | null> };


export const HealthProgramCallsForVendorDocument = gql`
    query healthProgramCallsForVendor($filter: CallsForVendorFilter) {
  healthProgramCallsForVendor(filter: $filter) {
    isUrgentDemandCall
    numberOfCalls
  }
}
    `;

/**
 * __useHealthProgramCallsForVendorQuery__
 *
 * To run a query within a React component, call `useHealthProgramCallsForVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramCallsForVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramCallsForVendorQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramCallsForVendorQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramCallsForVendorQuery, HealthProgramCallsForVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramCallsForVendorQuery, HealthProgramCallsForVendorQueryVariables>(HealthProgramCallsForVendorDocument, options);
      }
export function useHealthProgramCallsForVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramCallsForVendorQuery, HealthProgramCallsForVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramCallsForVendorQuery, HealthProgramCallsForVendorQueryVariables>(HealthProgramCallsForVendorDocument, options);
        }
export type HealthProgramCallsForVendorQueryHookResult = ReturnType<typeof useHealthProgramCallsForVendorQuery>;
export type HealthProgramCallsForVendorLazyQueryHookResult = ReturnType<typeof useHealthProgramCallsForVendorLazyQuery>;
export type HealthProgramCallsForVendorQueryResult = Apollo.QueryResult<HealthProgramCallsForVendorQuery, HealthProgramCallsForVendorQueryVariables>;