import { BotProps, BubbleProps } from "flowise-embed";
import { FullPageChat } from "flowise-embed-react";
import React, { FC, useState } from "react";
import { Button, IconButton, useTheme } from "../../base/mui";
import { RebootIcon, RobotIcon } from "../../icons";
import { CustomDialog } from "../Dialogs";

const CustomFullPageChat: FC<BubbleProps & BotProps> = FullPageChat as any;

export const FlowiseChat: FC<{
  chatFlowId: string;
  apiHost: string;
  iconButton?: boolean;
  title?: string;
}> = ({ chatFlowId, apiHost, title = "Preview", iconButton = false }) => {
  const theme = useTheme();
  const [id, setId] = useState(Date.now());

  const handleClearChat = () => {
    localStorage.removeItem(`${chatFlowId}_EXTERNAL`);
    setId(Date.now());
  };
  return (
    <CustomDialog
      type='base'
      button={
        iconButton ? (
          <IconButton>
            <RobotIcon />
          </IconButton>
        ) : (
          <Button> Preview </Button>
        )
      }
      DialogTitleProps={{
        title,
        children: (
          <IconButton
            sx={{
              position: "absolute",
              right: theme.spacing(5),
              alignSelf: "center",
            }}
            onClick={handleClearChat}
          >
            <RebootIcon
              sx={{
                width: 15,
                height: 15,
              }}
            />
          </IconButton>
        ),
      }}
      DialogContentProps={{
        sx: {
          padding: 0,
        },
      }}
    >
      <CustomFullPageChat
        chatflowid={chatFlowId}
        apiHost={apiHost}
        key={id}
        theme={{
          chatWindow: {
            showAgentMessages: true,

            // titleAvatarSrc: "https://smart3.iohealth.im/assets/images/logo.png",
            // title: "Smart3",
            showTitle: false,
            height: 780,
            userMessage: {
              backgroundColor: theme.palette.primary.main,
              textColor: theme.palette.common.white,
            },
            textInput: {
              sendButtonColor: theme.palette.primary.main,
            },
            footer: {
              showFooter: false,
            },
          },
        }}
      />
    </CustomDialog>
  );
};
