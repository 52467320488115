import { getAutocompleteFilter } from "@toolkit/ui";
import { useMarketplaceBrandsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getMarketplaceBrandsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceBrands",
        query: useMarketplaceBrandsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
    });
};
