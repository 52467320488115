import { H_EntityError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors, updateCache } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { HealthProgramDocument } from "gql/queries";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { HealthProgramCreateMutation, useHealthProgramCreateMutation } from "pages/HealthProgram/gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDefinitionRequestInputs } from "../form/HealthProgramForm.utils";
import { IHealthProgramInput, VariablesInput } from "../types";

export const useHealthProgramAdd = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<H_EntityError[]>([]);

  const [healthProgramCreate, { loading: isSubmitting }] = useHealthProgramCreateMutation({
    onCompleted: (mutationData: HealthProgramCreateMutation) => {
      const mutationErrors = mutationData?.healthProgramCreate?.healthProgramErrors;
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Program Created Successfully"));
        navigate(healthProgramPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as H_EntityError[]);
        failed(t("Health Program Create Failed"));
      }
    },
    update(cache, { data }) {
      updateCache(cache, data, HealthProgramDocument);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleSubmit = (input: IHealthProgramInput) => {
    const benefitMap = input?.benefitGroups?.filter(item => item?.isAdded);

    const benefitGroups = benefitMap?.map(item => ({
      benefitGroup: item?.id,
      optionsValues: item?.optionsValues
        ?.filter(item => item.isChecked)
        .map(value => ({ value: Number(value?.value), name: value?.nameOption, option: value?.id })),
      hasEmptyValue: item?.optionsValues
        ?.filter(item => item.isChecked)
        .map(value => ({
          value: Number(value?.value),
          name: value?.nameOption,
          option: value?.id,
        }))
        .some(item => isNaN(item?.value) || item?.value < 0),
    }));
    const request = benefitGroups?.map(item => ({
      benefitGroup: item?.benefitGroup,
      optionsValues: item?.optionsValues,
    }));
    const checkValue = benefitGroups?.some(item => item?.hasEmptyValue);
    if (checkValue) {
      return;
    } else {
      healthProgramCreate({
        variables: {
          input: { ...getDefinitionRequestInputs(input, request), payerId: input?.payerId?.id! },
        },
      } as Partial<VariablesInput>);
    }
  };

  const onSubmit = input => {
    handleSubmit(input);
  };
  const handleCancel = () => {
    navigate(healthProgramPaths.listPath.fullPath);
  };
  return {
    errors,
    handleCancel,
    onSubmit,
    isSubmitting,
  };
};
