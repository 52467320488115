import { Card, Grid, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import ContentLoader from "react-content-loader";

const useSimpleCardLoaderStyles = makeStyles()({
  card: {
    padding: 10,
    height: 250,
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

const SurveyCardLoader: FC = () => {
  const { classes } = useSimpleCardLoaderStyles();
  return (
    <>
      <Grid container spacing={2}>
        {new Array(8).fill(0).map((el, key) => (
          <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
            <Card elevation={1} className={classes.card}>
              <ContentLoader width={334} viewBox='0 0 350 160'>
                <rect x='10' y='73' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='88' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='103' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='118' rx='3' ry='3' width='334' height='8' />
                <rect x='105' y='133' rx='3' ry='3' width='135' height='8' />
                <circle cx='170' cy='32' r='26' />
                <circle cx='126' cy='152' r='8' />
                <circle cx='173' cy='152' r='8' />
                <circle cx='219' cy='152' r='8' />
              </ContentLoader>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SurveyCardLoader;
