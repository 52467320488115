import { SimplePaletteColorOptions } from "@mui/material/styles";

export const red: SimplePaletteColorOptions = {
  // Persian Red
  50: "#fcf5f5",
  100: "#fcecec",
  200: "#f8d9d8",
  300: "#f5c7c5",
  400: "#f1b4b1",
  500: "#eea19e",
  600: "#eb8e8b",
  700: "#e77b77",
  800: "#e46964",
  900: "#e05650",
  main: "#DD433D",
  1100: "#c73c37",
  1200: "#b13631",
  1300: "#9b2f2b",
  1400: "#852825",
  1500: "#6f221f",
  1600: "#581b18",
  1700: "#421412",
  1800: "#2c0d0c",
  1900: "#140505",
};
