import { Button, FormCard, Grid, MenuItem, RemoveIcon, TextField, ToggleButtonController } from "@toolkit/ui";
import { shouldQuestionHaveOptions } from "pages/Surveys/utils";
import React from "react";
import AddOptionForm from "./AddOptionForm";
import OptionItem from "./OptionItem";
import QuestionOption from "./QuestionOption";
import { QuestionCardProps } from "./types";
import useQuestionCard from "./useQuestionCard";

export const QuestionCard: React.FC<QuestionCardProps> = ({ questionIndex, onRemoveQuestion, onAddOption, onRemoveOption }) => {
  const {
    classes,
    questionTypeOptions,
    hoveredOption,
    option,
    canManageSurvey,
    control,
    requiredQuestion,
    reactFormErrors,
    questionOptions,
    questionType,
    register,
    handleAddOption,
    handleOptionChange,
    handleCopyOption,
    t,
    onOptionHovered,
    getDisplayedQuestionType,
  } = useQuestionCard({ onAddOption, questionIndex });

  return (
    <FormCard doYouHaveData={true} loading={false} classes={{ root: classes.root }}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} container className={classes.header} alignItems='center'>
          <Grid item xs className={classes.requiredLabelContainer}>
            <ToggleButtonController
              control={control}
              name={`questions.${questionIndex}.required`}
              label={t("Required")}
              defaultValue={requiredQuestion ?? false}
              disabled={!canManageSurvey}
            />
          </Grid>
          <Grid item>
            {canManageSurvey && (
              <Button startIcon={<RemoveIcon />} variant='text' onClick={onRemoveQuestion}>
                {t("Remove Question")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} padding={2} paddingBottom={0}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size='small'
              label={t("Question")}
              disabled={!canManageSurvey}
              error={Boolean(reactFormErrors.questions?.[questionIndex]?.question?.message)}
              helperText={t(reactFormErrors.questions?.[questionIndex]?.question?.message)}
              {...register(`questions.${questionIndex}.question`)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              select
              disabled={!canManageSurvey}
              value={questionType}
              error={Boolean(reactFormErrors.questions?.[questionIndex]?.questionType?.message)}
              helperText={t(reactFormErrors.questions?.[questionIndex]?.questionType?.message)}
              placeholder={t("Question Type")}
              label={t("Question Type")}
              {...register(`questions.${questionIndex}.questionType`)}
            >
              {questionTypeOptions.map(option => {
                return (
                  <MenuItem key={option!} value={option!} classes={{ root: classes.questionTypeItem }}>
                    <OptionItem checked item={getDisplayedQuestionType(option)} type={option} />
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {questionOptions?.map((option, index) => (
              <div key={option! + index} onMouseEnter={() => onOptionHovered(option)} onMouseLeave={() => onOptionHovered(undefined)}>
                <QuestionOption
                  questionIndex={questionIndex}
                  allOptions={questionOptions}
                  option={option}
                  isHovered={hoveredOption === option}
                  type={questionType}
                  onRemoveOption={() => onRemoveOption(index)}
                  onCopyOption={() => handleCopyOption(option!)}
                />
              </div>
            ))}
          </Grid>
          {canManageSurvey && shouldQuestionHaveOptions(questionType) && (
            <Grid xs={12} paddingLeft={2} marginTop={2} container alignItems='center' paddingRight='0px !important'>
              <AddOptionForm
                option={option}
                allOptions={questionOptions ?? []}
                onAddOption={handleAddOption}
                onOptionChange={handleOptionChange}
                questionType={questionType}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default QuestionCard;
