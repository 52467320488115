import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { AutocompleteProps } from "../../../types";
import Autocomplete from "../../AutocompleteV2/Autocomplete";

type FormAutocompleteProps = Omit<AutocompleteProps, "defaultValue"> & {
  name: string;
};

export const FormAutocomplete: FC<FormAutocompleteProps> = props => {
  const { name, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={props?.multiple ? [] : null}
      render={({ field }) => {
        const handleOnChange = (_: any, data: any) => {
          field.onChange(data);
        };

        return (
          <Autocomplete
            {...rest}
            {...field}
            fullWidth
            size={"small"}
            error={!!error}
            helperText={error}
            disabled={isFormDisabled || disabled}
            onChange={handleOnChange}
          />
        );
      }}
    />
  );
};
