import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Container, CustomIcon, FormAutocomplete, Grid, IconButton } from "@toolkit/ui";
import { DecisionConditionFieldsForm } from "../../../Decisions/forms/DecisionConditionFields/DecisionConditionFieldsForm";
import { getConditionAllowedValuesOptions, getConditionCategoriesOptions, getConditionFieldsOptions, getConditionOperatorsOptions, hideDecisionConditionOperator, } from "../../../Decisions/others/utils";
import { useFormContext } from "react-hook-form";
import { useDecisionConditionUpsertStyle } from "./useDecisionConditionUpsertStyle";
export const DecisionConditionsUpsertForm = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { isFieldsMoreThanOne, decisionPlanFields, conditionPath, onRemoveCondition } = props;
    const { t } = useTranslation("domains");
    const form = useFormContext();
    const { watch } = form;
    const condition = watch(conditionPath);
    const healthParameter = !!watch("healthParameter");
    const categoriesOptions = getConditionCategoriesOptions(decisionPlanFields, healthParameter);
    const fieldsOptions = getConditionFieldsOptions(decisionPlanFields, (_b = (_a = condition === null || condition === void 0 ? void 0 : condition.category) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.code);
    const operatorOptions = getConditionOperatorsOptions(decisionPlanFields, (_d = (_c = condition === null || condition === void 0 ? void 0 : condition.field) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.code);
    const allowedValuesOptions = getConditionAllowedValuesOptions(decisionPlanFields, (_f = (_e = condition === null || condition === void 0 ? void 0 : condition.field) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.code);
    const { classes, theme } = useDecisionConditionUpsertStyle({ isFieldsMoreThanOne });
    return (_jsxs(_Fragment, { children: [isFieldsMoreThanOne && (_jsxs(Grid, { item: true, lg: 1, className: classes.gridIcon, children: [_jsx(Container, { className: classes.container }), _jsx(IconButton, { onClick: onRemoveCondition, children: _jsx(CustomIcon, { color: theme.palette.error.main, icon: 'minus' }) })] })), _jsx(Grid, { item: true, xs: 12, md: 6, lg: isFieldsMoreThanOne ? 2.5 : 3, children: _jsx(FormAutocomplete, { name: `${conditionPath}.category`, label: t("Category"), placeholder: t("Category"), options: categoriesOptions }) }), _jsx(Grid, { item: true, xs: 12, md: 6, lg: isFieldsMoreThanOne ? 2.5 : 3, children: _jsx(FormAutocomplete, { name: `${conditionPath}.field`, label: t("Field"), placeholder: t("Field"), options: fieldsOptions }) }), _jsx(Grid, { item: true, xs: 12, md: 6, lg: isFieldsMoreThanOne ? 2.5 : 3, children: _jsx(FormAutocomplete, { name: `${conditionPath}.operator`, label: t("Operator"), placeholder: t("Operator"), options: operatorOptions }) }), _jsx(Grid, { item: true, xs: 12, md: 6, lg: isFieldsMoreThanOne ? 2.5 : 3, children: !condition || hideDecisionConditionOperator((_g = condition === null || condition === void 0 ? void 0 : condition.operator) === null || _g === void 0 ? void 0 : _g.key) ? (_jsx(_Fragment, {})) : (_jsx(DecisionConditionFieldsForm, { conditionPath: conditionPath, condition: condition, allowedValuesOptions: allowedValuesOptions })) })] }));
};
