import { TFunction } from "@toolkit/i18n";
import { subscriptionsManagementPaths } from "pages/SubscriptionsMangment/constants/subscriptions-management-paths";

export const subscriptionsManagementTitleBreadcrumb = (t: TFunction) => {
  return t("Subscriptions Management", { ns: "admin" });
};

export const subscriptionsManagementListBreadCrumb = (t: TFunction) => {
  return {
    id: "subscriptions-management-list",
    name: t("Subscriptions", { ns: "admin" }),
    route: subscriptionsManagementPaths.listPath.fullPath,
  };
};

export const subscriptionsManagementNewBreadCrumb = (t: TFunction) => {
  return {
    id: "subscriptions-management-new",
    name: t("New", { ns: "admin" }),
  };
};

export const subscriptionsManagementFieldBreadCrumb = (fieldName: string) => {
  return {
    id: "subscriptions-management-field",
    name: fieldName,
  };
};
