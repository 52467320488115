import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { some } from "lodash";
import { useMemo, useState } from "react";

export const useReasonForm = ({ reasons, onChange }) => {
  const [state, setState] = useState({ reason: "", other: "", isOther: false });

  const { t } = useTranslation("domains");
  const { open: isOpen, handleToggle } = useOpenState();

  const reasonsWithOtherOption = useMemo(() => {
    const _reasonsWithOtherOption = reasons || [];
    if (reasons?.length === 0 && !some(reasons, item => item.value === "Other")) {
      _reasonsWithOtherOption?.push({ value: "Other", label: t("Other") });
    }
    return _reasonsWithOtherOption;
  }, [reasons?.length]);

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "Other") {
      setState({ ...state, isOther: true, reason: e.target.value });
      onChange("", false);
    } else {
      setState({ isOther: false, reason: e.target.value, other: "" });
      onChange(e.target.value, true);
    }
  };

  const handleChangeOtherReason = e => {
    setState({ ...state, other: e.target.value, isOther: true });
    onChange(e.target.value, !!e.target.value);
  };

  return {
    state,
    isOpen,
    reasonsWithOtherOption,
    handleToggle,
    handleChangeOtherReason,
    handleChangeReason,
  };
};
