import { jsx as _jsx } from "react/jsx-runtime";
import { Type } from "@health/queries/types";
import { Box } from "@toolkit/ui";
import { useMemo } from "react";
import { mapStringValueToBackendFieldType } from "../EditAdminManagedListDetails/utils";
import { getConceptPropertyValue, getFilterValuePropertyName, getInputType } from "./utils";
export function useMapConceptDefinitionsToDataGridColumns(codeSystemConceptFields) {
    return useMemo(() => {
        var _a;
        return ((_a = codeSystemConceptFields === null || codeSystemConceptFields === void 0 ? void 0 : codeSystemConceptFields.map(item => {
            const type = getInputType(item);
            const isBooleanField = (item === null || item === void 0 ? void 0 : item.fieldType) === Type.Boolean;
            const booleanFieldFilterOptions = !isBooleanField
                ? {}
                : {
                    options: ["Yes", "No"],
                    getOptionLabel: o => o,
                };
            return {
                key: item === null || item === void 0 ? void 0 : item.code,
                header: item === null || item === void 0 ? void 0 : item.display,
                accessor: ({ properties }) => {
                    const itemData = properties === null || properties === void 0 ? void 0 : properties.find(row => (row === null || row === void 0 ? void 0 : row.code) == (item === null || item === void 0 ? void 0 : item.code));
                    return itemData ? (_jsx(Box, { component: 'div', sx: { width: "max-content" }, children: getConceptPropertyValue(itemData) })) : ("-");
                },
                isSortable: false,
                filter: Object.assign(Object.assign({ name: item === null || item === void 0 ? void 0 : item.code, accessor: item === null || item === void 0 ? void 0 : item.code, type }, booleanFieldFilterOptions), { getValueForBackend: o => {
                        if (!o || (typeof o === "string" && (o === null || o === void 0 ? void 0 : o.trim().length) === 0))
                            return undefined;
                        return {
                            code: item === null || item === void 0 ? void 0 : item.code,
                            type: item === null || item === void 0 ? void 0 : item.fieldType,
                            [getFilterValuePropertyName(item === null || item === void 0 ? void 0 : item.fieldType)]: mapStringValueToBackendFieldType(item === null || item === void 0 ? void 0 : item.fieldType, o),
                        };
                    } }),
            };
        })) !== null && _a !== void 0 ? _a : []);
    }, [JSON.stringify(codeSystemConceptFields || "{}")]);
}
