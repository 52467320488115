/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { FC, memo } from "react";
import { makeStyles } from "tss-react/mui";
import { Box } from "../base/mui";

interface DotProps {
  width?: number | string;
  height?: number | string;
  background?: string;
}

const useDotStyle = makeStyles<DotProps>()((theme, props) => {
  return {
    root: {
      width: props.width || 10,
      height: props.height || 10,
      borderRadius: "50%",
      background: props.background || theme.palette?.secondary?.main,
    },
  };
});

const DotComponent: FC<DotProps> = props => {
  const {
    classes: { root },
  } = useDotStyle({ ...props });

  return <Box className={root} />;
};

export const Dot = memo(DotComponent);
