import { TFunction } from "@toolkit/i18n";
import { UploadIcon8 } from "@toolkit/ui";
import { reviewPageUploadPaths, reviewPageUploadRoute } from "pages/UploadPages/constants/review-page-upload-paths";
import { UploadPageReviewList } from "pages/UploadPages/UploadPage.screen";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { hasReviewUploadPermissions } from "utils";

export const reviewPageUploadRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "review-page-upload-routes",
    text: t("Files Management", { ns: "admin" }),
    route: reviewPageUploadRoute,
    fullPath: reviewPageUploadPaths.mainPath.fullPath,
    element: <UploadPageReviewList />,
    icon: <UploadIcon8 />,
    isProhibited: !hasReviewUploadPermissions(),
    onClick: (route: string) => navigate(route),
  };
};
