import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const healthParameterCategoryAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullable().optional(),
}));
export const HealthParameterCategoryAutocompleteSchemas = {
    healthParameterCategoryAutocomplete,
    healthParameterCategoryAutocompleteOptional: healthParameterCategoryAutocomplete.nullable().optional(),
};
