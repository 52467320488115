import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { siteSetting } from "../utils";
import { ISettingView } from "./types";
import { useSettingViewStyle } from "./useSettingViewStyle";

export const SettingView: FC<ISettingView> = ({ state, onChangeView: handleChangeView }) => {
  const { t } = useTranslation("admin");
  const { classes } = useSettingViewStyle();

  return (
    <Box className={classes.box}>
      <Button
        variant='text'
        onClick={handleChangeView(siteSetting.siteSettings)}
        className={state !== siteSetting.healthProgramsSiteSettings ? `${classes.button} ${classes.selected}` : classes.button}
      >
        {t("Site Setting")}
      </Button>
      <Button
        variant='text'
        className={state !== siteSetting.siteSettings ? `${classes.button} ${classes.selected}` : classes.button}
        onClick={handleChangeView(siteSetting.healthProgramsSiteSettings)}
      >
        {t("Health Program Setting")}
      </Button>
    </Box>
  );
};
