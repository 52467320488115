import { useOpenState } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import MDEditor, { ICommand, TextAreaTextApi, TextState, commands } from "@uiw/react-md-editor";
import React, { ChangeEvent, FC } from "react";
import { Typography } from "../../base/mui";
import { UploadImageModal } from "./CustomImageUploder";
import UploadImageCommandButton from "./CustomsCommands/ImageUplodCommand/UploadImageCommandButton";
import { useMDTextEditorStyle } from "./mdTextEditorStyle";

interface MDEditorProps {
  value?: string;
  onChange?: (v?: string) => void;
  readonly?: boolean;
  placeholder?: string;
  label?: string;
  token?: string;
  direction?: "ltr" | "rtl";
  height?: number;
}

export const MDTextEditor: FC<MDEditorProps> = ({
  value,
  onChange: handleChangeEditorValue,
  readonly,
  placeholder,
  label,
  token,
  direction,
  height = 200,
}) => {
  const { classes, theme } = useMDTextEditorStyle();

  const { open, handleOpen, handleClose: onClose } = useOpenState();
  const [image, setImage] = React.useState<string>("");
  const apiRef = React.useRef<TextAreaTextApi>();
  const executeCommandCallback = () => {
    apiRef?.current?.replaceSelection(`![alt text](${image})`);
    setImage("");
  };
  const onImageUploaded = (fileName: string | string[], e?: ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    setImage(fileName as string);
  };
  const call = (state: TextState, api: TextAreaTextApi) => {
    apiRef.current = api;
  };
  const handleUploadImageClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    command: ICommand,
    executeCommand: (command: ICommand, name?: string) => void
  ) => {
    event.stopPropagation();
    handleOpen();
    executeCommand(command, command.groupName);
  };
  const uploadImageCommand: ICommand | undefined = token
    ? {
        name: "uploadIMG",
        keyCommand: "uploadIMG",
        render: (command, disabled, executeCommand) => (
          <UploadImageCommandButton onClick={event => handleUploadImageClick(event, command, executeCommand)} disabled={disabled} />
        ),
        execute: async (state, api) => {
          call(state, api);
        },
      }
    : undefined;
  return (
    <div className='container' data-color-mode='light'>
      {label && <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.md, padding: "10px 0" }}>{label}</Typography>}
      <MDEditor
        direction={direction || i18n?.dir()}
        value={value}
        className={classes.editor}
        onChange={handleChangeEditorValue}
        preview={readonly ? "preview" : undefined}
        hideToolbar={readonly}
        aria-placeholder={placeholder}
        highlightEnable={false}
        height={height}
        commands={[
          commands.bold,
          commands.italic,
          commands.strikethrough,
          commands.hr,
          commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
            name: "title",
            groupName: "title",
            buttonProps: { "aria-label": "Insert title" },
          }),
          commands.divider,
          commands.quote,
          commands.table,
          commands.divider,
          commands.checkedListCommand,
          commands.orderedListCommand,
          commands.unorderedListCommand,
          commands.orderedListCommand,
          commands.divider,
          commands.image,
          ...(uploadImageCommand ? [uploadImageCommand] : []),
        ]}
      />

      <UploadImageModal
        handleClose={onClose}
        open={open}
        handleImageUploaded={onImageUploaded}
        image={image}
        executeCommandCallback={executeCommandCallback}
        token={token}
      />
    </div>
  );
};
MDTextEditor.defaultProps = {
  value: "",
  onChange: () => {},
  readonly: false,
  placeholder: "",
  label: "",
};
