import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { SurveyIcon } from "@toolkit/ui";
import { surveysPaths, surveysRoute } from "pages/Surveys/constants/surveys-paths";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { SurveyDetailsPage } from "./index";
import { SurveysListPage } from "./SurveysListPage";

export const surveysRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "surveys-routes",
    text: t("Surveys", { ns: "admin" }),
    icon: <SurveyIcon />,
    route: surveysRoute,
    isProhibited: !hasPermission(PermissionEnum.ViewSurveys),
    subItems: [
      {
        id: "surveys-list-route",
        text: t("Surveys", { ns: "admin" }),
        route: surveysPaths.listPath.route,
        fullPath: surveysPaths.listPath.fullPath,
        element: <SurveysListPage />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "surveys-details-route",
        text: t("Surveys Details", { ns: "admin" }),
        route: surveysPaths.detailsPath.route,
        fullPath: surveysPaths.detailsPath.fullPath,
        element: <SurveyDetailsPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
