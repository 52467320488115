import { sadaConfig } from "./sadaConfig";
export function createScriptElement(src, type) {
    const script = document.createElement("script");
    script.src = src;
    script.defer = true;
    script.type = type !== null && type !== void 0 ? type : "text/javascript";
    return script;
}
export function createStyleElement(href) {
    const link = document.createElement("link");
    link.href = href;
    link.type = "text/css";
    link.rel = "stylesheet";
    return link;
}
export function getSadaInstance() {
    var _a;
    if (typeof window === "undefined") {
        return null;
    }
    return (_a = window["SADA_EMBEDDED_INSTANCE"]) !== null && _a !== void 0 ? _a : null;
}
export function getSadaConfig() {
    const appUrl = sadaConfig.get("sadaBaseUrl");
    const apiEnv = sadaConfig.get("sadaApiEnv");
    const apiUrl = sadaConfig.get("sadaApiBaseUrl");
    return {
        apiEnv,
        apiUrl: apiUrl === null || apiUrl === void 0 ? void 0 : apiUrl.replace(/\/+$/, ""),
        appUrl: appUrl === null || appUrl === void 0 ? void 0 : appUrl.replace(/\/+$/, ""),
    };
}
