import moment, { Moment } from "moment";
import { defaultWeekWorkingDays } from "./constants";
import { DayTimeRange, WorkingHour, WorkingHoursType } from "./types";

export const removeEmptyRanges = (workingDays: Array<WorkingHoursType>): Array<WorkingHoursType> => {
  return workingDays
    ?.map(workingDay => {
      const openTimeRanges = workingDay?.openTimeRanges
        ?.map(timeRange => ({
          openTime: timeRange?.openTime,
          closeTime: timeRange?.closeTime,
        }))
        .filter(timeRange => timeRange.openTime && timeRange.closeTime);
      return {
        day: workingDay.day,
        openTimeRanges,
      };
    })
    .filter(workingDay => workingDay?.openTimeRanges?.length);
};

export const getMomentTime = (time: string): Moment | undefined => {
  if (!time) {
    return;
  }
  return moment(time, "HH:mm:ss");
};

export const getWeekWorkingDays = (workingDays: Array<WorkingHour>): Array<WorkingHour> =>
  defaultWeekWorkingDays.map(defaultWorkingDay => {
    let passedWorkingDay = workingDays?.find(workingDay => workingDay?.day === defaultWorkingDay.day);
    if (passedWorkingDay) {
      passedWorkingDay = {
        day: passedWorkingDay.day,
        openTimeRanges: (passedWorkingDay.openTimeRanges ?? []).map(
          timeRange =>
            ({
              ...timeRange,
              id: `${Math.ceil(Math.random() * 100)}`,
            } as DayTimeRange)
        ),
      };
    }
    return passedWorkingDay || defaultWorkingDay;
  });
